// vendors
import React, { useCallback, useEffect, useState } from "react";
import { IonButton, IonChip, IonCol, IonGrid, IonRow, isPlatform } from "@ionic/react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    IgrDataGrid,
    IgrDataGridCellEventArgs,
    IgrTemplateColumn,
    IgrTextColumn,
    IIgrCellTemplateProps,
} from "igniteui-react-grids";
//components
import { UserModalForm } from "../UserModalForm/UserModalForm";
// utils
import { validateUser, SytemAppType } from "../../utils/FormValidations";
import { RootState } from "../../_redux/reducers/rootReducer";
import { DataGridPager } from "../../pages/HotelManager/ProductsPage/ProductGrid/DataGridPager";
import {
    fetchUserRequest,
    DeleteUser,
    fetchUserLocationRequest,
    resendInviteUser,
} from "../../_redux/actions/userManagementActions";
import { sortArrayOfObjects } from "../../utils";
import {
    Hotel365Portal,
    GLOBAL_FIRST_NAME,
    GLOBAL_LAST_NAME,
    GLOBAL_EMAIL,
    USER_MGMT_TABLE_ROLE,
    USER_MGMT_TABLE_ADD_USR,
    USER_MGMT_CONFM_DLT_HDR,
    USER_MGMT_TABLE_CUSTOM_LOC_SHOW,
    USER_MGMT_TABLE_RESEND_BTN,
    USER_MGMT_TABLE_EDIT_BTN,
    USER_MGMT_TABLE_NO_USR_MSG,
    USER_MGMT_TABLE_EDIT_USR,
    PortalType,
    CLIENT_ADMIN_ID
} from "../../constants/constant";
import { portalType } from "../../auth.config";
import { PostResetPassword } from "../../_redux/actions/resetPasswordActions";

const colNameMapping = {
    delete: "",
    firstName: GLOBAL_FIRST_NAME,
    lastName: GLOBAL_LAST_NAME,
    email: GLOBAL_EMAIL,
    role: USER_MGMT_TABLE_ROLE,
    resendButton: "",
    editButton: "",
};

export const UserManagementTable: React.FC<any> = (props) => {
    let _grid: IgrDataGrid;
    const dispatch = useDispatch();
    const originalColList = { ...colNameMapping } as any;
    const sytemPortaltype = SytemAppType(portalType);
    const {
        postUserManagement,
        fetchUserManagement,
        paginate,
        deleteUserManagement,
        patchUserManagement,
        patchUserManagementRole,
        updateUserDetailLocation,
        updateUserDetailOrganization,
        postUserDetailEmail,
        setLocationForCP,
    } = useSelector((state: RootState) => state.UserManagement, shallowEqual);
    const [user, setUser] = useState({} as Record<string, any>);
    const [userData, setUserData] = useState(fetchUserManagement);
    const [openModal, setOpenModal] = useState(false);
    const [formTitle, setFormTitle] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [deleteModal, setDeleteModal] = useState(false);
    const [unableDelete, setUnableDelete] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [ok, setOk] = useState(false);
    const isdesktop = isPlatform("desktop");
    const ismobile = isPlatform("mobile");
    const istablet = isPlatform("tablet");
    const { error, fetchUserDetail, fetchUserDetailLocations } = useSelector((state: RootState) => state.UserManagement, shallowEqual);
    const locations = useSelector((state: RootState) => { return state.locations; }, shallowEqual);
    const userDetails = useSelector((state: RootState) => { return state.systemUsers; }, shallowEqual);
    const userProfile = useSelector((state: RootState) => { return state.profile; }, shallowEqual);
    const { profiles } = userProfile.allProfiles;
    const modalProps = {
        formTitle,
        closeModal,
        openModal,
        AddUser,
        deleteModal,
        deleteClose,
        setUserData,
        confirmDelete,
        unableDeleteClose,
        userData,
        unableDelete,
        cancel,
        user,
        okBtn,
        fetchUserDetail
    };

    const checkPostUserManagement = useCallback(() => {
        return postUserManagement && Object.keys(postUserManagement).length > 0;
    }, [postUserManagement]);
    const checkDeleteUserManagement = useCallback(() => {
        return (
            deleteUserManagement && Object.keys(deleteUserManagement).length > 0
        );
    }, [deleteUserManagement]);

    const checkPatchUserManagement = useCallback(() => {
        return (
            patchUserManagement && Object.keys(patchUserManagement).length > 0
        );
    }, [patchUserManagement]);

    const checkPatchUserManagementRole = useCallback(() => {
        return (
            patchUserManagementRole &&
            Object.keys(patchUserManagementRole).length > 0
        );
    }, [patchUserManagementRole]);

    const checkUpdateUserLocations = useCallback(() => {
        return (
            updateUserDetailLocation &&
            Object.keys(updateUserDetailLocation).length > 0
        );
    }, [updateUserDetailLocation]);

    const checkUpdateUserOrganizations = useCallback(() => {
        return (
            updateUserDetailOrganization &&
            Object.keys(updateUserDetailOrganization).length > 0
        );
    }, [updateUserDetailOrganization]);

    const checkUpdateUserEmail = useCallback(() => {
        return (
            postUserDetailEmail && Object.keys(postUserDetailEmail).length > 0
        );
    }, [postUserDetailEmail]);

    useEffect(() => {
        if ((fetchUserDetail && Object.keys(fetchUserDetail).length > 0) && (fetchUserDetailLocations && Object.keys(fetchUserDetailLocations).length > 0)) {
            setOpenModal(true);
            let location: string[] = [];
            if (fetchUserDetailLocations && fetchUserDetailLocations.length > 0) {
                location = fetchUserDetailLocations.map((_location: Record<string, any>) => _location.locationId);
            }
            fetchUserDetail.effectiveEndDate = fetchUserDetail.effectiveEndDate ? fetchUserDetail.effectiveEndDate.split(" ")[0] : "";
            fetchUserDetail.effectiveStartDate = fetchUserDetail.effectiveStartDate ? fetchUserDetail.effectiveStartDate.split(" ")[0] : "";
            setUser({ ...fetchUserDetail, location });
            setFormTitle(USER_MGMT_TABLE_EDIT_USR);
        }
    }, [fetchUserDetail, fetchUserDetailLocations]);

    function editUser(_user: any) {
        dispatch(fetchUserRequest(_user.systemUserId));
        dispatch(fetchUserLocationRequest(_user.systemUserId));
    }

    function AddUser() {
        setFormTitle(USER_MGMT_TABLE_ADD_USR);
        setUser({});
        setOpenModal(true);
        dispatch(fetchUserLocationRequest(userDetails.systemUserId));
    }

    function deleteUser(_user: Record<string, any>) {
        if (userData.length === 1) {
            setUnableDelete(!unableDelete);
        } else {
            setDeleteModal(!deleteModal);
        }
        setFormTitle(USER_MGMT_CONFM_DLT_HDR);
    }

    function closeModal() {
        setFormErrors(false);
        setOpenModal(false);
    }

    function confirmDelete() {
        dispatch(DeleteUser(user.systemUserId, { isDisabled: true }));
        deleteClose();
    }

    function cancel() {
        setDeleteModal(!deleteModal);
        setCancelModal(!cancelModal);
    }

    function okBtn() {
        setOk(ok);
        setUnableDelete(!unableDelete);
    }

    function deleteClose() {
        setDeleteModal(false);
    }

    function unableDeleteClose() {
        setUnableDelete(false);
    }

    function save(locationList: Record<string, any>[]) {
        const newUser = portalType === PortalType.CP ? { ...user, roleId: CLIENT_ADMIN_ID, isDisabled:fetchUserDetail?.effectiveStartDate?true :false } as any : {...user, isDisabled:fetchUserDetail?.effectiveStartDate?true :false} as any;
        const errorObject = validateUser(newUser);
        const { defaultOrgId } = userDetails;
        setFormErrors(errorObject);
        const isError = Object.keys(errorObject).length;
        const orgIds = locationList?.filter(item => newUser?.location?.includes(item?.locationId))
        const request = {
            email: newUser.email,
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            locations: newUser.location ? newUser.location : [],
            orgs: orgIds?.length > 0 ? orgIds.map(item => item.orgId) : [],
            defaultOrgId: defaultOrgId,
            roles: [
                {
                    effectiveEndDate: newUser.effectiveEndDate ? newUser.effectiveEndDate : "",
                    effectiveStartDate: newUser.effectiveStartDate ? newUser.effectiveStartDate : "",
                    roleId: newUser.roleId,
                },
            ],
        };
        isError === 0 && props.submitData(request, fetchUserDetail?.systemUserId);
    }

    const onGridRef = (grid: IgrDataGrid) => {
        if (!grid) {
            return;
        }
        _grid = grid;
    };

    const selectedColumnsTextColor = (i: string) => {
        return ["delete", "location", "resendButton", "editButton"].indexOf(i) >
            -1
            ? Hotel365Portal.defaultPrimaryColor
            : Hotel365Portal.gridTextValueColorCode;
    };

    const renderOtherColumns = (i: string) => {
        return (
            <IgrTextColumn
                field={i}
                key={i}
                horizontalAlignment="center"
                headerText={originalColList[i] || i}
                isFilteringEnabled={false}
                isColumnOptionsSummariesEnabled={false}
                isEditable={false}
                textColor={selectedColumnsTextColor(i)}
                name={i}
            />
        );
    };

    const customLocation = () => {
        return (
            <span className="market-product-name location-text">
                {USER_MGMT_TABLE_CUSTOM_LOC_SHOW}
            </span>
        );
    };

    const customDelete = (props: IIgrCellTemplateProps) => {
        return (
            <IonChip className={`${!props.dataContext.rowItem.showEditDelete ? "onboarded" : ""} delete-chip`}>
                <p className="cancel-modal">&times;</p>
            </IonChip>
        );
    };

    const renderDeleteIcon = (i: string) => {
        return (
            <IgrTemplateColumn
                field={i}
                key={i}
                horizontalAlignment="center"
                template={(props: IIgrCellTemplateProps) => customDelete(props)}
                width="80"
                headerText=""
                isFilteringEnabled={false}
                isColumnOptionsSummariesEnabled={false}
                name={i}
            />
        );
    };
    const renderActionButton = (i: string) => {
        return (
            <IgrTemplateColumn
                field={i}
                key={i}
                horizontalAlignment="center"
                template={(props: IIgrCellTemplateProps) => customActionButton(props, i)}
                width="100"
                headerText=""
                isFilteringEnabled={false}
                isColumnOptionsEnabled={false}
                isColumnOptionsSummariesEnabled={false}
                name={i}
            />
        );
    };

    const userModalData = { selectedUser: user, ...modalProps};
    const customActionButton = (props: IIgrCellTemplateProps, field: string) => {
        return (
            <IonRow>
                <IonCol>
                    {field === "resendButton" && (
                        <span
                            className={
                                props.dataContext.rowItem.isPasswordSet
                                    ? "market-product-name location-text onboarded"
                                    : "market-product-name location-text"
                            }
                        >
                            {USER_MGMT_TABLE_RESEND_BTN}
                        </span>
                    )}
                    </IonCol>
                    <IonCol>
                    {field === "editButton" && (
                        <span
                            className={
                                props.dataContext.rowItem.showEditDelete
                                    ? "market-product-name location-text"
                                    : "market-product-name location-text onboarded"
                            }
                        >
                            {USER_MGMT_TABLE_EDIT_BTN}
                        </span>
                    )}
                </IonCol>
            </IonRow>
        );
    };
    const renderCols = () => {
        const arr = [];
        for (let i in colNameMapping) {
            // @ts-ignore
            i !== "productId" &&
                arr.push(
                    ["location"].indexOf(i) > -1 ? (
                        <IgrTemplateColumn
                            field={i}
                            key={i}
                            horizontalAlignment="center"
                            headerText={originalColList[i] || ""}
                            template={() => customLocation()}
                            isFilteringEnabled={false}
                            isColumnOptionsSummariesEnabled={false}
                            name={i}
                        />
                    ) : ["delete"].indexOf(i) > -1 ? (
                        renderDeleteIcon(i)
                    ) : ["resendButton", "editButton"].indexOf(i) > -1 ? (
                        renderActionButton(i)
                    ) : (
                        renderOtherColumns(i)
                    )
                );
        }
        return arr;
    };
    const selectedRow = (gridData: any, event: IgrDataGridCellEventArgs) => {
        setUser(event.cellInfo.rowItem);
        if (gridData._implementation.dm?.c === "delete") {
            event.cellInfo.rowItem.showEditDelete && deleteUser(event.cellInfo.rowItem);
        } else if (["editButton"].indexOf(gridData._implementation.dm?.c) > -1) {
            event.cellInfo.rowItem.showEditDelete && editUser(event.cellInfo.rowItem);
        } else if (
            gridData._implementation.dm?.c === "resendButton" &&
            !event.cellInfo.originalValue
        ) {
            if (portalType === PortalType.CP) {
                const request = { email: event.cellInfo.rowItem.email, systemAppType: sytemPortaltype };
                event.cellInfo.rowItem.email && dispatch(PostResetPassword(request));
            } else {
                dispatch(resendInviteUser(event.cellInfo.rowItem.email));
            }
        }
    };
    useEffect(() => {
        if (
            checkPostUserManagement() ||
            checkDeleteUserManagement() ||
            checkPatchUserManagement() ||
            checkPatchUserManagementRole() ||
            checkUpdateUserLocations() ||
            checkUpdateUserOrganizations() ||
            checkUpdateUserEmail()
        ) {
            closeModal();
            setUser({});
        }
    }, [
        postUserManagement,
        deleteUserManagement,
        patchUserManagement,
        patchUserManagementRole,
        updateUserDetailLocation,
        updateUserDetailOrganization,
        postUserDetailEmail,
    ]);

    const onPageChanged = (offset: number) => {
        props.updatePaginate(offset);
    };
    // @ts-ignore
    return (
        <>
            <IonRow className="div-user-management-bg">
                <IonCol>
                    <IonButton
                        className="add-user"
                        color="primary"
                        onClick={AddUser}
                    >
                        {USER_MGMT_TABLE_ADD_USR}
                    </IonButton>
                </IonCol>
            </IonRow>
            {fetchUserManagement.length > 0 && (
                <>
                    <IonRow
                        className="container sample usermanagment-table-section"
                        key="market-product-list"
                    >
                        <IonCol key="grid-container" className="consumer-row-container">
                            <IgrDataGrid
                                ref={onGridRef}
                                height={fetchUserManagement.length > 8 ? "calc(100% - 130px)" : "calc(100% - 130px)"}
                                width="100%"
                                defaultColumnMinWidth={100}
                                autoGenerateColumns={false}
                                dataSource={fetchUserManagement}
                                selectionMode="SingleCell"
                                activationMode="Cell"
                                editMode="CellBatch"
                                cellClicked={(s: IgrDataGrid, e: IgrDataGridCellEventArgs) =>
                                    selectedRow(s, e)
                                }
                                isColumnOptionsEnabled="true"
                                headerBackground="#635D5D"
                                headerTextColor="#ffffff"
                                autoAcceptEdits={false}
                                headerTextStyle="Normal Normal 12px Roboto"
                                cellTextStyle="Normal Normal 14px Roboto"
                                columnMovingMode={"NONE"}
                            >
                                {renderCols()}
                            </IgrDataGrid>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <DataGridPager
                                dataSource={fetchUserManagement}
                                pageSize={paginate.limit}
                                totalPageCount={paginate.pageCount}
                                currentPage={paginate.currentPage}
                                pagedChanged={onPageChanged}
                            />
                        </IonCol>
                    </IonRow>
                </>
            )}
            {fetchUserManagement.length === 0 && (
                <IonRow>
                    <IonCol
                            className={
                                isdesktop || istablet
                                    ? "user-management-no-product"
                                    : "user-management-no-product-mobile"
                            }
                        >
                            {USER_MGMT_TABLE_NO_USR_MSG}
                      
                    </IonCol>
                </IonRow>
            )}

            <UserModalForm
                userForm={user}
                {...userModalData}
                formErrors={formErrors}
                profiles={profiles}
                setUser={setUser}
                save={save}
                onSubmit={save}
                fetchUserDetailLocations={fetchUserDetailLocations}
                setLocationForCP={setLocationForCP}
            />
        </>
    );
};

export default UserManagementTable;