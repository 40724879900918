import { IonLabel, isPlatform } from '@ionic/react';
import { Hotel365Portal } from '../../../constants/constant';
import { ImageDescriberInterface } from '../../../models/UserProfile';
import { getlastInventoryDate } from '../../../utils';

export function imageDescriptor(props: ImageDescriberInterface): JSX.Element {
    const { marketTheme } = props;
    const { logoUrl } = marketTheme;
    return logoUrl ? <img className="site-logo" src={logoUrl} /> : <img className="user-site-logo" src={Hotel365Portal.defaultWhiteLogo} />;
}
export function headerMarketLabels(props: any): JSX.Element {
    const { currentProfile, hasPreventOrdersUntilInventoryTaken } = props;
    const isDesktop = isPlatform("desktop");
    const isTablet = isPlatform("tablet");
    return (
        <>
            <IonLabel slot="start" className="header-market-name">{currentProfile && currentProfile.marketName}</IonLabel>
            {(isDesktop || isTablet) && (
                <IonLabel slot="end" className={hasPreventOrdersUntilInventoryTaken ? "header-inventory-span-margin header-inventory-banner-content" : "header-last-inventory"}>
                    {getlastInventoryDate(currentProfile.lastInventorySessionDate)}
                </IonLabel>
            )}
        </>
    )
}

export default { imageDescriptor, headerMarketLabels };
