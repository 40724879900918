//vendors
import React, { useEffect, useState, useCallback } from 'react'
import {
    isPlatform,
    IonCol,
    IonContent,
    IonGrid,
    IonRow,
    IonSpinner
} from "@ionic/react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { shallowEqual, useSelector } from 'react-redux';
//components
import Header from '../Header';
import Sidenav from '../Sidenav';
//reducers
import { RootState } from '../../_redux/reducers/rootReducer';
//constants
import { fallBackNumber, PAGE_NOT_FOUND_MSG1,PAGE_NOT_FOUND_MSG2 } from '../../constants/constant';


export const Fallback: React.FC<any> = ({ doLogout }) => {
    const profile = useSelector((state: RootState) => {
        return state.profile;
    }, shallowEqual);
    const { pending } = profile && profile.currentProfile;
    const history = useHistory();
    const location = useLocation();
    const { pathname } = location;
    const [activeNavItem, setActiveNavItem] = useState<string>(pathname);
    const { isAuthenticated, user, logout, isLoading } = useAuth0();
    const isDesktopView = isPlatform("desktop");
    const isTabletView = isPlatform("tablet");
    const isMobileView = isPlatform("mobile");
    const [mQuery, setMQuery] = React.useState<any>({ matches: isDesktopView ? true : false });
    const systemUserId = user && user['https://365rm.us/systemUserId'];
    const setNavItemActiveOnClick = useCallback((route) => {
        setActiveNavItem(route.toLowerCase());
        history.push({ pathname: `/dashboard/${route}` });
    }, []);
    useEffect(() => {
        let mediaQuery = window.matchMedia("(min-width: 768px)");
        mediaQuery.addEventListener('change', setMQuery);
        return () => mediaQuery.removeEventListener('change', setMQuery);
    }, []);
    const renderFallback = () => {
        return (
            <>
                {isAuthenticated && <Header
                    systemUserId={systemUserId}
                    buildLogoutUrl={logout}
                    doLogout={doLogout}
                />}
                <IonContent fullscreen>
                    <IonGrid className="ion-no-border dashboard-ig-main">
                        <IonRow className="dashboard-ig-height">
                            {isAuthenticated && <Sidenav
                                mQuery={mQuery}
                                setNavItemActiveOnClick={setNavItemActiveOnClick}
                                activeNavItem={activeNavItem}
                            />}
                            <IonCol className="main-content padding-0">
                                <IonCol size="12" sizeXs="12" sizeMd="12" sizeLg="12">
                                    <div className='fallback-center'>
                                        <div className={isDesktopView ? 'fallback-circle-desktop' : isTabletView ? "fallback-circle-error-tablet" : 'fallback-circle-mobileview'}>
                                            <b>{fallBackNumber}</b>
                                        </div>
                                        <h1 className={isDesktopView || isTabletView ? 'page-not-found' : 'page-not-found-mobile'}><b>{PAGE_NOT_FOUND_MSG1}</b></h1>
                                        <h3 className={isMobileView && !isTabletView ? 'page-not-found-mobile' : 'page-not-found-tablet'}>{PAGE_NOT_FOUND_MSG2}</h3>
                                    </div>
                                </IonCol>
                            </IonCol>
                        </IonRow >
                    </IonGrid >
                </IonContent >
            </>
        )
    }

    return (<>{pending && isAuthenticated ? (<IonSpinner name="circles" className="fallback-spinner" />) : renderFallback()}</>)

}