import React from "react";
import { IonGrid, IonCheckbox, IonRow, IonCol, IonLabel } from "@ionic/react";

interface ProductProps {
    productName: string;
    checked: boolean;
    onCheckedCheckBox: Function;
    param:string
}

const Body: React.FC<ProductProps> = (props: ProductProps) => {
    return (<>
        <IonGrid>
            <IonRow>
                <IonCol offsetXs="1" size="auto">
                    <IonCheckbox
                        checked = {props.checked}
                        onIonChange = {(e) => {props.onCheckedCheckBox(props.param)}}
                        value = {props.param}
                        key={props.param}
                    />
                </IonCol>
                <IonCol size="8">
                    <IonLabel>{props.productName}</IonLabel>
                </IonCol>
            </IonRow>
        </IonGrid>
    </>)
}

export default Body;
