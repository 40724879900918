import React, { memo, useEffect, useCallback, useState, useMemo } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
    IonModal,
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    isPlatform
} from "@ionic/react";

import { RootState } from "../../../_redux/reducers/rootReducer";
import {
    updateProductCategoryFilters,
    FetchGlobalProductsCategories,
} from "../../../_redux/actions/productActions";
import {resetProductsSettingsPanel } from '../../../_redux/actions/productSettingsPanelActions'

import { ProductFilterHeader } from "./partials/product-filter-header";
import { ClearFilterButton } from "./partials/clear-filter-button";
import { CategoryItems } from "./partials/category-items";

export interface ProductFiltersProps {
    name?: string;
    showFilterModal: boolean;
    notifyFilterClose: (filters: string[]) => void;
}

interface Category {
    categoryName: string;
}
export const ProductFilters: React.FC<ProductFiltersProps> = memo((props) => {
    const dispatch = useDispatch();
    const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
    const [showProductFilterModal, setShowProductFilterModal] = useState(props.showFilterModal);
    const [categoryList, setCategoryList] = useState([] as Category[]);
    const [checked, setChecked] = useState([] as string[]);
    const isMobileView = isPlatform("mobile");
    
    const productFilter = useSelector(
        (state: RootState) => state.product.categoryFilters,
        shallowEqual
    );
    const { globalProductCategories, globalProductCategoriesPaginate } = useSelector(
        (state: RootState) => state.product,
        shallowEqual
    );

    const categoryData = useMemo(() => {
      if(globalProductCategories && globalProductCategories.length > 0)
        return globalProductCategories.map((item) => {
            return {
                categoryName: item,
            };
        });
    }, [globalProductCategories]);

    useEffect(() => {
        setShowProductFilterModal(props.showFilterModal);
        setChecked(productFilter)
        if (categoryData) {
            let data =
                categoryData.length > 0 ? categoryData : ([] as Category[]);
            setCategoryList(data as Category[]);
        }
        if (
          globalProductCategories.length !== 0 &&
          globalProductCategories.length === globalProductCategoriesPaginate.totalCount
        ) {
            setInfiniteDisabled(true);
        }
    }, [
        categoryData,
        setCategoryList,
        props.showFilterModal,
        productFilter,
        setChecked,
        setShowProductFilterModal,
    ]);

    const handleScroll = (ev: any) => {
        setTimeout(() => {
            const offset: number = globalProductCategoriesPaginate.nextOffset;
            dispatch(resetProductsSettingsPanel())
            dispatch(FetchGlobalProductsCategories("", offset));
            ev.target.complete();
            if (globalProductCategories.length === globalProductCategoriesPaginate.totalCount) {
                setInfiniteDisabled(true);
            }
        }, 500);
    };

    const onCloseFilterModal = useCallback(() => {
        setShowProductFilterModal(false);
        dispatch(updateProductCategoryFilters(checked));
        props.notifyFilterClose(checked);
    }, [props.notifyFilterClose]);

    const onClearFilter = useCallback(() => {
        dispatch(updateProductCategoryFilters([]));
        setChecked([]);
    }, [setChecked, updateProductCategoryFilters]);

    const isActiveHandler = useCallback(
        (category: string) => {
            const seletedValues = checked;
            if (seletedValues.length > 0) {
                const isPresent = seletedValues.some(
                    (item: string) => item === category
                );
                if (isPresent) {
                    seletedValues.splice(seletedValues.indexOf(category), 1);
                } else {
                    seletedValues.push(category);
                }
            } else {
                seletedValues.push(category);
            }
            setChecked(seletedValues);
        },
        [setChecked, checked]
    );

    return (
        <IonModal
            isOpen={showProductFilterModal}
            className={isMobileView ? "mobileview-modal-pop product-category-filter" : "modal-pop product-category-filter"}
            onDidDismiss={onCloseFilterModal}
        >
            <IonContent>
                <ProductFilterHeader onCloseFilterModal={onCloseFilterModal} />
                <ClearFilterButton onClearFilter={onClearFilter} />
                <CategoryItems
                    isActiveHandler={isActiveHandler}
                    checked={checked}
                    categoryList={categoryList}
                />
                <IonInfiniteScroll
                    onIonInfinite={handleScroll}
                    threshold="100px"
                    disabled={isInfiniteDisabled}
                >
                    <IonInfiniteScrollContent
                        loadingSpinner="bubbles"
                        loadingText="Loading more data..."
                    ></IonInfiniteScrollContent>
                </IonInfiniteScroll>
            </IonContent>
        </IonModal>
    );
});
