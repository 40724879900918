//vendors
import React from "react";
import {
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonRow,
    IonCard,
    IonButton,
    isPlatform
} from "@ionic/react";
//constants
import { PAGE_NOT_FOUND_MSG1,PAGE_NOT_FOUND_MSG2, TRY_AGAIN, fallBackNumber } from '../constants/constant';

const ErrorComponent: React.FC<any> = ({ message, tryAgain = false, tryAgainCallBack, isFallback = false }) => {
    const isMobile = isPlatform("mobile");
    const isDesktop = isPlatform("desktop");
    const isTablet = isPlatform("tablet");
    return (
        <IonContent fullscreen>
            <IonGrid className={isDesktop ? "ion-no-border errorWrapper" : isMobile && !isTablet ? "ion-no-border mobile-errorWrapper" : "ion-no-border tablet-errorWrapper"}>
                <IonRow>
                    <IonCol>
                        {isFallback ?
                            <IonCol className="main-content padding-0">
                                <IonCol size="12" sizeXs="12" sizeMd="12" sizeLg="12">
                                    <div className='fallback-center-error'>
                                        <div className={isDesktop || isTablet ? 'fallback-circle' : 'fallback-circle-mobile'}>
                                            <b>{fallBackNumber}</b>
                                        </div>
                                        <h1 className={isDesktop || isTablet ? 'page-not-found' : 'page-not-found-mobile'}><b>{PAGE_NOT_FOUND_MSG1}</b></h1>
                                        <h3 className={isMobile && !isTablet ? 'page-not-found-mobile' : 'page-not-found-tablet'}>{PAGE_NOT_FOUND_MSG2}</h3>
                                    </div>
                                </IonCol>
                            </IonCol>
                            : <IonCard>
                                <IonCardContent className={isDesktop ? "errorMsg" : isMobile && !isTablet ? "mobile-errorMsg" : "tablet-errorMsg"}>
                                    {message}
                                    {tryAgain &&
                                        <IonButton
                                            className="tryAgainBtn"
                                            color="primary"
                                            onClick={tryAgainCallBack}
                                        >
                                           {TRY_AGAIN}
                                        </IonButton>}
                                </IonCardContent>
                            </IonCard>}
                    </IonCol >
                </IonRow >
            </IonGrid >
        </IonContent >
    )
}

export default ErrorComponent;