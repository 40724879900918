import { useIonAlert } from '@ionic/react';

const AlertHook = () => {
    const [present] = useIonAlert();
    const confirmPrompt = (alertText:string,
                           okButtonText:string,
                           cancelButtonText:string,
                           proceedHandler:Function) =>
    present({
        cssClass: 'my-css',
        header: 'Alert',
        message: alertText,
        buttons: [
            {text: cancelButtonText,
                role:"cancel",
                cssClass:"",
                id:""
                },
            { text: okButtonText,role:"destructive", handler: (d) => {proceedHandler()} },
        ],
        onDidDismiss: (e) => console.log('did dismiss'),
    })
    return [confirmPrompt]
};
export default AlertHook;

/*usage:
const [alertPrompt] = AlertConfirmWindow();
alertPrompt("alert prompt text goes here")
 */
