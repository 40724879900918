const theme = {
  '--ion-color-secondary' : '#5260ff',
  '--ion-color-secondary-rgb' : '82,96,255',
  '--ion-color-secondary-contrast' : '#ffffff',
  '--ion-color-secondary-contrast-rgb' : '255,255,255',
  '--ion-color-secondary-shade' : '#4854e0',
  '--ion-color-secondary-tint' : '#6370ff',

  '--ion-color-tertiary' : '#5260ff',
  '--ion-color-tertiary-rgb' : '82,96,255',
  '--ion-color-tertiary-contrast' : '#ffffff',
  '--ion-color-tertiary-contrast-rgb' : '255,255,255',
  '--ion-color-tertiary-shade' : '#4854e0',
  '--ion-color-tertiary-tint' : '#6370ff',

  '--ion-color-success' : '#2dd36f',
  '--ion-color-success-rgb' : '45,211,111',
  '--ion-color-success-contrast' : '#000000',
  '--ion-color-success-contrast-rgb' : '0,0,0',
  '--ion-color-success-shade' : '#28ba62',
  '--ion-color-success-tint' : '#42d77d',

  '--ion-color-warning' : '#ffc409',
  '--ion-color-warning-rgb' : '255,196,9',
  '--ion-color-warning-contrast' : '#000000',
  '--ion-color-warning-contrast-rgb' : '0,0,0',
  '--ion-color-warning-shade' : '#e0ac08',
  '--ion-color-warning-tint' : '#ffca22',

  '--ion-color-danger' : '#eb445a',
  '--ion-color-danger-rgb' : '235,68,90',
  '--ion-color-danger-contrast' : '#ffffff',
  '--ion-color-danger-contrast-rgb' : '255,255,255',
  '--ion-color-danger-shade' : '#cf3c4f',
  '--ion-color-danger-tint' : '#ed576b',

  '--ion-color-medium' : '#4a4953',
  '--ion-color-medium-rgb' : '74,73,83',
  '--ion-color-medium-contrast' : '#ffffff',
  '--ion-color-medium-contrast-rgb' : '255,255,255',
  '--ion-color-medium-shade' : '#414049',
  '--ion-color-medium-tint' : '#5c5b64',

  '--ion-color-light' : '#eaeaeb',
  '--ion-color-light-rgb' : '244,245,248',
  '--ion-color-light-contrast' : '#000000',
  '--ion-color-light-contrast-rgb' : '0,0,0',
  '--ion-color-light-shade' : '#d7d8da',
  '--ion-color-light-tint' : '#f5f6f9',
}

export default theme;