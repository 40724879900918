// vendors
import React from "react";
import { IonButton, IonCol, IonRow } from "@ionic/react";
//constants
import { paymentConstants, PaymentFooterProps } from '../constants';

const Footer: React.FC<PaymentFooterProps> = ({
    detachCard,
    isAddCardClicked,
    onCancel,
    showDeleteCardBtn = false,
    showSaveNewCardBtn = false,
    onClickSave
}) => {
    return (
        <IonCol size="12">
            <IonRow>
                <IonCol>
                    {showDeleteCardBtn && (
                        <IonButton
                            color="danger"
                            onClick={detachCard}
                            className="btn-full-width">
                            {paymentConstants.CONFIRM_DELETE_BUTTON}
                        </IonButton>
                    )}
                    {showSaveNewCardBtn && (
                        <IonButton
                            id="paymentSubmit"
                            onClick={onClickSave}
                            className="mobile-payment-save-button"
                            disabled={isAddCardClicked}
                        />
                    )}
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonButton onClick={onCancel} className="btn-full-width">{paymentConstants.CONFIRM_CANCEL_BUTTON}</IonButton>
                </IonCol>
            </IonRow>
        </IonCol>
    );
}
export default Footer;