import { all, put, take, takeLatest, select } from "redux-saga/effects";
import { doDelete, doGet, doPost } from '../../../utils/fetchWrapper';
import {
    postAddCardRequest,
    postAddCardSuccess,
    postAddCardFailure,
    fetchPublicKeySuccess,
    fetchPublicKeyFailure,
    fetchSysUserTokensRequest,
    fetchSysUserTokensSuccess,
    fetchSysUserTokensFailure,
    detachCardSuccess,
    detachCardFailure,
    detachCardRequest,
} from "../../actions/paymentActions";
import * as PAYMENT from '../../ActionTypes/paymentTypes';
import { Routes } from '../../../constants/Routes';
import { BILLING_ZIP_CODE_REQUIRED, NAME_ON_CARD_REQUIRED } from "../../../pages/Payment/constants";

function* getSystemUserTokens(action: any) {
    const { systemUserId } = action;
    const url = `${Routes.payment.getSysUserTokens}${systemUserId}/tokens`;
    try {
        // @ts-ignore
        const response = yield doGet(url);
        const { res } = response
        yield put(fetchSysUserTokensSuccess(res));
    } catch (error: any) {
        yield put(fetchSysUserTokensFailure(error))
    }
}

function* getPublicKey(action: any) {
    const { locationId } = action;
    const url = `${Routes.payment.getPublicKey}${locationId}`;
    try {
        // @ts-ignore
        const response = yield doGet(url);
        yield put(fetchPublicKeySuccess(response));
    } catch (error: any) {
        yield put(fetchPublicKeyFailure(error));
    }
}

function* postAddCard(action: any) {
    const { payload } = action;
    const url = `${Routes.payment.addCard}`;
    const { cardHolder, zip } = payload;
    try {
        if (cardHolder === '') {
            throw ({ message: NAME_ON_CARD_REQUIRED, type: 'NON_API_CALL' });
        } else if (zip === '') {
            throw ({ message: BILLING_ZIP_CODE_REQUIRED, type: 'NON_API_CALL' });
        } else {
            const updatedPayload = { ...payload, "cardHolder": cardHolder.trim(), "zip": zip.trim() };
            // @ts-ignore
            const response = yield doPost(url, updatedPayload);
            yield put(postAddCardSuccess(response));
        }
    } catch (error: any) {
        yield put(postAddCardFailure(error));
    }
}

function* addCardFlow(action: any) {
    const { payload } = action;
    const { systemUserId } = payload;
    yield put(postAddCardRequest(payload));
    const { response } = yield take([PAYMENT.POST_ADDCARD_SUCCESS]);
    yield put(fetchSysUserTokensRequest(systemUserId));
    yield take(PAYMENT.GET_SYSUSER_TOKENS_SUCCESS);
}

function* deleteCardFlow(action: any) {
    const { payload } = action;
    const { systemUserId } = payload;
    yield put(detachCardRequest(payload));
    yield take([PAYMENT.DETACH_CARD_SUCCESS]);
    yield put(fetchSysUserTokensRequest(systemUserId));
    yield take(PAYMENT.GET_SYSUSER_TOKENS_SUCCESS);
}

function* detachCard(action: any) {
    const { payload } = action;
    const url = `${Routes.payment.detachCard}`;
    try {
        // @ts-ignore
        const response = yield doDelete(url, payload);
        yield put(detachCardSuccess(response));
    } catch (error: any) {
        yield put(detachCardFailure(error));
    }
}

function* PaymentSaga() {
    yield all([
        takeLatest(PAYMENT.GET_PUBLIC_KEY_REQUEST, getPublicKey),
        takeLatest(PAYMENT.POST_ADDCARD_REQUEST, postAddCard),
        takeLatest(PAYMENT.ADD_CARD_FLOW, addCardFlow),
        takeLatest(PAYMENT.GET_SYSUSER_TOKENS_REQUEST, getSystemUserTokens),
        takeLatest(PAYMENT.DETACH_CARD_REQUEST, detachCard),
        takeLatest(PAYMENT.DELETE_CARD_FLOW, deleteCardFlow),
    ])
}

export default PaymentSaga;