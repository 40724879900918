//vendors
import React from "react";
import { IonCol, IonInput, IonItem, IonLabel, IonRow, isPlatform } from "@ionic/react";
//components
import FooterComponent from "../FooterComponent";
import OnboardingLeftSections from "../OnboardingProcess/SignupLeftSection";

//constants
import {
    Hotel365Portal,
    tokenExpiredLeftSection,
} from "../../constants/constant";
const isdesktop = isPlatform("desktop");
const ismobile = isPlatform("mobile");
const istablet = isPlatform("tablet")

const TokenExpired: React.FC<any> = (props) => {
    const {
        formErrors,
        resetPasswordNewInviteErrorMessage,
        setEmail,
        onResetpasswordNewEmailSubmit,
        newEmail,
        verifyEmailTokenExpired,
        verifyEmailNewInviteErrorMessage,
        createPasswordNewInviteErrorMessage,
        onCreatepasswordNewEmailSubmit,
    } = props;

    const onSetEmailChangeHandler = (e: any) => {
        setEmail((prevState: any) => ({
            ...prevState,
            [e.target.name]: e.detail.value,
        }));
    };
    
    return (
        <IonRow className="h-100">
            <OnboardingLeftSections
                colSize={ismobile || istablet && !isdesktop ? '12' : '6'}
                title={resetPasswordNewInviteErrorMessage ||
                    verifyEmailNewInviteErrorMessage ||
                    createPasswordNewInviteErrorMessage
                    ? resetPasswordNewInviteErrorMessage ||
                    verifyEmailNewInviteErrorMessage ||
                    createPasswordNewInviteErrorMessage
                    : tokenExpiredLeftSection} />
            <IonCol sizeLg='6' className={ismobile || istablet && !isdesktop ? 'token-exp-tab' : 'token-expired-desktop'}>
                <IonRow className='ion-padding-start'>
                    <IonCol sizeXs='12'>
                        <IonLabel><b>{Hotel365Portal.tokenEmailName}</b></IonLabel>
                    </IonCol>
                </IonRow>
                <IonRow className='ion-padding-start'>
                    <IonCol>
                        <IonItem className="new-invite-email">
                            <IonInput
                                aria-label={Hotel365Portal.tokenEmailName}
                                onIonChange={onSetEmailChangeHandler}
                                name="newEmail"
                                value={newEmail} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow className='ion-padding-start'><span>{Hotel365Portal.tokenInfoText}</span></IonRow>
                <IonRow className='ion-padding-start'>{formErrors.emailError && (
                    <span className="global-red">
                        *{formErrors.emailError}
                    </span>
                )}</IonRow>
                <IonCol size='12'>
                    <FooterComponent
                        submitCallBack={(resetPasswordNewInviteErrorMessage
                            ? onResetpasswordNewEmailSubmit
                            : onResetpasswordNewEmailSubmit) ||
                            (verifyEmailNewInviteErrorMessage &&
                                verifyEmailTokenExpired) ||
                            (createPasswordNewInviteErrorMessage
                                ? onCreatepasswordNewEmailSubmit
                                : onCreatepasswordNewEmailSubmit)}
                        footerRowClassName={isdesktop ? 'token-expired-footer' : ''}
                        footerRowMobileClassName={ismobile || istablet && !isdesktop ? 'token-expired-mobile' : ''}
                        footerColClassName='onboarding-footer-col'
                        buttonLabel={resetPasswordNewInviteErrorMessage ||
                            verifyEmailNewInviteErrorMessage ||
                            createPasswordNewInviteErrorMessage
                            ? Hotel365Portal.GLOBAL_SEND_LINK_BTN
                            : Hotel365Portal.GLOBAL_NEW_INVITE_BTN} />
                </IonCol>
            </IonCol>
        </IonRow>
    );
};
export default TokenExpired;