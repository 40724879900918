//vendors
import React, { useCallback, useEffect, useState } from "react";
import {
    IonButton,
    IonChip,
    IonCol,
    IonFooter,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    isPlatform,
} from "@ionic/react";
import { star } from "ionicons/icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
//reducer
import { RootState } from "../../_redux/reducers/rootReducer";
//actions
import { FetchLocationsBySysUserIdRequest } from "../../_redux/actions/locationsActions";
import { resetFetchUsers } from "../../_redux/actions/userManagementActions";
//constants
import {
    FOOTER_CANCEL_BUTTON,
    FOOTER_SAVE_BUTTON,
    GLOBAL_EMAIL,
    GLOBAL_FIRST_NAME,
    GLOBAL_LAST_NAME,
    GLOBAL_OK_BUTTON,
    Hotel365Portal,
    USER_MGMT_CONFIRM_DELETE_HOTEL_MSG,
    USER_MGMT_CONFIRM_DELETE_CLIENT_MSG,
    USER_MGMT_CONFIRM_DELETE_MSG2,
    USER_MGMT_CONFIRM_YES_DELETE_BUTTON,
    USER_MGMT_UNABLE_DELETE_HEADER,
    USER_MGMT_UNABLE_DELETE_MSG,
    USER_MOD_FORM_EFFECT_DATE,
    USER_MOD_FORM_EXPIRE_DATE,
    USER_MOD_FORM_LOC,
    USER_MOD_FORM_SECURITY_ROLE,
    USER_MOD_FORM_SELECT_LOC,
    CLIENT_ADMIN,
    PortalType
} from '../../constants/constant';
import { portalType } from "../../auth.config";
import { sortArrayOfObjects, getUserRolesBasedOnPortalType } from '../../utils';
//components
import UserRoleSelection from './UserRoleSelection';

export const UserModalForm: React.FC<any> = (props) => {
    const {
        openModal,
        formTitle,
        closeModal,
        handleSubmit,
        deleteModal,
        deleteClose,
        unableDelete,
        unableDeleteClose,
        confirmDelete,
        selectedUser,
        cancel,
        okBtn,
        save,
        userForm,
        setUser,
        formErrors,
        profiles,
        fetchUserDetailLocations,
        setLocationForCP,
        fetchUserDetail
    } = props;
    const {
        firstName,
        lastName,
        email,
        location,
        effectiveEndDate,
        effectiveStartDate,
        roleId,
    } = userForm || {};
    const dispatch = useDispatch();
    const isDesktopView = isPlatform("desktop")

    const [locationList, setLocationList] = useState([] as Record<string, any>);
    const userDetails = useSelector((state: RootState) => state.systemUsers, shallowEqual);

    const onChangeHandler = (e: any) => {
        setUser((prevState: any) => ({
            ...prevState,
            [e.target.name]: e.detail.value,
        }));
    };
    const onRoleChangeHandler = (e: any) => {
        setUser((prevState: any) => ({ ...prevState, roleId: e.detail.value }));
    };
    useEffect(() => {
        let profileLocations
        profileLocations = profiles && profiles.map((location: { locationId: number; marketName: string; orgId: number }) => {
            return {
                locationId: location.locationId,
                name: location.marketName,
                orgId: location.orgId
            }
        })

        if (portalType !== PortalType.HP) {
            profileLocations = setLocationForCP && setLocationForCP.map((location: any) => {
                return {
                    locationId: location.locationId,
                    name: location.name,
                    orgId: location.orgId
                }
            });
        }
        let updatedUserLocations = fetchUserDetailLocations.map((location: any) => {
            return {
                locationId: location.locationId,
                name: location.name,
                orgId: location.orgId
            }
        })
        //@ts-ignore
        const uniqueLocations = [... new Map([...profileLocations, ...updatedUserLocations].map((item) => {
            return [item['locationId'], item]
        })).values()];
        const sortedLocationList = (sortArrayOfObjects(uniqueLocations, "name"));
        setLocationList(sortedLocationList);
    }, [profiles, fetchUserDetailLocations])
    useEffect(() => {
        const systemUserId = userDetails.systemUserId;
        dispatch(FetchLocationsBySysUserIdRequest(systemUserId));
    }, [dispatch]);

    const isEffectiveDisabled = useCallback(
        () => {
            return formTitle.toLowerCase().indexOf('edit') > -1 && fetchUserDetail?.effectiveStartDate !== '';
        },
        [formTitle, fetchUserDetail?.effectiveStartDate],
    )

    return (
        <>
            <IonModal
                isOpen={openModal}
                className={!isDesktopView ? "user-management-mobile" : "user-management-modal-pop"}
                onDidDismiss={() => {
                    dispatch(resetFetchUsers(true));
                    closeModal();
                }}>
                <IonHeader className="header">
                    <IonRow style={{ marginTop: 10 }}>
                        <IonCol>
                            <IonTitle>{formTitle}</IonTitle>
                        </IonCol>
                        <IonCol>
                            <IonChip
                                color="primmary"
                                className="ion-button"
                                onClick={() => {
                                    dispatch(resetFetchUsers(true));
                                    closeModal();
                                }}>
                                <span className="cancel">&times;</span>
                            </IonChip>
                        </IonCol>
                    </IonRow>
                </IonHeader>
                <form className={!isDesktopView ? "form-body-mobile" : "form-body"} onSubmit={handleSubmit}>
                    <IonRow>
                        <IonCol offset="1"></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" size-xs="12" size-md="6">
                            {formErrors && formErrors.firstName && (
                                <span style={{ color: "red" }}>*</span>
                            )}
                            <IonLabel className="input-headers">
                                {GLOBAL_FIRST_NAME}
                            </IonLabel>
                            <IonItem className="user-form">
                                <IonInput
                                    value={firstName}
                                    type="text"
                                    name="firstName"
                                    aria-label={GLOBAL_FIRST_NAME}
                                    onIonChange={(e) => {
                                        onChangeHandler(e);
                                    }}
                                    className="usermanagment-txt-box"
                                    required
                                ></IonInput>
                            </IonItem>
                            {formErrors.firstName && (
                                <span style={{ color: "red" }}>
                                    {formErrors.firstName}
                                </span>
                            )}
                        </IonCol>
                        <IonCol size-xs="12" size-md="6">
                            {formErrors && formErrors.lastName && (
                                <span style={{ color: "red" }}>*</span>
                            )}
                            <IonLabel className="input-headers">
                                {GLOBAL_LAST_NAME}
                            </IonLabel>
                            <IonItem className="user-form">
                                <IonInput
                                    value={lastName}
                                    type="text"
                                    name="lastName"
                                    aria-label={GLOBAL_LAST_NAME}
                                    onIonChange={(e) => {
                                        onChangeHandler(e);
                                    }}
                                    className="usermanagment-txt-box"
                                    required
                                ></IonInput>
                            </IonItem>
                            {formErrors.lastName && (
                                <span style={{ color: "red" }}>
                                    {formErrors.lastName}
                                </span>
                            )}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {formErrors && formErrors.email && (
                                <span style={{ color: "red" }}>*</span>
                            )}
                            <IonLabel className="input-headers">
                                {GLOBAL_EMAIL}
                            </IonLabel>
                            <IonItem className="user-form-input">
                                <IonInput
                                    value={email}
                                    type="email"
                                    name="email"
                                    aria-label={GLOBAL_EMAIL}
                                    onIonChange={(e) => {
                                        onChangeHandler(e);
                                    }}
                                    className="usermanagment-txt-box"
                                    required
                                ></IonInput>
                            </IonItem>
                            {formErrors.email && (
                                <span style={{ color: "red" }}>
                                    {formErrors.email}
                                </span>
                            )}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonLabel className="input-headers">
                                {formErrors?.location && (
                                    <IonIcon
                                        md={star}
                                        size="small"
                                        color="danger"
                                        className="error-asterik-size"
                                    />
                                )}
                                {USER_MOD_FORM_LOC}
                            </IonLabel>
                            <IonSelect
                                value={location}
                                placeholder={USER_MOD_FORM_SELECT_LOC}
                                name="location"
                                onIonChange={(e) => {
                                    onChangeHandler(e);
                                }}
                                multiple
                                interface="popover"
                                className="usermanagement-loc-selct"
                            >
                                {locationList.map((location: any, index: number) => {
                                    const { name, locationId } =
                                        location;
                                    return (
                                        <IonSelectOption
                                            value={locationId}
                                            key={index}
                                        >
                                            {name}
                                        </IonSelectOption>
                                    );
                                })}
                            </IonSelect>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonLabel className="input-headers">
                                {formErrors?.roleId && (
                                    <IonIcon
                                        md={star}
                                        size="small"
                                        color="danger"
                                        className="error-asterik-size"
                                    />
                                )}
                                {USER_MOD_FORM_SECURITY_ROLE}
                            </IonLabel>

                            {portalType === PortalType.HP ? <UserRoleSelection
                                onRoleChangeHandler={onRoleChangeHandler}
                                roles={getUserRolesBasedOnPortalType(portalType)}
                                roleId={roleId}
                                formTitle={formTitle}
                            /> :
                                <IonRow>
                                    <IonCol>
                                        <IonLabel className="segment-input client-admin-default-sel">{CLIENT_ADMIN}</IonLabel>
                                    </IonCol>
                                </IonRow>
                            }
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size-xs="12" size-md="6">
                            <IonLabel className="input-headers">
                                {USER_MOD_FORM_EFFECT_DATE}
                            </IonLabel>
                            <IonItem className={!effectiveStartDate && formErrors.userValidityError ? "required-date-error" : "user-form-input"}>
                                <IonInput
                                    name="effectiveStartDate"
                                    type="date"
                                    max={Hotel365Portal.maxDateFormat}
                                    value={effectiveStartDate}
                                    aria-label={USER_MOD_FORM_EFFECT_DATE}
                                    onIonInput={(e) => {
                                        onChangeHandler(e);
                                    }}
                                    disabled={isEffectiveDisabled()}
                                    className="usermanagment-txt-box"
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-xs="12" size-md="6">
                            <IonLabel className="input-headers">
                                {USER_MOD_FORM_EXPIRE_DATE}
                            </IonLabel>
                            <IonItem className={!effectiveEndDate && formErrors.userValidityError ? "required-date-error" : "user-form-input"}>
                                <IonInput
                                    name="effectiveEndDate"
                                    type="date"
                                    max={Hotel365Portal.maxDateFormat}
                                    value={effectiveEndDate}
                                    aria-label={USER_MOD_FORM_EXPIRE_DATE}
                                    onIonInput={(e) => {
                                        onChangeHandler(e);
                                    }}
                                    className="usermanagment-txt-box"
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className='validation-error-message'>
                        <IonCol>
                            {formErrors.userValidityError}
                        </IonCol>
                    </IonRow>
                    </form>
                    <IonFooter className="footer">
                        <IonRow>
                            <IonCol offsetMd="4" sizeMd="4" offsetXs="0" sizeXs="6">
                                <IonButton
                                    color="gray"
                                    expand="block"
                                    className="btn-text-cases-cancel"
                                    onClick={() => closeModal(false)}
                                >
                                    {FOOTER_CANCEL_BUTTON}
                                </IonButton>
                            </IonCol>
                            <IonCol sizeMd="4" sizeXs="6">
                                <IonButton
                                    color="primary"
                                    expand="block"
                                    type="submit"
                                    className="btn-text-cases-save"
                                    onClick={() => save(locationList)}
                                >
                                    {FOOTER_SAVE_BUTTON}
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonFooter>
            </IonModal>
            <IonModal
                isOpen={deleteModal}
                className="delete-modal-pop"
                onDidDismiss={deleteClose}
            >
                <IonHeader className="delete-header">
                    <IonRow className="unable-title">
                        <IonTitle className="unable-header-bold">
                            {formTitle}
                        </IonTitle>
                        <IonChip
                            className="ion-button-delete"
                            onClick={deleteClose}
                        >
                            <p className="cancel-chip">&times;</p>
                        </IonChip>
                    </IonRow>
                </IonHeader>
                <form className="form-delete-body" onSubmit={handleSubmit}>
                    <IonRow>
                        <IonCol size="11">
                            <IonLabel className="delete-text-bold line-break">
                                {portalType === PortalType.HP ? USER_MGMT_CONFIRM_DELETE_HOTEL_MSG : USER_MGMT_CONFIRM_DELETE_CLIENT_MSG}
                            </IonLabel>
                            <IonLabel
                                color="danger"
                                className="delete-name"
                            >
                                {selectedUser?.firstName}
                            </IonLabel>
                            <span>{" - "}</span>
                            <IonLabel
                                color="danger"
                                className="delete-name line-break"
                            >
                                {selectedUser?.email}
                            </IonLabel>
                            <IonLabel className="delete-text-bold">
                                {USER_MGMT_CONFIRM_DELETE_MSG2}
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                </form>
                <IonFooter className="footer">
                    <IonRow>
                        <IonCol offset="3" sizeXs="4">
                            <IonButton
                                color="gray"
                                className="cancel-btn-1"
                                onClick={cancel}
                            >
                                {FOOTER_CANCEL_BUTTON}
                            </IonButton>
                        </IonCol>
                        <IonCol sizeXs="4">
                            <IonButton
                                color="danger"
                                className="delete-btn"
                                onClick={confirmDelete}
                            >
                                {USER_MGMT_CONFIRM_YES_DELETE_BUTTON}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonFooter>
            </IonModal>
            <IonModal
                isOpen={unableDelete}
                className="delete-modal-pop"
                onDidDismiss={unableDeleteClose}
            >
                <IonHeader className="delete-header">
                    <IonRow className="unable-title">
                        <IonCol>
                            <IonTitle className="unable-header-bold">
                                {USER_MGMT_UNABLE_DELETE_HEADER}
                            </IonTitle>
                        </IonCol>
                        <IonCol>
                            <IonChip
                                className="ion-button"
                                onClick={unableDeleteClose}
                            >
                                <p className="cancel-chip">&times;</p>
                            </IonChip>
                        </IonCol>
                    </IonRow>
                </IonHeader>
                <form className="form-delete-body" onSubmit={handleSubmit}>
                    <IonRow>
                        <IonCol size="11">
                            <IonLabel className="delete-text-bold">
                                {USER_MGMT_UNABLE_DELETE_MSG}
                            </IonLabel>
                            <IonLabel
                                color="danger"
                                className="delete-name"
                            >
                                {selectedUser?.firstName}
                            </IonLabel>
                            <span>{" - "}</span>
                            <IonLabel
                                color="danger"
                                className="delete-name"
                            >
                                {selectedUser?.email}
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                </form>
                <IonFooter className="footer">
                    <IonRow>
                        <IonCol offset="3 " sizeMd="4">
                            <IonButton
                                color="danger"
                                className="unable-ok-btn"
                                onClick={okBtn}
                            >
                                {GLOBAL_OK_BUTTON}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonFooter>
            </IonModal>
        </>
    );
};