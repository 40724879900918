// vendors
import React, { useEffect } from "react";
import { IonCol, IonRow, IonSpinner, isPlatform } from "@ionic/react";
//components
import ToastrHook from '../../components/common/toastr/Toastr';
import FooterComponent from "../../components/FooterComponent";
import OnboardingLeftSection from "../../components/OnboardingProcess/SignupLeftSection";
//constant
import { onboarding } from "../../constants/constant";

const OnboardingContainer: React.FC<any> = (props) => {
    const { RightSectionComponent, submitHandler, title, secondaryTitle, requestPending, errors, isSkip, isBackButton, backButtonHandler } = props;
    const [renderToastr] = ToastrHook();
    const ismobile = isPlatform('mobile');
    const istablet = isPlatform('tablet');
    const isdesktop = isPlatform('desktop');
    //toaster for network calls
    useEffect(() => {
        errors !== null && requestPending === false && renderToastr(errors.error, 'danger');
    }, [errors, requestPending]);

    return (
        <IonRow className="h-100">
            {(ismobile || istablet) ?
                <IonCol className="ion-no-padding">
                    <IonRow>
                        <OnboardingLeftSection title={title} secondaryTitle={secondaryTitle} colSize="12" />
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-no-padding">
                            {requestPending ? <IonSpinner name="circles" className="spinner-circles" /> : RightSectionComponent()}
                            <FooterComponent
                                isBackButton={isBackButton}
                                submitCallBack={submitHandler}
                                classReverseFlex='reverse-flex'
                                backButtonHandler={backButtonHandler}
                                footerRowClassName='onboarding-footer'
                                disableButton={requestPending || errors !== null}
                                backButtonLabel={isBackButton && onboarding.RIGHT_SIDE_BACK}
                                footerRowMobileClassName='global-mobile-footer-btn-onboarding'
                                buttonLabel={isSkip ? onboarding.RIGHT_SIDE_NEXT_SKIP : onboarding.RIGHT_SIDE_NEXT}
                            />
                        </IonCol>
                    </IonRow>
                </IonCol> :
                <>
                    <OnboardingLeftSection title={title} secondaryTitle={secondaryTitle} colSize="6" />
                    <IonCol size="6" size-sm="6" size-md="6" size-lg="6" className="ion-no-padding">
                        {requestPending ? <IonSpinner name="circles" className="spinner-circles" /> : RightSectionComponent()}
                        <FooterComponent
                            isBackButton={isBackButton}
                            submitCallBack={submitHandler}
                            classReverseFlex='reverse-flex'
                            backButtonHandler={backButtonHandler}
                            footerRowClassName='onboarding-footer'
                            footerColClassName='onboarding-footer-col'
                            disableButton={requestPending || errors !== null}
                            backButtonLabel={isBackButton && onboarding.RIGHT_SIDE_BACK}
                            buttonLabel={isSkip ? onboarding.RIGHT_SIDE_NEXT_SKIP : onboarding.RIGHT_SIDE_NEXT}
                        />
                    </IonCol>
                </>
            }
        </IonRow>
    )
}

export default OnboardingContainer;