import React, { memo } from 'react';

import {
    IonHeader,
  } from '@ionic/react';

import Header from '../../ManageColumns/Header';
import { GLOBAL_FILTER_FOR_PRODUCT } from '../../../../constants/constant'
interface ProductFilterHeaderProps {
    onCloseFilterModal: () => void;
}

export const ProductFilterHeader: React.FC<ProductFilterHeaderProps> = memo((props) => {
  return (
    <IonHeader className="header product-filter">
          <Header
            headerTitle={GLOBAL_FILTER_FOR_PRODUCT}
            onCloseModal={props.onCloseFilterModal}
            filterIcon={false}
          />
    </IonHeader>
  );
});
