import { PostEmailUpdate, PostEmailUpdateSuccess, PostEmailUpdateFailure, PostEmailUpdateType }
    from './../actions/emailUpdateActions';
import { EmailUpdate } from "../../models/EmailUpdate";

export type emailUpdateTypes = PostEmailUpdateType;

export interface IEmailUpdate {
    pending: boolean;
    postEmailUpdate: EmailUpdate | null;
    emailUpdateError: Record<string, any> | null;
}
const initialState = {
    pending: false,
    postEmailUpdate: {} as EmailUpdate,
    emailUpdateError: null
};

const strategies = {
    POST_EMAIL_UPDATE_REQUEST: postEmailUpdate,
    POST_EMAIL_UPDATE_SUCCESS: postEmailUpdateSuccess,
    POST_EMAIL_UPDATE_FAILURE: postEmailUpdateFailure,
    default: (state: IEmailUpdate) => state,
};
function postEmailUpdate(state: IEmailUpdate, action: PostEmailUpdate) {
    return { ...state, pending: true, postEmailUpdate: null, emailUpdateError: null }
}

function postEmailUpdateSuccess(state: IEmailUpdate, action: PostEmailUpdateSuccess) {
    return { ...state, pending: false, postEmailUpdate: action.response, emailUpdateError: null }
}

function postEmailUpdateFailure(state: IEmailUpdate, action: PostEmailUpdateFailure) {
    return { ...state, emailUpdateError: action.error, pending: false, postEmailUpdate: null }
}

export default (state: IEmailUpdate = initialState, action: emailUpdateTypes): IEmailUpdate => {
    return (strategies[action.type] || strategies.default)(state, action as never);
};