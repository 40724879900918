// vendors
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonItem,
    IonLabel,
    IonRow,
    isPlatform,
    useIonRouter,
} from "@ionic/react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    IgrDataGrid,
    IgrDataGridCellEventArgs,
    IgrNumericColumn,
    IgrTemplateCellInfo,
    IgrTemplateColumn,
    IgrTextColumn,
    IIgrCellTemplateProps,
} from "igniteui-react-grids";

import { RootState } from "../../_redux/reducers/rootReducer";
import { Pagination } from "../../components/Pagination";

import { Routes } from "../../constants/Routes";
import { ClientPortal, Hotel365Portal, CONSUMER_LOCATION_LABEL, reportProductPricingColumns, CONSUMER_BALANCE_LABEL, CONSUMER_GMA_EMAIL_VERIFIED_LABEL, CONSUMER_HASAPP_LABEL, CONSUMER_LAST_ACTIVITY_LABEL, CONSUMER_PAYROLL_GROUP, CONSUMER_PENDING_EMAIL_UNVERIFIED_LABEL, CONSUMER_SUBSIDY_GROUP, FINGERPRINT_LABEL, GLOBAL_FIRST_NAME, GLOBAL_LAST_NAME } from "../../constants/constant";
import getSymbolFromCurrencyCode from "../../i18n/currency-symbol-map";
import {
    fetchConsumerBalances, fetchConsumerIdentifiers, fetchPayrollGrp, fetchSubsidyGrp, resetFetchConsumers,
} from "../../_redux/actions/consumerActions";

const colNameMapping = {
    accountId: "",
    locationName: CONSUMER_LOCATION_LABEL,
    firstName: GLOBAL_FIRST_NAME,
    lastName: GLOBAL_LAST_NAME,
    email: CONSUMER_GMA_EMAIL_VERIFIED_LABEL,
    unverifiedEmail: CONSUMER_PENDING_EMAIL_UNVERIFIED_LABEL,
    lastUpdated: CONSUMER_LAST_ACTIVITY_LABEL,
    balance: CONSUMER_BALANCE_LABEL,
    scanCode: reportProductPricingColumns.scancode,
    hasFingerPrint: FINGERPRINT_LABEL,
    subsidyGroupName: CONSUMER_SUBSIDY_GROUP,
    payrollDeductGroup: CONSUMER_PAYROLL_GROUP,
    hasApp: CONSUMER_HASAPP_LABEL,
};

export const ConsumerGrid: React.FC<any> = (props) => {
    let _grid: IgrDataGrid;
    const router = useIonRouter();
    const dispatch = useDispatch();
    const originalColList = { ...colNameMapping } as any;
    const [selectedConsumer, setSelectedConsumer] = useState({} as Record<string, any>);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [consumerList, setConsumerList] = useState(
        [] as Record<string, any>[]
    );
    const { nonHiddenColumns, consumerSelectRow } = props;
    const consumerLst = useSelector((state: RootState) => {
        return state.consumer;
    }, shallowEqual);
    const {
        error: consumerErr,
        consumerLists,
        pending: consumerPending,
        consumerPaginate,
        consumerChecked,
        consumerBalances,
        consumerIdentifiers,
        consumerListReq
    } = consumerLst;
    const profile = useSelector((state: RootState) => state.profile, shallowEqual);
    const { currentProfile } = profile;
    const { currency } = currentProfile;
    const [checked, setChecked] = useState([] as string[]);
    const isDesktopView = isPlatform("desktop");
    const isTabletView = isPlatform("tablet");
    const isMobileView = isPlatform("mobile");
    const onGridRef = (grid: IgrDataGrid) => {
        if (!grid) {
            return;
        }
        _grid = grid;
    };
    useEffect(() => {
        setConsumerList([]);
    }, [consumerSelectRow]);


    useEffect(() => {
        if (selectedConsumer.accountId) {
            consumerListReq && Object.keys(consumerListReq).length > 0 && consumerListReq.searchBy !== 'accountid' && localStorage.setItem('initListReq', JSON.stringify(consumerListReq))
            setTimeout(() => {
                router.push(Routes.dashboard.consumerDetailUrl);
                setSelectedConsumer({})
            }, 300);
        }
    }, [selectedConsumer.accountId, dispatch]);


    useEffect(() => {
        setCurrentPage(consumerPaginate.currentPage);
    }, [consumerPaginate]);

    useEffect(() => {
        /** if the consumer has not checked, then reset the consumer list grid */
        if (consumerChecked.length === 0) {
            setConsumerList([]);
        }
        let updatedCheckedConsumer: Record<string, any>[] = [];
        if (props.consumers) {
            updatedCheckedConsumer = consumerLists.map((item) => {
                item.isChecked = props.consumers.indexOf(item.accountId) > -1 ? true : false;
                return item;
            })
        }
        setTimeout(() => {
            _grid.flush();
            setChecked(consumerChecked);
            setConsumerList(updatedCheckedConsumer.length > 0 ? updatedCheckedConsumer : consumerLists);
        }, 10);
    }, [setChecked, consumerLists, consumerChecked]);

    const selectedColumnsTextColor = (i: string) => {
        return Object.values(ClientPortal.consumerFnameLnameVal).indexOf(i) > -1
            ? Hotel365Portal.defaultPrimaryColor
            : Hotel365Portal.gridTextValueColorCode;
    };

    const checkColumnHidden = (i: string) => {
        return nonHiddenColumns.length > 0
            ? nonHiddenColumns.indexOf(i) > -1
                ? false
                : true
            : true;
    };

    const checkWidth = (i: string) => {
        if (Object.values(ClientPortal.consumerGridEmailVal).includes(i)) return "*>240";
        else return "*>150";
    };

    const renderOtherColumns = (i: string) => {
        return (
            <IgrTextColumn
                field={i}
                key={i}
                width={checkWidth(i)}
                horizontalAlignment={i === ClientPortal.consumerProductLabel ? "left" : "center"}
                headerText={originalColList[i] || i}
                isFilteringEnabled={false}
                isColumnOptionsSummariesEnabled={false}
                isEditable={false}
                isHidden={checkColumnHidden(i)}
                textColor={selectedColumnsTextColor(i)}
                name={i}
            />
        );
    };

    const customCheckBox = (props: IIgrCellTemplateProps) => {
        return (
            <div className="align-checkbox">
                <IonCheckbox
                    checked={props.dataContext.rowItem.isChecked}
                    disabled={consumerSelectRow === 'all'}
                    onIonChange={(e) => {
                        checkedConsumer(props.dataContext as any)
                    }}
                />
            </div>
        );
    };

    const renderCheckBox = (i: string) => {
        return (
            <IgrTemplateColumn
                field={i}
                key={i}
                horizontalAlignment={"center"}
                template={(props: IIgrCellTemplateProps) =>
                    customCheckBox(props)
                }
                width="60"
                headerText={""}
                isFilteringEnabled={false}
                isHidden={false}
                isColumnOptionsEnabled={false}
                isColumnOptionsSummariesEnabled={false}
                name={i}
            />
        );
    };
    const formatCostTotalColumn = () => {
        /**
         * to show the format with 2 decimal points
         */
        return new Intl.NumberFormat(navigator.language, {
            style: "currency",
            currency: currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    const renderBalanceCurrency = (i: string) => {
        return (
            <IgrNumericColumn
                field={i}
                key={i}
                horizontalAlignment={"center"}
                headerText={originalColList[i] || i}
                isFilteringEnabled={false}
                isColumnOptionsSummariesEnabled={false}
                isEditable={false}
                isHidden={checkColumnHidden(i)}
                isColumnOptionsEnabled={false}
                name={i}
                positivePrefix={getSymbolFromCurrencyCode(currency)}
                formatOverride={formatCostTotalColumn()}
            />
        );
    };

    const renderCols = () => {
        const arr = [];
        for (let i in colNameMapping) {
            // @ts-ignore
            arr.push(
                ["accountId"].indexOf(i) > -1
                    ? renderCheckBox(i)
                    : ["balance"].indexOf(i) > -1
                        ? renderBalanceCurrency(i)
                        : renderOtherColumns(i)
            );
        }
        return arr;
    };
    const checkedConsumer = (event: IgrTemplateCellInfo) => {
        if (consumerSelectRow === 'all') {
            return false;
        }
        const rowItem = event.rowItem;
        const seletedValues = Object.assign([], checked); // to Clone an Array
        if (seletedValues.length > 0) {
            const isPresent = seletedValues.some(
                (item: string) => item === rowItem.accountId
            );
            if (isPresent) {
                seletedValues.splice(
                    seletedValues.indexOf(rowItem.accountId),
                    1
                );
            } else {
                seletedValues.push(rowItem.accountId);
            }
        } else {
            seletedValues.push(rowItem.accountId);
        }
        setChecked(seletedValues);
        /**
         * To update the bulk action dropdown for show/hide the values
         */
        props.getSelectedConsumers(seletedValues);
    };

    const selectedRow = (gridData: any, event: IgrDataGridCellEventArgs) => {
        if (
            Object.values(ClientPortal.consumerFnameLnameVal).indexOf(gridData._implementation.dm?.c) >
            -1
        ) {
            localStorage.setItem('detailConsumer', JSON.stringify(event.cellInfo.rowItem));
            props.getSelectedConsumers([]);
            setSelectedConsumer(prevState => {
                return { ...prevState, ...event.cellInfo.rowItem };
            });
        }
    };

    useEffect(() => {
        if (selectedConsumer && Object.keys(selectedConsumer).length > 0) {
            dispatch(resetFetchConsumers("resetResendemail"));
        }
    }, [selectedConsumer])

    useEffect(() => {
        // unmount the component when route changes
        return () => {
            setSelectedConsumer({});
        };
    }, [location.pathname]);


    // @ts-ignore
    return (
        <React.Fragment>
            <div className={isDesktopView || isTabletView ? "container sample" : ""} key="market-product-list">
                {isMobileView && <br />}
                <IonRow key="grid-container" className="consumer-row-container">
                    <IgrDataGrid
                        ref={onGridRef}
                        height={isDesktopView || isTabletView ? "calc(100% - 122px)" : "calc(100% - 60px)"}
                        width="100%"
                        defaultColumnMinWidth={100}
                        autoGenerateColumns={false}
                        dataSource={consumerList}
                        selectionMode="SingleCell"
                        activationMode="Cell"
                        editMode="CellBatch"
                        cellClicked={(
                            s: IgrDataGrid,
                            e: IgrDataGridCellEventArgs
                        ) => selectedRow(s, e)}
                        isColumnOptionsEnabled="true"
                        headerBackground="#635D5D"
                        headerTextColor="#ffffff"
                        autoAcceptEdits={false}
                        headerTextStyle="Normal Normal 12px Roboto"
                        cellTextStyle="Normal Normal 14px Roboto"
                        columnMovingMode={"NONE"}
                    >
                        {renderCols()}
                    </IgrDataGrid>
                    {consumerList.length === 0 && (
                        <IonRow className="grid-no-result">
                            <IonCol
                                size="12"
                                sizeXs="12"
                                className={
                                    !isDesktopView && !isTabletView
                                        ? "no-orders-mobile"
                                        : "no-orders-label"
                                }
                            >
                                <IonItem lines="none">
                                    <IonLabel>
                                        <b>{ClientPortal.consumer_grid_no_result_txt}</b>
                                    </IonLabel>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    )}
                </IonRow>
                {consumerList.length > 0 && (
                    <div className={isDesktopView || isTabletView ? "consumer-pagination-section" : ""}>
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={consumerPaginate.totalCount}
                            pageSize={consumerPaginate.limit}
                            onPageChange={(page: number) => {
                                props.setConsumerSelectRow("");
                                if (consumerSelectRow === "all") {
                                    props.getSelectedConsumers([]);
                                }
                                setCurrentPage(page);
                                props.updatePaginate(page);
                            }}
                        />
                    </div>
                )}


            </div>
        </React.Fragment>
    );
};

export default ConsumerGrid;
