// vendors
import { DateTime } from 'luxon';
import React, { useState } from "react";
import { IonPage, IonRow, isPlatform } from "@ionic/react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
//Actions
import { postReportRequest, runReport } from "../../_redux/actions/reportsActions";
//components
import ReportsSidePanel from "./ReportsSidePanel";
import ReportContent from "./ReportContent";
//utils
import { getReportPayload, getCommaSeparatedValues } from '../../utils';
//reducers
import { RootState } from '../../_redux/reducers/rootReducer';


export const Reports: React.FC = () => {
    const dispatch = useDispatch();
    const [showSidePanel, setShowSidePanel] = useState(true);
    const [showReportList, setShowReportList] = useState(true);
    const [selectedReport, setSelectedReport] = useState({ name: '', id: '' });

    const userProfile = useSelector((state: RootState) => { return state.profile; }, shallowEqual);
    const { currentProfile } = userProfile;
    const { locationId, orgId } = currentProfile;
    const isMobile = isPlatform("mobile");
    const isTablet = isPlatform("tablet");

    const backToReportsList = () => {
        setShowReportList(true);
        isMobile && setShowSidePanel(true);
        setSelectedReport({ name: '', id: '' });
        dispatch(runReport(false));
    };

    const runReportCallBack = (filterObject: any) => {
        const { dateRangeDates, timeRange, categories } = filterObject;
        const { fromDate, toDate } = dateRangeDates;
        const { fromTime, toTime } = timeRange;
        const fromDateFormat = fromDate && DateTime.fromJSDate(new Date(fromDate)).toFormat('yyyy-MM-dd');
        const toDateFormat = toDate && DateTime.fromJSDate(new Date(toDate)).toFormat('yyyy-MM-dd');
        const payload = getReportPayload(selectedReport.id, {
            locationId,
            endDate: `${toDateFormat} ${toTime}:00.0`,
            startDate: `${fromDateFormat} ${fromTime}:00.0`,
            category: getCommaSeparatedValues(categories),
            orgID: orgId
        });
        //@ts-ignore
        dispatch(postReportRequest(payload, selectedReport.id));
        dispatch(runReport(true));
        isMobile && !isTablet && setShowSidePanel(false);
    }

    return (
        <IonPage>
            <IonRow>
                {showSidePanel &&
                    <ReportsSidePanel
                        runReport={runReportCallBack}
                        showReportList={showReportList}
                        setSelectedReport={setSelectedReport}
                        setShowReportList={setShowReportList}
                    />
                }
                <ReportContent
                    showSidePanel={showSidePanel}
                    selectedReport={selectedReport}
                    setShowSidePanel={setShowSidePanel}
                    backToReportsList={backToReportsList}
                />
            </IonRow>
        </IonPage>
    );
};

export default Reports;

