import {
    FetchGlobalMargin,
    FetchGlobalMarginFailure,
    FetchGlobalMarginSuccess,
    FetchGlobalMarginType,
    SaveGlobalMargin,
    SaveGlobalMarginFailure,
    SaveGlobalMarginSuccess,
    SaveGlobalMarginType,
    PatchGlobalMargin,
    PatchGlobalMarginFailure,
    PatchGlobalMarginSuccess,
    PatchGlobalMarginType,
    FetchCategoryMargin,
    FetchCategoryMarginFailure,
    FetchCategoryMarginSuccess,
    FetchCategoryMarginType,
    SaveCategoryMargin,
    SaveCategoryMarginFailure,
    SaveCategoryMarginSuccess,
    SaveCategoryMarginType,
    PatchCategoryMargin,
    PatchCategoryMarginFailure,
    PatchCategoryMarginSuccess,
    PatchCategoryMarginType,
    ResetProductsSettingsPanelType,
    ResetProductsSettingsPanel,
    ResetMarginsType,
    ResetMargins,
} from "../actions/productSettingsPanelActions";

import { GlobalMargin } from "../../models/GlobalMargin";
import {
    Category,
    CategoryMargin,
    CategoryMarginPagination,
} from "../../models/CategoryMargin";

export type ProductSettingsPanelTypes =
    | FetchGlobalMarginType
    | SaveGlobalMarginType
    | PatchGlobalMarginType
    | FetchCategoryMarginType
    | SaveCategoryMarginType
    | PatchCategoryMarginType
    |ResetProductsSettingsPanelType
    | ResetMarginsType;

const initialState = {
    pending: true,
    error: null,
    globalMargin: {} as GlobalMargin,
    createdGlobalMargin: {} as GlobalMargin,
    patchedGlobalMargin: {} as GlobalMargin,
    categoryMargin: [] as CategoryMargin["items"],
    categoryMarginPaginate: {} as CategoryMarginPagination,
    createdCategoryMargin: {} as Category,
    patchedCategoryMargin: {} as Category,
};
export interface IProductSettingsPanelState {
    pending: boolean;
    error: Record<string, any> | null;
    globalMargin: GlobalMargin;
    createdGlobalMargin: GlobalMargin;
    patchedGlobalMargin: GlobalMargin;
    categoryMargin: CategoryMargin["items"];
    categoryMarginPaginate: CategoryMarginPagination;
    createdCategoryMargin: Category;
    patchedCategoryMargin: Category;
}

const strategies = {
    FETCH_GLOBAL_MARGIN_REQUEST: fetchProductSettingsPanel,
    FETCH_GLOBAL_MARGIN_SUCCESS: fetchGlobalMarginSuccess,
    FETCH_GLOBAL_MARGIN_FAILURE: fetchProductSettingsPanelFailure,
    PATCH_GLOBAL_MARGIN_REQUEST: fetchProductSettingsPanel,
    PATCH_GLOBAL_MARGIN_SUCCESS: patchGlobalMarginSuccess,
    PATCH_GLOBAL_MARGIN_FAILURE: fetchProductSettingsPanelFailure,
    SAVE_GLOBAL_MARGIN_REQUEST: fetchProductSettingsPanel,
    SAVE_GLOBAL_MARGIN_SUCCESS: saveGlobalMargin,
    SAVE_GLOBAL_MARGIN_FAILURE: fetchProductSettingsPanelFailure,
    FETCH_CATEGORY_MARGIN_REQUEST: fetchProductSettingsPanel,
    FETCH_CATEGORY_MARGIN_SUCCESS: fetchCategoryMargins,
    FETCH_CATEGORY_MARGIN_FAILURE: fetchProductSettingsPanelFailure,
    SAVE_CATEGORY_MARGIN_REQUEST: fetchProductSettingsPanel,
    SAVE_CATEGORY_MARGIN_SUCCESS: saveCategoryMargin,
    SAVE_CATEGORY_MARGIN_FAILURE: fetchProductSettingsPanelFailure,
    PATCH_CATEGORY_MARGIN_REQUEST: fetchProductSettingsPanel,
    PATCH_CATEGORY_MARGIN_SUCCESS: patchCatgeoryMargin,
    PATCH_CATEGORY_MARGIN_FAILURE: fetchProductSettingsPanelFailure,
    RESET_PRODUCT_SETTINGS_PANEL: resetProductSettingsPanel,
    RESET_MARGINS:resetMargins,
    default: (state: IProductSettingsPanelState) => state,
};

export default (
    state: IProductSettingsPanelState = initialState,
    action: ProductSettingsPanelTypes
): IProductSettingsPanelState => {
    return (strategies[action.type] || strategies.default)(
        state,
        action as never
    );
};

function fetchProductSettingsPanel(
    state: IProductSettingsPanelState,
    _action:
        | FetchGlobalMargin
        | SaveGlobalMargin
        | PatchGlobalMargin
        | FetchCategoryMargin
        | SaveCategoryMargin
        | PatchCategoryMargin
) {
    return {
        ...state,
        error: null,
    };
}


function fetchProductSettingsPanelFailure(
    state: IProductSettingsPanelState,
    action:
        | FetchGlobalMarginFailure
        | PatchGlobalMarginFailure
        | SaveGlobalMarginFailure
        | FetchCategoryMarginFailure
        | SaveCategoryMarginFailure
        | PatchCategoryMarginFailure
) {
    return {
        ...state,
        pending: false,
        error: action.error,
    };
}


function fetchGlobalMarginSuccess(
    state: IProductSettingsPanelState,
    action: FetchGlobalMarginSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        globalMargin: action.data,
        error: null,
    });
}


function patchGlobalMarginSuccess(
    state: IProductSettingsPanelState,
    action: PatchGlobalMarginSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        patchedGlobalMargin: action.data,
        createdGlobalMargin: {},
        error: null,
    });
}

function saveGlobalMargin(
    state: IProductSettingsPanelState,
    action: SaveGlobalMarginSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        createdGlobalMargin: action.data,
        patchedGlobalMargin: {},
        error: null,
    });
}

function patchCatgeoryMargin(
    state: IProductSettingsPanelState,
    action: PatchCategoryMarginSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        patchedCategoryMargin: action.data,
        error: null,
    });
}

function saveCategoryMargin(
    state: IProductSettingsPanelState,
    action: SaveCategoryMarginSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        createdCategoryMargin: action.data,
        error: null,
    });
}

function fetchCategoryMargins(
    state: IProductSettingsPanelState,
    action: FetchCategoryMarginSuccess
) {
    let margins: any[] = [];
    /**to Avoid duplicates from an array */
    margins = state.categoryMargin
        .filter(
            (_reduxCategoryMargin) =>
                !action.data.items.find(
                    (_incomingResponse) => _reduxCategoryMargin["category"] === _incomingResponse["category"]
                )
        )
        .concat(action.data.items);
    margins.map((item: any) => {
        item.margin =
            item.margin || item.margin === 0 ? item.margin + "%" : "0%";
        item.margin = item.margin.replaceAll("%", "") + "%";
        return item;
    });
    return {
        ...state,
        pending: false,
        categoryMargin: margins,
        categoryMarginPaginate: action.data.metadata.pagination,
        error: null,
    };
}
function resetProductSettingsPanel(
    state: IProductSettingsPanelState,
    action: ResetProductsSettingsPanel
) {
    return Object.assign({}, state, {
        ...state,
        patchedGlobalMargin: {} as GlobalMargin,
        createdGlobalMargin: {} as GlobalMargin,
        createdCategoryMargin: {} as Category,
        patchedCategoryMargin: {} as Category,
        error:null
    });
}

function resetMargins(
    state: IProductSettingsPanelState,
    action: ResetMargins
) {
    return Object.assign({}, state, {
        ...state,
        globalMargin: {} as GlobalMargin,
        categoryMargin: [] as CategoryMargin["items"],
        error:null
    });
}