import React from 'react';

import {
  IonImg,
  useIonRouter
} from "@ionic/react";

import { LoginDescriberData } from '../../../models/UserProfile';

export function imageDescriptor(props:LoginDescriberData): JSX.Element {
  return <IonImg src={'/assets/logo.svg'} onClick={props.checkLogoClick}/>;
}
export function routeDescriptor(props:LoginDescriberData): void {
  props.redirectToProducts();
}
export default {
  imageDescriptor,
  routeDescriptor
};
