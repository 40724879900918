//vendors
import React, { useEffect, useMemo, useState } from "react";
import { IonCol, IonRow, IonLabel, IonSpinner, isPlatform, IonLoading } from "@ionic/react";
import { useHistory, useParams } from "react-router";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
//actions
import { deleteCardFlow, fetchSysUserTokensRequest } from "../../_redux/actions/paymentActions";
//components
import Footer from './Footer';
import DeleteModal from "./DeleteModal";
import ToastrHook from '../../components/common/toastr/Toastr';
import DisplayError from "./DisplayError";
//reducers
import { RootState } from '../../_redux/reducers/rootReducer';
//constants
import { paymentConstants, CARD_DELETED_SUCCESS, DELETE_CARD_FAILURE, LOADER_MSG } from "./constants";
import { Routes } from "../../constants/Routes";

const PaymentDetail: React.FC<any> = ({ systemUserId, locationId, orgId }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [renderToastr] = ToastrHook();
    //@ts-ignore
    const { systemUserTokenId } = useParams();
    const [cardDeleteModal, setCardDeleteModal] = useState(false);
    const [isDeleteCardClicked, setIsDeleteCardClicked] = useState(false);
    const payment = useSelector((state: RootState) => { return state.payment; }, shallowEqual);
    const { availableCards, deleteCard } = payment;
    const { cards, pending, error } = availableCards;
    const { pending: deleteCardPending, error: deleteCardError } = deleteCard;
    const istablet = isPlatform("tablet");

    const backToCardsList = () => {
        history.push(`${Routes.dashboard.paymentUrl}`);
    }
    const onDetachCard = (systemUserTokenId: string) => {
        setIsDeleteCardClicked(true);
        dispatch(deleteCardFlow({
            "locationId": locationId,
            "orgId": orgId,
            "systemUserId": systemUserId,
            systemUserTokenId
        }));
        setCardDeleteModal(false);
    };

    const cardDetails = useMemo(() => {
        return cards.length !== 0 && cards.find((card: Record<string, any>) => {
            const { systemUserTokenId: cardSysTokenId } = card;
            return systemUserTokenId === cardSysTokenId && card;
        });
    }, [systemUserTokenId, cards, pending, error]);

    const displayDetails = () => {
        if (pending) {
            return <IonSpinner name="circles" className={istablet ? "spinner-circles-tablet" : 'spinner-circles'} />
        } else {
            if (cardDetails && error === null) {
                const { cardHolder, expirationMonth, expirationYear, name: cardNumber, postalCode, systemUserTokenId: cardSysTokenId } = cardDetails;
                return (
                    <>
                        <IonRow className="ion-padding-start ion-padding-end">
                            <IonCol>
                                <IonRow>
                                    <IonCol>
                                        <h2 className="payment-info-header">
                                            <b>{paymentConstants.PAYMENT_INFO}</b>
                                        </h2>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <h5>
                                            <b>{paymentConstants.CREDIT_CARD_INFO}</b>
                                        </h5>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size-lg='3' size-xs='10' size-md='3' className="input-name">
                                        <IonLabel>{paymentConstants.NAME_ON_CARD}</IonLabel>
                                        <IonRow>
                                            <IonCol>
                                                <h6>{cardHolder}</h6>
                                            </IonCol>
                                        </IonRow>
                                    </IonCol>
                                    <IonCol size-lg='3' size-xs='10' size-md='3' className="input-name">
                                        <IonLabel>{paymentConstants.CARD_NUMBER}</IonLabel>
                                        <IonRow>
                                            <IonCol>
                                                <h6>{cardNumber}</h6>
                                            </IonCol>
                                        </IonRow>
                                    </IonCol>
                                    <IonCol size-lg='3' size-xs='10' size-md='3' className="input-name">
                                        <IonLabel>
                                            {paymentConstants.BILLING_ZIP_CODE}
                                        </IonLabel>
                                        <IonRow>
                                            <IonCol>
                                                <h6>{postalCode}</h6>
                                            </IonCol>
                                        </IonRow>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size-lg='3' size-xs='5' size-md='3' className="input-name-row">
                                        <IonLabel>{paymentConstants.PAYMENT_MONTH}</IonLabel>
                                        <IonRow>
                                            <IonCol>
                                                <h6>{expirationMonth}</h6>
                                            </IonCol>
                                        </IonRow>
                                    </IonCol>
                                    <IonCol size-lg='3' className="input-name-row-1">
                                        <IonLabel>{paymentConstants.PAYMENT_YEAR}</IonLabel>
                                        <IonRow>
                                            <IonCol>
                                                <h6>{expirationYear}</h6>
                                            </IonCol>
                                        </IonRow>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                        <Footer
                            showDeleteCardBtn={true}
                            onCancel={() => { history.push(`${Routes.dashboard.paymentUrl}`) }}
                            detachCard={() => setCardDeleteModal(true)}
                        />
                        <DeleteModal
                            onConfirm={() => { onDetachCard(systemUserTokenId) }}
                            cardDeleteModal={cardDeleteModal}
                            setCardDeleteModal={setCardDeleteModal}
                        />
                    </>
                )
            } else if (error !== null) {
                return <DisplayError message={paymentConstants.FETCH_CARD_DETAILS} onClickCallBack={() => { window.location.reload() }} btnLabel={paymentConstants.TRY_AGAIN_BUTTON} />
            } else if (!cardDetails) {
                return (<DisplayError message={paymentConstants.MISSING_CARD_DETAILS} onClickCallBack={backToCardsList} btnLabel={paymentConstants.BACK_BUTTON} />)
            }
        }
    }

    useEffect(() => {
        cards.length === 0 && dispatch(fetchSysUserTokensRequest(systemUserId));
    }, [systemUserTokenId]);

    //to show toaster messages for success and failure of delete card and redirection
    useEffect(() => {
        if (isDeleteCardClicked && !deleteCardPending && deleteCardError === null) {
            setIsDeleteCardClicked(false);
            renderToastr(CARD_DELETED_SUCCESS, 'success', 5000);
            backToCardsList();
        } else if (isDeleteCardClicked && !deleteCardPending && deleteCardError !== null) {
            setIsDeleteCardClicked(false);
            renderToastr(DELETE_CARD_FAILURE, 'danger', 5000);
            backToCardsList();
        }
    }, [deleteCardPending, deleteCardError]);

    return (
        <>
            {isDeleteCardClicked &&
                <IonLoading
                    cssClass="my-custom-class"
                    isOpen={isDeleteCardClicked}
                    message={LOADER_MSG}
                />
            }
            {displayDetails()}
        </>
    );
};

export default PaymentDetail;

