// vendors
import { IonRouterOutlet } from "@ionic/react";
import { Route } from "react-router-dom";
// constants
import { Routes } from "../../../constants/Routes";
//components
import Profile from "../../profile";
 import Payment from "../../Payment";
import ConsumerList from "../../Consumer/ConsumerList"
import ConsumerDetail from "../../Consumer/ConsumerDetail"
import ErrorComponent from "../../../components/ErrorComponent";
import UserManagement from "../../UserManagement/UserManagement";


export function routeDescriptor(): JSX.Element {
  return (
    <IonRouterOutlet animated={false} className="ion-no-padding">
      <Route exact path={Routes.dashboard.url} component={ConsumerList} />
      <Route exact path={Routes.dashboard.consumerListUrl} component={ConsumerList} />
      <Route exact path={Routes.dashboard.consumerDetailUrl} component={ConsumerDetail} />
      <Route exact path={Routes.dashboard.paymentUrl} component={Payment} />
      <Route exact path={`${Routes.dashboard.paymentUrl}/:id`} component={Payment} />
      <Route exact path={`${Routes.dashboard.paymentDetails}/:id`} component={Payment} />
      <Route exact path={Routes.userManagement.url} component={UserManagement} />
      <Route exact path={Routes.profile.url} component={Profile} />
      <Route render={() => <ErrorComponent isFallback={true} />} />
    </IonRouterOutlet>
  );
}
export default { routeDescriptor };
