import { all, put, takeLatest } from "redux-saga/effects";
import { doGet, doPatch, doPost } from '../../../utils/fetchWrapper';
import {
    fetchProfileSuccess,
    fetchProfileFailure,
    fetchProfilesSuccessWithSysUserId,
    fetchProfilesFailureWithSysUserId,
    patchProfileSuccess,
    patchProfileFailure,
    postProfileSuccess,
    postProfileFailure
} from "../../actions/profileActions";
import * as PROFILE from '../../ActionTypes/profileTypes';
import { Routes } from '../../../constants/Routes';
import { USER_PROFILES_FETCH_ERROR } from '../../../constants/constant';

function* fetchProfile(action: any) {
    const { locationId } = action;
    try {
        const url = `${Routes.marketProfile.url}${locationId}`;
        // @ts-ignore
        const response = yield doGet(url);
        delete response['status'];
        yield put(fetchProfileSuccess({ ...response }))
    } catch (e: any) {
        yield put(fetchProfileFailure({ error: e }));
    }
}

function* fetchProfileWithSysUserId(action: any) {
    const { systemUserId } = action
    try {
        const url = `${Routes.marketUsers.url}/${systemUserId}/market-profiles`;
        // @ts-ignore
        const response = yield doGet(url);
        delete response['status'];
        yield put(fetchProfilesSuccessWithSysUserId({ ...response }));
    } catch (e: any) {
        const updatedError = {
            type: USER_PROFILES_FETCH_ERROR,
            errorMessage: `Error while fetching data, please try after some time`
        }
        yield put(fetchProfilesFailureWithSysUserId({ error: updatedError }));
    }
}

function* patchProfile(action: any) {
    const { type, locationId } = action;
    let url = `${Routes.marketProfile.url}${locationId}`;
    const request = { ...action.data };
    try {
        // @ts-ignore
        const response = yield doPatch(url, request);
        // on success update redux store with complete request object
        yield put(patchProfileSuccess(request));
    } catch (error: any) {
        yield put(patchProfileFailure(error));
    }
}

function* postProfile(action: any) {
    const { locationId, initialStepOfPostCall } = action;
    let url = `${Routes.marketProfile.url}${locationId}`;
    const request = { ...action.data };
    try {
        // @ts-ignore
        const response = yield doPost(url, request);
        const updatedResponse = { ...response, onboardingStep: initialStepOfPostCall }
        yield put(postProfileSuccess(updatedResponse));
    } catch (error: any) {
        yield put(postProfileFailure(error));
    }
}

function* MarketProfileSaga() {
    yield all([
        takeLatest(PROFILE.FETCH_PROFILE_REQUEST, fetchProfile),
        takeLatest(PROFILE.FETCH_PROFILES_REQUEST_WITH_SYS_USER_ID, fetchProfileWithSysUserId),
        takeLatest(PROFILE.POST_PROFILE, postProfile),
        takeLatest(PROFILE.PATCH_PROFILE, patchProfile)
    ])
}

export default MarketProfileSaga;