import React from "react";
import {
    IonButton,
    IonChip,
    IonCol,
    IonFooter,
    IonHeader,
    IonLabel,
    IonModal,
    IonRow,
    IonTitle,
} from "@ionic/react";
import { Hotel365Portal, GLOBAL_OK_BUTTON } from "../../constants/constant";

export const VerifyEmailModalForm: React.FC<any> = (props) => {
    const { openModal, closeModal, handleSubmit, verifiedEmail } = props;

    return (
        <>
            <div>
                <IonModal
                    isOpen={openModal}
                    className="modal-pop-email"
                    onDidDismiss={() => closeModal(false)}
                >
                    <IonHeader className="header">
                        <IonRow style={{ marginTop: 10 }}>
                            <IonTitle>{Hotel365Portal.VERIFY_EMAIL_PROMPT}</IonTitle>
                            <IonChip
                                color="primmary"
                                className="ion-button-email"
                                onClick={() => closeModal(false)}
                            >
                                <p className="cancel-email">&times;</p>
                            </IonChip>
                        </IonRow>
                    </IonHeader>
                    <form className="form-body-email" onSubmit={handleSubmit}>
                        <IonRow className="modal-body-email">
                            <IonCol offset="1"></IonCol>
                        </IonRow>
                        <IonRow className="modalRowContent-email">
                            <IonLabel className="input-headers-email">
                                {Hotel365Portal.VERIFY_EMAIL_PROMPT_SUCCESS_MSG}
                            </IonLabel>
                            <IonLabel className="email-text-email">
                                {verifiedEmail || ""}
                            </IonLabel>
                        </IonRow>
                    </form>
                    <IonFooter className="footer">
                        <IonRow className="btn-email-ok">
                            <IonCol sizeMd="4">
                                <IonButton
                                    color="primary"
                                    expand="block"
                                    type="submit"
                                    className="btn-text-cases-save-email"
                                    onClick={() => closeModal(false)}
                                >
                                    {GLOBAL_OK_BUTTON}
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonFooter>
                </IonModal>
            </div>
        </>
    );
};
