// vendors
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    IonAccordion,
    IonAccordionGroup,
    IonButton,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonChip,
    isPlatform,
} from "@ionic/react";
import { CreateOrderPrompt } from "./CreateOrderPrompt";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../_redux/reducers/rootReducer";
//actions
import {
    postMarketOrderHeaderRequest,
    postAutoOrderHeaderRequest,
    fetchMarketOrderHeader,
    fetchMarketOrder,
    resetMarketOrder,
} from "../../_redux/actions/orderActions";
import { groupBy } from "../../utils";
import { LOADING_MORE_DATA, NO_ORDER_SIDEPANEL_TEXT, ORDER_AUTO_BUTTON, ORDER_HEADER, ORDER_MANUAL_BUTTON } from '../../constants/constant';

export const ManagerOrderSidenav: React.FC<any> = (props) => {
    const { sidenavExpanded, setSidenavExpanded } = props
    const [openModal, setOpenModal] = useState(false);
    const [formErrors, setFormErrors] = useState({
        isValid: true,
        errorMessage: "",
    });
    const [orderNumber, setOrderNumber] = useState("");
    const [orderType, setOrderType] = useState("");
    const dispatch = useDispatch();
    const isMobileView = isPlatform("mobile");
    const isDesktopView = isPlatform("desktop");
    const isTabletView = isPlatform("tablet");
    const date = new Date();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const getDate =
        (("" + month).length < 2 ? "0" : "") +
        month +
        "-" +
        (("" + day).length < 2 ? "0" : "") +
        day +
        "-" +
        date.getFullYear();
    const [orderName, setOrderName] = useState(getDate);
    const profile = useSelector((state: RootState) => {
        return state.profile;
    }, shallowEqual);
    const { locationId: locationID, orgId } = profile && profile.currentProfile;

    const userDetails = useSelector((state: RootState) => {
        return state.systemUsers;
    }, shallowEqual);

    const order = useSelector((state: RootState) => state.order, shallowEqual);

    const {
        fetchMarketOrderHeaders,
        postMarketOrderHeader,
        postAutoOrderHeader,
        paginate,
        fetchMarketOrder: _fetchMarketOrder,
        patchMarketOrderProduct: updateMarketOrderProduct,
    } = order;
    const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);

    function createOrder(type: string) {
        setOpenModal(true);
        setOrderType(type);
    }
    const closeModal = () => {
        setOpenModal(false);
        setOrderName(getDate);
        setFormErrors({ isValid: true, errorMessage: "" });
    };

    const save = (orderNo: string) => {
        const { isValid } = formErrors;
        const request = {
            locationId: locationID,
            orderNumber: orderNo,
            orgId: orgId,
            systemUserId: userDetails.systemUserId,
        };
        if (isValid) {
            if (orderType === 'manual') dispatch(postMarketOrderHeaderRequest(request));
            else {
                delete request.orgId;
                dispatch(postAutoOrderHeaderRequest(request));
            }
        }
    };

    const checkPostMarketOrderHeader = useCallback(() => {
        return (
            postMarketOrderHeader &&
            Object.keys(postMarketOrderHeader).length > 0
        );
    }, [postMarketOrderHeader]);

    const checkPostAutoOrderHeader = useCallback(() => {
        return (
            postAutoOrderHeader &&
            Object.keys(postAutoOrderHeader).length > 0
        );
    }, [postAutoOrderHeader]);

    useEffect(() => {
        setOrderNumber("");
        (checkPostAutoOrderHeader() || checkPostMarketOrderHeader()) && closeModal();
    }, [postMarketOrderHeader, postAutoOrderHeader, dispatch]);

    useEffect(() => {
        if (_fetchMarketOrder && Object.keys(_fetchMarketOrder).length > 0)
            setOrderNumber(_fetchMarketOrder.marketOrderHeaderId || "");
    }, [_fetchMarketOrder]);

    const orders = useMemo(() => {
        let result: Record<string, any>[] = [];
        if (fetchMarketOrderHeaders.length > 0) {
            const modifiedOrderHeaderResponse = groupBy(
                fetchMarketOrderHeaders,
                "lastUpdated"
            );
            Object.keys(modifiedOrderHeaderResponse).map((item, i) => {
                result.push({
                    orderYear: item,
                    marketOrders: modifiedOrderHeaderResponse[item],
                });
            });
        } else {
            result.push({ orderYear: "Recent", marketOrders: [] });
        }
        const sortedOrder = [
            ...result
                .filter((n) => !Number(n.orderYear[0]))
                .sort((a, b) => a.orderYear[0].localeCompare(b.orderYear[0])),
            ...result.filter((p) => Number(p.orderYear[0])),
        ];
        return sortedOrder;
    }, [fetchMarketOrderHeaders]);

    const invokeDefaultMarketOrder = useCallback(() => {
        const filterRecentItem = orders.filter(
            (item) =>
                ["recent", "pending"].indexOf(item.orderYear.toLowerCase()) > -1
        )[0]?.marketOrders;
        if (!filterRecentItem) {
            dispatch(fetchMarketOrder(orders[0].marketOrders[0].marketOrderHeaderId));
        } else if (filterRecentItem && filterRecentItem.length > 0) {
            dispatch(fetchMarketOrder(filterRecentItem[0].marketOrderHeaderId));
        } else {
            dispatch(resetMarketOrder());
        }
    }, [fetchMarketOrderHeaders]);

    useEffect(() => {
        fetchMarketOrderHeaders.length > 0
            ? invokeDefaultMarketOrder()
            : dispatch(resetMarketOrder());
    }, [fetchMarketOrderHeaders]);

    const checkPatchMarketOrderProduct = useCallback(() => {
        return (
            updateMarketOrderProduct &&
            Object.keys(updateMarketOrderProduct).length > 0
        );
    }, [updateMarketOrderProduct]);

    useEffect(() => {
        const { marketOrderHeaderId } = updateMarketOrderProduct;
        if (checkPatchMarketOrderProduct()) {
            dispatch(resetMarketOrder());
            dispatch(fetchMarketOrder(marketOrderHeaderId as string));
        }
    }, [updateMarketOrderProduct]);

    const invokeOrderProducts = (orderNumberType: string) => {
        dispatch(resetMarketOrder());
        setOrderNumber(orderNumberType);
        dispatch(fetchMarketOrder(orderNumberType));
        if(isMobileView) {
            setSidenavExpanded(false)
        }
    };

    const handleScroll = (ev: any) => {
        setTimeout(() => {
            const offset: number = paginate.nextOffset;
            dispatch(fetchMarketOrderHeader(locationID, orgId, offset));
            ev.target.complete();
            if (fetchMarketOrderHeaders.length === paginate.totalCount) {
                setInfiniteDisabled(true);
            }
        }, 500);
    };
    return (
        <>
            <IonRow
                className={
                    sidenavExpanded
                        ? "padding-0 orders-left-panel settings-sidenav ion-padding"
                        : "hide-sidenav"}
            >
                <IonCol
                    size='3'
                    sizeMd={!isDesktopView ? '5' : '3'}
                    sizeXs={!isDesktopView && !isTabletView ? "12" : "3"}
                    sizeXl="auto"
                >
                    <IonRow className={!isDesktopView && !isMobileView ? "order-row-property" : ""}>
                        <IonCol size="3" sizeMd='1.9' sizeXs='1.5' sizeXl='1.8'>
                            <h4 className="header-text orders-title">
                                <b>{ORDER_HEADER}</b>
                            </h4>
                        </IonCol>
                        <IonCol size="8" sizeMd="4" sizeXs="8" className={!isDesktopView && !isMobileView ? "order-tablet-btns" : "order-btns"}>
                            <IonButton
                                size="small"
                                className={
                                    "ion-color md button button-solid ion-activatable ion-focusable ion-color-primary grid-btns order-btn-size"
                                }
                                color="primary"
                                fill="solid"
                                onClick={() => createOrder('manual')}
                            >
                                {ORDER_MANUAL_BUTTON}
                            </IonButton>
                            <IonButton
                                size="small"
                                className={
                                    "ion-color md button button-solid ion-activatable ion-focusable ion-color-primary grid-btns"
                                }
                                color="primary"
                                fill="solid"
                                onClick={() => createOrder('auto')}
                            >
                                {ORDER_AUTO_BUTTON}
                            </IonButton>
                        </IonCol>
                        <IonCol sizeXs='0.5'>
                            {!isDesktopView && !isTabletView ? <IonChip
                                color="primary"
                                className="ion-chip-sidenav"
                                onClick={() => {
                                    setSidenavExpanded(false);
                                }}
                            >
                                <span className="product-list-cancel-chip">
                                    &times;
                                </span>
                            </IonChip> : " "}
                        </IonCol>
                    </IonRow>
                    <IonRow className="row-width">
                        <IonCol>
                            <IonAccordionGroup className={isMobileView ? "order-number-rows order-number-row-tablet" : " row-horizontal"}>
                                {orders.length > 0 &&
                                    orders.map((res: Record<string, any>) => (
                                        <IonAccordion
                                            value={res.orderYear}
                                            className="accordion-bg solid"
                                            key={res.orderYear}
                                        >
                                            <IonItem
                                                slot="header"
                                                className="accordion-header"
                                            >
                                                <IonLabel>{res.orderYear}</IonLabel>
                                            </IonItem>

                                            <IonList
                                                slot="content"
                                                className="accordion-bg"
                                            >
                                                <IonRow className="row-width">
                                                    <IonCol className="m0" size="12">
                                                        {res.marketOrders.length ===
                                                            0 && (
                                                                <IonItem>
                                                                    <IonLabel className={isMobileView && !isTabletView ? "sub-item" : "sub-item-spacing"}>
                                                                        {NO_ORDER_SIDEPANEL_TEXT}
                                                                    </IonLabel>
                                                                </IonItem>
                                                            )}

                                                        {res.marketOrders.length >
                                                            0 &&
                                                            res.marketOrders.map(
                                                                (
                                                                    marketOrderType: Record<
                                                                        string,
                                                                        any
                                                                    >
                                                                ) => (
                                                                    <IonItem
                                                                        key={
                                                                            marketOrderType.marketOrderHeaderId
                                                                        }
                                                                        onClick={() =>
                                                                            invokeOrderProducts(
                                                                                marketOrderType.marketOrderHeaderId
                                                                            )
                                                                        }
                                                                        className={
                                                                            orderNumber ===
                                                                                marketOrderType.marketOrderHeaderId
                                                                                ? "active"
                                                                                : ""
                                                                        }
                                                                    >
                                                                        <IonLabel className="sub-item">
                                                                            {
                                                                                marketOrderType.orderNumber
                                                                            }
                                                                        </IonLabel>
                                                                    </IonItem>
                                                                )
                                                            )}
                                                    </IonCol>
                                                </IonRow>
                                            </IonList>
                                        </IonAccordion>
                                    ))}
                                <IonInfiniteScroll
                                    onIonInfinite={handleScroll}
                                    threshold="100px"
                                    disabled={isInfiniteDisabled}
                                >
                                    <IonInfiniteScrollContent
                                        loadingSpinner="bubbles"
                                        loadingText={LOADING_MORE_DATA}
                                    ></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonAccordionGroup>
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>
            <CreateOrderPrompt
                closeModal={closeModal}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
                orderName={orderName}
                setOrderName={setOrderName}
                openModal={openModal}
                save={save}
                getDate={getDate}
            />
        </>
    );
};

export default ManagerOrderSidenav;
