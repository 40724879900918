import React from "react";
import {
    IonCol,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption,
} from "@ionic/react";

// constants
import { ClientPortal } from "../../../constants/constant";
import { ConsumerActionsDescriberData } from "../../../models/ConsumerActions";

export function titleDescriptor(): string {
    return ClientPortal.bulkPaycycleGrp;
}

export function contentDescriptor(
    props: ConsumerActionsDescriberData
): JSX.Element {
    const { payCycleGrp, updatePayCycleGrp, result,payrollGrps } = props;
    return (
        <React.Fragment>
            <IonRow>
                <IonCol>
                    {result && !result.isValid && (
                        <span className="validation-error-label">*</span>
                    )}
                    <IonLabel className="input-headers">
                        Pay Cycle Group
                    </IonLabel>
                    <IonSelect
                        interface="popover"
                        placeholder="-Choose-"
                        value={payCycleGrp}
                        onIonChange={(e) => updatePayCycleGrp(e.target.value!)}
                        className="sort-dropdown self-products-select consumer-actions-select"
                        disabled={!payrollGrps || !payrollGrps.length}
                    >
                        {payrollGrps && payrollGrps.length > 0 && payrollGrps?.map((item: Record<string,any>) => (
                            <IonSelectOption key={item.payrollDeductGroupId} value={item.payrollDeductGroupId}>
                                {item.groupName}
                            </IonSelectOption>
                        ))}
                    </IonSelect>
                    {result && !result.isValid && (
                        <span className="global-red">
                            {result && result.errorMessage}
                        </span>
                    )}
                </IonCol>
            </IonRow>
        </React.Fragment>
    );
}
export default {
    titleDescriptor,
    contentDescriptor,
};
