import * as ORDERS from "../../ActionTypes/orderTypes";
import { all, put, takeLatest, call } from "redux-saga/effects";
import { doGet, doPost, doDelete, doPatch } from "../../../utils/fetchWrapper";
import {
    postMarketOrderHeaderSuccess,
    postMarketOrderHeaderFailure,
    fetchMarketOrderHeaderSuccess,
    fetchMarketOrderHeaderFailure,
    fetchMarketOrderProductsFailure,
    fetchMarketOrderProductsSuccess,
    fetchMarketOrderSuccess,
    fetchMarketOrderFailure,
    deleteMarketOrderProductSuccess,
    deleteMarketOrderProductFailure,
    saveMarketOrderProductSuccess,
    saveMarketOrderProductFailure,
    patchMarketOrderProductSuccess,
    patchMarketOrderProductFailure,
    postMarketOrderSuccess,
    postMarketOrderFailure,
    postAutoOrderHeaderSuccess,
    postAutoOrderHeaderFailure
} from "../../actions/orderActions";
import { Routes } from "../../../constants/Routes";

function* postMarketOrderHeader(action: any) {
    const { data } = action;
    let url = `${Routes.order.marketOrderHeaderApiUrl}`;
    const request = { ...data };
    try {
        // @ts-ignore
        const response = yield doPost(url, request);
        yield put(postMarketOrderHeaderSuccess(response));
    } catch (error: any) {
        yield put(postMarketOrderHeaderFailure(error));
    }
}

function* fetchMarketOrderHeaders(action: any) {
    const { locationId, offset, orgId } = action;
    // @ts-ignore
    let url = `${Routes.order.marketOrderHeaderApiUrl}?offset=${offset}&orderBy=lastUpdated`;
    if (locationId) {
        url += `&locationId=${locationId}`;
    }
    if (orgId) {
        url += `&orgId=${orgId}`;
    }
    try {
        // @ts-ignore
        const response = yield doGet(url);
        yield put(fetchMarketOrderHeaderSuccess(response, locationId));
    } catch (error: any) {
        yield put(fetchMarketOrderHeaderFailure(error));
    }
}

function* fetchMarketOrderProducts(action: any) {
    const { marketOrderHeaderId, offset } = action;
    // @ts-ignore
    let url = `${Routes.order.marketOrderHeaderApiUrl}/${marketOrderHeaderId}/items?offset=${offset}`;

    try {
        // @ts-ignore
        const response = yield doGet(url);
        yield put(fetchMarketOrderProductsSuccess(response));
    } catch (error: any) {
        yield put(fetchMarketOrderProductsFailure(error));
    }
}

function* fetchMarketOrder(action: any) {
    const { marketOrderHeaderId } = action;
    // @ts-ignore
    let url = `${Routes.order.marketOrderHeaderApiUrl}/${marketOrderHeaderId}`;

    try {
        // @ts-ignore
        const response = yield doGet(url);
        yield put(fetchMarketOrderSuccess(response));
        yield call(fetchMarketOrderProducts, {
            marketOrderHeaderId: marketOrderHeaderId,
            offset: 0,
        });
    } catch (error: any) {
        yield put(fetchMarketOrderFailure(error));
    }
}

function* removeMarketOrderProduct(info: any) {
    const { marketOrderHeaderId, marketOrderItemId } = info;
    // @ts-ignore
    let url = `${Routes.order.marketOrderHeaderApiUrl}/${marketOrderHeaderId}/items/${marketOrderItemId}`;
    try {
        // @ts-ignore
        const response = yield doDelete(url);
        yield put(deleteMarketOrderProductSuccess(response));
    } catch (e: any) {
        yield put(deleteMarketOrderProductFailure(e));
    }
}

function* deleteMarketOrderProducts(request: any) {
    yield all(
        request.info.map((file: any) => call(removeMarketOrderProduct, file))
    );
}

function* submitMarketOrderProduct(info: Record<string, any>) {
    const { marketOrderHeaderId } = info;
    delete info.marketOrderHeaderId;
    delete info.productId;
    let url = `${Routes.order.marketOrderHeaderApiUrl}/${marketOrderHeaderId}/items`;
    try {
        // @ts-ignore
        const response = yield doPost(url, info);
        yield put(saveMarketOrderProductSuccess(response));
    } catch (error: any) {
        yield put(saveMarketOrderProductFailure(error));
    }
}

function* submitMarketOrderProducts(request: Record<string, any>) {
    yield all(
        request.info.map((file: Record<string, any>) =>
            call(submitMarketOrderProduct, file)
        )
    );
}

function* patchMarketOrderProduct(action: any) {
    const { info } = action;
    const { marketOrderHeaderId, marketOrderItemId } = info;
    delete info.marketOrderHeaderId;
    delete info.marketOrderItemId;
    // @ts-ignore
    const url = `${Routes.order.marketOrderHeaderApiUrl}/${marketOrderHeaderId}/items/${marketOrderItemId}`;
    try {
        // @ts-ignore
        const response = yield doPatch(url, info);
        yield put(patchMarketOrderProductSuccess(response));
    } catch (error: any) {
        yield put(patchMarketOrderProductFailure(error));
    }
}

function* postMarketOrder(action: any) {
    const { marketOrderHeaderId, data } = action;
    // @ts-ignore
    let url = `${Routes.order.marketOrderActionsAPiUrl}/${marketOrderHeaderId}/submit`;
    try {
        // @ts-ignore
        const response = yield doPost(url, data);
        yield put(postMarketOrderSuccess(response));
    } catch (error: any) {
        yield put(postMarketOrderFailure(error));
    }
}


function* postAutoOrderHeader(action: any) {
    const { data } = action;
    let url = `${Routes.order.marketOrderActionsAPiUrl}/auto-generate`;
    const request = { ...data };
    try {
        // @ts-ignore
        const response = yield doPost(url, request);
        yield put(postAutoOrderHeaderSuccess(response));
    } catch (error: any) {
        yield put(postAutoOrderHeaderFailure(error));
    }
}

function* OrderSaga() {
    yield all([
        takeLatest(
            ORDERS.FETCH_MARKET_ORDER_HEADER_REQUEST,
            fetchMarketOrderHeaders
        ),
        takeLatest(
            ORDERS.POST_MARKET_ORDER_HEADER_REQUEST,
            postMarketOrderHeader
        ),
        takeLatest(
            ORDERS.FETCH_MARKET_ORDER_PRODUCT_REQUEST,
            fetchMarketOrderProducts
        ),
        takeLatest(ORDERS.FETCH_MARKET_ORDER_REQUEST, fetchMarketOrder),
        takeLatest(
            ORDERS.DELETE_MARKET_ORDER_PRODUCT,
            deleteMarketOrderProducts
        ),
        takeLatest(
            ORDERS.SAVE_MARKET_ORDER_PRODUCT_REQUEST,
            submitMarketOrderProducts
        ),
        takeLatest(ORDERS.PATCH_MARKET_ORDER_PRODUCT, patchMarketOrderProduct),
        takeLatest(ORDERS.POST_MARKET_ORDER_REQUEST, postMarketOrder),
        takeLatest(ORDERS.POST_AUTO_ORDER_HEADER_REQUEST, postAutoOrderHeader),
    ]);
}

export default OrderSaga;
