export type UPDATE_PRODUCT_FILTERS = typeof UPDATE_PRODUCT_FILTERS;
export const UPDATE_PRODUCT_FILTERS = "UPDATE_PRODUCT_FILTERS";

export type UPDATE_MANAGE_COLUMN = typeof UPDATE_MANAGE_COLUMN;
export const UPDATE_MANAGE_COLUMN = "UPDATE_MANAGE_COLUMN";

export type UPDATE_PRODUCT_SETTINGS = typeof UPDATE_PRODUCT_SETTINGS;
export const UPDATE_PRODUCT_SETTINGS = "UPDATE_PRODUCT_SETTINGS";

/**
 * Set location id
 */
export type SET_LOCATION_ID = typeof SET_LOCATION_ID;
export const SET_LOCATION_ID = "SET_LOCATION_ID";

/**
 * Set organization id
 */
export type SET_ORGANIZATION_ID = typeof SET_ORGANIZATION_ID;
export const SET_ORGANIZATION_ID = "SET_ORGANIZATION_ID";

/**
 * ReSet Global products
 */
export type RESET_GLOBAL_PRODUCTS = typeof RESET_GLOBAL_PRODUCTS;
export const RESET_GLOBAL_PRODUCTS = "RESET_GLOBAL_PRODUCTS";

/**
 * market product listing
 */
export type FETCH_MARKET_PRODUCTS_REQUEST =
    typeof FETCH_MARKET_PRODUCTS_REQUEST;
export type FETCH_MARKET_PRODUCTS_SUCCESS =
    typeof FETCH_MARKET_PRODUCTS_SUCCESS;
export type FETCH_MARKET_PRODUCTS_FAILURE =
    typeof FETCH_MARKET_PRODUCTS_FAILURE;

export const FETCH_MARKET_PRODUCTS_REQUEST = "FETCH_MARKET_PRODUCTS_REQUEST";
export const FETCH_MARKET_PRODUCTS_SUCCESS = "FETCH_MARKET_PRODUCTS_SUCCESS";
export const FETCH_MARKET_PRODUCTS_FAILURE = "FETCH_MARKET_PRODUCTS_FAILURE";

/**
 * global product listing
 */
export type FETCH_GLOBAL_PRODUCTS_REQUEST =
    typeof FETCH_GLOBAL_PRODUCTS_REQUEST;
export type FETCH_GLOBAL_PRODUCTS_SUCCESS =
    typeof FETCH_GLOBAL_PRODUCTS_SUCCESS;
export type FETCH_GLOBAL_PRODUCTS_FAILURE =
    typeof FETCH_GLOBAL_PRODUCTS_FAILURE;

export const FETCH_GLOBAL_PRODUCTS_REQUEST = "FETCH_GLOBAL_PRODUCTS_REQUEST";
export const FETCH_GLOBAL_PRODUCTS_SUCCESS = "FETCH_GLOBAL_PRODUCTS_SUCCESS";
export const FETCH_GLOBAL_PRODUCTS_FAILURE = "FETCH_GLOBAL_PRODUCTS_FAILURE";

/**
 * market product save
 */
export type SAVE_MARKET_PRODUCT_REQUEST = typeof SAVE_MARKET_PRODUCT_REQUEST;
export type SAVE_MARKET_PRODUCT_SUCCESS = typeof SAVE_MARKET_PRODUCT_SUCCESS;
export type SAVE_MARKET_PRODUCT_FAILURE = typeof SAVE_MARKET_PRODUCT_FAILURE;

export const SAVE_MARKET_PRODUCT_REQUEST = "SAVE_MARKET_PRODUCT_REQUEST";
export const SAVE_MARKET_PRODUCT_SUCCESS = "SAVE_MARKET_PRODUCT_SUCCESS";
export const SAVE_MARKET_PRODUCT_FAILURE = "SAVE_MARKET_PRODUCT_FAILURE";

/**
 * FETCH product details
 */
export type FETCH_PRODUCT_DETAILS_REQUEST =
    typeof FETCH_PRODUCT_DETAILS_REQUEST;
export type FETCH_PRODUCT_DETAILS_SUCCESS =
    typeof FETCH_PRODUCT_DETAILS_SUCCESS;
export type FETCH_PRODUCT_DETAILS_FAILURE =
    typeof FETCH_PRODUCT_DETAILS_FAILURE;

export const FETCH_PRODUCT_DETAILS_REQUEST = "FETCH_PRODUCT_DETAILS_REQUEST";
export const FETCH_PRODUCT_DETAILS_SUCCESS = "FETCH_PRODUCT_DETAILS_SUCCESS";
export const FETCH_PRODUCT_DETAILS_FAILURE = "FETCH_PRODUCT_DETAILS_FAILURE";

/**
 * Submit market product detail
 */
export type PATCH_MARKET_PRODUCT_REQUEST = typeof PATCH_MARKET_PRODUCT_REQUEST;
export type PATCH_MARKET_PRODUCT_SUCCESS = typeof PATCH_MARKET_PRODUCT_SUCCESS;
export type PATCH_MARKET_PRODUCT_FAILURE = typeof PATCH_MARKET_PRODUCT_FAILURE;

export const PATCH_MARKET_PRODUCT_REQUEST = "PATCH_MARKET_PRODUCT_REQUEST";
export const PATCH_MARKET_PRODUCT_SUCCESS = "PATCH_MARKET_PRODUCT_SUCCESS";
export const PATCH_MARKET_PRODUCT_FAILURE = "PATCH_MARKET_PRODUCT_FAILURE";

export type DELETE_MARKET_PRODUCT = typeof DELETE_MARKET_PRODUCT;
export type DELETE_MARKET_PRODUCT_SUCCESS =
    typeof DELETE_MARKET_PRODUCT_SUCCESS;
export type DELETE_MARKET_PRODUCT_FAILURE =
    typeof DELETE_MARKET_PRODUCT_FAILURE;
export const DELETE_MARKET_PRODUCT = "DELETE_MARKET_PRODUCT";
export const DELETE_MARKET_PRODUCT_SUCCESS = "DELETE_MARKET_PRODUCT_SUCCESS";
export const DELETE_MARKET_PRODUCT_FAILURE = "DELETE_MARKET_PRODUCT_FAILURE";

/**
 * SWAP PRODUCT save
 */
export type SAVE_SWAP_PRODUCT_REQUEST = typeof SAVE_SWAP_PRODUCT_REQUEST;
export type SAVE_SWAP_PRODUCT_SUCCESS = typeof SAVE_SWAP_PRODUCT_SUCCESS;
export type SAVE_SWAP_PRODUCT_FAILURE = typeof SAVE_SWAP_PRODUCT_FAILURE;

export const SAVE_SWAP_PRODUCT_REQUEST = "SAVE_SWAP_PRODUCT_REQUEST";
export const SAVE_SWAP_PRODUCT_SUCCESS = "SAVE_SWAP_PRODUCT_SUCCESS";
export const SAVE_SWAP_PRODUCT_FAILURE = "SAVE_SWAP_PRODUCT_FAILURE";

/**
 * global product categories
 */
export type FETCH_GLOBAL_PRODUCTS_CATEGORIES_REQUEST =
    typeof FETCH_GLOBAL_PRODUCTS_CATEGORIES_REQUEST;
export type FETCH_GLOBAL_PRODUCTS_CATEGORIES_SUCCESS =
    typeof FETCH_GLOBAL_PRODUCTS_CATEGORIES_SUCCESS;
export type FETCH_GLOBAL_PRODUCTS_CATEGORIES_FAILURE =
    typeof FETCH_GLOBAL_PRODUCTS_CATEGORIES_FAILURE;

export const FETCH_GLOBAL_PRODUCTS_CATEGORIES_REQUEST =
    "FETCH_GLOBAL_PRODUCTS_CATEGORIES_REQUEST";
export const FETCH_GLOBAL_PRODUCTS_CATEGORIES_SUCCESS =
    "FETCH_GLOBAL_PRODUCTS_CATEGORIES_SUCCESS";
export const FETCH_GLOBAL_PRODUCTS_CATEGORIES_FAILURE =
    "FETCH_GLOBAL_PRODUCTS_CATEGORIES_FAILURE";



/**
 * ReSet Market products
 */
export type RESET_MARKET_PRODUCTS = typeof RESET_MARKET_PRODUCTS;
export const RESET_MARKET_PRODUCTS = "RESET_MARKET_PRODUCTS";


/**
 * SWAP PRODUCT save
 */
export type POST_PUBLISH_PRODUCT_REQUEST = typeof POST_PUBLISH_PRODUCT_REQUEST;
export type POST_PUBLISH_PRODUCT_SUCCESS = typeof POST_PUBLISH_PRODUCT_SUCCESS;
export type POST_PUBLISH_PRODUCT_FAILURE = typeof POST_PUBLISH_PRODUCT_FAILURE;

export const POST_PUBLISH_PRODUCT_REQUEST = "POST_PUBLISH_PRODUCT_REQUEST";
export const POST_PUBLISH_PRODUCT_SUCCESS = "POST_PUBLISH_PRODUCT_SUCCESS";
export const POST_PUBLISH_PRODUCT_FAILURE = "POST_PUBLISH_PRODUCT_FAILURE";

/**
* Show publish product
*/
export type SHOW_PUBLISH_PRODUCT = typeof SHOW_PUBLISH_PRODUCT;
export const SHOW_PUBLISH_PRODUCT = "SHOW_PUBLISH_PRODUCT";

/*
market products for report
*/
export type FETCH_MARKETPRODUCTS_REPORT_REQUEST = typeof FETCH_MARKETPRODUCTS_REPORT_REQUEST;
export type FETCH_MARKETPRODUCTS_REPORT_SUCCESS = typeof FETCH_MARKETPRODUCTS_REPORT_SUCCESS;
export type FETCH_MARKETPRODUCTS_REPORT_FAILURE = typeof FETCH_MARKETPRODUCTS_REPORT_FAILURE;

export const FETCH_MARKETPRODUCTS_REPORT_REQUEST = "FETCH_MARKETPRODUCTS_REPORT_REQUEST";
export const FETCH_MARKETPRODUCTS_REPORT_SUCCESS = "FETCH_MARKETPRODUCTS_REPORT_SUCCESS";
export const FETCH_MARKETPRODUCTS_REPORT_FAILURE = "FETCH_MARKETPRODUCTS_REPORT_FAILURE";

/**
 * Self operated PRODUCT save
 */
export type POST_SELF_OPERATED_PRODUCT_REQUEST = typeof POST_SELF_OPERATED_PRODUCT_REQUEST;
export type POST_SELF_OPERATED_PRODUCT_SUCCESS = typeof POST_SELF_OPERATED_PRODUCT_SUCCESS;
export type POST_SELF_OPERATED_PRODUCT_FAILURE = typeof POST_SELF_OPERATED_PRODUCT_FAILURE;

export const POST_SELF_OPERATED_PRODUCT_REQUEST = "POST_SELF_OPERATED_PRODUCT_REQUEST";
export const POST_SELF_OPERATED_PRODUCT_SUCCESS = "POST_SELF_OPERATED_PRODUCT_SUCCESS";
export const POST_SELF_OPERATED_PRODUCT_FAILURE = "POST_SELF_OPERATED_PRODUCT_FAILURE";