//vendors
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//constants
import { languages } from './constants';

i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    lng: navigator.language,
    resources: {
        en: { translations: require("./translations/en.json") },
        es: { translations: require("./translations/es.json") },
        it: { translations: require("./translations/it.json") },
        de: { translations: require("./translations/de.json") },
        nl: { translations: require("./translations/nl.json") },
        fr: { translations: require("./translations/fr.json") },
        sv: { translations: require("./translations/sv.json") },
        da: { translations: require("./translations/da.json") },
        fi: { translations: require("./translations/fi.json") },
        no: { translations: require("./translations/no.json") },
        "fr-CA": { translations: require("./translations/fr-CA.json") },
    },
    ns: ['translations'],
    defaultNS: 'translations'
});

i18n.languages = languages;

export default i18n;