import React, { useCallback, useContext, useEffect, useState } from "react";
import {
    IonButton,
    IonRow,
    IonCol,
    IonSelect,
    IonSelectOption,
    isPlatform,
} from "@ionic/react";

import ManageColumns from "../../components/Product/ManageColumns/Index";
import { PortalContext } from "../..";
import mapper from "./mapping.json";
import { ConsumerListActionsPrompt } from "./ConsumerListActionsPrompt";
import { ConsumerGrid } from "./ConsumerGrid";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../_redux/reducers/rootReducer";
import { ClientPortal, Hotel365Portal } from "../../constants/constant";
import { fetchPayrollGrp, fetchSubsidyGrp, updateConsumerCheckboxType } from "../../_redux/actions/consumerActions";
import ToastrHook from "../../components/common/toastr/Toastr";

export const ConsumerSearchResult: React.FC<any> = (searchResultprops) => {
    const [buttonState, setButtonState] = useState(false);
    const dispatch = useDispatch();
    const [renderToastr] = ToastrHook();
    const [consumerAction, setConsumerAction] = useState("");
    const [consumerSelectRow, setConsumerSelectRow] = useState("");
    const [consumers, setConsumers] = useState([] as string[]);
    const _portalTypes = useContext(PortalContext) as any;
    const isDesktopView = isPlatform("desktop");
    const isTabletView = isPlatform("tablet");
    const [colsSelected, setColsSelected] = useState(
        Object.values(
            ClientPortal.consumerChooseColumnDefaultValues
        ) as string[]
    );
    const selectCols = (selectedItems: string[] = []) => {
        if (selectedItems) {
            setColsSelected(selectedItems);
        }
    };
    const consumerLst = useSelector((state: RootState) => {
        return state.consumer;
    }, shallowEqual);
    const {
        error: consumerErr,
        consumerLists,
        cummulativeConsumers,
        pending: consumerPending,
        consumerChecked,
        consumerPaginate,
        bulkActionResponse,
        payrollGrps,
        subsidyGrps,
    } = consumerLst;

    const checkBulkActionStartResponse = useCallback(() => {
        return bulkActionResponse && Object.keys(bulkActionResponse).length > 0;
    }, [bulkActionResponse]);

    useEffect(() => {
        if (checkBulkActionStartResponse()) setConsumerAction('');
    }, [bulkActionResponse])


    const props = {
        selectCols,
        setButtonState,
        colNameMapping: { ...mapper },
        selectedCols: Object.keys(mapper),
        preselectedColumn: colsSelected,
        _portalTypes,
        headerTitle: ClientPortal.consumer_choose_column_header,
    };
    const consumerActionHandle = (e: any) => {
        if (e.detail.value && (e.detail.value !== consumerAction)) {
            const _locationId = checkMultipleLocations();
            e.detail.value === "payCycleGrp" && dispatch(fetchPayrollGrp(_locationId?.length > 0 && _locationId[0]));
            e.detail.value !== "payCycleGrp" && dispatch(fetchSubsidyGrp(_locationId?.length > 0 && _locationId[0]));
            setTimeout(() => { setConsumerAction(e.detail.value), 100 });
        }

    };
    const checkMultipleLocations = () => {
        const { location, updatedUserLocations } = searchResultprops;
        let locationIds: string[] = [];
        if (
            location.length > 0 &&
            location[0].value !==
            ClientPortal.consumerChooseColumnViewAllLbl
        ) {
            locationIds = location.map(
                (item: Record<string, any>) => item.value
            );
        } else {
            locationIds = updatedUserLocations.map(
                (item: Record<string, any>) => item.locationId
            );
        }

        const consumerLocationIds = cummulativeConsumers
            .filter((item) => consumerChecked?.includes(item.accountId))
            .map((item: Record<string, any>) => item.locationId);
        return consumerLocationIds.reduce(
            (x, y) => (x.includes(y) ? x : [...x, y]),
            []
        );
    };
    const customPopoverOptions = {
        message: ClientPortal.consumer_multiple_loc_error,
        cssClass: ClientPortal.consumerModalPopupClass,
    };
    const customPopoverSelectRowOptions = {
        cssClass: ClientPortal.consumerModalPopupClass,
    };
    const consumerselectRowHandle = (e: any) => {
        if (e.detail.value !== 'Select') {
            setConsumerSelectRow(e.detail.value);
            if (e.detail.value === ClientPortal.consumer_select_row_none_type) {
                setConsumers([]);
                dispatch(updateConsumerCheckboxType([]));
            } else {
                const result = [...consumers,...consumerLists.map((item) => item.accountId)]
                setConsumers(result.filter((v,i) => result.indexOf(v) == i));
                dispatch(
                    updateConsumerCheckboxType(
                        result
                    )
                );
            }
        }

    };
    const checkConsumersSelected = (data: string[]) => {
        /**to get the list of selected consumers whose checkbox is checked */
        let _checkedConsumer: string[] = []
        if (consumers.length > 0 && data.length > 0) {
            consumers.forEach((preObj) => {
                const found = data.find((newObj) => newObj == preObj);
                if (!found) {
                    setConsumers(consumers.filter((item) => item != preObj));
                    dispatch(
                        updateConsumerCheckboxType(
                            consumers.filter((item) => item != preObj)
                        )
                    );
                }
            });

            data.forEach((newObj) => {
                const found = consumers.find((prevObj) => prevObj == newObj);
                if (!found) {
                    setConsumers([...consumers, newObj]);
                    dispatch(
                        updateConsumerCheckboxType([...consumers, newObj])
                    );
                }
            });
        } else {
            setConsumers(data);
            dispatch(updateConsumerCheckboxType(data));
        }
        
    };

    useEffect(() => {
        setConsumerAction("");
        setConsumerSelectRow("");
        dispatch(updateConsumerCheckboxType([]));
    }, []);

    const getCheckBoxCount = ()=>{
        if((consumerSelectRow === ClientPortal.consumer_select_row_Current_val) || ((!consumerSelectRow || consumerSelectRow === ClientPortal.consumer_select_row_none_type)&& consumers.length)){
            return `(${consumers?.length} selected)`;
        } else if(consumerSelectRow === ClientPortal.consumer_select_row_all_lbl.toLowerCase()){
            return `(${consumerPaginate?.totalCount} selected)`;
        } else {
            return ''
        }
    }

    return (
        <React.Fragment>
            <IonRow color="secondary" className="report-bar">
                <IonCol className="consumer-toolbar-container" sizeLg="1" sizeMd="2" sizeXs="5">
                    <IonSelect
                        interface="popover"
                        placeholder={ClientPortal.consumer_select_lbl}
                        value={ClientPortal.consumer_select_lbl}
                        interfaceOptions={customPopoverSelectRowOptions}
                        className="sort-dropdown pl-50 grid-select-button"
                        onIonChange={consumerselectRowHandle}
                        disabled={!consumerLists.length}
                    >
                        <IonSelectOption
                            value={ClientPortal.consumer_select_row_Current_val}
                        >
                            {ClientPortal.consumer_select_row_all_lbl}{" "}
                            {consumerLists.length}{" "}
                            {
                                ClientPortal.consumer_select_row_all_consumers_page_lbl
                            }
                        </IonSelectOption>
                        <IonSelectOption
                            value={ClientPortal.consumer_select_row_all_lbl.toLowerCase()}
                        >
                            {ClientPortal.consumer_select_row_all_lbl}{" "}
                            {consumerPaginate.totalCount}{" "}
                            {ClientPortal.consumer_select_row_all_consumer_lbl}
                        </IonSelectOption>
                        <IonSelectOption
                            value={ClientPortal.consumer_select_row_none_type}
                        >
                            {ClientPortal.consumer_select_row_none_lbl}
                        </IonSelectOption>
                    </IonSelect>
                </IonCol>
                <IonCol className="consumer-toolbar-container" sizeLg="1" sizeMd="2" sizeXs="7">
                    <IonButton
                        size="default"
                        fill={"solid"}
                        className="grid-btns consumer-column-btn"
                        onClick={(e) => {
                            setButtonState((pv) => !pv);
                        }}
                        disabled={!consumerLists.length}
                    >
                        {ClientPortal.consumer_col_btn}
                    </IonButton>
                    <ManageColumns
                        {...props}
                        showManageColumnModal={buttonState}
                    />
                </IonCol>
                <IonCol className="consumer-actions-container" sizeLg="1.7" sizeMd="1.7" sizeXs="5">
                    <IonSelect
                        interface="popover"
                        placeholder={`Actions ${getCheckBoxCount()}`}
                        value={consumerAction}
                        interfaceOptions={
                            checkMultipleLocations().length > 1
                                ? customPopoverOptions
                                : {
                                    cssClass:
                                        ClientPortal.consumerModalPopupClass,
                                }
                        }
                        onIonChange={consumerActionHandle}
                        className="sort-dropdown pl-50 grid-select-row"
                        disabled={!consumerLists.length}
                    >
                        {/* <IonSelectOption
                                value="addFunds"
                                disabled={true}
                            >
                                Bulk Add Funds
                            </IonSelectOption>
                            <IonSelectOption
                                value="topOff"
                                disabled={true}
                            >
                                Bulk Top Off
                            </IonSelectOption> */}
                        <IonSelectOption
                            value={
                                ClientPortal.consumerBulkAssignSubsidyVal
                            }
                            disabled={
                                !consumers.length ||
                                checkMultipleLocations().length > 1
                            }
                        >
                            {ClientPortal.consumer_bulk_subsidy_lbl}
                        </IonSelectOption>
                        {/* <IonSelectOption
                                value={ClientPortal.consumerBulkPinResetVal}
                                disabled={!consumers.length}
                            >
                                {ClientPortal.consumer_bulk_pin_reset_lbl}
                            </IonSelectOption> */}
                        <IonSelectOption
                            value={
                                ClientPortal.consumerBulkPayChangeVal
                            }
                            disabled={
                                !consumers.length ||
                                checkMultipleLocations().length > 1
                            }
                        >
                            {ClientPortal.consumer_bulk_pay_change_lbl}
                        </IonSelectOption>
                    </IonSelect>
                </IonCol>
                <ConsumerListActionsPrompt
                    actionName={consumerAction}
                    setConsumerAction={setConsumerAction}
                    consumerSelectRow={consumerSelectRow}
                    consumers={consumers}
                    type={'list'}
                />
            </IonRow>
            <ConsumerGrid
                nonHiddenColumns={colsSelected}
                consumerSelectRow={consumerSelectRow}
                setConsumerSelectRow={setConsumerSelectRow}
                getSelectedConsumers={checkConsumersSelected}
                updatePaginate={searchResultprops.updatePaginate}
                consumers={consumers}
            />
        </React.Fragment>
    );
};
export default ConsumerSearchResult;
