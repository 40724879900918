import React, { useState, useEffect, useCallback } from 'react';
import {
    IonButton, IonContent, IonFooter,
    IonHeader, IonModal, IonText, IonRow,
    IonCol,
    isPlatform
} from '@ionic/react';

import Header from './Header';
import Footer from './Footer';
import Body from './Body';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../_redux/reducers/rootReducer';
import { updateManageColumns } from '../../../_redux/actions/productActions';
//Constant
import {
    MANAGE_COLUMNS,
    MODAL_CHOOSE_COLUMNS,
    ORDER_CLEAR_FILTER
} from '../../../constants/constant'
interface ModalBox {
    name: string;
    isChecked: boolean;
}

const ModalBox: React.FC<any> = ({ selectCols, setButtonState, colNameMapping, selectedCols, showManageColumnModal, preselectedColumn, _portalTypes, headerTitle }) => {
    const dispatch = useDispatch();
    const selectedManageColumns = useSelector(
        (state: RootState) => state.product.manageColumns,
        shallowEqual
    );
    const [showModal, setShowModal] = useState(showManageColumnModal);
    const [productData, setProductDataList] = useState(selectedCols)
    const [checked, setChecked] = useState([] as string[])
    const ismobileview = isPlatform("mobile")
    useEffect(() => {
        setShowModal(showManageColumnModal)
        if (productData) {
            let data = productData.length > 0 ? productData : ([])
            setProductDataList(data)
        }
        if (selectedManageColumns && selectedManageColumns.length > 0) {
            setChecked(selectedManageColumns);
        }
        if (preselectedColumn && preselectedColumn.length > 0) setChecked(preselectedColumn);
    }, [showManageColumnModal])

    const clearFilter = useCallback(() => {
        setChecked([]);
        dispatch(updateManageColumns([]));
    }, [setChecked])

    const onCloseModal = () => {
        dispatch(updateManageColumns(selectedManageColumns));
        setShowModal(false);
        setButtonState(false);

    }

    const onSave = () => {
        setShowModal(false);
        dispatch(updateManageColumns(checked));
        selectCols(checked)
    }

    const isActiveHandler = useCallback(
        (category: string) => {
            const seletedValues = Object.assign([], checked); // to Clone an Array
            if (seletedValues.length > 0) {
                const isPresent = seletedValues.some(
                    (item: string) => item === category,
                )
                if (isPresent) {
                    seletedValues.splice(seletedValues.indexOf(category), 1)
                } else {
                    seletedValues.push(category)
                }
            } else {
                seletedValues.push(category)
            }
            setChecked(seletedValues)
        },
        [setChecked, checked],
    )

    const filterApply = () => {

    }
    const renderColNames = () => {
        let arr: JSX.Element[] = [];
        if (productData.length > 0) {
            productData.forEach((el: any) => {
                el.name !== "productId" && arr.push(<Body
                    productName={colNameMapping[el]}
                    onCheckedCheckBox={(el: any) => isActiveHandler(el)}
                    checked={checked.includes(el)}
                    key={el}
                    param={el}
                />)
            })
        }

        return arr;
    }
    return (
        <>
            <IonModal
                isOpen={showModal}
                className={!ismobileview ? "modal-pop" : "mobileview-modal-pop"}
                onDidDismiss={onCloseModal}
            >
                <IonContent>
                    <IonHeader className="header">
                        <Header
                            headerTitle={MANAGE_COLUMNS || "Manage Columns"}
                            onCloseModal={onCloseModal}
                            filterIcon={false}
                            filterApply={() => filterApply}
                        />
                    </IonHeader>
                    {_portalTypes === 'hp' && <React.Fragment>
                        <IonRow className="modal-body">
                            <IonCol offset="1" className="manage-columns-side-heading">
                                <IonText className="text-heading">
                                    {MODAL_CHOOSE_COLUMNS}
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol offset="1" className="mb-5">
                                <IonButton
                                    className="cleat-filter-btn btn-text-cases"
                                    fill="clear"
                                    onClick={clearFilter}
                                >
                                    {ORDER_CLEAR_FILTER}
                                </IonButton>
                            </IonCol>
                        </IonRow></React.Fragment>}
                    <IonRow>
                        <IonCol className={_portalTypes === 'hp' ? "product-list" : 'cp-product-list mt56'}>
                            {renderColNames()}
                        </IonCol>
                    </IonRow>
                    <IonFooter className="footer">
                        <Footer onCloseModal={onCloseModal} onSave={onSave} />
                    </IonFooter>
                </IonContent>
            </IonModal>
        </>
    );
}
export default ModalBox;
