import { IonGrid, IonRow, IonCol, IonIcon, IonImg } from "@ionic/react";
import { close } from "ionicons/icons";

interface HeaderTitle {
    headerTitle: string;
    onCloseModal: Function;
    filterIcon: boolean;
    filterApply?: Function;
}

const Header: React.FC<HeaderTitle> = (props: HeaderTitle) => {
    return (
        <>
            <IonGrid>
                <IonRow>
                    <IonCol offsetXs="1" offsetLg="0" sizeLg="8" sizeXs="7">
                        <div className="header-title">{props.headerTitle}</div>
                    </IonCol>

                    <IonCol sizeXs="4">
                        <IonRow>
                            {props.filterIcon ? (
                                <IonCol>
                                    <span className="cancel-button filter-icon-btn">
                                        <IonImg
                                            src={"/assets/filterWhite.svg"}
                                            style={{
                                                width: "20px"
                                               
                                            }}
                                            onClick={() =>
                                                props.filterApply &&
                                                props.filterApply()
                                            }
                                        />
                                    </span>
                                </IonCol>
                            ) : (
                                ""
                            )}
                            <IonCol offset={props.filterIcon ? "0" : "0"}>
                                <span
                                    className="cancel-button close-button"
                                    onClick={() => props.onCloseModal(false)}
                                >
                                    <IonIcon icon={close} />
                                </span>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </>
    );
};

export default Header;
