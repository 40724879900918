export type FETCH_LOCATIONS_BY_SYSUSERID_REQUEST = typeof FETCH_LOCATIONS_BY_SYSUSERID_REQUEST;
export type FETCH_LOCATIONS_BY_SYSUSERID_SUCCESS = typeof FETCH_LOCATIONS_BY_SYSUSERID_SUCCESS;
export type FETCH_LOCATIONS_BY_SYSUSERID_FAILURE = typeof FETCH_LOCATIONS_BY_SYSUSERID_FAILURE;

export const FETCH_LOCATIONS_BY_SYSUSERID_REQUEST = 'FETCH_LOCATIONS_BY_SYSUSERID_REQUEST';
export const FETCH_LOCATIONS_BY_SYSUSERID_SUCCESS = 'FETCH_LOCATIONS_BY_SYSUSERID_SUCCESS';
export const FETCH_LOCATIONS_BY_SYSUSERID_FAILURE = 'FETCH_LOCATIONS_BY_SYSUSERID_FAILURE';


export type FETCH_LOCATIONS_REQUEST = typeof FETCH_LOCATIONS_REQUEST;
export type FETCH_LOCATIONS_SUCCESS = typeof FETCH_LOCATIONS_SUCCESS;
export type FETCH_LOCATIONS_FAILURE = typeof FETCH_LOCATIONS_FAILURE;

export const FETCH_LOCATIONS_REQUEST = 'FETCH_LOCATIONS_REQUEST';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAILURE = 'FETCH_LOCATIONS_FAILURE';