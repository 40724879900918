import React, { useState } from "react";
import {
    IonButton,
    IonChip,
    IonCol,
    IonFooter,
    IonHeader,
    IonModal,
    IonRow,
    IonInput,
    IonTitle,
    isPlatform,
    IonLabel,
} from "@ionic/react";
//constants
import {
    Hotel365Portal,
    FOOTER_CANCEL_BUTTON,
    FOOTER_SAVE_BUTTON,
    POS_LAST_NAME,
    POS_ROOM_CHARGE,
    POS_ROOM_NUMBER,
    LAST_NAME_ERR
} from "../../constants/constant";

export const Room: React.FC<any> = (props) => {
    const { openModal, closeModal, save } = props;
    const [roomNo, setRoomNo] = useState("");
    const [lastName, setLastName] = useState("");
    const [lnErrorMessage, setLnErrorMessage] = useState("");
    const [rnoErrorMessage, setRnoErrorMessage] = useState("");
    const isTabletView = isPlatform("tablet");
    const isDesktopView = isPlatform("desktop");

    const restrictNumbersAndSplChar = (e: any) => {
        const charCode = e.nativeEvent.which || e.nativeEvent.keycode;
        if (
            (charCode > 64 && charCode < 91) ||
            (charCode > 96 && charCode < 123) ||
            charCode == 8
        ) {
            const result =
                e.target.selectionStart === 0 &&
                e.target.selectionEnd === e.target.value.length
                    ? e.key
                    : [
                          e.target.value.slice(0, e.target.selectionStart),
                          e.key,
                          e.target.value.slice(e.target.selectionStart),
                      ].join("");
        } else {
            e.preventDefault();
            return false;
        }
    };

    const onCloseModal = () => {
        closeModal();
        setRoomNo("");
        setLastName("");
        setLnErrorMessage("");
        setRnoErrorMessage("");
    };

    const saveRoomNo = () => {
        if (!lastName) {
            setLnErrorMessage(LAST_NAME_ERR);
        }
        if (!roomNo) {
            setRnoErrorMessage(Hotel365Portal.ROOM_NUMBER);
        }
        if (lastName && roomNo) {
            setLnErrorMessage("");
            setRnoErrorMessage("");
            save(roomNo, lastName);
        }
    };
    return (
        <>
            <div className="">
                <IonModal
                    isOpen={openModal}
                    className={
                        !isDesktopView && !isTabletView
                            ? "pos-modal-mobile"
                            : "room-modal"
                    }
                    onDidDismiss={onCloseModal}
                >
                    <IonHeader className="header">
                        <IonRow className="primary-header">
                            <IonTitle>{POS_ROOM_CHARGE}</IonTitle>
                            <IonChip
                                color="primmary"
                                className="ion-button"
                                onClick={onCloseModal}
                            >
                                <p className="cancel">&times;</p>
                            </IonChip>
                        </IonRow>
                    </IonHeader>
                    <form
                        className={
                            !isDesktopView && !isTabletView
                                ? "form-mobile-view"
                                : "order-form-body"
                        }
                    >
                        <IonRow className="modal-body">
                            <IonCol offset="1"></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                {lnErrorMessage && (
                                    <span style={{ color: "red" }}>*</span>
                                )}
                                <IonLabel className="input-headers">
                                    {POS_LAST_NAME}
                                </IonLabel>
                                <IonInput
                                    aria-label={POS_LAST_NAME}
                                    className="pos-comp-reason-description"
                                    maxlength={25}
                                    value={lastName}
                                    onIonChange={(e) =>
                                        setLastName(e.detail.value! as string)
                                    }
                                    onKeyPress={(e) => {
                                        restrictNumbersAndSplChar(e);
                                    }}
                                />
                                {lnErrorMessage && (
                                <span className="global-red">
                                    {lnErrorMessage}
                                </span>
                            )}
                            </IonCol>
                            
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                {rnoErrorMessage && (
                                    <span style={{ color: "red" }}>*</span>
                                )}
                                <IonLabel className="input-headers">
                                    {POS_ROOM_NUMBER}
                                </IonLabel>
                                <IonInput
                                    aria-label={POS_ROOM_NUMBER}
                                    className="pos-comp-reason-description"
                                    maxlength={6}
                                    value={roomNo}
                                    onIonChange={(e) =>
                                        setRoomNo(e.detail.value! as string)
                                    }
                                />
                                {rnoErrorMessage && (
                                <span className="global-red">
                                    {rnoErrorMessage}
                                </span>
                            )}
                            </IonCol>
                            
                        </IonRow>
                    </form>
                    <IonFooter className="footer">
                        <IonRow>
                            <IonCol offset="4" sizeMd="4">
                                <IonButton
                                    color="gray"
                                    expand="block"
                                    className="btn-text-cases-cancel"
                                    onClick={onCloseModal}
                                >
                                    {FOOTER_CANCEL_BUTTON}
                                </IonButton>
                            </IonCol>

                            <IonCol sizeMd="4">
                                <IonButton
                                    color="primary"
                                    expand="block"
                                    type="submit"
                                    className="btn-text-cases-save"
                                    onClick={saveRoomNo}
                                >
                                    {FOOTER_SAVE_BUTTON}
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonFooter>
                </IonModal>
            </div>
        </>
    );
};
