import {
    PostUser,
    PostUserSuccess,
    PostUserFailure,
    PostUserType,
    FetchUsers,
    FetchUsersSuccess,
    FetchUsersFailure,
    FetchUsersType,
    FetchUserDetailRequest,
    FetchUserDetailSuccess,
    FetchUserDetailFailure,
    FetchUserDetailType,
    PatchUser,
    PatchUserSuccess,
    PatchUserFailure,
    PatchUserType,
    DeleteUser,
    DeleteUserSuccess,
    DeleteUserFailure,
    DeleteUserType,
    ResendInviteUser,
    ResendInviteUserSuccess,
    ResendInviteUserFailure,
    ResendInviteUserType,
    PutUserRole,
    PutUserRoleSuccess,
    PutUserRoleFailure,
    PutUserRoleType,
    FetchUserDetailLocationFailure,
    FetchUserDetailLocationRequest,
    FetchUserDetailLocationSuccess,
    FetchUserDetailLocationType,
    UpdateUserDetailLocationFailure,
    UpdateUserDetailLocationRequest,
    UpdateUserDetailLocationSuccess,
    UpdateUserDetailLocationType,
    UpdateUserDetailOrganizationFailure,
    UpdateUserDetailOrganizationRequest,
    UpdateUserDetailOrganizationSuccess,
    UpdateUserDetailOrganizationType,
    ResetFetchUsersType,
    ResetFetchUsers,
    PostUserDetailEmailFailure,
    PostUserDetailEmailRequest,
    PostUserDetailEmailSuccess,
    PostUserDetailEmailType,
    SetLocationForCpSuccess
} from "../actions/userManagementActions";
import {
    UserManagement,
    UserManagementPagination,
} from "../../models/UserManagement";
import { HOTEL_ADMIN, HOTEL_ADMIN_ID, HOTEL_MANAGER, HOTEL_MANAGER_ID, CLIENT_ADMIN_ID, CLIENT_ADMIN } from "../../constants/constant";

export type userManagementTypes =
    | PatchUserType
    | PutUserRoleType
    | FetchUsersType
    | FetchUserDetailType
    | FetchUserDetailLocationType
    | ResendInviteUserType
    | PostUserType
    | DeleteUserType
    | UpdateUserDetailLocationType
    | UpdateUserDetailOrganizationType
    | ResetFetchUsersType
    | PostUserDetailEmailType;

export interface IUserManagement {
    pending: boolean;
    paginate: UserManagementPagination;
    error: Record<string, any> | null;
    postUserManagement: UserManagement | null;
    patchUserManagement: Record<string, any> | null;
    fetchUserManagement: Record<string, unknown>[];
    fetchUserDetail: UserManagement | null;
    deleteUserManagement: Record<string, any> | null;
    resendUserManagement: Record<string, any> | string;
    patchUserManagementRole: Record<string, any> | null;
    fetchUserDetailLocations: Record<string, unknown>[];
    updateUserDetailLocation: Record<string, unknown>;
    updateUserDetailOrganization: Record<string, unknown>;
    postUserDetailEmail: Record<string, unknown>;
    setLocationForCP: Record<string, unknown>[]; 
}
const initialState = {
    pending: true,
    paginate: {} as UserManagementPagination,
    error: null,
    postUserManagement: {} as UserManagement,
    patchUserManagementRole: {} as Record<string, any>,
    patchUserManagement: {} as Record<string, any>,
    fetchUserManagement: [],
    fetchUserDetail: {} as UserManagement,
    deleteUserManagement: {} as Record<string, any>,
    resendUserManagement: {} as Record<string, any> | string,
    fetchUserDetailLocations: [],
    updateUserDetailLocation: {},
    updateUserDetailOrganization: {},
    postUserDetailEmail: {},
    setLocationForCP: []
};

const strategies = {
    PATCH_USER_REQUEST: patchUser,
    PATCH_USER_SUCCESS: patchUserSuccess,
    PATCH_USER_FAILURE: patchUserFailure,
    FETCH_USERS_REQUEST: patchUser,
    FETCH_USERS_SUCCESS: fetchUsersSuccess,
    FETCH_USERS_FAILURE: patchUserFailure,
    FETCH_USERDETAIL_REQUEST: patchUser,
    FETCH_USERDETAIL_SUCCESS: getUserDetailSuccess,
    FETCH_USERDETAIL_FAILURE: patchUserFailure,
    DELETE_USER_REQUEST: patchUser,
    DELETE_USER_SUCCESS: deleteUserSuccess,
    DELETE_USER_FAILURE: patchUserFailure,
    RESEND_INVITE_LINK_REQUEST: patchUser,
    RESEND_INVITE_LINK_SUCCESS: resendInviteUserSuccess,
    RESEND_INVITE_LINK_FAILURE: patchUserFailure,
    POST_USER_REQUEST: patchUser,
    POST_USER_SUCCESS: postUserSuccess,
    POST_USER_FAILURE: patchUserFailure,
    PUT_USER_ROLE_REQUEST: patchUser,
    PUT_USER_ROLE_SUCCESS: putUserRoleSuccess,
    PUT_USER_ROLE_FAILURE: patchUserFailure,
    FETCH_USERDETAIL_LOCATION_REQUEST: patchUser,
    FETCH_USERDETAIL_LOCATION_SUCCESS: fetchUserDetailLocationSuccess,
    FETCH_USERDETAIL_LOCATION_FAILURE: patchUserFailure,
    PUT_USERDETAIL_LOCATION_REQUEST: patchUser,
    PUT_USERDETAIL_LOCATION_SUCCESS: updateUserLocations,
    PUT_USERDETAIL_LOCATION_FAILURE: patchUserFailure,
    PUT_USERDETAIL_ORGANIZATION_REQUEST: patchUser,
    PUT_USERDETAIL_ORGANIZATION_SUCCESS: updateUserOrganizations,
    PUT_USERDETAIL_ORGANIZATION_FAILURE: patchUserFailure,
    RESET_FETCH_USERS: resetFetchUsers,
    POST_USERDETAIL_EMAIL_REQUEST: patchUser,
    POST_USERDETAIL_EMAIL_SUCCESS: postUserEmail,
    POST_USERDETAIL_EMAIL_FAILURE: patchUserFailure,
    SET_LOCATION_FOR_CP: setLocationForCpReducer,
    default: (state: IUserManagement) => state,
};

function postUserSuccess(
    state: IUserManagement,
    action: PostUserSuccess
) {
    return {
        ...state,
        error: null,
        pending: false,
        postUserManagement: action.response,
    };
}

function patchUser(
    state: IUserManagement,
    _action:
        | PatchUser
        | PostUser
        | FetchUsers
        | FetchUserDetailRequest
        | DeleteUser
        | ResendInviteUser
        | PutUserRole
        | FetchUserDetailLocationRequest
        | UpdateUserDetailLocationRequest
        | UpdateUserDetailOrganizationRequest
        | PostUserDetailEmailRequest
) {
    return {
        ...state,
        error: null,
        deleteUserManagement: {},
        postUserManagement: {} as UserManagement,
        patchUserManagement: {},
        patchUserManagementRole: {},
        updateUserDetailLocation: {},
        updateUserDetailOrganization: {},
        postUserDetailEmail: {},
        resendUserManagement: {}
    };
}
function patchUserSuccess(
    state: IUserManagement,
    action: PatchUserSuccess
) {
    return {
        ...state,
        error: null,
        pending: false,
        patchUserManagement: action.data,
    };
}
function patchUserFailure(
    state: IUserManagement,
    action:
        | PostUserFailure
        | PatchUserFailure
        | FetchUsersFailure
        | FetchUserDetailFailure
        | DeleteUserFailure
        | ResendInviteUserFailure
        | PutUserRoleFailure
        | FetchUserDetailLocationFailure
        | UpdateUserDetailLocationFailure
        | UpdateUserDetailOrganizationFailure
        | PostUserDetailEmailFailure
) {
    return {
        ...state,
        error: action.error,
        pending: false,
        postUserManagement: null,
    };
}

function fetchUsersSuccess(state: IUserManagement, action: FetchUsersSuccess) {
    const users = action.data.items.map((user: Record<string, any>) => {
        const roleId = user.roles ? user.roles.map((role: Record<string, any>) => role.roleId) : [];
        const checkNotIncludedRoles = roleId.filter((x: string) => ![HOTEL_ADMIN_ID, HOTEL_MANAGER_ID, CLIENT_ADMIN_ID].includes(x))
        user.role = roleId.indexOf(HOTEL_ADMIN_ID) !== -1 ? HOTEL_ADMIN : roleId.indexOf(HOTEL_MANAGER_ID) !== -1 ? HOTEL_MANAGER : CLIENT_ADMIN;
        user.delete = true;
        user.resendButton = user.isPasswordSet;
        user.editButton = true;
        user.showEditDelete = checkNotIncludedRoles.length > 0 ? false : true
        return user;
    });
    return {
        ...state,
        error: null,
        pending: false,
        fetchUserManagement: users,
        paginate: action.data.metadata.pagination,
    };
}

function getUserDetailSuccess(
    state: IUserManagement,
    action: FetchUserDetailSuccess
) {
    const roleIds = action.data.roles ? action.data.roles.map((role: Record<string, any>) => role.roleId) : [];
    const roleId: string = roleIds.indexOf(HOTEL_ADMIN_ID) !== -1 ? HOTEL_ADMIN_ID : roleIds.indexOf(HOTEL_MANAGER_ID) !== -1 ? HOTEL_MANAGER_ID : "";
    const effEndDate = action.data.roles ? action.data.roles.map((role: Record<string, any>) => role.endDate) : [];
    const effStartDate = action.data.roles ? action.data.roles.map((role: Record<string, any>) => role.startDate) : [];
    return {
        ...state,
        error: null,
        pending: false,
        fetchUserDetail: {
            ...action.data,
            roleId,
            effectiveEndDate: effEndDate[0] ? effEndDate[0].split(' ')[0] + ' 12:00:00' : '',
            effectiveStartDate: effStartDate[0] ? effStartDate[0].split(' ')[0] + ' 12:00:00' : '',
        },
    };
}

function deleteUserSuccess(
    state: IUserManagement,
    action: DeleteUserSuccess
) {
    return {
        ...state,
        error: null,
        pending: false,
        deleteUserManagement: action.data,
    };
}

function resendInviteUserSuccess(
    state: IUserManagement,
    action: ResendInviteUserSuccess
) {
    return {
        ...state,
        error: null,
        pending: false,
        resendUserManagement: action.data,
    };
}

function putUserRoleSuccess(
    state: IUserManagement,
    action: PutUserRoleSuccess
) {
    return {
        ...state,
        error: null,
        pending: false,
        patchUserManagementRole: action.data,
    };
}
function fetchUserDetailLocationSuccess(
    state: IUserManagement,
    action: FetchUserDetailLocationSuccess
) {
    return {
        ...state,
        error: null,
        pending: false,
        fetchUserDetailLocations: action.data
    };
}
function setLocationForCpReducer(state: IUserManagement, action: SetLocationForCpSuccess) {
    return {
        ...state,
        setLocationForCP: action.data,
    }
}
function updateUserLocations(
    state: IUserManagement,
    action: UpdateUserDetailLocationSuccess
) {
    return {
        ...state,
        error: null,
        pending: false,
        updateUserDetailLocation: action.data
    };
}

function updateUserOrganizations(
    state: IUserManagement,
    action: UpdateUserDetailOrganizationSuccess
) {
    return {
        ...state,
        error: null,
        pending: false,
        updateUserDetailOrganization: action.data
    };
}


function resetFetchUsers(state: IUserManagement, action: ResetFetchUsers) {
    const { resetUserDetails } = action;
    if (resetUserDetails) {
        return {
            ...state,
            error: null,
            fetchUserDetail: {} as UserManagement,
        }
    }
    return Object.assign({}, state, {
        ...state,
        fetchUserManagement: [],
        fetchUserDetail: {} as UserManagement,
        error: null
    });
}

function postUserEmail(
    state: IUserManagement,
    action: PostUserDetailEmailSuccess
) {
    return {
        ...state,
        error: null,
        pending: false,
        postUserDetailEmail: action.data
    };
}

export default (
    state: IUserManagement = initialState,
    action: userManagementTypes
): IUserManagement => {
    return (strategies[action.type] || strategies.default)(
        state,
        action as never
    );
};