import { IonAccordion, IonAccordionGroup, IonBadge, IonCol, IonItem, IonLabel, IonRow } from '@ionic/react';
//constants
import { dashboardLabels } from '../../../constants/constant';
//interfaces
import { MenuItems } from '../../../models/UserProfile';

export function menuItems(props: MenuItems): JSX.Element {
    const {
        onLocationCHange,
        selectedLocation,
        onboardedProfiles,
        isOnboardingPermitted,
        needsOnboardingProfiles,
        onLocationSelectedToOnboard } = props;
    return (
        <>
            <IonAccordionGroup className="location-accordian-grp clickableText">
                <IonAccordion value="first" className="location-accordian">
                    <IonItem slot="header" className="accordian-location-header">
                        <IonLabel className="location-item-container clickableText">{dashboardLabels.MARKETS_LABEL}</IonLabel>
                    </IonItem>
                    {onboardedProfiles.map((profile: any) => {
                        const { locationId, marketName, orgId } = profile;
                        return (
                            <IonRow slot="content">
                                <IonCol key={locationId} className={selectedLocation === locationId ? "ion-padding selected-location" : "ion-padding"}
                                    onClick={() => { onLocationCHange(locationId, orgId); }}>
                                    {marketName}
                                </IonCol>
                            </IonRow>
                        );
                    })}
                </IonAccordion>
            </IonAccordionGroup>
            {isOnboardingPermitted && needsOnboardingProfiles.length > 0 && (
                <IonAccordionGroup className="location-accordian-grp clickableText">
                    <IonAccordion value="first" className="location-accordian">
                        <IonItem slot="header" className="accordian-location-header">
                            <IonLabel className="location-item-container clickableText">
                                {dashboardLabels.NEEDS_ONBOARDING_LABEL}
                            </IonLabel>
                            <IonBadge color="warning" className="needs-onboarding-count">
                                {needsOnboardingProfiles.length}
                            </IonBadge>
                        </IonItem>
                        {needsOnboardingProfiles.map((profile: any) => {
                            const { locationId, marketName } = profile;
                            return (
                                <IonRow slot="content">
                                    <IonCol
                                        className="ion-padding"
                                        key={locationId}
                                        onClick={() => { onLocationSelectedToOnboard(locationId); }}>
                                        {marketName}
                                    </IonCol>
                                </IonRow>
                            );
                        })}
                    </IonAccordion>
                </IonAccordionGroup>
            )}
        </>
    )
}
export default { menuItems };
