import * as SYSTEMUSERS from '../ActionTypes/systemUsersTypes';
import {
    PutCreatePasswordRequest,
    PutCreatePasswordSuccess,
    PutCreatePasswordFailure,
    FetchSystemUsersRequest,
    FetchSystemUsersSuccessRespsonse,
    FetchSystemUsersSuccess,
    FetchSystemUsersFailure,
    AfterLoginFlowCP,
    AfterLoginFlow,
    AfterLocationChange,
    PatchSystemUsers,
    PatchSystemUsersSuccess,
    PatchSystemUsersFailure,
    PostVerifyTokenRequest,
    PostVerifyTokenSuccess,
    PostVerifyTokenSuccessResponse,
    PostVerifyTokenFailure
} from '../../models/SystemUsers';

/** 
* fetch details
*/
export function fetchSystemUsersRequest(systemUserId: string): FetchSystemUsersRequest {
    return {
        type: SYSTEMUSERS.FETCH_SYSTEMUSERS_REQUEST,
        systemUserId
    };
}
export function fetchSystemUsersSuccess(data: FetchSystemUsersSuccessRespsonse): FetchSystemUsersSuccess {
    return {
        type: SYSTEMUSERS.FETCH_SYSTEMUSERS_SUCCESS,
        data,
    };
}
export function fetchSystemUsersFailure(error: Record<string, any>): FetchSystemUsersFailure {
    return {
        type: SYSTEMUSERS.FETCH_SYSTEMUSERS_FAILURE,
        error,
    };
}

/**
 PATCH CALL
 */
export function patchSystemUsers(systemUserId: string, data: Record<string, any> | {}): PatchSystemUsers {
    return {
        type: SYSTEMUSERS.PATCH_SYSTEMUSERS_REQUEST,
        systemUserId,
        data
    };
}
export function patchSystemUsersSuccess(response: any): PatchSystemUsersSuccess {
    return {
        type: SYSTEMUSERS.PATCH_SYSTEMUSERS_SUCCESS,
        response,
    };
}
export function patchSystemUsersFailure(error: any): PatchSystemUsersFailure {
    return {
        type: SYSTEMUSERS.PATCH_SYSTEMUSERS_FAILURE,
        error: { error: 'Error in saving data, please try after some time', status: error.status },
    };
}

export function afterLoginFlow(systemUserId: string): AfterLoginFlow {
    return {
        type: SYSTEMUSERS.AFTER_LOGIN_FLOW,
        systemUserId
    }
}

export function afterLoginFlowCP(systemUserId: string): AfterLoginFlowCP {
    return {
        type: SYSTEMUSERS.AFTER_LOGIN_FLOW_CP,
        systemUserId
    }
}

export function afterLocationChange(systemUserId: string, data: Record<string, any>): AfterLocationChange {
    return {
        type: SYSTEMUSERS.AFTER_LOCATION_CHANGE,
        systemUserId,
        data,
    }
}

/**
 * verify token post call
*/
export function postVerifyTokenRequest(token: string): PostVerifyTokenRequest {
    return {
        type: SYSTEMUSERS.POST_VERIFYTOKEN_REQUEST,
        token,
    };
}
export function postVerifyTokenSuccess(response: PostVerifyTokenSuccessResponse): PostVerifyTokenSuccess {
    const { systemUserId, email, token } = response;
    return {
        type: SYSTEMUSERS.POST_VERIFYTOKEN_SUCCESS,
        systemUserId,
        email,
        token,
    };
}
export function postVerifyTokenFailure(error: Record<string, any>): PostVerifyTokenFailure {
    return {
        type: SYSTEMUSERS.POST_VERIFYTOKEN_FAILURE,
        error,
    };
}

/**
 * Create password
 */
export function putCreatePasswordRequest(password: any): PutCreatePasswordRequest {
    return {
        type: SYSTEMUSERS.PUT_CREATE_PASSWORD_REQUEST,
        password
    };
}
export function putCreatePasswordSuccess(response: PutCreatePasswordSuccess): PutCreatePasswordSuccess {
    return {
        type: SYSTEMUSERS.PUT_CREATE_PASSWORD_SUCCESS,
        response,
    };
}
export function putCreatePasswordFailure(error: Record<string, any>): PutCreatePasswordFailure {
    return { type: SYSTEMUSERS.PUT_CREATE_PASSWORD_FAILURE, error };
}