import React from "react";
import {
    IonButton,
    IonChip,
    IonCol,
    IonFooter,
    IonGrid,
    IonHeader,
    IonLabel,
    IonModal,
    IonRow,
    IonTitle,
    isPlatform
} from '@ionic/react';
import { CreateOrderProps } from '../../models/CreateOrderProps';
import { isOrderNameValid } from '../../utils';
import { CREATE_ORDER_HEADER, DESCRIPTIVE_ORDER_NAME, FOOTER_CANCEL_BUTTON, FOOTER_SAVE_BUTTON } from '../../constants/constant';

export const CreateOrderPrompt: React.FC<CreateOrderProps> = (props) => {
    const {
        openModal,
        closeModal,
        getDate,
        formErrors,
        orderName,
        setOrderName,
        setFormErrors,
        save
    } = props;
    const { errorMessage } = formErrors;
    const isMobileView = isPlatform("mobile");
    const isTabletView = isPlatform("tablet");
    const isDesktopView = isPlatform("desktop");
    const onChangeHandler = (e: any) => {
        const targetOrder = e.target.value
        const { errorMessage, isValid } = isOrderNameValid(targetOrder);
        setFormErrors({ isValid, errorMessage });
        setOrderName(targetOrder);
    }
    return (
            <IonModal
                isOpen={openModal}
                className={!isDesktopView && !isTabletView ? "user-management-mobiles" : "order-modal-pop"}
                onDidDismiss={() => closeModal(false)}
            >
                <IonHeader className="header">
                    <IonRow className='primary-header'>
                        <IonCol>
                            <IonTitle>{CREATE_ORDER_HEADER}</IonTitle>
                        </IonCol>
                        <IonCol>
                            <IonChip
                                color="primmary"
                                className="ion-button"
                                onClick={() => closeModal(false)}
                            >
                                <p className="cancel dismissed">&times;</p>
                            </IonChip>
                        </IonCol>
                    </IonRow>
                </IonHeader>
                <form className={!isDesktopView && !isTabletView ? "form-mobile-views" : "order-form-body"}>
                    <IonRow className="modal-body">
                        <IonCol offset="1"></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {errorMessage && <span className="validation-error-label">*</span>}
                            <IonLabel className="input-headers">
                                {DESCRIPTIVE_ORDER_NAME}
                            </IonLabel>
                            <input
                                className="order-form-input"
                                type="text"
                                value={orderName}
                                name="orderName"
                                placeholder={getDate}
                                onChange={onChangeHandler}
                                autoComplete="off"
                            >
                            </input>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <span className="validation-error-label">{errorMessage}</span>
                        </IonCol>
                    </IonRow>
                </form>
                <IonFooter className="footer">
                    <IonRow>
                        <IonCol offset="4" sizeMd="4">
                            <IonButton
                                color="gray"
                                expand="block"
                                className="btn-text-cases-cancel"
                                onClick={() => closeModal(false)}
                            >
                                {FOOTER_CANCEL_BUTTON}
                            </IonButton>
                        </IonCol>
                        <IonCol sizeMd="4">
                            <IonButton
                                color="primary"
                                expand="block"
                                type="submit"
                                className="btn-text-cases-save"
                                onClick={() => save(orderName)}
                            >
                                {FOOTER_SAVE_BUTTON}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonFooter>
            </IonModal>
    )
}

