import React, { useEffect, useState } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    IonCol,
    IonRow,isPlatform, IonImg, IonButton, IonInput,
} from "@ionic/react";
import { PostResetPassword, resetPasswordData } from "../../_redux/actions/resetPasswordActions";
import { RootState } from "../../_redux/reducers/rootReducer";
import ToastrHook from "../../components/common/toastr/Toastr";
import { Hotel365Portal, PortalType as SysPortalAppType, SETTING_COLOR_LOGO_RESET_BUTTON, FORGOT_PWD_ERR_MSG } from '../../constants/constant';
import { SytemAppType, validateEmail } from "../../utils/FormValidations";
import { portalType } from "../../auth.config";

export const ForgetPassword: React.FC<any> = () => {
    const isDesktop = isPlatform("desktop");
    const isMobile = isPlatform("mobile");
    const istablet = isPlatform("tablet");
    const dispatch = useDispatch();
    const [emailValue, setEmailValue] = useState();
    const [emailValidation, setEmailValidation] = useState()
    const [renderToastr] = ToastrHook();
    let { pending, error, postResetPasswordStatus } = useSelector((state: RootState) => { return (state.resetPassword) }, shallowEqual)
    const errorEmail = validateEmail(emailValue ? emailValue : '');
    const [apiErrorMsg, setApiErrorMsg] = useState(false)
    const [forgotPwdSuccess, setForgotPwdSuccess] = useState(false);
    const history = useHistory();
    const resetPassword = () => {
        const sytemPortaltype = SytemAppType(portalType)
        if (errorEmail) {
            setEmailValidation(errorEmail.emailError)
        }
        if (emailValue && !errorEmail.emailError) {
            dispatch(PostResetPassword({ email: emailValue, systemAppType: sytemPortaltype }))
        }
    }
    const onChangeEmail = (e: any) => {
        setEmailValue(e.detail.value)
    }
    useEffect(() => {
        if (!pending && postResetPasswordStatus === null && error && error.status === 404) {
            setApiErrorMsg(true)
        }
        if (!pending && postResetPasswordStatus && Object.keys(postResetPasswordStatus).length > 0) {
            renderToastr(Hotel365Portal.RESET_PASSWORD_LINK, "success");
            dispatch(resetPasswordData());
            setForgotPwdSuccess(true)
        }
    }, [postResetPasswordStatus, error, errorEmail]);

    const redirectToLogin = () => {
        dispatch(resetPasswordData());
        history.push(`/login`)
    }
    return (
        <IonRow className="pwd-update-screen h-100 scroll-content">
            <IonCol>
                <IonRow className={isDesktop ? "content" : "content mobile"}>
                    <IonCol
                        sizeLg='4'
                        pushLg="2"
                        sizeXs='12'
                        className={"login-content-padding"}>
                        {portalType === SysPortalAppType.HP && <IonImg src="/assets/logo.svg" />}
                        {portalType === SysPortalAppType.CP && <IonImg src="/assets/Client365.svg" />}
                    </IonCol>
                    {!isMobile && <IonCol
                        className={"login-content-padding " + (isDesktop ? "v-line" : "h-line")}
                        sizeLg='2'
                    />}
                    <IonCol sizeLg='6'>
                        <IonRow className='ion-padding-start reset-main-div'>
                            <IonCol><h1 className="reset-main-header">{Hotel365Portal.FORGOT_PWD_QUESTION} </h1></IonCol>
                        </IonRow>
                        {forgotPwdSuccess ? <>
                            <IonRow className='ion-padding-start reset-main-div'>
                                <IonCol><p className="forget-pass-text"> {Hotel365Portal.FORGOT_PWD_DESCRIPTION_SUCCESS} </p></IonCol>
                            </IonRow>

                            <IonRow className={`ion-padding-start ${istablet ? "ion-padding-end" : ""}`}>
                                <IonCol size="12" sizeLg='6' className="reset-email-text-right">
                                    <IonButton color="secondary" onClick={redirectToLogin}>{Hotel365Portal.RETURN_TO_SIGN_IN}</IonButton>
                                </IonCol>
                            </IonRow>
                        </> : <>
                            <IonRow className='ion-padding-start reset-main-div'>
                                <IonCol><p className="forget-pass-text"> {Hotel365Portal.FORGOT_PWD_DESCRIPTION} </p></IonCol>
                            </IonRow>
                            <IonRow className={`ion-padding-start ${istablet ? "ion-padding-end" : ""}`}>
                                <IonCol className='reset-main-div' sizeLg='6'>
                                    <IonInput
                                        className="forget-email"
                                        type="email"
                                        onIonChange={onChangeEmail}
                                    />
                                </IonCol>
                            </IonRow>
                            <IonRow className={`ion-padding-start ${istablet ? "ion-padding-end" : ""}`}>
                                <IonCol className='reset-main-div' sizeLg='6'>
                                    {emailValidation && <span className="global-red">
                                        {emailValidation}
                                    </span>}
                                    {apiErrorMsg && <span className="global-red">
                                        {FORGOT_PWD_ERR_MSG}
                                    </span>}
                                </IonCol>
                            </IonRow>
                            <IonRow className={`ion-padding-start ${istablet ? "ion-padding-end" : ""}`}>
                                <IonCol size="12" sizeLg='6' className="reset-email-text-right">
                                    <IonButton color="secondary" onClick={resetPassword} > {SETTING_COLOR_LOGO_RESET_BUTTON}</IonButton>                            </IonCol>
                            </IonRow>
                        </>}
                    </IonCol>
                </IonRow>
            </IonCol>
        </IonRow>        
    );
};

export default ForgetPassword

