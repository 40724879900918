export type FETCH_PROFILE_REQUEST = typeof FETCH_PROFILE_REQUEST;
export type FETCH_PROFILE_SUCCESS = typeof FETCH_PROFILE_SUCCESS;
export type FETCH_PROFILE_FAILURE = typeof FETCH_PROFILE_FAILURE;

export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

export type PATCH_PROFILE = typeof PATCH_PROFILE;
export type PATCH_PROFILE_SUCCESS = typeof PATCH_PROFILE_SUCCESS;
export type PATCH_PROFILE_FAILURE = typeof PATCH_PROFILE_FAILURE;

export const PATCH_PROFILE = 'PATCH_PROFILE';
export const PATCH_PROFILE_SUCCESS = 'PATCH_PROFILE_SUCCESS';
export const PATCH_PROFILE_FAILURE = 'PATCH_PROFILE_FAILURE';

export const POST_PROFILE = 'POST_PROFILE';
export const POST_PROFILE_SUCCESS = 'POST_PROFILE_SUCCESS';
export const POST_PROFILE_FAILURE = 'POST_PROFILE_FAILURE';

export type POST_PROFILE = typeof POST_PROFILE;
export type POST_PROFILE_SUCCESS = typeof POST_PROFILE_SUCCESS;
export type POST_PROFILE_FAILURE = typeof POST_PROFILE_FAILURE;

export type FETCH_PROFILES_REQUEST_WITH_SYS_USER_ID = typeof FETCH_PROFILES_REQUEST_WITH_SYS_USER_ID;
export type FETCH_PROFILES_SUCCESS_WITH_SYS_USER_ID = typeof FETCH_PROFILES_SUCCESS_WITH_SYS_USER_ID;
export type FETCH_PROFILES_FAILURE_WITH_SYS_USER_ID = typeof FETCH_PROFILES_FAILURE_WITH_SYS_USER_ID;

export const FETCH_PROFILES_REQUEST_WITH_SYS_USER_ID = 'FETCH_PROFILES_REQUEST_WITH_SYS_USER_ID';
export const FETCH_PROFILES_SUCCESS_WITH_SYS_USER_ID = 'FETCH_PROFILES_SUCCESS_WITH_SYS_USER_ID';
export const FETCH_PROFILES_FAILURE_WITH_SYS_USER_ID = 'FETCH_PROFILES_FAILURE_WITH_SYS_USER_ID';

/**
 * Reset Profile Updation
 */
export type RESET_PROFILE_UPDATION = typeof RESET_PROFILE_UPDATION;
export const RESET_PROFILE_UPDATION = "RESET_PROFILE_UPDATION";
