import React from "react";
import {
    IonCol,
    IonLabel,
    IonNote,
    IonRow,
    IonSelect,
    IonSelectOption,
} from "@ionic/react";

import CurrencyInput from "react-currency-input-field";

// constants
import { ClientPortal } from "../../../constants/constant";
import { ConsumerActionsDescriberData } from "../../../models/ConsumerActions";
import { NoPaymentMethod } from './no-payment-method'
import getSymbolFromCurrencyCode from "../../../i18n/currency-symbol-map";

export function titleDescriptor(): string {
    return ClientPortal.bulkTopOff;
}
export function contentDescriptor(
    props: ConsumerActionsDescriberData
): JSX.Element {
    const {
        topOffBalance,
        updateTopOffBalance,
        topOffPaymentMethod,
        updateTopOffPaymentMethod,
        result,
        isNoPaymentMethodAvailable,
        cards,
        currency
    } = props;
    return (
        <div>
            {!isNoPaymentMethodAvailable ? (
                <React.Fragment>
                    <IonRow>
                        <IonCol>
                            {result &&
                                !result.isValid &&
                                result.amtErrorMessage && (
                                    <span className="validation-error-label">
                                        *
                                    </span>
                                )}
                            <IonLabel className="input-headers">
                                Top off balance up to
                            </IonLabel>
                            <CurrencyInput
                                name={`topoff`}
                                id={`topoff`}
                                value={topOffBalance}
                                className={`consumer-action-textbox-size consumer-action-textbox currency-text-box sc-ion-input-md-h sc-ion-input-md-s md self-operated-product-current`}
                                prefix={getSymbolFromCurrencyCode(currency)}
                                step={1}
                                decimalsLimit={2}
                                decimalScale={2}
                                onValueChange={(value) => {
                                    updateTopOffBalance(value as any);
                                }}
                            />
                            {result && !result.isValid && (
                                <span className="global-red">
                                    {result && result.amtErrorMessage}
                                </span>
                            )}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {result &&
                                !result.isValid &&
                                result.methdErrMessage && (
                                    <span className="validation-error-label">
                                        *
                                    </span>
                                )}
                            <IonLabel className="input-headers">
                                Payment Method
                            </IonLabel>
                            <IonSelect
                                interface="popover"
                                value={topOffPaymentMethod}
                                placeholder="Select"
                                onIonChange={(e) =>
                                    updateTopOffPaymentMethod(e.target.value!)
                                }
                                className="sort-dropdown self-products-select consumer-actions-select"
                            >
                                {cards.map((item: Record<string, any>) => (
                                    <IonSelectOption key={item.systemUserTokenId} value={item.systemUserTokenId}>
                                        {item.name}
                                    </IonSelectOption>
                                ))}
                            </IonSelect>
                            {result && !result.isValid && (
                                <span className="global-red">
                                    {result && result.methdErrMessage}
                                </span>
                            )}
                        </IonCol>
                    </IonRow>
                </React.Fragment>
            ) : (
                <NoPaymentMethod />
            )}
        </div>
    );
}
export default {
    titleDescriptor,
    contentDescriptor,
};
