//vendors
import { useEffect } from 'react';
import { IonCol, IonDatetime } from '@ionic/react';
import { DateTime } from 'luxon';
//constants
import { CUSTOM_DATE_RANGE_ERR } from '../../../constants/constant';

const RangeCalendars: React.FC<any> = ({
    toDateLbl,
    dateObject,
    fromDateLbl,
    setDateObject,
    customDateRangeErr,
    setCustomDateRangeErr
}) => {
    const { fromDate, toDate } = dateObject;

    useEffect(() => {
        const { fromDate, toDate } = dateObject;
        const luxonFromDate = DateTime.fromJSDate(new Date(fromDate));
        const luxonToDate = DateTime.fromJSDate(new Date(toDate));
        if (luxonFromDate.startOf('day') > luxonToDate.startOf('day')) {
            setCustomDateRangeErr(CUSTOM_DATE_RANGE_ERR);
        } else {
            setCustomDateRangeErr('');
        }
    }, [dateObject]);

    return (
        <>
            <IonCol size="4">
                <h6 className="date-range-label ion-padding">{fromDateLbl}</h6>
                <IonDatetime
                    value={fromDate}
                    presentation='date'
                    onIonChange={(e) => {
                        setDateObject((prevState: any) => ({ ...prevState, fromDate: e.detail.value }));
                    }}
                />
            </IonCol>
            <IonCol size="4">
                <h6 className="date-range-label ion-padding">{toDateLbl}</h6>
                <IonDatetime
                    value={toDate}
                    presentation='date'
                    onIonChange={(e) => {
                        setDateObject((prevState: any) => ({ ...prevState, toDate: e.detail.value }));
                    }}
                />
                <span className="validation-error-label">{customDateRangeErr}</span>
            </IonCol>
        </>
    );
}

export default RangeCalendars;