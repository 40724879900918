//post api
export type POST_GENERATE_CART_REQUEST = typeof POST_GENERATE_CART_REQUEST;
export type POST_GENERATE_CART_SUCCESS = typeof POST_GENERATE_CART_SUCCESS;
export type POST_GENERATE_CART_FAILURE = typeof POST_GENERATE_CART_FAILURE;

export const POST_GENERATE_CART_REQUEST = "POST_GENERATE_CART_REQUEST";
export const POST_GENERATE_CART_SUCCESS = "POST_GENERATE_CART_SUCCESS";
export const POST_GENERATE_CART_FAILURE = "POST_GENERATE_CART_FAILURE";

//search api
export type FETCH_SEARCH_PRODUCT_REQUEST = typeof FETCH_SEARCH_PRODUCT_REQUEST;
export type FETCH_SEARCH_PRODUCT_SUCCESS = typeof FETCH_SEARCH_PRODUCT_SUCCESS;
export type FETCH_SEARCH_PRODUCT_FAILURE = typeof FETCH_SEARCH_PRODUCT_FAILURE;

export const FETCH_SEARCH_PRODUCT_REQUEST = "FETCH_SEARCH_PRODUCT_REQUEST";
export const FETCH_SEARCH_PRODUCT_SUCCESS = "FETCH_SEARCH_PRODUCT_SUCCESS";
export const FETCH_SEARCH_PRODUCT_FAILURE = "FETCH_SEARCH_PRODUCT_FAILURE";

//post Checkout api
export type POST_CHECKOUT_CART_REQUEST = typeof POST_CHECKOUT_CART_REQUEST;
export type POST_CHECKOUT_CART_SUCCESS = typeof POST_CHECKOUT_CART_SUCCESS;
export type POST_CHECKOUT_CART_FAILURE = typeof POST_CHECKOUT_CART_FAILURE;

export const POST_CHECKOUT_CART_REQUEST = "POST_CHECKOUT_CART_REQUEST";
export const POST_CHECKOUT_CART_SUCCESS = "POST_CHECKOUT_CART_SUCCESS";
export const POST_CHECKOUT_CART_FAILURE = "POST_CHECKOUT_CART_FAILURE";


/**
 * ReSet pos
 */
 export type RESET_POS = typeof RESET_POS;
 export const RESET_POS = "RESET_POS";