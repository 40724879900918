export default{
    environment:"",
    portalType:'hp',  // Need to change the portal type (cp) and (hp) based on the portal type build
    local:{
        "hp":{
            "REACT_APP_CLIENT_ID" :"hAmncmkn7tuC4vjaRVdb84j5Gqidm6Ab",
            "REACT_APP_APP_ID":"com.smartshop.hotelportal",
        },
        "cp":{
            "REACT_APP_CLIENT_ID" :"ctvPWoasBkTz0gOOSm9QyORZ8ZzrjIxu",
            "REACT_APP_APP_ID":"com.smartshop.clientportal",
        },
        "REACT_APP_API": "https://gateway.test3.365rm.us",
        "REACT_APP_ENV":"dev",
        "REACT_APP_DOMAIN":"test3-auth.365rm.us",
        "REACT_APP_AUDIENCE":"https://gateway.test3.365rm.us",
        "ImageServerBaseUrl":"https://static.test3.365rm.us/images"
    },
    test3:{
        "hp":{
            "REACT_APP_CLIENT_ID" :"hAmncmkn7tuC4vjaRVdb84j5Gqidm6Ab",
            "REACT_APP_APP_ID":"com.smartshop.hotelportal",
        },
        "cp":{
            "REACT_APP_CLIENT_ID" :"ctvPWoasBkTz0gOOSm9QyORZ8ZzrjIxu",
            "REACT_APP_APP_ID":"com.smartshop.clientportal",
        },
        "REACT_APP_API": "https://gateway.test3.365rm.us",
        "REACT_APP_ENV": "dev",
        "REACT_APP_DOMAIN": "test3-auth.365rm.us",
        "REACT_APP_AUDIENCE": "https://gateway.test3.365rm.us",
        "ImageServerBaseUrl":"https://static.test3.365rm.us/images"
    },
    test4:{
        "hp":{
            "REACT_APP_CLIENT_ID" :"qeD8DLOjHDEp8MUIiWcriTGINgP7yuvr",
            "REACT_APP_APP_ID":"com.smartshop.hotelportal",
        },
        "cp":{
            "REACT_APP_CLIENT_ID" :"SrDkNfM0L8MWDN2QJDowFIzuFSLrNKe2",
            "REACT_APP_APP_ID":"com.smartshop.clientportal",
        },
        "REACT_APP_API": "https://gateway.test4.365rm.us",
        "REACT_APP_ENV": "qa",
        "REACT_APP_DOMAIN": "test4-auth.365rm.us",
        "REACT_APP_AUDIENCE": "https://gateway.test4.365rm.us",
        "ImageServerBaseUrl":"https://static.test4.365rm.us/images"
    },
    staging:{
        "hp":{
            "REACT_APP_CLIENT_ID" :"nJWyq1OOffiOv0GORJ2Bng49stYJlfab",// need to create app in auth0 still
            "REACT_APP_APP_ID":"com.smartshop.hotelportal",
        },
        "cp":{
            "REACT_APP_CLIENT_ID" :"7s54xPl4KQpW9Q38PBNzehPFZRY0vdk2",
            "REACT_APP_APP_ID":"com.smartshop.clientportal",
        },
        "REACT_APP_API": "https://gateway.staging.365rm.us",
        "REACT_APP_ENV": "qa",
        "REACT_APP_DOMAIN": "staging-auth.365rm.us",
        "REACT_APP_AUDIENCE": "https://gateway.staging.365rm.us",
        "ImageServerBaseUrl":"https://static.staging.365rm.us/images"
    },
    production:{
        "hp":{
            "REACT_APP_CLIENT_ID" :"lnVmoj7Ubi0ZIi16jcHxQk7U3LHNfeoO",// need to create app in auth0 still
            "REACT_APP_APP_ID":"com.smartshop.hotelportal",
        },
        "cp":{
            "REACT_APP_CLIENT_ID" :"3cZGraEhNZs60qLrIBigjuP1dWjLPvhP",
            "REACT_APP_APP_ID":"com.smartshop.clientportal",
        },
        "REACT_APP_API": "https://gateway.prod.365rm.us",
        "REACT_APP_ENV": "prod",
        "REACT_APP_DOMAIN": "auth.365rm.us",
        "REACT_APP_AUDIENCE": "https://gateway.prod.365rm.us",
        "ImageServerBaseUrl":"https://static.prod.365rm.us/images"
    }

}