import { IonGrid, IonRow, IonCol, IonButton } from "@ionic/react";
import { FOOTER_CANCEL_BUTTON, FOOTER_SAVE_BUTTON } from '../../../constants/constant'

interface FooterTitle {
    onCloseModal: Function;
    onSave: Function;
    isSaveDisabled?: boolean;
    primaryBtnName?: string;
    isSelfOperated?: boolean;
}

const Footer: React.FC<FooterTitle> = (props: FooterTitle) => {
    return (<>
        <IonGrid>
            <IonRow className={props.isSelfOperated ? "self-operated-fields" : ""}>
                <IonCol offset="4" sizeMd={props.isSelfOperated ? "2" : "4"}>
                    <IonButton
                        color="light"
                        expand="block"
                        className="btn-text-cases"
                        disabled={!props.isSelfOperated && props.isSaveDisabled}
                        onClick={() => props.onCloseModal(false)}
                    >
                        {FOOTER_CANCEL_BUTTON}
                    </IonButton>
                </IonCol>
                <IonCol sizeMd={props.isSelfOperated ? "2" : "4"}>
                    <IonButton
                        color="primary"
                        expand="block"
                        type='submit'
                        className="btn-text-cases"
                        disabled={props.isSaveDisabled}
                        onClick={e => props.onSave(e)}
                    >
                        {props.primaryBtnName || FOOTER_SAVE_BUTTON}
                    </IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>
    </>)
}

export default Footer;