import { IonButton, IonIcon, IonLabel } from "@ionic/react";
import React, { useState } from "react";
import ManageColumns from "../../../../components/Product/ManageColumns/Index";
import { eyeOffOutline } from "ionicons/icons";
import { MANAGE_COLUMNS } from '../../../../constants/constant';

const ManageColumnsButton: React.FC<any> = ({
    onHeaderButtonClick,
    current,
    selectCols,
    colNameMapping,
    selectedCols,
    preselectedColumn,
}) => {
    const [buttonState, setButtonState] = useState(false);
    const props = {
        selectCols,
        setButtonState,
        colNameMapping,
        selectedCols,
        preselectedColumn,
    };
    return (
        <>
            <IonButton
                size="small"
                color={buttonState ? "primary" : undefined}
                fill={current === MANAGE_COLUMNS ? "solid" : undefined}
                className="grid-btns"
                onClick={(e) => {
                    onHeaderButtonClick(e);
                    setButtonState((pv) => !pv);
                }}
            >
                {MANAGE_COLUMNS}
                <IonIcon slot="end" icon={eyeOffOutline} />
                <IonLabel slot="end" className="column-count">
                    {preselectedColumn.length}
                </IonLabel>
            </IonButton>
            <ManageColumns {...props} showManageColumnModal={buttonState} />
        </>
    );
};

export default ManageColumnsButton;