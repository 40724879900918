//vendros
import React from "react";
import {
    IonCol,
    IonRow,
    IonIcon,
    IonText,
    IonItem,
    IonButton,
    isPlatform
} from "@ionic/react";
import { chevronBackCircleOutline, chevronForwardCircleOutline } from 'ionicons/icons';
//components
import ReportHeaderBarForMobile from "./ReportHeaderBarForMobile";
import ReportHeaderBarForTablet from './ReportHeaderBarForTablet';
import { BACK_TO_REPORT_LIST, LABEL_REPORTS } from "../../constants/constant";

const ReportHeaderBar: React.FC<any> = ({ setShowSidePanel, showSidePanel, selectedReport, backToReportsList }) => {
    const isMobile = isPlatform("mobile");
    const istablet = isPlatform("tablet");
    const isDesktop = isPlatform("desktop")
    return (
        <IonRow>
            <IonCol size="12" sizeMd="12" sizeSm="6">
                {isMobile && !istablet ?
                    <ReportHeaderBarForMobile
                        setShowSidePanel={setShowSidePanel}
                        showSidePanel={showSidePanel}
                        selectedReport={selectedReport}
                        backToReportsList={backToReportsList}
                    /> : istablet && !isDesktop ? <ReportHeaderBarForTablet
                        setShowSidePanel={setShowSidePanel}
                        showSidePanel={showSidePanel}
                        selectedReport={selectedReport}
                        backToReportsList={backToReportsList}
                    /> :
                        <IonItem lines="none" className="ion-no-padding">
                            <IonIcon
                                className="side-nav-icon click-cursor"
                                onClick={() => setShowSidePanel((prevState: any) => !prevState)}
                                icon={showSidePanel ? chevronBackCircleOutline : chevronForwardCircleOutline}
                            />
                            <IonText className={"reports-header-text"}>{selectedReport.name || LABEL_REPORTS}</IonText>
                            {selectedReport.name &&
                                <IonButton
                                    fill="clear"
                                    className={"back-to-reports-btn"}
                                    onClick={backToReportsList}
                                >
                                    {BACK_TO_REPORT_LIST}
                                </IonButton>
                            }
                        </IonItem>
                }
            </IonCol>
        </IonRow >
    )
}
export default ReportHeaderBar