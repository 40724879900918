import { useAuth0 } from "@auth0/auth0-react";
import { Browser } from "@capacitor/browser";
import { IonContent, IonPage, IonCol, IonRow } from "@ionic/react";


const SuccessPage: React.FC = () => {
  const { loginWithRedirect  } = useAuth0();

  const login = async () => {
    // Ask auth0-react to build the login URL
    // const url = await buildAuthorizeUrl();
    // await Browser.open({ url });

    loginWithRedirect({
      async openUrl(url) {
        await Browser.open({ url });
      },
    });
  };
  return (
    <IonPage>
      <IonContent>
        <IonRow>
          <IonCol size="4"></IonCol>
          <IonCol className="center-content">
            <h1>Signup process has completed.</h1>
            <br />
            <button className="login-button" onClick={login}>
              Click here to Login
            </button>
          </IonCol>
          <IonCol size="4"></IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default SuccessPage;
