import React from "react";
import "./scss/ScreenTitle.scss";
import { IonCol, IonImg, isPlatform } from "@ionic/react";
import { SignupLeftSectionProps } from '../../models/SignupProps';
import { portalType } from "../../auth.config";
import { PortalType as SysPortalAppType } from "../../constants/constant";

const OnboardingLeftSection: React.FC<SignupLeftSectionProps> = ({ title, secondaryTitle, colSize }) => {
    const isMobile = isPlatform("mobile");
    return (
        <IonCol size={colSize} className={isMobile ? "ion-padding-start left-section" : "ion-padding-end left-section"}>
            {portalType === SysPortalAppType.HP && <IonImg className="img-log" src="/assets/logo.svg" />}
            {portalType === SysPortalAppType.CP && <IonImg className="img-log" src="/assets/Client365.svg" />}
            <div className={isMobile ? "onboarding-left-section-text-mobile" : "onboarding-left-section-text"}>
                {Array.isArray(title) ?
                    title.map((titleItem: any, index: any) => <h2 className="bold-h1-32" key={index}>{titleItem}</h2>) : <h2 className="bold-h1-32" >{title}</h2>}
                {secondaryTitle && <p>{secondaryTitle}</p>}
            </div>
        </IonCol>
    )
}

export default OnboardingLeftSection;