//post api
export type POST_USER_REQUEST = typeof POST_USER_REQUEST;
export type POST_USER_SUCCESS = typeof POST_USER_SUCCESS;
export type POST_USER_FAILURE = typeof POST_USER_FAILURE;

export const POST_USER_REQUEST = "POST_USER_REQUEST";
export const POST_USER_SUCCESS = "POST_USER_SUCCESS";
export const POST_USER_FAILURE = "POST_USER_FAILURE";
//edit api

export type PATCH_USER_REQUEST = typeof PATCH_USER_REQUEST;
export type PATCH_USER_SUCCESS = typeof PATCH_USER_SUCCESS;
export type PATCH_USER_FAILURE = typeof PATCH_USER_FAILURE;

export const PATCH_USER_REQUEST = "PATCH_USER_REQUEST";
export const PATCH_USER_SUCCESS = "PATCH_USER_SUCCESS";
export const PATCH_USER_FAILURE = "PATCH_USER_FAILURE";

//fetch api
export type FETCH_USERS_REQUEST = typeof FETCH_USERS_REQUEST;
export type FETCH_USERS_SUCCESS = typeof FETCH_USERS_SUCCESS;
export type FETCH_USERS_FAILURE = typeof FETCH_USERS_FAILURE;

export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

/*
delete api
*/
export type DELETE_USER_REQUEST = typeof DELETE_USER_REQUEST;
export type DELETE_USER_SUCCESS = typeof DELETE_USER_SUCCESS;
export type DELETE_USER_FAILURE = typeof DELETE_USER_FAILURE;

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

/*
resend api
*/
export type RESEND_INVITE_LINK_REQUEST = typeof RESEND_INVITE_LINK_REQUEST;
export type RESEND_INVITE_LINK_SUCCESS = typeof RESEND_INVITE_LINK_SUCCESS;
export type RESEND_INVITE_LINK_FAILURE = typeof RESEND_INVITE_LINK_FAILURE;

export const RESEND_INVITE_LINK_REQUEST = "RESEND_INVITE_LINK_REQUEST";
export const RESEND_INVITE_LINK_SUCCESS = "RESEND_INVITE_LINK_SUCCESS";
export const RESEND_INVITE_LINK_FAILURE = "RESEND_INVITE_LINK_FAILURE";

//fetch system user api
export type FETCH_USERDETAIL_REQUEST = typeof FETCH_USERDETAIL_REQUEST;
export type FETCH_USERDETAIL_SUCCESS = typeof FETCH_USERDETAIL_SUCCESS;
export type FETCH_USERDETAIL_FAILURE = typeof FETCH_USERDETAIL_FAILURE;

export const FETCH_USERDETAIL_REQUEST = "FETCH_USERDETAIL_REQUEST";
export const FETCH_USERDETAIL_SUCCESS = "FETCH_USERDETAIL_SUCCESS";
export const FETCH_USERDETAIL_FAILURE = "FETCH_USERDETAIL_FAILURE";

//edit Role api

export type PUT_USER_ROLE_REQUEST = typeof PUT_USER_ROLE_REQUEST;
export type PUT_USER_ROLE_SUCCESS = typeof PUT_USER_ROLE_SUCCESS;
export type PUT_USER_ROLE_FAILURE = typeof PUT_USER_ROLE_FAILURE;

export const PUT_USER_ROLE_REQUEST = "PUT_USER_ROLE_REQUEST";
export const PUT_USER_ROLE_SUCCESS = "PUT_USER_ROLE_SUCCESS";
export const PUT_USER_ROLE_FAILURE = "PUT_USER_ROLE_FAILURE";

//fetch system user api location
export type FETCH_USERDETAIL_LOCATION_REQUEST = typeof FETCH_USERDETAIL_LOCATION_REQUEST;
export type FETCH_USERDETAIL_LOCATION_SUCCESS = typeof FETCH_USERDETAIL_LOCATION_SUCCESS;
export type FETCH_USERDETAIL_LOCATION_FAILURE = typeof FETCH_USERDETAIL_LOCATION_FAILURE;

export const FETCH_USERDETAIL_LOCATION_REQUEST = "FETCH_USERDETAIL_LOCATION_REQUEST";
export const FETCH_USERDETAIL_LOCATION_SUCCESS = "FETCH_USERDETAIL_LOCATION_SUCCESS";
export const FETCH_USERDETAIL_LOCATION_FAILURE = "FETCH_USERDETAIL_LOCATION_FAILURE";



//update system user location api
export type PUT_USERDETAIL_LOCATION_REQUEST = typeof PUT_USERDETAIL_LOCATION_REQUEST;
export type PUT_USERDETAIL_LOCATION_SUCCESS = typeof PUT_USERDETAIL_LOCATION_SUCCESS;
export type PUT_USERDETAIL_LOCATION_FAILURE = typeof PUT_USERDETAIL_LOCATION_FAILURE;

export const PUT_USERDETAIL_LOCATION_REQUEST = "PUT_USERDETAIL_LOCATION_REQUEST";
export const PUT_USERDETAIL_LOCATION_SUCCESS = "PUT_USERDETAIL_LOCATION_SUCCESS";
export const PUT_USERDETAIL_LOCATION_FAILURE = "PUT_USERDETAIL_LOCATION_FAILURE";


//update system user organization api
export type PUT_USERDETAIL_ORGANIZATION_REQUEST = typeof PUT_USERDETAIL_ORGANIZATION_REQUEST;
export type PUT_USERDETAIL_ORGANIZATION_SUCCESS = typeof PUT_USERDETAIL_ORGANIZATION_SUCCESS;
export type PUT_USERDETAIL_ORGANIZATION_FAILURE = typeof PUT_USERDETAIL_ORGANIZATION_FAILURE;

export const PUT_USERDETAIL_ORGANIZATION_REQUEST = "PUT_USERDETAIL_ORGANIZATION_REQUEST";
export const PUT_USERDETAIL_ORGANIZATION_SUCCESS = "PUT_USERDETAIL_ORGANIZATION_SUCCESS";
export const PUT_USERDETAIL_ORGANIZATION_FAILURE = "PUT_USERDETAIL_ORGANIZATION_FAILURE";



/**
 * ReSet fetch users
 */
 export type RESET_FETCH_USERS = typeof RESET_FETCH_USERS;
 export const RESET_FETCH_USERS = "RESET_FETCH_USERS";


 
//update system user EMAIL api
export type POST_USERDETAIL_EMAIL_REQUEST = typeof POST_USERDETAIL_EMAIL_REQUEST;
export type POST_USERDETAIL_EMAIL_SUCCESS = typeof POST_USERDETAIL_EMAIL_SUCCESS;
export type POST_USERDETAIL_EMAIL_FAILURE = typeof POST_USERDETAIL_EMAIL_FAILURE;

export const POST_USERDETAIL_EMAIL_REQUEST = "POST_USERDETAIL_EMAIL_REQUEST";
export const POST_USERDETAIL_EMAIL_SUCCESS = "POST_USERDETAIL_EMAIL_SUCCESS";
export const POST_USERDETAIL_EMAIL_FAILURE = "POST_USERDETAIL_EMAIL_FAILURE";

export const SET_LOCATION_FOR_CP = 'SET_LOCATION_FOR_CP';
export type SET_LOCATION_FOR_CP = typeof SET_LOCATION_FOR_CP