import { IonAlert } from '@ionic/react'
import React from 'react'
import { Prompt } from 'react-router-dom';
//constants
import {
  DETECT_UNSAVE_HEADER,
  DETECT_UNSAVE_MESSAGE,
  FOOTER_CANCEL_BUTTON,
  GLOBAL_OK_BUTTON
} from "../../constants/constant";
export class RouteLeavingGuard extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      modalVisible: false,
      lastLocation: null,
      confirmedNavigation: false,
    }
  }

  showModal = (location: any) => this.setState({
    modalVisible: true,
    lastLocation: location,
  })
  closeModal = (callback: any) => this.setState({
    modalVisible: false,
    confirmedNavigation: false
  }, callback)
  handleBlockedNavigation = (nextLocation: any) => {
    const { confirmedNavigation } = this.state
    const { shouldBlockNavigation } = this.props
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      this.showModal(nextLocation)
      return false
    }

    return true
  }
  handleConfirmNavigationClick = () => this.closeModal(() => {
    const { navigate } = this.props
    const { lastLocation } = this.state

    if (lastLocation) {
      this.setState({
        confirmedNavigation: true
      }, () => {
        // Navigate to the previous blocked location with your navigate function     
        navigate(lastLocation.pathname)
      })
    }
  })
  componentDidUpdate(previousProps: any, previousState: any) {
    if (previousProps.when !== this.props.when) {
      this.setState({ confirmedNavigation: false })
    }
  }
  render() {
    const { when, message, promptContent, btnCancel, btnOk, btnCancelStyles, type ='' } = this.props;
    const { modalVisible } = this.state;
    const cancelBtnClass = btnCancelStyles ? `${btnCancelStyles} btn-text-cases` : 'btn-text-cases cancel-btn';
    return (
      <>
        <Prompt
          when={when}
          message={this.handleBlockedNavigation} />
        <IonAlert
          isOpen={modalVisible}
          cssClass='guard-restrict'
          header={message || DETECT_UNSAVE_HEADER}
          message={promptContent || DETECT_UNSAVE_MESSAGE}
          buttons={[
            {
              text: btnCancel || FOOTER_CANCEL_BUTTON,
              role: 'cancel',
              cssClass: cancelBtnClass,
              id: 'cancel-button',
              handler: type? this.handleConfirmNavigationClick : this.closeModal
            },
            {
              text: btnOk || GLOBAL_OK_BUTTON,
              id: 'confirm-button',
              cssClass: "btn-text-cases confirm-btn",
              handler: type? this.closeModal:this.handleConfirmNavigationClick
            }
          ]}
        />
      </>
    )
  }
}
export default RouteLeavingGuard