//vendors
import React, { useCallback, useEffect, useState } from "react";
import {
    IonCol,
    IonPage,
    IonRow,
    IonItem,
    IonLabel,
    IonSpinner,
    IonIcon,
    isPlatform,
} from "@ionic/react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
//components
import { ManagerOrderSidenav } from "../../../components/NavigationProcess/ManagerOrderSidenav";
import ToastrHook from "../../../components/common/toastr/Toastr";
import OrderDetails from "../../../components/OrderDetails/OrderDetails";
import FooterComponent from "../../../components/FooterComponent";
//actions
import {
    fetchMarketOrderHeader,
    resetMarketOrder,
    postMarketOrderRequest,
    fetchMarketOrderProducts
} from "../../../_redux/actions/orderActions";
import {
    FetchGlobalProducts,
    FetchGlobalProductsCategories,
    FetchMarketProducts
} from "../../../_redux/actions/productActions";
//reducer
import { RootState } from "../../../_redux/reducers/rootReducer";
//constants
import {
    Hotel365Portal,
    DUPLICATE_ORDER_WARNING,
    GLOBAL_NO_CHANGES_TO_SAVE,
    GLOBAL_SUBMIT_TEXT,
    MARKET_ORDER_HEADER_SUCCESS,
    NO_ORDER_TO_DISPLAY,
    ORDER_ALREADY_OPEN_WARNING,
    ORDER_DEACTIVATED_PRODUCT,
    ORDER_PRODUCT_REMOVED_SUCCESS,
    ORDER_PRODUCT_SAVE_SUCCESS,
    ORDER_SUBMIT_SUCCESS,
    ORDER_SWAP_DUPLICATE_ENTRY,
    ORDER_WITH_ZERO_QTY,
    PRODUCT_ORDER_UPDATE_SUCCESS,
    fixPositionTwoDecimal
} from "../../../constants/constant";
import { chevronBackCircleOutline, chevronForwardCircleOutline } from 'ionicons/icons';
import { getLocalizedNumericValues } from "../../../utils";
const OrdersPage: React.FC = () => {
    const dispatch = useDispatch();
    const [renderToastr] = ToastrHook();
    const [finalOrderExpense, setFinalOrderExpense] = useState(0);
    const [sidenavExpanded, setSidenavExpanded] = useState<boolean>(true);
    const isDesktopView = isPlatform("desktop");
    const isTabletView = isPlatform("tablet");
    const isMobileView = isPlatform("mobile");
    const profile = useSelector((state: RootState) => {
        return state.profile;
    }, shallowEqual);
    const {
        marketTheme,
        locationId: locationID,
        orgId,
        currency,
        additionalDetails
    } = profile && profile.currentProfile;
    const order = useSelector((state: RootState) => state.order, shallowEqual);
    const product = useSelector((state: RootState) => state.product, shallowEqual);
    const { createdMarketProduct, marketProducts } = product;
    const ordeTotal = getLocalizedNumericValues(finalOrderExpense, "currency", currency, fixPositionTwoDecimal);
    const {
        pending,
        error,
        postMarketOrderHeader,
        postAutoOrderHeader,
        submittedMarketOrder,
        fetchMarketOrder,
        removedMarketOrderProduct,
        patchMarketOrderProduct: updateMarketOrderProduct,
        fetchMarketOrderHeaders,
        createdMarketOrderProduct,
        fetchMarketOrderProducts: orderProducts
    } = order;

    const marketOrderHeaderId = fetchMarketOrderHeaders && fetchMarketOrderHeaders[0] && fetchMarketOrderHeaders[0].marketOrderHeaderId;

    const { primaryColorHex } = marketTheme;
    const { hasPreventOrdersUntilInventoryTaken } = additionalDetails;
    const userDetails = useSelector((state: RootState) => { return state.systemUsers }, shallowEqual);
    const toggleAccordion = () => {
        setSidenavExpanded(!sidenavExpanded);
    };
    const checkPostMarketOrderHeader = useCallback(() => {
        return (
            postMarketOrderHeader &&
            Object.keys(postMarketOrderHeader).length > 0
        );
    }, [postMarketOrderHeader]);

    const checkPostAutoOrderHeader = useCallback(() => {
        return (
            postAutoOrderHeader &&
            Object.keys(postAutoOrderHeader).length > 0
        );
    }, [postAutoOrderHeader]);

    const checkRemovedMarketProductsOrder = useCallback(() => {
        return (
            removedMarketOrderProduct &&
            Object.keys(removedMarketOrderProduct).length > 0
        );
    }, [removedMarketOrderProduct]);

    const checkPatchMarketOrderProduct = useCallback(() => {
        return (
            updateMarketOrderProduct &&
            Object.keys(updateMarketOrderProduct).length > 0
        );
    }, [updateMarketOrderProduct]);

    const checkSubmittedMarketOrder = useCallback(() => {
        return (
            submittedMarketOrder && Object.keys(submittedMarketOrder).length > 0
        );
    }, [submittedMarketOrder]);

    useEffect(() => {
        let errorMessage = "";
        if (error && Object.keys(error).length > 0) {
            if (error.status === 401) {
                errorMessage = Hotel365Portal.NOT_AUTHORIZED_ERR;
            } else if (error.status === 409) {
                errorMessage = DUPLICATE_ORDER_WARNING;
            } else if (error?.error?.status === 422 && error.type === "post_market_header_failure") {
                errorMessage = ORDER_ALREADY_OPEN_WARNING;
            } else if (error.status === 422 && error.type !== "post_market_header_failure") {
                errorMessage = ORDER_DEACTIVATED_PRODUCT;
            } else {
                errorMessage = Hotel365Portal.SERVICE_UNAVAILABLE_ERR;
            }
            renderToastr(errorMessage, "danger");
        }
        !error &&
            (checkPostMarketOrderHeader() || checkPostAutoOrderHeader()) &&
            renderToastr(MARKET_ORDER_HEADER_SUCCESS, "success");

        !error &&
            checkRemovedMarketProductsOrder() &&
            renderToastr(ORDER_PRODUCT_REMOVED_SUCCESS, "success");
        !error &&
            checkPatchMarketOrderProduct() &&
            renderToastr(PRODUCT_ORDER_UPDATE_SUCCESS, "success");
        !error &&
            checkSubmittedMarketOrder() &&
            renderToastr(ORDER_SUBMIT_SUCCESS, "success");

        setTimeout(() => {
            if (
                !error &&
                (checkPostMarketOrderHeader() ||
                    checkRemovedMarketProductsOrder() ||
                    checkSubmittedMarketOrder() || checkPostAutoOrderHeader())
            ) {
                dispatch(resetMarketOrder());
                dispatch(fetchMarketOrderHeader(locationID, orgId, 0));
            }
        }, 2000);

        let errorMessages = "";
        if (product.error && Object.keys(product.error).length > 0) {
            if (product.error && ["save_market_duplicate_failure"].indexOf(product.error.type) > -1) {
                errorMessages = ORDER_SWAP_DUPLICATE_ENTRY;
            } else {
                errorMessages =
                    (error && error.status === 401) ||
                        (product.error && product.error.status === 401)
                        ? Hotel365Portal.NOT_AUTHORIZED_ERR
                        : Hotel365Portal.SERVICE_UNAVAILABLE_ERR;
            }
            renderToastr(errorMessages, "danger");
        }
    }, [
        error,
        postMarketOrderHeader,
        postAutoOrderHeader,
        removedMarketOrderProduct,
        updateMarketOrderProduct,
        submittedMarketOrder,
        product.error,
        dispatch,
    ]);

    useEffect(() => {
        dispatch(resetMarketOrder([]));
        if (locationID) dispatch(fetchMarketOrderHeader(locationID, orgId, 0));
    }, [dispatch, locationID]);

    const checkCreatedMarketOrderProduct = useCallback(() => {
        return createdMarketOrderProduct && Object.keys(createdMarketOrderProduct).length > 0
    }, [createdMarketOrderProduct]);

    const checkCreatedMarketProduct = useCallback(() => {
        return createdMarketProduct && Object.keys(createdMarketProduct).length > 0
    }, [createdMarketProduct])

    useEffect(() => {
        if (checkCreatedMarketOrderProduct()) {
            renderToastr(ORDER_PRODUCT_SAVE_SUCCESS, "success");
            dispatch(fetchMarketOrderProducts(marketOrderHeaderId, 0));
        }
        if (checkCreatedMarketProduct()) {
            dispatch(FetchMarketProducts(0));
        }
    }, [createdMarketOrderProduct, createdMarketProduct])

    /** commented: componentunmount*/
    useEffect(() => {
        return () => {
            dispatch(resetMarketOrder([]));
        };
    }, []);

    const submitOrders = () => {
        if (finalOrderExpense === 0) {
            renderToastr(GLOBAL_NO_CHANGES_TO_SAVE, "warning");
            return false;
        }
        const checkQtyWithZero = orderProducts.every((i: Record<string, any>) => i.qty != 0);
        if (!checkQtyWithZero) {
            renderToastr(ORDER_WITH_ZERO_QTY, "warning");
            return false;
        }
        const request = {
            "systemUserId": userDetails.systemUserId,
        }
        /** commented : need below dispatch  */
        dispatch(postMarketOrderRequest(fetchMarketOrder?.marketOrderHeaderId || '', request))
    };

    const checkShowOrderSection = () => {
        if (fetchMarketOrder && Object.keys(fetchMarketOrder).length > 0 && ['pending'].indexOf(fetchMarketOrder?.marketOrderStatus?.status.toLowerCase()) > -1) {
            if (finalOrderExpense === 0) {
                return false;
            } else {
                return true;
            }

        } else {
            return false;
        }
    }

    const isCheckMarketAndGlobalData = useCallback(() => {
        dispatch(FetchMarketProducts(0));
        dispatch(FetchGlobalProducts(null, ""));
        dispatch(FetchGlobalProductsCategories("", 0));
    }, [])

    useEffect(() => {
        if (marketProducts.length === 0) {
            isCheckMarketAndGlobalData()
        }
    }, [isCheckMarketAndGlobalData])
    return (
        <IonPage>
            {pending && (
                <IonSpinner name="circles" className="spinner-circles" />
            )}
            {!pending && (
                <React.Fragment>
                    <IonRow style={{height: isTabletView? "92%": "90%" }}>
                        <IonCol 
                            sizeXs={sidenavExpanded? "12": "0"}
                            sizeMd={!sidenavExpanded? "0": "4"}
                            sizeLg="2.35"
                            className={isDesktopView || isTabletView ? 'padding-0 sidenav-size' : 'padding-0'}>
                            <ManagerOrderSidenav
                                setSidenavExpanded={setSidenavExpanded}
                                sidenavExpanded={sidenavExpanded}
                            />
                        </IonCol>
                        <IonCol
                            size={isDesktopView && sidenavExpanded ? "9" : "12"}
                            sizeMd={
                                sidenavExpanded && isTabletView ? "7.9" : "12"
                            }
                            sizeXl={sidenavExpanded ? "9.65" : "12"}
                            sizeXs={sidenavExpanded ? "0": "12"}
                            className="order-content"
                        >
                            {fetchMarketOrder &&
                                Object.keys(fetchMarketOrder).length > 0 && (
                                    <IonCol
                                        size={"9"}
                                        style={{ height: "100%" }}
                                    >
                                        <OrderDetails
                                            primaryColorHex={primaryColorHex}
                                            toggleAccordion={toggleAccordion}
                                            setSidenavExpanded={
                                                setSidenavExpanded
                                            }
                                            sidenavExpanded={sidenavExpanded}
                                            setFinalTotal={(data: number) =>
                                                setFinalOrderExpense(data)
                                            }
                                        />
                                    </IonCol>
                                )}
                            {fetchMarketOrderHeaders &&
                                fetchMarketOrderHeaders.length === 0 &&
                                fetchMarketOrder &&
                                Object.keys(fetchMarketOrder).length === 0 ? (
                                <IonCol>
                                    <IonCol
                                        className="orderNav-icon click-cursor"
                                        size="1"
                                        sizeMd="2"
                                        sizeXs="4"
                                    >
                                        <IonIcon
                                            onClick={toggleAccordion}
                                            size="large"
                                            md={
                                                sidenavExpanded
                                                    ? chevronBackCircleOutline
                                                    : chevronForwardCircleOutline
                                            }
                                        />
                                    </IonCol>
                                    <IonRow style={{ height: "20%" }}>
                                        <IonCol
                                            size="12"
                                            className="no-orders-label"
                                        >
                                            <IonItem lines="none">
                                                <IonLabel>
                                                    <b>
                                                        {NO_ORDER_TO_DISPLAY}
                                                    </b>
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            ) : (
                                <IonSpinner
                                    name="circles"
                                    className="spinner-circles"
                                />
                            )}
                        </IonCol>
                    </IonRow>

                    <FooterComponent
                        buttonLabel={GLOBAL_SUBMIT_TEXT}
                        submitCallBack={submitOrders}
                        footerClassName={
                            isDesktopView || isMobileView && !isTabletView
                                ? "order-form-footer"
                                : "order-form-footer-tablet"
                        }
                        showPreventOrderBanner={
                            fetchMarketOrder &&
                                Object.keys(fetchMarketOrder).length > 0
                                ? hasPreventOrdersUntilInventoryTaken &&
                                ["pending"].indexOf(
                                    fetchMarketOrder?.marketOrderStatus?.status.toLowerCase()
                                ) > -1
                                : false
                        }
                        showOrderTotal={ordeTotal}
                        showOrderSection={
                            fetchMarketOrder &&
                                Object.keys(fetchMarketOrder).length > 0
                                ? checkShowOrderSection()
                                : false
                        }
                    />
                </React.Fragment>
            )
            }
        </IonPage >
    );
};

export default OrdersPage;
