import * as PAYMENT from '../ActionTypes/paymentTypes';
import {
    PostAddCardRequest,
    PostAddCardSuccess,
    PostAddCardFailure,
    GetPublicKeyRequest,
    GetPublicKeySuccess,
    GetPublicKeyFailure,
    GetSysUserTokensRequest,
    GetSysUserTokensSuccess,
    GetSysUserTokensFailure,
    DetachCardRequest,
    DetachCardSuccess,
    DetachCardFailure,
    AddCardFlow,
    DeleteCardFlow,
    GetUserData
} from "../../models/Payment";

/**
 * GET PUBLIC KEY
 */
export function fetchPublicKeyRequest(locationId: string): GetPublicKeyRequest {
    return {
        type: PAYMENT.GET_PUBLIC_KEY_REQUEST,
        locationId
    };
}
export function fetchPublicKeySuccess(response: Record<string, any>): GetPublicKeySuccess {
    return {
        type: PAYMENT.GET_PUBLIC_KEY_SUCCESS,
        response
    };
}
export function fetchPublicKeyFailure(error: Record<string, any>): GetPublicKeyFailure {
    return {
        type: PAYMENT.GET_PUBLIC_KEY_FAILURE,
        error
    };
}

/**
 * GET SYSTEM USER TOKENS
 */
export function fetchSysUserTokensRequest(systemUserId: string): GetSysUserTokensRequest {
    return {
        type: PAYMENT.GET_SYSUSER_TOKENS_REQUEST,
        systemUserId
    };
}
export function fetchSysUserTokensSuccess(response: Array<Record<string, any>>): GetSysUserTokensSuccess {
    return {
        type: PAYMENT.GET_SYSUSER_TOKENS_SUCCESS,
        response
    };
}
export function fetchSysUserTokensFailure(error: Record<string, any>): GetSysUserTokensFailure {
    return {
        type: PAYMENT.GET_SYSUSER_TOKENS_FAILURE,
        error
    };
}

/**
 * POST ADD CARD
 */
export function postAddCardRequest(payload: Record<string, any> | {}): PostAddCardRequest {
    return {
        type: PAYMENT.POST_ADDCARD_REQUEST,
        payload
    };
}
export function postAddCardSuccess(response: Record<string, any>): PostAddCardSuccess {
    return {
        type: PAYMENT.POST_ADDCARD_SUCCESS,
        response,
    };
}
export function postAddCardFailure(error: Record<string, any>): PostAddCardFailure {
    return {
        type: PAYMENT.POST_ADDCARD_FAILURE,
        error
    };
}

/**
 * DELETE CARD
 */
export function detachCardRequest(payload: Record<string, any> | {}): DetachCardRequest {
    return {
        type: PAYMENT.DETACH_CARD_REQUEST,
        payload
    };
}
export function detachCardSuccess(response: Record<string, any>): DetachCardSuccess {
    return {
        type: PAYMENT.DETACH_CARD_SUCCESS,
        response,
    };
}
export function detachCardFailure(error: Record<string, any>): DetachCardFailure {
    return {
        type: PAYMENT.DETACH_CARD_FAILURE,
        error
    };
}

/**
 * ADD CARD FLOW
 */
export function addCardFlow(payload: Record<string, any>): AddCardFlow {
    return {
        type: PAYMENT.ADD_CARD_FLOW,
        payload
    }
}

/**
 * DELETE CARD FLOW
 */
export function deleteCardFlow(payload: Record<string, any>): DeleteCardFlow {
    return {
        type: PAYMENT.DELETE_CARD_FLOW,
        payload
    }
}

export function getUserData(userData: Record<string, any>): GetUserData {
    return {
        type: PAYMENT.GET_USER_DATA,
        userData
    };
}