export interface IErrorReducer {
    error: Record<string, any> | null;
}

export const initialState = {
    error: null
};

export default function errorReducer(state: IErrorReducer = initialState, action: any) {
    const { error } = action;
    if (error) {
        return { ...state, ...error }
    }
    return state;
}