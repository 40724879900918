import { toExcelProductsHeaders } from '../../../../constants/constant'
import { getLocalizedNumericValues } from '../../../../utils';
export class DataGridSharedData {
    private original: ({} | null)[];
    private columnList: {};
    private columnMappingList: {};
    private showTaxes;
    private productList: ({} | null)[];

    constructor(products: []) {
        this.columnMappingList = { ...toExcelProductsHeaders };
        function getMappings(keys: []) {
            let keyObj = {}
            // @ts-ignore
            keys.forEach((k: any) => keyObj[k] = k.toUpperCase())
        }
        // @ts-ignore
        this.columnList = products.length > 0 ? Object.keys(this.columnMappingList) : [];
        this.productList = JSON.parse(JSON.stringify(products));
        this.original = JSON.parse(JSON.stringify(this.productList));
        this.showTaxes = true;
    }
    private toggleTaxMode() {
        this.showTaxes = !this.showTaxes;
        if (this.showTaxes) {
            this.productList = [...this.original]
        }
    }

    private getProductList(currency: string) {
        const products = this.productList;
        return products.map((item: any) => {
            const tempItem = { ...item };
            const updatedPrice = getLocalizedNumericValues(tempItem.calculatedPrice, "currency", currency);
            const updatedDeposit = getLocalizedNumericValues(tempItem.deposit, "currency", currency);
            const updatedCost = getLocalizedNumericValues(tempItem.price, "currency", currency);
            const updatedTax = getLocalizedNumericValues(tempItem.tax, "percent", currency);
            const updatedTax2 = getLocalizedNumericValues(tempItem.tax2, "percent", currency);
            const updatedTax3 = getLocalizedNumericValues(tempItem.tax3, "percent", currency);
            const updatedTax4 = getLocalizedNumericValues(tempItem.tax4, "percent", currency);
            tempItem.cost = updatedCost;
            tempItem.price = updatedPrice;
            tempItem.deposit = updatedDeposit;
            tempItem.tax = updatedTax;
            tempItem.tax2 = updatedTax2;
            tempItem.tax3 = updatedTax3;
            tempItem.tax4 = updatedTax4;
            return tempItem;
        });
    }

    private setProductList() {
        const upd = this.productList.map(ob => {
            // @ts-ignore
            let local = { ...ob } || null;
            for (let k in local) {
                // @ts-ignore
                if (!this.columnList[k]) {
                    // @ts-ignore
                    delete local[k]
                }
            }
            return local;
        })
        this.productList = upd
    }

    private setColumnList(obj: Object) {
        this.columnList = obj || null;
    }

    private getColumnList() {
        return this.columnList
    }
}