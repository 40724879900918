import * as CONSUMER from "../ActionTypes/consumerTypes";
import { Balances, Consumer, ConsumerList, ConsumerLists, Identifiers, PayrollGrp, SubsidyGrp } from "../../models/Consumer";


/**
 *  fetch consumer list
 * */
export interface FetchConsumerList {
    type: CONSUMER.FETCH_CONSUMER_LISTS_REQUEST;
    data: Record<string, any>;
}
export interface FetchConsumerListSuccess {
    type: CONSUMER.FETCH_CONSUMER_LISTS_SUCCESS;
    data: ConsumerLists;
}
export interface FetchConsumerListFailure {
    type: CONSUMER.FETCH_CONSUMER_LISTS_FAILURE;
    error: Record<string, any> | null;
}
export type FetchConsumerListType =
    | FetchConsumerList
    | FetchConsumerListSuccess
    | FetchConsumerListFailure;
export function fetchConsumerList(
    data: Record<string, any>
): FetchConsumerList {
    return {
        type: CONSUMER.FETCH_CONSUMER_LISTS_REQUEST,
        data,
    };
}
export function fetchConsumerListSuccess(
    data: ConsumerLists
): FetchConsumerListSuccess {
    return {
        type: CONSUMER.FETCH_CONSUMER_LISTS_SUCCESS,
        data,
    };
}
export function fetchConsumerListFailure(
    error: Record<string, any>
): FetchConsumerListFailure {
    return {
        type: CONSUMER.FETCH_CONSUMER_LISTS_FAILURE,
        error,
    };
}



/**
 * post consumer bulk action
 */
export interface PostConsumerBulkAction {
    type: CONSUMER.POST_BULK_ACTION_REQUEST;
    info: Record<string,any>;
}
export interface PostConsumerBulkActionSuccess {
    type: CONSUMER.POST_BULK_ACTION_SUCCESS;
    data: Record<string, any>;
}
export interface PostConsumerBulkActionFailure {
    type: CONSUMER.POST_BULK_ACTION_FAILURE;
    error: Record<string, any> | null;
}
export type PostConsumerBulkActionType =
    | PostConsumerBulkAction
    | PostConsumerBulkActionSuccess
    | PostConsumerBulkActionFailure;

export function postConsumerBulkAction(info: Record<string,any>): PostConsumerBulkAction {
    return {
        type: CONSUMER.POST_BULK_ACTION_REQUEST,
        info,
    };
}
export function postConsumerBulkActionSuccess(
    data: Record<string, any>,
): PostConsumerBulkActionSuccess {
    return {
        type: CONSUMER.POST_BULK_ACTION_SUCCESS,
        data,
    };
}
export function postConsumerBulkActionFailure(
    error: Record<string, any> | null
): PostConsumerBulkActionFailure {
    return {
        type: CONSUMER.POST_BULK_ACTION_FAILURE,
        error,
    };
}



export interface UpdateConsumerCheckbox {
    type: CONSUMER.UPDATE_CONSUMER_SELECTED;
    info: string[];
}

export type UpdateConsumerCheckboxType = UpdateConsumerCheckbox;
export function updateConsumerCheckboxType(
    info: string[]
): UpdateConsumerCheckbox {
    return {
        type: CONSUMER.UPDATE_CONSUMER_SELECTED,
        info,
    };
}


export interface ResetFetchConsumers {
    type: CONSUMER.RESET_FETCH_CONSUMERS;
    info?:string
}

export type ResetFetchConsumersType = ResetFetchConsumers;

export function resetFetchConsumers(info?:string): ResetFetchConsumersType {
    return {
        type: CONSUMER.RESET_FETCH_CONSUMERS,
        info
    };
}



/**
 *  fetch payrollgrp list
 * */
export interface FetchPayrollGrp {
    type: CONSUMER.FETCH_PAYROLLGRP_REQUEST;
    locationId?: string;
}
export interface FetchPayrollGrpSuccess {
    type: CONSUMER.FETCH_PAYROLLGRP_SUCCESS;
    data: PayrollGrp[];
}
export interface FetchPayrollGrpFailure {
    type: CONSUMER.FETCH_PAYROLLGRP_FAILURE;
    error: Record<string, any> | null;
}
export type FetchPayrollGrpType =
    | FetchPayrollGrp
    | FetchPayrollGrpSuccess
    | FetchPayrollGrpFailure;
export function fetchPayrollGrp(locationId?: string
): FetchPayrollGrp {
    return {
        type: CONSUMER.FETCH_PAYROLLGRP_REQUEST,
        locationId
    };
}
export function fetchPayrollGrpSuccess(
    data: PayrollGrp[]
): FetchPayrollGrpSuccess {
    return {
        type: CONSUMER.FETCH_PAYROLLGRP_SUCCESS,
        data,
    };
}
export function fetchPayrollGrpFailure(
    error: Record<string, any>
): FetchPayrollGrpFailure {
    return {
        type: CONSUMER.FETCH_PAYROLLGRP_FAILURE,
        error,
    };
}


/**
 *  fetch subsidygrp list
 * */
export interface FetchSubsidyGrp {
    type: CONSUMER.FETCH_SUBSIDYGRP_REQUEST;
    locationId?: string;
}
export interface FetchSubsidyGrpSuccess {
    type: CONSUMER.FETCH_SUBSIDYGRP_SUCCESS;
    data: SubsidyGrp[];
}
export interface FetchSubsidyGrpFailure {
    type: CONSUMER.FETCH_SUBSIDYGRP_FAILURE;
    error: Record<string, any> | null;
}
export type FetchSubsidyGrpType =
    | FetchSubsidyGrp
    | FetchSubsidyGrpSuccess
    | FetchSubsidyGrpFailure;
export function fetchSubsidyGrp(locationId?: string
): FetchSubsidyGrp {
    return {
        type: CONSUMER.FETCH_SUBSIDYGRP_REQUEST,
        locationId
    };
}
export function fetchSubsidyGrpSuccess(
    data: SubsidyGrp[]
): FetchSubsidyGrpSuccess {
    return {
        type: CONSUMER.FETCH_SUBSIDYGRP_SUCCESS,
        data,
    };
}
export function fetchSubsidyGrpFailure(
    error: Record<string, any>
): FetchSubsidyGrpFailure {
    return {
        type: CONSUMER.FETCH_SUBSIDYGRP_FAILURE,
        error,
    };
}

/**
 *  fetch consumer balances
 * */
export interface FetchConsumerBalances {
    type: CONSUMER.FETCH_CONSUMER_BALANCES_REQUEST;
    accountId: string;
}
export interface FetchConsumerBalancesSuccess {
    type: CONSUMER.FETCH_CONSUMER_BALANCES_SUCCESS;
    data: Balances[];
}
export interface FetchConsumerBalancesFailure {
    type: CONSUMER.FETCH_CONSUMER_BALANCES_FAILURE;
    error: Record<string, any> | null;
}
export type FetchConsumerBalancesType =
    | FetchConsumerBalances
    | FetchConsumerBalancesSuccess
    | FetchConsumerBalancesFailure;
export function fetchConsumerBalances(
    accountId: string
): FetchConsumerBalances {
    return {
        type: CONSUMER.FETCH_CONSUMER_BALANCES_REQUEST,
        accountId,
    };
}
export function fetchConsumerBalancesSuccess(
    data: Balances[]
): FetchConsumerBalancesSuccess {
    return {
        type: CONSUMER.FETCH_CONSUMER_BALANCES_SUCCESS,
        data,
    };
}
export function fetchConsumerBalancesFailure(
    error: Record<string, any>
): FetchConsumerBalancesFailure {
    return {
        type: CONSUMER.FETCH_CONSUMER_BALANCES_FAILURE,
        error,
    };
}


/**
 *  fetch consumer identifiers
 * */
export interface FetchConsumerIdentifiers {
    type: CONSUMER.FETCH_CONSUMER_IDENTIFIERS_REQUEST;
    accountId: string;
    identityTypes:number[]
}
export interface FetchConsumerIdentifiersSuccess {
    type: CONSUMER.FETCH_CONSUMER_IDENTIFIERS_SUCCESS;
    data: Identifiers[];
    identifier:number
}
export interface FetchConsumerIdentifiersFailure {
    type: CONSUMER.FETCH_CONSUMER_IDENTIFIERS_FAILURE;
    error: Record<string, any> | null;
}
export type FetchConsumerIdentifiersType =
    | FetchConsumerIdentifiers
    | FetchConsumerIdentifiersSuccess
    | FetchConsumerIdentifiersFailure;
export function fetchConsumerIdentifiers(
    accountId: string,
    identityTypes:number[]
): FetchConsumerIdentifiers {
    return {
        type: CONSUMER.FETCH_CONSUMER_IDENTIFIERS_REQUEST,
        accountId,
        identityTypes
    };
}
export function fetchConsumerIdentifiersSuccess(
    data: Identifiers[],
    identifier:number
): FetchConsumerIdentifiersSuccess {
    return {
        type: CONSUMER.FETCH_CONSUMER_IDENTIFIERS_SUCCESS,
        data,
        identifier
    };
}
export function fetchConsumerIdentifiersFailure(
    error: Record<string, any>
): FetchConsumerIdentifiersFailure {
    return {
        type: CONSUMER.FETCH_CONSUMER_IDENTIFIERS_FAILURE,
        error,
    };
}



/**
 * resend email update
 */
export interface PostConsumerResendEmail {
    type: CONSUMER.POST_RESEND_EMAIL_REQUEST;
    accountId: string;
    info: Record<string,any>;
}
export interface PostConsumerResendEmailSuccess {
    type: CONSUMER.POST_RESEND_EMAIL_SUCCESS;
    data: Record<string, any>;
}
export interface PostConsumerResendEmailFailure {
    type: CONSUMER.POST_RESEND_EMAIL_FAILURE;
    error: Record<string, any> | null;
}
export type PostConsumerResendEmailType =
    | PostConsumerResendEmail
    | PostConsumerResendEmailSuccess
    | PostConsumerResendEmailFailure;

export function postConsumerResendEmail(accountId:string,info: Record<string,any>): PostConsumerResendEmail {
    return {
        type: CONSUMER.POST_RESEND_EMAIL_REQUEST,
        accountId,
        info,
    };
}
export function postConsumerResendEmailSuccess(
    data: Record<string, any>,
): PostConsumerResendEmailSuccess {
    return {
        type: CONSUMER.POST_RESEND_EMAIL_SUCCESS,
        data,
    };
}
export function postConsumerResendEmailFailure(
    error: Record<string, any> | null
): PostConsumerResendEmailFailure {
    return {
        type: CONSUMER.POST_RESEND_EMAIL_FAILURE,
        error,
    };
}



/**
 * resend email update
 */
export interface PostConsumerAddFunds {
    type: CONSUMER.POST_ADD_FUNDS_REQUEST;
    info: Record<string,any>;
}
export interface PostConsumerAddFundsSuccess {
    type: CONSUMER.POST_ADD_FUNDS_SUCCESS;
    data: Record<string, any>;
}
export interface PostConsumerAddFundsFailure {
    type: CONSUMER.POST_ADD_FUNDS_FAILURE;
    error: Record<string, any> | null;
}
export type PostConsumerAddFundsType =
    | PostConsumerAddFunds
    | PostConsumerAddFundsSuccess
    | PostConsumerAddFundsFailure;

export function postConsumerAddFunds(info: Record<string,any>): PostConsumerAddFunds {
    return {
        type: CONSUMER.POST_ADD_FUNDS_REQUEST,
        info,
    };
}
export function postConsumerAddFundsSuccess(
    data: Record<string, any>,
): PostConsumerAddFundsSuccess {
    return {
        type: CONSUMER.POST_ADD_FUNDS_SUCCESS,
        data,
    };
}
export function postConsumerAddFundsFailure(
    error: Record<string, any> | null
): PostConsumerAddFundsFailure {
    return {
        type: CONSUMER.POST_ADD_FUNDS_FAILURE,
        error,
    };
}


/**
 * resend email update
 */
export interface PostNewConsumer{
    type: CONSUMER.POST_NEW_CONSUMER_REQUEST;
    info: Record<string,any>;
}
export interface PostNewConsumerSuccess{
    type: CONSUMER.POST_NEW_CONSUMER_SUCCESS;
    data: Record<string, any>;
}
export interface PostNewConsumerFailure {
    type: CONSUMER.POST_NEW_CONSUMER_FAILURE;
    error: Record<string, any> | null;
}
export type PostNewConsumerType =
    | PostNewConsumer
    | PostNewConsumerSuccess
    | PostNewConsumerFailure;

export function postNewConsumer(info: Record<string,any>): PostNewConsumer {
    return {
        type: CONSUMER.POST_NEW_CONSUMER_REQUEST,
        info,
    };
}
export function postNewConsumerSuccess(
    data: Record<string, any>,
): PostNewConsumerSuccess {
    return {
        type: CONSUMER.POST_NEW_CONSUMER_SUCCESS,
        data,
    };
}
export function postNewConsumerFailure(
    error: Record<string, any> | null
): PostNewConsumerFailure {
    return {
        type: CONSUMER.POST_NEW_CONSUMER_FAILURE,
        error,
    };
}


/**
 * delete Consumer scan code
 */

export interface DeleteConsumerScanCode {
    type: CONSUMER.DELETE_CONSUMER_SCAN_CODE;
    // info should contain an array of object . Each object shpuld contain accountId , accountIdentifierId :string;
    info: Record<string, any>[];
}
export interface DeleteConsumerScanCodeSuccess {
    type: CONSUMER.DELETE_CONSUMER_SCAN_CODE_SUCCESS;
    data: string;
}
export interface DeleteConsumerScanCodeFailure {
    type: CONSUMER.DELETE_CONSUMER_SCAN_CODE_FAILURE;
    error: Record<string, any> | null;
}
export type DeleteConsumerScanCodeType =
    | DeleteConsumerScanCode
    | DeleteConsumerScanCodeSuccess
    | DeleteConsumerScanCodeFailure;

export function deleteConsumerScanCode(
    info: Record<string, any>[]
): DeleteConsumerScanCode {
    return {
        type: CONSUMER.DELETE_CONSUMER_SCAN_CODE,
        info,
    };
}
export function deleteConsumerScanCodeSuccess(
    data: string
): DeleteConsumerScanCodeSuccess {
    return {
        type: CONSUMER.DELETE_CONSUMER_SCAN_CODE_SUCCESS,
        data,
    };
}
export function deleteConsumerScanCodeFailure(
    error: Record<string, any> | null
): DeleteConsumerScanCodeFailure {
    return {
        type: CONSUMER.DELETE_CONSUMER_SCAN_CODE_FAILURE,
        error,
    };
}




/**
 * post IDENTIFIERS(PAYROLL ID AND SCANCODE UPDATE)
 */
export interface PostConsumerIdentifiers {
    type: CONSUMER.POST_IDENTIFIERS_REQUEST;
    accountId:string;
    info: Record<string,any>;
}
export interface PostConsumerIdentifiersSuccess {
    type: CONSUMER.POST_IDENTIFIERS_SUCCESS;
    data: Record<string, any>;
}
export interface PostConsumerIdentifiersFailure {
    type: CONSUMER.POST_IDENTIFIERS_FAILURE;
    error: Record<string, any> | null;
}
export type PostConsumerIdentifiersType =
    | PostConsumerIdentifiers
    | PostConsumerIdentifiersSuccess
    | PostConsumerIdentifiersFailure;

export function postConsumerIdentifiers(info: Record<string,any>,accountId:string): PostConsumerIdentifiers {
    return {
        type: CONSUMER.POST_IDENTIFIERS_REQUEST,
        info,
        accountId
    };
}
export function postConsumerIdentifiersSuccess(
    data: Record<string, any>,
): PostConsumerIdentifiersSuccess {
    return {
        type: CONSUMER.POST_IDENTIFIERS_SUCCESS,
        data,
    };
}
export function postConsumerIdentifiersFailure(
    error: Record<string, any> | null
): PostConsumerIdentifiersFailure {
    return {
        type: CONSUMER.POST_IDENTIFIERS_FAILURE,
        error,
    };
}



export interface PatchConsumer {
    type: CONSUMER.PATCH_CONSUMER_REQUEST;
    info: Record<string, any>;
}
export interface PatchConsumerSuccess {
    type: CONSUMER.PATCH_CONSUMER_SUCCESS;
    data: Record<string, any>;
}
export interface PatchConsumerFailure {
    type: CONSUMER.PATCH_CONSUMER_FAILURE;
    error: Record<string, any> | null;
}
export type PatchConsumerType =
    | PatchConsumer
    | PatchConsumerSuccess
    | PatchConsumerFailure;

export function PatchConsumer(
    info: Record<string, any>
): PatchConsumer {
    return {
        type: CONSUMER.PATCH_CONSUMER_REQUEST,
        info,
    };
}
export function PatchConsumerSuccess(
    data: Record<string, any>
): PatchConsumerSuccess {
    return {
        type: CONSUMER.PATCH_CONSUMER_SUCCESS,
        data,
    };
}
export function PatchConsumerFailure(
    error: Record<string, any>
): PatchConsumerFailure {
    return {
        type: CONSUMER.PATCH_CONSUMER_FAILURE,
        error,
    };
}



export interface PostConsumerSubsidyGrp {
    type: CONSUMER.POST_SUBSIDYGRP_REQUEST;
    info: Record<string, any>;
}
export interface PostConsumerSubsidyGrpSuccess {
    type: CONSUMER.POST_SUBSIDYGRP_SUCCESS;
    data: Record<string, any>;
}
export interface PostConsumerSubsidyGrpFailure {
    type: CONSUMER.POST_SUBSIDYGRP_FAILURE;
    error: Record<string, any> | null;
}
export type PostConsumerSubsidyGrpType =
    | PostConsumerSubsidyGrp
    | PostConsumerSubsidyGrpSuccess
    | PostConsumerSubsidyGrpFailure;

export function postConsumerSubsidyGrp(
    info: Record<string, any>
): PostConsumerSubsidyGrp {
    return {
        type: CONSUMER.POST_SUBSIDYGRP_REQUEST,
        info,
    };
}
export function postConsumerSubsidyGrpSuccess(
    data: Record<string, any>
): PostConsumerSubsidyGrpSuccess {
    return {
        type: CONSUMER.POST_SUBSIDYGRP_SUCCESS,
        data,
    };
}
export function postConsumerSubsidyGrpFailure(
    error: Record<string, any>
): PostConsumerSubsidyGrpFailure {
    return {
        type: CONSUMER.POST_SUBSIDYGRP_FAILURE,
        error,
    };
}