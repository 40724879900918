import { memo, useCallback, useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
    IonModal,
    IonButton,
    IonContent,
    IonRow,
    IonCol,
    IonItem,
    IonHeader,
    IonFooter,
    IonLabel,
    IonInput,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSpinner,
    isPlatform
} from "@ionic/react";
import { search, close } from "ionicons/icons";

import { RootState } from "../../../_redux/reducers/rootReducer";
import {
    FetchGlobalProducts,
    resetGlobalProducts,
    SaveSwapProduct,
} from "../../../_redux/actions/productActions";
import { resetProductsSettingsPanel } from "../../../_redux/actions/productSettingsPanelActions";

import Header from "../../../components/Product/ManageColumns/Header";
import { ProductFilters } from "../../../components/Product/ProductFilters/ProductFilters";
import Footer from "../../../components/Product/ManageColumns//Footer";
import ToastrHook from "../../../components/common/toastr/Toastr";
import {
    Hotel365Portal,
    PRODUCT_NAME_ATOZ,
    PRODUCT_POP_SWAP_TITLE,
    GLOBAL_PRODUCT,
    PRODUCT_CHOOSE_SWAP,
    ORDER_SORT_BY_HEADER,
    MODAL_FILTER_APPLIED,
    GLOBAL_SEARCH_PLACEHOLDER,
    ADD_PRODUCT_EMPTY_MESSAGE,
    LOADING_MORE_DATA,
    ADD,
    PRODUCT_LOW_TO_HIGH,
    GLOBAL_PLEASE_SELECT_PRODUCT
} from "../../../constants/constant";
import { getLocalizedNumericValues } from "../../../utils";

interface ProductList {
    productName: string;
    productPrice: number;
    product: Record<string, unknown>;
}

const SwapProduct: React.FC<any> = memo((props) => {
    const dispatch = useDispatch();
    const [sortSelect, setSortSelect] = useState(PRODUCT_NAME_ATOZ);
    const [searchBarShow, setSearchBarShow] = useState(true);
    const [showModalBox, setShowModalBox] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [searchText, setSearchText] = useState<string>();
    const isMobileView = isPlatform("mobile");

    const [renderToastr] = ToastrHook();
    const productFilter = useSelector(
        (state: RootState) => state.product.categoryFilters,
        shallowEqual
    );

    const [isInfiniteDisabled, setInfiniteDisabled] = useState(
        productFilter && productFilter.length > 0 ? true : false
    );

    const {
        pending,
        globalProducts,
        globalPaginate,
        error,
        categoryFilters
    } = useSelector((state: RootState) => state.product, shallowEqual);

    const [productList, setProductList] = useState(globalProducts);
    const [product, setProduct] = useState<Record<string, unknown>>({});
    const handleScroll = (ev: any) => {
        setTimeout(() => {
            const offset: number = globalPaginate.nextOffset;
            dispatch(resetProductsSettingsPanel());
            dispatch(FetchGlobalProducts(offset, searchText, categoryFilters));
            ev.target.complete();
            if (globalProducts.length === globalPaginate.totalCount) {
                setInfiniteDisabled(true);
            }
        }, 500);
    };

    const SortArray = (x: any, y: any) => {
        const productTempX = x.productName.trim().toLocaleLowerCase();
        const productTempY = y.productName.trim().toLocaleLowerCase();

        if (productTempX < productTempY) {
            return -1;
        }
        if (productTempX > productTempY) {
            return 1;
        }

        return 0;
    };

    const onCloseModal = () => {
        setSearchBarShow(true);
        setShowModalBox(false);
        setSearchText("");
        setInfiniteDisabled(false);
        setSortSelect(PRODUCT_NAME_ATOZ)
        setProduct({});
        props.notifySwapProductClose();
        dispatch(resetProductsSettingsPanel());
        dispatch(FetchGlobalProducts(null, ""));
    };

    const searchItems = (event: any) => {
        setSearchText(event.detail.value);
        setInfiniteDisabled(false);
        dispatch(resetGlobalProducts());
        dispatch(resetProductsSettingsPanel());
        dispatch(FetchGlobalProducts(null, event.detail.value, categoryFilters));
    };

    useEffect(() => {
        setProductList(globalProducts);
        setIsFilterApplied(
            productFilter && productFilter.length > 0 ? true : false
        );
        setShowModalBox(props.buttonState);
        if (
            globalProducts.length !== 0 &&
            globalProducts.length === globalPaginate.totalCount
        ) {
            setInfiniteDisabled(true);
        }
        if (
            error &&
            Object.keys(error).length > 0 &&
            product &&
            Object.keys(product).length > 0
        ) {
            setProduct({});
        }
    }, [error, productList, globalProducts, props.buttonState]);

    const sortByPrice = (args: any) => {
        const dbs = globalProducts?.sort(
            (a: any, b: any) => a.productPrice - b.productPrice
        );
        if (args.detail.value == PRODUCT_NAME_ATOZ) {
            setProductList(globalProducts.sort(SortArray));
            setSortSelect(args.detail.value);
        } else {
            dbs && setProductList([...dbs]);
            setSortSelect(args.detail.value);
        }
    };
    useEffect(() => {
        if (sortSelect === PRODUCT_LOW_TO_HIGH) {
            sortByPrice({ detail: { value: PRODUCT_LOW_TO_HIGH } })
        } else {
            sortByPrice({ detail: { value: PRODUCT_NAME_ATOZ } })
        }
    }, [globalProducts, sortSelect])

    const searchBar = (args: boolean) => {
        setSearchBarShow(args);
        if (args === true) {
            setInfiniteDisabled(false);
            setSearchText("");
            dispatch(resetGlobalProducts());
            dispatch(resetProductsSettingsPanel());
            dispatch(FetchGlobalProducts(null, "", categoryFilters));
        }
        setSortSelect(PRODUCT_NAME_ATOZ)
    };
    const filterApply = () => {
        setShowFilterModal(true);
    };

    const onSave = () => {
        if (!product.productId) {
            renderToastr(GLOBAL_PLEASE_SELECT_PRODUCT, "warning");
            return false;
        }
        props.notifySwapProductClose();
        props.swappedProduct(product);
    };

    const onCloseFilterModal = (_appliedCategories: string[]) => {
        setShowModalBox(true);
        setShowFilterModal(false);
        setInfiniteDisabled(false)
        setProduct({});
        dispatch(resetGlobalProducts());
        dispatch(resetProductsSettingsPanel());
        dispatch(FetchGlobalProducts(0, searchText, _appliedCategories));
        if (_appliedCategories.length > 0) {
            setIsFilterApplied(true);
        } else {
            setIsFilterApplied(false);
        }
    };

    const onProductSelect = useCallback(
        (product, ind) => {
            setProduct(product);
        },
        [setProduct, productList]
    );

    return (
        <>
            <IonModal
                isOpen={showModalBox}
                className={isMobileView ? "mobileview-modal-pop" : "modal-pop"}
                onDidDismiss={onCloseModal}
            >
                <IonContent>
                    <IonHeader className="header">
                        <Header
                            headerTitle={PRODUCT_POP_SWAP_TITLE}
                            onCloseModal={onCloseModal}
                            filterIcon={true}
                            filterApply={filterApply}
                        />
                    </IonHeader>
                    <div className="product-heading-name">
                        <b>
                            <h2>{GLOBAL_PRODUCT}</h2>
                        </b>
                    </div>
                    <b className="product-des">{PRODUCT_CHOOSE_SWAP}</b>
                    {searchBarShow ? (
                        <div className="filter-section">
                            <IonRow>
                                <IonCol sizeXs="8" sizeSm="9">
                                    <IonLabel>{ORDER_SORT_BY_HEADER}</IonLabel>
                                </IonCol>
                                {isFilterApplied && (
                                    <IonCol sizeXs="4" sizeSm="3">
                                        <IonLabel className="filter-applied-text">
                                            {MODAL_FILTER_APPLIED}
                                        </IonLabel>
                                    </IonCol>
                                )}
                            </IonRow>
                            <IonRow>
                                <IonCol size="10">
                                    <IonSelect
                                        interface="popover"
                                        value={sortSelect}
                                        onIonChange={(e) => {
                                            sortByPrice(e);
                                        }}
                                        className="sort-dropdown"
                                    >
                                        <IonSelectOption value={PRODUCT_NAME_ATOZ}>
                                            {PRODUCT_NAME_ATOZ}
                                        </IonSelectOption>
                                        <IonSelectOption value={PRODUCT_LOW_TO_HIGH}>
                                            {PRODUCT_LOW_TO_HIGH}
                                        </IonSelectOption>
                                    </IonSelect>
                                </IonCol>
                                <IonCol size="2">
                                    <IonButton
                                        className="search-icon"
                                        onClick={() => searchBar(false)}
                                    >
                                        <IonIcon icon={search} />
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </div>
                    ) : (
                        <IonRow className="modal-bodys filter-section search-box">
                            <IonCol size="10" className='search-box-col'>
                                <IonLabel color="light">{GLOBAL_SEARCH_PLACEHOLDER}</IonLabel>
                                <IonItem className="product-search-box">
                                    <IonInput
                                        className='search-item-box'
                                        placeholder={GLOBAL_SEARCH_PLACEHOLDER}
                                        value={searchText}
                                        onIonChange={searchItems}
                                    />
                                </IonItem>
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon
                                    className="close-icon"
                                    onClick={() => searchBar(true)}
                                    icon={close}
                                />
                            </IonCol>
                        </IonRow>
                    )}
                    <IonRow className="items-scroll">
                        <IonCol className="item-list click-cursor">
                            {pending && (
                                <IonSpinner
                                    name="circles"
                                    className="spinner-circles"
                                />
                            )}
                            {!pending && productList.length === 0 && (
                                <p className="no-products">
                                    {ADD_PRODUCT_EMPTY_MESSAGE}
                                </p>
                            )}
                            {!pending &&
                                productList.length > 0 &&
                                productList.map((res: any, i: number) => (
                                    <IonItem
                                        key={i}
                                        className="swap-items"
                                        lines="full"
                                        color={
                                            res.product.productId ===
                                                product.productId
                                                ? "light"
                                                : ""
                                        }
                                        onClick={() =>
                                            onProductSelect(res.product, i)
                                        }
                                    >
                                        <IonLabel className="product-item ion-text-wrap">
                                            {res.productName}
                                        </IonLabel>
                                        <IonLabel
                                            slot="end"
                                            className="product-prise"
                                        >
                                            {getLocalizedNumericValues(res.productPrice, "currency", props.currency)}
                                        </IonLabel>
                                    </IonItem>
                                ))}

                            <IonInfiniteScroll
                                onIonInfinite={handleScroll}
                                threshold="100px"
                                disabled={isInfiniteDisabled}
                            >
                                <IonInfiniteScrollContent
                                    loadingSpinner="bubbles"
                                    loadingText={LOADING_MORE_DATA}
                                ></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                    </IonRow>
                    <IonFooter className="footer">
                        <Footer
                            onCloseModal={onCloseModal}
                            onSave={onSave}
                            isSaveDisabled={pending}
                            primaryBtnName={ADD}
                        />
                    </IonFooter>
                </IonContent>
            </IonModal>
            <ProductFilters
                showFilterModal={showFilterModal}
                notifyFilterClose={onCloseFilterModal}
            />
        </>
    );
});

export default SwapProduct;
