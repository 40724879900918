
import * as POS from "../../ActionTypes/posTypes";
import { all, put, takeLatest} from "redux-saga/effects";
import { doGet, doPost } from "../../../utils/fetchWrapper";
import {
    postGenerateCartSuccess,
    postGenerateCartFailure,
    fetchSearchProductSuccess,
    fetchSearchProductFailure,
    postCheckoutCartFailure,
    postCheckoutCartSuccess
} from "../../actions/posActions";
import { Routes } from "../../../constants/Routes";

function* postGenerateCart(action: any) {
    const { data } = action;
    let url = `${Routes.pos.sales}/generate-cart`;
    const request = { ...data };
    try {
        // @ts-ignore
        const response = yield doPost(url, request);
        yield put(postGenerateCartSuccess(response));
    } catch (error: any) {
        yield put(postGenerateCartFailure(error));
    }
}

function* fetchSearchProducts(action: any) {
    const { data, locationId } = action;
    let url = `${Routes.systemUserActions.compilePriceActions}${locationId}/product-search`;
    const request = { ...data };
    try {
        // @ts-ignore
        const response = yield doPost(url, request);
        yield put(
            fetchSearchProductSuccess(response.res)
        );
    } catch (e: any) {
        yield put(
            fetchSearchProductFailure(e)
        );
    }
}


function* postCheckoutCart(action: any) {
    const { data } = action;
    let url = `${Routes.pos.sales}/checkout`;
    const request = { ...data };
    try {
        // @ts-ignore
        const response = yield doPost(url, request);
        yield put(postCheckoutCartSuccess(response));
    } catch (error: any) {
        yield put(postCheckoutCartFailure(error));
    }
}

function* PosSaga() {
    yield all([
        takeLatest(POS.POST_GENERATE_CART_REQUEST, postGenerateCart),
        takeLatest(POS.FETCH_SEARCH_PRODUCT_REQUEST, fetchSearchProducts),
        takeLatest(POS.POST_CHECKOUT_CART_REQUEST, postCheckoutCart),
    ]);
}

export default PosSaga;
