import { all, put, takeLatest, select, call } from "redux-saga/effects";
import { doGet, doPost, doPatch } from "../../../utils/fetchWrapper";
import {
    FetchGlobalMarginSuccess,
    FetchGlobalMarginFailure,
    PatchGlobalMarginSuccess,
    PatchGlobalMarginFailure,
    SaveGlobalMarginFailure,
    SaveGlobalMarginSuccess,
    PatchCategoryMarginSuccess,
    PatchCategoryMarginFailure,
    SaveCategoryMarginSuccess,
    SaveCategoryMarginFailure,
    FetchCategoryMarginSuccess,
    FetchCategoryMarginFailure,
} from "../../actions/productSettingsPanelActions";
import * as PRODUCT from "../../ActionTypes/productSettingsPanelTypes";
import { Routes } from "../../../constants/Routes";
import {SubmitPatchCategoryRequest, Category} from '../../../models/CategoryMargin';
import {SubmitPatchGlobalMarginRequest} from '../../../models/GlobalMargin';


function* fetchGlobalMargin(action: any) {
    const { currentProfile } = yield select((state) => { return state.profile});
    const { locationId } = currentProfile;
    let url = `${Routes.dashboard.fetchGlobalMarginApiUrl}${locationId}`;
    try {
        // @ts-ignore
        const response = yield doGet(url);
        yield put(FetchGlobalMarginSuccess(response));
    } catch (e: any) {
        yield put(
            FetchGlobalMarginFailure(e)
        );
    }
}


function* patchGlobalMargin(request: SubmitPatchGlobalMarginRequest) {
    const { info } = request;
    // @ts-ignore
    const { currentProfile } = yield select((state) => {return state.profile});
    const { locationId } = currentProfile;
    try {
        // @ts-ignore
        const response = yield doPatch(
            Routes.dashboard.fetchGlobalMarginApiUrl + locationId,
            info
        );
        yield put(PatchGlobalMarginSuccess(response));
    } catch (error: any) {
        yield put(PatchGlobalMarginFailure(error));
    }
}

function* submitGlobalMargin(request: SubmitPatchGlobalMarginRequest) {
    try {
        // @ts-ignore
        const response = yield doPost(
            Routes.dashboard.submitGlobalMarginApiUrl,
            request.info
        );
        yield put(SaveGlobalMarginSuccess(response));
    } catch (error: any) {
        yield put(SaveGlobalMarginFailure(error));
    }
}

function* patchCategoryMargin(request: Category) {
    // @ts-ignore
    const { currentProfile } = yield select((state) => {return state.profile});
    const { locationId } = currentProfile;
    try {
        // @ts-ignore
        const response = yield doPatch(
            Routes.dashboard.fetchCategoryMarginApiUrl +
            locationId,
            request
        );
        yield put(PatchCategoryMarginSuccess(response));
    } catch (error: any) {
        yield put(PatchCategoryMarginFailure(error));
    }
}

function* patchCategoryMargins(request: SubmitPatchCategoryRequest) {
    yield all(request.info.map((file: Category) => call(patchCategoryMargin, file)));
}

function* submitCategoryMargin(request: Category) {
    try {
        // @ts-ignore
        const response = yield doPost(
            Routes.dashboard.saveCategoryMarginApiUrl,
            request
        );
        yield put(SaveCategoryMarginSuccess(response));
    } catch (error: any) {
        yield put(SaveCategoryMarginFailure(error));
    }
}

function* submitCategoryMargins(request: SubmitPatchCategoryRequest) {
    yield all(
        request.info.map((file: Category) => call(submitCategoryMargin, file))
    );
}


function* fetchCategoryMargin(action: any) {
    const { currentProfile } = yield select((state) => { return state.profile});
    const { locationId } = currentProfile;
    let url = `${Routes.dashboard.fetchCategoryMarginApiUrl}${locationId}`;
    try {
        // @ts-ignore
        const response = yield doGet(url);
        yield put(FetchCategoryMarginSuccess(response));
    } catch (e: any) {
        yield put(
            FetchCategoryMarginFailure(e)
        );
    }
}

function* ProductsSettingsPanelSaga() {
    yield all([
        takeLatest(PRODUCT.FETCH_GLOBAL_MARGIN_REQUEST, fetchGlobalMargin),
        // @ts-ignore 
        takeLatest(PRODUCT.PATCH_GLOBAL_MARGIN_REQUEST, patchGlobalMargin),
        // @ts-ignore 
        takeLatest(PRODUCT.SAVE_GLOBAL_MARGIN_REQUEST, submitGlobalMargin),
        takeLatest(PRODUCT.FETCH_CATEGORY_MARGIN_REQUEST, fetchCategoryMargin),
        // @ts-ignore 
        takeLatest(PRODUCT.PATCH_CATEGORY_MARGIN_REQUEST, patchCategoryMargins),
        // @ts-ignore 
        takeLatest(PRODUCT.SAVE_CATEGORY_MARGIN_REQUEST, submitCategoryMargins),
    ]);
}

export default ProductsSettingsPanelSaga;
