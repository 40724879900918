//vendors
import React, { useRef, useState, useMemo, useEffect } from 'react';
import { IonButton, IonPopover, IonRow, IonCol, IonIcon, isPlatform } from '@ionic/react';
import { calendarOutline, caretDown } from 'ionicons/icons';
//utils
import {
    getFromToDateLbl,
    getFromToDateLblForCustomRange,
    getPredefinedLblsFromCustomDates
} from '../../../utils';
//components
import RangeCalendars from './RangeCalendars';
import Footer from './Footer';
import DateRangesList from './DateRangesList';
import RangeCalendarForMobile from './RangeCalendarForMobile';
//constants
import { customRange, APPLY_BUTTON, LABEL_TODATE, LABEL_FROMDATE, FOOTER_CANCEL_BUTTON } from '../../../constants/constant';


const DateRangePicker: React.FC<any> = ({
    label,
    onApply,
    dateRange,
    popoverOpen,
    setDateRange,
    dateRangeDates,
    setPopoverOpen,
    warningLbl = '',
    defaultRangeDates,
    customDateRangeErr,
    setCustomDateRangeErr,
    applyBtnLbl = APPLY_BUTTON,
    toDateLbl = LABEL_TODATE,
    cancelBtnLbl = FOOTER_CANCEL_BUTTON,
    fromDateLbl = LABEL_FROMDATE,
    predefinedOptions = {},
    showCustomRange = false,
    onPredefinedOptionSelect,
    setTimeRange,
    setFormErrors
}) => {
    const popover = useRef<HTMLIonPopoverElement>(null);
    const { fromDate: defaultFromDate, toDate: defaultToDate } = defaultRangeDates;
    const [openModal, setOpenModal] = useState(false);
    const isMobileView = isPlatform("mobile");
    const isTabletView = isPlatform("tablet");
    const isDesktopView = isPlatform("desktop");
    const defaultStartDate = defaultFromDate.toISODate();
    const defaultEndDate = defaultToDate.toISODate();
    const [customDateObject, setCustomDateObject] = useState({
        fromDate: dateRangeDates.fromDate || defaultFromDate || '',
        toDate: dateRangeDates.toDate || defaultToDate || ''
    });

    const openPopover = (e: any) => {
        if (isMobileView) {
            setPopoverOpen(true);
        } else {
            popover.current!.event = e;
            setPopoverOpen(true);
        }
    };

    function closeModal() {
        setOpenModal(false);
    }

    const displayDateRange = useMemo(() => {
        return dateRange !== customRange ?
            getFromToDateLbl(predefinedOptions[dateRange]) :
            getFromToDateLblForCustomRange([customDateObject.fromDate || defaultFromDate, customDateObject.toDate || defaultToDate]);
    }, [dateRange, customDateObject]);

    const resetOnDismiss = () => {
        setCustomDateObject({
            fromDate: dateRangeDates.fromDate || defaultFromDate || '',
            toDate: dateRangeDates.toDate || defaultToDate || ''
        });
        setPopoverOpen(false);
        isMobileView && setOpenModal(false);
    };

    useEffect(() => {
        setCustomDateObject(dateRangeDates);
    }, [dateRangeDates]);

    useEffect(() => {
        const fromDate = customDateObject.fromDate || defaultFromDate;
        const toDate = customDateObject.toDate || defaultToDate;
        const predefinedLbl = getPredefinedLblsFromCustomDates({ fromDate, toDate });
        setDateRange(predefinedLbl || dateRange);
    }, [customDateObject]);

    return (
        <IonRow className='ion-padding-start ion-padding-end ion-margin-top'>
            <IonCol>
                <h6 className="reports-h6">{label}</h6>
                <IonButton
                    expand="full"
                    fill="clear"
                    className="date-range-btn"
                    id="trigger-button"
                    onClick={openPopover}>
                    <IonIcon slot="start" icon={calendarOutline} className="marginRight10" />
                    {displayDateRange}
                    <IonIcon slot="end" icon={caretDown} className="marginLeft10" />
                </IonButton>

                {isMobileView &&
                    <RangeCalendarForMobile
                        onApply={onApply}
                        toDateLbl={toDateLbl}
                        openModal={openModal}
                        warningLbl={warningLbl}
                        closeModal={closeModal}
                        fromDateLbl={fromDateLbl}
                        applyBtnLbl={applyBtnLbl}
                        cancelBtnLbl={cancelBtnLbl}
                        resetOnDismiss={resetOnDismiss}
                        showCustomRange={showCustomRange}
                        dateObject={{
                            fromDate: customDateObject.fromDate || defaultFromDate,
                            toDate: customDateObject.toDate || defaultToDate
                        }}
                        setDateObject={setCustomDateObject}
                        customDateRangeErr={customDateRangeErr}
                        setCustomDateRangeErr={setCustomDateRangeErr}
                    />
                }

                <IonPopover
                    ref={popover}
                    trigger="trigger-button"
                    side="bottom"
                    alignment="start"
                    id={isMobileView ? 'date-range-mobile' : 'date-range'}
                    isOpen={popoverOpen}
                    onDidDismiss={() => resetOnDismiss()}>
                    <IonRow className="date-picker-modal">
                        {predefinedOptions &&
                            <DateRangesList
                                dateRange={dateRange}
                                setDateRange={setDateRange}
                                setOpenModal={setOpenModal}
                                setPopoverOpen={setPopoverOpen}
                                showCustomRange={showCustomRange}
                                predefinedOptions={predefinedOptions}
                                setTimeRange={setTimeRange}
                                setFormErrors={setFormErrors}
                                onPredefinedOptionSelect={onPredefinedOptionSelect}
                            />
                        }
                        {(isTabletView || isDesktopView) && showCustomRange &&
                            <RangeCalendars
                                fromDateLbl={fromDateLbl}
                                toDateLbl={toDateLbl}
                                dateObject={{
                                    fromDate: customDateObject.fromDate || defaultFromDate,
                                    toDate: customDateObject.toDate || defaultToDate
                                }}
                                setDateObject={setCustomDateObject}
                                customDateRangeErr={customDateRangeErr}
                                setCustomDateRangeErr={setCustomDateRangeErr}
                            />
                        }
                    </IonRow>
                    {(isTabletView || isDesktopView) && showCustomRange &&
                        <Footer
                            onApply={onApply}
                            warningLbl={warningLbl}
                            applyBtnLbl={applyBtnLbl}
                            cancelBtnLbl={cancelBtnLbl}
                            dateObject={customDateObject}
                            resetOnDismiss={resetOnDismiss}
                            setPopoverOpen={setPopoverOpen}
                        />
                    }
                </IonPopover>
            </IonCol>
        </IonRow >
    )
};
export default DateRangePicker;



