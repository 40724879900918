//vendors
import Select, { ActionMeta, OnChangeValue } from "react-select";
//components
import Option from "./Option";
import MultiValue from "./MultiValue";
//config and constants
import { InterfaceOption, orderOptions, colourStyles as defaultStyles } from "./config";

const MultiSelectDropdown: React.FC<any> = ({ placeHolder = "", colourStyles = defaultStyles, options = [], setSelectedOptions, selectedOptions, isMulti = false, components = {} }) => {

    const onChange = (newValue: OnChangeValue<InterfaceOption, true>, actionMeta: ActionMeta<InterfaceOption>) => {
        switch (actionMeta.action) {
            case 'remove-value':
            case 'pop-value':
                if (actionMeta.removedValue.isFixed) {
                    return;
                }
                break;
            case 'clear':
                newValue = options.filter((v: Record<string, any>) => v.isFixed);
                break;
        }
        setSelectedOptions(orderOptions(newValue));
    };

    return (
        <Select
            isMulti={isMulti}
            options={options}
            onChange={(newValue, actionMeta) => {
                if(newValue && newValue.__proto__.constructor.name === 'Object'){
                    newValue = [newValue]
                } 
                onChange(newValue, actionMeta)}
            }
            styles={colourStyles}
            value={selectedOptions}
            placeholder={placeHolder}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            classNamePrefix="react-select"
            className="react-select-container"
            components={components}
            isClearable={selectedOptions && selectedOptions.length > 0 && selectedOptions.some((v: any) => !v.isFixed)}
        />
    );
};

export default MultiSelectDropdown;
