//vendors
import { IonRow, IonCol, isPlatform } from "@ionic/react";
//constants
import { defaultFromTime, defaultToTime, timeValues } from '../../constants/constant';
// components
import Dropdown from '../../components/common/Dropdown';
import { validateTimeRange } from '../../utils';
const styleHeight = { 'minHeight': '45px' };

export const TimeRange: React.FC<any> = ({ label, timeRange, setTimeRange, dateRange, formErrors, setFormErrors }) => {
    const interfaceStyles = { cssClass: 'custom-interface' }
    const { errorMessage } = formErrors;
    const isDesktopView = isPlatform("desktop");
    return (
        <IonRow className="ion-padding-start ion-padding-end">
            <IonCol>
                <h6 className="reports-h6">{label}</h6>
                <IonRow>
                    <IonCol size="6" className="ion-padding-end">
                        {/*** start time dropdown*/}
                        <Dropdown
                            label='value'
                            value='value'
                            options={timeValues}
                            styles={styleHeight}
                            selected={timeRange.fromTime}
                            placeholder={defaultFromTime}
                            className='time-rage-dropdown'
                            interfaceOptions={interfaceStyles}
                            onChangeHandler={(value: any) => {
                                const { errorMessage, isValid } = validateTimeRange(dateRange, value, timeRange.toTime);
                                setTimeRange((prevState: any) => { return { ...prevState, fromTime: value } })
                                setFormErrors({ isValid, errorMessage });
                            }}
                        />
                    </IonCol>
                    <IonCol size="6" className="ion-padding-start">
                        <Dropdown
                            label='value'
                            value='value'
                            options={timeValues}
                            styles={styleHeight}
                            selected={timeRange.toTime}
                            placeholder={defaultToTime}
                            className='time-rage-dropdown'
                            interfaceOptions={interfaceStyles}
                            onChangeHandler={(value: any) => {
                                const { errorMessage, isValid } = validateTimeRange(dateRange, timeRange.fromTime, value);
                                setTimeRange((prevState: any) => { return { ...prevState, toTime: value } })
                                setFormErrors({ isValid, errorMessage });
                            }}
                        />
                    </IonCol>
                </IonRow>
                {errorMessage && <IonRow className={isDesktopView ? 'time-range-error' : 'mobile-time-range'}>{errorMessage}</IonRow>}
            </IonCol>
        </IonRow>
    )
};
export default TimeRange;
