import { isPlatform } from '@ionic/react';

const CounterFeature = (props: any) => {
    const isMobileView = isPlatform("mobile");
    const { counter, label, descreseCounter, increaseCounter, setCounter } = props

    return (
        <div className={isMobileView ? "counter-feature-mobile" : ''}>
            {label !== '' && <h6 className="font-size-for-h6">{label}</h6>}
            <span className="minus" aria-disabled="true" onClick={descreseCounter}>-</span>
            <input className={isMobileView ? "preview-mobile-textbox" : ''} type="text" onChange={setCounter} value={counter} />
            <span className="plus" onClick={increaseCounter}>+</span>
        </div>
    );
};
export default CounterFeature;


