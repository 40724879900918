//vendors
import { IonButton, IonRow, IonCol } from '@ionic/react';

const Footer: React.FC<any> = ({
    onApply,
    dateObject,
    warningLbl,
    applyBtnLbl,
    cancelBtnLbl,
    resetOnDismiss
}) => {
    return (
        <IonRow className="date-range-footer-background">
            <IonCol size="8" sizeMd="8" className='marginTop10'>
                {warningLbl && <span>{warningLbl}</span>}
            </IonCol>
            <IonCol size="2">
                <IonButton
                    color="gray"
                    expand="block"
                    className="btn-text-cases-cancel"
                    onClick={() => (resetOnDismiss())}
                >
                    {cancelBtnLbl}
                </IonButton>
            </IonCol>
            <IonCol size="2">
                <IonButton
                    color="primary"
                    expand="block"
                    type="submit"
                    className="btn-text-cases-save"
                    onClick={() => {
                        const { fromDate, toDate } = dateObject;
                        onApply(fromDate, toDate);
                    }}
                >
                    {applyBtnLbl}
                </IonButton>
            </IonCol>
        </IonRow>)
}
export default Footer;