import * as PRODUCT from "../ActionTypes/productSettingsPanelTypes";
import { GlobalMargin } from "../../models/GlobalMargin";
import { CategoryMargin, Category } from "../../models/CategoryMargin";

/**
 * Fetch global Margin.
 */
export interface FetchGlobalMargin {
    type: PRODUCT.FETCH_GLOBAL_MARGIN_REQUEST;
    locationId: string;
}
export interface FetchGlobalMarginSuccess {
    type: PRODUCT.FETCH_GLOBAL_MARGIN_SUCCESS;
    data: GlobalMargin;
}
export interface FetchGlobalMarginFailure {
    type: PRODUCT.FETCH_GLOBAL_MARGIN_FAILURE;
    error: Record<string, any> | null;
}
export type FetchGlobalMarginType =
    | FetchGlobalMargin
    | FetchGlobalMarginSuccess
    | FetchGlobalMarginFailure;
export function FetchGlobalMargin(locationId: string): FetchGlobalMargin {
    return {
        type: PRODUCT.FETCH_GLOBAL_MARGIN_REQUEST,
        locationId,
    };
}
export function FetchGlobalMarginSuccess(
    data: GlobalMargin
): FetchGlobalMarginSuccess {
    return {
        type: PRODUCT.FETCH_GLOBAL_MARGIN_SUCCESS,
        data,
    };
}
export function FetchGlobalMarginFailure(
    error: Record<string, any>
): FetchGlobalMarginFailure {
    return {
        type: PRODUCT.FETCH_GLOBAL_MARGIN_FAILURE,
        error,
    };
}

/**
 * Save Global Margin
 */
export interface SaveGlobalMargin {
    type: PRODUCT.SAVE_GLOBAL_MARGIN_REQUEST;
    info: GlobalMargin;
}
export interface SaveGlobalMarginSuccess {
    type: PRODUCT.SAVE_GLOBAL_MARGIN_SUCCESS;
    data: GlobalMargin;
}
export interface SaveGlobalMarginFailure {
    type: PRODUCT.SAVE_GLOBAL_MARGIN_FAILURE;
    error: Record<string, any> | null;
}
export type SaveGlobalMarginType =
    | SaveGlobalMargin
    | SaveGlobalMarginSuccess
    | SaveGlobalMarginFailure;

export function SaveGlobalMargin(info: GlobalMargin): SaveGlobalMargin {
    return {
        type: PRODUCT.SAVE_GLOBAL_MARGIN_REQUEST,
        info,
    };
}
export function SaveGlobalMarginSuccess(
    data: GlobalMargin
): SaveGlobalMarginSuccess {
    return {
        type: PRODUCT.SAVE_GLOBAL_MARGIN_SUCCESS,
        data,
    };
}
export function SaveGlobalMarginFailure(
    error: Record<string, any> | null
): SaveGlobalMarginFailure {
    return {
        type: PRODUCT.SAVE_GLOBAL_MARGIN_FAILURE,
        error,
    };
}

/**
 * patch Global Margin
 */
export interface PatchGlobalMargin {
    type: PRODUCT.PATCH_GLOBAL_MARGIN_REQUEST;
    info: GlobalMargin;
}
export interface PatchGlobalMarginSuccess {
    type: PRODUCT.PATCH_GLOBAL_MARGIN_SUCCESS;
    data: GlobalMargin;
}
export interface PatchGlobalMarginFailure {
    type: PRODUCT.PATCH_GLOBAL_MARGIN_FAILURE;
    error: Record<string, any> | null;
}
export type PatchGlobalMarginType =
    | PatchGlobalMargin
    | PatchGlobalMarginSuccess
    | PatchGlobalMarginFailure;

export function PatchGlobalMargin(info: any): PatchGlobalMargin {
    return {
        type: PRODUCT.PATCH_GLOBAL_MARGIN_REQUEST,
        info,
    };
}
export function PatchGlobalMarginSuccess(data: any): PatchGlobalMarginSuccess {
    return {
        type: PRODUCT.PATCH_GLOBAL_MARGIN_SUCCESS,
        data,
    };
}
export function PatchGlobalMarginFailure(
    error: Record<string, any> | null
): PatchGlobalMarginFailure {
    return {
        type: PRODUCT.PATCH_GLOBAL_MARGIN_FAILURE,
        error,
    };
}

/**
 * Fetch category Margin.
 */
export interface FetchCategoryMargin {
    type: PRODUCT.FETCH_CATEGORY_MARGIN_REQUEST;
    locationId: string;
    offset: number | null;
}
export interface FetchCategoryMarginSuccess {
    type: PRODUCT.FETCH_CATEGORY_MARGIN_SUCCESS;
    data: CategoryMargin;
}
export interface FetchCategoryMarginFailure {
    type: PRODUCT.FETCH_CATEGORY_MARGIN_FAILURE;
    error: Record<string, any> | null;
}
export type FetchCategoryMarginType =
    | FetchCategoryMargin
    | FetchCategoryMarginSuccess
    | FetchCategoryMarginFailure;
export function FetchCategoryMargin(
    locationId: string,
    offset: number | null
): FetchCategoryMargin {
    return {
        type: PRODUCT.FETCH_CATEGORY_MARGIN_REQUEST,
        locationId,
        offset,
    };
}
export function FetchCategoryMarginSuccess(
    data: CategoryMargin
): FetchCategoryMarginSuccess {
    return {
        type: PRODUCT.FETCH_CATEGORY_MARGIN_SUCCESS,
        data,
    };
}
export function FetchCategoryMarginFailure(
    error: Record<string, any>
): FetchCategoryMarginFailure {
    return {
        type: PRODUCT.FETCH_CATEGORY_MARGIN_FAILURE,
        error,
    };
}

/**
 * Save Category Margin
 */
export interface SaveCategoryMargin {
    type: PRODUCT.SAVE_CATEGORY_MARGIN_REQUEST;
    info: Category[];
}
export interface SaveCategoryMarginSuccess {
    type: PRODUCT.SAVE_CATEGORY_MARGIN_SUCCESS;
    data: Category;
}
export interface SaveCategoryMarginFailure {
    type: PRODUCT.SAVE_CATEGORY_MARGIN_FAILURE;
    error: Record<string, any> | null;
}
export type SaveCategoryMarginType =
    | SaveCategoryMargin
    | SaveCategoryMarginSuccess
    | SaveCategoryMarginFailure;

export function SaveCategoryMargin(info: Category[]): SaveCategoryMargin {
    return {
        type: PRODUCT.SAVE_CATEGORY_MARGIN_REQUEST,
        info,
    };
}
export function SaveCategoryMarginSuccess(
    data: Category
): SaveCategoryMarginSuccess {
    return {
        type: PRODUCT.SAVE_CATEGORY_MARGIN_SUCCESS,
        data,
    };
}
export function SaveCategoryMarginFailure(
    error: Record<string, any> | null
): SaveCategoryMarginFailure {
    return {
        type: PRODUCT.SAVE_CATEGORY_MARGIN_FAILURE,
        error,
    };
}

/**
 * patch Category Margin
 */
export interface PatchCategoryMargin {
    type: PRODUCT.PATCH_CATEGORY_MARGIN_REQUEST;
    info: Category[];
}
export interface PatchCategoryMarginSuccess {
    type: PRODUCT.PATCH_CATEGORY_MARGIN_SUCCESS;
    data: Category;
}
export interface PatchCategoryMarginFailure {
    type: PRODUCT.PATCH_CATEGORY_MARGIN_FAILURE;
    error: Record<string, any> | null;
}
export type PatchCategoryMarginType =
    | PatchCategoryMargin
    | PatchCategoryMarginSuccess
    | PatchCategoryMarginFailure;

export function PatchCategoryMargin(info: Category[]): PatchCategoryMargin {
    return {
        type: PRODUCT.PATCH_CATEGORY_MARGIN_REQUEST,
        info,
    };
}
export function PatchCategoryMarginSuccess(
    data: Category
): PatchCategoryMarginSuccess {
    return {
        type: PRODUCT.PATCH_CATEGORY_MARGIN_SUCCESS,
        data,
    };
}
export function PatchCategoryMarginFailure(
    error: Record<string, any> | null
): PatchCategoryMarginFailure {
    return {
        type: PRODUCT.PATCH_CATEGORY_MARGIN_FAILURE,
        error,
    };
}

export interface ResetProductsSettingsPanel {
    type: PRODUCT.RESET_PRODUCT_SETTINGS_PANEL;
}

export type ResetProductsSettingsPanelType = ResetProductsSettingsPanel;

export function resetProductsSettingsPanel(): ResetProductsSettingsPanelType {
    return {
        type: PRODUCT.RESET_PRODUCT_SETTINGS_PANEL,
    };
}



export interface ResetMargins {
    type: PRODUCT.RESET_MARGINS;
}

export type ResetMarginsType = ResetMargins;

export function resetMargins(): ResetMarginsType {
    return {
        type: PRODUCT.RESET_MARGINS,
    };
}