//vendors
import { IonCol } from "@ionic/react";

export const SidePanelHOC = (PassedComponent: any) => {
    return (props: any) => {
        return (
            <IonCol
                sizeLg="3"
                sizeMd="5"
                sizeXs="12"
                className="reports-sidenav ion-no-padding">
                <PassedComponent {...props} />
            </IonCol>
        )
    }
};
export default SidePanelHOC;
