
import React from "react";

import { IonCol, IonRow, IonContent, IonButton } from "@ionic/react";

export const AdminNavigation: React.FC<any> = ({ navigationFlow }) => {
    return (
        <IonContent>
            <div className="admin-navigation-bg">
                <IonRow style={{ height: "10%" }}>
                    <IonCol size="4">
                        {navigationFlow.location === false && (
                            <h4>
                                <b>***NO LOCATIONS FOUND***</b>
                            </h4>
                        )}
                        {navigationFlow.location && (
                            <h4>
                                <b>Best Hotel - Troy</b>
                            </h4>
                        )}
                    </IonCol>
                </IonRow>
                {navigationFlow.location &&
                    !navigationFlow.onBoard &&
                    !navigationFlow.addProducts && (
                        <IonRow className="navigation-row">
                            <IonCol className="navigation-col" size="12">
                                <IonButton className="navigation-button" color="primary" onClick={()=>null}>
                                ONBOARD
            </IonButton>
                            </IonCol>
                        </IonRow>
                    )}
                {(navigationFlow.onBoard || navigationFlow.addProducts) && (
                    <IonRow className="navigation-lobby-row">
                        <div className="main-lobby">
                            <b className="main-lobby-text">Main lobby</b>

                            <IonCol className="navigation-col" size="12">
                                {navigationFlow.onBoard && (
                                    <IonButton className="main-lobby-button" color="primary" onClick={()=>null}>
                                    ADD PRODUCTS
                                    </IonButton>
                                )}
                                {navigationFlow.addProducts && (
                                    <IonButton className="main-lobby-button" color="primary" onClick={()=>null}>
                                    MANAGE PRODUCTS
                                    </IonButton>
                                )}
                            </IonCol>
                        </div>
                    </IonRow>
                )}
            </div>
        </IonContent>
    );
};

export default AdminNavigation;
