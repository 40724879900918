//vendors
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux'
import React from 'react';
// import ReactDOM from 'react-dom/client'; 
import ReactDOM from 'react-dom';
import './i18n/config';
import store from './_redux/store'
//components
import App from './App';
import CPApp from './Client-Portal-App';
import { audience, clientId, domain, scope, portalType } from './auth.config';
//service workers
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration'; 
 //Created for the context provider to pass the portal type as cp or hp
export const PortalContext = React.createContext(null);
const PortalTypes: any = portalType
ReactDOM.render(
  <React.StrictMode>
    <PortalContext.Provider value={PortalTypes}>
      <Auth0Provider
        cacheLocation='localstorage'
        domain={domain}
        clientId={clientId}
        useRefreshTokens={true}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience : audience,
          scope : scope
        }}>
        <Provider store={store}>
          {/* Please choose the (App) for the Hotel portal and (CPApp) for the client portal   */}
          {/* <CPApp /> */}
          <App />
        </Provider>
      </Auth0Provider>
    </PortalContext.Provider>
  </React.StrictMode>,
   document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();