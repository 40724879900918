// vendors
import React, { useCallback, useEffect, useState } from "react";
import { IonLoading, IonPage } from "@ionic/react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// components
import ProfileForm from "../../components/ProfileForm/ProfileForm";
import ToastrHook from "../../components/common/toastr/Toastr";
// reducers
import { RootState } from "../../_redux/reducers/rootReducer";
// actions
import { PostResetPassword } from "../../_redux/actions/resetPasswordActions";
import { fetchSystemUsersRequest, patchSystemUsers } from "../../_redux/actions/systemUsersActions";
import { PostEmailUpdate, emailUpdateFlow } from "../../_redux/actions/emailUpdateActions";
// utils
import { validateEmail, validateUser, validateConfirmEmail, SytemAppType } from "../../utils/FormValidations";
// constants
import {
    GLOBAL_NO_CHANGES_TO_SAVE,
    Hotel365Portal,
    PROFILE_EMAIL_EXISTS_ERR,
    UPDATE_PROFILE_DATA_TOAST_MSG
} from '../../constants/constant';
import RouteLeavingGuard from "../../components/RouteLeavingGuard";
import { portalType } from "../../auth.config";
import { LOADER_MSG } from "../Payment/constants";

export const ProfilePage: React.FC<any> = () => {
    const dispatch = useDispatch();
    const [renderToastr] = ToastrHook();
    const { push } = useHistory();
    const [enableEmailField, setEnableEmailField] = useState(false);
    const sytemPortaltype = SytemAppType(portalType);
    const [isSaveClicked, setIsSaveClicked] = useState(false);
    const [resetPwdClicked, setResetPwdClicked] = useState(false);
    const userDetails = useSelector((state: RootState) => { return state.systemUsers; }, shallowEqual);
    const emailUpdate = useSelector((state: RootState) => { return state.emailUpdate; }, shallowEqual);
    const { errors: userDetailsError, pending: userDetailsPending } = userDetails;
    const { emailUpdateError, pending: emailUpdatePending } = emailUpdate;
    const [user, setUser] = useState({ ...userDetails, newEmail: "", confirmEmail: "" });
    const [formErrors, setFormErrors] = useState({});

    const save = () => {
        const errorObject = validateUser(user, true);
        const errorEmail = validateEmail(user.newEmail);
        const errorConfirmEmail = validateConfirmEmail(user.newEmail, user.confirmEmail);
        const isError = Object.keys(errorObject).length;
        const isErrorEmail = Object.keys(errorEmail).length;
        const isErrorConfirmEmail = Object.keys(errorConfirmEmail).length;
        const areValuesChanged = detectUnsavedChanges();
        setFormErrors({ ...errorObject, ...errorEmail, ...errorConfirmEmail });
        if (isError === 0 && isErrorEmail === 0 && isErrorConfirmEmail === 0) {
            if (
                userDetails?.firstName === user.firstName &&
                userDetails?.lastName === user.lastName &&
                user.newEmail === "" &&
                user.confirmEmail === ""
            ) {
                setEnableEmailField(false);
                renderToastr(GLOBAL_NO_CHANGES_TO_SAVE, "warning");
            }
            if ((userDetails?.firstName !== user.firstName || userDetails?.lastName !== user.lastName) && areValuesChanged) {
                dispatch(patchSystemUsers(user.systemUserId, user));
            }
            if ((user.newEmail !== "" && user.confirmEmail !== "") && areValuesChanged) {
                enableEmailField && dispatch(emailUpdateFlow(user.systemUserId, { email: user.newEmail, systemAppType: sytemPortaltype }));
            }
        }
        setIsSaveClicked(true);
    };

    const resetPassword = useCallback(() => {
        const request = { email: user.email, systemAppType: sytemPortaltype };
        setIsSaveClicked(true);
        setResetPwdClicked(true);
        user.email && dispatch(PostResetPassword(request));
    }, []);

    const detectUnsavedChanges = () => {
        return (userDetails.firstName !== user.firstName || userDetails.lastName !== user.lastName ||
            user.newEmail !== '' || user.confirmEmail !== '' )
    };
    //email update toasters
    useEffect(() => {
        if (!emailUpdate.pending && isSaveClicked) {
            if (emailUpdateError !== null) {
                renderToastr(emailUpdateError?.status === 422 ? PROFILE_EMAIL_EXISTS_ERR : Hotel365Portal.SERVICE_UNAVAILABLE_ERR, "danger");
            }
        }
    }, [emailUpdate]);

    //user details & password update toasters
    useEffect(() => {
        if (userDetailsPending && isSaveClicked) {
            if (userDetailsError !== null) {
                renderToastr(userDetailsError?.status === 401 ? Hotel365Portal.NOT_AUTHORIZED_ERR : Hotel365Portal.SERVICE_UNAVAILABLE_ERR, "danger");
            } else if (userDetailsError === null) {
                renderToastr(resetPwdClicked ? Hotel365Portal.RESET_PASSWORD_MSG : UPDATE_PROFILE_DATA_TOAST_MSG, "success");
                resetPwdClicked && setResetPwdClicked(false);
                enableEmailField && setEnableEmailField(false);
                setUser({ ...userDetails, newEmail: "", confirmEmail: ""});
            }
            setIsSaveClicked(false);
        } else {
            setUser({ ...userDetails, newEmail: user.newEmail, confirmEmail: user.confirmEmail });
        }
    }, [userDetails]);

    return (
        <>
            <IonLoading
                cssClass="my-custom-class"
                isOpen={((emailUpdatePending || userDetailsPending) && isSaveClicked)}
                message={LOADER_MSG}
            />
            <IonPage>
                <ProfileForm
                    resetPassword={resetPassword}
                    onSubmit={save}
                    userData={user}
                    formErrors={formErrors}
                    setUser={setUser}
                    submitCallBack={save}
                    enableEmailField={enableEmailField}
                    showEmailField={() => { setEnableEmailField(true); }}
                />
                <RouteLeavingGuard
                    when={detectUnsavedChanges()}
                    navigate={(path: string) => {
                        push(path);
                        setUser({ ...userDetails, newEmail: '', confirmEmail: '' })                        
                        setEnableEmailField(false);
                        setFormErrors({});
                    }}
                    shouldBlockNavigation={() => {
                        if (detectUnsavedChanges()) {
                            return true;
                        }
                        return true;
                    }}
                />
            </IonPage>
        </>
    );
};

export default ProfilePage;