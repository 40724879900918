export const languages = [
    "en",
    "es",
    "fr",
    "it",
    "de",
    "nl",
    "fr-CA",
    "sv",
    "da",
    "fi",
    "no",
] as const;

export const stringTypes = [
    "labels",
    "buttons",
    "toast_msg",
    "error_msg",
    "constant_texts",
] as const;

export const CSV_HEADERS = [
    { id: "Identifier", title: "Identifier" },
    { id: "en", title: "English" },
    { id: "it", title: "Italian" },
    { id: "de", title: "German" },
    { id: "nl", title: "Dutch" },
    { id: "fr", title: "French" },
    { id: "fr-CA", title: "French-Canadian" },
    { id: "es", title: "Spanish" },
    { id: "sv", title: "Swedish" },
    { id: "da", title: "Danish" },
    { id: "fi", title: "Finnish" },
    { id: "no", title: "Norwegian" },
];