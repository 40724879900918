import React, { memo } from 'react'

import { IonRow, IonButton } from '@ionic/react';
import { ORDER_CLEAR_FILTER } from '../../../../constants/constant'
interface ClearFilterButtonProps {
  onClearFilter: () => void
}

export const ClearFilterButton: React.FC<ClearFilterButtonProps> = memo(
  (props) => {
    return (
      <IonRow className="modal-body clear-filter-background">
        <IonButton
          className="cleat-filter-btn btn-text-cases"
          fill="clear"
          onClick={props.onClearFilter}
        >
          {ORDER_CLEAR_FILTER}
        </IonButton>
      </IonRow>
    )
  },
)
