import React, { useMemo } from 'react';

import { usePagination, DOTS } from './hook';

export const Pagination: React.FC<any> = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  let lastPage = useMemo(
    () => paginationRange && paginationRange[paginationRange.length - 1],
    [paginationRange]
);
  if (currentPage === 0 || (paginationRange && paginationRange.length < 1)) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  return (
    <React.Fragment>
    <ul
      className={`pagination-container ${className}`}
    >
      <li
        className={`pagination-item ${currentPage === 1 ? 'disabled': ''}`}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      {paginationRange && paginationRange.length > 0 && paginationRange.map(pageNumber => {
        if (pageNumber === DOTS) {
          return <li key={pageNumber} className="pagination-item dots">&#8230;</li>;
        }

        return (
          <li
            className={`pagination-item ${pageNumber === currentPage ? 'selected': ''}`}
            onClick={() => onPageChange(pageNumber)}
            key={pageNumber}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={`pagination-item ${currentPage === lastPage ? 'disabled': ''}`}
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
    </React.Fragment>
  );
};

{/**
const [currentPage, setCurrentPage] = useState(1);
let PageSize = 10;

<Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={data.length}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
      />
 */}
export default Pagination;
