import React, { useContext } from "react";
import { IonCol, IonLabel, IonRow, IonList } from "@ionic/react";
import { shallowEqual, useSelector } from "react-redux";
import { PortalContext } from "../..";
import { RootState } from "../../_redux/reducers/rootReducer";
//utils
import { getSideNavItemClassname, filterNavItemsOnRoles, getSidenavItemsByMarketType } from "../../utils";
import { getRoleId } from "../../utils/roleBasedAccess";

const Sidenav: React.FC<any> = ({ activeNavItem, setNavItemActiveOnClick, sideNavItems = [] }) => {
    const _portalTypes = useContext(PortalContext) as any;
    const systemUser = useSelector((state: RootState) => { return state.systemUsers; }, shallowEqual);
    const { roles } = systemUser;
    const userRoleIds = getRoleId(roles);
    const filteredSideNavItemsByMarketType: Array<Record<string, any>> = getSidenavItemsByMarketType(sideNavItems, _portalTypes);
    return (
        <IonList className="nav-col ion-no-padding">
            {filterNavItemsOnRoles(filteredSideNavItemsByMarketType, userRoleIds).map((item: any) => {
                return (
                    <IonRow className="sidenav-item-dimension" key={item.name}>
                        <IonCol
                            onClick={() => setNavItemActiveOnClick(item.name)}
                            className={getSideNavItemClassname(_portalTypes, activeNavItem, item)}>
                            <>
                                <svg viewBox="-100 0 860 640">
                                    <g><polygon points={item.points} /><path d={item.path} /></g>
                                </svg>
                                <IonLabel className="sidenav-label">{item.label}</IonLabel>
                            </>
                        </IonCol>
                    </IonRow>
                )
            })}
        </IonList>
    );
};

export default Sidenav;