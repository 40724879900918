import * as React from "react";
//constants
import {
    POS_BOTTLE_DEPOSIT,
    POS_RECEIPT_HEADER,
    GLOBAL_SUB_TOTAL,
    POS_TAX, GLOBAL_FOOTER_TOTAL
} from '../../constants/constant';
import { addZeroes, getLocalizedNumericValues } from "../../utils";


export class ComponentToPrint extends React.PureComponent<any, any>{
    constructor(props: any) {
        super(props);
    }
    render() {

        const getSubtotal = () => {
            if (
                this.props.printCartReceiptData.salesItems &&
                this.props.printCartReceiptData.salesItems.length > 0
            ) {
                return this.props.printCartReceiptData.salesItems.reduce(
                    (
                        sum: number,
                        currentItem: { price: number; quantity: number }
                    ) => {
                        return sum + currentItem.price * currentItem.quantity;
                    },
                    0
                );
            }
            return 0;
        };
        const getTax = () => {
            if (
                this.props.printCartReceiptData.salesItems &&
                this.props.printCartReceiptData.salesItems.length > 0
            ) {
                return this.props.printCartReceiptData.salesItems.reduce(
                    (
                        sum: number,
                        currentItem: { tax: number; tax2: number; tax3: number; tax4: number; quantity: number }
                    ) => {
                        return sum + (currentItem.tax + currentItem.tax2 + currentItem.tax3 + currentItem.tax4) * currentItem.quantity;
                    },
                    0
                );
            }
            return 0;
        };

        const getDeposit = () => {
            if (
                this.props.printCartReceiptData.salesItems &&
                this.props.printCartReceiptData.salesItems.length > 0
            ) {
                return this.props.printCartReceiptData.salesItems.reduce(
                    (
                        sum: number,
                        currentItem: { deposit: any; quantity: number }
                    ) => {
                        return (
                            sum + (currentItem.deposit || 0) * currentItem.quantity
                        );
                    },
                    0
                );
            }
            return 0;
        };

        const getPageMargins = () => {
            return `
      @page {
        size: portrait; 
        margin: 10mm; 
      }    
      @media all {
        .pagebreak {
          display: none;
        }
      }    
       
      @media print {
        .page-break {
          margin-top: 1rem;
          display: block;
          page-break-before: auto;
        }
      }
      div.pos-print-receipt { 
        text-align: -webkit-center;
        margin-left: 115px; 
      } 
      div.receipt-main-container{
        display: flex; 
        width: 50%;    
      }
      div.receipt-left-col {
        width: 50%;  
        padding: 8px;  
        text-align: left;  
        inline-size: 160px;
        overflow-wrap: break-word;
      }
      div.receipt-right-col {
        width: 50%; 
        padding: 8px; 
        text-align: left; 
      } 
      div h2 {
        text-align: left; 
        width : 50%; 
        margin-left : 10px;  
      } 
      `;
        };
        return (
            <>
                <div className="pos-print-receipt">
                    <style type="text/css" media="print">
                        {getPageMargins()}
                    </style>
                    <div className="flash" />
                    <div>
                        <h2>
                            <b>{POS_RECEIPT_HEADER}</b>
                        </h2>
                    </div>
                    {this.props.printReceiptData.length > 0 &&
                        this.props.printReceiptData.map((res: any) => (
                            <>
                                <div
                                    className="receipt-main-container"
                                    key={res.name}
                                >
                                    <div className="receipt-left-col">
                                        <span>
                                            <b>{res.quantity}</b> {res.name}
                                        </span>
                                        <span> @  {getLocalizedNumericValues(res.price, "currency", this.props.currency)}</span>
                                    </div>
                                    <div className="receipt-right-col">
                                        <b>
                                            {" "}   
                                          {getLocalizedNumericValues(res.quantity * res.price, "currency", this.props.currency)}

                                        </b>
                                    </div>
                                </div>
                            </>
                        ))}
                    <React.Fragment>
                        {Number(getDeposit().toFixed(2)) != 0 && (
                            <div className="receipt-main-container">
                                <div className="receipt-left-col">
                                    {POS_BOTTLE_DEPOSIT}
                                </div>
                                <div className="receipt-right-col">
                                    {getLocalizedNumericValues(getDeposit(), "currency", this.props.currency)}

                                </div>
                            </div>
                        )}
                    </React.Fragment>

                    <div className="receipt-main-container">
                        <div className="receipt-left-col">{GLOBAL_SUB_TOTAL}</div>
                        <div className="receipt-right-col">
                            {getLocalizedNumericValues(getSubtotal(), "currency", this.props.currency)}
                        </div>
                    </div>
                    <div className="receipt-main-container">
                        <div className="receipt-left-col">{POS_TAX}</div>
                        <div className="receipt-right-col">
                            {getLocalizedNumericValues(getTax(), "currency", this.props.currency)}
                        </div>
                    </div>
                    <div className="receipt-main-container">
                        <div className="receipt-left-col">{GLOBAL_FOOTER_TOTAL}</div>
                        <div className="receipt-right-col"> 
                              {getLocalizedNumericValues(getSubtotal() + getTax() + getDeposit() , "currency", this.props.currency)}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export const PrintReceipt = React.forwardRef((props: any, ref: React.ForwardedRef<any>,) => {
    // eslint-disable-line max-len  
    return <div style={{ display: "none" }} ><div ref={ref}><ComponentToPrint printReceiptData={props.printItemDetails} printCartReceiptData={props.printCartDetails} currency={props.currency} /></div></div>;

});