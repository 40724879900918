import { type } from "os";

/* Post Reposrts types */
export const RUN_REPORT = 'RUN_REPORT';
export const POST_REPORT_REQUEST = 'POST_REPORT_REQUEST';
export const POST_REPORT_SUCCESS = 'POST_REPORT_SUCCESS';
export const POST_REPORT_FAILURE = 'POST_REPORT_FAILURE';

export type RUN_REPORT = typeof RUN_REPORT;
export type POST_REPORT_REQUEST = typeof POST_REPORT_REQUEST;
export type POST_REPORT_SUCCESS = typeof POST_REPORT_SUCCESS;
export type POST_REPORT_FAILURE = typeof POST_REPORT_FAILURE;
