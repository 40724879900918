 import * as RESET_PASSWORD from '../../ActionTypes/resetPasswordTypes';
import { all, put, take, takeLatest } from "redux-saga/effects";
import { doPost , doPut} from '../../../utils/fetchWrapper';
import { 
    PostResetPasswordFailure,
    PostResetPasswordSuccess,
    PutResetPasswordFailure,
    PutResetPasswordSuccess
} from "../../actions/resetPasswordActions";
import { Routes } from '../../../constants/Routes';

function* postResetPassword(action: any) {
     let url = `${Routes.systemUserActions.postResetPassword}`;
    try {
        // @ts-ignore
        const response = yield doPost(url, action.data);
        yield put(PostResetPasswordSuccess(response));
    } catch (error: any) { 
        yield put(PostResetPasswordFailure(error));
    }
}

function* putResetPassword(action: any) {
    const { data,systemUserId} = action;  
     let url = `${Routes.systemUserActions.url}${systemUserId}/password`; 
    const request = { ...data };
    try {
        // @ts-ignore
        const response = yield doPut(url, request);
        yield put(PutResetPasswordSuccess(response));
    } catch (error: any) { 
        yield put(PutResetPasswordFailure(error));
    }
}
 

function* ResetPasswordSaga() {
    yield all([ 
        takeLatest(RESET_PASSWORD.POST_RESET_PASSWORD_REQUEST, postResetPassword), 
        takeLatest(RESET_PASSWORD.PUT_RESET_PASSWORD_REQUEST, putResetPassword), 
    ])
}

export default ResetPasswordSaga;