import { IonImg } from "@ionic/react";

export function imageDescriptor(): JSX.Element {
    return <IonImg className="cp-user-site-logo" src="/assets/Client365.svg" />;
}

export function headerMarketLabels(props: any): JSX.Element {
    return <></>
}
export default { imageDescriptor, headerMarketLabels };
