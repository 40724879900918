import { UserManagement, UserManagements } from "../../models/UserManagement";
import * as USERMANAGEMENT from "../ActionTypes/userManagementTypes";

/**
 * get system users
 */
export interface FetchUserDetailRequest {
    type: USERMANAGEMENT.FETCH_USERDETAIL_REQUEST;
    systemUserId: string;
}
export interface FetchUserDetailSuccess {
    type: USERMANAGEMENT.FETCH_USERDETAIL_SUCCESS;
    data: UserManagement;
}
export interface FetchUserDetailFailure {
    type: USERMANAGEMENT.FETCH_USERDETAIL_FAILURE;
    error: Record<string, any> | null;
}
export type FetchUserDetailType =
    | FetchUserDetailRequest
    | FetchUserDetailSuccess
    | FetchUserDetailFailure;

export function fetchUserRequest(systemUserId: string): FetchUserDetailRequest {
    return {
        type: USERMANAGEMENT.FETCH_USERDETAIL_REQUEST,
        systemUserId,
    };
}

export function fetchUserSuccess(data: UserManagement): FetchUserDetailSuccess {
    return {
        type: USERMANAGEMENT.FETCH_USERDETAIL_SUCCESS,
        data,
    };
}
export function fetchUserFailure(
    error: Record<string, any>
): FetchUserDetailFailure {
    return {
        type: USERMANAGEMENT.FETCH_USERDETAIL_FAILURE,
        error,
    };
}

/**
 * patch system users/edit
 */

export interface PatchUser {
    type: USERMANAGEMENT.PATCH_USER_REQUEST;
    systemUserId: string;
    data: Record<string, any>;
}
export interface PatchUserSuccess {
    type: USERMANAGEMENT.PATCH_USER_SUCCESS;
    data: Record<string, any>;
}

export interface PatchUserFailure {
    type: USERMANAGEMENT.PATCH_USER_FAILURE;
    error: Record<string, any> | null;
}

export type PatchUserType =
    | PatchUser
    | PatchUserSuccess
    | PatchUserFailure;

export function PatchUser(
    systemUserId: string,
    data: Record<string, any>
): PatchUser {
    return {
        type: USERMANAGEMENT.PATCH_USER_REQUEST,
        systemUserId,
        data,
    };
}
export function PatchUserSuccess(
    data: Record<string, any>
): PatchUserSuccess {
    return {
        type: USERMANAGEMENT.PATCH_USER_SUCCESS,
        data,
    };
}

export function PatchUserFailure(
    error: Record<string, any> | null
): PatchUserFailure {
    return {
        type: USERMANAGEMENT.PATCH_USER_FAILURE,
        error,
    };
}

export interface PostUser {
    type: USERMANAGEMENT.POST_USER_REQUEST;
    data: Record<string, any>;
}
export interface PostUserSuccess {
    type: USERMANAGEMENT.POST_USER_SUCCESS;
    response: UserManagement;
}

export interface PostUserFailure {
    type: USERMANAGEMENT.POST_USER_FAILURE;
    error: Record<string, any> | null;
}
export type PostUserType =
    | PostUser
    | PostUserSuccess
    | PostUserFailure;

export function PostUser(
    data: Record<string, any>
): PostUser {
    return {
        type: USERMANAGEMENT.POST_USER_REQUEST,
        data,
    };
}

export function PostUserSuccess(
    response: UserManagement
): PostUserSuccess {
    return {
        type: USERMANAGEMENT.POST_USER_SUCCESS,
        response,
    };
}
export function PostUserFailure(
    error: Record<string, any>
): PostUserFailure {
    return {
        type: USERMANAGEMENT.POST_USER_FAILURE,
        error: error,
    };
}

/**
 * fetch  system users
 */
export interface FetchUsers {
    type: USERMANAGEMENT.FETCH_USERS_REQUEST;
    locationId: string;
    offset: number | null;
}
export interface FetchUsersSuccess {
    type: USERMANAGEMENT.FETCH_USERS_SUCCESS;
    data: UserManagements;
}
export interface FetchUsersFailure {
    type: USERMANAGEMENT.FETCH_USERS_FAILURE;
    error: Record<string, any> | null;
}
export type FetchUsersType =
    | FetchUsers
    | FetchUsersSuccess
    | FetchUsersFailure;

export function FetchUsers(
    locationId: string,
    offset: number | null
): FetchUsers {
    return {
        type: USERMANAGEMENT.FETCH_USERS_REQUEST,
        locationId,
        offset,
    };
}

export function FetchUsersSuccess(
    data: UserManagements
): FetchUsersSuccess {
    return {
        type: USERMANAGEMENT.FETCH_USERS_SUCCESS,
        data,
    };
}

export function FetchUsersFailure(
    error: Record<string, any>
): FetchUsersFailure {
    return {
        type: USERMANAGEMENT.FETCH_USERS_FAILURE,
        error,
    };
}

/*
delete action
*/
export interface DeleteUser {
    type: USERMANAGEMENT.DELETE_USER_REQUEST;
    systemUserId: string;
    data: Record<string, any>;
}
export interface DeleteUserSuccess {
    type: USERMANAGEMENT.DELETE_USER_SUCCESS;
    data: Record<string, any>;
}
export interface DeleteUserFailure {
    type: USERMANAGEMENT.DELETE_USER_FAILURE;
    error: Record<string, any> | null;
}
export type DeleteUserType =
    | DeleteUser
    | DeleteUserSuccess
    | DeleteUserFailure;

export function DeleteUser(
    systemUserId: string,
    data: Record<string, any>
): DeleteUser {
    return {
        type: USERMANAGEMENT.DELETE_USER_REQUEST,
        systemUserId,
        data,
    };
}

export function DeleteUserSuccess(
    data: Record<string, any>
): DeleteUserSuccess {
    return {
        type: USERMANAGEMENT.DELETE_USER_SUCCESS,
        data,
    };
}
export function DeleteUserFailure(
    error: Record<string, any> | null
): DeleteUserFailure {
    return {
        type: USERMANAGEMENT.DELETE_USER_FAILURE,
        error,
    };
}

/*
 Resend 
*/
// resend api is made changes according to api calls
export interface ResendInviteUser {
    type: USERMANAGEMENT.RESEND_INVITE_LINK_REQUEST;
    info: string
}
export interface ResendInviteUserSuccess {
    type: USERMANAGEMENT.RESEND_INVITE_LINK_SUCCESS;
    data: Record<string, any> | string;
}

export interface ResendInviteUserFailure {
    type: USERMANAGEMENT.RESEND_INVITE_LINK_FAILURE;
    error: Record<string, any> | null;
}

export type ResendInviteUserType =
    | ResendInviteUser
    | ResendInviteUserSuccess
    | ResendInviteUserFailure;

export function resendInviteUser(info: string): ResendInviteUser {
    return {
        type: USERMANAGEMENT.RESEND_INVITE_LINK_REQUEST,
        info,
    };
}

export function resendInviteUserSuccess(
    data: Record<string, any> | string
): ResendInviteUserSuccess {
    return {
        type: USERMANAGEMENT.RESEND_INVITE_LINK_SUCCESS,
        data,
    };
}

export function resendInviteUserFailure(
    error: Record<string, any> | null
): ResendInviteUserFailure {
    return {
        type: USERMANAGEMENT.RESEND_INVITE_LINK_FAILURE,
        error,
    };
}


/**
 * patch system users/edit role
 */

export interface PutUserRole {
    type: USERMANAGEMENT.PUT_USER_ROLE_REQUEST;
    systemUserId: string;
    roleId: string;
    data: Record<string, any>;
}
export interface PutUserRoleSuccess {
    type: USERMANAGEMENT.PUT_USER_ROLE_SUCCESS;
    data: Record<string, any>;
}

export interface PutUserRoleFailure {
    type: USERMANAGEMENT.PUT_USER_ROLE_FAILURE;
    error: Record<string, any> | null;
}

export type PutUserRoleType =
    | PutUserRole
    | PutUserRoleSuccess
    | PutUserRoleFailure;

export function putUserRole(
    systemUserId: string,
    roleId: string,
    data: Record<string, any>
): PutUserRole {
    return {
        type: USERMANAGEMENT.PUT_USER_ROLE_REQUEST,
        systemUserId,
        roleId,
        data,
    };
}
export function putUserRoleSuccess(
    data: Record<string, any>
): PutUserRoleSuccess {
    return {
        type: USERMANAGEMENT.PUT_USER_ROLE_SUCCESS,
        data,
    };
}

export function putUserRoleFailure(
    error: Record<string, any> | null
): PutUserRoleFailure {
    return {
        type: USERMANAGEMENT.PUT_USER_ROLE_FAILURE,
        error,
    };
}


/**
 * get system user LOCATION
 */
export interface FetchUserDetailLocationRequest {
    type: USERMANAGEMENT.FETCH_USERDETAIL_LOCATION_REQUEST;
    systemUserId: string;
}
export interface FetchUserDetailLocationSuccess {
    type: USERMANAGEMENT.FETCH_USERDETAIL_LOCATION_SUCCESS;
    data: Record<string, unknown>[];
}
export interface FetchUserDetailLocationFailure {
    type: USERMANAGEMENT.FETCH_USERDETAIL_LOCATION_FAILURE;
    error: Record<string, any> | null;
}
export type FetchUserDetailLocationType =
    | FetchUserDetailLocationRequest
    | FetchUserDetailLocationSuccess
    | FetchUserDetailLocationFailure;

export function fetchUserLocationRequest(systemUserId: string): FetchUserDetailLocationRequest {
    return {
        type: USERMANAGEMENT.FETCH_USERDETAIL_LOCATION_REQUEST,
        systemUserId,
    };
}

export function fetchUserLocationSuccess(data: Record<string, unknown>[]): FetchUserDetailLocationSuccess {
    return {
        type: USERMANAGEMENT.FETCH_USERDETAIL_LOCATION_SUCCESS,
        data,
    };
}
export function fetchUserLocationFailure(
    error: Record<string, any>
): FetchUserDetailLocationFailure {
    return {
        type: USERMANAGEMENT.FETCH_USERDETAIL_LOCATION_FAILURE,
        error,
    };
}


/**
 * put system user LOCATION
 */
export interface UpdateUserDetailLocationRequest {
    type: USERMANAGEMENT.PUT_USERDETAIL_LOCATION_REQUEST;
    systemUserId: string;
    data: Record<string, any>;
}
export interface UpdateUserDetailLocationSuccess {
    type: USERMANAGEMENT.PUT_USERDETAIL_LOCATION_SUCCESS;
    data: Record<string, unknown>;
}
export interface UpdateUserDetailLocationFailure {
    type: USERMANAGEMENT.PUT_USERDETAIL_LOCATION_FAILURE;
    error: Record<string, any> | null;
}
export type UpdateUserDetailLocationType =
    | UpdateUserDetailLocationRequest
    | UpdateUserDetailLocationSuccess
    | UpdateUserDetailLocationFailure;

export function updateUserLocationRequest(systemUserId: string, data: Record<string, any>): UpdateUserDetailLocationRequest {
    return {
        type: USERMANAGEMENT.PUT_USERDETAIL_LOCATION_REQUEST,
        systemUserId,
        data
    };
}

export function updateUserLocationSuccess(data: Record<string, unknown>): UpdateUserDetailLocationSuccess {
    return {
        type: USERMANAGEMENT.PUT_USERDETAIL_LOCATION_SUCCESS,
        data,
    };
}
export function updateUserLocationFailure(
    error: Record<string, any>
): UpdateUserDetailLocationFailure {
    return {
        type: USERMANAGEMENT.PUT_USERDETAIL_LOCATION_FAILURE,
        error,
    };
}



/**
 * put system user organization
 */
export interface UpdateUserDetailOrganizationRequest {
    type: USERMANAGEMENT.PUT_USERDETAIL_ORGANIZATION_REQUEST;
    systemUserId: string;
    data: Record<string, any>;
}
export interface UpdateUserDetailOrganizationSuccess {
    type: USERMANAGEMENT.PUT_USERDETAIL_ORGANIZATION_SUCCESS;
    data: Record<string, unknown>;
}
export interface UpdateUserDetailOrganizationFailure {
    type: USERMANAGEMENT.PUT_USERDETAIL_ORGANIZATION_FAILURE;
    error: Record<string, any> | null;
}
export interface SetLocationForCpSuccess {
    type: USERMANAGEMENT.SET_LOCATION_FOR_CP;
    data: Record<string, unknown>[];
}
export type UpdateUserDetailOrganizationType =
    | UpdateUserDetailOrganizationRequest
    | UpdateUserDetailOrganizationSuccess
    | UpdateUserDetailOrganizationFailure
    | SetLocationForCpSuccess;

export function updateUserOrganizationRequest(systemUserId: string, data: Record<string, any>): UpdateUserDetailOrganizationRequest {
    return {
        type: USERMANAGEMENT.PUT_USERDETAIL_ORGANIZATION_REQUEST,
        systemUserId,
        data
    };
}

export function updateUserOrganizationSuccess(data: Record<string, unknown>): UpdateUserDetailOrganizationSuccess {
    return {
        type: USERMANAGEMENT.PUT_USERDETAIL_ORGANIZATION_SUCCESS,
        data,
    };
}
export function updateUserOrganizationFailure(
    error: Record<string, any>
): UpdateUserDetailOrganizationFailure {
    return {
        type: USERMANAGEMENT.PUT_USERDETAIL_ORGANIZATION_FAILURE,
        error,
    };
}

export function setLocationForCP(data: Record<string, unknown>[]): SetLocationForCpSuccess {
    return {
        type: USERMANAGEMENT.SET_LOCATION_FOR_CP,
        data,
    }
}

export interface ResetFetchUsers {
    type: USERMANAGEMENT.RESET_FETCH_USERS;
    resetUserDetails?: boolean;
}

export type ResetFetchUsersType = ResetFetchUsers;

export function resetFetchUsers(resetUserDetails = false): ResetFetchUsersType {
    return {
        type: USERMANAGEMENT.RESET_FETCH_USERS,
        resetUserDetails
    };
}



/**
 * post system user email
 */
export interface PostUserDetailEmailRequest {
    type: USERMANAGEMENT.POST_USERDETAIL_EMAIL_REQUEST;
    systemUserId: string;
    data: Record<string, any>;
}
export interface PostUserDetailEmailSuccess {
    type: USERMANAGEMENT.POST_USERDETAIL_EMAIL_SUCCESS;
    data: Record<string, unknown>;
}
export interface PostUserDetailEmailFailure {
    type: USERMANAGEMENT.POST_USERDETAIL_EMAIL_FAILURE;
    error: Record<string, any> | null;
}
export type PostUserDetailEmailType =
    | PostUserDetailEmailRequest
    | PostUserDetailEmailSuccess
    | PostUserDetailEmailFailure;

export function postUserEmailRequest(systemUserId: string, data: Record<string, any>): PostUserDetailEmailRequest {
    return {
        type: USERMANAGEMENT.POST_USERDETAIL_EMAIL_REQUEST,
        systemUserId,
        data
    };
}

export function postUserEmailSuccess(data: Record<string, unknown>): PostUserDetailEmailSuccess {
    return {
        type: USERMANAGEMENT.POST_USERDETAIL_EMAIL_SUCCESS,
        data,
    };
}
export function postUserEmailFailure(
    error: Record<string, any>
): PostUserDetailEmailFailure {
    return {
        type: USERMANAGEMENT.POST_USERDETAIL_EMAIL_FAILURE,
        error,
    };
}