import { ResetPassword, UpdatePassword } from './../../models/ResetPassword';
import * as RESETPASSWORD from '../ActionTypes/resetPasswordTypes';

export interface ResetPasswordData {
    type: RESETPASSWORD.RESET_PASSWORD_DATA;
}
export type ResetPasswordType = ResetPasswordData;
export function resetPasswordData(): ResetPasswordType {
    return {
        type: RESETPASSWORD.RESET_PASSWORD_DATA,
    };
}
/* Post Reset password API */
export interface PostResetPassword {
    type: RESETPASSWORD.POST_RESET_PASSWORD_REQUEST; 
    data: Record<string, any>;
}
export interface PostResetPasswordSuccess {
    type: RESETPASSWORD.POST_RESET_PASSWORD_SUCCESS;
    response: ResetPassword;
}

export interface PostResetPasswordFailure {
    type: RESETPASSWORD.POST_RESET_PASSWORD_FAILURE;
    error: Record<string, any> | null;
}
export type PostResetPasswordType =
    | PostResetPassword
    | PostResetPasswordSuccess
    | PostResetPasswordFailure;

export function PostResetPassword(data: Record<string, any>): PostResetPassword {
    return {
        type: RESETPASSWORD.POST_RESET_PASSWORD_REQUEST, 
        data
    };
}

export function PostResetPasswordSuccess(response: ResetPassword): PostResetPasswordSuccess {
    return {
        type: RESETPASSWORD.POST_RESET_PASSWORD_SUCCESS,
        response,
    };
}
export function PostResetPasswordFailure(error: Record<string, any>): PostResetPasswordFailure {
    return {
        type: RESETPASSWORD.POST_RESET_PASSWORD_FAILURE,
        error: error,
    };
}

/* Put Reset password API */

export interface PutResetPassword {
    type: RESETPASSWORD.PUT_RESET_PASSWORD_REQUEST; 
    systemUserId: string;
    data: Record<string, any>;
}
export interface PutResetPasswordSuccess {
    type: RESETPASSWORD.PUT_RESET_PASSWORD_SUCCESS;
    response: UpdatePassword;
}

export interface PutResetPasswordFailure {
    type: RESETPASSWORD.PUT_RESET_PASSWORD_FAILURE;
    error: Record<string, any> | null;
}
export type PutResetPasswordType =
    | PutResetPassword
    | PutResetPasswordSuccess
    | PutResetPasswordFailure;

export function PutResetPassword(systemUserId: string,data: Record<string, any>): PutResetPassword {
    return {
        type: RESETPASSWORD.PUT_RESET_PASSWORD_REQUEST, 
        systemUserId,
        data
    };
}

export function PutResetPasswordSuccess(response: UpdatePassword): PutResetPasswordSuccess {
    return {
        type: RESETPASSWORD.PUT_RESET_PASSWORD_SUCCESS,
        response,
    };
}
export function PutResetPasswordFailure(error: Record<string, any>): PutResetPasswordFailure {
    return {
        type: RESETPASSWORD.PUT_RESET_PASSWORD_FAILURE,
        error: error,
    };
}