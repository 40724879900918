//actions
import {
    FetchSystemUsersRequest,
    FetchSystemUsersSuccess,
    FetchSystemUsersFailure,
    PatchSystemUsers,
    PatchSystemUsersSuccess,
    PatchSystemUsersFailure,
} from '../../models/SystemUsers';
import { PostResetPassword, PostResetPasswordSuccess, PostResetPasswordFailure } from '../actions/resetPasswordActions';

export type systemUsersTypes = FetchSystemUsersSuccess | FetchSystemUsersRequest;

export interface InterfaceSystemUsers {
    pending: boolean;
    systemUserId: string;
    role: Record<string, any> | null;
    email: string;
    defaultOrgId: string;
    firstName: string;
    lastName: string,
    lastOrgId: Record<string, any> | null;
    orgs: Record<string, any> | null;
    locations: Record<string, any> | null;
    roles: Record<string, any> | null;
    addAutoOrgs: boolean;
    addAutoLocs: boolean;
    dateCreated: string;
    lastUpdated: string;
    locationLastViewedId: string;
    errors?: Record<string, any> | null;
}
const initialState = {
    pending: false,
    systemUserId: '',
    role: [],
    email: '',
    defaultOrgId: '',
    firstName: '',
    lastName: '',
    lastOrgId: null,
    orgs: [],
    locations: null,
    roles: [],
    addAutoOrgs: false,
    addAutoLocs: false,
    dateCreated: '',
    lastUpdated: '',
    locationLastViewedId: '',
    errors: null,
};

const strategies = {
    FETCH_SYSTEMUSERS_REQUEST: fetchSystemUsers,
    FETCH_SYSTEMUSERS_SUCCESS: fetchSystemUsersSuccess,
    FETCH_SYSTEMUSERS_FAILURE: fetchSystemUsersFailure,
    PATCH_SYSTEMUSERS_REQUEST: patchSystemUsersRequest,
    PATCH_SYSTEMUSERS_SUCCESS: patchSystemUsersSuccess,
    PATCH_SYSTEMUSERS_FAILURE: patchSystemUsersFailure,
    POST_RESET_PASSWORD_REQUEST: postResetPasswordRequest,
    POST_RESET_PASSWORD_SUCCESS: postResetPasswordSuccess,
    POST_RESET_PASSWORD_FAILURE: postResetPasswordFailure,
    default: (state: InterfaceSystemUsers) => state,
};

function fetchSystemUsers(state: InterfaceSystemUsers) {
    return { ...state, errors: null, pending: true };
}

function fetchSystemUsersSuccess(state: InterfaceSystemUsers, action: FetchSystemUsersSuccess) {
    return { ...state, ...action.data, errors: null, pending: false };
}

function fetchSystemUsersFailure(state: InterfaceSystemUsers, action: FetchSystemUsersFailure) {
    return { ...state, pending: false, errors: { ...action.error } };
}

function patchSystemUsersRequest(state: InterfaceSystemUsers, action: PatchSystemUsers) {
    return { ...state, errors: null, pending: true }
}

function patchSystemUsersSuccess(state: InterfaceSystemUsers, action: PatchSystemUsersSuccess) {
    return { ...state, errors: null, pending: false, ...action.response }
}

function patchSystemUsersFailure(state: InterfaceSystemUsers, action: PatchSystemUsersFailure) {
    return { ...state, errors: action.error, pending: false }
}
function postResetPasswordRequest(state: InterfaceSystemUsers, action: PostResetPassword) {
    return { ...state, errors: null, pending: true }
}

function postResetPasswordSuccess(state: InterfaceSystemUsers) {
    return { ...state, errors: null, pending: false }
}

function postResetPasswordFailure(state: InterfaceSystemUsers, action: PostResetPasswordFailure) {
    return { ...state, errors: action.error, pending: false, passwordUpdateError: action.error }
}

export default (state: InterfaceSystemUsers = initialState, action: systemUsersTypes): InterfaceSystemUsers => {
    return (strategies[action.type] || strategies.default)(state, action as never);
};