//vendors
import React, { useState, useEffect, useMemo, useContext } from "react";
import { IonItem, IonList } from "@ionic/react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
//constants
import { Routes } from "../../constants/Routes";
import { dashboardLabels, HOTEL_ADMIN_ID, CLIENT_ADMIN_ID, USER_MGMT_HEADER } from "../../constants/constant";
// reducers
import { RootState } from "../../_redux/reducers/rootReducer";
// actions
import { afterLocationChange } from "../../_redux/actions/systemUsersActions";
import { fetchProfileRequest } from "../../_redux/actions/profileActions";
import { resetPOS } from "../../_redux/actions/posActions";
//utils
import { getRoleId, isOnboardingAccessPermitted } from "../../utils/roleBasedAccess";
import { PortalContext } from '../..';
//descriptors
import { getPopOverListDescriber } from "./Descriptor/PopoverListDescriptor";
//interfaces
import { MenuItems } from "../../models/UserProfile";

export const PopoverList: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    const {
        doLogout,
        onHide,
        history,
        onboardedProfiles,
        needsOnboardingProfiles,
        systemUserId,
        allProfilesError,
        currentProfileError,
        marketType
    } = props;
    const systemUsers = useSelector((state: RootState) => { return state.systemUsers }, shallowEqual);
    const { locationLastViewedId, roles, pending } = systemUsers;
    const [selectedLocation, setSelectedLocation] = useState(locationLastViewedId);
    const roleId = getRoleId(roles);
    const _portalTypes = useContext(PortalContext) as any;
    const { menuItems } = getPopOverListDescriber(_portalTypes);

    const isOnboardingPermitted = useMemo(() => isOnboardingAccessPermitted(pending, roles), [pending, roles]);

    const navigateTo = (url: any) => {
        onHide();
        history.push(url);
    };

    const onLocationCHange = (locationId: string, orgId: string) => {
        dispatch(resetPOS());
        dispatch(afterLocationChange(systemUserId, { locationLastViewedId: locationId, orgId }));
        onHide();
    };

    const onLocationSelectedToOnboard = (locationId: string) => {
        dispatch(fetchProfileRequest(locationId));
        onHide();
        history.push(Routes.onboarding.url);
    };

    const logoutHandler = () => {
        doLogout();
        onHide();
        localStorage.removeItem("env");
    }

    useEffect(() => {
        const { locationLastViewedId } = systemUsers;
        setSelectedLocation(locationLastViewedId);
    }, [systemUsers, locationLastViewedId]);

    const menuItemProps: MenuItems = {
        roleId,
        navigateTo,
        logoutHandler,
        allProfilesError,
        onLocationCHange,
        selectedLocation,
        onboardedProfiles,
        currentProfileError,
        isOnboardingPermitted,
        needsOnboardingProfiles,
        onLocationSelectedToOnboard
    };
    return (
        <IonList className="menu-tab">
            {allProfilesError === null && currentProfileError === null && (
                <>
                    <IonItem className="clickableText" onClick={() => { navigateTo(Routes.profile.url); }}>{dashboardLabels.PROFILE_LABEL}</IonItem>
                    {roleId && roleId.length > 0 && roleId.find((value: string) => value === HOTEL_ADMIN_ID || CLIENT_ADMIN_ID) && (
                        <IonItem className="clickableText" onClick={() => { navigateTo(Routes.userManagement.url); }}>{USER_MGMT_HEADER}</IonItem>
                    )}
                    {menuItems(menuItemProps)}
                </>
            )}
            <IonItem className="clickableText" onClick={() => { logoutHandler() }}>{dashboardLabels.LOGOUT_LABEL}</IonItem>
        </IonList>
    );
};
