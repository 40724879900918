import { PostReportRequest, PostReportSuccess, PostReportFailure, PostReportsType, RunReport } from "../actions/reportsActions";

const initialState = {
    data: {},
    error: null,
    pending: true,
    isReportRan: false,
}

const strategies = {
    POST_REPORT_REQUEST: postReportRequest,
    POST_REPORT_SUCCESS: postReportSuccess,
    POST_REPORT_FAILURE: postReportFailure,
    RUN_REPORT: runReport,
    default: (state: any) => state,
}

function runReport(state: any, action: RunReport) {
    const { runReport } = action;
    return { ...state, isReportRan: runReport }
}

function postReportRequest(state: any, action: PostReportRequest) {
    return { ...state, error: null, pending: true }
}

function postReportSuccess(state: any, action: PostReportSuccess) {
    const { dateRangeDates, response } = action;
    return { ...state, error: null, pending: false, data: { ...response, ...dateRangeDates } }
}

function postReportFailure(state: any, action: PostReportFailure) {
    return { ...state, pending: false, error: action.error }
}

export default (state: any = initialState, action: PostReportsType): any => {
    return (strategies[action.type] || strategies.default)(state, action as never)
}