// vendors
import React, { useEffect, useState, useMemo } from "react";
import { useIonRouter } from "@ionic/react";
import { useStep } from "react-hooks-helper";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// components
import { ZipCode } from "../../components/OnboardingProcess/ZipCode";
import OnboardingContainer from "./OnboardingContainer";
import MarketQuestionnaires from "../../components/OnboardingProcess/MarketQuestionnaires";
import SiteTheme from "../../components/OnboardingProcess/SiteTheme";
import LocationProfile from "../../components/OnboardingProcess/LocationInfo";
import SelectLocationToOnboard from "../../components/OnboardingProcess/SelectLocationToOnboard";
import OnboardingNotAllowed from '../../components/OnboardingProcess/OnboardingNotAllowed';
import SpinningLoader from "../../components/common/loader/SpinningLoader";
//constant
import { onBoardingLeftSection, onBoardingRightSection, onboardingStepIds } from "../../constants/constant";
import { Routes } from "../../constants/Routes";
// actions
import {
  fetchProfileRequest,
  postProfile,
  patchProfile,
  fetchProfilesRequestWithSysUserId
} from '../../_redux/actions/profileActions';
import { patchSystemUsers } from '../../_redux/actions/systemUsersActions';
// reducers
import { RootState } from '../../_redux/reducers/rootReducer';
//utils
import { onboardingValidations, getNeedsOnboardingProfiles } from '../../utils';
import { isOnboardingAccessPermitted as isOnboardingPermitted } from '../../utils/roleBasedAccess';

interface UseStepType {
  step: any;
  navigation: any;
  complete?: any;
};
const { questionaries } = onBoardingRightSection;

export const Onboarding: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const router = useIonRouter();
  const { zipCode, addLogo, locationProfile, marketDetails, selectLocationToOnboard } = onBoardingLeftSection;
  const profile = useSelector((state: RootState) => { return state.profile }, shallowEqual);
  const systemUsers = useSelector((state: RootState) => { return state.systemUsers }, shallowEqual);
  const { locationLastViewedId, systemUserId, roles, pending: sysUserPending } = systemUsers;
  const { allProfiles, currentProfile } = profile;
  const [toBeOnboardProfiles, setToBeOnboardProfiles] = useState<Array<any>>([]);
  const [onboardCurrentProfile, setOnboardCurrentProfile] = useState({ ...currentProfile });
  const [formErrors, setFormErrors] = useState({});
  const [logoError, setLogoError] = useState({ isValid: true, errorMessage: "" });
  const { doLogout } = props;
  const {
    errors,
    pending,
    zip,
    marketName,
    locationId,
    marketProfileId,
    marketSizeInSqFt,
    isFullServiceRestaurant,
    isQuickServiceRestaurant,
    isRoomServiceAvailable,
    doesBarOrRestaurantOfferAlcohol,
    isCoffeeShopBar,
    hasPreferredBeverageCompany,
    marketTheme,
    onboardingStep,
  } = onboardCurrentProfile;

  const areAnyLocationsOnboarded = useMemo(() => {
    const { profiles } = allProfiles;
    setToBeOnboardProfiles(getNeedsOnboardingProfiles(profiles || []))
    return profiles ? profiles.some((profile: any) => {
      const { onboardingStep } = profile;
      return onboardingStep === -1;
    }) : [];
  }, [allProfiles]);

  const getDetailsOfProfileToOnboard = (locationId: string) => {
    dispatch(fetchProfileRequest(locationId));
  };

  const onboardingSteps = [
    {
      id: onboardingStepIds.selectLocationToOnboard,
      title: selectLocationToOnboard.title,
      Component: SelectLocationToOnboard,
      isSkip: false,
      rightSectionProps: {
        selected: locationId,
        setOnboarding: setOnboardCurrentProfile,
        onChangeHandler: getDetailsOfProfileToOnboard,
        toBeOnboardProfiles,
        formErrors,
      }
    },
    {
      id: onboardingStepIds.locationProfile,
      onboardingCounter: 2,
      title: locationProfile.title,
      Component: LocationProfile,
      isSkip: false,
      rightSectionProps: {
        marketName,
        setOnboarding: setOnboardCurrentProfile
      }
    },
    {
      id: onboardingStepIds.addLogo,
      onboardingCounter: 3,
      title: addLogo.title,
      Component: SiteTheme,
      isSkip: true,
      rightSectionProps: {
        setOnboarding: setOnboardCurrentProfile,
        marketTheme,
        logoError,
        setLogoError
      }
    },
    {
      id: onboardingStepIds.marketDetails,
      onboardingCounter: 4,
      title: marketDetails.title,
      secondaryTitle: marketDetails.secondaryTitle,
      Component: MarketQuestionnaires,
      isSkip: true,
      rightSectionProps: {
        setOnboarding: setOnboardCurrentProfile,
        questionaries,
        isFullServiceRestaurant,
        isQuickServiceRestaurant,
        isRoomServiceAvailable,
        doesBarOrRestaurantOfferAlcohol,
        isCoffeeShopBar,
        hasPreferredBeverageCompany,
        marketSizeInSqFt,
      }
    },
    {
      id: onboardingStepIds.zipCode,
      onboardingCounter: -1,
      title: zipCode.title,
      Component: ZipCode,
      isSkip: true,
      rightSectionProps: {
        zip,
        setOnboarding: setOnboardCurrentProfile,
      },
    },
  ];
  const donotShowLocatinDropDown = ((!areAnyLocationsOnboarded && toBeOnboardProfiles.length === 1) || toBeOnboardProfiles.length === 1);
  const initialStep = (onboardingStep === 0 || onboardingStep === -1) ? 0 : onboardingStep;
  const { step, navigation }: UseStepType = useStep({ initialStep, steps: onboardingSteps });
  const { id: stepId, onboardingCounter, title: stepTitle, secondaryTitle: stepSecondaryTitle, rightSectionProps, Component, isSkip } = step;

  const isOnboardingAccessPermitted = useMemo(() => {
    return isOnboardingPermitted(sysUserPending, roles)
  }, [roles, sysUserPending]);
  const submitHandler = () => {
    const next = navigation ? navigation.next : null;
    if (stepId === onboardingStepIds.selectLocationToOnboard) {
      next && next();
    } else {
      const payload = { ...onboardCurrentProfile, onboardingStep: onboardingCounter };
      delete payload['pending'];
      delete payload['errors'];
      const formValidation = onboardingValidations(stepId, onboardCurrentProfile);
      const { isValid, errorMessage } = formValidation;
      const { isValid: isLogoValid } = logoError
      setFormErrors({ isValid, errorMessage });
      if (isValid && isLogoValid) {
        marketProfileId ? dispatch(patchProfile(locationId, payload)) : dispatch(postProfile(locationId, payload, 2));
        if (onboardingCounter === -1) {
          //setting newly onboarded locationId as locationLastViewedId
          dispatch(patchSystemUsers(systemUserId, { locationLastViewedId: locationId }));
          dispatch(fetchProfilesRequestWithSysUserId(systemUserId))
          router.push(Routes.dashboard.productsUrl);
        } else {
          next && next();
        }
      }
    }
  }

  const backButtonHandler = () => {
    if (stepId === onboardingStepIds.selectLocationToOnboard && areAnyLocationsOnboarded) {
      dispatch(fetchProfileRequest(locationLastViewedId));
      router.push(Routes.dashboard.productsUrl);
    } else if (stepId === onboardingStepIds.locationProfile && donotShowLocatinDropDown) {
      dispatch(fetchProfileRequest(locationLastViewedId));
      router.push(Routes.dashboard.productsUrl);
    } else {
      const previous = navigation ? navigation.previous : null;
      previous && previous()
    }
  };

  const showBackButton = useMemo(() => {
    if (!areAnyLocationsOnboarded && stepId === onboardingStepIds.locationProfile && donotShowLocatinDropDown) {
      return false
    } else if (areAnyLocationsOnboarded || stepId !== onboardingStepIds.selectLocationToOnboard) {
      return true
    }
    return false;
  }, [stepId]);

  const renderOnboarding = () => {
    if (isOnboardingAccessPermitted) {
      return (
        <OnboardingContainer
          title={stepTitle}
          secondaryTitle={stepSecondaryTitle || ''}
          errors={errors}
          isSkip={isSkip}
          isBackButton={showBackButton}
          submitHandler={submitHandler}
          backButtonHandler={backButtonHandler}
          RightSectionComponent={() => {
            return <Component {...rightSectionProps} key={stepId} />
          }}
          requestPending={pending}
        />
      )
    } else {
      return <OnboardingNotAllowed doLogout={doLogout} />
    }
  }
  useEffect(() => {
    const { onboardingStep } = currentProfile;
    onboardingStep === -1 && router.push(Routes.dashboard.productsUrl);
    setOnboardCurrentProfile({ ...currentProfile });
  }, [currentProfile]);

  useEffect(() => {
    const { go } = navigation;
    donotShowLocatinDropDown && onboardingStep === 0 && go(1);
  }, [donotShowLocatinDropDown, onboardingStep])

  return renderOnboarding();
};

export default withAuthenticationRequired(
  Onboarding,
  { onRedirecting: () => <SpinningLoader /> }
);