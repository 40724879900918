//vendors
import { useCallback } from "react";
import XLSX from "sheetjs-style";
import { TO_EXCEL } from "../../constants/constant";

const ToExcel = (props: any) => {
    const { records, reportHeaders = [], reportName, origin, skipHeader, sheetName } = props;

    const exportFile = useCallback(() => {
        const updatesRecords = records.map((record: any) => {
            delete record['$hashCode'];
            return record;
        });

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(ws, reportHeaders);
        ws['A1'].s = {
            font: {
                bold: true,
            }
        };

        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.utils.sheet_add_json(ws, updatesRecords, { origin: origin, skipHeader });
        XLSX.writeFile(wb, `${reportName}.xlsx`);
    }, [records]);

    return (<div onClick={exportFile}>{TO_EXCEL}</div>);
}
export default ToExcel;