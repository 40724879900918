import { CARD_NUMBER_REQUIRED, CARD_NUMBER_INVALID, SERV_NOT_ALLOWED } from "./constants";

export const errorMessageMapping = (error: string) => {
    switch (error) {
        case "Card number is undefined.":
            return CARD_NUMBER_REQUIRED;
        case "Card number is invalid":
        case "CID FORMAT ERROR":
        case "Unexpected Gateway Response":
            return CARD_NUMBER_INVALID;
        case "SERV NOT ALLOWED":
            return SERV_NOT_ALLOWED;
        default:
            return error;
    }
} 