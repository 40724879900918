import {
    PostGenerateCart,
    PostGenerateCartSuccess,
    PostGenerateCartFailure,
    PostGenerateCartType,
    FetchSearchProduct,
    FetchSearchProductSuccess,
    FetchSearchProductFailure,
    FetchSearchProductType,
    PostCheckoutCart,
    PostCheckoutCartSuccess,
    PostCheckoutCartFailure,
    PostCheckoutCartType,
    ResetPosType,
    ResetPos,
} from "../actions/posActions";
import { GenerateCart, PosSearchProducts } from "../../models/pos";

export type posTypes = PostGenerateCartType | FetchSearchProductType | PostCheckoutCartType | ResetPosType;

export interface IPOS {
    pending: boolean;
    error: Record<string, any> | null;
    postGenerateCart: GenerateCart;
    posSearchedProducts: PosSearchProducts[];
    postCheckoutCart:GenerateCart;
    isCheckoutSuccess: boolean;
}
const initialState = {
    pending: false,
    error: null,
    postGenerateCart: {} as GenerateCart,
    posSearchedProducts: [] as PosSearchProducts[],
    postCheckoutCart: {} as GenerateCart,
    isCheckoutSuccess: false,
};

const strategies = {
    POST_GENERATE_CART_REQUEST: postGenerateCart,
    POST_GENERATE_CART_SUCCESS: postGenerateCartSuccess,
    POST_GENERATE_CART_FAILURE: postGenerateCartFailure,
    FETCH_SEARCH_PRODUCT_REQUEST: postGenerateCart,
    FETCH_SEARCH_PRODUCT_SUCCESS: fetchSearchProductsSuccess,
    FETCH_SEARCH_PRODUCT_FAILURE: postGenerateCartFailure,
    POST_CHECKOUT_CART_REQUEST: postGenerateCart,
    POST_CHECKOUT_CART_SUCCESS: postCheckoutCartSuccess,
    POST_CHECKOUT_CART_FAILURE: postGenerateCartFailure,
    RESET_POS: resetPos,
    default: (state: IPOS) => state,
};

function postGenerateCartSuccess(state: IPOS, action: PostGenerateCartSuccess) {

    let removeCount = 0;
    let addCount = 0;
    if (
        state.postCheckoutCart &&
        Object.keys(state.postCheckoutCart).length > 0 &&
        state.postCheckoutCart.salesItems
    ) {
        state.postGenerateCart = {} as GenerateCart;
        state.postCheckoutCart = {} as GenerateCart;
    }
    if (
        state.postGenerateCart &&
        Object.keys(state.postGenerateCart).length > 0 &&
        state.postGenerateCart.salesItems
    ) {
        state.postGenerateCart.salesItems.forEach((preObj) => {
            const found = action.response.salesItems.find(
                (newObj) => JSON.stringify(newObj) == JSON.stringify(preObj)
            );
            if (!found) {
                removeCount++;
            }
        });
        action.response.salesItems.forEach((newObj) => {
            const found = state.postGenerateCart.salesItems.find(
                (prevObj) => JSON.stringify(prevObj) == JSON.stringify(newObj)
            );
            if (!found) {
                addCount++;
            }
        });
    } else {
        addCount++;
    }
    return {
        ...state,
        error: null,
        pending: false,
        isCheckoutSuccess:false,
        postGenerateCart: {
            ...action.response,
            isProductAddOrRemove: addCount
                ? "Added"
                : removeCount
                ? "Removed"
                : "",
        },
    };
}

function postGenerateCart(
    state: IPOS,
    _action: PostGenerateCart | FetchSearchProduct | PostCheckoutCart
) {
    return {
        ...state,
        error: null,
        pending: true,
        isCheckoutSuccess:false,
        postGenerateCart: {...state.postGenerateCart, isProductAddOrRemove:''}
    };
}
function postGenerateCartFailure(
    state: IPOS,
    action: PostGenerateCartFailure | FetchSearchProductFailure | PostCheckoutCartFailure
) {
    return {
        ...state,
        error: action.error,
        isCheckoutSuccess:false,
        pending: false,
    };
}

function fetchSearchProductsSuccess(
    state: IPOS,
    action: FetchSearchProductSuccess
) {
    return {
        ...state,
        error: null,
        pending: false,
        posSearchedProducts: action.response,
        isCheckoutSuccess:false
    };
}

function postCheckoutCartSuccess(state: IPOS, action: PostCheckoutCartSuccess) {
    return {
        ...state,
        error: null,
        pending: false,
        postCheckoutCart: action.response,
        isCheckoutSuccess:true
    };
}

function resetPos(state: IPOS, action: ResetPos) {
    return Object.assign({}, state, {
        ...state,
        postGenerateCart:{},
        postCheckoutCart: {},
        isCheckoutSuccess:false,
        error: null,
    });
}
export default (state: IPOS = initialState, action: posTypes): IPOS => {
    return (strategies[action.type] || strategies.default)(
        state,
        action as never
    );
};
