//post api
export type POST_MARKET_ORDER_HEADER_REQUEST =
    typeof POST_MARKET_ORDER_HEADER_REQUEST;
export type POST_MARKET_ORDER_HEADER_SUCCESS =
    typeof POST_MARKET_ORDER_HEADER_SUCCESS;
export type POST_MARKET_ORDER_HEADER_FAILURE =
    typeof POST_MARKET_ORDER_HEADER_FAILURE;

export const POST_MARKET_ORDER_HEADER_REQUEST =
    "POST_MARKET_ORDER_HEADER_REQUEST";
export const POST_MARKET_ORDER_HEADER_SUCCESS =
    "POST_MARKET_ORDER_HEADER_SUCCESS";
export const POST_MARKET_ORDER_HEADER_FAILURE =
    "POST_MARKET_ORDER_HEADER_FAILURE";

//fetch api
export type FETCH_MARKET_ORDER_HEADER_REQUEST =
    typeof FETCH_MARKET_ORDER_HEADER_REQUEST;
export type FETCH_MARKET_ORDER_HEADER_SUCCESS =
    typeof FETCH_MARKET_ORDER_HEADER_SUCCESS;
export type FETCH_MARKET_ORDER_HEADER_FAILURE =
    typeof FETCH_MARKET_ORDER_HEADER_FAILURE;

export const FETCH_MARKET_ORDER_HEADER_REQUEST =
    "FETCH_MARKET_ORDER_HEADER_REQUEST";
export const FETCH_MARKET_ORDER_HEADER_SUCCESS =
    "FETCH_MARKET_ORDER_HEADER_SUCCESS";
export const FETCH_MARKET_ORDER_HEADER_FAILURE =
    "FETCH_MARKET_ORDER_HEADER_FAILURE";

//fetch MARKET ORDER PRODUCT api
export type FETCH_MARKET_ORDER_PRODUCT_REQUEST =
    typeof FETCH_MARKET_ORDER_PRODUCT_REQUEST;
export type FETCH_MARKET_ORDER_PRODUCT_SUCCESS =
    typeof FETCH_MARKET_ORDER_PRODUCT_SUCCESS;
export type FETCH_MARKET_ORDER_PRODUCT_FAILURE =
    typeof FETCH_MARKET_ORDER_PRODUCT_FAILURE;

export const FETCH_MARKET_ORDER_PRODUCT_REQUEST =
    "FETCH_MARKET_ORDER_PRODUCT_REQUEST";
export const FETCH_MARKET_ORDER_PRODUCT_SUCCESS =
    "FETCH_MARKET_ORDER_PRODUCT_SUCCESS";
export const FETCH_MARKET_ORDER_PRODUCT_FAILURE =
    "FETCH_MARKET_ORDER_PRODUCT_FAILURE";

//fetch MARKET ORDER  api
export type FETCH_MARKET_ORDER_REQUEST = typeof FETCH_MARKET_ORDER_REQUEST;
export type FETCH_MARKET_ORDER_SUCCESS = typeof FETCH_MARKET_ORDER_SUCCESS;
export type FETCH_MARKET_ORDER_FAILURE = typeof FETCH_MARKET_ORDER_FAILURE;

export const FETCH_MARKET_ORDER_REQUEST = "FETCH_MARKET_ORDER_REQUEST";
export const FETCH_MARKET_ORDER_SUCCESS = "FETCH_MARKET_ORDER_SUCCESS";
export const FETCH_MARKET_ORDER_FAILURE = "FETCH_MARKET_ORDER_FAILURE";

/**
 * ReSet fetch market order header
 */
export type RESET_MARKET_ORDER = typeof RESET_MARKET_ORDER;
export const RESET_MARKET_ORDER = "RESET_MARKET_ORDER";

/**
 * Delete Market Order Product
 */
export type DELETE_MARKET_ORDER_PRODUCT = typeof DELETE_MARKET_ORDER_PRODUCT;
export type DELETE_MARKET_ORDER_PRODUCT_SUCCESS =
    typeof DELETE_MARKET_ORDER_PRODUCT_SUCCESS;
export type DELETE_MARKET_ORDER_PRODUCT_FAILURE =
    typeof DELETE_MARKET_ORDER_PRODUCT_FAILURE;
export const DELETE_MARKET_ORDER_PRODUCT = "DELETE_MARKET_ORDER_PRODUCT";
export const DELETE_MARKET_ORDER_PRODUCT_SUCCESS =
    "DELETE_MARKET_ORDER_PRODUCT_SUCCESS";
export const DELETE_MARKET_ORDER_PRODUCT_FAILURE =
    "DELETE_MARKET_ORDER_PRODUCT_FAILURE";

/**
 * market order product save
 */
export type SAVE_MARKET_ORDER_PRODUCT_REQUEST =
    typeof SAVE_MARKET_ORDER_PRODUCT_REQUEST;
export type SAVE_MARKET_ORDER_PRODUCT_SUCCESS =
    typeof SAVE_MARKET_ORDER_PRODUCT_SUCCESS;
export type SAVE_MARKET_ORDER_PRODUCT_FAILURE =
    typeof SAVE_MARKET_ORDER_PRODUCT_FAILURE;

export const SAVE_MARKET_ORDER_PRODUCT_REQUEST =
    "SAVE_MARKET_ORDER_PRODUCT_REQUEST";
export const SAVE_MARKET_ORDER_PRODUCT_SUCCESS =
    "SAVE_MARKET_ORDER_PRODUCT_SUCCESS";
export const SAVE_MARKET_ORDER_PRODUCT_FAILURE =
    "SAVE_MARKET_ORDER_PRODUCT_FAILURE";

/**
 * Patch Market Order Product
 */
export type PATCH_MARKET_ORDER_PRODUCT = typeof PATCH_MARKET_ORDER_PRODUCT;
export type PATCH_MARKET_ORDER_PRODUCT_SUCCESS =
    typeof PATCH_MARKET_ORDER_PRODUCT_SUCCESS;
export type PATCH_MARKET_ORDER_PRODUCT_FAILURE =
    typeof PATCH_MARKET_ORDER_PRODUCT_FAILURE;
export const PATCH_MARKET_ORDER_PRODUCT = "PATCH_MARKET_ORDER_PRODUCT";
export const PATCH_MARKET_ORDER_PRODUCT_SUCCESS =
    "PATCH_MARKET_ORDER_PRODUCT_SUCCESS";
export const PATCH_MARKET_ORDER_PRODUCT_FAILURE =
    "PATCH_MARKET_ORDER_PRODUCT_FAILURE";

//POST market order
export type POST_MARKET_ORDER_REQUEST = typeof POST_MARKET_ORDER_REQUEST;
export type POST_MARKET_ORDER_SUCCESS = typeof POST_MARKET_ORDER_SUCCESS;
export type POST_MARKET_ORDER_FAILURE = typeof POST_MARKET_ORDER_FAILURE;

export const POST_MARKET_ORDER_REQUEST = "POST_MARKET_ORDER_REQUEST";
export const POST_MARKET_ORDER_SUCCESS = "POST_MARKET_ORDER_SUCCESS";
export const POST_MARKET_ORDER_FAILURE = "POST_MARKET_ORDER_FAILURE";


//post Auto Order header api
export type POST_AUTO_ORDER_HEADER_REQUEST =
    typeof POST_AUTO_ORDER_HEADER_REQUEST;
export type POST_AUTO_ORDER_HEADER_SUCCESS =
    typeof POST_AUTO_ORDER_HEADER_SUCCESS;
export type POST_AUTO_ORDER_HEADER_FAILURE =
    typeof POST_AUTO_ORDER_HEADER_FAILURE;

export const POST_AUTO_ORDER_HEADER_REQUEST =
    "POST_AUTO_ORDER_HEADER_REQUEST";
export const POST_AUTO_ORDER_HEADER_SUCCESS =
    "POST_AUTO_ORDER_HEADER_SUCCESS";
export const POST_AUTO_ORDER_HEADER_FAILURE =
    "POST_AUTO_ORDER_HEADER_FAILURE";