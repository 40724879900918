// vendors
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    IonButton,
    IonCol,
    IonRow,
    IonChip,
    isPlatform,
    IonGrid,
    IonNote,
    IonInput,
    IonIcon,
} from "@ionic/react";
import { closeOutline, removeOutline, addOutline } from 'ionicons/icons';

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../_redux/reducers/rootReducer";
import { PurchaseAsComp } from "../PurchaseAsComp/PurchaseAsComp";
//constants
import {
    Hotel365Portal,
    POS_BOTTLE_DEPOSIT,
    POS_CHECKOUT_HEADER,
    POS_CHECKOUT_SUB_TOTAL,
    POS_NO_PRODUCT_ADD_TO_CART,
    POS_PRINT_RECEIPT_DONE_BUTTON,
    POS_RECEIPT_HEADER,
    POS_TAX,
    GLOBAL_FOOTER_TOTAL
} from "../../constants/constant";
import { Room } from "../Room/Room";

//actions
import {
    postGenerateCart as postGenerateCartAction,
    resetPOS,
    postCheckoutCart as postCheckoutCartActions,
} from "../../_redux/actions/posActions";
import { SalesItems } from "../../models/pos";
import { addZeroes, getCurrentUserTime, getLocalizedNumericValues } from "../../utils";
import { PrintReceiptButton } from "../PrintReceipt/PrintReceiptButton";

export const Checkout: React.FC<any> = (props) => {
    const {
        sidenavExpanded,
        setSidenavExpanded,
        setProductList,
        setSearchText,
        currency
    } = props;
    const dispatch = useDispatch();
    const isMobileView = isPlatform("mobile");
    const isDesktopView = isPlatform("desktop");
    const isTabletView = isPlatform("tablet");
    const [sideNavType, setSideNavType] = useState("checkout");
    const [openPurchaseCompModal, setOpenPurchaseCompModal] = useState(false);
    const [openRoomNumberModal, setOpenRoomNumberModal] = useState(false);
    const [qty, setQty] = useState(0);
    const [items, setItems] = useState([] as SalesItems[]);

    useEffect(() => {
        return () => {
            setSideNavType("checkout");
        };
    }, [sidenavExpanded]);

    const { postGenerateCart, pending, postCheckoutCart } = useSelector(
        (state: RootState) => state.pos,
        shallowEqual
    );

    const profile = useSelector((state: RootState) => {
        return state.profile;
    }, shallowEqual);
    const { locationId: locationID } = profile && profile.currentProfile;

    useEffect(() => {
        if (sideNavType === 'receipt' && !checkPostCheckoutCart() && postGenerateCart && Object.keys(postGenerateCart).length > 0) {
            setItems(items.splice(0, items.length));
        }
    }, [postGenerateCart])

    useEffect(() => {
        setTimeout(() => {
            if (items && items.length > 0) {
                items.forEach((item, index, array) => {
                    const ItemIndex = postGenerateCart.salesItems?.findIndex(
                        (b) => b.barcode === item.barcode
                    );
                    if (ItemIndex >= 0)
                        postGenerateCart.salesItems[ItemIndex].quantity =
                            item.quantity;
                });
            }
            setItems(postGenerateCart.salesItems);
        }, 300)


    }, [postGenerateCart, setItems]);

    useEffect(() => {
        if (checkPostCheckoutCart()) {
            closePurchaseComp();
            closeRoom();
            setSideNavType("receipt");
        } else {
            setSideNavType("checkout");
        }
    }, [postCheckoutCart]);

    const openPurchaseComp = () => {
        setOpenPurchaseCompModal(true);
    };

    const closePurchaseComp = () => {
        setOpenPurchaseCompModal(false);
    };

    const checkPostCheckoutCart = useCallback(() => {
        return postCheckoutCart && Object.keys(postCheckoutCart).length > 0;
    }, [postCheckoutCart]);

    const save = (type: string, value?: string, lastname?: string) => {
        const amount =
            Number(getSubtotal().toFixed(2)) +
            Number(getTax().toFixed(2)) +
            Number(getDeposit().toFixed(2));
        prepareRequestObject.payments = [];
        if (type === "purchaseAsComp") {
            prepareRequestObject.payments.push({
                amount: Number(amount.toFixed(2)),
                type: "Comp",
                reason: value,
            } as never);
        } else if (type === "room") {
            prepareRequestObject.payments.push({
                amount: Number(amount.toFixed(2)),
                type: "Room",
                roomNumber: value,
                lastName: lastname,
            } as never);
        } else {
            prepareRequestObject.payments.push({
                amount: Number(amount.toFixed(2)),
                type: "ThirdPartyCash",
            } as never);
        }
        dispatch(postCheckoutCartActions(prepareRequestObject));
    };

    const openRoom = () => {
        setOpenRoomNumberModal(true);
    };
    const closeRoom = () => {
        setOpenRoomNumberModal(false);
    };
    const cartItems = useMemo(
        () =>
            items && items.length > 0
                ? items.map((item) => ({
                    barcode: item.barcode,
                    qty: item.quantity,
                }))
                : [],
        [items]
    );

    let prepareRequestObject = useMemo(() => {
        const mappedValue = cartItems.flatMap((el) =>
            new Array(el.qty).fill(null).map((e) => ({ barcode: el.barcode }))
        );
        return {
            cartItems: mappedValue,
            deviceName: "Hotel365",
            locationLookupId: locationID,
            payments: [],
            saleContext: "HotelPOS",
            serviceType: "none",
            transactionDate: getCurrentUserTime()
        };
    }, [cartItems, locationID]);

    const restrictAlphabets = (e: any) => {
        const code = e.nativeEvent.which || e.nativeEvent.keycode;
        if (code >= 48 && code <= 57) {
            const result =
                e.target.selectionStart === 0 &&
                    e.target.selectionEnd === e.target.value.length
                    ? e.key
                    : [
                        e.target.value.slice(0, e.target.selectionStart),
                        e.key,
                        e.target.value.slice(e.target.selectionStart),
                    ].join("");
        } else {
            e.preventDefault();
            return false;
        }
    };

    const receiptTypeBtns = () => {
        return (
            <IonRow className="mb-9">
                <IonCol sizeMd="6" className="ion-no-padding">
                    <PrintReceiptButton recieptItemDetails={items} cartDetails={postCheckoutCart} currency={currency}/>
                </IonCol>
                <IonCol sizeMd="6" className="ion-no-padding">
                    <IonButton
                        expand="full"
                        type="button"
                        className="btn-text-cases"
                        onClick={() => {
                            dispatch(resetPOS());
                            setSideNavType("checkout");
                            setSidenavExpanded(false);
                            setProductList([]);
                            setSearchText("");
                        }}
                    >
                        {POS_PRINT_RECEIPT_DONE_BUTTON}
                    </IonButton>
                </IonCol>
            </IonRow>
        );
    };

    const checkoutTypeBtns = () => {
        return (
            <IonRow className="mb-9">
                <IonCol sizeMd="4" className="ion-no-padding">
                    <IonButton
                        expand="full"
                        type="button"
                        className="btn-text-cases"
                        onClick={() => {
                            save("cash");
                        }}
                    >
                        {Hotel365Portal.typeCash}
                    </IonButton>
                </IonCol>
                <IonCol sizeMd="4" className="ion-no-padding">
                    <IonButton
                        expand="full"
                        type="button"
                        className="btn-text-cases"
                        onClick={openPurchaseComp}
                    >
                        {Hotel365Portal.typeComp}
                    </IonButton>
                </IonCol>
                <IonCol sizeMd="4" className="ion-no-padding">
                    <IonButton
                        expand="full"
                        type="button"
                        className="btn-text-cases"
                        onClick={openRoom}
                    >
                        {Hotel365Portal.typeRoom}
                    </IonButton>
                </IonCol>
            </IonRow>
        );
    };

    const updateQty = (
        type: string,
        i: number,
        qty: number,
        res: any,
        barcode: string
    ) => {
        var cartProducts = JSON.parse(JSON.stringify(items));
        //Find index of specific object using findIndex method.
        const objIndex = cartProducts.findIndex(
            (obj: SalesItems) => obj.barcode == barcode
        );

        if (type === "minus") {
            if (qty > 1)
                cartProducts[objIndex].quantity =
                    cartProducts[objIndex].quantity - 1;
            else return false;
        } else {
            cartProducts[objIndex].quantity =
                cartProducts[objIndex].quantity + 1;
        }
        setTimeout(() => setItems(cartProducts), 100);
    };

    const removeCartProduct = (removedProduct: Record<string, any>) => {
        const notRemovedProductsBarcodes = postGenerateCart?.salesItems
            ?.filter((item) => item.product !== removedProduct.product)
            .map(function (obj) {
                return obj.barcode;
            });
        const request = {
            // accountId: "29e65b294020083d63e0f577e7137c57",
            cartItems:
                notRemovedProductsBarcodes &&
                    notRemovedProductsBarcodes.length > 0
                    ? notRemovedProductsBarcodes.map((item) => ({
                        barcode: item,
                    }))
                    : [],
            locationId: locationID,
        };
        dispatch(postGenerateCartAction(request));
    };

    const getSubtotal = () => {
        if (items && items.length > 0) {
            return items.reduce((sum, currentItem) => {
                return sum + currentItem.price * currentItem.quantity;
            }, 0);
        }
        return 0;
    };

    const getTax = () => {
        if (items && items.length > 0) {
            return items.reduce((sum, currentItem) => {
                return sum + (currentItem.tax + currentItem.tax2 + currentItem.tax3 + currentItem.tax4) * currentItem.quantity;
            }, 0);
        }
        return 0;
    };

    const getDeposit = () => {
        if (items && items.length > 0) {
            return items.reduce((sum, currentItem) => {
                return sum + (currentItem.deposit || 0) * currentItem.quantity;
            }, 0);
        }
        return 0;
    };

    const cartProducts = (type: string) => {
        return (
            <>
                <React.Fragment>
                    {items &&
                        items?.length > 0 &&
                        items?.map((res: any, i: number) => (
                            <IonGrid key={res.name}>
                                <IonRow className="pos-checkout-items-row-border">
                                    {sideNavType === "checkout" ? (
                                        <IonCol className="pos-checkout-close-icon-col">
                                            <IonIcon icon={closeOutline} size="large"
                                                className="pos-checkout-close-icon"
                                                onClick={() => {
                                                    removeCartProduct(res);
                                                }}
                                            />
                                        </IonCol>
                                    ) : (
                                        <IonCol className="pos-receipt-qty-col">
                                            <span className="pos-receipt-qty-details">
                                                <b>{res.quantity}</b>
                                            </span>
                                        </IonCol>

                                    )
                                    }


                                    <IonCol className="pos-checkout-items-col">
                                        <IonRow >
                                            <div className={sideNavType === "checkout" && (!isMobileView && isTabletView && isDesktopView) ? "pos-checkout-item-div" : (isMobileView && !isTabletView && !isDesktopView) ? "pos-receipt-item-div-mobile" : "pos-receipt-item-div"} >
                                                <div className={(sideNavType === "checkout" && !isMobileView && !isTabletView && isDesktopView) ? "pos-checkout-item-div-desk" : ''}>
                                                    <b> {res.name} </b>
                                                </div>
                                            </div>
                                        </IonRow>
                                        <IonRow>
                                            <div className={sideNavType === "checkout" && (!isMobileView && isTabletView && isDesktopView) ? "pos-checkout-item-div" : (isMobileView && !isTabletView && !isDesktopView) ? "pos-receipt-item-div-mobile" : "pos-receipt-item-div"} >
                                                <div className={(sideNavType === "checkout" && !isMobileView && !isTabletView && isDesktopView) ? "pos-checkout-item-div-desk" : ''}>
                                                    @ {getLocalizedNumericValues(res.price, "currency", currency)}
                                                </div>
                                            </div>
                                        </IonRow>
                                    </IonCol>
                                    {sideNavType === "checkout" && (
                                        <IonCol className="pos-checkout-qty-col">
                                            <div className="pos-checkout-item-names-row">
                                                <IonCol className="pos-checkout-remove-icon" >  <IonIcon icon={removeOutline} color="primary"
                                                    onClick={() =>
                                                        updateQty(
                                                            "minus",
                                                            i,
                                                            res.quantity,
                                                            res,
                                                            res.barcode
                                                        )
                                                    }
                                                />  </IonCol>
                                                <IonCol className="pos-checkout-qty-input">
                                                    <IonInput
                                                        className="pos-product-qty"
                                                        name={`cart.${i}.quantity`}
                                                        id={`cart.${i}.quantity`}
                                                        value={res.quantity}
                                                        defaultValue={res.quantity}
                                                        onIonChange={(e) => {
                                                            setQty(
                                                                Number(e.target.value)
                                                            );
                                                        }}  
                                                        onKeyDown={(e) => {
                                                            restrictAlphabets(e);
                                                        }}
                                                        disabled
                                                    >{res.quantity}</IonInput>

                                                </IonCol>
                                                <IonCol className="pos-checkout-add-icon">  <IonIcon icon={addOutline} color="primary"
                                                    onClick={() =>
                                                        updateQty(
                                                            "plus",
                                                            i,
                                                            res.quantity,
                                                            res,
                                                            res.barcode
                                                        )
                                                    }
                                                /></IonCol>

                                            </div>
                                        </IonCol>
                                    )}

                                    <IonCol className="pos-checkout-price-col ion-padding-start ion-padding-end">
                                        <span>
                                            <b>
                                                {getLocalizedNumericValues(res.quantity * res.price, "currency", currency)}
                                            </b>
                                        </span>
                                    </IonCol>

                                </IonRow>
                            </IonGrid>
                        ))}

                </React.Fragment>
                <React.Fragment>
                    {postGenerateCart?.salesItems &&
                        postGenerateCart?.salesItems.length > 0 && (
                            <div className="pos-checkout-total-type-section">
                                <IonRow className="total-section">
                                    <div className="pos-checkout-line"></div>
                                    <IonCol
                                        sizeMd="6"
                                        className={isMobileView && !isTabletView && !isDesktopView ? "pos-total-pad-mobile" : "pos-total-pad"}
                                    >
                                        <IonNote className="pos-total clr-blk-text">
                                            {POS_CHECKOUT_SUB_TOTAL}
                                        </IonNote>
                                    </IonCol>
                                    <IonCol
                                        sizeMd="6"
                                        className={isMobileView && !isTabletView && !isDesktopView ? "pos-total-pad-mobile" : "pos-total-pad"}

                                    >
                                        <IonNote className="pos-total-value clr-blk">
                                            {getLocalizedNumericValues(getSubtotal(), "currency", currency)}
                                        </IonNote>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="total-section">
                                    <div className="pos-checkout-line"></div>
                                    <IonCol
                                        sizeMd="6"
                                        className={isMobileView && !isTabletView && !isDesktopView ? "pos-total-pad-mobile" : "pos-total-pad"}

                                    >
                                        <IonNote className="pos-total clr-blk-text">
                                            {POS_TAX}
                                        </IonNote>
                                    </IonCol>
                                    <IonCol
                                        sizeMd="6"
                                        className={isMobileView && !isTabletView && !isDesktopView ? "pos-total-pad-mobile" : "pos-total-pad"}

                                    >
                                        <IonNote className="pos-total-value clr-blk">
                                            {getLocalizedNumericValues(getTax(), "currency", currency)}
                                        </IonNote>
                                    </IonCol>
                                </IonRow>
                                <React.Fragment>
                                    {Number(getDeposit().toFixed(2)) != 0 && (
                                        <IonRow className="total-section">
                                            <div className="pos-checkout-line"></div>
                                            <IonCol
                                                sizeMd="6"
                                                className={isMobileView && !isTabletView && !isDesktopView ? "pos-total-pad-mobile" : "pos-total-pad"}

                                            >
                                                <IonNote className="pos-total clr-blk-text">
                                                    {POS_BOTTLE_DEPOSIT}
                                                </IonNote>
                                            </IonCol>
                                            <IonCol
                                                sizeMd="6"
                                                className={isMobileView && !isTabletView && !isDesktopView ? "pos-total-pad-mobile" : "pos-total-pad"}

                                            >
                                                <IonNote className="pos-total-value clr-blk">
                                                    {getLocalizedNumericValues(getDeposit(), "currency", currency)}
                                                </IonNote>
                                            </IonCol>
                                        </IonRow>)}
                                </React.Fragment>
                                <IonRow className="total-section">
                                    <div className="pos-checkout-line"></div>
                                    <IonCol
                                        sizeMd="6"
                                        className={isMobileView && !isTabletView && !isDesktopView ? "pos-total-pad-mobile" : "pos-total-pad"}

                                    >
                                        <IonNote className="pos-total clr-blk-total">
                                            <b>{GLOBAL_FOOTER_TOTAL}</b>
                                        </IonNote>
                                    </IonCol>
                                    <IonCol
                                        sizeMd="6"
                                        className={isMobileView && !isTabletView && !isDesktopView ? "pos-total-pad-mobile" : "pos-total-pad"}
                                    >
                                        <IonNote className="pos-total-value  clr-blk-total">
                                            <b>
                                                {getLocalizedNumericValues(getSubtotal() + getTax() + getDeposit(), "currency", currency)}
                                            </b>
                                        </IonNote>
                                    </IonCol>
                                </IonRow>

                            </div>
                        )}
                </React.Fragment>
            </>

        );
    };
    return (
        <>
            <IonCol
                size="9"
                sizeMd={"9"}
                sizeXs={"7"}
                sizeXl="auto"
                className={
                    sidenavExpanded
                        ? "padding-0 orders-left-panel"
                        : "hide-sidenav"
                }
            >
                <div className="pos-checkout-main-container pos-side-nav">
                    <IonRow
                        className={
                            !isDesktopView && !isMobileView
                                ? "order-row-property"
                                : ""
                        }
                    >
                       <IonCol>
                       <IonCol className="pos-checkout-header">
                            <h4 className="header-text orders-title">
                                {sideNavType === "checkout" ? (
                                    <b>{POS_CHECKOUT_HEADER}</b>
                                ) : (
                                    <b>{POS_RECEIPT_HEADER}</b>
                                )}
                            </h4>
                            {!isDesktopView && !isTabletView ? (
                                <IonChip
                                    color="primary"
                                    className={
                                        sideNavType === "checkout"
                                            ? "pos-checkout-ion-chip-sidenav"
                                            : "pos-checkout-ion-chip-sidenav-receipt"
                                    }
                                    onClick={() => {
                                        setSidenavExpanded(false);
                                    }}
                                >
                                    <span className="product-list-cancel-chip">
                                        &times;
                                    </span>
                                </IonChip>
                            ) : (
                                " "
                            )}
                        </IonCol>
                        <IonCol className="cart-products">
                            {postGenerateCart?.salesItems &&
                                postGenerateCart?.salesItems.length > 0 ? (
                                cartProducts(sideNavType)
                            ) : (
                                <span className="cart-prdt-name cart-no-products">
                                    {POS_NO_PRODUCT_ADD_TO_CART}
                                </span>
                            )}
                        </IonCol>
                       </IonCol>
                    </IonRow>
                    <React.Fragment>
                        {postGenerateCart?.salesItems &&
                            postGenerateCart?.salesItems.length > 0 && (
                                <div className="pos-total-type-section">
                                    {/* <IonGrid> */}
                                    {sideNavType === "checkout"
                                        ? checkoutTypeBtns()
                                        : receiptTypeBtns()}
                                    {/* </IonGrid> */}
                                </div>
                            )}
                    </React.Fragment>
                </div>
                <PurchaseAsComp
                    openModal={openPurchaseCompModal}
                    closeModal={closePurchaseComp}
                    save={(reason: string) => {
                        save("purchaseAsComp", reason);
                    }}
                />
                <Room
                    openModal={openRoomNumberModal}
                    closeModal={closeRoom}
                    save={(roomNo: string, lastname: string) => {
                        save("room", roomNo, lastname);
                    }}
                />
            </IonCol>
        </>
    );
};

export default Checkout;
