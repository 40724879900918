//vendros
import React from "react";
import {
    IonCol,
    IonRow,
    IonIcon,
    IonText,
    IonButton,
    IonItem
} from "@ionic/react";
import { chevronBackCircleOutline, chevronForwardCircleOutline } from 'ionicons/icons';
//constants
import { BACK_TO_REPORT_LIST } from "../../constants/constant";

const ReportHeaderBarForTablet: React.FC<any> = ({ setShowSidePanel, showSidePanel, selectedReport, backToReportsList }) => {
    return (
        <>{showSidePanel ?
            <>
                <IonRow className='text-align-center ion-no-padding'>
                    <IonCol sizeMd='12'>
                        <IonItem lines="none">
                            <IonIcon
                                slot='start'
                                className="report-side-nav-icon click-cursor"
                                onClick={() => setShowSidePanel((prevState: any) => !prevState)}
                                icon={showSidePanel ? chevronBackCircleOutline : chevronForwardCircleOutline} />
                            <IonText className={"tablet-reports-header-text"}>{selectedReport.name || 'Reports'}</IonText>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-no-padding">
                    <IonCol size='11'>
                        {selectedReport.name &&
                            <IonButton fill="clear" onClick={backToReportsList}>
                                {BACK_TO_REPORT_LIST}
                            </IonButton>}
                    </IonCol>
                </IonRow>
            </>
            : !showSidePanel ?
                <IonItem lines="none" className="ion-no-padding">
                    <IonIcon
                        className="side-nav-icon click-cursor"
                        onClick={() => setShowSidePanel((prevState: any) => !prevState)}
                        icon={showSidePanel ? chevronBackCircleOutline : chevronForwardCircleOutline}
                    />
                    <IonText className={"reports-header-text"}>{selectedReport.name || 'Reports'}</IonText>
                    {selectedReport.name &&
                        <IonButton fill="clear" className={"back-to-reports-btn"} onClick={backToReportsList}>
                            {BACK_TO_REPORT_LIST}
                        </IonButton>
                    }
                </IonItem> : ''
        }
        </>
    )
}
export default ReportHeaderBarForTablet;