import { EmailUpdate } from './../../models/EmailUpdate';
import * as EMAILUPDATE from '../ActionTypes/emailUpdateTypes';
export interface PostEmailUpdate {
    type: EMAILUPDATE.POST_EMAIL_UPDATE_REQUEST;
    systemUserId: string;
    data: Record<string, any>;
}
export interface PostEmailUpdateSuccess {
    type: EMAILUPDATE.POST_EMAIL_UPDATE_SUCCESS;
    response: EmailUpdate;
}

export interface PostEmailUpdateFailure {
    type: EMAILUPDATE.POST_EMAIL_UPDATE_FAILURE;
    error: Record<string, any> | null;
}

export interface EmailUpdateFlow {
    type: EMAILUPDATE.EMAIL_UPDATE_FLOW;
    systemUserId: string;
    data: Record<string, any>;
}

export type PostEmailUpdateType =
    | PostEmailUpdate
    | PostEmailUpdateSuccess
    | PostEmailUpdateFailure;



export function PostEmailUpdate(systemUserId: string, data: Record<string, any>): PostEmailUpdate {
    return {
        type: EMAILUPDATE.POST_EMAIL_UPDATE_REQUEST,
        systemUserId,
        data
    };
}

export function PostEmailUpdateSuccess(response: EmailUpdate): PostEmailUpdateSuccess {
    return {
        type: EMAILUPDATE.POST_EMAIL_UPDATE_SUCCESS,
        response,
    };
}
export function PostEmailUpdateFailure(error: Record<string, any>): PostEmailUpdateFailure {
    return {
        type: EMAILUPDATE.POST_EMAIL_UPDATE_FAILURE,
        error: error,
    };
}


export function emailUpdateFlow(systemUserId: string, data: Record<string, any>): EmailUpdateFlow {
    return {
        type: EMAILUPDATE.EMAIL_UPDATE_FLOW,
        systemUserId,
        data
    }
}
