// vendors
import React from "react";
import { IonButton, IonCol, IonRow, isPlatform } from "@ionic/react";
//constants
import { FOOTER_SAVE_BUTTON } from "../../constants/constant";
import { ConsumerFooterProps } from "./ConsumerFooter";
import { paymentConstants } from "../Payment/constants";

const Footer: React.FC<ConsumerFooterProps> = ({
    onCancel,
    onClickSave,
    isDisabled = false
}) => {
    const isTablet = isPlatform('tablet');
    return (
        <IonCol size={isTablet ? "11.5" : "11"}>
            <IonRow>
                <IonCol>
                    <IonButton
                        onClick={onClickSave}
                        disabled={isDisabled}
                        className="btn-full-width"
                    >{FOOTER_SAVE_BUTTON}</IonButton>
                    <IonButton
                        className="btn-full-width-cancel"
                        onClick={onCancel}
                    >{paymentConstants.CONFIRM_CANCEL_BUTTON}
                    </IonButton>
                </IonCol>
            </IonRow>
        </IonCol>
    );
}
export default Footer;