import PaymentProps from "../constants";

const Discover: React.FC<PaymentProps> = (props) => {
  return (
    <svg
      width={(32 / 21) * props.size}
      height={props.size}
      viewBox="0 0 32 21"
    >
      <path
        d="M26.58,21L2.42,21A2.4,2.4 0,0 1,0 18.62L0,4.38A2.4,2.4 0,0 1,2.42 2h24.16A2.4,2.4 0,0 1,29 4.38v14.25A2.4,2.4 0,0 1,26.58 21z"
        fill="#EBF1F8"
      />
      <path
        d="M15.24,21h11.34A2.4,2.4 0,0 0,29 18.62v-2.85A46.81,46.81 0,0 1,15.24 21z"
        fill="#F27712"
      />
      <path
        d="M28,12.9h-1.03l-1.16,-1.53h-0.11v1.53h-0.84L24.86,9.1h1.24c0.97,0 1.53,0.4 1.53,1.12 0,0.59 -0.35,0.97 -0.98,1.09L28,12.9zM26.76,10.25c0,-0.37 -0.28,-0.56 -0.8,-0.56h-0.26v1.15h0.24c0.54,0 0.82,-0.2 0.82,-0.59zM21.92,9.1h2.38v0.64h-1.54v0.85h1.48v0.65h-1.48v1.03h1.54v0.64h-2.38L21.92,9.1zM19.22,13L17.4,9.09h0.92l1.15,2.56 1.16,-2.56h0.9L19.69,13h-0.45zM11.61,12.99c-1.28,0 -2.28,-0.87 -2.28,-2 0,-1.1 1.02,-1.99 2.3,-1.99 0.36,0 0.66,0.07 1.03,0.23v0.88a1.5,1.5 0,0 0,-1.05 -0.43c-0.8,0 -1.41,0.58 -1.41,1.31 0,0.77 0.6,1.32 1.45,1.32 0.38,0 0.67,-0.12 1.01,-0.42v0.88c-0.38,0.16 -0.7,0.22 -1.05,0.22zM9.07,11.74c0,0.74 -0.61,1.25 -1.49,1.25 -0.64,0 -1.1,-0.22 -1.49,-0.72l0.55,-0.47c0.19,0.34 0.51,0.51 0.91,0.51 0.38,0 0.65,-0.23 0.65,-0.53 0,-0.17 -0.08,-0.3 -0.25,-0.4a3.48,3.48 0,0 0,-0.58 -0.22c-0.79,-0.25 -1.06,-0.52 -1.06,-1.05 0,-0.62 0.58,-1.09 1.34,-1.09 0.48,0 0.91,0.15 1.27,0.43l-0.44,0.5a0.92,0.92 0,0 0,-0.68 -0.3c-0.36,0 -0.62,0.18 -0.62,0.42 0,0.2 0.15,0.31 0.65,0.48 0.96,0.3 1.24,0.58 1.24,1.2zM4.94,9.1h0.84v3.81h-0.84L4.94,9.1zM2.24,12.91L1,12.91L1,9.1h1.24c1.36,0 2.3,0.78 2.3,1.9 0,0.57 -0.28,1.11 -0.77,1.47 -0.42,0.3 -0.89,0.44 -1.54,0.44zM3.22,10.05c-0.28,-0.22 -0.6,-0.3 -1.15,-0.3h-0.23v2.52h0.23c0.54,0 0.88,-0.1 1.15,-0.3 0.29,-0.24 0.46,-0.6 0.46,-0.97s-0.17,-0.72 -0.46,-0.95z"
        fill="#000000"
      />
      <path
        d="M15,9c-1.1,0 -2,0.88 -2,1.97 0,1.16 0.86,2.03 2,2.03 1.12,0 2,-0.88 2,-2s-0.87,-2 -2,-2z"
        fill="#F27712"
      />
    </svg>
  );
}

export default Discover;