import * as REPORTS from '../ActionTypes/reportsTypes';

export interface PostReportRequest {
    type: REPORTS.POST_REPORT_REQUEST;
    reportType: string,
    filterObject: Record<string, any>;
}
export interface PostReportSuccess {
    type: REPORTS.POST_REPORT_SUCCESS;
    response: Record<string, any>;
    dateRangeDates: Record<string, any>;
}
export interface PostReportFailure {
    type: REPORTS.POST_REPORT_FAILURE;
    error: Record<string, any>;
}
export interface RunReport {
    type: REPORTS.RUN_REPORT,
    runReport: boolean;
}

export type PostReportsType = PostReportRequest | PostReportSuccess | PostReportFailure | RunReport;

export function postReportRequest(data: Record<string, null>, reportType: string): PostReportRequest {
    return {
        type: REPORTS.POST_REPORT_REQUEST,
        filterObject: data,
        reportType
    }
}

export function postReportSuccess(response: Record<string, null>, dateRangeDates: Record<string, null>): PostReportSuccess {
    return {
        type: REPORTS.POST_REPORT_SUCCESS,
        response,
        dateRangeDates
    }
}

export function postReportFailure(error: Record<string, null>): PostReportFailure {
    return {
        type: REPORTS.POST_REPORT_FAILURE,
        error
    }
}

export function runReport(status: boolean): RunReport {
    return {
        type: REPORTS.RUN_REPORT,
        runReport: status
    };
}