//constants
import * as LOCATIONS from '../ActionTypes/locationsTypes';
//modals
import { LocationsModal } from '../../models/LocationsModal';
//constants
import { ERR_WHILE_FETCHING } from "../../constants/constant";

/**
 * fetch locations
 */
export interface FetchLocationsSuccessRespsonse {
    response: Record<string, any> | {};
}
export interface FetchLocationsRequest {
    type: LOCATIONS.FETCH_LOCATIONS_REQUEST;
}
export interface FetchLocationsSuccess {
    type: LOCATIONS.FETCH_LOCATIONS_SUCCESS;
    locations: Array<Record<string, any>>;
}
export interface FetchLocationsFailure {
    type: LOCATIONS.FETCH_LOCATIONS_FAILURE;
    error: Record<string, any> | null;
}

export function FetchLocationsRequest(): FetchLocationsRequest {
    return {
        type: LOCATIONS.FETCH_LOCATIONS_REQUEST,
    }
}

export function FetchLocationsSuccess(items: Array<any>): FetchLocationsSuccess {
    return {
        type: LOCATIONS.FETCH_LOCATIONS_SUCCESS,
        locations: items,
    }
}

export function FetchLocationsFailure(error: Record<string, any>): FetchLocationsFailure {
    return {
        type: LOCATIONS.FETCH_LOCATIONS_FAILURE,
        error,
    }
}
/** 
* fetch details by system user id
*/
export interface FetchLocationsBySysUserIdRequest {
    type: LOCATIONS.FETCH_LOCATIONS_BY_SYSUSERID_REQUEST;
    systemUserId: string;
}
export interface FetchLocationsBySysUserIdSuccess {
    type: LOCATIONS.FETCH_LOCATIONS_BY_SYSUSERID_SUCCESS;
    locations: Array<LocationsModal>;
    systemUserId: string;
}
export interface FetchLocationsBySysUserIdFailure {
    type: LOCATIONS.FETCH_LOCATIONS_BY_SYSUSERID_FAILURE;
    error: Record<string, any> | null;
}


export function FetchLocationsBySysUserIdRequest(systemUserId: string): FetchLocationsBySysUserIdRequest {
    return {
        type: LOCATIONS.FETCH_LOCATIONS_BY_SYSUSERID_REQUEST,
        systemUserId
    };
}
export function FetchLocationsBySysUserIdSuccess(data: Record<string, any>): FetchLocationsBySysUserIdSuccess {
    const { systemUserId, locations } = data
    return {
        type: LOCATIONS.FETCH_LOCATIONS_BY_SYSUSERID_SUCCESS,
        systemUserId,
        locations
    };
}
export function FetchLocationsBySysUserIdFailure(error: Record<string, any>): FetchLocationsBySysUserIdFailure {
    return {
        type: LOCATIONS.FETCH_LOCATIONS_BY_SYSUSERID_FAILURE,
        error: { error: `${ERR_WHILE_FETCHING} ${error}` },
    };
}