import React, { useCallback, useEffect } from "react";
import { IonCol, IonGrid, IonPage, IonRow, IonSpinner } from "@ionic/react";
import { UserManagementTable } from "../../components/UserManagementTable/UserManagementTable";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../_redux/reducers/rootReducer";
import {
    PostUser,
    FetchUsers,
    PatchUser,
    putUserRole,
    updateUserLocationRequest,
    updateUserOrganizationRequest,
    resetFetchUsers,
    postUserEmailRequest
} from "../../_redux/actions/userManagementActions";
import {
    Hotel365Portal,
    USER_MGMT_HEADER,
    USER_MGMT_RESEND_EMAIL_INVITE_TOST,
    USER_MGMT_USR_ADD_SUCESS_TOST,
    USER_MGMT_USR_DLT_SUCESS_TOST,
    USER_MGMT_USR_EXISTS_ERR_TOST,
    USER_MGMT_USR_UPDATE_SUCESS_TOST,
    GLOBAL_NO_CHANGES_TO_SAVE,
} from "../../constants/constant";
import ToastrHook from "../../components/common/toastr/Toastr";
import { resetPasswordData } from "../../_redux/actions/resetPasswordActions";

export const UserManagement: React.FC<any> = () => {
    const dispatch = useDispatch();
    const [renderToastr] = ToastrHook();

    const {
        error,
        postUserManagement,
        pending,
        deleteUserManagement,
        fetchUserDetail,
        patchUserManagement,
        patchUserManagementRole,
        updateUserDetailLocation,
        updateUserDetailOrganization,
        postUserDetailEmail,
        fetchUserDetailLocations,
        resendUserManagement
    } = useSelector((state: RootState) => state.UserManagement, shallowEqual);

    const profile = useSelector((state: RootState) => { return state.profile }, shallowEqual);
    const { locationId: locationID } = profile && profile.currentProfile;
    let { postResetPasswordStatus } = useSelector((state: RootState) => { return (state.resetPassword) }, shallowEqual)


    const checkPostUserManagement = useCallback(() => {
        return postUserManagement && Object.keys(postUserManagement).length > 0;
    }, [postUserManagement]);

    const checkDeleteUserManagement = useCallback(() => {
        return (
            deleteUserManagement && Object.keys(deleteUserManagement).length > 0
        );
    }, [deleteUserManagement]);

    const checkPatchUserManagement = useCallback(() => {
        return (
            patchUserManagement && Object.keys(patchUserManagement).length > 0
        );
    }, [patchUserManagement]);

    const checkPatchUserManagementRole = useCallback(() => {
        return (
            patchUserManagementRole &&
            Object.keys(patchUserManagementRole).length > 0
        );
    }, [patchUserManagementRole]);

    const checkUpdateUserLocations = useCallback(() => {
        return (
            updateUserDetailLocation &&
            Object.keys(updateUserDetailLocation).length > 0
        );
    }, [updateUserDetailLocation]);

    const checkUpdateUserOrganizations = useCallback(() => {
        return (
            updateUserDetailOrganization &&
            Object.keys(updateUserDetailOrganization).length > 0
        );
    }, [updateUserDetailOrganization]);

    const checkUpdateUserEmail = useCallback(() => {
        return (
            postUserDetailEmail && Object.keys(postUserDetailEmail).length > 0
        );
    }, [postUserDetailEmail]);

    const checkResendInviteUser = useCallback(() => {
        return (
            resendUserManagement && Object.keys(resendUserManagement).length > 0
        );
    }, [resendUserManagement]);

    const submitData = useCallback(
        (data, sysId) => {
            let location: string[] = [];
            if (
                fetchUserDetailLocations &&
                fetchUserDetailLocations.length > 0
            ) {
                location = fetchUserDetailLocations.map(
                    (_location: Record<string, any>) => _location.locationId
                );
            }
            if (!sysId) {
                dispatch(PostUser(data));
            } else {
                const endDate = /\s/g.test(data.roles[0].effectiveEndDate) ? data.roles[0].effectiveEndDate.slice(0, 10) : data.roles[0].effectiveEndDate
                const startDate = /\s/g.test(data.roles[0].effectiveStartDate) ? data.roles[0].effectiveStartDate.slice(0, 10) : data.roles[0].effectiveStartDate
                /**
                 * No changes to save
                 */
                if (
                    (fetchUserDetail?.firstName === data.firstName) &&
                    (fetchUserDetail?.lastName === data.lastName) &&
                    (fetchUserDetail?.effectiveEndDate ===
                        endDate) &&
                    (fetchUserDetail?.email ===
                        data.email) &&
                    (fetchUserDetail?.effectiveStartDate ===
                        startDate) &&
                    JSON.stringify(location) === JSON.stringify(data.locations)
                ) {
                    renderToastr(GLOBAL_NO_CHANGES_TO_SAVE, "warning");
                    return;
                }
                /**
                 * Check the modified values from the popup and invoke respective API
                 */
                if (
                    fetchUserDetail?.firstName !== data.firstName ||
                    fetchUserDetail?.lastName !== data.lastName
                ) {
                    dispatch(PatchUser(sysId, data));
                }
                const effectiveEndDate = data.roles[0].effectiveEndDate
                    ? data.roles[0].effectiveEndDate.split(" ")[0]
                    : "";
                const effectiveStartDate = data.roles[0].effectiveStartDate
                    ? data.roles[0].effectiveStartDate.split(" ")[0]
                    : "";
                if (
                    fetchUserDetail?.effectiveEndDate !== effectiveEndDate ||
                    fetchUserDetail?.effectiveStartDate !== effectiveStartDate
                ) {
                    dispatch(
                        putUserRole(sysId, data.roles[0].roleId, {
                            effectiveEndDate: data.roles[0].effectiveEndDate,
                            effectiveStartDate: data.roles[0].effectiveStartDate,
                        })
                    );
                }
                if (
                    JSON.stringify(location) !== JSON.stringify(data.locations)
                ) {
                    dispatch(
                        updateUserOrganizationRequest(sysId, {
                            orgs: data.orgs,
                        })
                    );
                    dispatch(
                        updateUserLocationRequest(sysId, {
                            locations: data.locations,
                        })
                    );
                }
                if (fetchUserDetail?.email !== data.email) {
                    dispatch(
                        postUserEmailRequest(sysId, {
                            email: data.email,
                        })
                    );
                }
            }
        },
        [fetchUserDetail, fetchUserDetailLocations]
    );

    useEffect(() => {
        let errorMessage = "";
        if (error && Object.keys(error).length > 0) {
            if (error.status === 401) {
                errorMessage = Hotel365Portal.NOT_AUTHORIZED_ERR;
            } else if (error.status === 422) {
                errorMessage = USER_MGMT_USR_EXISTS_ERR_TOST;
            } else {
                errorMessage = Hotel365Portal.SERVICE_UNAVAILABLE_ERR;
            }
            renderToastr(errorMessage, "danger");
        }
        !error &&
            checkPostUserManagement() &&
            renderToastr(USER_MGMT_USR_ADD_SUCESS_TOST, "success");

        !error &&
            checkDeleteUserManagement() &&
            renderToastr(USER_MGMT_USR_DLT_SUCESS_TOST, "success");

        !error &&
            checkResendInviteUser() &&
            renderToastr(USER_MGMT_RESEND_EMAIL_INVITE_TOST, "success");

        !error &&
            (checkPatchUserManagement() ||
                checkPatchUserManagementRole() ||
                checkUpdateUserLocations() ||
                checkUpdateUserOrganizations() ||
                checkUpdateUserEmail()) &&
            renderToastr(USER_MGMT_USR_UPDATE_SUCESS_TOST, "success");
        if (postResetPasswordStatus && Object.keys(postResetPasswordStatus).length > 0) {
            renderToastr(Hotel365Portal.RESET_PASSWORD_LINK, "success");
            dispatch(resetPasswordData());
        }
        setTimeout(() => {
            if (
                !error &&
                (checkPostUserManagement() ||
                    checkDeleteUserManagement() ||
                    checkPatchUserManagement() ||
                    checkPatchUserManagementRole() ||
                    checkUpdateUserLocations() ||
                    checkUpdateUserOrganizations() ||
                    checkUpdateUserEmail() || checkResendInviteUser())
            ) {
                dispatch(resetFetchUsers());
                dispatch(FetchUsers(locationID, 0));
            }
        }, 2000);
    }, [
        error,
        postUserManagement,
        deleteUserManagement,
        patchUserManagement,
        patchUserManagementRole,
        updateUserDetailLocation,
        updateUserDetailOrganization,
        postUserDetailEmail,
        resendUserManagement,
        postResetPasswordStatus,
        dispatch
    ]);

    useEffect(() => {
        dispatch(resetFetchUsers());
        dispatch(FetchUsers(locationID, 0));
    }, [dispatch, locationID]);

    const updatePaginate = (offset: number) => {
        dispatch(FetchUsers(locationID, offset));
    };

    return (
        <IonPage className="user-management-body">
            {pending && (
                <IonSpinner
                    name="circles"
                    className="spinner-circles"
                />
            )}
            {!pending && (
                <React.Fragment>
                    <IonRow>
                        <IonCol size="12">
                            <h1 className="header-primary">
                                <b>{USER_MGMT_HEADER}</b>
                            </h1>
                        </IonCol>
                    </IonRow>
                    <UserManagementTable
                        submitData={submitData}
                        updatePaginate={updatePaginate}
                    />
                </React.Fragment>
            )}
        </IonPage>
    );
};
export default UserManagement;
