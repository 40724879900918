// vendors
import React from "react";
import { IonButton, isPlatform, IonCol, IonRow } from "@ionic/react";
//components
import MobileFooter from "./MobileFooter";
//constants
import { paymentConstants, PaymentFooterProps } from '../constants';

const Footer: React.FC<PaymentFooterProps> = ({
    detachCard,
    isAddCardClicked,
    onCancel,
    showDeleteCardBtn = false,
    showSaveNewCardBtn = false,
    onClickSave
}) => {
    const ismobile = isPlatform('mobile');
    return (
        <IonRow className={ismobile ? `footer-payment-mobile ion-no-padding` : `footer-payment  ion-no-padding`}>
            {ismobile ?
                <MobileFooter
                    onCancel={onCancel}
                    detachCard={detachCard}
                    onClickSave={onClickSave}
                    isAddCardClicked={isAddCardClicked}
                    showDeleteCardBtn={showDeleteCardBtn}
                    showSaveNewCardBtn={showSaveNewCardBtn}
                /> :
                <IonCol size="12" className="payment-footer-col">
                    {showSaveNewCardBtn && (
                        <IonButton
                            id="paymentSubmit"
                            onClick={onClickSave}
                            className="square-buttons"
                            disabled={isAddCardClicked}
                            style={{ marginRight: 0, marginBottom: 0, marginLeft: '4px' }}
                        />
                    )}
                    <IonButton onClick={onCancel} className="square-buttons" style={{ marginRight: 0 }}>{paymentConstants.CONFIRM_CANCEL_BUTTON}</IonButton>
                    {showDeleteCardBtn && (
                        <IonButton
                            color="danger"
                            onClick={detachCard}
                            className="square-buttons">
                            {paymentConstants.CONFIRM_DELETE_BUTTON}
                        </IonButton>
                    )}
                </IonCol>}
        </IonRow>
    );
}
export default Footer;