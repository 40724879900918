import { IonSpinner } from "@ionic/react";
import React, { useEffect, useState, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import ToastrHook from "../../components/common/toastr/Toastr";
import TokenExpired from "../../components/CreatePassword/TokenExpired";
import ResetPasswordForm from "../../components/ResetPassword/ResetPasswordForm";
import { Hotel365Portal, PWD_UPDATE_SUCCESS, USER_MOD_FORM_INVAILD_EMAIL_ERR } from "../../constants/constant";
import { Routes } from "../../constants/Routes";
import {
    SytemAppType,
    resetPasswordValidation,
    validateEmail,
} from "../../utils/FormValidations";
import {
    PostResetPassword,
    PutResetPassword,
} from "../../_redux/actions/resetPasswordActions";
import { postVerifyTokenRequest } from "../../_redux/actions/systemUsersActions";
import { RootState } from "../../_redux/reducers/rootReducer";
import { callbackUri, portalType } from "../../auth.config";
import { useAuth0 } from "@auth0/auth0-react";
import { Browser } from "@capacitor/browser";

export const ResetPasswordContainer: React.FC<any> = () => {
    const {       
        logout,
    } = useAuth0();
    const dispatch = useDispatch();
    const [renderToastr] = ToastrHook();
    const history = useHistory();
    const [password, setPassword] = useState({
        newPassword: "",
        confirmPassword: "",
    });
    const [email, setEmail] = useState({
        newEmail: "",
    });
    const [formErrors, setFormErrors] = useState({});
    const useQuery = new URLSearchParams(useLocation().search);

    const tokenIdData = useQuery.get("token");
    const createPassword = useSelector((state: RootState) => {
        return state.createPassword;
    }, shallowEqual);
    const resetPassword = useSelector((state: RootState) => {
        return state.resetPassword;
    }, shallowEqual);

    const { pending, errors, systemUserId } = createPassword;
    const {
        ResetPasswordErrors,
        putResetPassword,
        error: resetPasswordError,
        updatePasswordStatus,
        postResetPasswordStatus, 
    } = resetPassword;
    const onSubmitHandler = () => {
        const errorPassword = resetPasswordValidation(
            password.newPassword,
            password.confirmPassword
        );
        const isPasswordError = Object.keys(errorPassword).length;
        setFormErrors(errorPassword);
        if (isPasswordError === 0 && !errors) {
            const request = {
                password: password.newPassword,
                token: tokenIdData,
            };
            dispatch(PutResetPassword(systemUserId, request));
        }
    };
    const onChangeHandler = (e: any) => {
        setPassword((prevState: any) => ({
            ...prevState,
            [e.target.name]: e.detail.value,
        }));
    };   

    const doLogout = useCallback(async () => {
        logout({
            logoutParams: {
                returnTo: callbackUri,
            }
        });
    }, [])

    const onResetpasswordNewEmailSubmit = () => {
        const errorEmail = validateEmail(email.newEmail);
        const isErrorEmail = Object.keys(errorEmail).length;
        setFormErrors({ ...errorEmail });
        const sytemPortaltype = SytemAppType(portalType)
        const request = {
            email: email.newEmail,
            systemAppType: sytemPortaltype
        };
        if (isErrorEmail === 0) {           
            email.newEmail && dispatch(PostResetPassword(request));
        }
    }; 
    useEffect(() => {
        setEmail({ newEmail: "" });
        if (tokenIdData) {
            dispatch(postVerifyTokenRequest(tokenIdData));
        }
    }, []);
    useEffect(() => { 
        if (  ResetPasswordErrors !== null && !putResetPassword && updatePasswordStatus === "") {
            history.push({
                pathname: Routes.inviteExpired.url,
                state: { errorResponse: true },
            });
            renderToastr(Hotel365Portal.UPDATE_PASSWORD_ERR, "danger");
        }
        if (resetPasswordError === null && putResetPassword !== null && updatePasswordStatus ) {
            renderToastr(PWD_UPDATE_SUCCESS, "success");
            setTimeout(()=>{
                doLogout() 
            },2000)             
        }
        if (resetPasswordError && resetPasswordError.status === 404) {
            renderToastr(USER_MOD_FORM_INVAILD_EMAIL_ERR, "danger");
        }         
        if (postResetPasswordStatus && postResetPasswordStatus.status === 202) {
            renderToastr(Hotel365Portal.newLinkUpdated, "success");
            setTimeout(()=>{
                doLogout() 
            },2000)
        }
    }, [resetPassword]);
    const renderComponent = () => {
        return (
            <>
                {errors ? (
                    <TokenExpired
                        onResetpasswordNewEmailSubmit={
                            onResetpasswordNewEmailSubmit
                        }
                        setEmail={setEmail}
                        newEmail={email.newEmail}
                        formErrors={formErrors}
                    />
                ) : (
                    <ResetPasswordForm
                        onSubmitHandler={onSubmitHandler}
                        onChangeHandler={onChangeHandler}
                        formErrors={formErrors}
                    />
                )}

                {ResetPasswordErrors !== null &&
                    !putResetPassword &&
                    updatePasswordStatus === "" && (
                        <TokenExpired
                            setEmail={setEmail}
                            createPasswordNewInviteErrorMessage={
                                Hotel365Portal.LINK_EXPIRED_ERR
                            }
                            newEmail={email.newEmail}
                            onResetpasswordNewEmailSubmit={
                                onResetpasswordNewEmailSubmit
                            }
                            formErrors={formErrors}
                        />
                    )}
            </>
        );
    };

    return (
        <>
            {pending ? (
                <IonSpinner name="circles" className="spinner-circles" />
            ) : (
                renderComponent()
            )}
        </>
    );
};
