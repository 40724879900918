import {
    PostMarketOrderHeaderRequest,
    PostMarketOrderHeaderSuccess,
    PostMarketOrderHeaderFailure,
    PostMarketOrderHeaderType,
    FetchMarketOrderHeader,
    FetchMarketOrderHeaderSuccess,
    FetchMarketOrderHeaderFailure,
    FetchMarketOrderHeaderType,
    ResetMarketOrderType,
    ResetMarketOrder,
    FetchMarketOrderProducts,
    FetchMarketOrderProductsSuccess,
    FetchMarketOrderProductsFailure,
    FetchMarketOrderProductsType,
    FetchMarketOrder,
    FetchMarketOrderSuccess,
    FetchMarketOrderFailure,
    FetchMarketOrderType,
    DeleteMarketOrderProduct,
    DeleteMarketOrderProductSuccess,
    DeleteMarketOrderProductFailure,
    DeleteMarketOrderProductType,
    SaveMarketOrderProduct,
    SaveMarketOrderProductSuccess,
    SaveMarketOrderProductFailure,
    SaveMarketOrderProductType,
    PatchMarketOrderProduct,
    PatchMarketOrderProductSuccess,
    PatchMarketOrderProductFailure,
    PatchMarketOrderProductType,
    PostMarketOrderRequest,
    PostMarketOrderSuccess,
    PostMarketOrderFailure,
    PostMarketOrderType,
    PostAutoOrderHeaderRequest,
    PostAutoOrderHeaderSuccess,
    PostAutoOrderHeaderFailure,
    PostAutoOrderHeaderType,
} from "../actions/orderActions";
import { Order, OrderPagination, OrderProduct } from "../../models/Order";

export type orderTypes =
    | PostMarketOrderHeaderType
    | FetchMarketOrderHeaderType
    | ResetMarketOrderType
    | FetchMarketOrderProductsType
    | FetchMarketOrderType
    | DeleteMarketOrderProductType
    | SaveMarketOrderProductType
    | PatchMarketOrderProductType
    | PostMarketOrderType
    | PostAutoOrderHeaderType;

export interface IOrder {
    pending: boolean;
    paginate: OrderPagination;
    error: Record<string, any> | null;
    postMarketOrderHeader: Order | null;
    fetchMarketOrderHeaders: Order[];
    fetchMarketOrderProducts: OrderProduct[];
    fetchMarketOrderProductsPagination: OrderPagination;
    fetchMarketOrder: Order | null;
    removedMarketOrderProduct: Record<string, unknown>;
    createdMarketOrderProduct: Record<string, unknown>;
    locationId: string;
    patchMarketOrderProduct: Record<string, unknown>;
    submittedMarketOrder: Record<string, unknown>;
    postAutoOrderHeader: Order | null;
}
const initialState = {
    pending: true,
    paginate: {} as OrderPagination,
    error: null,
    postMarketOrderHeader: {} as Order,
    fetchMarketOrderHeaders: [],
    fetchMarketOrderProducts: [],
    fetchMarketOrderProductsPagination: {} as OrderPagination,
    fetchMarketOrder: {} as Order,
    removedMarketOrderProduct: {},
    createdMarketOrderProduct: {},
    locationId: "",
    patchMarketOrderProduct: {},
    submittedMarketOrder: {},
    postAutoOrderHeader: {} as Order,
};

const strategies = {
    FETCH_MARKET_ORDER_HEADER_REQUEST: fetchMarketOrderHeaders,
    FETCH_MARKET_ORDER_HEADER_SUCCESS: fetchMarketOrderHeadersSuccess,
    FETCH_MARKET_ORDER_HEADER_FAILURE: fetchMarketOrderHeadersFailure,
    POST_MARKET_ORDER_HEADER_REQUEST: fetchMarketOrderHeaders,
    POST_MARKET_ORDER_HEADER_SUCCESS: postMarketOrderHeaderSuccess,
    POST_MARKET_ORDER_HEADER_FAILURE: postMarketOrderHeaaderFailure,
    RESET_MARKET_ORDER: resetMarketOrders,
    FETCH_MARKET_ORDER_PRODUCT_REQUEST: fetchMarketOrderHeaders,
    FETCH_MARKET_ORDER_PRODUCT_SUCCESS: fetchMarketOrderProductsSuccess,
    FETCH_MARKET_ORDER_PRODUCT_FAILURE: fetchMarketOrderHeadersFailure,
    FETCH_MARKET_ORDER_REQUEST: fetchMarketOrderHeaders,
    FETCH_MARKET_ORDER_SUCCESS: fetchMarketOrderSuccess,
    FETCH_MARKET_ORDER_FAILURE: fetchMarketOrderHeadersFailure,
    DELETE_MARKET_ORDER_PRODUCT: fetchMarketOrderHeaders,
    DELETE_MARKET_ORDER_PRODUCT_SUCCESS: deleteMarketOrderProduct,
    DELETE_MARKET_ORDER_PRODUCT_FAILURE: fetchMarketOrderHeadersFailure,
    SAVE_MARKET_ORDER_PRODUCT_REQUEST: fetchMarketOrderHeaders,
    SAVE_MARKET_ORDER_PRODUCT_SUCCESS: saveMarketOrderProduct,
    SAVE_MARKET_ORDER_PRODUCT_FAILURE: fetchMarketOrderHeadersFailure,
    PATCH_MARKET_ORDER_PRODUCT: fetchMarketOrderHeaders,
    PATCH_MARKET_ORDER_PRODUCT_SUCCESS: patchMarketOrderProduct,
    PATCH_MARKET_ORDER_PRODUCT_FAILURE: fetchMarketOrderHeadersFailure,
    POST_MARKET_ORDER_REQUEST: fetchMarketOrderHeaders,
    POST_MARKET_ORDER_SUCCESS: postMarketOrderProducts,
    POST_MARKET_ORDER_FAILURE: fetchMarketOrderHeadersFailure,
    POST_AUTO_ORDER_HEADER_REQUEST: fetchMarketOrderHeaders,
    POST_AUTO_ORDER_HEADER_SUCCESS: postAutoOrderHeaderSuccess,
    POST_AUTO_ORDER_HEADER_FAILURE: fetchMarketOrderHeadersFailure,
    default: (state: IOrder) => state,
};

function postMarketOrderHeaderSuccess(
    state: IOrder,
    action: PostMarketOrderHeaderSuccess
) {
    return {
        ...state,
        error: null,
        pending: false,
        postMarketOrderHeader: action.data,
    };
}

function fetchMarketOrderHeaders(
    state: IOrder,
    _action:
        | PostMarketOrderHeaderRequest
        | FetchMarketOrderHeader
        | FetchMarketOrderProducts
        | FetchMarketOrder
        | DeleteMarketOrderProduct
        | SaveMarketOrderProduct
        | PatchMarketOrderProduct
        | PostMarketOrderRequest
        | PostAutoOrderHeaderRequest
) {
    return {
        ...state,
        error: null,
        postMarketOrderHeader: {} as Order,
        postAutoOrderHeader: {} as Order,
    };
}
function fetchMarketOrderHeadersFailure(
    state: IOrder,
    action:
        | FetchMarketOrderHeaderFailure
        | FetchMarketOrderProductsFailure
        | FetchMarketOrderFailure
        | DeleteMarketOrderProductFailure
        | SaveMarketOrderProductFailure
        | PatchMarketOrderProductFailure
        | PostMarketOrderFailure
        | PostAutoOrderHeaderFailure
) {
    return {
        ...state,
        error: action.error,
        pending: false,
        postMarketOrderHeader: null,
        postAutoOrderHeader: null
    };
}
function postMarketOrderHeaaderFailure(
    state: IOrder,
    action: PostMarketOrderHeaderFailure
) {
    const { status } = action.error as Record<string, any>;
    let postMarketHeaderFailure = {} as Record<string, any> | null;
    if (status === 422) {
        postMarketHeaderFailure = { 'type': 'post_market_header_failure', error: action.error }
    } else {
        postMarketHeaderFailure = action.error;
    }
    return {
        ...state,
        pending: false,
        error: postMarketHeaderFailure,
    };
}

function fetchMarketOrderHeadersSuccess(
    state: IOrder,
    action: FetchMarketOrderHeaderSuccess
) {
    if (state.locationId !== action.locationId)
        state.fetchMarketOrderHeaders = [];
    /**to Avoid duplicates from an array */
    const orderHeadrs = state.fetchMarketOrderHeaders
        .filter(
            (_stateMarketOrders) =>
                !action.data.items.find(
                    (_recentMarketOrders: Record<string, any>) =>
                        _stateMarketOrders?.marketOrderHeaderId ===
                        _recentMarketOrders?.marketOrderHeaderId
                )
        )
        .concat(action.data.items);
    orderHeadrs.sort(function (a, b) {
        return new Date(a.lastUpdated).getTime() >
            new Date(b.lastUpdated).getTime()
            ? -1
            : 1;
    });

    return {
        ...state,
        error: null,
        pending: false,
        fetchMarketOrderHeaders: orderHeadrs.filter((item) => item.orderNumber),
        paginate: action.data.metadata.pagination,
        locationId: action.locationId,
    };
}

function resetMarketOrders(state: IOrder, action: ResetMarketOrder) {
    return Object.assign({}, state, {
        ...state,
        fetchMarketOrderHeaders: action.data
            ? action.data
            : state.fetchMarketOrderHeaders,
        fetchMarketOrder: {},
        fetchMarketOrderProducts: [],
        removedMarketOrderProduct: {},
        createdMarketOrderProduct: {},
        patchMarketOrderProduct: {},
        submittedMarketOrder: {},
        error: null,
    });
}

function fetchMarketOrderProductsSuccess(
    state: IOrder,
    action: FetchMarketOrderProductsSuccess
) {
    const marketOrderProducts = Object.assign([], action.data.items); // to clone an array
    const finalProductList = marketOrderProducts.map(
        (item: Record<string, any>) => {
            item.price = item.price ? item.price : 0;
            if (
                state.fetchMarketOrder &&
                Object.keys(state.fetchMarketOrder).length > 0 &&
                ["pending", "ordered"].indexOf(
                    state.fetchMarketOrder.marketOrderStatus.status.toLowerCase()
                ) > -1
            ) {
                item.qty = item.qtyOrdered || item.qtyPlanned || 0;
                item.total = item.qty * item.price;
                item.qtyOrdered = item.qtyOrdered ? item.qtyOrdered : item.qty;
            } else if (
                state.fetchMarketOrder &&
                Object.keys(state.fetchMarketOrder).length > 0 && ["delivered", "cancelled", "canceled"].indexOf(
                    state.fetchMarketOrder?.marketOrderStatus.status.toLowerCase()
                ) > -1
            ) {
                item.qty = item.qtyDelivered || item.qtyOrdered || 0;
                item.total = state.fetchMarketOrder?.marketOrderStatus.status.toLowerCase() === 'delivered' ? (item.qtyDelivered * item.price) : 0.0;
                item.qtyOrdered = item.qtyOrdered
                    ? item.qtyOrdered
                    : item.qty;
            } else {
                item.total = 0.0;
                item.qty = 0;
            }
            return item;
        }
    ) as OrderProduct[];
    /**to Avoid duplicates from an array */
    const orderProducts = state.fetchMarketOrderProducts
        .filter(
            (_stateMarketOrders) =>
                !finalProductList.find(
                    (_recentMarketOrders: Record<string, any>) =>
                        _stateMarketOrders?.marketOrderItemId ===
                        _recentMarketOrders?.marketOrderItemId
                )
        )
        .concat(finalProductList);
    return {
        ...state,
        error: null,
        pending: false,
        fetchMarketOrderProducts: orderProducts,
        fetchMarketOrderProductsPagination: action.data.metadata.pagination,
    };
}

function fetchMarketOrderSuccess(
    state: IOrder,
    action: FetchMarketOrderSuccess
) {
    return {
        ...state,
        error: null,
        pending: false,
        fetchMarketOrder: action.data,
    };
}

function deleteMarketOrderProduct(
    state: IOrder,
    action: DeleteMarketOrderProductSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        removedMarketOrderProduct: action.data,
        error: null,
    });
}

function patchMarketOrderProduct(
    state: IOrder,
    action: PatchMarketOrderProductSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        patchMarketOrderProduct: action.data,
        error: null,
    });
}

function postMarketOrderProducts(
    state: IOrder,
    action: PostMarketOrderSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        submittedMarketOrder: action.data,
        error: null,
    });
}

function saveMarketOrderProduct(
    state: IOrder,
    action: SaveMarketOrderProductSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        createdMarketOrderProduct: action.data,
        error: null,
    });
}

function postAutoOrderHeaderSuccess(
    state: IOrder,
    action: PostAutoOrderHeaderSuccess
) {
    return {
        ...state,
        error: null,
        pending: false,
        postAutoOrderHeader: action.data,
    };
}

export default (state: IOrder = initialState, action: orderTypes): IOrder => {
    return (strategies[action.type] || strategies.default)(
        state,
        action as never
    );
};
