//vendors
import React from "react";
import { IonCol, IonRow } from "@ionic/react";
//constants
import { paymentConstants } from "./constants";

const NoCardsAvailable: React.FC = () => {
    return (
        <IonRow style={{height:"80%"}}>
            <IonCol>
                <div className="no-cards-msg-wrapper">
                    <div className="no-cards-msg">
                        <span>{paymentConstants.NO_PAYMENT_MSG1}</span>
                        <span>{paymentConstants.NO_PAYMENT_MSG2}</span>
                        <span>{paymentConstants.NO_PAYMENT_MSG3}</span>
                    </div>
                </div>
            </IonCol>
        </IonRow>
    );
};
export default NoCardsAvailable;
