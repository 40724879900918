// vendors
import React, { useEffect, useState } from "react";
import { IonCol, IonItem, IonNote, IonRow, isPlatform } from "@ionic/react";
//components
import ToggleButton from "../Container/ToggleSwitchFeature/ToggleButton";
import CounterFeature from "../Container/CounterFeature";
//constants
import { toggleOptionLabels, onBoardingRightSection, ONBOARDING_SQUARE_FEET } from '../../constants/constant'
//utils
import { getQuestionsForToggle } from '../../utils'

const MarketQuestionnaires: React.FC<any> = ({
    isFullServiceRestaurant,
    isQuickServiceRestaurant,
    isRoomServiceAvailable,
    doesBarOrRestaurantOfferAlcohol,
    isCoffeeShopBar,
    hasPreferredBeverageCompany,
    marketSizeInSqFt,
    questionaries,
    setOnboarding
}) => {
    const isMobile = isPlatform("mobile");

    const questionariesValues = {
        "isFullServiceRestaurant": isFullServiceRestaurant,
        "isQuickServiceRestaurant": isQuickServiceRestaurant,
        "isRoomServiceAvailable": isRoomServiceAvailable,
        "doesBarOrRestaurantOfferAlcohol": doesBarOrRestaurantOfferAlcohol,
        "isCoffeeShopBar": isCoffeeShopBar,
        "hasPreferredBeverageCompany": hasPreferredBeverageCompany,
    };
    const updatedQuestionaries = getQuestionsForToggle(questionariesValues, questionaries);
    const [questions, setQuestions] = useState(updatedQuestionaries);

    const onChangeHandler = (question: string, checked: boolean) => {
        setOnboarding((prevState: any) => {
            return {
                ...prevState,
                [question]: checked
            }
        })
        setQuestions({ ...questions, [question]: { ...questions[question], checked } })
    }

    const incSquareFeetCounter = () => {
        setOnboarding((prevState: any) => {
            return {
                ...prevState,
                marketSizeInSqFt: Number(prevState.marketSizeInSqFt) + Number(1)
            }
        })
    }

    const decSquareFeetCounter = () => {
        if (marketSizeInSqFt >= 1) {
            setOnboarding((prevState: any) => {
                return {
                    ...prevState,
                    marketSizeInSqFt: Number(prevState.marketSizeInSqFt) - Number(1)
                }
            })
        }
    }
    const setSquareFeet = (e: any) => {
        if (e.target.value >= 0) {
            setOnboarding((prevState: any) => {
                return {
                    ...prevState,
                    marketSizeInSqFt: e.target.value
                }
            })
        }
    }

    useEffect(() => {
        const questionariesValues = {
            "isFullServiceRestaurant": isFullServiceRestaurant,
            "isQuickServiceRestaurant": isQuickServiceRestaurant,
            "isRoomServiceAvailable": isRoomServiceAvailable,
            "doesBarOrRestaurantOfferAlcohol": doesBarOrRestaurantOfferAlcohol,
            "isCoffeeShopBar": isCoffeeShopBar,
            "hasPreferredBeverageCompany": hasPreferredBeverageCompany,
        };
        const updatedQuestionaries = getQuestionsForToggle(questionariesValues, questionaries);
        setQuestions(updatedQuestionaries);
    }, [isFullServiceRestaurant,
        isQuickServiceRestaurant,
        isRoomServiceAvailable,
        doesBarOrRestaurantOfferAlcohol,
        isCoffeeShopBar,
        hasPreferredBeverageCompany])

    return (
        <>
            <IonRow className="ion-padding-start">
                <IonCol><h2><b>{onBoardingRightSection.questionHeading}</b></h2></IonCol>
            </IonRow>
            <IonRow style={isMobile ? { height: '35vh', overflowY: 'auto' } : {}} className="ion-padding-start">
                <IonCol>
                    {Object.keys(questions).map((question) => {
                        const { label, name, id, checked } = questions[question];
                        return (
                            <IonRow key={id}>
                                <IonCol>
                                    <IonNote color="medium" className="note-bold-12">{label}</IonNote>
                                    <IonItem lines="none">
                                        <ToggleButton
                                            id={id}
                                            name={name}
                                            checked={checked}
                                            optionLabels={toggleOptionLabels as any}
                                            onChange={(checked: any) => onChangeHandler(question, checked)}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        );
                    })}
                </IonCol>
            </IonRow>
            <IonRow className="ion-padding-start">
                <IonCol>
                    <h2 className="bold-h2">{onBoardingRightSection.questionMarketFirst}</h2>
                    <CounterFeature
                        counter={marketSizeInSqFt}
                        setCounter={setSquareFeet}
                        increaseCounter={incSquareFeetCounter}
                        descreseCounter={decSquareFeetCounter}
                        label={ONBOARDING_SQUARE_FEET}
                    />
                </IonCol>
            </IonRow>
        </>
    );
}

export default MarketQuestionnaires;