//vendors
import { IonButton, IonRow, IonCol } from "@ionic/react";
import { useSelector, shallowEqual } from "react-redux";
//reducer
import { RootState } from "../../_redux/reducers/rootReducer";
//constants
import { RUN_REPORT } from '../../constants/constant';

export const RunReport: React.FC<any> = ({ runReportCallBack, formErrors }) => {
    const { isValid } = formErrors;
    const reportsData = useSelector((state: RootState) => { return state.reports }, shallowEqual);
    const { data, pending, isReportRan } = reportsData;
    return (
        <IonRow>
            <IonCol className="ion-no-padding">
                <IonButton
                    className="report-run-report-btn"
                    onClick={() => { runReportCallBack() }}
                    disabled={(!isValid) || (isReportRan && pending)}>
                    {RUN_REPORT}
                </IonButton>
            </IonCol>
        </IonRow>
    )
};
export default RunReport;
