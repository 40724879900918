//constant 
import { portalType } from "../auth.config";
import { HOTEL_ADMIN_ID, SUPER_DEV_ID, SUPER_ID, HOTEL_MANAGER_ID, CLIENT_ADMIN_ID, PortalType } from "../constants/constant";

export const getRoleId = (roles: any) => {
    return roles.map((role: any) => role.roleId)
}

export const isOnboardingAccessPermitted = (pending: boolean, roles: any) => {
    const roleIds = getRoleId(roles);
    return (!pending && (roleIds.includes(HOTEL_ADMIN_ID) || roleIds.includes(SUPER_DEV_ID) || roleIds.includes(SUPER_ID)));
}

export const isUserValidityIntact = (pending = true, roles = []) => {
    let validity = false;
    const roleIds = getRoleId(roles);
    if (portalType === PortalType.HP && !pending && (roleIds.includes(SUPER_DEV_ID) || roleIds.includes(SUPER_ID))) {
        validity = true;
    } else if ((portalType === PortalType.HP && !pending && (roleIds.includes(HOTEL_MANAGER_ID) || roleIds.includes(HOTEL_ADMIN_ID)) || (portalType === PortalType.CP && (roleIds.includes(CLIENT_ADMIN_ID))))) {
        for (let i = 0; i < roles.length; i++) {
            if ((portalType === PortalType.HP && roles[i]['roleId'] === HOTEL_MANAGER_ID || roles[i]['roleId'] === HOTEL_ADMIN_ID) || (portalType === PortalType.CP && roles[i]['roleId'] === CLIENT_ADMIN_ID)) {
                const { endDate, startDate } = roles[i];
                const isEndDateValid = !endDate ? true : new Date(endDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
                const isStartDateValid = !startDate ? true : new Date(startDate).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0);
                validity = isEndDateValid && isStartDateValid;
                break;
            }
        }
    }
    return validity;

}