import i18next from "i18next";
import {
    ClientPortalErrors,
    Hotel365Portal,
    passPattern,
    passWordValidationMsg,
    FIRST_NAME_ERR,
    LAST_NAME_ERR,
    USER_MOD_FORM_INVAIL_FIRST_LAST_NAME_ERR,
    USER_MOD_FORM_TEXT_FIELD_LENGTH_ERR,
    USER_MOD_FORM_EMAIL_REQ_ERR,
    USER_MOD_FORM_INVAILD_EMAIL_ERR,
    USER_MOD_FORM_DATE_VALID_ERR,
    GLOBAL_FIRST_NAME,
    GLOBAL_LAST_NAME,
    PROFILE_FORMAT_ERR,
    PROFILE_FORMAT_EMAIL_ERR,
    PROFILE_CONFIRM_EMAIL_REQ,
    PROFILE_NEW_EMAIL_ERR,
    PROFILE_NEW_EMAIL_REQ,
    PortalAppType,
    PortalType,
    USER_MOD_FORM_DATE_FORMAT_ERR,
    REQUIRED_DATE_ERROR
} from "../constants/constant";
import { DateTime } from "luxon";
interface ValidateErrorType {
    firstName: string;
    lastName: string;
    email: string;
    newEmail: string;
    confirmEmail: string;
    password: string;
    confirmPassword: string;
}

interface LooseObject {
    [key: string]: any;
}

export const validateUser = (
    values: Record<string, any>,
    isFromProfile?: boolean
) => {
    const errors: LooseObject = {};
    const todayDate = DateTime.now().toFormat('yyyy-MM-dd');
    if (!values.firstName) {
        errors.firstName = FIRST_NAME_ERR;
    } else if (values.firstName.length < 2) {
        errors.firstName = USER_MOD_FORM_TEXT_FIELD_LENGTH_ERR;
    } else if (!/^[a-zA-Z-]*$/i.test(values.firstName)) {
        errors.firstName = USER_MOD_FORM_INVAIL_FIRST_LAST_NAME_ERR;
    }
    if (!values.lastName) {
        errors.lastName = LAST_NAME_ERR;
    } else if (values.lastName.length < 2) {
        errors.lastName = USER_MOD_FORM_TEXT_FIELD_LENGTH_ERR;
    }
    else if (!/^[a-zA-Z-]*$/i.test(values.lastName)) {
        errors.lastName = USER_MOD_FORM_INVAIL_FIRST_LAST_NAME_ERR;
    }
    if (!values.email) {
        errors.email = USER_MOD_FORM_EMAIL_REQ_ERR;
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = USER_MOD_FORM_INVAILD_EMAIL_ERR;
    }
    if (!isFromProfile && !values.roleId) {
        errors.roleId = USER_MOD_FORM_INVAILD_EMAIL_ERR;
    }
    if (!isFromProfile && (!values.location || values.location?.length === 0)) {
        errors.location = USER_MOD_FORM_INVAILD_EMAIL_ERR;
    }
    if (((!values.isDisabled && values.effectiveStartDate && values.effectiveStartDate < todayDate) || (values.effectiveEndDate && values.effectiveEndDate < todayDate))) {
        errors.userValidityError = USER_MOD_FORM_DATE_FORMAT_ERR;
    } else if ((values.effectiveStartDate && values.effectiveEndDate) && values.effectiveStartDate > values.effectiveEndDate) {
        errors.userValidityError = USER_MOD_FORM_DATE_VALID_ERR;
    } else if ((values.effectiveStartDate && !values.effectiveEndDate) || (values.effectiveEndDate && !values.effectiveStartDate)) {
        errors.userValidityError = REQUIRED_DATE_ERROR;
    }
    return errors;
};
export const validateEmail = (email: string) => {
    const errors: LooseObject = {};

    if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        errors.emailError = PROFILE_FORMAT_ERR;
    }
    return errors;
};
export const validateConfirmEmail = (email: string, confirmEmail: string) => {
    const errors: LooseObject = {};
    if (!confirmEmail && email) {
        errors.confirmEmail = PROFILE_CONFIRM_EMAIL_REQ;
    } else if (!email && confirmEmail) {
        errors.emailError = PROFILE_NEW_EMAIL_REQ;
    } else if (
        confirmEmail &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(confirmEmail)
    ) {
        errors.confirmEmail = PROFILE_FORMAT_EMAIL_ERR;
    } else if (email && confirmEmail !== email) {
        errors.confirmEmail = PROFILE_NEW_EMAIL_ERR;
    }

    return errors;
};

export const resetPasswordValidation = (
    newPassword: string,
    confirmPassword?: string
) => {
    const errors: LooseObject = {};
    if (!newPassword && !confirmPassword) {
        errors.newPassword = Hotel365Portal.NEW_PASSWORD_REQUIRED_ERR;
    } else if (!newPassword && confirmPassword) {
        errors.newPassword = Hotel365Portal.NEW_PASSWORD_REQUIRED_ERR;
    }
    else if (newPassword && newPassword.length < 8) {
        errors.newPassword = passWordValidationMsg.passwordLength;
    } else if ((newPassword && confirmPassword && !passPattern.invalidConsecutiveCharacterPattern.test(newPassword))) {
        errors.confirmPassword = Hotel365Portal.INVALID_PASSWORD_ERR;
    } else if (newPassword && !confirmPassword) {
        errors.confirmPassword = Hotel365Portal.CONFIRMED_PASSWORD;
    } else if (newPassword && confirmPassword && confirmPassword !== newPassword) {
        errors.confirmPassword = Hotel365Portal.PASSWORD_MATCH_ERR;
    }
    return errors
};

export const validatePassword = (values: Record<string, any>) => {
    const errors = {
        password: "",
        confirmPassword: "",
    };
    if (!values.password) {
        errors.password = Hotel365Portal.PASSWORD_REQUIRED_ERR;
    }
    if (!values.confirmPassword) {
        errors.confirmPassword = Hotel365Portal.PASSWORD_REQUIRED_ERR;
    } else if (values.password && values.confirmPassword !== values.password) {
        errors.confirmPassword = Hotel365Portal.CONFIRM_PASSWORD_ERR;
    }
    return errors;
};
export const validateConsumer = (
    values: Record<string, any>,
    scancodes: Record<string, any>[]
) => {
    const errors: LooseObject = {};
    if (!values.firstName) {
        errors.firstName = FIRST_NAME_ERR;
    } else if (values.firstName.trim().length < 1) {
        errors.firstName = USER_MOD_FORM_TEXT_FIELD_LENGTH_ERR;
    }
    if (!values.lastName) {
        errors.lastName = LAST_NAME_ERR;
    } else if (values.lastName.trim().length < 1) {
        errors.lastName = USER_MOD_FORM_TEXT_FIELD_LENGTH_ERR;
    }
    if (!values.pin || values.pin.length < 4) {
        errors.pin = ClientPortalErrors.pinLength;
    }
    if(!values.accountId && values.location && values.location.length === 0){
        errors.location = ClientPortalErrors.locationErr;
    }
    const checkisDisabledScancodes = (scancodes?.filter(item => item.isDisabled))
    if (!scancodes || scancodes.length === 0 || (scancodes.length === checkisDisabledScancodes.length)) {
        if (!values.email) {
            errors.email = ClientPortalErrors.emailField;
        }
    }
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = ClientPortalErrors.invalidEmail;
    }

    if ((!scancodes || scancodes.length === 0 || (scancodes.length === checkisDisabledScancodes.length)) && !values.email) {
        errors.scancode = ClientPortalErrors.emailField;
    }
    return errors;

};
export const addFundsValidation = (values: Record<string, any>) => {
    const errors: LooseObject = {};
    if (!values.amount) {
        errors.amount = ClientPortalErrors.invalidAmount;
    }
    if ((!values.payment || values.payment.length === 0)) {
        errors.payment = ClientPortalErrors.paymentRequired;
    }
}
export const SytemAppType = (_portalType: any) => {
    if (_portalType === PortalType.HP) {
        return PortalAppType.HP
    } else if (_portalType === PortalType.CP) {
        return PortalAppType.CP
    }
}
