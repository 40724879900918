import {
    IonCol,
    IonGrid,
    IonIcon,
    IonPage,
    IonRow,
    IonSpinner,
    IonText,
} from "@ionic/react";
import {
    chevronBackCircleOutline,
    chevronForwardCircleOutline,
} from "ionicons/icons";
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import AlertConfirmWindow from "../../../components/common/alertconfirmwindow/AlertConfirmWindow";
import ToastrHook from "../../../components/common/toastr/Toastr";
import { Hotel365Portal, dashboardLabels, PRODUCT_LIST, ORDER_PRODUCT_SAVE_SUCCESS, GLOBAL_NO_CHANGES_TO_SAVE, ORDER_SWAP_DUPLICATE_ENTRY } from "../../../constants/constant";
import { navigateWithLoc } from "../../../constants/navigation";
import { getProductsDataForExcel, toExcel } from '../../../utils';
import {
    FetchMarketProducts,
    FetchGlobalProducts,
    updateProductSettings,
    PatchMarketProduct,
    FetchGlobalProductsCategories,
    resetMarketProducts,
    resetGlobalProducts,
    postPublishProduct,
    setShowPublishProduct,
    fetchMarketProductsForReport
} from "../../../_redux/actions/productActions";
import {
    FetchGlobalMargin,
    FetchCategoryMargin,
    resetProductsSettingsPanel,
    resetMargins
} from "../../../_redux/actions/productSettingsPanelActions";
import { RootState } from "../../../_redux/reducers/rootReducer";
import { DataGridSharedData } from "./ProductGrid/GridData";
import ProductListGrid from "./ProductListGrid";
import ProductsSettingsPanel from "./ProductsSettingPanel";
interface NavigateData {
    location: boolean;
    onBoard: boolean;
    addProducts: boolean;
}

const navigationFlow: NavigateData = navigateWithLoc;
const ProductListPage: React.FC = () => {
    const dispatch = useDispatch();
    const {
        pending,
        products,
        error,
        paginate,
        createdMarketProduct,
        patchedMarketProduct,
        removedMarketProduct,
        marketProducts,
        saveSwappedProduct,
        productSettings,
        publishProduct,
        showPublishProduct,
        marketProductsForReport,
        selfOperatedProducts
    } = useSelector((state: RootState) => state.product, shallowEqual);
    const { pedning: toExcelPending, error: toExcelError, items: toExcelItems } = marketProductsForReport;
    const {
        pending: settingsPanelPending,
        error: settingsPanelError,
        createdGlobalMargin,
        patchedGlobalMargin,
        createdCategoryMargin,
        patchedCategoryMargin,
        globalMargin: fetchGlobalMargin,
    } = useSelector((state: RootState) => state.productSettingsPanel, shallowEqual);

    const selectedManageColumns = useSelector((state: RootState) => state.product.manageColumns, shallowEqual);

    const profile = useSelector((state: RootState) => { return state.profile; }, shallowEqual);
    const { marketTheme, orgId, locationId, marketName, isSelfOperated, currency } = profile && profile.currentProfile;
    const { primaryColorHex } = marketTheme;

    const [renderTable, setShowTableState] = useState(false);
    const [showPanel, SetPanelShowStatus] = useState(false);
    const [showPublishButton, SetShowPublishButton] = useState(false);
    const [renderToastr] = ToastrHook();
    const [alertPrompt] = AlertConfirmWindow();
    const currentDate = DateTime.fromJSDate(new Date()).toFormat('MM/dd/yyyy');
    const currentTime = DateTime.fromJSDate(new Date()).toLocaleString(DateTime.TIME_SIMPLE);
    let dbObj: never[] | DataGridSharedData;

    useEffect(() => {
        if (locationId && orgId) {
            setShowTableState(false);
            SetPanelShowStatus(false)
            dispatch(resetMarketProducts());
            dispatch(resetMargins())
            dispatch(resetProductsSettingsPanel());
            dispatch(resetGlobalProducts());
            dispatch(FetchMarketProducts(0));
            dispatch(FetchGlobalProducts(0));
            dispatch(FetchGlobalMargin(""));
            dispatch(FetchCategoryMargin("", 0));
            dispatch(FetchGlobalProductsCategories("", 0));
        }
    }, [dispatch, orgId, locationId, setShowTableState, SetPanelShowStatus]);

    const checkTriggerCompilePrice = useCallback(() => {
        return (
            publishProduct && Object.keys(publishProduct).length > 0
        );
    }, [publishProduct]);

    const checkCreatedMarketProduct = useCallback(() => {
        return (
            createdMarketProduct && Object.keys(createdMarketProduct).length > 0
        );
    }, [createdMarketProduct]);

    const checkPatchedMarketProduct = useCallback(() => {
        return (
            patchedMarketProduct && Object.keys(patchedMarketProduct).length > 0
        );
    }, [patchedMarketProduct]);

    const checkCreatedGlobalMargin = useCallback(() => {
        return (
            createdGlobalMargin && Object.keys(createdGlobalMargin).length > 0
        );
    }, [createdGlobalMargin]);

    const checkPatchedGlobalMargin = useCallback(() => {
        return (
            patchedGlobalMargin && Object.keys(patchedGlobalMargin).length > 0
        );
    }, [patchedGlobalMargin]);

    const checkCreatedCategoryMargin = useCallback(() => {
        return (
            createdCategoryMargin &&
            Object.keys(createdCategoryMargin).length > 0
        );
    }, [createdCategoryMargin]);

    const checkPatchedCategoryMargin = useCallback(() => {
        return (
            patchedCategoryMargin &&
            Object.keys(patchedCategoryMargin).length > 0
        );
    }, [patchedCategoryMargin]);

    const checkRemovedMarketProduct = useCallback(() => {
        return (
            removedMarketProduct && Object.keys(removedMarketProduct).length > 0
        );
    }, [removedMarketProduct]);

    const checkSaveSwappedProduct = useCallback(() => {
        return saveSwappedProduct && Object.keys(saveSwappedProduct).length > 0;
    }, [saveSwappedProduct]);

    const checkCreateAndExtendProduct = useCallback(() => {
        return (
            selfOperatedProducts && Object.keys(selfOperatedProducts).length > 0
        );
    }, [selfOperatedProducts]);

    useEffect(() => {
        let errorMessage = "";
        if (
            (error && Object.keys(error).length > 0) ||
            (settingsPanelError && Object.keys(settingsPanelError).length > 0)
        ) {
            if (
                error &&
                [
                    "save_market_duplicate_failure",
                    "swap_duplicate_failure",
                ].indexOf(error.type) > -1
            ) {
                errorMessage = ORDER_SWAP_DUPLICATE_ENTRY;
            } else {
                errorMessage =
                    ((error && error.status === 401) ||
                        (settingsPanelError && settingsPanelError.status === 401))
                        ? Hotel365Portal.NOT_AUTHORIZED_ERR
                        : (error && error.status === 422) ? Hotel365Portal.upcAlreadyExists : Hotel365Portal.SERVICE_UNAVAILABLE_ERR;
            }

            renderToastr(errorMessage, "danger");
        }
        if (products && Object.keys(paginate).length > 0) {
            setShowTableState(true);
        } else {
            setShowTableState(false);
        }
        if (checkPatchedMarketProduct()) {
            renderToastr(Hotel365Portal.PRODUCT_UPDATE_SUCCESS, "success");
        }
        if (checkSaveSwappedProduct()) {
            renderToastr(Hotel365Portal.PRODUCT_SWAP_SUCCESS, "success");
        }
        if (checkTriggerCompilePrice()) {
            dispatch(setShowPublishProduct(false))
            renderToastr(Hotel365Portal.PUBLISH_PRODUCT_SUCCESS_MSG, "success");
        }
        if (checkCreatedGlobalMargin() || checkPatchedGlobalMargin()) {
            dispatch(setShowPublishProduct(true))
        }
        if (checkCreateAndExtendProduct()) {
            renderToastr(Hotel365Portal.productExtendedMsg, "success");
        }
        setTimeout(() => {
            if (
                checkCreatedMarketProduct() ||
                checkPatchedMarketProduct() ||
                checkCreatedGlobalMargin() ||
                checkPatchedGlobalMargin() ||
                checkCreatedCategoryMargin() ||
                checkPatchedCategoryMargin() ||
                checkRemovedMarketProduct() ||
                checkSaveSwappedProduct() ||
                checkTriggerCompilePrice() ||
                checkCreateAndExtendProduct()
            ) {
                setShowTableState(false);
                SetPanelShowStatus(false);
                dbObj = new DataGridSharedData([]);
                dispatch(resetMarketProducts());
                dispatch(resetMargins())
                dispatch(resetProductsSettingsPanel());
                dispatch(FetchMarketProducts(0));
                dispatch(FetchGlobalMargin(""));
                dispatch(FetchCategoryMargin("", 0));
            }

            if (checkPatchedMarketProduct() || checkSaveSwappedProduct() || checkCreateAndExtendProduct()) {
                dispatch(resetGlobalProducts());
                dispatch(FetchGlobalProducts(0));
                dispatch(FetchGlobalProductsCategories("", 0));
            }
        }, 2000);

    }, [
        error,
        settingsPanelError,
        paginate,
        products,
        setShowTableState,
        createdMarketProduct,
        productSettings,
        SetShowPublishButton,
        patchedMarketProduct,
        fetchGlobalMargin,
        createdGlobalMargin,
        patchedGlobalMargin,
        patchedCategoryMargin,
        createdCategoryMargin,
        saveSwappedProduct,
        removedMarketProduct,
        publishProduct,
        selfOperatedProducts
    ]);


    useEffect(() => {
        if (fetchGlobalMargin && Object.keys(fetchGlobalMargin).length > 0) {
            dispatch(
                updateProductSettings({
                    globalMargin: fetchGlobalMargin.margin + "%",
                    allowCategoryMargin:
                        fetchGlobalMargin.doesAllowCategoryLevelMargin,
                    allowProductMargin:
                        fetchGlobalMargin.doesAllowProductLevelMargin,
                    allowRoundToNines: fetchGlobalMargin.shouldRoundToTheNines,
                    taxRate1Name: fetchGlobalMargin.taxRate1Name,
                    taxRate2Name: fetchGlobalMargin.taxRate2Name,
                    taxRate3Name: fetchGlobalMargin.taxRate3Name,
                    taxRate4Name: fetchGlobalMargin.taxRate4Name,
                })
            );
        }
    }, [fetchGlobalMargin]);
    dbObj = useMemo(() => {
        return Array.isArray(products)
            ? new DataGridSharedData(products as any)
            : [];
    }, [products]);
    useEffect(() => {
        if (checkCreatedMarketProduct()) {
            renderToastr(ORDER_PRODUCT_SAVE_SUCCESS, "success");
        }
    }, [createdMarketProduct])

    /** commented: componentunmount*/
    useEffect(() => {
        return () => {
            dispatch(resetMarketProducts());
            dispatch(resetProductsSettingsPanel());
        };
    }, []);
    const renderProductList = () => {
        return renderTable ? (
            <ProductListGrid
                renderToastr={renderToastr}
                alertPrompt={alertPrompt}
                setShowTableState={setShowTableState}
                dbObj={dbObj}
                onClickToExcel={onClickToExcel}
                paginate={paginate}
                toExcelPending={marketProductsForReport['pending']}
                updatePaginate={updatePaginate}
                showPublish={showPublishButton}
                duplicate={marketProducts}
                updateMarketProduct={updateMarketProduct}
                publishProductChanges={publishProductChanges}
                primaryColorHex={primaryColorHex}
                showPanel={showPanel}
                selectedManageColumns={selectedManageColumns}
                showPublishProduct={showPublishProduct}
                isSelfOperated={isSelfOperated}
                currency={currency}
            />
        ) : null;
    };

    const updatePaginate = (offset: number) => {
        dispatch(resetProductsSettingsPanel());
        dispatch(FetchMarketProducts(offset));
    };
    const updateMarketProduct = (data: any) => {
        if (data && data.length > 0) {
            if (data[0].minStock > data[0].maxStock) {
                renderToastr(Hotel365Portal.MIN_SHOULD_NOT_BE_GREATER_MSG, "danger");
                return false;
            }
            if (data[0].minStock === 0 && data[0].maxStock > 0) {
                renderToastr(Hotel365Portal.MAX_SHOULD_NOT_BE_SET_MSG, "danger");
                return false;
            }
            dispatch(resetProductsSettingsPanel());
            dispatch(resetMarketProducts());
            dispatch(PatchMarketProduct(data));
            dispatch(setShowPublishProduct(true))
        } else renderToastr(GLOBAL_NO_CHANGES_TO_SAVE, "warning");
    };

    const publishProductChanges = () => {
        const request = {
            locationId
        }
        dispatch(postPublishProduct(request));
    }

    const onClickToExcel = () => {
        if (!toExcelPending && toExcelError === null) {
            const records = getProductsDataForExcel(toExcelItems as []);
            const reportHeaders = () => {
                return [[`${"Products"} - ${marketName} - ${currentDate} ${currentTime}`]];
            }
            records.length && toExcel(records, reportHeaders(), dashboardLabels.PRODUCTS_LABEL.toLowerCase(), "A2", false, dashboardLabels.PRODUCTS_LABEL.toLowerCase());
        }
    }

    useEffect(() => {
        dispatch(fetchMarketProductsForReport());
    }, [dispatch])

    return (
        <IonPage>
            <IonRow className="h-100">
                {showPanel && (
                    <IonCol
                        sizeLg="2.3"
                        sizeMd="4"
                        sizeSm="1.5"
                        sizeXs="14"
                        className="padding-0"
                    >
                        <ProductsSettingsPanel
                            navigationFlow={navigationFlow}
                            SetPanelShowStatus={SetPanelShowStatus}
                        />
                    </IonCol>
                )}
                <IonCol
                    size={showPanel ? "9.7" : "12"}
                    sizeLg={showPanel ? "9.7" : "12"}
                    sizeMd={showPanel ? "8" : "12"}
                    className="show-pannel"
                >
                    {(pending || settingsPanelPending || !renderTable) && (
                        <IonSpinner
                            name="circles"
                            className="spinner-circles"
                        />
                    )}
                    {!pending && !settingsPanelPending && renderTable && (
                        <>
                            <IonGrid>
                                <IonRow className="top">
                                    <IonCol size="12" sizeMd="12" sizeSm="6" className="to-excel-product">
                                        <IonIcon
                                            className="side-nav-icon click-cursor"
                                            onClick={() =>
                                                SetPanelShowStatus((ps) => !ps)
                                            }
                                            md={
                                                showPanel
                                                    ? chevronBackCircleOutline
                                                    : chevronForwardCircleOutline
                                            }
                                        />
                                        <IonText className="header-text">
                                            {PRODUCT_LIST}
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                            {products && renderProductList()}
                        </>
                    )}
                </IonCol>
            </IonRow>
        </IonPage>
    );
};

export default ProductListPage;