import * as EMAILUPDATE from '../../ActionTypes/emailUpdateTypes';
import {FETCH_SYSTEMUSERS_SUCCESS} from "../../ActionTypes/systemUsersTypes";
import { all, put, take, takeLatest } from "redux-saga/effects";
import { doPost } from '../../../utils/fetchWrapper';
import {
    PostEmailUpdate,
    PostEmailUpdateSuccess,
    PostEmailUpdateFailure,
} from "../../actions/emailUpdateActions";
import { Routes } from '../../../constants/Routes';
import { fetchSystemUsersRequest } from '../../actions/systemUsersActions';

function* postEmailUpdate(action: any) {
    const { systemUserId, data } = action
    const url = `${Routes.systemUserActions.emailUpdate}/${systemUserId}/email-update`;
    const request = { ...data };
    try {
        // @ts-ignore
        const response = yield doPost(url, request);
        yield put(PostEmailUpdateSuccess(response));
    } catch (error: any) {
        yield put(PostEmailUpdateFailure(error));
    }
}

function* emailUpdateFlow(action: any) {
    const { systemUserId, data: actionData } = action
    yield put(PostEmailUpdate(systemUserId, actionData));
    const { data } = yield take([EMAILUPDATE.POST_EMAIL_UPDATE_SUCCESS]);
    yield put(fetchSystemUsersRequest(systemUserId));
    const { data:sysResponse } = yield take([FETCH_SYSTEMUSERS_SUCCESS]);
}

function* EmailUpdateSaga() {
    yield all([
        takeLatest(EMAILUPDATE.EMAIL_UPDATE_FLOW, emailUpdateFlow),
        takeLatest(EMAILUPDATE.POST_EMAIL_UPDATE_REQUEST, postEmailUpdate),
    ])
}

export default EmailUpdateSaga;