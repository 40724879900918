export const navigateWithNoLoc={
        "location":false,
        "onBoard":false,
        "addProducts":false,
    }


export const navigateWithLoc={
        "location":true,
        "onBoard":false,
        "addProducts":false,
    }

export const navigateWithOnboard={
        "location":true, 
        "onBoard":true,
        "addProducts":false,
    }

export const navigateWithProducts={
        "location":true,
        "onBoard":false,
        "addProducts":true,
    }
