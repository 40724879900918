import React, { useEffect, useState } from "react";
import { IonSpinner } from "@ionic/react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { postVerifyTokenRequest } from "../../_redux/actions/systemUsersActions";
import { RootState } from "../../_redux/reducers/rootReducer";
import Login from "../Login";
import TokenExpired from "../../components/CreatePassword/TokenExpired";
import { Routes } from "../../constants/Routes";
import { Hotel365Portal, USER_MOD_FORM_INVAILD_EMAIL_ERR } from "../../constants/constant";
import { validateEmail } from "../../utils/FormValidations"; 
import { 
    resendInviteUser,
} from "../../_redux/actions/userManagementActions";
import ToastrHook from "../../components/common/toastr/Toastr";

export const VerifyPage: React.FC<any> = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [renderToastr] = ToastrHook();
    const useQuery = new URLSearchParams(useLocation().search);
    const tokenIdData = useQuery.get("token");
    const createPassword = useSelector((state: RootState) => {
        return state.createPassword;
    }, shallowEqual);
    const sendVerificationEmail = useSelector(
        (state: RootState) => state.UserManagement,
        shallowEqual
    );
    const [verifyEmail, setVerifyEmail] = useState({
        newEmail: "",
    });
    const [formErrors, setFormErrors] = useState({});
    const { pending, errors, email } = createPassword;
    const { error: resendEmailError, resendUserManagement } =
        sendVerificationEmail;

    const verifyEmailTokenExpired = () => { 
        const errorEmail = validateEmail(verifyEmail.newEmail);
        const isErrorEmail = Object.keys(errorEmail).length;
        setFormErrors({ ...errorEmail });
        if (isErrorEmail === 0) {
            dispatch(resendInviteUser(verifyEmail.newEmail));
        }
    }; 
    const renderComponent = () => {
        return (
            <>
                {errors ? (
                    <TokenExpired
                        verifyEmailTokenExpired={verifyEmailTokenExpired}
                        setEmail={setVerifyEmail}
                        verifyEmailNewInviteErrorMessage={
                            Hotel365Portal.LINK_EXPIRED_ERR
                        }
                        newEmail={verifyEmail.newEmail}
                        formErrors={formErrors}
                    />
                ) : (
                    <Login verifiedEmail={email} />
                )}
            </>
        );
    };

    useEffect(() => {
        setVerifyEmail({ newEmail: "" });
        if (tokenIdData) {
            dispatch(postVerifyTokenRequest(tokenIdData));
        }
    }, []);
    useEffect(() => {
        if (resendEmailError && resendEmailError.status === 404) {
            renderToastr(USER_MOD_FORM_INVAILD_EMAIL_ERR, "danger");
        }
        if (
            !resendEmailError &&
            resendUserManagement &&
            Object.keys(resendUserManagement).length > 0
        ) {
            history.push({ pathname: Routes.login.url });
            renderToastr(Hotel365Portal.newLinkUpdated, "success");
        }
    }, [sendVerificationEmail]);

    return (
        <>
            {pending ? (
                <IonSpinner name="circles" className="spinner-circles" />
            ) : (
                renderComponent()
            )}
        </>
    );
};
