export const POST_EMAIL_UPDATE_REQUEST = 'POST_EMAIL_UPDATE_REQUEST';
export const POST_EMAIL_UPDATE_SUCCESS = 'POST_EMAIL_UPDATE_SUCCESS';
export const POST_EMAIL_UPDATE_FAILURE = 'POST_EMAIL_UPDATE_FAILURE';

export type POST_EMAIL_UPDATE_REQUEST = typeof POST_EMAIL_UPDATE_REQUEST;
export type POST_EMAIL_UPDATE_SUCCESS = typeof POST_EMAIL_UPDATE_SUCCESS;
export type POST_EMAIL_UPDATE_FAILURE = typeof POST_EMAIL_UPDATE_FAILURE;

export const EMAIL_UPDATE_FLOW = 'EMAIL_UPDATE_FLOW';
export type EMAIL_UPDATE_FLOW = typeof EMAIL_UPDATE_FLOW;

 