import { all, put, takeLatest } from "redux-saga/effects";
import { doGet } from '../../../utils/fetchWrapper';
import {
    FetchLocationsSuccess,
    FetchLocationsFailure,
    FetchLocationsBySysUserIdSuccess,
    FetchLocationsBySysUserIdFailure
} from "../../actions/locationsActions";
import * as LOCATIONS from '../../ActionTypes/locationsTypes';
import { Routes } from '../../../constants/Routes';

function* fetchLocations() {
    try {
        const url = `${Routes.locations.url}`;
        // @ts-ignore
        const response = yield doGet(url);
        const { items } = response;
        yield put(FetchLocationsSuccess([...items]));
    } catch (e: any) {
        yield put(FetchLocationsFailure({ error: e }))
    }
}

function* fetchLocationsBySysUserId(action: any) {
    const { systemUserId } = action
    try {
        const url = `${Routes.locations.url}/system-user?systemUserId=${systemUserId}&type=hotel`;
        const { items } = yield doGet(url);
        yield put(FetchLocationsBySysUserIdSuccess({ systemUserId, locations:[...items] }));
    } catch (e: any) {
        yield put(FetchLocationsBySysUserIdFailure({ error: e }));
    }
}

function* LocationsSaga() {
    yield all([
        takeLatest(LOCATIONS.FETCH_LOCATIONS_REQUEST, fetchLocations),
        takeLatest(LOCATIONS.FETCH_LOCATIONS_BY_SYSUSERID_REQUEST, fetchLocationsBySysUserId),
    ])
}

export default LocationsSaga;