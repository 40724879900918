// vendors
import { useCallback, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
    IonApp,
    IonNav,
    IonSplitPane,
    IonRouterOutlet,
    setupIonicReact, 
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { App as CapApp } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
// components
import Onboarding from "./pages/Onboarding/Onboarding";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import SuccessPage from "./pages/SuccessPage/SuccessPage";
import ForgotPasswordPage from "./components/PasswordUpdate/ForgotPasswordPage";
import CreatePasswordContainer from "./pages/CreatePassword/CreatePasswordContainer";
import theme from "./components/Theme/Index";
import TokenExpired from "./components/CreatePassword/TokenExpired";
import { ResetPasswordContainer } from "./pages/ResetPassword/resetPasswordContainer";
import { VerifyPage } from "./pages/VerifyEmail/verifyEmail";
import ChangeEnvironment from "./pages/ChangeEnvironment";
 // constants
import { Routes } from "./constants/Routes";
import { Hotel365Portal } from './constants/constant'
import { callbackUri, audience, scope,  } from "./auth.config";
// actions
import { afterLoginFlow } from "./_redux/actions/systemUsersActions";
// reducers
import { RootState } from "./_redux/reducers/rootReducer";
// utils
import envChange from "./utils/changeEnvironment";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./styles/variables.scss";
import "./App.scss";
import "./styles/styles.scss";
import { Fallback } from './pages/FallBack/Fallback'; 

setupIonicReact({
    mode: "md",
});

const App: React.FC = () => {
    const {
        handleRedirectCallback, 
        user, 
        logout,
        isAuthenticated,
        getAccessTokenSilently
    } = useAuth0();
    const dispatch = useDispatch();
    const envConfig = envChange();
    const profile = useSelector((state: RootState) => { return state.profile; }, shallowEqual);
    const { marketTheme, pending: currentProfilePending } = profile && profile.currentProfile;
    const { primaryColorHex } = marketTheme;
    const primaryColor = primaryColorHex ? `${primaryColorHex}` : Hotel365Portal.defaultPrimaryColor;
    const cssProperties: Record<string, string> = {
        "--ion-color-primary": `${primaryColor}`,
        "--ion-color-primary-rgb": "137,107,177",
        "--ion-color-primary-contrast": "#ffffff",
        "--ion-color-primary-contrast-rgb": "255,255,255",
        "--ion-color-primary-shade": "#795e9c",
        "--ion-color-primary-tint": "#957ab9",
        "--background-activated": `${primaryColor}`,
        ...theme,
    };
    let filterLocalStorageDomain: string[];
    let authorizationObject: any;

    const doLogout = useCallback(async () => {
        logout({
            logoutParams: {
                returnTo: callbackUri,
            }
        });
    }, [])

    
  
    function setTheme() {
        Object.keys(cssProperties).forEach((key) => {
            document.documentElement.style.setProperty(key, cssProperties[key]);
        });
    }
    /**Fetch env from env.txt config file */
    useEffect(() => {
        fetch("/assets/config/env.txt")
            .then((response) => response.text())
            .then((text) => {
                /**Change it to "local" if running in localhost */
                if (!localStorage.getItem("env")) {
                    envChange(text);
                    localStorage.setItem("env", JSON.stringify(text));
                }
            })
            .catch((error) => console.log(error));
    },[]);

    const getRefreshToken = useCallback(async () => {
        try {
            const accessToken = await getAccessTokenSilently({
                cacheMode: 'off',
                authorizationParams: {
                    audience: audience,
                    scope: scope
                }
            });
        } catch (e: any) {
            console.log(e.message);
        }
    }, []);
    
    useEffect(() => {
        // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
        CapApp.addListener("appUrlOpen", async ({ url }) => {
            if (url.includes("state") && (url.includes("code") || url.includes("error"))) { await handleRedirectCallback(url); }
            // No-op on Android
            await Browser.close();
        });

        const interval = setInterval(() => { 
            if (isAuthenticated) { 
                filterLocalStorageDomain = Object.keys(localStorage).filter(
                    (item) => {
                        if (item.includes((envConfig as any)[envConfig.environment].REACT_APP_API as string)) {
                            return item;
                        }
                    }
                );
                authorizationObject = JSON.parse(localStorage.getItem(filterLocalStorageDomain[0]) as string);
                const { expiresAt } = authorizationObject || {};
                const expirySeconds = parseInt(expiresAt, 10);
                const refreshTokenExpiryTime = expirySeconds * 1000;
                if (new Date().getTime() >= refreshTokenExpiryTime) {
                    console.log("calling refresh token");
                    getRefreshToken();
                }
            }
        }, 5000);
        const systemUserId = user && user["https://365rm.us/systemUserId"];
        systemUserId && dispatch(afterLoginFlow(systemUserId));
        return () => clearInterval(interval);
    }, [
        handleRedirectCallback,
        isAuthenticated,
        authorizationObject,
        authorizationObject?.expiresAt,
        user,
    ]); 

    useEffect(() => {
        if (primaryColor) { setTheme(); }
    });

    useEffect(() => {
        if (localStorage.getItem("env")) {
            const getEnv = JSON.parse(localStorage.getItem("env") as string);
            envChange(getEnv);
        }
    }, [localStorage.getItem("env")]);
    return (
        <IonApp>
            <IonNav swipeGesture={true}></IonNav>            
             <IonReactRouter>
                    <IonSplitPane contentId="all-routes" when={false}>
                        <IonRouterOutlet id="all-routes">
                            <Route
                                exact
                                path={Routes.login.url}
                                component={Login}
                            />
                            <Route
                                path={Routes.success.url}
                                component={SuccessPage}
                            />
                            <Route
                                exact
                                path={Routes.forgotPassword.url}
                                component={ForgotPasswordPage}
                            />
                            <Route
                                exact
                                path={Routes.createPassword.url}
                                component={CreatePasswordContainer}
                            />
                            <Route
                                exact
                                path={Routes.verifyEmail.url}
                                component={VerifyPage}
                            />
                            <Route
                                exact
                                path={Routes.changeEnvironment.url}
                                component={ChangeEnvironment}
                            />
                            <Route
                                exact
                                path={Routes.resetPassword.url}
                                component={ResetPasswordContainer}
                            />
                            <Route
                                exact
                                path={Routes.inviteExpired.url}
                                component={TokenExpired}
                            />
                            <Route
                                exact
                                path={Routes.onboarding.url}
                                component={() => <Onboarding doLogout={doLogout} />}
                            />
                            <Route
                                exact
                                path={`${Routes.dashboard.url}/:id`}
                                component={() => <Dashboard doLogout={doLogout} currentProfilePending={currentProfilePending} />}
                            />
                            <Route
                                exact
                                path={`${Routes.dashboard.url}`}
                                component={() => <Dashboard doLogout={doLogout} currentProfilePending={currentProfilePending} />}
                            />
                            <Route render={() => <Fallback doLogout={doLogout} />} />
                            <Redirect exact from="/" to={Routes.login.url} />
                        </IonRouterOutlet>
                    </IonSplitPane>
                </IonReactRouter>           
        </IonApp>
    );
};

export default App;

