import { IonButton } from "@ionic/react";
import * as React from "react";
import { useReactToPrint } from "react-to-print";
//constants
import { PRINT, POS_PRINT_RECEIPT_HEADER } from '../../constants/constant';
import { PrintReceipt } from "./PrintReceipt";
export const PrintReceiptButton = (props: any) => {
    const componentRef = React.useRef<React.MutableRefObject<any>>(null);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current as any;
    }, [componentRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: POS_PRINT_RECEIPT_HEADER,
        removeAfterPrint: true,
        copyStyles: false,
    });

    return (
        <div>
            <IonButton
                expand="full"
                type="button"
                className="btn-text-cases"
                onClick={handlePrint}
            >
                {PRINT}
            </IonButton>
            <PrintReceipt ref={componentRef} printItemDetails={props.recieptItemDetails} printCartDetails={props.cartDetails} currency={props.currency} />

        </div>
    );
};
