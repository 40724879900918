// vendors
import React from "react";
import { FooterProps } from "../models/FooterProps";
import { IonButton, IonCol, IonRow, IonText, isPlatform } from "@ionic/react";
import { useTranslation } from 'react-i18next';
import { FOOTER_SAVE_BUTTON, ORDER_TOTAL_LABEL, SUBMIT_UNAVAILABLE_STATUS } from '../constants/constant';

const FooterComponent: React.FC<FooterProps> = ({
    submitCallBack,
    backButtonHandler,
    showOrderTotal = "",
    backButtonLabel = '',
    isBackButton = false,
    classReverseFlex = '',
    disableButton = false,
    showOrderSection = true,
    showPreventOrderBanner = false,
    buttonLabel = FOOTER_SAVE_BUTTON,
    footerRowClassName = 'footer-payment',
    footerColClassName = 'payment-footer-col',
    footerRowMobileClassName = 'global-mobile-footer-btn',
}) => {
    const isMobileView = isPlatform("mobile");
    const isDesktopView = isPlatform("desktop")
    const isTabletView = isPlatform("tablet");

    return (
        <IonRow className={isMobileView ? `${footerRowMobileClassName} ion-no-padding` : `${footerRowClassName} ion-no-padding`}>
            <IonCol size="12" className={`${showPreventOrderBanner ? `${footerColClassName} prevent-inventory-banner-content` : footerColClassName} ion-no-padding`} >
                <IonRow className={`${classReverseFlex} ion-no-padding`}>
                    {showPreventOrderBanner ? (
                        <IonCol className="prevent-inventory-banner-content">
                            <IonText className={isMobileView ? "order-submit-mobile" : "order-float-right"}>
                                {SUBMIT_UNAVAILABLE_STATUS}
                            </IonText>
                        </IonCol>
                    ) :

                        <>{showOrderSection && showOrderTotal && (
                            <IonCol>
                                <IonText className={isMobileView ? "total-expense-mobile" : "total-expense-content"}>
                                    {`${ORDER_TOTAL_LABEL} ${showOrderTotal}`}
                                </IonText>
                            </IonCol>
                        )}
                            {showOrderSection && (
                                <IonCol className="ion-no-padding">
                                    <IonButton
                                        className={isDesktopView ? "square-buttons" : 'square-buttons-mobile'}
                                        style={{ marginRight: 0 }}
                                        color="primary"
                                        disabled={disableButton}
                                        onClick={() => submitCallBack()}>
                                        {buttonLabel}
                                    </IonButton>
                                </IonCol>
                            )}
                        </>
                    }
                    {isBackButton && (
                        <IonCol className="ion-no-padding">
                            <IonButton
                                color="primary"
                                className={isDesktopView ? "square-buttons" : 'square-buttons-mobile'}
                                disabled={disableButton}
                                onClick={backButtonHandler}>{backButtonLabel}
                            </IonButton>
                        </IonCol>
                    )}
                </IonRow>
            </IonCol>
        </IonRow>
    );
};

export default FooterComponent;