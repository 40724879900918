import React from "react";
import {
    IonButton,
    IonCol,
    IonGrid,
    IonRow,
    IonSpinner,
    isPlatform,
    IonText,
} from "@ionic/react";
import {
    EnterKeyBehaviorAfterEdit,
    EnterKeyBehaviors,
    IgrComboBoxColumn,
    IgrDataGrid,
    IgrDataGridModule,
    IgrDataGridCellEventArgs,
    IgrGridCellValueChangingEventArgs,
    IgrGridColumnOptionsModule,
    IgrGridDataCommittingEventArgs,
    IgrTemplateCellInfo,
    IgrTemplateColumn,
    IgrTextColumn,
    IIgrCellTemplateProps,
} from "igniteui-react-grids";

import { ProductDetails } from "../../../components/Product/ProductDetails/Index";
import AddProductComponent from "./AddProductComponent";
import ManageColumnsButton from "./ManageColumns/ManageColumnsButton";
// import mapper from "./mapping.json";
import { mapper, PRODUCT_BELOW_MIN } from "../../../constants/constant";

import { DataGridPager } from "./ProductGrid/DataGridPager";
import RouteLeavingGuard from "../../../components/RouteLeavingGuard";
import { withRouter } from "react-router-dom";
import {
    Hotel365Portal,
    toExcelProductsHeaders,
    TO_EXCEL,
    MARKET_PRODUCT_SWAP,
    MARKET_PRODUCT_SWAPPPED,
    PRODUCT_BELOW_MAX,
    ORDER_ADD_PRODUCT_TEXT,
    MANAGE_PRODUCTS,
    COMMIT_CHANGES,
    DISCARD,
    PUBLISH_PRODUCT_CHANGES,
    ORDER_ADD_NEW_PRODUCTS,
    ADD_NEW_PRODUCT
} from "../../../constants/constant";
import getSymbolFromCurrencyCode from "../../../i18n/currency-symbol-map";
import { getLocalizedNumericValues } from "../../../utils";

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
const isDesktop = isPlatform("desktop");
const istablet = isPlatform("tablet");
export class DataGridColumnChooserToolbar extends React.Component<any, any> {
    public data: any[];
    public grid: IgrDataGrid | undefined;
    private originalColList: any;

    constructor(props: any) {
        super(props);
        this.onGridRef = this.onGridRef.bind(this);
        this.originalColList = { ...mapper };
        this.data = this.props.dbObj.getProductList(this.props.currency);
        this.state = {
            colNameMapping: { ...this.props.dbObj.columnMappingList },
            showPublish: false,
            selectedCols: [...this.props.dbObj.getColumnList()],
            canMoveAfterEdit: true,
            current: "",
            componentVisible: true,
            enterBehavior: EnterKeyBehaviors.Edit,
            enterBehaviorAfterEdit: EnterKeyBehaviorAfterEdit.MoveDown,
            updatedData: this.data,
            showTaxes: this.props.dbObj.showTaxes,
            paginate: this.props.paginate,
            colsSelected: this.props.selectedManageColumns,
            selectedProduct: null,
            showDetailModal: false,
            isDiscarded: false,
        };
    }
    public onGridRef(grid: IgrDataGrid) {
        this.grid = grid;
    }

    checkStatus = (status: string) => {
        status = status.toLocaleLowerCase();
        if (status === Hotel365Portal.productStatusNormal) return MARKET_PRODUCT_SWAP;
        else if (status === Hotel365Portal.productStatusSwapped) return MARKET_PRODUCT_SWAPPPED;
        else {
            return status.toUpperCase();
        }
    };

    custom(props: IIgrCellTemplateProps, field: string) {
        const info = props.dataContext as IgrTemplateCellInfo;
        const data = info.rowItem[field];
        const status = this.checkStatus(info.rowItem["status"]);
        return (
            <>
                <span className="market-product-name">{data}</span>
                <IonButton
                    size="small"
                    strong
                    className={
                        status.toLowerCase() === "discontinued"
                            ? "float-right discontinued"
                            : "float-right"
                    }
                    color={
                        status.toLowerCase() === "discontinued" ? "" : "primary"
                    }
                    fill="clear"
                >
                    {status}
                </IonButton>
            </>
        );
    }
    checkColumnHidden = (i: string) => {
        return this.state.colsSelected.length > 0
            ? this.state.colsSelected.indexOf(i) > -1
                ? true
                : false
            : false;
    };
    selectedColumnsTextColor = (i: string) => {
        return [
            "deposit",
            // "tax",
            // "tax2",
            // "tax3",
            // "tax4",
            "minStock",
            "maxStock",
            "category1",
            "refillAction",
        ].indexOf(i) > -1
            ? this.props.primaryColorHex || Hotel365Portal.defaultPrimaryColor
            : Hotel365Portal.gridTextValueColorCode;
    };

    checkWidth = (i: string) => {
        if (["category1"].includes(i)) {
            return "*>200";
        } else if (["deposit"].indexOf(i) > -1)
            // } else if (["tax", "tax2", "tax3", "tax4", "deposit"].indexOf(i) > -1)
            return "*>130";
    };
    checkEditableField = (i: string) => {
        return [
            "deposit",
            // "tax",
            // "tax2",
            // "tax3",
            // "tax4",
            "minStock",
            "maxStock",
        ].indexOf(i) > -1
            ? true
            : false;
    };

    renderRefillAction = (i: string) => {
        return (
            <IgrComboBoxColumn
                field={i}
                key={i}
                headerText={this.originalColList[i] || ""}
                isHidden={this.checkColumnHidden(i)}
                dataSource={[
                    { key: PRODUCT_BELOW_MAX, value: "BELOW_MAXIMUM" },
                    {
                        key: PRODUCT_BELOW_MIN,
                        value: "AT_OR_BELOW_MINIMUM",
                    },
                ]}
                textField={"key"}
                valueField={"value"}
                width="*>200"
                textColor={this.selectedColumnsTextColor(i)}
            />
        );
    };

    renderOtherColumns = (i: string) => {
        return (
            <IgrTextColumn
                field={i}
                key={i}
                horizontalAlignment={i == "scancode" ? "left" : "center"}
                width={this.checkWidth(i)}
                headerText={this.originalColList[i] || i}
                isFilteringEnabled={false}
                isEditable={this.checkEditableField(i)}
                isHidden={this.checkColumnHidden(i)}
                isColumnOptionsSummariesEnabled={false}
                textColor={this.selectedColumnsTextColor(i)}
                name={i}
            />
        );
    };
    renderCols = (data: any) => {
        const arr = [];
        const { colNameMapping } = this.state;

        for (let i in colNameMapping) {
            // @ts-ignore
            i !== "productId" &&
                arr.push(
                    ["name"].indexOf(i) > -1 ? (
                        <IgrTemplateColumn
                            field={i}
                            key={i}
                            horizontalAlignment="center"
                            width={"*>350"}
                            headerText={this.originalColList[i] || ""}
                            template={(props: IIgrCellTemplateProps) =>
                                this.custom(props, i)
                            }
                            isFilteringEnabled={false}
                            isHidden={this.checkColumnHidden(i)}
                            isEditable={this.checkEditableField(i)}
                            isColumnOptionsSummariesEnabled={false}
                            name={i}
                        />
                    ) : ["refillAction"].indexOf(i) > -1 ? (
                        this.renderRefillAction(i)
                    ) : (
                        this.renderOtherColumns(i)
                        )
                );
        }
        return arr;
    };
    dismiss = (state: boolean) => this.props.setShowTableState(!state);
    selectCols = (selectedItems: string[] = []) => {
        if (selectedItems) {
            this.setState({ colsSelected: selectedItems });
        }
        if (selectedItems.length === 0) {
            this.setState({ showTaxes: false });
        } else if (
            JSON.stringify(selectedItems.sort()) ===
            JSON.stringify(["deposit"].sort())
            // JSON.stringify(["deposit", "tax", "tax2", "tax3", "tax4"].sort())
        ) {
            this.setState({ showTaxes: true });
        }
    };
    componentDidUpdate(previousProps: any, previousState: any) {
        const dataSource = previousProps.dbObj.productList;
        const changes = this.findModifiedProduct(
            dataSource,
            this.props.duplicate
        );

        const removed = this.props.duplicate.filter(
            (_originalMarketProduct: any) =>
                !dataSource.find(
                    (_gridData: any) =>
                        _originalMarketProduct.productId === _gridData.productId
                )
        );
        if (previousState.showTaxes !== this.state.showTaxes) {
            this.setState({ showTaxes: this.state.showTaxes });
        }
        if (previousProps.showPublish !== this.props.showPublish) {
            this.setState({ showPublish: this.props.showPublish });
        }
        if (
            previousProps.dbObj.productList.length !==
            this.props.dbObj.productList.length ||
            previousState.isDiscarded !== this.state.isDiscarded ||
            (changes && changes.length > 0) ||
            (removed && removed.length > 0)
        ) {
            this.data = this.props.dbObj.getProductList(this.props.currency);
            this.setState({
                colNameMapping: { ...this.props.dbObj.columnMappingList },
                showPublish: false,
                selectedCols: [...this.props.dbObj.getColumnList()],
                canMoveAfterEdit: true,
                current: "",
                componentVisible: true,
                enterBehavior: EnterKeyBehaviors.Edit,
                enterBehaviorAfterEdit: EnterKeyBehaviorAfterEdit.MoveDown,
                updatedData: this.data,
                showTaxes: this.props.dbObj.showTaxes,
                paginate: this.props.paginate,
                colsSelected: this.props.selectedManageColumns,
                selectedProduct: null,
                showDetailModal: false,
                isDiscarded: false,
            });
        }
    }
    onHeaderButtonClick(e: any) {
        this.setState({ current: e.target.innerText });
        const taxes = e.target.innerText.toLowerCase();
        if (taxes === "hide taxes") {
            this.setState({ showTaxes: true }, () => {
                this.props.dbObj.toggleTaxMode();
                this.setState((prevState: any) => {
                    const filteredTaxes = prevState.colsSelected.concat([
                        "deposit",
                        // "tax",
                        // "tax2",
                        // "tax3",
                        // "tax4",
                    ]);
                    return {
                        ...prevState,
                        colsSelected: filteredTaxes,
                    };
                });
            });
        } else if (taxes === "view taxes") {
            this.setState({ showTaxes: false }, () => {
                this.props.dbObj.toggleTaxMode();
                this.setState((prevState: any) => {
                    const filteredTaxes = prevState.colsSelected.filter(
                        (column: string) =>
                            // ["deposit", "tax", "tax2", "tax3", "tax4"].indexOf(
                            ["deposit"].indexOf(
                                column
                            ) === -1
                    );
                    return {
                        ...prevState,
                        colsSelected: filteredTaxes,
                    };
                });
            });
        }
    }

    proceedHandler() {
        this.setState({ showPublish: true });
    }

    updateTwoDecimal = (num: number) => {
        const result = num.toFixed(2);
        return result.toString();
    };

    addZeroes = (num: number) => {
        var numberAsString = num.toString();
        if (
            numberAsString.indexOf(".") === -1 ||
            numberAsString.split(".")[1].length < 3
        ) {
            numberAsString = this.updateTwoDecimal(num);
        }
        return numberAsString;
    };

    updateColumnValue = (event: any) => {
        return ["tax", "tax2", "tax3", "tax4"].indexOf(event.column.name) >
            -1 && event.newValue
            ? event.newValue + "%"
            : ["cost", "deposit"].indexOf(event.column.name) > -1 &&
                event.newValue
                ? getLocalizedNumericValues(event.newValue, "currency", this.props.currency)
                : event.newValue;
    };

    onCellChanged(gridData: any, event: any) {
        console.log(
            "event",
            gridData,
            event,
            event.column.name,
            event._implementation._item[event.column.name]
        );
        const test = this.addZeroes(parseFloat(event._implementation.e));
        if (
            ["cost", "deposit"].indexOf(event.column.name) > -1 &&
            !isNaN(Number(test))
        ) {
            event.newValue = getLocalizedNumericValues(event._implementation.e, "currency", this.props.currency);
        }
        // else if (
        //     ["tax", "tax2", "tax3", "tax4"].indexOf(event.column.name) > -1 &&
        //     !isNaN(Number(test))
        // ) {
        //     event.newValue =
        //         this.addZeroes(parseFloat(event._implementation.e)) + "%";
        // }
        return event;
    }

    hideShowPublish = (gridData: any, event: any) => {
        this.setState({ showPublish: false });
        gridData?.rejectEdit(event.editID);
    };

    onCellValueChanging = async (gridData: any, event: any) => {
        console.log(
            "cell value changing event",
            gridData,
            event,
            event.editID,
            event.newValue
        );
        event = await this.onCellChanged(gridData, event);
        event.newValue =
            typeof event.newValue === "string" &&
                event.newValue.indexOf(getSymbolFromCurrencyCode(this.props.currency)) > -1
                ? event.newValue.replace(getSymbolFromCurrencyCode(this.props.currency), "")
                : event.newValue;
        event.newValue =
            typeof event.newValue === "string" &&
                event.newValue.indexOf("%") > -1
                ? event.newValue.replace("%", "")
                : event.newValue;
        var pattern = /^\d{1,2}(\.\d{1,2})?$/;
        if (
            ["cost", "deposit"].indexOf(
                // ["cost", "deposit", "tax", "tax2", "tax3", "tax4"].indexOf(
                event.column.name
            ) > -1 &&
            (event.newValue < 0 || !pattern.test(Number(event.newValue) as any))
        ) {
            this.hideShowPublish(gridData, event);
        } else if (
            ["minStock", "maxStock"].indexOf(event.column.name) > -1 &&
            (event.newValue < 0 || !/^\d+$/.test(Number(event.newValue) as any))
        ) {
            this.hideShowPublish(gridData, event);
        } else {
            gridData?.acceptEdit(event.editID);
            gridData?.commitEdits();
            event._implementation._item[event.column.name] =
                this.updateColumnValue(event);
            this.setState({ showPublish: true });
        }
    };
    onPageChanged = (offset: number) => {
        this.props.updatePaginate(offset);
    };
    selectedRow = (gridData: any, event: IgrDataGridCellEventArgs) => {
        if (gridData._implementation.dm?.c === "name") {
            this.setState({ selectedProduct: event.cellInfo.rowItem }, () => {
                this.setState({ showDetailModal: true });
            });
        }
    };
    onDataCommit = (s: IgrDataGrid, e: IgrGridDataCommittingEventArgs) => {
        s.acceptCommit(e.commitID);
    };
    getDataSourceArray = () => {
        let array = [];
        array = this.grid?.dataSource.map((datum: any) => {
            Object.keys(datum).forEach((key) => {
                if (datum[key] === "") {
                    datum[key] = null;
                }
            });
            return datum;
        });
        return array;
    };

    findModifiedProduct = (gridData: any, duplicateData: any) => {
        let modifiedProduct: any[] = [];
        gridData?.map((gridItem: any) => {
            duplicateData.map((originalItem: any) => {
                if (
                    gridItem.productLocationId ===
                    originalItem.productLocationId
                ) {
                    const deposit = typeof (gridItem.deposit) === 'string' ? gridItem?.deposit?.slice(1) : gridItem?.deposit
                    if (
                        gridItem.maxStock != originalItem.maxStock ||
                        gridItem.minStock != originalItem.minStock ||
                        Number(deposit) != originalItem.deposit ||
                        gridItem.calculatedPrice != originalItem.calculatedPrice ||
                        // gridItem.tax != originalItem.tax ||
                        // gridItem.tax2 != originalItem.tax2 ||
                        // gridItem.tax3 != originalItem.tax3 ||
                        // gridItem.tax4 != originalItem.tax4 ||
                        gridItem.refillAction != originalItem.refillAction ||
                        gridItem.status != originalItem.status
                    ) {
                        modifiedProduct.push(gridItem);
                    }
                }
            });
        });
        return modifiedProduct;
    };

    checkGridChanges = () => {
        const dataSource = this.getDataSourceArray();
        const changes = this.findModifiedProduct(
            dataSource,
            this.props.duplicate
        );
        return changes;
    };

    commitChanges = () => {
        const dataSource = this.getDataSourceArray();
        const changes = this.findModifiedProduct(
            dataSource,
            this.props.duplicate
        );
        let finalReqObject: any[] = [];
        if (changes && changes.length > 0) {
            finalReqObject = changes.map((datum: any) => {
                return {
                    productLocationId: datum?.productLocationId,
                    maxStock: datum.maxStock,
                    minStock: datum.minStock,
                    // tax: datum.tax
                    //     ? Number(datum.tax.replaceAll("%", "")) / 100
                    //     : datum.tax,
                    // tax2: datum.tax2
                    //     ? Number(datum.tax2.replaceAll("%", "")) / 100
                    //     : datum.tax2,
                    // tax3: datum.tax3
                    //     ? Number(datum.tax3.replaceAll("%", "")) / 100
                    //     : datum.tax3,
                    // tax4: datum.tax4
                    //     ? Number(datum.tax4.replaceAll("%", "")) / 100
                    //     : datum.tax4,
                    cost: datum.cost
                        ? Number(datum.cost.replaceAll(getSymbolFromCurrencyCode(this.props.currency), ""))
                        : datum.cost,
                    deposit: datum.deposit
                        ? Number(datum.deposit.replaceAll(getSymbolFromCurrencyCode(this.props.currency), ""))
                        : datum.price,
                    refillAction: datum.refillAction,
                };
            });
        }
        this.grid?.flush();
        this.props.updateMarketProduct(finalReqObject);
    };
    triggerCompilePrice = () => {
        this.props.publishProductChanges();
    };
    public render(): JSX.Element {
        const { history, match, showPanel } = this.props;
        return (
            <React.Fragment>
                <IonRow>
                    <IonCol size={"12"} className="grid-buttons">
                        <IonRow color="secondary">
                            <IonCol className="toolbar-container">
                                <AddProductComponent
                                    onHeaderButtonClick={(e: any) =>
                                        this.onHeaderButtonClick(e)
                                    }
                                    current={this.state.current}
                                    match={match}
                                    history={history}
                                    buttonLabel={
                                        this.props.isSelfOperated
                                            ? MANAGE_PRODUCTS
                                            : ORDER_ADD_PRODUCT_TEXT
                                    }
                                    buttonClassName="grid-btns"
                                />
                                {this.props.isSelfOperated && (
                                    <AddProductComponent
                                        onHeaderButtonClick={(e: any) =>
                                            this.onHeaderButtonClick(e)
                                        }
                                        current={this.state.current}
                                        match={match}
                                        history={history}
                                        buttonLabel={ADD_NEW_PRODUCT}
                                        buttonClassName="grid-btns"
                                        isSelfOperated={
                                            this.props.isSelfOperated
                                        }
                                    />
                                )}
                                <IonRow
                                    className={
                                        isDesktop 
                                            ? "commit-discard-btn" : (istablet && !showPanel) ? "commit-discard-btn-tablet"
                                            : "discard-grid-container"
                                    }
                                >
                                    <IonCol>
                                        {this.state.updatedData.length > 0 &&
                                            this.state.showPublish && (
                                                <React.Fragment>
                                                    <IonButton
                                                        size="small"
                                                        color={
                                                            this.state.current ===
                                                                "COMMIT CHANGES"
                                                                ? "primary"
                                                                : undefined
                                                        }
                                                        fill={
                                                            this.state.current ===
                                                                "COMMIT CHANGES"
                                                                ? "solid"
                                                                : undefined
                                                        }
                                                        className={
                                                            isDesktop || !istablet
                                                                ? "grid-btns"
                                                                : "grid-btns-tab"
                                                        }
                                                        onClick={this.commitChanges}
                                                    >
                                                        {COMMIT_CHANGES}
                                                    </IonButton>
                                                    <IonButton
                                                        size="small"
                                                        color={
                                                            this.state.current ===
                                                                "DISCARD"
                                                                ? "primary"
                                                                : undefined
                                                        }
                                                        fill={
                                                            this.state.current ===
                                                                "DISCARD"
                                                                ? "solid"
                                                                : undefined
                                                        }
                                                        className={
                                                            isDesktop || !istablet
                                                                ? "grid-btns"
                                                                : "grid-btns-tab"
                                                        }
                                                        onClick={() =>
                                                            this.setState({
                                                                isDiscarded: true,
                                                            })
                                                        }
                                                    >
                                                        {DISCARD}
                                                    </IonButton>
                                                </React.Fragment>
                                            )}
                                        {this.state.updatedData.length > 0 && (
                                            <ManageColumnsButton
                                                onHeaderButtonClick={(e: any) =>
                                                    this.onHeaderButtonClick(e)
                                                }
                                                current={this.state.current}
                                                selectCols={this.selectCols}
                                                colNameMapping={
                                                    this.state.colNameMapping
                                                }
                                                selectedCols={
                                                    this.state.selectedCols
                                                }
                                                preselectedColumn={
                                                    this.state.colsSelected
                                                }
                                            />
                                        )}
                                        {this.props.toExcelPending ? (
                                            <IonSpinner
                                                name="circles"
                                                className="spinner-toExcel"
                                            />
                                        ) : (
                                                <IonButton
                                                    fill="solid"
                                                    className={
                                                        isDesktop
                                                            ? "product-to-excel"
                                                            : istablet
                                                                ? "product-to-excel-tab"
                                                                : "product-to-excel-mob"
                                                    }
                                                    onClick={() => {
                                                        this.props.onClickToExcel();
                                                    }}
                                                    disabled={this.state.updatedData.length === 0}
                                                >
                                                    {TO_EXCEL}
                                                </IonButton>
                                            )}
                                    </IonCol>
                                </IonRow>

                                {this.state.updatedData.length > 0 &&
                                    this.props.showPublishProduct && (
                                        <>
                                            <IonButton
                                                size="small"
                                                color={"danger"}
                                                fill={"solid"}
                                                className="product-publish"
                                                onClick={
                                                    this.triggerCompilePrice
                                                }
                                            >
                                                {PUBLISH_PRODUCT_CHANGES}
                                            </IonButton>
                                        </>
                                    )}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
                {
                    this.state.updatedData.length > 0 && (
                        <div
                            className="container sample product-list-grid-section"
                            key="market-product-list"
                        >
                            <IonRow
                                key="grid-container"
                                className="consumer-row-container"
                            >
                                <IonCol className="grid-column-height">
                                    <IgrDataGrid
                                        ref={this.onGridRef}
                                        height="calc(100% - 155px)"
                                        width="100%"
                                        defaultColumnMinWidth={100}
                                        autoGenerateColumns={false}
                                        dataSource={this.state.updatedData}
                                        selectionMode="SingleCell"
                                        activationMode="Cell"
                                        editMode="CellBatch"
                                        cellValueChanging={(
                                            s: IgrDataGrid,
                                            e: IgrGridCellValueChangingEventArgs
                                        ) => this.onCellValueChanging(s, e)}
                                        cellClicked={(
                                            s: IgrDataGrid,
                                            e: IgrDataGridCellEventArgs
                                        ) => this.selectedRow(s, e)}
                                        dataCommitting={(
                                            s: IgrDataGrid,
                                            e: IgrGridDataCommittingEventArgs
                                        ) => this.onDataCommit(s, e)}
                                        isColumnOptionsEnabled="true"
                                        headerBackground="#635D5D"
                                        headerTextColor="#ffffff"
                                        autoAcceptEdits={false}
                                        headerTextStyle="Normal Normal 12px Roboto"
                                        cellTextStyle="Normal Normal 14px Roboto"
                                        columnMovingMode={"NONE"}
                                    >
                                        {this.renderCols(this.data)}
                                    </IgrDataGrid>
                                </IonCol>
                            </IonRow>

                            <DataGridPager
                                dataSource={this.state.updatedData}
                                pageSize={this.state.paginate.limit}
                                totalPageCount={this.state.paginate.pageCount}
                                currentPage={this.state.paginate.currentPage}
                                pagedChanged={this.onPageChanged}
                            />
                        </div>
                    )
                }
                {
                    this.state.updatedData.length === 0 && (
                        <IonRow>
                            <IonCol
                                className={
                                    isDesktop || istablet
                                        ? "no-product"
                                        : "no-products-mobile"
                                }
                            >
                                <IonText>{ORDER_ADD_NEW_PRODUCTS}</IonText>
                            </IonCol>
                        </IonRow>
                    )
                }

                {
                    this.state.selectedProduct && (
                        <ProductDetails
                            showDetailModal={this.state.showDetailModal}
                            selectedProduct={this.state.selectedProduct}
                            closeModal={() =>
                                this.setState({
                                    showDetailModal: false,
                                })
                            }
                        />
                    )
                }

                <RouteLeavingGuard
                    when={this.checkGridChanges()?.length > 0}
                    navigate={(path: string) => {
                        this.setState({ isDiscarded: true }, () => {
                            history.push(path);
                        });
                    }}
                    shouldBlockNavigation={() => {
                        if (this.checkGridChanges()?.length > 0) {
                            return true;
                        }
                        return false;
                    }}
                />
            </React.Fragment >
        );
    }
}
export default withRouter(DataGridColumnChooserToolbar);