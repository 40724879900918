//vendors
import React, { useEffect, useState } from "react";
import { IonGrid, IonPage, IonRouterOutlet } from "@ionic/react";
import { Route, useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
//reducers
import { RootState } from '../../_redux/reducers/rootReducer';
//constants
import { Routes } from "../../constants/Routes";
import { ADD_CARD_FAILURE, CARD_ADDED_SUCCESS } from "./constants"
//components
import AvailableCards from "./AvailableCards";
import PaymentForm from './PaymentForm';
import ToastrHook from "../../components/common/toastr/Toastr";
import PaymentDetail from "./PaymentDetail";

export const Payment: React.FC<any> = () => {
    const history = useHistory();
    const [renderToastr] = ToastrHook();
    const [isAddCardClicked, setIsAddCardClicked] = useState(false);
    const [errObject, setErrObject] = useState({ message: '', type: '' });
    const userProfile = useSelector((state: RootState) => { return state.profile; }, shallowEqual);
    const systemUser = useSelector((state: RootState) => { return state.systemUsers; }, shallowEqual);
    const payment = useSelector((state: RootState) => { return state.payment; }, shallowEqual);
    const { pending: addCardPending, error: addCardErr } = payment;
    const { systemUserId } = systemUser;
    const { currentProfile } = userProfile;
    const { locationId, orgId } = currentProfile;

    const redirectToDetails = (systemUserTokenId: string) => {
        history.push(`${Routes.dashboard.paymentDetails}/${systemUserTokenId}`)
    }
    //to show toaster messages for success and failure of add card
    useEffect(() => {
        if (isAddCardClicked && !addCardPending && addCardErr === null) {
            renderToastr(CARD_ADDED_SUCCESS, 'success', 5000);
            history.push(`${Routes.dashboard.paymentUrl}`);
        } else if (isAddCardClicked && !addCardPending && addCardErr !== null) {
            addCardErr?.type !== 'NON_API_CALL' && renderToastr(ADD_CARD_FAILURE, 'danger', 5000);
            setIsAddCardClicked(false);
            setErrObject(addCardErr);
        }
    }, [addCardPending, addCardErr]);

    return (
        <IonPage>
            <IonRouterOutlet className="ion-no-padding">
                <Route
                    exact
                    path={`${Routes.dashboard.paymentUrl}`}
                    render={() => <AvailableCards redirectToDetails={redirectToDetails} />}
                />
                <Route
                    exact
                    path={`${Routes.dashboard.paymentUrl}/card-details/:systemUserTokenId`}
                    render={() => <PaymentDetail systemUserId={systemUserId} locationId={locationId} orgId={orgId} />}
                />
                <Route
                    exact
                    path={`${Routes.dashboard.paymentUrl}/add-card`}
                    render={() => <PaymentForm isAddCardClicked={isAddCardClicked} setIsAddCardClicked={setIsAddCardClicked} errObject={errObject} setErrObject={setErrObject}/>}
                />
            </IonRouterOutlet>
        </IonPage>
    );
};

export default Payment;