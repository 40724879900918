//vendors
import React, { useState, useEffect } from "react";
import {
    IonCheckbox,
    IonCol,
    IonContent,
    IonIcon,
    IonInput,
    IonNote,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    isPlatform,
} from "@ionic/react";
import {
    chevronBackCircleOutline,
    chevronForwardCircleOutline,
} from "ionicons/icons";
//css
import "./AdditionalInfoFrom.scss";
import "../../scss/SettingForms.scss";
// interfaces
import { AdditinalInfoProps } from "../../../../models/AdditionalInfoProps";
//constants
import {
    SETTING_ADDITIONAL_INFO,
    ADDITIONAL_TIME_BETWEEN_INVENTORY,
    ADDITIONAL_PREVENT_ORD_INV_TAKEN,
    ADDITIONAL_INVENTORY,
    ADDITIONAL_OFF,
    ADDITIONAL_1W,
    ADDITIONAL_2W,
    ADDITIONAL_3W,
    ADDITIONAL_4W,
    ADDITIONAL_BADGE_REQUIRED,
    ADDITIONAL_CLOSE_AT,
    ADDITIONAL_DELIVERY,
    ADDITIONAL_GUARD_ACCESS,
    ADDITIONAL_HRS_OF_OPERATION,
    ADDITIONAL_OPEN_AT,
    ADDITIONAL_SPECIAL_INSTR_LBL,
    ADDITIONAL_SPECIAL_INSTR_MSG,
} from '../../../../constants/constant';

const AdditionalInfoForm: React.FC<AdditinalInfoProps> = ({
    setOnboarding,
    sidenavExpanded,
    setSidenavExpanded,
    additionalDetails,
}) => {
    const {
        hasGuardAccess,
        isBadgeRequired,
        hasPreventOrdersUntilInventoryTaken,
        deliveryHoursOfOperationClose,
        deliveryHoursOfOperationOpen,
        timeBetweenInventories,
        specialInstructions,
    } = additionalDetails;
    const [displayInstruction, setDisplayInstruction] = useState<boolean>(hasGuardAccess || isBadgeRequired);
    const isDesktopView = isPlatform("desktop");
    const isTabletView = isPlatform("tablet");
    const isMobileView = isPlatform("mobile");
    const toggleAccordion = () => { setSidenavExpanded(!sidenavExpanded) };
    useEffect(() => {
        hasGuardAccess || isBadgeRequired
            ? setDisplayInstruction(true)
            : setDisplayInstruction(false);
    }, [hasGuardAccess, isBadgeRequired]);
    return (
        <IonContent className="scroll-content">
            <IonRow>
                <IonCol>
                    <IonRow className="ion-padding">
                        <IonCol onClick={toggleAccordion} style={{ display: 'flex' }}>
                            <IonIcon
                                size="large"
                                className="settings-back-icon click-cursor"
                                md={sidenavExpanded ? chevronBackCircleOutline : chevronForwardCircleOutline} />
                            <h2 className="header-text"><b>{SETTING_ADDITIONAL_INFO}</b></h2>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-start">
                        <IonCol>
                            <IonRow><h5 style={{ margin: '0' }}><b className="font18">{ADDITIONAL_INVENTORY}</b></h5></IonRow>
                            <IonRow>
                                <IonNote color="medium" className="sub-header-label-font">
                                    {ADDITIONAL_TIME_BETWEEN_INVENTORY}
                                </IonNote>
                            </IonRow>
                            <IonRow>
                                <IonSelect
                                    aria-label={ADDITIONAL_TIME_BETWEEN_INVENTORY}
                                    interface="popover"
                                    className="setting-form-field setting-text-input-size"
                                    name="timeBtwInventiories"
                                    style={{ width: "200px" }}
                                    value={timeBetweenInventories}
                                    onIonChange={(e) => {
                                        setOnboarding((prevState: any) => {
                                            return {
                                                ...prevState,
                                                additionalDetails: {
                                                    ...prevState.additionalDetails,
                                                    timeBetweenInventories: e.detail.value,
                                                },
                                            };
                                        });
                                    }}>
                                    <IonSelectOption value='off' aria-label={ADDITIONAL_OFF}>{ADDITIONAL_OFF}</IonSelectOption>
                                    <IonSelectOption value="1 Week" aria-label={ADDITIONAL_1W}>{ADDITIONAL_1W}</IonSelectOption>
                                    <IonSelectOption value="2 Week" aria-label={ADDITIONAL_2W} >{ADDITIONAL_2W}</IonSelectOption>
                                    <IonSelectOption value="3 Week" aria-label={ADDITIONAL_3W}>{ADDITIONAL_3W}</IonSelectOption>
                                    <IonSelectOption value="4 Week" aria-label={ADDITIONAL_4W}>{ADDITIONAL_4W}</IonSelectOption>
                                </IonSelect>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    {(timeBetweenInventories && timeBetweenInventories !== 'off') && (
                        <IonRow className="ion-padding-start ion-margin-top">
                            <IonCol sizeLg={sidenavExpanded ? '0.5' : '0.4'} sizeMd={sidenavExpanded ? '0.8' : '0.6'} sizeXs='1' >
                                <IonCheckbox
                                    className="inventory-checkbox-size"
                                    labelPlacement="start"
                                    checked={hasPreventOrdersUntilInventoryTaken}
                                    onIonChange={(e) => {
                                        setOnboarding((prevState: any) => {
                                            return {
                                                ...prevState,
                                                additionalDetails: {
                                                    ...prevState.additionalDetails,
                                                    hasPreventOrdersUntilInventoryTaken: e.detail.checked,
                                                },
                                            };
                                        });
                                    }}
                                />
                            </IonCol>
                            <IonCol className={isMobileView && !isTabletView ? 'ion-padding-start prevent-order-mobile' : 'prevent-order-tablet'} sizeMd={sidenavExpanded ? '7' : '9'}>
                                <IonNote color="medium">{ADDITIONAL_PREVENT_ORD_INV_TAKEN}</IonNote>
                            </IonCol>
                        </IonRow>
                    )}
                    <IonRow className="ion-padding-start">
                        <IonCol><h5><b>{ADDITIONAL_DELIVERY}</b></h5></IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-start">
                        <IonCol><b>{ADDITIONAL_HRS_OF_OPERATION}</b></IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'>
                        <IonCol className='ion-padding-start' sizeLg={sidenavExpanded ? '2' : '1.6'} sizeMd={sidenavExpanded ? '3.3' : '2.7'} sizeXs='12'>
                            <IonNote color="medium" className="sub-header-label-font">{ADDITIONAL_OPEN_AT}</IonNote>
                            <IonInput
                                aria-label={ADDITIONAL_OPEN_AT}
                                type="time"
                                className="setting-form-field time-picker-start setting-text-input-size"
                                value={deliveryHoursOfOperationOpen}
                                onIonChange={(e) => {
                                    setOnboarding((prevState: any) => {
                                        return {
                                            ...prevState,
                                            additionalDetails: {
                                                ...prevState.additionalDetails,
                                                deliveryHoursOfOperationOpen: e.detail.value,
                                            },
                                        };
                                    });
                                }} />
                        </IonCol>
                        <IonCol sizeLg={sidenavExpanded ? '1.6' : '1.4'} sizeMd={sidenavExpanded ? '2.8' : '2'} sizeXs='12' className={isMobileView && !isTabletView ? 'ion-padding-start ion-margin-top' : ''}>
                            <IonNote color="medium" className="sub-header-label-font">{ADDITIONAL_CLOSE_AT}</IonNote>
                            <IonInput
                                aria-label={ADDITIONAL_CLOSE_AT}
                                type="time"
                                className="setting-form-field time-picker-start setting-text-input-size"
                                value={deliveryHoursOfOperationClose}
                                onIonChange={(e) => {
                                    setOnboarding((prevState: any) => {
                                        return {
                                            ...prevState,
                                            additionalDetails: {
                                                ...prevState.additionalDetails,
                                                deliveryHoursOfOperationClose: e.detail.value,
                                            },
                                        };
                                    });
                                }} />
                        </IonCol>
                        <IonCol className={isDesktopView ? 'ion-margin-top' : isMobileView ? 'ion-padding-start ion-margin-top' : ''} sizeLg={sidenavExpanded ? '0.5' : '0.4'} sizeMd={sidenavExpanded ? '1.2' : '0.9'} sizeXs='2'>
                            <IonCheckbox
                                className="inventory-checkbox-size"
                                labelPlacement="end"
                                checked={hasGuardAccess}
                                name="guardAccess"
                                onIonChange={(e) => {
                                    setOnboarding((prevState: any) => {
                                        return {
                                            ...prevState,
                                            additionalDetails: {
                                                ...prevState.additionalDetails,
                                                hasGuardAccess: e.detail.checked,
                                                specialInstructions: e.detail.checked ? ADDITIONAL_SPECIAL_INSTR_MSG : ''
                                            },
                                        };
                                    });
                                }}>
                            </IonCheckbox>
                        </IonCol>
                        <IonCol className='ion-margin-top guard-access-font' sizeLg={sidenavExpanded ? '1.5' : '1.2'} sizeMd={sidenavExpanded ? '1.5' : '2'} sizeXs='9'>
                            {ADDITIONAL_GUARD_ACCESS}
                        </IonCol>
                        <IonCol className={isDesktopView ? 'ion-margin-top' : isMobileView ? 'ion-padding-start ion-margin-top' : ''} sizeLg={sidenavExpanded ? '0.5' : '0.4'} sizeMd={sidenavExpanded ? '1.2' : '0.9'} sizeXs='2'>
                            <IonCheckbox
                                className="inventory-checkbox-size"
                                labelPlacement="end"
                                checked={isBadgeRequired}
                                onIonChange={(e) => {
                                    setOnboarding((prevState: any) => {
                                        return {
                                            ...prevState,
                                            additionalDetails: {
                                                ...prevState.additionalDetails,
                                                isBadgeRequired: e.detail.checked,
                                                specialInstructions: e.detail.checked ? ADDITIONAL_SPECIAL_INSTR_MSG : ''
                                            },
                                        };
                                    });
                                }}>

                            </IonCheckbox>
                        </IonCol>
                        <IonCol className='ion-margin-top guard-access-font'>
                            {ADDITIONAL_BADGE_REQUIRED}
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-padding-start ion-padding-top'>
                        <IonCol><IonNote color="medium" className="sub-header-label-font">{ADDITIONAL_SPECIAL_INSTR_LBL}</IonNote></IonCol>
                    </IonRow>
                    <IonRow className='ion-padding-start ion-padding-end'>
                        <IonTextarea
                            className="ion-input-size"
                            aria-label={ADDITIONAL_SPECIAL_INSTR_LBL}
                            maxlength={255}
                            value={specialInstructions}
                            onIonChange={(e) => {
                                setOnboarding((prevState: any) => {
                                    return {
                                        ...prevState,
                                        additionalDetails: {
                                            ...prevState.additionalDetails,
                                            specialInstructions: displayInstruction ? e.detail.value : "",
                                        },
                                    };
                                });
                            }}

                        />  
                    </IonRow>
                </IonCol>
            </IonRow>
        </IonContent>
    );
};
export default AdditionalInfoForm;