import React, { useState, useEffect } from "react";
import type { ToggleButtonProps } from "../../../models/ToggleSwitchProps";
import "./ToggleSwitch.scss";

const toggleTrue = Math.floor(Math.random() * 100000);
const toggleFalse = Math.floor(Math.random() * 100000);

const ToggleButton: React.FC<ToggleButtonProps> = ({
    id,
    name,
    checked = false,
    onChange,
    disabled = false,
    optionLabels,
    
}) => {
    const [localChecked, setLocalChecked] = useState(checked);
    useEffect(() => {
        setLocalChecked(checked);
    }, [checked]);

    return (
        <div className={"switch-service-info toggle-border-box clearfix"}>
            {/** this for true */}
            <input
                type="radio"
                name={name}
                className="toggle-switch-checkbox custom1"
                id={id + toggleTrue}
                checked={localChecked}
                onChange={() => {
                    onChange(true);
                    setLocalChecked(checked);
                }}
            />
            <label
                className="switch__label"
                tabIndex={disabled ? -1 : 1}
                htmlFor={id + toggleTrue}
            >
                {optionLabels[0]}
            </label>
            {/** this for false */}
            <input
                type="radio"
                name={name}
                className="toggle-switch-checkbox custom2"
                id={id + toggleFalse}
                checked={!localChecked}
                onChange={() => {
                    onChange(false);
                    setLocalChecked(checked);
                }}
            />
            <label
                className="switch__label"
                tabIndex={disabled ? -1 : 1}
                htmlFor={id + toggleFalse}
            >
                {optionLabels[1]}
            </label>
            <div className="switch__indicator new_switch_label" />
        </div>
    );
};

ToggleButton.defaultProps = {
    optionLabels: ["Yes", "No"],
};
export default ToggleButton;
