export type FETCH_SYSTEMUSERS_REQUEST = typeof FETCH_SYSTEMUSERS_REQUEST;
export type FETCH_SYSTEMUSERS_SUCCESS = typeof FETCH_SYSTEMUSERS_SUCCESS;
export type FETCH_SYSTEMUSERS_FAILURE = typeof FETCH_SYSTEMUSERS_FAILURE;

export type AFTER_LOGIN_FLOW = typeof AFTER_LOGIN_FLOW;
export type AFTER_LOGIN_FLOW_CP = typeof AFTER_LOGIN_FLOW_CP;

export const FETCH_SYSTEMUSERS_REQUEST = 'FETCH_SYSTEMUSERS_REQUEST';
export const FETCH_SYSTEMUSERS_SUCCESS = 'FETCH_SYSTEMUSERS_SUCCESS';
export const FETCH_SYSTEMUSERS_FAILURE = 'FETCH_SYSTEMUSERS_FAILURE';

export const AFTER_LOGIN_FLOW = 'AFTER_LOGIN_FLOW';
export const AFTER_LOGIN_FLOW_CP = 'AFTER_LOGIN_FLOW_CP';

export type PATCH_SYSTEMUSERS_REQUEST = typeof PATCH_SYSTEMUSERS_REQUEST;
export type PATCH_SYSTEMUSERS_SUCCESS = typeof PATCH_SYSTEMUSERS_SUCCESS;
export type PATCH_SYSTEMUSERS_FAILURE = typeof PATCH_SYSTEMUSERS_FAILURE;

export const PATCH_SYSTEMUSERS_REQUEST = 'PATCH_SYSTEMUSERS_REQUEST';
export const PATCH_SYSTEMUSERS_SUCCESS = 'PATCH_SYSTEMUSERS_SUCCESS';
export const PATCH_SYSTEMUSERS_FAILURE = 'PATCH_SYSTEMUSERS_FAILURE';

export type AFTER_LOCATION_CHANGE = typeof AFTER_LOCATION_CHANGE;
export const AFTER_LOCATION_CHANGE = 'AFTER_LOCATION_CHANGE';
 
export type POST_VERIFYTOKEN_REQUEST = typeof POST_VERIFYTOKEN_REQUEST;
export type POST_VERIFYTOKEN_SUCCESS = typeof POST_VERIFYTOKEN_SUCCESS;
export type POST_VERIFYTOKEN_FAILURE = typeof POST_VERIFYTOKEN_FAILURE;

export const POST_VERIFYTOKEN_REQUEST = 'POST_VERIFYTOKEN_REQUEST';
export const POST_VERIFYTOKEN_SUCCESS = 'POST_VERIFYTOKEN_SUCCESS';
export const POST_VERIFYTOKEN_FAILURE = 'POST_VERIFYTOKEN_FAILURE';

export type PUT_CREATE_PASSWORD_REQUEST = typeof PUT_CREATE_PASSWORD_REQUEST;
export type PUT_CREATE_PASSWORD_SUCCESS = typeof PUT_CREATE_PASSWORD_SUCCESS;
export type PUT_CREATE_PASSWORD_FAILURE = typeof PUT_CREATE_PASSWORD_FAILURE;

export const PUT_CREATE_PASSWORD_REQUEST = 'PUT_CREATE_PASSWORD_REQUEST';
export const PUT_CREATE_PASSWORD_SUCCESS = 'PUT_CREATE_PASSWORD_SUCCESS';
export const PUT_CREATE_PASSWORD_FAILURE = 'PUT_CREATE_PASSWORD_FAILURE';

export type IS_LOGGED_IN_USER_VALID = typeof IS_LOGGED_IN_USER_VALID;
export const IS_LOGGED_IN_USER_VALID = 'IS_LOGGED_IN_USER_VALID';