//vendors
import React from "react";
import { IonButton, IonCol, IonRow, isPlatform } from "@ionic/react";
import { useHistory } from "react-router";
//constants
import { Routes } from "../../constants/Routes";
import { paymentConstants } from './constants';

const AddPaymentButton: React.FC<any> = () => {
    const history = useHistory();
    const isdesktop = isPlatform('desktop');
    const ismobile = isPlatform('mobile');
    return (
        <IonRow className={isdesktop ? "payment-background" : ''}>
            <IonCol>
                <IonButton
                    className={ismobile ? 'btn-full-width' : ''}
                    onClick={() => { history.push(`${Routes.dashboard.paymentUrl}/add-card`) }}>
                    {paymentConstants.ADD_CARD}
                </IonButton>
            </IonCol>
        </IonRow>
    );
};
export default AddPaymentButton;
