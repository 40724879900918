import React, { useCallback, useEffect, useState } from "react";
import {
    IonCol,
    IonIcon,
    IonItem,
    IonList,
    IonPage,
    IonRow,
    IonSearchbar,
    IonSpinner,
    IonText,
    isPlatform,
    SearchbarCustomEvent,
} from "@ionic/react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { RootState } from "../../_redux/reducers/rootReducer";
import {
    chevronBackCircleOutline,
    chevronForwardCircleOutline,
} from "ionicons/icons";
//constants
import {
    Hotel365Portal,
    GLOBAL_POS_HEADING,
    POS_CANNOT_ADD_SAME_PRODUCT_TO_CART,
    POS_NO_PRODUCT_FOUND,
    POS_ORDER_PLACED_SUCCESS_TOST,
    POS_PRODUCT_ADDED_TO_CART_TOST,
    POS_PRODUCT_REMOVED_SUCCESS_TOST,
    POS_SCANCODE_NOT_FOUND,
    POS_SEARCH_ITEM,
    GLOBAL_SEARCH_PLACEHOLDER
} from "../../constants/constant";
import ToastrHook from "../../components/common/toastr/Toastr";
import Checkout from "../../components/Checkout/Checkout";
//actions
import { fetchSearchProduct, postGenerateCart as postGenerateCartAction, resetPOS } from "../../_redux/actions/posActions";

export const POS: React.FC<any> = () => {
    const dispatch = useDispatch();
    const [renderToastr] = ToastrHook();
    const location = useLocation();
    const isDesktopView = isPlatform("desktop");
    const isTabletView = isPlatform("tablet" || "phablet" || "ipad");
    const isMobileView = isPlatform("mobile" || "iphone");
    const [sidenavExpanded, setSidenavExpanded] = useState<boolean>(false);
    const {
        error,
        pending,
        posSearchedProducts,
        postGenerateCart,
        postCheckoutCart,
        isCheckoutSuccess
    } = useSelector((state: RootState) => state.pos, shallowEqual);
    const [searchText, setSearchText] = useState("")
    const [productList, setProductList] = useState(posSearchedProducts);
    const profile = useSelector((state: RootState) => {
        return state.profile;
    }, shallowEqual);
    const { locationId: locationID, currency } = profile && profile.currentProfile;
    useEffect(() => {
        setProductList(posSearchedProducts);
    }, [posSearchedProducts])

    const checkPostCheckoutCart = useCallback(() => {
        return postCheckoutCart && Object.keys(postCheckoutCart).length > 0;
    }, [postCheckoutCart]);

    useEffect(() => {
        const { isProductAddOrRemove = '' } = postGenerateCart
        if (!error && isProductAddOrRemove === 'Added') {
            renderToastr(POS_PRODUCT_ADDED_TO_CART_TOST, "success");
            setSidenavExpanded(true);
            setSearchText("");
            setProductList([])
        }
        if (!error && isProductAddOrRemove === 'Removed') {
            renderToastr(POS_PRODUCT_REMOVED_SUCCESS_TOST, "success");
        }

      !error &&
      isCheckoutSuccess &&
            renderToastr(POS_ORDER_PLACED_SUCCESS_TOST, "success");

        let errorMessage = "";
        if (error && Object.keys(error).length > 0) {
            if (error.status === 401) {
                errorMessage = Hotel365Portal.NOT_AUTHORIZED_ERR;
            } else if (error.status === 422) {
                errorMessage = POS_SCANCODE_NOT_FOUND;
            } else {
                errorMessage = Hotel365Portal.SERVICE_UNAVAILABLE_ERR;
            }
            renderToastr(errorMessage, "danger");
        }
    }, [postGenerateCart,  setSidenavExpanded, error, postCheckoutCart, isCheckoutSuccess])
    
    const handleChange = (ev: SearchbarCustomEvent) => {
        //setProductList([])
        let query = "";
        const target = ev.detail;
        if (target) query = target.value!.toLowerCase();
        if (query.length > 2) {
            setSearchText(query)
            dispatch(fetchSearchProduct({
                query
            }, locationID));
        } else {
            setSearchText(query)
            setProductList([])
        }
    };

    const handleClear = () => {
        setProductList([])
        setSearchText('')
    }

    const selectProduct = (selectedBarCode: string) => {
        const isDuplicate = !checkPostCheckoutCart() && postGenerateCart?.salesItems?.filter((item) => item.barcode === selectedBarCode)
        let cartItems = !checkPostCheckoutCart() && postGenerateCart?.salesItems && postGenerateCart?.salesItems.length > 0 ? postGenerateCart?.salesItems.map(item => ({ 'barcode': item.barcode })) : []
        cartItems.push({ 'barcode': selectedBarCode as any })
        if (!isDuplicate || isDuplicate?.length === 0) {
            const request = {
                //"accountId": "29e65b294020083d63e0f577e7137c57",
                "cartItems": cartItems,
                "locationId": locationID
            }
            dispatch(postGenerateCartAction(request))
        } else {
            renderToastr(POS_CANNOT_ADD_SAME_PRODUCT_TO_CART, "warning");
            return false;
        }
    }

    useEffect(() => {
        setSidenavExpanded(false);
        setProductList([])
        setSearchText('')
    }, [dispatch, locationID]);

    useEffect(() => {
        // unmount the component when route changes
        return () => {
            setSidenavExpanded(false);
            setProductList([])
            setSearchText('')
        }
    }, [location.pathname])


    return (
        <IonPage>
            <IonRow className="pos-main-container"> 
                <IonCol className={sidenavExpanded && ((isMobileView && isTabletView && !isDesktopView)) ? "pos-search-col" : sidenavExpanded && (!isMobileView && !isTabletView && isDesktopView) ? "pos-search-col-desk" : "pos-search-col-sidenav-disabled"}>
                    <div className={sidenavExpanded && (isMobileView && !isTabletView && !isDesktopView) ? "pos-search-container-display" : ""}>
                        <IonIcon
                            className="pos-side-nav-icon"
                            md={chevronBackCircleOutline}
                        />
                        <IonText className="pos-header-text">
                            {GLOBAL_POS_HEADING}
                        </IonText>
                        <IonIcon
                            className="pos-side-nav-icon click-cursor float-right"
                            onClick={() =>
                                setSidenavExpanded((ps) => !ps)
                            }
                             md={
                                sidenavExpanded
                                    ? chevronBackCircleOutline
                                    : chevronForwardCircleOutline
                            }
                        />
                        <div className={isDesktopView || isTabletView ? "search-container-div" : "search-container-div-mobile"} >
                            <h1 className="search-page-desc">
                                <b>{POS_SEARCH_ITEM}</b>
                            </h1>
                            {/* <IonText className="pos-search-sub-text">
                                        Search
                                    </IonText> */}
                            <IonSearchbar
                                color="light" 
                                onIonInput={handleChange}
                                value={searchText}
                                debounce={500}
                                onIonClear={handleClear}
                                placeholder={GLOBAL_SEARCH_PLACEHOLDER}
                            ></IonSearchbar> 
                            {pending && (
                                <IonSpinner
                                    name="circles"
                                    className="spinner-circles"
                                />
                            )} 
                            <div className="search-list-ion-items">
                                {!pending && productList.length === 0 && searchText.length > 2 && (
                                    <h6 className="search-no-products-span">
                                        {POS_NO_PRODUCT_FOUND}
                                    </h6>
                                )}
                                {!pending &&
                                    productList.length > 0 &&
                                    productList.map((res: any, i: number) => (
                                        <IonItem
                                            key={res.barcode}
                                            lines="full"
                                            onClick={() => {
                                                selectProduct(res.barcode)
                                            }}
                                            className="pointer"
                                        >
                                            <IonList key={res.barcode}>
                                                {res.productName}
                                            </IonList> 
                                        </IonItem>
                                    ))}
                            </div>
                        </div>
                    </div> 
                </IonCol>
                {sidenavExpanded && 
                        <div className={sidenavExpanded && (isMobileView && !isTabletView && !isDesktopView) ? "pos-checkout-col-mobile" : sidenavExpanded && (isMobileView && isTabletView && !isDesktopView) ?  "pos-checkout-col" : "pos-checkout-col-desk"}>
                        <Checkout
                            setSidenavExpanded={setSidenavExpanded}
                            sidenavExpanded={sidenavExpanded}
                            setProductList={setProductList}
                            setSearchText={setSearchText}
                            currency={currency}
                        />
                        </div> 
                } 
            </IonRow> 
        </IonPage> 
      

    );
};
export default POS;
