import { useIonToast } from '@ionic/react';

const ToastrHook = () => {
    const [present, dismiss] = useIonToast();
    // As per SOS-42333, we are changing the time from 3 sec to 5 sec.
    const renderToastr = (toastrMessage: string, type: string = 'primary', duration: number = 3000) => (
        present({
            buttons: [{ text: 'X', handler: () => dismiss() }],
            position: "top",
            duration,
            color: type,
            animated: true,
            message: toastrMessage,
            cssClass: "toast-container",
            onDidDismiss: () => console.log('dismissed'),
            onWillDismiss: () => console.log('will dismiss'),
        })
    )
    return [renderToastr]
};
export default ToastrHook

/*usage:
* const [renderToastr] = ToastrHook()
* renderToastr("Toastr custom message goes here")
*/
