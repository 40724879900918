import React from "react";
import { IonCol, IonLabel, IonNote, IonRow } from "@ionic/react";

export const NoPaymentMethod: React.FC<any> = () => {
    return (
        <React.Fragment>
            <IonRow>
                <IonCol>
                    <IonNote className="consumer-no-pymnt-header">
                        No Payment Method
                    </IonNote>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonNote className="consumer-no-pymnt-description">
                        In order to add funds, please go to Payment and add a
                        payment method.
                    </IonNote>
                </IonCol>
            </IonRow>
        </React.Fragment>
    );
};
export default NoPaymentMethod;
