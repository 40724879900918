import React from "react";
import {
    IonCol,
    IonInput,
    IonLabel,
    IonRow,
} from "@ionic/react";

import { ClientPortal } from "../../../constants/constant";
import { ConsumerActionsDescriberData } from "../../../models/ConsumerActions";
import { restrictAlphaAndSplChar } from "../../../utils";

export function titleDescriptor(): string {
    return ClientPortal.bulkPinReset;
}

export function contentDescriptor(
    props: ConsumerActionsDescriberData
): JSX.Element {
    const { resetPIN, updateResetPin, result } = props;
    return (
        <React.Fragment>
            <IonRow>
                <IonCol>
                    {result && !result.isValid && (
                        <span className="validation-error-label">*</span>
                    )}
                    <IonLabel className="input-headers">Enter New PIN</IonLabel>
                    <IonInput
                        className="consumer-action-textbox"
                        maxlength={4}
                        value={resetPIN}
                        placeholder={ClientPortal.enterPin}
                        onIonChange={(e) => updateResetPin(e.detail.value! as string)}
                        onKeyPress={(e) => {
                            restrictAlphaAndSplChar(e);
                        }}
                    />
                    {result && !result.isValid && (
                        <span className="global-red">
                            {result && result.errorMessage}
                        </span>
                    )}
                </IonCol>
            </IonRow>
        </React.Fragment>
    );
}
export default {
    titleDescriptor,
    contentDescriptor,
};
