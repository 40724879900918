import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";

import {
    IonAccordion,
    IonAccordionGroup,
    IonCheckbox,
    IonCol,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
    IonText,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonButton,
    IonChip,
    isPlatform,
    IonContent,
    IonGrid,
} from "@ionic/react";
import { helpCircleOutline, star } from "ionicons/icons";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { ToolTip } from "../../../components/common/tooltip/tooltip";
import { updateProductSettings } from "../../../_redux/actions/productActions";
import {
    PatchGlobalMargin,
    SaveGlobalMargin,
    FetchCategoryMargin,
    FetchGlobalMargin,
    SaveCategoryMargin,
    resetProductsSettingsPanel,
    PatchCategoryMargin,
} from "../../../_redux/actions/productSettingsPanelActions";
import { RootState } from "../../../_redux/reducers/rootReducer";
import { Category } from "../../../models/CategoryMargin";
import ToastrHook from "../../../components/common/toastr/Toastr";
import {
    Hotel365Portal,
    PRODUCT_GLOBAL_MARGINS,
    PRODUCT_PLACEHOLDER_GLOBAL_MARGINS,
    PRODUCT_CATEGORY_LEVEL_MARGINS,
    PRODUCT_LEVEL_MARGINS,
    RODUCT_ROUND_NINES_MSG,
    RODUCT_ROUND_NINES,
    // PRODUCT_TAX_RATE1_NAME,
    // PRODUCT_PLACEHOLDER_TAX_RATE,
    // PRODUCT_TAX_RATE2_NAME,
    // PRODUCT_TAX_RATE3_NAME,
    // PRODUCT_TAX_RATE4_NAME,
    FOOTER_SAVE_BUTTON,
    PRODUCT_CATEGORY_MARGINS,
    LOADING_MORE_DATA,
    GLOBAL_SETTING,
    PRODUCT_PLACEHOLDER_CATEGORY_MARGINS,
    GLOBAL_NO_CHANGES_TO_SAVE
} from "../../../constants/constant";

const globalMarginSchema = yup.object().shape({
    productGlobalMargin: yup
        .string()
        .matches(Hotel365Portal.marginRegEx, "not matches"),
});
const formSchema = {
    margin: yup
        .string()
        .nullable(true)
        .test("match with regex", "not matches", (value) => {
            const margin = value?.replace("%", "") as string;
            return Hotel365Portal.marginRegEx.test(margin);
        }),
};
const categoryMarginSchema = yup.object({
    categoryMargin: yup.array().of(yup.object().shape(formSchema)),
});
export const ProductsSettingsPanel: React.FC<any> = ({
    SetPanelShowStatus,
}) => {
    const dispatch = useDispatch();

    const [globalMargin, setGlobalMargin] = useState("0%");
    // const [taxRateName1, setTaxRateName1] = useState("");
    // const [taxRateName2, setTaxRateName2] = useState("");
    // const [taxRateName3, setTaxRateName3] = useState("");
    // const [taxRateName4, setTaxRateName4] = useState("");
    const [allowCategoryMargin, setAllowCategoryMargin] = useState(false);
    const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
    const [allowProductMargin, setAllowProductMargin] = useState(false);
    const [roundToNines, setRoundToNines] = useState(false);
    const isDesktop = isPlatform("desktop");
    const isTablet = isPlatform("tablet");
    const ismobile = isPlatform("mobile");

    const { productSettings } = useSelector(
        (state: RootState) => state.product,
        shallowEqual
    );

    const profile = useSelector((state: RootState) => {
        return state.profile;
    }, shallowEqual);
    const { locationId: locationID } = profile && profile.currentProfile;

    const {
        pending,
        globalMargin: fetchGlobalMargin,
        createdGlobalMargin,
        patchedGlobalMargin,
        categoryMargin,
        categoryMarginPaginate,
        createdCategoryMargin,
        patchedCategoryMargin,
    } = useSelector(
        (state: RootState) => state.productSettingsPanel,
        shallowEqual
    );
    const [renderToastr] = ToastrHook();

    const {
        register: categoryMarginRegister,
        control,
        handleSubmit,
        formState: { errors: categoryMarginError },
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(categoryMarginSchema),
        defaultValues: {
            categoryMargin: categoryMargin,
        },
    });
    const { fields, update } = useFieldArray({
        control,
        name: "categoryMargin",
    });

    const {
        register,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(globalMarginSchema),
    });

    useEffect(() => {
        if (fetchGlobalMargin && Object.keys(fetchGlobalMargin).length > 0) {
            setGlobalMargin(fetchGlobalMargin.margin + "%");
            setAllowCategoryMargin(
                fetchGlobalMargin.doesAllowCategoryLevelMargin
            );
            setRoundToNines(fetchGlobalMargin.shouldRoundToTheNines);
            setAllowProductMargin(
                fetchGlobalMargin.doesAllowProductLevelMargin
            );
            // setTaxRateName1(fetchGlobalMargin.taxRate1Name);
            // setTaxRateName2(fetchGlobalMargin.taxRate2Name);
            // setTaxRateName3(fetchGlobalMargin.taxRate3Name);
            // setTaxRateName4(fetchGlobalMargin.taxRate4Name);
            dispatch(
                updateProductSettings({
                    globalMargin: fetchGlobalMargin.margin + "%",
                    allowCategoryMargin:
                        fetchGlobalMargin.doesAllowCategoryLevelMargin,
                    allowProductMargin:
                        fetchGlobalMargin.doesAllowProductLevelMargin,
                    allowRoundToNines: fetchGlobalMargin.shouldRoundToTheNines,
                    // taxRate1Name: fetchGlobalMargin.taxRate1Name,
                    // taxRate2Name: fetchGlobalMargin.taxRate2Name,
                    // taxRate3Name: fetchGlobalMargin.taxRate3Name,
                    // taxRate4Name: fetchGlobalMargin.taxRate4Name,
                })
            );
        }
        if (
            categoryMargin.length !== 0 &&
            categoryMargin.length === categoryMarginPaginate.totalCount
        ) {
            setInfiniteDisabled(true);
        }
        if (
            (createdGlobalMargin &&
                Object.keys(createdGlobalMargin).length > 0) ||
            (patchedGlobalMargin && Object.keys(patchedGlobalMargin).length > 0)
        ) {
            const isCreated =
                createdGlobalMargin &&
                Object.keys(createdGlobalMargin).length > 0;
            renderToastr(
                isCreated
                    ? Hotel365Portal.GLOBAL_MARGIN_CREATION_SUCCESS
                    : Hotel365Portal.GLOBAL_MARGIN_UPDATION_SUCCESS,
                "success"
            );
            dispatch(resetProductsSettingsPanel());
            dispatch(FetchGlobalMargin(""));
        }
        if (
            (createdCategoryMargin &&
                Object.keys(createdCategoryMargin).length > 0) ||
            (patchedCategoryMargin &&
                Object.keys(patchedCategoryMargin).length > 0)
        ) {
            const isCreated =
                createdCategoryMargin &&
                Object.keys(createdCategoryMargin).length > 0;
            renderToastr(
                isCreated
                    ? Hotel365Portal.CATEGORY_MARGIN_CREATION_SUCCESS
                    : Hotel365Portal.CATEGORY_MARGIN_UPDATION_SUCCESS,
                "success"
            );
            dispatch(resetProductsSettingsPanel());
            dispatch(FetchCategoryMargin("", 0));
        }
    }, [
        fetchGlobalMargin,
        categoryMargin,
        createdGlobalMargin,
        patchedGlobalMargin,
        createdCategoryMargin,
        patchedCategoryMargin,
    ]);

    useEffect(() => {
        console.log(errors, categoryMarginError);
    }, [errors, categoryMarginError]);

    const handleScroll = (ev: any) => {
        setTimeout(() => {
            const offset: number = categoryMarginPaginate.nextOffset;
            dispatch(FetchCategoryMargin("", offset));
            ev.target.complete();
            if (categoryMargin.length === categoryMarginPaginate.totalCount) {
                setInfiniteDisabled(true);
            }
        }, 500);
    };

    const updateGlobalMargin = (data: string) => {
        setGlobalMargin(data);
        if (Hotel365Portal.marginRegEx.test(data))
            dispatch(updateProductSettings({ globalMargin: data }));
    };

    const restrictAlphabets = (
        e: any,
        type: string,
        index?: number,
        categoryMargin?: Category
    ) => {
        var code = e.nativeEvent.which || e.nativeEvent.keycode;
        if ((code >= 48 && code <= 57) || code === 45) {
            const result =
                e.target.selectionStart === 0 &&
                    e.target.selectionEnd === e.target.value.length
                    ? e.key
                    : [
                        e.target.value.slice(0, e.target.selectionStart),
                        e.key,
                        e.target.value.slice(e.target.selectionStart),
                    ].join("");
            if (type === "globalMargin")
                setTimeout(() => updateGlobalMargin(result), 100);
            else {
                setTimeout(
                    () =>
                        update(index as number, {
                            ...(categoryMargin as Category),
                            margin: result,
                        }),
                    100
                );
            }
        } else {
            e.preventDefault();
            return false;
        }
    };

    const updatePercentageSymbol = (
        e: any,
        type: string,
        index: number | null,
        categoryMargin?: Category
    ) => {
        if (type === "globalMargin") {
            setValue(
                "productGlobalMargin",
                e.target.value.replaceAll("%", ""),
                {
                    shouldValidate: true,
                }
            );
            if (
                globalMargin &&
                Hotel365Portal.marginRegEx.test(
                    globalMargin.replaceAll("%", "")
                )
            )
                setGlobalMargin(`${globalMargin.replaceAll("%", "")}%`);
        } else {
            const updatedObject = { ...categoryMargin, margin: e.target.value };
            if (
                Hotel365Portal.marginRegEx.test(
                    e.target.value.replaceAll("%", "")
                )
            ) {
                e.target.value = e.target.value.replaceAll("%", "") + "%";
                setTimeout(
                    () => update(index as number, updatedObject as Category),
                    100
                );
            }
        }
    };
    const removePercentageSymbol = (
        e: any,
        type: string,
        index: number | null,
        categoryMargin?: Category
    ) => {
        if (type === "globalMargin") {
            setGlobalMargin(`${globalMargin.replaceAll("%", "")}`);
        } else {
            const updatedObject = { ...categoryMargin, margin: e.target.value };
            e.target.value = e.target.value.replaceAll("%", "");
            update(index as number, updatedObject as Category);
        }
    };

    const updateTaxRateNames = (e: any, taxName: string) => {
        dispatch(updateProductSettings({ [taxName]: e.target.value }));
    };

    const prepareReqObject = (productSettings: any) => {
        const margin = productSettings.globalMargin.replace("%", "");
        return {
            doesAllowCategoryLevelMargin: productSettings.allowCategoryMargin,
            doesAllowProductLevelMargin: productSettings.allowProductMargin,
            locationId: locationID,
            margin: margin ? Number(margin) : 0,
            shouldRoundToTheNines: productSettings.allowRoundToNines,
            taxRate1Name: productSettings.taxRate1Name,
            taxRate2Name: productSettings.taxRate2Name,
            taxRate3Name: productSettings.taxRate3Name,
            taxRate4Name: productSettings.taxRate4Name,
        };
    };

    const validateDirty = () => {
        if (fetchGlobalMargin && Object.keys(fetchGlobalMargin).length > 0) {
            return (
                productSettings.globalMargin.toString().replaceAll("%", "") ==
                fetchGlobalMargin.margin.toString().replaceAll("%", "") &&
                productSettings.allowCategoryMargin ==
                fetchGlobalMargin.doesAllowCategoryLevelMargin &&
                productSettings.allowProductMargin ==
                fetchGlobalMargin.doesAllowProductLevelMargin &&
                productSettings.allowRoundToNines ==
                fetchGlobalMargin.shouldRoundToTheNines &&
                productSettings.taxRate1Name ==
                fetchGlobalMargin.taxRate1Name &&
                productSettings.taxRate2Name ==
                fetchGlobalMargin.taxRate2Name &&
                productSettings.taxRate3Name ==
                fetchGlobalMargin.taxRate3Name &&
                productSettings.taxRate4Name == fetchGlobalMargin.taxRate4Name
            );
        } else {
            return (
                productSettings.globalMargin.toString().replaceAll("%", "") ==
                "0%" &&
                productSettings.allowCategoryMargin == false &&
                productSettings.allowProductMargin == false &&
                productSettings.allowRoundToNines == false &&
                !productSettings.taxRate1Name &&
                !productSettings.taxRate2Name &&
                !productSettings.taxRate3Name &&
                !productSettings.taxRate4Name
            );
        }
    };

    const submitGlobalMargin = () => {
        const request = prepareReqObject(productSettings);
        const checkChangesToSave = validateDirty();
        if (!checkChangesToSave) {
            if (
                fetchGlobalMargin &&
                Object.keys(fetchGlobalMargin).length > 0 &&
                fetchGlobalMargin.globalMarginId
            ) {
                dispatch(PatchGlobalMargin(request));
            } else {
                dispatch(SaveGlobalMargin(request));
            }
        } else {
            renderToastr(GLOBAL_NO_CHANGES_TO_SAVE, "danger");
            return false;
        }
    };

    const onSubmit = (data: any) => {
        const differenceCategoryArray = data.categoryMargin.filter(
            ({
                margin: updatedMargin,
                shouldRoundToTheNines: updatedRoundToNines,
            }: Category) =>
                !categoryMargin.some(
                    ({
                        margin: originalMargin,
                        shouldRoundToTheNines: originalRoundToNines,
                    }) =>
                        updatedMargin.toString().replaceAll("%", "") ==
                        originalMargin.toString().replaceAll("%", "") &&
                        updatedRoundToNines == originalRoundToNines
                )
        );
        const modifiedCategoryArr = differenceCategoryArray.map((item: any) => {
            item.margin = item.margin ? Number(item.margin.replaceAll("%", "")) : 0;
            return item;
        });
        const patchCatgeoryMargin = modifiedCategoryArr.filter(
            (item: any) => item.categoryMarginId
        );
        const saveCategoryMargin = modifiedCategoryArr.filter(
            (item: any) => !item.categoryMarginId
        );
        if (saveCategoryMargin && saveCategoryMargin.length > 0) {
            dispatch(SaveCategoryMargin(saveCategoryMargin));
        } else if (patchCatgeoryMargin && patchCatgeoryMargin.length > 0) {
            dispatch(PatchCategoryMargin(patchCatgeoryMargin));
        } else {
            renderToastr(GLOBAL_NO_CHANGES_TO_SAVE, "danger");
            return false;
        }
    };
    return (
        <IonCol
            size="2"
            className={
                isDesktop || isTablet
                    ? "padding-0 product-settings-panel"
                    : " padding-0 product-settings-panel-mobile"
            }
        >
            <IonGrid className="settings-sidenav">
                <IonRow>
                    <IonCol>
                        <IonText className="header-text product-settings-title">
                            {GLOBAL_SETTING}
                        </IonText>
                        {!isDesktop && !isTablet ? (
                            <IonChip
                                color="primary"
                                className="ion-chip"
                                onClick={() => {
                                    SetPanelShowStatus(false);
                                }}
                            >
                                <span className="product-list-cancel-chip">
                                    &times;
                                </span>
                            </IonChip>
                        ) : (
                            ""
                        )}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonGrid className="form-content settings-form">
                            <IonRow>
                                <IonCol
                                    offsetXs="1"
                                    size="auto"
                                    className="m0"
                                >
                                    <p className="text-left taxes">
                                        {errors?.productGlobalMargin
                                            ?.message && (
                                                <IonIcon
                                                    md={star}
                                                    size="small"
                                                    color="danger"
                                                    className="error-asterik-size"
                                                />
                                            )}
                                        {PRODUCT_GLOBAL_MARGINS}
                                    </p>
                                    <IonItem className="input-item">
                                        <IonInput
                                            placeholder={PRODUCT_PLACEHOLDER_GLOBAL_MARGINS}
                                            type="text"
                                            inputmode="numeric"
                                            className={`text-box-width`}
                                            {...register(
                                                "productGlobalMargin"
                                            )}
                                            value={globalMargin}
                                            defaultValue={globalMargin}
                                            id="productGlobalMargin"
                                            name="productGlobalMargin"
                                            onKeyPress={(e) => {
                                                restrictAlphabets(
                                                    e,
                                                    "globalMargin"
                                                );
                                            }}
                                            onIonChange={(e: any) => {
                                                if (e.detail.value === "-")
                                                    dispatch(
                                                        updateProductSettings(
                                                            {
                                                                globalMargin:
                                                                    "-",
                                                            }
                                                        )
                                                    );
                                            }}
                                            onBlur={(e) => {
                                                updatePercentageSymbol(
                                                    e,
                                                    "globalMargin",
                                                    null
                                                );
                                            }}
                                            onFocus={(e) => {
                                                removePercentageSymbol(
                                                    e,
                                                    "globalMargin",
                                                    null
                                                );
                                            }}
                                        ></IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="product-margins-column">
                                    <IonRow className="product-margin-row">
                                        <IonCol
                                            offsetXs="1"
                                            size="auto"
                                            className="m0"
                                        >
                                            <IonCheckbox
                                                checked={allowCategoryMargin}
                                                onIonChange={(e) => {
                                                    setAllowCategoryMargin(
                                                        e.detail.checked
                                                    );
                                                    dispatch(
                                                        updateProductSettings({
                                                            allowCategoryMargin:
                                                                e.detail.checked,
                                                        })
                                                    );
                                                }}
                                            />
                                        </IonCol>
                                        <IonLabel>
                                            {PRODUCT_CATEGORY_LEVEL_MARGINS}
                                        </IonLabel>
                                    </IonRow>
                                    <IonRow className="product-margin-row">
                                        <IonCol
                                            offsetXs="1"
                                            size="auto"
                                            className="m0"
                                        >
                                            <IonCheckbox
                                                checked={allowProductMargin}
                                                onIonChange={(e) => {
                                                    setAllowProductMargin(
                                                        e.detail.checked
                                                    );
                                                    dispatch(
                                                        updateProductSettings({
                                                            allowProductMargin:
                                                                e.detail.checked,
                                                        })
                                                    );
                                                }}
                                                className="setting-chkbox"
                                            />
                                        </IonCol>

                                        <IonLabel>
                                            {PRODUCT_LEVEL_MARGINS}
                                        </IonLabel>
                                    </IonRow>
                                    <ToolTip text={RODUCT_ROUND_NINES_MSG}>
                                        <IonRow className="round-to-nines">
                                            <IonCol
                                                offsetXs="1"
                                                size="auto"
                                                className="m0"
                                            >
                                                <IonCheckbox
                                                    checked={roundToNines}
                                                    onIonChange={(e) => {
                                                        setRoundToNines(
                                                            e.detail.checked
                                                        );
                                                        dispatch(
                                                            updateProductSettings({
                                                                allowRoundToNines:
                                                                    e.detail
                                                                        .checked,
                                                            })
                                                        );
                                                    }}
                                                />
                                            </IonCol>

                                            <IonLabel>
                                                {RODUCT_ROUND_NINES}
                                                <IonIcon
                                                    md={helpCircleOutline}
                                                    size="medium"
                                                />
                                            </IonLabel>
                                        </IonRow>
                                    </ToolTip>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonButton
                                        className="product-settings-save-btn-left marginLeft5"
                                        color="primary"
                                        onClick={submitGlobalMargin}
                                        disabled={errors?.productGlobalMargin?.message as any}
                                    >
                                        {FOOTER_SAVE_BUTTON}
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <React.Fragment>
                                    <IonRow>
                                        <IonAccordionGroup className="row-horizontal">
                                            {!pending &&
                                                fields.length > 0 &&
                                                fields.map(
                                                    (
                                                        res: Category,
                                                        i: number
                                                    ) => (
                                                        <IonAccordion
                                                            value={res.category}
                                                            className="accordion-bg solid"
                                                            key={res.category}
                                                        >
                                                            <IonItem
                                                                slot="header"
                                                                className="accordion-header"
                                                            >
                                                                <IonLabel>
                                                                    {
                                                                        res.category
                                                                    }
                                                                </IonLabel>
                                                            </IonItem>

                                                            <IonList
                                                                slot="content"
                                                                className="accordion-bg"
                                                            >
                                                                {allowCategoryMargin && (
                                                                    <IonRow>
                                                                        <IonCol
                                                                            offsetXs="1"
                                                                            size="auto"
                                                                            className="m0"
                                                                        >
                                                                            <p className="text-left marginLeft10">
                                                                                {categoryMarginError
                                                                                    .categoryMargin?.[
                                                                                    i
                                                                                ]
                                                                                    ?.margin && (
                                                                                        <IonIcon
                                                                                            md={
                                                                                                star
                                                                                            }
                                                                                            size="small"
                                                                                            color="danger"
                                                                                            className="error-asterik-size"
                                                                                        />
                                                                                    )}
                                                                                {PRODUCT_CATEGORY_MARGINS}
                                                                            </p>
                                                                            <IonItem className="input-item marginLeft5">
                                                                                <IonInput
                                                                                    placeholder={PRODUCT_PLACEHOLDER_CATEGORY_MARGINS}
                                                                                    type="text"
                                                                                    inputmode="numeric"
                                                                                    {...categoryMarginRegister(
                                                                                        `categoryMargin.${i}.margin`
                                                                                    )}
                                                                                    className={`text-box-width`}
                                                                                    id={`categoryMargin.${i}.margin`}
                                                                                    name={`categoryMargin.${i}.margin`}
                                                                                    defaultValue={
                                                                                        res.margin
                                                                                    }
                                                                                    value={
                                                                                        res.margin
                                                                                    }
                                                                                    onKeyPress={(
                                                                                        e
                                                                                    ) => {
                                                                                        restrictAlphabets(
                                                                                            e,
                                                                                            "categoryMargin",
                                                                                            i,
                                                                                            res
                                                                                        );
                                                                                    }}
                                                                                    onBlur={(
                                                                                        e
                                                                                    ) => {
                                                                                        updatePercentageSymbol(
                                                                                            e,
                                                                                            "categoryMargin",
                                                                                            null
                                                                                        );
                                                                                    }}
                                                                                    onFocus={(
                                                                                        e
                                                                                    ) => {
                                                                                        removePercentageSymbol(
                                                                                            e,
                                                                                            "categoryMargin",
                                                                                            i,
                                                                                            res
                                                                                        );
                                                                                    }}
                                                                                ></IonInput>
                                                                            </IonItem>
                                                                        </IonCol>
                                                                    </IonRow>
                                                                )}
                                                                <ToolTip text={RODUCT_ROUND_NINES_MSG}
                                                                    isMarginNeed={true}>
                                                                    <IonRow className="round-to-nines marginLeft10">
                                                                        <IonCol
                                                                            offsetXs="1"
                                                                            size="auto"
                                                                            className="m0"
                                                                        >
                                                                            <IonCheckbox
                                                                                checked={res.shouldRoundToTheNines}
                                                                                value={res.shouldRoundToTheNines}
                                                                                {...categoryMarginRegister(
                                                                                    `categoryMargin.${i}.shouldRoundToTheNines`
                                                                                )}
                                                                                onClick={(e) => {
                                                                                    update(
                                                                                        i as number,
                                                                                        {
                                                                                            ...res,
                                                                                            shouldRoundToTheNines:
                                                                                                !res.shouldRoundToTheNines,
                                                                                        }
                                                                                    );
                                                                                }}

                                                                            />
                                                                        </IonCol>
                                                                        <IonLabel>
                                                                            {RODUCT_ROUND_NINES}
                                                                            <IonIcon
                                                                                md={helpCircleOutline}
                                                                                size="medium"
                                                                            />
                                                                        </IonLabel>
                                                                    </IonRow>
                                                                </ToolTip>
                                                            </IonList>
                                                        </IonAccordion>
                                                    )
                                                )}

                                            <IonInfiniteScroll
                                                onIonInfinite={handleScroll}
                                                threshold="100px"
                                                disabled={isInfiniteDisabled}
                                            >
                                                <IonInfiniteScrollContent
                                                    loadingSpinner="bubbles"
                                                    loadingText={LOADING_MORE_DATA}
                                                ></IonInfiniteScrollContent>
                                            </IonInfiniteScroll>
                                        </IonAccordionGroup>
                                    </IonRow>
                                    {!pending && fields.length > 0 && (
                                        <IonRow>
                                            <IonCol>
                                                <IonButton
                                                    type="submit"
                                                    className={
                                                        isDesktop ||
                                                            (ismobile && !isTablet)
                                                            ? "product-settings-save-btn product-setting-save"
                                                            : "product-settings-save-btn product-setting-save-tablet"
                                                    }
                                                    color="primary"
                                                >
                                                    {FOOTER_SAVE_BUTTON}
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                </React.Fragment>
                            </form>
                        </IonGrid>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonCol>
    );
};

export default ProductsSettingsPanel;
