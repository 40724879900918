//vendors
import { StylesConfig } from "react-select";
export interface InterfaceOption {
    readonly value: string;
    readonly label: string;
    readonly isFixed?: boolean;
    readonly isDisabled?: boolean;
}

export const orderOptions = (values: readonly InterfaceOption[]) => {
    return values
        .filter((v) => v.isFixed)
        .concat(values.filter((v) => !v.isFixed));
};

export const colourStyles: StylesConfig<InterfaceOption, true> = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { isSelected, isDisabled }) => {
        return {
            ...styles,
            backgroundColor: isSelected ? "#EBEBEB" : "",
            color: isSelected ? "black" : isDisabled ? "#C1C1C1" : "",
        };
    },
    multiValue: (styles) => {
        return {
            ...styles,
            backgroundColor: "#EBEBEB",
            borderRadius: 20,
            fontSize: 16,
            width: "auto",
            height: 25,
            marginRight: 8,
            paddingTop: 2
        };
    },
    multiValueLabel: (base, state) => {
        return state.data.isFixed ? { ...base, color: 'gray', paddingRight: 6 } : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
    menuPortal: base => ({ ...base, zIndex: 999999 }),
    menu: provided => ({ ...provided, zIndex: 999999 })
};
//Example data
export const colourOptions = [
    { value: 'purple', label: 'Purple', isFixed: true },
    { value: 'red', label: 'Red', isDisabled: true },
    { value: 'blue', label: 'Blue' },
    { value: 'gray', label: 'Gray' },
    { value: 'white', label: 'White' },
    { value: 'green', label: 'Green' },
    { value: 'yellow', label: 'Yellow' },
    { value: 'orange', label: 'Orange' },
    { value: 'peach', label: 'Peach' },
    { value: 'violet', label: 'Violet' }
];