//vendors
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { shallowEqual, useSelector } from 'react-redux';
// components
import Header from "../../pages/Header";
import ErrorComponent from "../ErrorComponent";
// reducers
import { RootState } from "../../_redux/reducers/rootReducer";

const OnboardingNotAllowed: React.FC<any> = ({ doLogout }) => {
    const { user, logout } = useAuth0();
    const errorReducer = useSelector((state: RootState) => { return state.error }, shallowEqual);
    const { error } = errorReducer;
    const { errorMessage } = error || {};
    const systemUserId = user && user['https://365rm.us/systemUserId'];
    return (
        <>
            <Header
                systemUserId={systemUserId}
                buildLogoutUrl={logout}
                doLogout={doLogout}
            />
            <ErrorComponent message={errorMessage} />
        </>
    )
}

export default OnboardingNotAllowed;