
/* Post Reset password API */
export const POST_RESET_PASSWORD_REQUEST = 'POST_RESET_PASSWORD_REQUEST';
export const POST_RESET_PASSWORD_SUCCESS = 'POST_RESET_PASSWORD_SUCCESS';
export const POST_RESET_PASSWORD_FAILURE = 'POST_RESET_PASSWORD_FAILURE';

export type POST_RESET_PASSWORD_REQUEST = typeof POST_RESET_PASSWORD_REQUEST;
export type POST_RESET_PASSWORD_SUCCESS = typeof POST_RESET_PASSWORD_SUCCESS;
export type POST_RESET_PASSWORD_FAILURE = typeof POST_RESET_PASSWORD_FAILURE;

/* Put Reset password API */

export const PUT_RESET_PASSWORD_REQUEST = 'PUT_RESET_PASSWORD_REQUEST';
export const PUT_RESET_PASSWORD_SUCCESS = 'PUT_RESET_PASSWORD_SUCCESS';
export const PUT_RESET_PASSWORD_FAILURE = 'PUT_RESET_PASSWORD_FAILURE';

export type PUT_RESET_PASSWORD_REQUEST = typeof PUT_RESET_PASSWORD_REQUEST;
export type PUT_RESET_PASSWORD_SUCCESS = typeof PUT_RESET_PASSWORD_SUCCESS;
export type PUT_RESET_PASSWORD_FAILURE = typeof PUT_RESET_PASSWORD_FAILURE;

/**
 * Password data reset
 */
export type RESET_PASSWORD_DATA = typeof RESET_PASSWORD_DATA;
export const RESET_PASSWORD_DATA = "RESET_PASSWORD_DATA";