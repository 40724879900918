//vendors
import { IonRow, IonCol, IonText, IonButton } from "@ionic/react";

const DisplayError: React.FC<any> = ({ message, onClickCallBack, btnLabel, messageStyle, color='primary' }) => {
    return (
        <IonRow className="ion-padding-start ion-padding-end" style={{ height: '100%' }}>
            <IonCol>
                <p className={messageStyle || "no-cards-msg-wrapper"}>
                    <IonText color={color}>{message}</IonText>
                    {onClickCallBack && <IonButton onClick={() => { onClickCallBack() }}>{btnLabel}</IonButton>}
                </p>
            </IonCol>
        </IonRow>
    )
}

export default DisplayError;