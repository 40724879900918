/**
 * FETCH global margin
 */
export type FETCH_GLOBAL_MARGIN_REQUEST = typeof FETCH_GLOBAL_MARGIN_REQUEST;
export type FETCH_GLOBAL_MARGIN_SUCCESS = typeof FETCH_GLOBAL_MARGIN_SUCCESS;
export type FETCH_GLOBAL_MARGIN_FAILURE = typeof FETCH_GLOBAL_MARGIN_FAILURE;

export const FETCH_GLOBAL_MARGIN_REQUEST = "FETCH_GLOBAL_MARGIN_REQUEST";
export const FETCH_GLOBAL_MARGIN_SUCCESS = "FETCH_GLOBAL_MARGIN_SUCCESS";
export const FETCH_GLOBAL_MARGIN_FAILURE = "FETCH_GLOBAL_MARGIN_FAILURE";

/**
 * Global Margin save
 */
export type SAVE_GLOBAL_MARGIN_REQUEST = typeof SAVE_GLOBAL_MARGIN_REQUEST;
export type SAVE_GLOBAL_MARGIN_SUCCESS = typeof SAVE_GLOBAL_MARGIN_SUCCESS;
export type SAVE_GLOBAL_MARGIN_FAILURE = typeof SAVE_GLOBAL_MARGIN_FAILURE;

export const SAVE_GLOBAL_MARGIN_REQUEST = "SAVE_GLOBAL_MARGIN_REQUEST";
export const SAVE_GLOBAL_MARGIN_SUCCESS = "SAVE_GLOBAL_MARGIN_SUCCESS";
export const SAVE_GLOBAL_MARGIN_FAILURE = "SAVE_GLOBAL_MARGIN_FAILURE";

/**
 * Update Global margin
 */
export type PATCH_GLOBAL_MARGIN_REQUEST = typeof PATCH_GLOBAL_MARGIN_REQUEST;
export type PATCH_GLOBAL_MARGIN_SUCCESS = typeof PATCH_GLOBAL_MARGIN_SUCCESS;
export type PATCH_GLOBAL_MARGIN_FAILURE = typeof PATCH_GLOBAL_MARGIN_FAILURE;

export const PATCH_GLOBAL_MARGIN_REQUEST = "PATCH_GLOBAL_MARGIN_REQUEST";
export const PATCH_GLOBAL_MARGIN_SUCCESS = "PATCH_GLOBAL_MARGIN_SUCCESS";
export const PATCH_GLOBAL_MARGIN_FAILURE = "PATCH_GLOBAL_MARGIN_FAILURE";

/**
 * FETCH category margin
 */
export type FETCH_CATEGORY_MARGIN_REQUEST =
    typeof FETCH_CATEGORY_MARGIN_REQUEST;
export type FETCH_CATEGORY_MARGIN_SUCCESS =
    typeof FETCH_CATEGORY_MARGIN_SUCCESS;
export type FETCH_CATEGORY_MARGIN_FAILURE =
    typeof FETCH_CATEGORY_MARGIN_FAILURE;

export const FETCH_CATEGORY_MARGIN_REQUEST = "FETCH_CATEGORY_MARGIN_REQUEST";
export const FETCH_CATEGORY_MARGIN_SUCCESS = "FETCH_CATEGORY_MARGIN_SUCCESS";
export const FETCH_CATEGORY_MARGIN_FAILURE = "FETCH_CATEGORY_MARGIN_FAILURE";

/**
 * Category Margin save
 */
export type SAVE_CATEGORY_MARGIN_REQUEST = typeof SAVE_CATEGORY_MARGIN_REQUEST;
export type SAVE_CATEGORY_MARGIN_SUCCESS = typeof SAVE_CATEGORY_MARGIN_SUCCESS;
export type SAVE_CATEGORY_MARGIN_FAILURE = typeof SAVE_CATEGORY_MARGIN_FAILURE;

export const SAVE_CATEGORY_MARGIN_REQUEST = "SAVE_CATEGORY_MARGIN_REQUEST";
export const SAVE_CATEGORY_MARGIN_SUCCESS = "SAVE_CATEGORY_MARGIN_SUCCESS";
export const SAVE_CATEGORY_MARGIN_FAILURE = "SAVE_CATEGORY_MARGIN_FAILURE";

/**
 * Update category margin
 */
export type PATCH_CATEGORY_MARGIN_REQUEST =
    typeof PATCH_CATEGORY_MARGIN_REQUEST;
export type PATCH_CATEGORY_MARGIN_SUCCESS =
    typeof PATCH_CATEGORY_MARGIN_SUCCESS;
export type PATCH_CATEGORY_MARGIN_FAILURE =
    typeof PATCH_CATEGORY_MARGIN_FAILURE;

export const PATCH_CATEGORY_MARGIN_REQUEST = "PATCH_CATEGORY_MARGIN_REQUEST";
export const PATCH_CATEGORY_MARGIN_SUCCESS = "PATCH_CATEGORY_MARGIN_SUCCESS";
export const PATCH_CATEGORY_MARGIN_FAILURE = "PATCH_CATEGORY_MARGIN_FAILURE";

/**
 * ReSet product settings panel
 */
export type RESET_PRODUCT_SETTINGS_PANEL = typeof RESET_PRODUCT_SETTINGS_PANEL;
export const RESET_PRODUCT_SETTINGS_PANEL = "RESET_PRODUCT_SETTINGS_PANEL";


/**
 * ReSet fetch margins
 */
 export type RESET_MARGINS = typeof RESET_MARGINS;
 export const RESET_MARGINS = "RESET_MARGINS";