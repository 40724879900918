//vendors
import { isPlatform } from '@ionic/react';
import i18next from 'i18next';

const istablet = isPlatform('tablet');
const isdesktop = isPlatform('desktop');
const ismobile = isPlatform('mobile');

export const paymentConstants = {
    PAYMENT_LABEL: i18next.t('constant_texts.global_payment_label'),
    ADD_CARD: i18next.t('buttons.payment_add_card'),
    PAYMENT_CREDIET_CARD: i18next.t('constant_texts.credit_card'),
    NO_PAYMENT_MSG1: i18next.t('constant_texts.no_paymet_msg1'),
    NO_PAYMENT_MSG2: i18next.t('constant_texts.no_paymet_msg2'),
    NO_PAYMENT_MSG3: i18next.t('constant_texts.no_paymet_msg3'),
    PAYMENT_INFO: i18next.t('constant_texts.payment-info'),
    CREDIT_CARD_INFO: i18next.t('constant_texts.credit_card_info'),
    NAME_ON_CARD: i18next.t('constant_texts.name_on_card'),
    CARD_NUMBER: i18next.t('constant_texts.card_number'),
    BILLING_ZIP_CODE: i18next.t('constant_texts.billing_zip_code'),
    CARD_EXPIRATION: i18next.t('constant_texts.card_expiration'),
    CARD_CVV: i18next.t('constant_texts.card_cvv'),
    PAYMENT_MONTH: i18next.t('constant_texts.payment_month'),
    PAYMENT_YEAR: i18next.t('constant_texts.payment_year'),
    CONFIRM_DELETE_HEADER: i18next.t('constant_texts.user_mgmt_confm_dlt_hdr'),
    CONFIRM_DELETE_BODY_TEXT: i18next.t('constant_texts.confirm_delete_body_text'),
    CONFIRM_CANCEL_BUTTON: i18next.t('buttons.cancel'),
    CONFIRM_DELETE_BUTTON: i18next.t('buttons.delete'),
    MISSING_CARD_DETAILS: i18next.t('error_msg.payment_missing_card_details'),
    FETCH_CARD_DETAILS: i18next.t('error_msg.payment_fetch_card_details_err'),
    TRY_AGAIN_BUTTON: i18next.t('labels.try_again'),
    BACK_BUTTON: i18next.t('buttons.back')
};

// error messages to user
export const CARD_ADDED_SUCCESS = i18next.t("toast_msg.payment_card_added_success");
export const CARD_DELETED_SUCCESS = i18next.t("toast_msg.payment_card_deleted_success");
export const ADD_CARD_FAILURE = i18next.t('error_msg.payment_add_card_failure');
export const DELETE_CARD_FAILURE = i18next.t('error_msg.payment_delete_card_failure');
export const CARD_NUMBER_REQUIRED = i18next.t('error_msg.payment_card_number_req');
export const CARD_NUMBER_INVALID = i18next.t('error_msg.payment_card_number_invalid');
export const BILLING_ZIP_CODE_REQUIRED = i18next.t('error_msg.payment_billing_zip_code_req');
export const CVV_INVALID = i18next.t('error_msg.paymnet_cvv_invalid');
export const NAME_ON_CARD_REQUIRED = i18next.t('error_msg.payment_name_on_card_req');
export const LOADER_MSG = i18next.t('toast_msg.loader_msg');
export const LOGIN_LOADER_MSG = i18next.t('toast_msg.login_loader_msg');
export const SERV_NOT_ALLOWED = i18next.t('error_msg.serv_not_allowed');

//payment form styles
export const paymentFormStyle = {
    "input": {
        'background': '#fff',
        'border': '1px solid',
        'border-color': '#bbb3b9 #c7c1c6 #c7c1c6',
        'box-sizing': 'border-box',
        'font-family': 'serif',
        'font-size': '16px',
        'line-height': '1',
        'max-width': '100%',
        'outline': '0',
        'vertical-align': 'baseline',
        'height': '50px',
    },
    '#heartland-field': {
        'font-family': 'sans-serif',
        'box-sizing': 'border-box',
        'display': 'block',
        'font-size': '14px',
        'line-height': '1.42857143',
        'color': '#555',
        'background-color': '#fff',
        'border': '1px solid #ccc',
        'border-radius': '5px',
        '-webkit-box-shadow': 'inset 0 1px 1px rgba(0,0,0,.075)',
        'box-shadow': 'inset 0 1px 1px rgba(0,0,0,.075)',
        '-webkit-transition': 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
        '-o-transition': 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
        'transition': 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
        'width': '100%'
    },
    '#heartland-field[name=submit]': {
        'background-color': '#2555D9',
        'font-family': 'sans-serif',
        'text-transform': 'uppercase',
        'color': '#ffffff',
        'font-weight': 'bold',
        'font-size': '16px',
        'height': '55px',
        'border': 'none'
    },
    'heartland-field-wrapper #heartland-field': {},
    '#heartland-field[placeholder]': {
        'letter-spacing': '3px'
    },
    'input#heartland-field[name=cardExpiration]': isdesktop ? { 'max-width': '60%' } : (ismobile || istablet) && { 'max-width': '95%' },
    '#heartland-field[name=cardCvv]': isdesktop ? {
        'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/cvv1.png?raw=true) no-repeat right',
        'background-size': '63px 40px',
        'max-width': '60%'
    } : (ismobile || istablet) && {
        'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/cvv1.png?raw=true) no-repeat right',
        'background-size': '63px 40px',
        'max-width': '95%'
    },
    'input#heartland-field[name=cardNumber]': (isdesktop || istablet) ? {
        'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-inputcard-blank@2x.png?raw=true) no-repeat right',
        'background-size': '55px 35px',
        'width':'99%'
    } : {
        'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-inputcard-blank@2x.png?raw=true) no-repeat right',
        'background-size': '55px 35px',
    },
    '#heartland-field.invalid.card-type-visa': {
        'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-visa@2x.png?raw=true) no-repeat right',
        'background-size': '83px 88px',
        'background-position-y': '-44px'
    },
    '#heartland-field.valid.card-type-visa': {
        'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-visa@2x.png?raw=true) no-repeat right top',
        'background-size': '82px 86px'
    },
    '#heartland-field.invalid.card-type-discover': {
        'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-discover@2x.png?raw=true) no-repeat right',
        'background-size': '85px 90px',
        'background-position-y': '-44px'
    },
    '#heartland-field.valid.card-type-discover': {
        'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-discover@2x.png?raw=true) no-repeat right',
        'background-size': '85px 90px',
        'background-position-y': '1px'
    },
    '#heartland-field.invalid.card-type-amex': {
        'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-savedcards-amex@2x.png?raw=true) no-repeat right',
        'background-size': '50px 90px',
        'background-position-y': '-44px'
    },
    '#heartland-field.valid.card-type-amex': {
        'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-savedcards-amex@2x.png?raw=true) no-repeat right top',
        'background-size': '50px 90px'
    },
    '#heartland-field.invalid.card-type-mastercard': {
        'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-mastercard.png?raw=true) no-repeat right',
        'background-size': '62px 105px',
        'background-position-y': '-52px'
    },
    '#heartland-field.valid.card-type-mastercard': {
        'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-mastercard.png?raw=true) no-repeat right',
        'background-size': '62px 105px',
        'background-position-y': '-1px'
    },
    '#heartland-field.invalid.card-type-jcb': {
        'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-jcb@2x.png?raw=true) no-repeat right',
        'background-size': '55px 94px',
        'background-position-y': '-44px'
    },
    '#heartland-field.valid.card-type-jcb': {
        'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-jcb@2x.png?raw=true) no-repeat right top',
        'background-size': '55px 94px',
        'background-position-y': '2px'
    },
    'input#heartland-field[name=cardNumber]::-ms-clear': {
        'display': 'none'
    },
    'iframe': { 'width': '100%' }
}

export interface PaymentFooterProps {
    showCancelNewCardBtn?: boolean;
    showDeleteCardBtn?: boolean;
    showSaveNewCardBtn?: boolean;
    detachCard?: any;
    onCancel?: any;
    isAddCardClicked?: boolean;
    onClickSave?: () => void
}