//vendor
import React, { useEffect } from "react";
import { IonRow, IonCol, IonSpinner, isPlatform } from "@ionic/react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
//actions
import { fetchSysUserTokensRequest } from "../../_redux/actions/paymentActions";
import { RootState } from '../../_redux/reducers/rootReducer';
//components
import AddPaymentButton from "./AddPaymentButton";
import NoCardsAvailable from "./NoCardsAvailable";
import CardsList from "./CardsList";
//constants
import { paymentConstants } from "./constants";

const AvailableCards: React.FC<any> = ({ redirectToDetails }) => {
    const dispatch = useDispatch();
    const systemUser = useSelector((state: RootState) => { return state.systemUsers; }, shallowEqual);
    const payment = useSelector((state: RootState) => { return state.payment; }, shallowEqual);
    const { systemUserId } = systemUser;
    const { availableCards } = payment;
    const { cards, pending: availableCardsPending, error: availableCardsErr } = availableCards;
    const istablet = isPlatform("tablet");
    const isdesktop = isPlatform("desktop")

    useEffect(() => {
        cards.length === 0 && dispatch(fetchSysUserTokensRequest(systemUserId));
    }, []);

    const listAvailableCards = () => {
        if (availableCardsPending) {
            return <IonSpinner name="circles" className={istablet && !isdesktop ? "spinner-circles-tablet" : 'spinner-circles'} />
        } else {
            if (cards.length > 0 && availableCardsErr === null) {
                return <CardsList cards={cards} redirectToDetails={redirectToDetails} />
            } else if (cards.length === 0 && availableCardsErr === null) {
                return <NoCardsAvailable />
            } else if (availableCardsErr !== null) {
                return <div>{paymentConstants.FETCH_CARD_DETAILS}</div>
            }
        }
    }
    return (
        <IonRow className="ion-padding-start ion-padding-end">
            <IonCol>
                <IonRow><IonCol><h3><b>{paymentConstants.PAYMENT_LABEL}</b></h3></IonCol></IonRow>
                <AddPaymentButton />
                {listAvailableCards()}
            </IonCol>
        </IonRow>
    )
}
export default AvailableCards; 