// vendors
import { IonCol, isPlatform } from "@ionic/react";
import { shallowEqual, useSelector } from "react-redux";
//components
import ReportHeaderBar from "./ReportHeaderBar";
import DataGrid from "./DataGrid";
import BeforeReportRun from './BeforeReportRun';
import ErrorComponent from "../../components/ErrorComponent";
//reducers
import { RootState } from '../../_redux/reducers/rootReducer';
//constants
import { REPORT_ERROR } from "../../constants/constant";

const ReportContent: React.FC<any> = ({ showSidePanel, selectedReport, setShowSidePanel, backToReportsList }) => {
    const report = useSelector((state: RootState) => { return state.reports; }, shallowEqual);
    const { isReportRan: runReport, error } = report;

    return (
        <IonCol size={showSidePanel ? "9" : "12"} size-lg={showSidePanel ? "9" : "12"} size-md="7" size-xs="12" size-sm="12" className="report-right-section">
            <ReportHeaderBar
                showSidePanel={showSidePanel}
                selectedReport={selectedReport}
                setShowSidePanel={setShowSidePanel}
                backToReportsList={backToReportsList}
            />
            {!runReport ?
                <BeforeReportRun selectedReport={selectedReport} /> :
                <>
                    {error ?
                        <ErrorComponent message={REPORT_ERROR} /> :
                        <DataGrid selectedReport={selectedReport} runReport={runReport} showSidePanel={showSidePanel} />
                    }
                </>
            }
        </IonCol>
    )
}
export default ReportContent;