//vendors
import React, { useState, useEffect, useContext } from "react";
import {
    IonButton,
    IonHeader,
    IonIcon,
    IonNote,
    IonToolbar,
    isPlatform,
    useIonPopover,
    IonLabel,
} from "@ionic/react";
import { caretDown, menu } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
// reducers
import { RootState } from "../../_redux/reducers/rootReducer";
//components
import { PopoverList } from './PopoverList';
//utils
import { getOnboardedProfiles, getNeedsOnboardingProfiles, splitFirstName } from "../../utils/index";
import { PortalContext } from '../..';
//descriptors
import { getDescriber } from './Descriptor/HeaderDescriptor';

type HeaderProps = {
    userLogo?: string;
    systemUserId: string;
    buildLogoutUrl: Function;
    doLogout: Function;
};
const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
    const history = useHistory();
    const { systemUserId, doLogout } = props;
    const _portalTypes = useContext(PortalContext) as any;
    const { imageDescriptor, headerMarketLabels } = getDescriber(_portalTypes);
    const [onboardedProfiles, setOnboardedProfiles] = useState<Array<any>>([]);
    const [needsOnboardingProfiles, setNeedsonboardingProfiles] = useState<Array<any>>([]);
    const profile = useSelector((state: RootState) => { return state.profile; }, shallowEqual);
    const { firstName } = useSelector((state: RootState) => { return state.systemUsers; }, shallowEqual);
    const { allProfiles, currentProfile } = profile;
    const { marketTheme, additionalDetails, errors: currentProfileError, marketType } = currentProfile;
    const { pending: allProfilesPending, errors: allProfilesError, profiles } = allProfiles;
    const { hasPreventOrdersUntilInventoryTaken } = additionalDetails;

    const [present, dismiss] = useIonPopover(PopoverList, {
        onHide: () => dismiss(),
        systemUserId,
        history,
        doLogout,
        onboardedProfiles,
        needsOnboardingProfiles,
        allProfilesError,
        currentProfileError,
        marketType
    });

    const onProfileClick = (e: any) => {
        present({ event: e.nativeEvent });
    };
    const isDesktop = isPlatform("desktop");
    const isTablet = isPlatform("tablet");
    var isITablet = /iPad/i.test(navigator.userAgent);

    useEffect(() => {
        !allProfilesError && !allProfilesPending && setOnboardedProfiles(getOnboardedProfiles(profiles));
        !allProfilesError && !allProfilesPending && setNeedsonboardingProfiles(getNeedsOnboardingProfiles(profiles));
    }, [allProfilesPending, allProfilesError, profile]);

    return (
        <IonHeader>
            <IonToolbar className="font-smaller" color="medium">
                <IonLabel slot="start">{imageDescriptor({ marketTheme })}</IonLabel>
                {headerMarketLabels({ hasPreventOrdersUntilInventoryTaken, currentProfile })}
                <IonButton slot="end" size="small" fill="clear" className="header-user-menu" onClick={onProfileClick}>
                    {isDesktop || isTablet || isITablet ? (
                        <>
                            <IonNote className="header-user">{splitFirstName(firstName) || ""}</IonNote>
                            <IonIcon icon={caretDown} className="header-caret-down" />
                        </>
                    ) : (<IonIcon slot="end" md={menu} />)}
                </IonButton>
            </IonToolbar>
        </IonHeader>
    );
};

export default Header;
