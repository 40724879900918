import { useEffect, useState } from "react";
import { IonButton, IonCol, IonImg, IonInput, IonRow, isPlatform } from "@ionic/react";
import PreviewButton from "../common/Button/PreviewButton";
import type { SignupProps } from "../../models/SignupProps";
import { isValidHttpUrl } from '../../utils';
//constant
import { Hotel365Portal, onboarding, GLOBAL_USE_BUTTON, ONBOARDING_COLOR_THEME_LOGOURL_ERR, SETTING_COLOR_LOGO_PREVIEW_BUTTON } from "../../constants/constant";

const SiteTheme: React.FC<SignupProps> = ({ marketTheme, setOnboarding, logoError, setLogoError }) => {
    const isMobile = isPlatform("mobile");
    const { errorMessage } = logoError;
    const [siteLogo, setSiteLogo] = useState(marketTheme.logoUrl || '');
    const [siteColor, setsiteColor] = useState(marketTheme.primaryColorHex ? marketTheme.primaryColorHex : Hotel365Portal.defaultPrimaryColor);
    const [showLogo, setShowLogo] = useState(false);
    const onImageChange = (e: any) => {
        const { errorMessage, isValid } = isValidHttpUrl(e.detail.value);
        setSiteLogo(e.detail.value);
        setLogoError({ isValid, errorMessage });
        setOnboarding((prevState: any) => ({ ...prevState, marketTheme: { logoUrl: e.detail.value, primaryColorHex: marketTheme.primaryColorHex } }));
        setShowLogo(true);
    }
    const onClickUse = () => {
        if (!errorMessage) {
            setShowLogo(true);
        }
    }
    const clearColor = () => {
        setsiteColor(Hotel365Portal.defaultPrimaryColor);
        setOnboarding((prevState: any) => ({ ...prevState, marketTheme: { ...prevState.marketTheme, primaryColorHex: Hotel365Portal.defaultPrimaryColor } }));
    }
    const colorPicker = (e: any) => {
        setsiteColor(e.target.value);
        setOnboarding((prevState: any) => ({ ...prevState, marketTheme: { primaryColorHex: e.target.value, logoUrl: marketTheme.logoUrl } }));
    }
    useEffect(() => {
        if (!siteLogo) {
            setShowLogo(false);
        }
    })
    return (
        <IonRow className={isMobile ? "auto-overflow-mobile verticle-center ion-padding-start" : "verticle-center ion-padding-start"}>
            <IonCol>
                <IonRow>
                    <IonCol>
                        <h2 style={isMobile ? { fontSize: '20px' } : {}}>
                            <b>
                                {onboarding.RIGHT_SIDE_HEADING1_THEME_COLOR}<br />
                                {onboarding.RIGHT_SIDE_HEADING2_THEME_COLOR}
                            </b>
                        </h2>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="2" sizeXs="4" sizeLg="2" sizeMd="2" sizeSm="3">
                        <span className="logo-subheader">{onboarding.RIGHT_SIDE_LOGO_HEADING}</span>
                        <IonImg
                            id="image"
                            src={showLogo ? siteLogo : "/assets/logo.svg"}
                            className={`${showLogo ? "brand-logo" : "site-logo-back brand-logo"}`}
                        />
                    </IonCol>
                    <IonCol size="10" sizeXs="12" sizeLg="10" sizeMd="10" sizeSm="9">
                        <IonRow>
                            <IonCol className="ion-no-padding" sizeXs="12">
                                <span className="logo-subheader">{onboarding.RIGHT_SIDE_LOGO_URL}</span>
                                <IonInput
                                    id="logo"
                                    type="url"
                                    name="logoUrl"
                                    accept="image/*"
                                    value={siteLogo}
                                    className="logo-input-url"
                                    onIonChange={onImageChange}
                                    aria-label={onboarding.RIGHT_SIDE_LOGO_URL}
                                />
                                <small className="short-note-label">{onboarding.RIGHT_SIDE_COLOR_LOGO_NOTE1}</small>
                            </IonCol>
                            <IonCol className="ion-no-padding" sizeXs="12">
                                <IonButton className="url-button" onClick={onClickUse}>{GLOBAL_USE_BUTTON}</IonButton>
                            </IonCol>
                        </IonRow>
                        {errorMessage && <span className='url-error-label'> {ONBOARDING_COLOR_THEME_LOGOURL_ERR}</span>}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="ion-no-padding">
                        <h2><b>{onboarding.RIGHT_SIDE_THEME_COLOR_BRAND}</b></h2>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="ion-no-padding"><span className="logo-subheader">{onboarding.RIGHT_SIDE_PRIMARY_COLOR}</span></IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="ion-no-padding" size="2" sizeXs="5" sizeLg="2" sizeMd="2" sizeSm="5">
                        <IonButton className="theme-selected-logo" style={{ "--background": siteColor }} />
                    </IonCol>
                    <IonCol className="ion-padding-start" size="3" sizeXs="7" sizeLg="3" sizeMd="3" sizeSm="7">
                        <IonButton className="button-text-property" color="dark" fill="outline" onClick={clearColor}>{onboarding.RIGHT_SIDE_RESET}</IonButton>
                        <IonButton className="color-selector">
                            <span className="color-use-button">{GLOBAL_USE_BUTTON}</span>
                            <input type="color" onChange={colorPicker} className="hidden" />
                        </IonButton>
                        <small>{onboarding.RIGHT_SIDE_COLOR_LOGO_NOTE2}</small>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="ion-no-padding">
                        <PreviewButton
                            siteColorProps={siteColor}
                            paddingProps="9px 20px 9px 20px"
                            heightProps="50px"
                            borderRadiusProps="5px"
                            backgroundProps="#494952"
                            labelProps={SETTING_COLOR_LOGO_PREVIEW_BUTTON}
                        />
                        <PreviewButton
                            siteColorProps={siteColor}
                            paddingProps="9px 20px 9px 20px"
                            heightProps="50px"
                            borderRadiusProps="5px"
                            backgroundProps="#e7e7e8"
                            labelProps={SETTING_COLOR_LOGO_PREVIEW_BUTTON}
                        />
                        <PreviewButton
                            siteColorProps={siteColor}
                            labelProps={SETTING_COLOR_LOGO_PREVIEW_BUTTON}
                            marginProps="13px 20px"
                            heightProps="31px"
                        />
                        <PreviewButton
                            labelProps={SETTING_COLOR_LOGO_PREVIEW_BUTTON}
                            marginProps="13px 20px"
                            boxShadowProps="none"
                            siteColorProps="#fff"
                            colorProps={siteColor}
                        />
                    </IonCol>
                </IonRow>
            </IonCol>
        </IonRow>
    );
}
export default SiteTheme;