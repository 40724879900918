//vendors
import React, { useEffect, useState } from "react";
import { IonSpinner } from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
//actions
import { postVerifyTokenRequest, putCreatePasswordRequest } from '../../_redux/actions/systemUsersActions';
// reducers
import { RootState } from "../../_redux/reducers/rootReducer";
//components
import TokenExpired from "../../components/CreatePassword/TokenExpired";
import CreatePasswordForm from "../../components/CreatePassword/CreatePasswordForm";
import ToastrHook from '../../components/common/toastr/Toastr';
import {
    SytemAppType,
    resetPasswordValidation,
    validateEmail,
} from "../../utils/FormValidations";
import { Hotel365Portal, PWD_UPDATE_SUCCESS, USER_MOD_FORM_INVAILD_EMAIL_ERR } from "../../constants/constant";
import { PostResetPassword } from "../../_redux/actions/resetPasswordActions";
import { Routes } from "../../constants/Routes";
import { portalType } from "../../auth.config";

const CreatePasswordContainer: React.FC<any> = () => {
    const dispatch = useDispatch();
    const [renderToastr] = ToastrHook();

    const [password, setPassword] = useState({
        newPassword: "",
        confirmPassword: "",
    });
    const [showRedirection, setShowRedirection] = useState(false);
    const useQuery = new URLSearchParams(useLocation().search);

    const tokenIdData = useQuery.get('token');
    const createPassword = useSelector((state: RootState) => { return state.createPassword }, shallowEqual);

    const { pending, errors, email, createPasswordPending, CreatePasswordErrors, createPasswordStatus } = createPassword;
    const [verifyEmail, setVerifyEmail] = useState({
        newEmail: "",
    });
    const [formErrors, setFormErrors] = useState({});
    const history = useHistory();

    const resetPassword = useSelector((state: RootState) => {
        return state.resetPassword;
    }, shallowEqual);

    const {
        error: resetPasswordError,
        postResetPasswordStatus,
    } = resetPassword;

    const onSubmitHandler = () => {
        const errorPassword = resetPasswordValidation(
            password.newPassword,
            password.confirmPassword
        );
        const isPasswordError = Object.keys(errorPassword).length;
        setFormErrors(errorPassword);
        if (isPasswordError === 0 && !errors) {
            const passwordData = { password: password.newPassword }
            dispatch(putCreatePasswordRequest(passwordData));
        }
    }

    const onChangeHandler = (e: any) => {
        setPassword((prevState: any) => ({
            ...prevState,
            [e.target.name]: e.detail.value,
        }));
    }


    const onCreatepasswordNewEmailSubmit = () => {
        const errorEmail = validateEmail(verifyEmail.newEmail);
        const isErrorEmail = Object.keys(errorEmail).length;
        setFormErrors({ ...errorEmail });
        const sytemPortaltype = SytemAppType(portalType)

        const request = {
            email: verifyEmail.newEmail,
            systemAppType: sytemPortaltype
        };
        if (isErrorEmail === 0) {
            if (isErrorEmail === 0) {                
                verifyEmail.newEmail && dispatch(PostResetPassword(request));
            }
        }
    }

    const renderComponent = () => {
        return (
            <>{
                errors ?
                    <TokenExpired
                        onCreatepasswordNewEmailSubmit={onCreatepasswordNewEmailSubmit}
                        newEmail={verifyEmail.newEmail}
                        formErrors={formErrors}
                        setEmail={setVerifyEmail}
                    /> :
                    <CreatePasswordForm
                        email={email}
                        onSubmitHandler={onSubmitHandler}
                        onChangeHandler={onChangeHandler}
                        showRedirection={showRedirection}
                        formErrors={formErrors}
                    />
            }
                {CreatePasswordErrors !== null && createPasswordPending === false && createPasswordStatus === '' && (
                    <TokenExpired
                        setEmail={setVerifyEmail}
                        createPasswordNewInviteErrorMessage={
                            Hotel365Portal.LINK_EXPIRED_ERR
                        }
                        onCreatepasswordNewEmailSubmit={
                            onCreatepasswordNewEmailSubmit
                        }
                        newEmail={verifyEmail.newEmail}
                        formErrors={formErrors}
                    />
                )}
            </>
        )
    }

    useEffect(() => {
        if (tokenIdData) {
            dispatch(postVerifyTokenRequest(tokenIdData))
        }
    }, []);

    useEffect(() => {
        if (CreatePasswordErrors !== null && createPasswordPending === false && createPasswordStatus === '') {
            renderToastr(Hotel365Portal.CREATE_PWD_ERROR_TOAST, 'danger')
        } else if (CreatePasswordErrors === null && createPasswordPending === false && createPasswordStatus) {
            setShowRedirection(true)
            renderToastr(PWD_UPDATE_SUCCESS, 'success');
        }
        if (resetPasswordError && resetPasswordError.status === 404) {
            renderToastr(USER_MOD_FORM_INVAILD_EMAIL_ERR, "danger");
        }
        if (postResetPasswordStatus && postResetPasswordStatus.status === 202) {
            history.push({ pathname: Routes.login.url });
            renderToastr(Hotel365Portal.newLinkUpdated, "success");
        }
    }, [createPassword, resetPassword])

    return (<>{pending ? <IonSpinner name="circles" className="spinner-circles" /> : renderComponent()}</>)
}

export default CreatePasswordContainer;
