//vendors
import React from "react";
//components
import ReportsList from "./ReportsList";
import ReportFilters from "./ReportFilters";
import SidePanelHOC from "../../components/Reports/SidePanelHOC";

export const ReportsSidePanel: React.FC<any> = ({ showReportList, setSelectedReport, setShowReportList, runReport }) => {
    const SidePanelReportList = SidePanelHOC(ReportsList);
    const SidePanelReportFilters = SidePanelHOC(ReportFilters);
    return (
        <>
            {showReportList ?
                <SidePanelReportList
                    setSelectedReport={setSelectedReport}
                    setShowReportList={setShowReportList}
                /> :
                <SidePanelReportFilters runReport={runReport} />}
        </>
    )
};
export default ReportsSidePanel;
