import { Hotel365Portal } from './../../constants/constant';
//utils
import { getLocalizedNumericValues } from "../../utils/index";

import {
    UpdateProductFilters,
    UpdateProductFiltersType,
    FetchMarketProducts,
    FetchMarketProductsSuccess,
    FetchMarketProductsFailure,
    FetchMarketProductsType,
    FetchGlobalProducts,
    FetchGlobalProductsFailure,
    FetchGlobalProductsSuccess,
    FetchGlobalProductsType,
    SaveMarketProduct,
    SaveMarketProductFailure,
    SaveMarketProductSuccess,
    SaveMarketProductType,
    SetLocationID,
    SetOrganizationID,
    SetLocationOrganzationIDType,
    UpdateManageColumns,
    UpdateManageColumnsType,
    ResetGlobalProductsType,
    ResetGlobalProducts,
    UpdateProductSettings,
    UpdateProductSettingsType,
    FetchProductDetails,
    FetchProductDetailsFailure,
    FetchProductDetailsSuccess,
    FetchProductDetailsType,
    PatchMarketProduct,
    PatchMarketProductFailure,
    PatchMarketProductSuccess,
    PatchMarketProductType,
    DeleteMarketProduct,
    DeleteMarketProductFailure,
    DeleteMarketProductSuccess,
    DeleteMarketProductType,
    SaveSwapProduct,
    SaveSwapProductSuccess,
    SaveSwapProductFailure,
    SaveSwapProductType,
    FetchGlobalProductsCategories,
    FetchGlobalProductsCategoriesSuccess,
    FetchGlobalProductsCategoriesFailure,
    FetchGlobalProductsCategoriesType,
    ResetMarketProductsType,
    ResetMarketProducts,
    PostPublishProduct,
    PostPublishProductSuccess,
    PostPublishProductFailure,
    PostPublishProductType,
    ShowPublishProduct,
    ShowPublishProductType,
    FetchMarketProductsForReportType,
    FetchMarketProductsForReportSuccess,
    FetchMarketProductsForReportRequest,
    FetchMarketProductsForReportFailure,
    SaveSelfOperatedProduct,
    SaveSelfOperatedProductSuccess,
    SaveSelfOperatedProductFailure,
    SaveSelfOperatedProductType
} from "../actions/productActions";
import { addZeroes, getFinalProductList } from "../../utils";
import { SaveGlobalProduct } from "../../models/SaveGlobalProduct";
import {
    GlobalProduct,
    ProductPagination,
    GlobalProductCatgeories,
} from "../../models/GlobalProducts";
import {
    MarketProduct,
} from "../../models/MarketProduct";

export type ProductTypes =
    | UpdateProductFiltersType
    | FetchMarketProductsType
    | FetchGlobalProductsType
    | SaveMarketProductType
    | SetLocationOrganzationIDType
    | UpdateManageColumnsType
    | ResetGlobalProductsType
    | UpdateProductSettingsType
    | FetchProductDetailsType
    | PatchMarketProductType
    | DeleteMarketProductType
    | SaveSwapProductType
    | ResetMarketProductsType
    | FetchGlobalProductsCategoriesType
    | PostPublishProductType
    | ShowPublishProductType
    | SaveSelfOperatedProductType
    | FetchMarketProductsForReportType

const initialState = {
    categoryFilters: [],
    pending: true,
    products: [],
    marketProducts: [],
    marketProductsForReport: {
        records: [],
        error: null,
        pending: false,
    },
    paginate: {},
    error: null,
    globalProducts: [],
    globalPaginate: {} as ProductPagination,
    createdMarketProduct: {},
    locationID: "",
    organizationID: "",
    manageColumns: [],
    // manageColumns: ["deposit", "tax", "tax2", "tax3", "tax4"],
    productSettings: {
        globalMargin: "0%",
        allowCategoryMargin: false,
        allowProductMargin: false,
        allowRoundToNines: false,
        taxRate1Name: "",
        taxRate2Name: "",
        taxRate3Name: "",
        taxRate4Name: "",
    },
    marketProductDetail: {} as MarketProduct,
    patchedMarketProduct: {} as MarketProduct,
    removedMarketProduct: {},
    saveSwappedProduct: {} as MarketProduct,
    globalProductCategories: [] as GlobalProductCatgeories["items"],
    globalProductCategoriesPaginate: {} as ProductPagination,
    publishProduct: {},
    showPublishProduct: false,
    selfOperatedProducts: {}
};
export interface IProductState {
    categoryFilters: string[];
    pending: boolean;
    products: Record<string, unknown>[];
    marketProducts: Record<string, unknown>[];
    marketProductsForReport: Record<string, unknown>;
    paginate: Record<string, unknown>;
    error: Record<string, any> | null;
    globalProducts: GlobalProduct[];
    globalPaginate: ProductPagination;
    createdMarketProduct: Record<string, unknown>;
    locationID: string;
    organizationID: string;
    manageColumns: string[];
    productSettings: Record<string, any>;
    marketProductDetail: MarketProduct;
    patchedMarketProduct: MarketProduct;
    removedMarketProduct: Record<string, unknown>;
    saveSwappedProduct: MarketProduct;
    globalProductCategories: GlobalProductCatgeories["items"];
    globalProductCategoriesPaginate: ProductPagination;
    publishProduct: Record<string, any>;
    showPublishProduct: boolean;
    selfOperatedProducts: Record<string, unknown>;
}

const strategies = {
    UPDATE_PRODUCT_FILTERS: updateProductFilters,
    FETCH_MARKET_PRODUCTS_REQUEST: fetchMarketGlobalProducts,
    FETCH_MARKET_PRODUCTS_SUCCESS: fetchMarketProductsSuccess,
    FETCH_MARKET_PRODUCTS_FAILURE: fetchGlobalProductsFailure,
    FETCH_GLOBAL_PRODUCTS_REQUEST: fetchMarketGlobalProducts,
    FETCH_GLOBAL_PRODUCTS_SUCCESS: fetchGlobalProductsSuccess,
    FETCH_GLOBAL_PRODUCTS_FAILURE: fetchGlobalProductsFailure,
    SAVE_MARKET_PRODUCT_REQUEST: fetchMarketGlobalProducts,
    SAVE_MARKET_PRODUCT_SUCCESS: saveMarketProduct,
    SAVE_MARKET_PRODUCT_FAILURE: saveMarketProductFailure,
    SET_LOCATION_ID: setLocationId,
    SET_ORGANIZATION_ID: setOrganizationId,
    UPDATE_MANAGE_COLUMN: updateProductManageColumns,
    RESET_GLOBAL_PRODUCTS: resetGlobalProducts,
    UPDATE_PRODUCT_SETTINGS: updateProductSettings,
    FETCH_PRODUCT_DETAILS_REQUEST: fetchMarketProductDetails,
    FETCH_PRODUCT_DETAILS_SUCCESS: fetchMarketProductDetailsSuccess,
    FETCH_PRODUCT_DETAILS_FAILURE: fetchGlobalProductsFailure,
    PATCH_MARKET_PRODUCT_REQUEST: fetchMarketGlobalProducts,
    PATCH_MARKET_PRODUCT_SUCCESS: patchMarketProductSuccess,
    PATCH_MARKET_PRODUCT_FAILURE: fetchGlobalProductsFailure,
    DELETE_MARKET_PRODUCT: fetchMarketGlobalProducts,
    DELETE_MARKET_PRODUCT_SUCCESS: deleteMarketProduct,
    DELETE_MARKET_PRODUCT_FAILURE: fetchGlobalProductsFailure,
    SAVE_SWAP_PRODUCT_REQUEST: fetchMarketGlobalProducts,
    SAVE_SWAP_PRODUCT_SUCCESS: saveSwappedProductSuccess,
    SAVE_SWAP_PRODUCT_FAILURE: swapProductFailure,
    FETCH_GLOBAL_PRODUCTS_CATEGORIES_REQUEST: fetchMarketGlobalProducts,
    FETCH_GLOBAL_PRODUCTS_CATEGORIES_SUCCESS: fetchGlobalProductCategories,
    FETCH_GLOBAL_PRODUCTS_CATEGORIES_FAILURE: fetchGlobalProductsFailure,
    POST_PUBLISH_PRODUCT_REQUEST: fetchMarketGlobalProducts,
    POST_PUBLISH_PRODUCT_SUCCESS: triggerCompilePrice,
    POST_PUBLISH_PRODUCT_FAILURE: fetchGlobalProductsFailure,
    RESET_MARKET_PRODUCTS: resetMarketProducts,
    SHOW_PUBLISH_PRODUCT: setShowPublishProduct,
    FETCH_MARKETPRODUCTS_REPORT_REQUEST: fetchMarketProductsForReportRequest,
    FETCH_MARKETPRODUCTS_REPORT_SUCCESS: fetchMarketProductsReportSuccess,
    FETCH_MARKETPRODUCTS_REPORT_FAILURE: fetchMarketProductsForReportFailure,
    POST_SELF_OPERATED_PRODUCT_REQUEST: fetchMarketGlobalProducts,
    POST_SELF_OPERATED_PRODUCT_SUCCESS: saveSelfOperatedProductsSuccess,
    POST_SELF_OPERATED_PRODUCT_FAILURE: fetchGlobalProductsFailure,
    default: (state: IProductState) => state,
};

export default (
    state: IProductState = initialState,
    action: ProductTypes
): IProductState => {
    return (strategies[action.type] || strategies.default)(
        state,
        action as never
    );
};

function updateProductFilters(
    state: IProductState,
    action: UpdateProductFilters
) {
    return Object.assign({}, state, {
        ...state,
        categoryFilters: action.info,
    });
}

function fetchMarketGlobalProducts(
    state: IProductState,
    _action:
        | FetchMarketProducts
        | FetchGlobalProducts
        | SaveMarketProduct
        | FetchProductDetails
        | PatchMarketProduct
        | DeleteMarketProduct
        | SaveSwapProduct
        | FetchGlobalProductsCategories
        | SaveSelfOperatedProduct
        | PostPublishProduct
) {
    return {
        ...state,
        error: null,
        products: _action.type === 'FETCH_MARKET_PRODUCTS_REQUEST' ? [] :state.products,
        marketProducts: _action.type === 'FETCH_MARKET_PRODUCTS_REQUEST' ? [] :state.marketProducts,
        paginate: _action.type === 'FETCH_MARKET_PRODUCTS_REQUEST' ? {}: state.paginate,
    };
}
function fetchMarketProductDetails(
    state: IProductState,
    _action: FetchProductDetails
) {
    return {
        ...state,
        marketProductDetail: {} as MarketProduct,
        error: null,
    };
}

function fetchMarketProductsSuccess(state: IProductState, action: FetchMarketProductsSuccess) {
    const ids = action.data.products.items.map((o: SaveGlobalProduct) => o.productLocationId)
    const filtered = action.data.products.items.filter(({ productLocationId }: SaveGlobalProduct, index: number) => !ids.includes(productLocationId, index + 1));

    const finalProductList = filtered.map((item: SaveGlobalProduct) => {
        const tempItem = { ...item };

        tempItem.refillAction = item.refillAction || "BELOW_MAXIMUM";
        return tempItem;
    }
    );
    return {
        ...state,
        pending: false,
        products: finalProductList,
        marketProducts: JSON.parse(JSON.stringify(finalProductList)),
        paginate: action.data.products.metadata.pagination,
        error: null,
    };
}

function fetchGlobalProductsSuccess(
    state: IProductState,
    action: FetchGlobalProductsSuccess
) {
    let products: any[] = [],
        data: GlobalProduct[] = [];
    /**to Avoid duplicates from an array */
    data = state.globalProducts
        .filter((aa) => !action.data.products.items.find((bb: Record<string, any>) => aa?.productId === bb?.productId))
        .concat(action.data.products.items);

    if (data.length > 0) {
        products = data
            .map(function (item: GlobalProduct) {
                // Sometimes will be getting productprice as 0, which should not execute the below block.So, we are checking null and undefined as separate instead of !productPrice
                if (item.productPrice == null || item.productPrice == undefined) {
                    return {
                        productName: item.productName,
                        productPrice: item.price,
                        productId: item.productId,
                        product: item,
                    };
                } else {
                    return item;
                }
            });
    }
    return {
        ...state,
        pending: false,
        globalProducts: products.filter((parameter) => !parameter?.product?.isDisabled),
        globalPaginate: action.data.products.metadata.pagination,
        error: null,
    };
}

function fetchGlobalProductsFailure(
    state: IProductState,
    action:
        | FetchGlobalProductsFailure
        | FetchMarketProductsFailure
        | FetchProductDetailsFailure
        | PatchMarketProductFailure
        | DeleteMarketProductFailure
        | FetchGlobalProductsCategoriesFailure
        | SaveSelfOperatedProductFailure
        | PostPublishProductFailure
) {
    return {
        ...state,
        pending: false,
        error: action.error,
    };
}

function saveMarketProduct(
    state: IProductState,
    action: SaveMarketProductSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        createdMarketProduct: action.data,
        error: null,
    });
}

function setLocationId(state: IProductState, action: SetLocationID) {
    return Object.assign({}, state, {
        ...state,
        locationID: action.info,
    });
}

function setOrganizationId(state: IProductState, action: SetOrganizationID) {
    return Object.assign({}, state, {
        ...state,
        organizationID: action.info,
    });
}

function updateProductManageColumns(
    state: IProductState,
    action: UpdateManageColumns
) {
    return {
        ...state,
        manageColumns: action.info,
    };
}

function resetGlobalProducts(
    state: IProductState,
    action: ResetGlobalProducts
) {
    return Object.assign({}, state, {
        ...state,
        globalProducts: [],
        globalPaginate:{},
        globalProductCategories: [],
    });
}

function updateProductSettings(
    state: IProductState,
    action: UpdateProductSettings
) {
    return Object.assign({}, state, {
        ...state,
        productSettings: { ...state.productSettings, ...action.info },
    });
}


function fetchMarketProductDetailsSuccess(state: IProductState,action: FetchProductDetailsSuccess) {
    action.data.refillAction = action.data.refillAction || 'BELOW_MAXIMUM'
    return Object.assign({}, state, {
        ...state,
        pending: false,
        marketProductDetail: action.data,
        error: null,
    });
}

function patchMarketProductSuccess(
    state: IProductState,
    action: PatchMarketProductSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        patchedMarketProduct: action.data,
        error: null,
    });
}


function deleteMarketProduct(
    state: IProductState,
    action: DeleteMarketProductSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        removedMarketProduct: action.data,
        error: null,
    });
}

function saveSwappedProductSuccess(
    state: IProductState,
    action: SaveSwapProductSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        saveSwappedProduct: action.data,
        error: null,
    });
}

function fetchGlobalProductCategories(
    state: IProductState,
    action: FetchGlobalProductsCategoriesSuccess
) {
    let categories: any[] = [];
    /**to Avoid duplicates from an array */
    categories = state.globalProductCategories
        .filter((aa) => !action.data.items.find((bb) => aa === bb))
        .concat(action.data.items);

    return {
        ...state,
        pending: false,
        globalProductCategories: categories,
        globalProductCategoriesPaginate: action.data.metadata.pagination,
        error: null,
    };
}

function resetMarketProducts(
    state: IProductState,
    action: ResetMarketProducts
) {
    return {
        ...state,
        removedMarketProduct: {},
        createdMarketProduct: {},
        patchedMarketProduct: {} as MarketProduct,
        saveSwappedProduct: {} as MarketProduct,
        publishProduct: {},
        selfOperatedProducts: {},
        error: null,
    }
}

function swapProductFailure(
    state: IProductState,
    action: SaveSwapProductFailure
) {
    const { status } = action.error as Record<string, any>;
    let swapProducterror = {} as Record<string, any> | null;
    if (status === 409) {
        swapProducterror = { 'type': 'swap_duplicate_failure', error: action.error }
    } else {
        swapProducterror = action.error;
    }

    return {
        ...state,
        pending: false,
        error: swapProducterror,
    };
}

function saveMarketProductFailure(
    state: IProductState,
    action: SaveMarketProductFailure
) {
    const { status } = action.error as Record<string, any>;
    let saveMarketProductError = {} as Record<string, any> | null;
    if (status === 409) {
        saveMarketProductError = { 'type': 'save_market_duplicate_failure', error: action.error }
    } else {
        saveMarketProductError = action.error;
    }

    return {
        ...state,
        pending: false,
        error: saveMarketProductError,
    };
}

function triggerCompilePrice(
    state: IProductState,
    action: PostPublishProductSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        publishProduct: action.data,
        error: null,
    });
}

function setShowPublishProduct(state: IProductState, action: SetOrganizationID) {
    return Object.assign({}, state, {
        ...state,
        showPublishProduct: action.info,
    });
}

function fetchMarketProductsForReportRequest(state: IProductState, _action: FetchMarketProductsForReportRequest) {
    return {
        ...state,
        marketProductsForReport: { items: [], pending: true, error: null },
    };
}

function fetchMarketProductsReportSuccess(state: IProductState, action: FetchMarketProductsForReportSuccess) {
    const { items } = action;
    const ids = items.map((o: SaveGlobalProduct) => o.productLocationId)
    const filtered = items.filter(({ productLocationId }: SaveGlobalProduct, index: number) => !ids.includes(productLocationId, index + 1))
    const finalProductList = getFinalProductList(filtered);
    return {
        ...state,
        marketProductsForReport: { items: JSON.parse(JSON.stringify(finalProductList)), pending: false, error: null },
    };
}

function fetchMarketProductsForReportFailure(state: IProductState, action: FetchMarketProductsForReportFailure) {
    return {
        ...state,
        marketProductsForReport: {
            items: [],
            pending: false,
            error: action.error,
        },
    };
}

function saveSelfOperatedProductsSuccess(
    state: IProductState,
    action: SaveSelfOperatedProductSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        selfOperatedProducts: action.data,
        error: null,
    });
}