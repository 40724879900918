import { combineReducers } from "redux";
import errorReducer from './errorReducer';
import productReducer from "./productReducer";
import productSettingsPanelReducer from './productSettingsPanelReducer';
import profileReducer from "./profileReducer";
import locationsReducer from "./locationsReducer";
import createPasswordReducer from "./createPasswordReducer";
import systemUsersReducer from './systemUsersReducer';
import userManagementReducer from './userManagementReducer';
import resetPasswordReducer from './resetPasswordReducer';
import emailUpdateReducer from "./emailUpdateReducer";
import orderReducer from "./orderReducer";
import reportsReducer from "./reportsReducer";
import posReducer from './posReducer';
import paymentReducer from "./paymentReducer";
import consumerReducer from "./consumerReducer";

const rootReducer = combineReducers({
    error: errorReducer,
    product: productReducer,
    productSettingsPanel: productSettingsPanelReducer,
    profile: profileReducer,
    systemUsers: systemUsersReducer,
    locations: locationsReducer,
    createPassword: createPasswordReducer,
    UserManagement: userManagementReducer,
    resetPassword: resetPasswordReducer,
    emailUpdate: emailUpdateReducer,
    order: orderReducer,
    reports: reportsReducer,
    pos: posReducer,
    payment: paymentReducer,
    consumer:consumerReducer
});
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
