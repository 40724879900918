import React, { useEffect, useMemo, useState } from "react";
import {
    IonButton,
    IonChip,
    IonCol,
    IonFooter,
    IonHeader,
    IonLabel,
    IonModal,
    IonRow,
    IonTitle,
    isPlatform,
    useIonRouter,
} from "@ionic/react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { ConsumerActionsDescriberData } from "../../models/ConsumerActions";
import { validateConsumerActions } from "../../utils";
import { getDescriber } from "./Descriptor/DescriptorType";
import {
    Hotel365Portal,
    ClientPortal,
    FOOTER_CANCEL_BUTTON,
    APPLY_BUTTON,
} from "../../constants/constant";
import { RootState } from "../../_redux/reducers/rootReducer";
import { Routes } from "../../constants/Routes";
import { fetchPayrollGrp, fetchSubsidyGrp, postConsumerBulkAction, resetFetchConsumers } from "../../_redux/actions/consumerActions";

export const ConsumerListActionsPrompt: React.FC<any> = (props) => {
    const router = useIonRouter();
    const dispatch = useDispatch();
    const { actionName, setConsumerAction } = props;
    const [openModal, setOpenModal] = useState(false);
    const [fundAmount, setFundAmount] = useState("");
    const [fundsPaymentMethod, setFundsPaymentMethod] = useState("");
    const [topOffBalance, setTopOffBalance] = useState("");
    const [topOffPaymentMethod, setTopOffPaymentMethod] = useState("");
    const [subsidyGrp, setSubsidyGrp] = useState("");
    const [resetPIN, setResetPIN] = useState("");
    const [payCycleGrp, setPayCycleGrp] = useState("");
    const [actionValidatorResult, setActionValidatorResult] = useState(
        null as any
    );
    const payment = useSelector((state: RootState) => {
        return state.payment;
    }, shallowEqual);
    const { availableCards } = payment;
    const { cards, error: availableCardsErr } = availableCards;

    const consumerLst = useSelector((state: RootState) => {
        return state.consumer;
    }, shallowEqual);

    const { error: consumerErr, payrollGrps, subsidyGrps, consumerListReq } = consumerLst;
    const profile = useSelector((state: RootState) => state.profile, shallowEqual);
    const { currentProfile } = profile;
    const { currency } = currentProfile;
    const resetValues = () => {
        setFundAmount("");
        setFundsPaymentMethod("");
        setTopOffBalance("");
        setTopOffPaymentMethod("");
        setSubsidyGrp("");
        setResetPIN("");
        setPayCycleGrp("");
        setConsumerAction("");
        dispatch(resetFetchConsumers("resetResendemail"));
        if(props.locationId){
            dispatch(fetchPayrollGrp(props.locationId));
            dispatch(fetchSubsidyGrp(props.locationId));
        }
    };
    const closeModal = () => {
        setOpenModal(false);
        resetValues();
        setActionValidatorResult(null);
    };
    const isMobileView = isPlatform("mobile");
    const isTabletView = isPlatform("tablet");
    const isDesktopView = isPlatform("desktop");

    useEffect(() => {
        if (actionName) setOpenModal(true);
        else closeModal()
    }, [actionName]);

    const updateResetPin = (_value: string) => {
        setResetPIN(_value);
    };
    const updateFundsAmount = (_value: string) => {
        setFundAmount(_value);
    };
    const updateFundsPaymentMethod = (_value: string) => {
        setFundsPaymentMethod(_value);
    };
    const updateTopOffBalance = (_value: string) => {
        setTopOffBalance(_value);
    };
    const updateTopOffPaymentMethod = (_value: string) => {
        setTopOffPaymentMethod(_value);
    };
    const updateSubsidy = (_value: string) => {
        setSubsidyGrp(_value);
    };
    const updatePayCycleGrp = (_value: string) => {
        setPayCycleGrp(_value);
    };
    const getActionType = () =>{
        return actionName === ClientPortal.consumerBulkPayChangeVal ? {type:ClientPortal.consumerBulkPaycycleReqVal, value: payCycleGrp} : (actionName === ClientPortal.consumerBulkAssignSubsidyVal ? {type:ClientPortal.consumerBulkSubsidygrpReqVal, value:subsidyGrp} : {type:ClientPortal.consumerBulkPinresetReqVal, value:''});
    }

    const validateActions = () => {
        const result = validateConsumerActions(actionName, {
            fundAmount,
            fundsPaymentMethod,
            topOffBalance,
            topOffPaymentMethod,
            subsidyGrp,
            resetPIN,
            payCycleGrp,
        });
        setActionValidatorResult(result);
        if (result && result.isValid) {
            if(props.type === 'detail'){
                props.applyFunds({fundAmount, fundsPaymentMethod})
            } else{
            let request = {
                "accountIds": props.consumerSelectRow === ClientPortal.consumer_select_row_all_lbl?.toLowerCase() ? null: props.consumers,
                "actionType": getActionType().type,
                "isAllAccounts": props.consumerSelectRow === ClientPortal.consumer_select_row_all_lbl?.toLowerCase() ? true: false,
                "locationIds": consumerListReq?.locationIds,
                "q": consumerListReq?.q,
                "searchBy": consumerListReq?.searchBy
            } as any;
            if(getActionType().type === ClientPortal.consumerBulkPaycycleReqVal){
                request['payrollDeductGroupId'] = getActionType().value
            } else if(getActionType().type === ClientPortal.consumerBulkSubsidygrpReqVal){
                request['subsidyGroupId'] = getActionType().value
            }
              dispatch(postConsumerBulkAction(request))
        }
        }
    };

    const redirectToPayment = () => {
        closeModal();
        router.push(Routes.dashboard.paymentUrl);
    };

    const isNoPaymentMethodAvailable = useMemo(() => {
        const options = Object.values(ClientPortal.noPaymentMethods);
        return (
            options.indexOf(actionName) > -1 &&
            cards.length === 0 &&
            !availableCardsErr
        );
    }, [actionName, cards, availableCardsErr]);

    const describerProps = {
        fundAmount,
        fundsPaymentMethod,
        topOffBalance,
        topOffPaymentMethod,
        subsidyGrp,
        resetPIN,
        payCycleGrp,
        updateResetPin,
        updateFundsAmount,
        updateFundsPaymentMethod,
        updateTopOffBalance,
        updateTopOffPaymentMethod,
        updateSubsidy,
        updatePayCycleGrp,
        result: actionValidatorResult,
        isNoPaymentMethodAvailable,
        payrollGrps,
        subsidyGrps,
        cards,
        currency
    };
    const title = getDescriber(actionName) &&
    getDescriber(actionName).titleDescriptor()

    return (
        <>
            <div>
                <IonModal
                    isOpen={openModal}
                    className={`${!isDesktopView && !isTabletView
                        ? "user-management-mobile"
                        : ""
                        } ${Object.values(ClientPortal.noPaymentMethods).indexOf(
                            actionName
                        ) === -1
                            ? "order-modal-pop"
                            : "order-modal-pop top-off-modal"
                        } ${isNoPaymentMethodAvailable ? "no-payment-modal" : ""}`}
                    onDidDismiss={() => closeModal()}
                >
                    <IonHeader className="header">
                        <IonRow className="primary-header">
                            <IonTitle>
                                {props.type === 'detail' ? title?.slice(5):title}
                            </IonTitle>
                            <IonChip
                                color="primmary"
                                className="ion-button"
                                onClick={() => closeModal()}
                            >
                                <p className="cancel">&times;</p>
                            </IonChip>
                        </IonRow>
                    </IonHeader>
                    <form
                        className={
                            !isDesktopView && !isTabletView
                                ? "form-mobile-view"
                                : "order-form-body"
                        }
                    >
                        <IonRow className="modal-body">
                            <IonCol offset="1"></IonCol>
                        </IonRow>
                        {/**content */}
                        {getDescriber(actionName) &&
                            getDescriber(actionName).contentDescriptor(
                                describerProps
                            )}
                    </form>
                    <IonFooter className="footer">
                        <IonRow>
                            <IonCol offset="4" sizeMd="4">
                                <IonButton
                                    color="gray"
                                    expand="block"
                                    className="btn-text-cases-cancel"
                                    onClick={() => closeModal()}
                                >
                                    {FOOTER_CANCEL_BUTTON}
                                </IonButton>
                            </IonCol>

                            {isNoPaymentMethodAvailable ? (
                                <IonCol sizeMd="4">
                                    <IonButton
                                        color="primary"
                                        expand="block"
                                        type="submit"
                                        className="btn-text-cases-save"
                                        onClick={redirectToPayment}
                                    >
                                        {ClientPortal.consumer_go_to_payment}
                                    </IonButton>
                                </IonCol>
                            ) : (
                                <IonCol sizeMd="4">
                                    <IonButton
                                        color="primary"
                                        expand="block"
                                        type="submit"
                                        className="btn-text-cases-save"
                                        onClick={validateActions}
                                    >
                                        {APPLY_BUTTON}
                                    </IonButton>
                                </IonCol>
                            )}
                        </IonRow>
                    </IonFooter>
                </IonModal>
            </div>
        </>
    );
};
