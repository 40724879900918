import HpDescriptor from './dashboard-hp';
import CpDescriptor from './dashboard-cp';
import { PortalType } from '../../../constants/constant';

export type GetDescriberType = {
  routeDescriptor: () => React.ReactNode;
};

const typeDescriber = {
  [PortalType.HP]: HpDescriptor,
  [PortalType.CP]: CpDescriptor,
};

export function getDescriber(model: string): GetDescriberType {
  return typeDescriber[model as keyof typeof typeDescriber];
}
