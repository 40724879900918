// vendors
import React, { useState, useEffect, useCallback } from "react";
import {
    IonCol,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonNote,
    IonRow,
    isPlatform,
} from "@ionic/react";
import {
    chevronBackCircleOutline,
    chevronForwardCircleOutline,
} from "ionicons/icons";
// components
import CounterFeature from "../../Container/CounterFeature";
import ToggleButton from "../../Container/ToggleSwitchFeature/ToggleButton";
//constants
import {
    onBoardingRightSection,
    toggleOptionLabels,
    SETTING_MARKET_INFO,
    MARKET_HEADER,
    MARKET_CITY,
    MARKET_NAME_HEADER,
    MARKET_PROPERTY,
    MARKET_STATE,
    MARKET_STREET,
    MARKET_ZIP,
    SETTING_LARGE_MARKET,
    ONBOARDING_SQUARE_FEET
} from "../../../constants/constant";
//utils
import { getQuestionsForToggle, isMarketNameValid } from '../../../../src/utils';
import { LocationInfoForm } from '../../../models/LocationFormProps';

const { questionaries } = onBoardingRightSection;
const LocationForm: React.FC<LocationInfoForm> = (props) => {
    const {
        zip,
        marketName,
        city,
        state,
        street,
        marketSizeInSqFt,
        setOnboarding,
        sidenavExpanded,
        setSidenavExpanded,
        isFullServiceRestaurant,
        isQuickServiceRestaurant,
        isRoomServiceAvailable,
        doesBarOrRestaurantOfferAlcohol,
        isCoffeeShopBar,
        hasPreferredBeverageCompany,
        formErrors,
        setFormErrors,
        roleId
    } = props;
    const questionariesValues = {
        "isFullServiceRestaurant": isFullServiceRestaurant,
        "isQuickServiceRestaurant": isQuickServiceRestaurant,
        "isRoomServiceAvailable": isRoomServiceAvailable,
        "doesBarOrRestaurantOfferAlcohol": doesBarOrRestaurantOfferAlcohol,
        "isCoffeeShopBar": isCoffeeShopBar,
        "hasPreferredBeverageCompany": hasPreferredBeverageCompany,
    };
    const updatedQuestionaries = getQuestionsForToggle(questionariesValues, onBoardingRightSection.questionaries);
    const [questions, setQuestions] = useState(updatedQuestionaries);
    const { errorMessage } = formErrors;
    const isDesktopView = isPlatform("desktop");
    const isTabletView = isPlatform("tablet");
    const isMobileView = isPlatform("mobile");
    const onChangeHandler = (question: string, checked: boolean) => {
        setOnboarding((prevState: any) => {
            return {
                ...prevState,
                [question]: checked
            }
        })
        setQuestions({ ...questions, [question]: { ...questions[question], checked } })
    }

    const incSquareFeetCounter = () => {
        setOnboarding((prevState: any) => {
            return {
                ...prevState,
                marketSizeInSqFt: Number(prevState.marketSizeInSqFt) + Number(1)
            }
        })
    }

    const decSquareFeetCounter = () => {
        if (marketSizeInSqFt >= 1) {
            setOnboarding((prevState: any) => {
                return {
                    ...prevState,
                    marketSizeInSqFt: Number(prevState.marketSizeInSqFt) - Number(1)
                }
            })
        }
    }
    const setSquareFeet = (e: any) => {
        if (e.target.value >= 0) {
            setOnboarding((prevState: any) => {
                return {
                    ...prevState,
                    marketSizeInSqFt: e.target.value
                }
            })
        }
    }

    const toggleAccordion = () => {
        setSidenavExpanded(!sidenavExpanded);
    };

    useEffect(() => {
        const questionariesValues = {
            "isFullServiceRestaurant": isFullServiceRestaurant,
            "isQuickServiceRestaurant": isQuickServiceRestaurant,
            "isRoomServiceAvailable": isRoomServiceAvailable,
            "doesBarOrRestaurantOfferAlcohol": doesBarOrRestaurantOfferAlcohol,
            "isCoffeeShopBar": isCoffeeShopBar,
            "hasPreferredBeverageCompany": hasPreferredBeverageCompany,
        };
        const updatedQuestionaries = getQuestionsForToggle(questionariesValues, questionaries);
        setQuestions(updatedQuestionaries);
    }, [isFullServiceRestaurant,
        isQuickServiceRestaurant,
        isRoomServiceAvailable,
        doesBarOrRestaurantOfferAlcohol,
        isCoffeeShopBar,
        hasPreferredBeverageCompany]);

    const onMarketNameChange = (e: any) => {
        const { errorMessage, isValid } = isMarketNameValid(e.detail.value);
        setOnboarding((prevState: any) => {
            return {
                ...prevState,
                marketName: e.detail.value,
            };
        });
        setFormErrors({ isValid, errorMessage });
    }
    return (
        <IonContent className="scroll-content">
            <IonRow>
                <IonCol>
                    <IonRow className="ion-padding">
                        <IonCol onClick={toggleAccordion} style={{ display: 'flex' }}>
                            <IonIcon
                                size="large"
                                className="settings-back-icon click-cursor"
                                md={sidenavExpanded ? chevronBackCircleOutline : chevronForwardCircleOutline} />
                            <h2 className="header-text"><b>{SETTING_MARKET_INFO}</b></h2>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-padding-start'>
                        <IonCol>
                            <h1 style={{ margin: '0' }}><b className="font18">{MARKET_HEADER}</b></h1>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-padding-start'>
                        <IonCol sizeLg='2' sizeMd='2.5'>
                            <p className="location-tab"><b>{MARKET_STREET}</b></p><p>{street || "-"}</p>
                        </IonCol>
                        <IonCol sizeLg='2' sizeXs='12' sizeMd='2.5'>
                            <p className="location-tab"><b>{MARKET_CITY}</b></p><p>{city || "-"}</p>
                        </IonCol>
                        <IonCol sizeLg='2' sizeXs='12' sizeMd='2.5'>
                            <p className="location-tab"><b>{MARKET_STATE}</b></p><p>{state || "-"}</p>
                        </IonCol>
                        <IonCol sizeLg='2' sizeXs='12' sizeMd='2.5'>
                            <p className="location-tab"><b>{MARKET_ZIP}</b></p><p>{zip || "-"}</p>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className='ion-padding-start' sizeXs='12'>
                            <IonNote color="medium" className="location-tab">
                                <span className={errorMessage ? "validation-error-label" : ""}>*</span>
                                <b>{MARKET_NAME_HEADER}</b>
                            </IonNote>
                            <IonInput
                                aria-label={MARKET_NAME_HEADER}
                                className={`setting-form-field ${isDesktopView || isTabletView ? 'location-text-input-size' : 'location-text-input-size-mobile'}`}
                                style={{ minHeight: '35px' }}
                                value={marketName}
                                disabled={roleId && roleId.length > 0 && roleId.find(value => value === '8') ? false : true}
                                onIonChange={onMarketNameChange} />
                            <IonRow className='short-logo-note-top'>
                                <IonCol>
                                    {errorMessage && <p className="error-alert-location">{errorMessage}</p>}
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-padding-start ion-padding-top'>
                        <IonCol>
                            <h1 className="font18"><b>{MARKET_PROPERTY}</b></h1>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-padding-start'>
                        <IonCol>
                            <div className={isDesktopView || isTabletView ? "question" : "question-map"}>
                                {Object.keys(questions).map((question: string) => {
                                    const { label, name, id, checked } = questions[question as keyof typeof questions];
                                    return (
                                        <div key={id}>
                                            <IonNote color="medium" className="questionnaire-tab">
                                                <b>{label}</b>
                                            </IonNote>
                                            <IonItem lines="none">
                                                <ToggleButton
                                                    id={id}
                                                    name={name}
                                                    onChange={(
                                                        checked: boolean
                                                    ) => {
                                                        onChangeHandler(
                                                            question,
                                                            checked
                                                        );
                                                    }}
                                                    checked={checked}
                                                    optionLabels={toggleOptionLabels as any} />
                                            </IonItem>
                                        </div>);
                                })}
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className='ion-padding-start'>
                            <h1><b className="font18">{SETTING_LARGE_MARKET}</b></h1>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className='ion-padding-start'>
                            <IonNote className="questionnaire-tab" color="primary"><b>{ONBOARDING_SQUARE_FEET}</b></IonNote>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-padding-start ion-padding-bottom'>
                        <IonCol>
                            <CounterFeature
                                counter={marketSizeInSqFt}
                                setCounter={setSquareFeet}
                                increaseCounter={incSquareFeetCounter}
                                descreseCounter={decSquareFeetCounter} />
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>
        </IonContent>
    );
};

export default LocationForm;