import React, { memo, useState } from 'react'

interface ToolTipProps {
  text: string;
  isMarginNeed?:boolean
}
export const ToolTip: React.FC<ToolTipProps> = memo((props) => {
  const { children, text = '', isMarginNeed = false } = props
  const [show, setShow] = useState(false)
  return (
    <React.Fragment>
      <div
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        className={isMarginNeed ? 'mt4' : ''}
      >
        {children}
      </div>
      <div className="tooltip-container">
        <div className={show ? 'tooltip-box visible' : 'tooltip-box'}>
          {text}
          <span className="tooltip-arrow" />
        </div>
      </div>
    </React.Fragment>
  )
})
