//vendors
import { IonCol, IonInput, IonItem, IonLabel, IonRow, IonLoading, isPlatform } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//hooks
import useScript from '../../custom-hooks/useScripts';
//actions
import { fetchPublicKeyRequest, getUserData, addCardFlow } from '../../_redux/actions/paymentActions';
//reducers
import { RootState } from '../../_redux/reducers/rootReducer';
//constants
import { CONSUMER_DETECT_UNSAVE_MESSAGE, DETECT_UNSAVE_HEADER, DISCARD, FOOTER_SAVE_BUTTON } from '../../constants/constant';
import { LOADER_MSG, paymentConstants, paymentFormStyle } from './constants';
import { Routes } from '../../constants/Routes';
//utils
import { errorMessageMapping } from "./utils";
//components
import Footer from './Footer';
import DisplayError from './DisplayError';
import RouteLeavingGuard from "../../components/RouteLeavingGuard/index";

const PaymentForm: React.FC<any> = ({ isAddCardClicked, setIsAddCardClicked, errObject, setErrObject }) => {
    //to append securesubmit to the head of DOM
    const isSecureSubmitLoaded = useScript("https://api2.heartlandportico.com/SecureSubmit.v1/token/2.1/securesubmit.js");
    const history = useHistory();
    const { push } = useHistory();
    const dispatch = useDispatch();
    const [hps, setHps] = useState({});
    const [isDataChanged, setIsDataChanged] = useState(false);
    const zipRef = useRef<HTMLIonInputElement>(null);
    const cardHolderRef = useRef<HTMLIonInputElement>(null);
    const payment = useSelector((state: RootState) => { return state.payment; }, shallowEqual);
    const userProfile = useSelector((state: RootState) => { return state.profile; }, shallowEqual);
    const systemUser = useSelector((state: RootState) => { return state.systemUsers; }, shallowEqual);

    const { systemUserId } = systemUser;
    const { currentProfile } = userProfile;
    const { locationId, orgId } = currentProfile;
    const { publicKeyObject, userData } = payment;
    const { key: publicKey } = publicKeyObject;

    const ismobile = isPlatform('mobile');
    const istablet = isPlatform('tablet');
    const isdesktop = isPlatform('desktop');

    // Callback when an error is received from the service
    const onTokenError = (resp: any) => {
        const { error } = resp;
        setErrObject({ ...error });
        setIsAddCardClicked(false);
    };

    // Callback when an event is fired within an iFrame
    const onEvent = (ev: any) => {
        const { source, type, data } = ev;
        const { keyCode } = data;
        if (type === 'keydown' && (keyCode >= 48 && keyCode <= 57)) { setIsDataChanged(true); }
        if (source === "submit" && type === "click") {
            setIsDataChanged(false);
            setIsAddCardClicked(true);
        }
    };

    useEffect(() => {
        //@ts-ignore
        const tempHps = publicKey && Heartland && new Heartland.HPS({
            publicKey: publicKey,
            type: "iframe",
            // Configure the iframe fields to tell the library where
            // the iframe should be inserted into the DOM and some
            // basic options
            fields: {
                cardHolder: {
                    target: "cardHolder"
                },
                cardNumber: {
                    target: "iframesCardNumber",
                    placeholder: "•••• •••• •••• ••••",
                },
                cardExpiration: {
                    target: "iframesCardExpiration",
                    placeholder: "MM / YYYY",
                },
                cardCvv: {
                    target: "iframesCardCvv",
                    placeholder: "CVV",
                },
                submit: {
                    target: "paymentSubmit",
                    value: FOOTER_SAVE_BUTTON
                },
            },
            style: { ...paymentFormStyle },
            // Callback when a token is received from the service
            onTokenSuccess: function (response: any) {
                const { card_type, exp_month, exp_year, token_value, card } = response;
                const { number } = card;
                const payload = {
                    //@ts-ignore
                    "zip": zipRef.current.value,
                    "cardIssuer": card_type.trim(),
                    "expirationMonth": exp_month.trim(),
                    "expirationYear": exp_year.trim(),
                    "isDefault": true,
                    "locationId": locationId.trim(),
                    "name": number.trim(),
                    "orgId": orgId.trim(),
                    "provider": 14,
                    "systemUserId": systemUserId,
                    "token": token_value.trim(),
                    //@ts-ignore
                    "cardHolder": cardHolderRef.current.value,

                };
                const storedToken = window.sessionStorage.getItem("token_value");
                storedToken !== token_value && dispatch(addCardFlow(payload));
                window.sessionStorage.setItem("token_value", token_value);
            },
            onTokenError,
            onEvent,
        });
        setHps(tempHps);
    }, [publicKey]);

    useEffect(() => {
        //@ts-ignore
        //const element = document.getElementById("iframesCardNumber").firstChild.style.display = "none";
        !publicKey && dispatch(fetchPublicKeyRequest(locationId));
    }, []);

    useEffect(() => {
        const ele = document.getElementById("iframesCardNumber");
        const iframeOfCardNumber = document.getElementById("heartland-frame-cardNumber");
        const iframesCardExpiration = document.getElementById("heartland-frame-cardExpiration");
        const iframeheartlandcardCvv = document.getElementById("heartland-frame-cardCvv");
        if (ele && ele.firstChild) {
            //@ts-ignore
            ele.firstChild.style.display = "none";
        }
        if (iframeOfCardNumber) {
            iframeOfCardNumber.style.width = "100%";
            iframeOfCardNumber.style.height = "55px";
        }
        if (iframesCardExpiration) {
            iframesCardExpiration.style.width = "100%";
        }
        if (iframeheartlandcardCvv) {
            iframeheartlandcardCvv.style.width = "100%";
        }
    })

    return (
        <IonRow className="ion-no-padding">
            <IonCol>
                {isAddCardClicked && (cardHolderRef.current?.value !== '' && zipRef.current?.value != '') &&
                    <IonLoading
                        cssClass="my-custom-class"
                        isOpen={isAddCardClicked}
                        message={LOADER_MSG}
                    />
                }
                <IonRow className="ion-padding-start ion-padding-end">
                    <IonCol>
                        <IonRow>
                            <IonCol>
                                <h2>
                                    <b>{paymentConstants.PAYMENT_INFO}</b>
                                </h2>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <h5>
                                    <b>{paymentConstants.CREDIT_CARD_INFO}</b>
                                </h5>
                            </IonCol>
                        </IonRow>
                        <IonRow className='margin-bottom-20'>
                            <IonCol size-lg="3" size-md="4" size-xs="12" className="ion-padding-end">
                                <span>*</span>
                                <IonLabel>{paymentConstants.NAME_ON_CARD}</IonLabel>
                                <IonInput
                                    type="text"
                                    ref={cardHolderRef}
                                    className="payment-input"
                                    aria-label={paymentConstants.NAME_ON_CARD}
                                    onIonChange={() => setIsDataChanged(true)}
                                />
                            </IonCol>
                            <IonCol size-lg="3" size-md="5" size-xs="12" className="ion-padding-end">
                                <span>*</span>
                                <label htmlFor="iframesCardNumber">{paymentConstants.CARD_NUMBER}</label>
                                <div id="iframesCardNumber" />
                            </IonCol>
                            <IonCol size-lg="3" size-md="3" size-xs="12" className="ion-padding-end">
                                <span>*</span>
                                <IonLabel>{paymentConstants.BILLING_ZIP_CODE}</IonLabel>
                                <IonInput
                                    type="tel"
                                    ref={zipRef}
                                    className="payment-input"
                                    onIonChange={() => setIsDataChanged(true)}
                                    aria-label={paymentConstants.BILLING_ZIP_CODE}
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size-lg="2" size-md="2.5" size-xs='6' size-sm='6'>
                                <span>*</span>
                                <label htmlFor="iframesCardExpiration">{paymentConstants.CARD_EXPIRATION}</label>
                                <div id="iframesCardExpiration" />
                            </IonCol>
                            <IonCol size-lg="2" size-md="2.5" size-xs='6' size-sm='6'>
                                <span>*</span>
                                <label htmlFor="iframesCardCvv">{paymentConstants.CARD_CVV}</label>
                                <div id="iframesCardCvv" />
                            </IonCol>
                        </IonRow>
                        {errObject.message && <DisplayError message={errorMessageMapping(errObject.message)} color='danger' messageStyle={isdesktop ? 'add-card-err-msg' : (istablet || !ismobile) ? 'tablet-add-card-err-msg' : 'mobile-add-card-err-msg'} />}
                    </IonCol>
                </IonRow>
                <Footer
                    onCancel={() => {
                        dispatch(getUserData({ cardHolder: '', zip: '' }));
                        history.push(`${Routes.dashboard.paymentUrl}`);
                    }}
                    showSaveNewCardBtn={true}
                    isAddCardClicked={isAddCardClicked}
                />
                <RouteLeavingGuard
                    type="payment"
                    btnOk={FOOTER_SAVE_BUTTON}
                    btnCancel={DISCARD}
                    btnCancelStyles='leaving-gaurd-cancel-button'
                    message={DETECT_UNSAVE_HEADER}
                    promptContent={CONSUMER_DETECT_UNSAVE_MESSAGE}
                    when={isDataChanged}
                    navigate={(path: string) => {
                        push(path);
                    }}
                    shouldBlockNavigation={() => isDataChanged}
                />
            </IonCol>
        </IonRow>
    );
};
export default PaymentForm;