import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    IonButton,
    IonLabel,
    IonRow,
    IonCol,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonPage,
    useIonRouter,
    isPlatform,
    IonSpinner,
    IonItem,
} from "@ionic/react";
import { Routes } from "../../constants/Routes";
import { useHistory, useLocation } from "react-router";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    CONSUMER_ACCOUNT_HEADER,
    CONSUMER_ACCOUNT_MESSAGE,
    CONSUMER_ACTIVE_SCANCODE,
    CONSUMER_ADDITIONAL_TEXT,
    CONSUMER_ADD_FUNDS,
    CONSUMER_GENERAL_LABEL,
    CONSUMER_GMA_LABEL,
    CONSUMER_HASAPP_LABEL,
    CONSUMER_LOCATION_LABEL,
    CONSUMER_OR_LABEL,
    CONSUMER_PAYCYCLE_GROUP,
    CONSUMER_PAYROLL_ID,
    CONSUMER_PENDING_LABEL,
    CONSUMER_PIN_LABEL,
    CONSUMER_SCANCODE_LABEL,
    CONSUMER_SUBSIDY_GROUP,
    CREATE_CONSUMER_LABEL,
    dashboardLabels,
    EDIT_CONSUMER_LABEL,
    FINGERPRINT_LABEL,
    GLOBAL_FIRST_NAME,
    GLOBAL_LAST_NAME,
    Hotel365Portal,
    USER_MGMT_TABLE_RESEND_BTN,
    YES,
    NO,
    ClientPortal,
    FOOTER_CANCEL_BUTTON,
    GLOBAL_OK_BUTTON,
    DETECT_UNSAVE_HEADER,
    CONSUMER_DETECT_UNSAVE_MESSAGE,
    ClientPortalErrors,
    GLOBAL_NO_CHANGES_TO_SAVE,
    FOOTER_SAVE_BUTTON,
    DISCARD,
    consumerDetailLocationCss,
} from "../../constants/constant";
import { DateTime } from "luxon";
import {
    restrictAlphaAndSplChar,
    restrictSplChar,
    getLocalizedNumericValues,
} from "../../utils";
import { ConsumerListActionsPrompt } from "./ConsumerListActionsPrompt";
import { validateConsumer } from "../../utils/FormValidations";
import ToastrHook from "../../components/common/toastr/Toastr";
import { RootState } from "../../_redux/reducers/rootReducer";
import MultiSelectDropdown from "../../components/common/MultiSelectDropdown";
import ConsumerFooter from "./ConsumerFooter";
import {
    fetchPayrollGrp,
    fetchSubsidyGrp,
    postConsumerResendEmail,
    resetFetchConsumers,
    postConsumerAddFunds,
    postNewConsumer,
    fetchConsumerList,
    PatchConsumer,
    fetchConsumerBalances,
    fetchConsumerIdentifiers,
} from "../../_redux/actions/consumerActions";
import RouteLeavingGuard from "../../components/RouteLeavingGuard";
import { Identifiers, PayrollGrp, SubsidyGrp } from "../../models/Consumer";
import { fetchSysUserTokensRequest } from "../../_redux/actions/paymentActions";

export const ConsumerDetail: React.FC<any> = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [renderToastr] = ToastrHook();
    const router = useIonRouter();
    const location = useLocation();
    const consumerLst = useSelector((state: RootState) => {
        return state.consumer;
    }, shallowEqual);
    const userDetails = useSelector((state: RootState) => {
        return state.systemUsers;
    }, shallowEqual);
    const systemUserId = userDetails.systemUserId;
    const payment = useSelector((state: RootState) => {
        return state.payment;
    }, shallowEqual);
    const { availableCards } = payment;
    const {
        cards,
    } = availableCards;
    let existingConsumer = localStorage.getItem('detailConsumer')
    const {
        error: consumerErr,
        consumerLists,
        pending: consumerPending,
        payrollGrps,
        subsidyGrps,
        ResenEmailResponse,
        consumerBalances,
        consumerIdentifiers,
        consumerPayrollIdIdentifiers,
        accountId,
        consumerAddFunds,
        createdConsumer,
        createdSubsidyGrp,
        removedConsumerScanCode,
        updateConsumerIdentifier,
        updatedConsumerResponse,
    } = consumerLst;
    const [consumerAction, setConsumerAction] = useState("");
    const [activeScancodes, setActiveScancodes] = useState(
        [] as Record<string, any>[]
    );
    const [isChanged, setIsChanged] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [nextPath, setNextPath] = useState('')
    let _payrollDeduct: PayrollGrp[] = [];
    let _subsiyGrp: SubsidyGrp[] = []
    let getScanCodes: Identifiers[] = []
    let getPayrollIds: Identifiers[] = []

    /**Check localstorage */
    useEffect(() => {
        /**only for refresh the screen */
        if (existingConsumer && location.pathname === Routes.dashboard.consumerDetailUrl) {
            const selectedConsumer = JSON.parse(existingConsumer)
            let reqObj = {
                isExactSearch: true,
                limit: 50,
                offset: 0,
                locationIds: null,
                status: selectedConsumer.isDisabled ? 'disabled' : 'active',
                q: [selectedConsumer.accountId],
                searchBy: 'accountid',
            } as any;
            cards.length === 0 && dispatch(fetchSysUserTokensRequest(systemUserId));
            dispatch(fetchConsumerList(reqObj));
            setTimeout(() => {
                dispatch(fetchConsumerBalances(selectedConsumer.accountId));
                dispatch(
                    fetchConsumerIdentifiers(selectedConsumer.accountId, [0, 6])
                );
                dispatch(fetchPayrollGrp(selectedConsumer.locationId));
                dispatch(fetchSubsidyGrp(selectedConsumer.locationId));
            }, 300);
        }
    }, [existingConsumer, location.pathname])


    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        email: "",
        pin: "",
        scancode: "",
        location: [] as Record<string, any>[],
        pantryGroupId: "",
        payrollDeductGroupId: "",
        payrollId: "",
    });
    const [formErrors, setFormErrors] = useState({} as any);
    const [locationOptions, setLocationOptions] = useState(
        [] as Record<string, any>[]
    );
    const { setLocationForCP } = useSelector(
        (state: RootState) => state.UserManagement,
        shallowEqual
    );
    const isTablet = isPlatform('tablet');
    const isDesktop = isPlatform('desktop');

    const checkConsumerResendEmailResponse = useCallback(() => {
        return ResenEmailResponse && Object.keys(ResenEmailResponse).length > 0;
    }, [ResenEmailResponse]);

    const checkConsumerAddFundsResponse = useCallback(() => {
        return consumerAddFunds && Object.keys(consumerAddFunds).length > 0;
    }, [consumerAddFunds]);

    const checkConsumerCreatedResponse = useCallback(() => {
        return ((createdConsumer && Object.keys(createdConsumer).length > 0) || (createdSubsidyGrp && Object.keys(createdSubsidyGrp).length > 0) || (removedConsumerScanCode && Object.keys(removedConsumerScanCode).length > 0) || (updateConsumerIdentifier && Object.keys(updateConsumerIdentifier).length > 0) || (updatedConsumerResponse && Object.keys(updatedConsumerResponse).length > 0));
    }, [createdConsumer, createdSubsidyGrp, removedConsumerScanCode, updateConsumerIdentifier, updatedConsumerResponse]);

    useEffect(() => {
        let errorMessage = "";
        let serviceErr = consumerErr;
        if (serviceErr && Object.keys(serviceErr).length > 0) {
            setIsSubmitted(false)
            setIsChanged(true);
            if (serviceErr.status === 401) {
                errorMessage = Hotel365Portal.NOT_AUTHORIZED_ERR;
            } else if (serviceErr.status === 422) {
                errorMessage = serviceErr.message ? serviceErr.message : Hotel365Portal.notAvailableData;
            } else if (serviceErr.status === 409) {
                errorMessage = ClientPortal.consumer_email_already_exists;
            } else if (serviceErr.status === 400 && serviceErr.message) {
                errorMessage = serviceErr.message;
            } else {
                errorMessage = Hotel365Portal.SERVICE_UNAVAILABLE_ERR;
            }
            dispatch(resetFetchConsumers('resetErrorMessage'));
            renderToastr(errorMessage, "danger");
        }
        if (checkConsumerResendEmailResponse()) {
            renderToastr(ClientPortal.CONSUMER_EMAIL_RESEND_SUCCESS, "success");
        }
        if (checkConsumerAddFundsResponse()) {
            renderToastr(ClientPortal.CONSUMER_ADD_FUNDS_SUCCESS, "success");
            setConsumerAction("");
        }
        setTimeout(() => {
            if (
                checkConsumerResendEmailResponse() ||
                checkConsumerAddFundsResponse()
            ) {
                dispatch(resetFetchConsumers("resetErrorMessage"));
            }
            if (!consumerErr && checkConsumerCreatedResponse()) {
                renderToastr(ClientPortal.consumer_account_succes, "success");
                dispatch(resetFetchConsumers());
                setIsSubmitted(false)
                setTimeout(() => {
                    router.push(`${Routes.dashboard.consumerListUrl}`);
                }, 1000);
            }
        }, 2000);
    }, [
        consumerErr,
        dispatch,
        ResenEmailResponse,
        consumerAddFunds,
        createdConsumer,
        createdSubsidyGrp,
        updatedConsumerResponse,
        removedConsumerScanCode,
        updateConsumerIdentifier
    ]);

    const resetForm = (callback?: Function) => {
        setState({
            firstName: "",
            lastName: "",
            email: "",
            pin: "",
            scancode: "",
            location: [] as Record<string, any>[],
            pantryGroupId: "",
            payrollDeductGroupId: "",
            payrollId: "",
        });
        setFormErrors({});
        setActiveScancodes([]);
        setIsChanged(false);
        setIsSubmitted(false)
        isExistingConsumer = null;
        selectedConsumer = {}
        callback && callback();
    };

    let selectedConsumer = useMemo(() => {
        return consumerLists?.filter(
            (item) => item.accountId === accountId
        )?.[0];
    }, [consumerLists, accountId]);

    let isExistingConsumer = useMemo(() => {
        return (
            selectedConsumer &&
            Object.keys(selectedConsumer).length > 0 &&
            selectedConsumer.accountId
        );
    }, [selectedConsumer]);

    useEffect(() => {
        if (isExistingConsumer && accountId && !isChanged) {
            _payrollDeduct = payrollGrps?.filter(
                (item) =>
                    item.groupName === selectedConsumer?.payrollDeductGroup
            );
            _subsiyGrp = subsidyGrps?.filter(
                (item) => item.name === selectedConsumer?.subsidyGroupName
            );
            getScanCodes = consumerIdentifiers?.filter(
                (item) =>
                    !item.isDisabled &&
                    selectedConsumer?.accountId === item.accountId
            );
            getPayrollIds = consumerPayrollIdIdentifiers?.filter(
                (item) =>
                    !item.isDisabled &&
                    selectedConsumer?.accountId === item.accountId
            );
            setState((prevState) => ({
                ...prevState,
                ...selectedConsumer,
                pantryGroupId:
                    _subsiyGrp?.length > 0 ? _subsiyGrp[0].subsidyGroupId : "",
                payrollDeductGroupId:
                    _payrollDeduct?.length > 0
                        ? _payrollDeduct[0].payrollDeductGroupId
                        : "",
                ["pin"]: "****",
                payrollId:
                    getPayrollIds?.length > 0 ? getPayrollIds.reduce((a, b) => (a.lastUpdated > b.lastUpdated ? a : b)).value : "",
            }));
            if (getScanCodes?.length > 0) {
                setActiveScancodes(
                    getScanCodes?.map((item) => {
                        return {
                            value: item.value,
                            isDisabled: item.isDisabled,
                            accountIdentifierId: item?.accountIdentifierId,
                            accountId: item?.accountId,
                        };
                    })
                );
            }
        }
    }, [
        selectedConsumer,
        isExistingConsumer,
        consumerIdentifiers,
        consumerPayrollIdIdentifiers,
        payrollGrps,
        subsidyGrps,
        setIsChanged,
        setState,
        accountId,
        consumerLists,
        existingConsumer
    ]);

    const locationHandle = (locations: any) => {
        setFormErrors((prevState: any) => ({
            ...prevState,
            ["location"]: '',
        }));
        setIsChanged(true);
        setState((prevState) => ({
            ...prevState,
            ["location"]: locations,
        }));
        if(locations[0].value !== state.location[0]?.value){
            dispatch(fetchPayrollGrp(locations[0].value));
            dispatch(fetchSubsidyGrp(locations[0].value));
        }
    };

    let requestBody = useMemo(() => {
        return {
            firstName: state.firstName,
            lastName: state.lastName,
            locationId:
                state.location && state.location.length > 0
                    ? state.location[0].value
                    : null,
            pin: state.pin,
            identifiers: [] as Record<string, any>[],
        } as any;
    }, [state]);

    const invokeAddConsumerAPI = () => {
        const _scanCodes = activeScancodes?.map((item) => {
            if (!item.isDisabled) {
                return {
                    type: "0",
                    usePin: false,
                    value: item.value,
                };
            }
        }) as Record<string, any>[];
        requestBody["identifiers"] = [
            ...requestBody["identifiers"],
            ..._scanCodes,
        ];
        if(state.scancode){
            requestBody["identifiers"] = [
                ...requestBody["identifiers"],
                {
                    type: "0",
                    usePin: false,
                    value: state.scancode,
                },
            ];
        }
        if (state.payrollId) {
            requestBody["identifiers"] = [
                ...requestBody["identifiers"],
                {
                    type: "6",
                    usePin: false,
                    value: state.payrollId,
                },
            ];
        }
        /**remove duplicates from array of objects */
        requestBody["identifiers"].filter((v: Record<string, any>, i: number, a: any) => a.findIndex((v2: Record<string, any>) => ['type', 'value'].every(k => v2[k] === v[k])) === i)
        if (state.payrollDeductGroupId) {
            requestBody["payrollDeductGroupId"] = state.payrollDeductGroupId;
        }
        if (state.pantryGroupId) {
            requestBody['pantryGroupId'] = state.pantryGroupId;
        }
        if (state.email) {
            requestBody["email"] = state.email;
        }
        requestBody["localization"] = {
            currency: "USD",
        };
        dispatch(postNewConsumer(requestBody));
    };

    const invokeExistingConsumerAPI = () => {
        let requestBody = {
            "accountId": selectedConsumer.accountId
        } as Record<string, any>
        if (state.firstName !== selectedConsumer.firstName) {
            requestBody["firstName"] = state.firstName;
        }
        if (state.lastName !== selectedConsumer.lastName) {
            requestBody["lastName"] = state.lastName;
        }
        _payrollDeduct = payrollGrps?.filter(
            (item) =>
                item.groupName === selectedConsumer?.payrollDeductGroup
        );
        const _payrollDeductId = _payrollDeduct?.length > 0
            ? _payrollDeduct[0].payrollDeductGroupId : ''
        if (state.payrollDeductGroupId !== _payrollDeductId) {
            requestBody["payrollDeductGroupId"] = state.payrollDeductGroupId;
        }
        const _addedScanCodes = activeScancodes
            ?.map((item) => {
                if (!item.isDisabled && !item?.accountIdentifierId) {
                    return {
                        type: "0",
                        usePin: false,
                        value: item.value,
                        "accountId": selectedConsumer.accountId
                    };
                }
            })
            .filter((item) => !!item) as Record<string, any>[];
        const _removedScanCodes = activeScancodes
            ?.map((item) => {
                if (item.isDisabled) {
                    return {
                        accountId: item.accountId,
                        accountIdentifierId: item.accountIdentifierId,
                    };
                }
            })
            .filter((item) => !!item) as Record<string, any>[];
        _subsiyGrp = subsidyGrps?.filter(
            (item) => item.name === selectedConsumer?.subsidyGroupName
        );
        const checkpantryGrpIdAlreadyExists = _subsiyGrp && _subsiyGrp.length > 0 && _subsiyGrp.filter(item => item.subsidyGroupId !== state.pantryGroupId)

        if ((checkpantryGrpIdAlreadyExists && checkpantryGrpIdAlreadyExists?.length === _subsiyGrp?.length) || (!_subsiyGrp?.length && state.pantryGroupId)) {
            /** pantryGroupId updated */
            requestBody['pantryGroupId'] = state.pantryGroupId;
        }
        const getPayrollIds = consumerPayrollIdIdentifiers?.filter(
            (item) =>
                !item.isDisabled &&
                selectedConsumer?.accountId === item.accountId
        );
        const checkPayrollAlreadyExists = getPayrollIds && getPayrollIds.length > 0 && getPayrollIds.filter(item => item.value !== state.payrollId)
        if ((checkPayrollAlreadyExists && checkPayrollAlreadyExists?.length === getPayrollIds?.length) || (!getPayrollIds?.length && state.payrollId)) {
            /** payroll id updated */
            requestBody['payrollId'] = {
                type: "6",
                usePin: false,
                value: state.payrollId,
                "accountId": selectedConsumer.accountId
            };
        }
        if (_addedScanCodes && _addedScanCodes.length > 0) {
            requestBody['addedScancodes'] = _addedScanCodes;
        }
        if(state.scancode){
            if(requestBody['addedScancodes']?.length > 0){
                requestBody['addedScancodes'] = [
                    ...requestBody['addedScancodes'],
                    {
                        type: "0",
                        usePin: false,
                        value: state.scancode,
                        "accountId": selectedConsumer.accountId
                    },
                ];
            } else {
                requestBody['addedScancodes'] = [
                    {
                        type: "0",
                        usePin: false,
                        value: state.scancode,
                        "accountId": selectedConsumer.accountId
                    },
                ];
            }
            
        }
        if (_removedScanCodes && _removedScanCodes.length > 0) {
            requestBody['removedScancodes'] = _removedScanCodes;
        }


        if (state.email !== selectedConsumer.email) {
            requestBody['emailUpdate'] = {
                email: state.email,
                source:
                    ClientPortal.consumer_balance_default.charAt(0).toUpperCase() +
                    ClientPortal.consumer_balance_default.slice(1),
            };

        }
        if (state.pin !== '****' && state.pin.includes('*')) {
            renderToastr(ClientPortalErrors.pinNumeric, "danger");
            return false;
        } else if (!state.pin.includes('*')) {
            requestBody["pin"] = state.pin;
        }
        Object.keys(requestBody).length > 1 ? dispatch(PatchConsumer(requestBody)) : renderToastr(GLOBAL_NO_CHANGES_TO_SAVE, "warning"); setIsSubmitted(false); return false;
    };

    const onSave = () => {
        if (isDuplicate) {
            renderToastr(Hotel365Portal.upcAlreadyExists, "danger");
            return false;
        }
        const errorObject = validateConsumer(state, activeScancodes);
        setFormErrors(errorObject);
        const isError = Object.keys(errorObject).length;
        if (isError === 0) {
            setIsChanged(false);
            setIsSubmitted(true)
            if (!isExistingConsumer) {
                invokeAddConsumerAPI();
            } else {
                invokeExistingConsumerAPI();
            }
        }
    };
    useEffect(() => {
        const updatedUserLocations = setLocationForCP?.map(
            (location: any) => {
                return {
                    value: location.locationId,
                    label: location.name,
                };
            }
        );
        setState((prevState) => ({
            ...prevState,
            ["location"]: [],
        }));
        setLocationOptions([...updatedUserLocations]);
    }, [setLocationForCP, setState]);

    const isDuplicate = useMemo(
        () =>
            activeScancodes &&
            activeScancodes.length > 0 &&
            activeScancodes.some((obj) => obj.value === state.scancode),
        [activeScancodes, state.scancode]
    );
     
    const checkAndAddUPC = useCallback(
        () => {
        if (!isDuplicate) {
            if (activeScancodes && activeScancodes.length > 0) {
                setActiveScancodes([
                    ...activeScancodes,
                    { value: state.scancode, isDisabled: false },
                ]);
            } else {
                setActiveScancodes([
                    { value: state.scancode, isDisabled: false },
                ]);
            }

            setState((prevState) => ({
                ...prevState,
                ["scancode"]: "",
            }));
        } else {
            renderToastr(Hotel365Portal.upcAlreadyExists, "danger");
        }
      },
      [isDuplicate,activeScancodes, state.scancode],
    )
    

    const addUPC = () => {
        setIsChanged(true);
        if (!state.scancode) {
            renderToastr("Scancode cannot be empty", "danger");
            return false;
        }
        checkAndAddUPC();
    };

    const deleteScancode = (code: string) => {
        setIsChanged(true);
        const foundIndex = activeScancodes.findIndex((x) => x.accountIdentifierId && (x.value == code));
        if (foundIndex === -1) {
            const result = activeScancodes.filter(function (el) { return el.value != code; });
            setActiveScancodes([...result]);
        } else {
            activeScancodes[foundIndex].isDisabled = true;
            setActiveScancodes([...activeScancodes]);
        }
    };
    const resendEmail = () => {
        const request = {
            email: selectedConsumer.unverifiedEmail,
            source:
                ClientPortal.consumer_balance_default.charAt(0).toUpperCase() +
                ClientPortal.consumer_balance_default.slice(1),
        };
        dispatch(postConsumerResendEmail(selectedConsumer.accountId, request));
    };

    const invokeFunds = (data: Record<string, any>) => {
        const getDefaultAccount = consumerBalances?.filter(
            (item) => item.type === ClientPortal.consumer_balance_default
        );
        const request = {
            accountBalanceId:
                getDefaultAccount &&
                getDefaultAccount.length > 0 &&
                getDefaultAccount[0].accountBalanceId,
            accountId: selectedConsumer.accountId,
            amount: Number(data.fundAmount),
            systemUserTokenId: data.fundsPaymentMethod.systemUserTokenId,
            transactionDate: DateTime.fromJSDate(new Date()).toFormat(
                ClientPortal.consumer_add_funds_transaction_date_frmt
            ),
        };
        dispatch(postConsumerAddFunds(request));
    };
    useEffect(() => {
        // unmount the component when route changes
        return () => {
            resetForm(() => { })
        };
    }, [location.pathname]);

    useEffect(() => {
        if (!isChanged && nextPath) {
            setNextPath('')
            history.push(nextPath);

        }
    }, [isChanged, nextPath])

    const checkSubSidy = () => {
        return (!isExistingConsumer &&
            (state.location?.length === 0 ||
                !subsidyGrps ||
                subsidyGrps?.length === 0)) || (isExistingConsumer && (!subsidyGrps ||
                    subsidyGrps?.length === 0))
    }

    const checkPayrollGrps = () => {
        return !isExistingConsumer &&
            (state.location?.length === 0 ||
                !payrollGrps ||
                payrollGrps?.length === 0) || (isExistingConsumer && (!payrollGrps ||
                    payrollGrps?.length === 0));
    }

    useEffect(() => {
        if (state.pin !== '****' && state.pin?.includes('*')) {
            setState((prevState) => ({
                ...prevState,
                ["pin"]: '',
            }));
        }
    }, [state.pin])

    return (
        <IonPage className="consumer-list">
            <IonRow className={(isDesktop || isTablet) ? "ion-padding-start ion-padding-end consumer-detail-scroll-content" : "ion-padding-start ion-padding-end consumer-scroll-content-mobile"}>
                <IonCol>
                    <IonRow>
                        <IonCol>
                            <h1>
                                <b>
                                    {!isExistingConsumer
                                        ? CREATE_CONSUMER_LABEL
                                        : EDIT_CONSUMER_LABEL}
                                </b>
                            </h1>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <h1 className="consmer-search-filter-lbl">
                                {CONSUMER_GENERAL_LABEL}
                            </h1>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size={isExistingConsumer ? "2.5" : "3"} sizeMd={isExistingConsumer ? "2.5" : "3"} sizeXs="12" className="ion-padding-end">
                            <span
                                className={
                                    formErrors.firstName ? "validation-error-label" : ""
                                }
                            >
                                *
                            </span>
                            <IonLabel className="consumer-detail-header">
                                {GLOBAL_FIRST_NAME}
                            </IonLabel>
                            <IonInput
                                className="consumer-form-field form-text-input"
                                name="firstName"
                                id={"firstName"}
                                maxlength={127}
                                aria-label={GLOBAL_FIRST_NAME}
                                value={state.firstName}
                                onIonInput={(e) => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        ["firstName"]: e.detail.value! as any,
                                    }));
                                    setFormErrors((prevState: any) => ({
                                        ...prevState,
                                        ["firstName"]: '',
                                    }));
                                }}
                                onKeyPress={(e) => {
                                    setIsChanged(true);
                                    restrictSplChar(e);
                                    setFormErrors((prevState: any) => ({
                                        ...prevState,
                                        ["firstName"]: '',
                                    }));
                                }}
                                onKeyDown={(e) => {
                                    setIsChanged(true);
                                    setFormErrors((prevState: any) => ({
                                        ...prevState,
                                        ["firstName"]: '',
                                    }));
                                }}
                            ></IonInput>
                            {formErrors && formErrors.firstName && (
                                <span className="consumer-form-errors">
                                    {formErrors.firstName}
                                </span>
                            )}
                        </IonCol>
                        <IonCol size={isExistingConsumer ? "2.5" : "3"} sizeMd={isExistingConsumer ? "2.5" : "3"} sizeXs="12" className="ion-padding-end">
                            <span
                                className={
                                    formErrors.lastName ? "validation-error-label" : ""
                                }
                            >
                                *
                            </span>
                            <IonLabel className="consumer-detail-header">
                                {GLOBAL_LAST_NAME}
                            </IonLabel>
                            <IonInput
                                className="consumer-form-field form-text-input"
                                name="lastName"
                                id={"lastName"}
                                maxlength={127}
                                autocomplete="off"
                                aria-label={GLOBAL_LAST_NAME}
                                value={state.lastName}
                                onIonInput={(e) => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        ["lastName"]: e.detail.value! as any,
                                    }));
                                    setFormErrors((prevState: any) => ({
                                        ...prevState,
                                        ["lastName"]: '',
                                    }));
                                }}
                                onKeyPress={(e) => {
                                    setIsChanged(true);
                                    restrictSplChar(e);
                                    setFormErrors((prevState: any) => ({
                                        ...prevState,
                                        ["lastName"]: '',
                                    }));
                                }}
                                onKeyDown={(e) => {
                                    setIsChanged(true);
                                    setFormErrors((prevState: any) => ({
                                        ...prevState,
                                        ["lastName"]: '',
                                    }));
                                }}
                            ></IonInput>
                            {formErrors.lastName && (
                                <span className="consumer-form-errors">
                                    {formErrors.lastName}
                                </span>
                            )}
                        </IonCol>
                        <IonCol size={isExistingConsumer ? "2.5" : "3"} sizeMd={isExistingConsumer ? "2" : "3"} sizeXs="12" className="ion-padding-end">
                            <span
                                className={
                                    formErrors.location ? "validation-error-label" : ""
                                }
                            >
                                *
                            </span>
                            <IonLabel className="consumer-detail-header">
                                {CONSUMER_LOCATION_LABEL}
                            </IonLabel>
                            {isExistingConsumer ? (
                                <div className="fingerprint-text">
                                    {selectedConsumer?.locationName}
                                </div>
                            ) : (
                                <React.Fragment>
                                    <MultiSelectDropdown
                                        colourStyles={consumerDetailLocationCss}
                                        setSelectedOptions={locationHandle}
                                        selectedOptions={state.location}
                                        options={locationOptions}
                                        isMulti={false}
                                    />

                                    {formErrors.location && (
                                        <span className="consumer-form-errors">
                                            {formErrors.location}
                                        </span>
                                    )}
                                </React.Fragment>
                            )}
                        </IonCol>
                        {isExistingConsumer && (<IonCol size="1.5" sizeMd="1.5" sizeXs="12" className="ion-padding-end">
                            <IonLabel className="consumer-detail-header">
                                {FINGERPRINT_LABEL}
                            </IonLabel>

                            {isExistingConsumer && (
                                <div className="fingerprint-text">
                                    {selectedConsumer?.hasFingerPrint === "N"
                                        ? NO
                                        : YES}
                                </div>
                            )}
                        </IonCol>)}
                        <IonCol size={isExistingConsumer ? "2" : "3"} sizeMd={isExistingConsumer ? "2" : "3"} sizeXs="12" className="ion-padding-end">
                            <span
                                className={
                                    formErrors.pin ? "validation-error-label" : ""
                                }
                            >
                                *
                            </span>
                            <IonLabel className="consumer-detail-header">
                                {CONSUMER_PIN_LABEL}
                            </IonLabel>
                            <IonInput
                                maxlength={4}
                                type="password"
                                className="consumer-form-field form-text-input"
                                name={"pin"}
                                id={"pin"}
                                aria-label={CONSUMER_PIN_LABEL}
                                autocomplete="off"
                                value={state.pin}
                                onIonInput={(e) => {
                                    const val= e.detail.value as string;
                                    if (val !== '****' && val?.includes('*')) {
                                        setState((prevState) => ({
                                            ...prevState,
                                            ["pin"]: '',
                                        }));
                                    }
                                    setState((prevState) => ({
                                        ...prevState,
                                        ["pin"]: e.detail.value! as any,
                                    }));
                                    setFormErrors((prevState: any) => ({
                                        ...prevState,
                                        ["pin"]: '',
                                    }));
                                }}
                                onKeyPress={(e) => {
                                    setIsChanged(true);
                                    restrictAlphaAndSplChar(e);
                                }}
                                onKeyDown={(e) => {
                                    setIsChanged(true);
                                    setFormErrors((prevState: any) => ({
                                        ...prevState,
                                        ["pin"]: '',
                                    }));
                                }}
                                onBlur={(
                                    e
                                ) => {
                                    if (!e.target.value && isExistingConsumer) {
                                        setState((prevState) => ({
                                            ...prevState,
                                            ["pin"]: '****',
                                        }));
                                    }
                                }}
                            ></IonInput>
                            {formErrors.pin && (
                                <span className="consumer-form-errors">
                                    {formErrors.pin}
                                </span>
                            )}
                        </IonCol>
                        {isExistingConsumer && (<IonCol size="1" sizeMd="1.5" sizeXs="12" className="ion-padding-end">
                            <IonLabel className="consumer-detail-header">
                                {CONSUMER_HASAPP_LABEL}
                            </IonLabel>
                            <div className="fingerprint-text">
                                <p>
                                    {selectedConsumer?.hasApp === "N"
                                        ? NO
                                        : YES}
                                </p>
                            </div>
                        </IonCol>
                        )}
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" sizeMd="3" sizeXs="12" className="ion-padding-end">
                            <IonLabel className="consumer-detail-header">
                                {isExistingConsumer
                                    ? CONSUMER_GMA_LABEL
                                    : Hotel365Portal.GLOBAL_EMAIL}
                            </IonLabel>
                            <IonInput
                                className="consumer-form-field form-text-input"
                                value={state.email}
                                name={"email"}
                                id={"email"}
                                type="email"
                                aria-label={'email'}
                                onIonInput={(e) => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        ["email"]: e.detail.value! as any,
                                    }));
                                    setFormErrors((prevState: any) => ({
                                        ...prevState,
                                        ["email"]: '',
                                    }));
                                }}
                                onKeyPress={(e) => {
                                    setIsChanged(true);
                                    setFormErrors((prevState: any) => ({
                                        ...prevState,
                                        ["email"]: '',
                                    }));
                                }}
                                onKeyDown={(e) => {
                                    setIsChanged(true);
                                    setFormErrors((prevState: any) => ({
                                        ...prevState,
                                        ["email"]: '',
                                    }));
                                }}
                            ></IonInput>
                            {formErrors.email && (
                                <span className="consumer-form-errors">
                                    {formErrors.email}
                                </span>
                            )}
                            {selectedConsumer?.email &&
                                selectedConsumer?.email === state.email && (
                                    <span className="verified-email-text">
                                        Verified
                                    </span>
                                )}
                        </IonCol>
                        {isExistingConsumer && (<IonCol size={selectedConsumer.unverifiedEmail ? "auto" : "2"} sizeMd={selectedConsumer.unverifiedEmail ? "auto" : "2"} sizeXs="12" className="ion-padding-end pending-email-field">
                            <IonLabel className="consumer-detail-header">
                                {CONSUMER_PENDING_LABEL}
                            </IonLabel>
                            <div className="pending-email-label">
                                {selectedConsumer.unverifiedEmail || "-"}
                            </div>
                        </IonCol>
                        )}
                        {isExistingConsumer && selectedConsumer?.unverifiedEmail && (<IonCol
                            size="1"
                            sizeMd="1"
                            sizeXs="12"
                            className="consumer-resend-button ion-padding-end"
                        >
                            <IonButton
                                className="resend-text"
                                onClick={resendEmail}
                                size="large"
                            >
                                {USER_MGMT_TABLE_RESEND_BTN}
                            </IonButton>
                        </IonCol>
                        )}
                        <IonCol className={isDesktop ? "consumer-or-text ion-padding-end" : isTablet ? "ion-padding-start consumer-or-text-tablet" : "consumer-or-text"} size="1" sizeMd="1" sizeXs="12">
                            <IonLabel>{CONSUMER_OR_LABEL}</IonLabel>
                        </IonCol>
                        <IonCol size="2" sizeMd="2" sizeXs="10">
                            <IonLabel className="consumer-detail-header">
                                {CONSUMER_SCANCODE_LABEL}
                            </IonLabel>
                            <IonInput
                                maxlength={40}
                                autocomplete="off"
                                className="consumer-form-field form-text-input"
                                name={"scancode"}
                                id={"scancode"}
                                value={state.scancode}
                                onIonInput={(e) => {
                                    setIsChanged(true);
                                    setState((prevState) => ({
                                        ...prevState,
                                        ["scancode"]: e.detail.value! as any,
                                    }));
                                }}
                            ></IonInput>
                            {false && (
                                <span className="consumer-form-errors">
                                    {"formErrors.scancode"}
                                </span>
                            )}
                        </IonCol>
                        <IonCol
                            className="ion-padding-start ion-padding-end consumer-plus-button"
                            size="1"
                            sizeMd="1"
                            sizeXs="2"
                        >
                            <IonButton className="scancode-add-button" onClick={addUPC} size="large">
                                <IonLabel className="consumer-plus-icon">
                                    +
                                </IonLabel>
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    {activeScancodes && activeScancodes.length > 0 && (
                        <IonRow>
                            <IonCol>
                                <IonLabel className="consumer-detail-header">
                                    {CONSUMER_ACTIVE_SCANCODE}
                                </IonLabel>
                                <div className="scan-codes-list">
                                    {activeScancodes?.map(
                                        (
                                            item: Record<string, any>,
                                            index: number
                                        ) => {
                                            if (!item.isDisabled) {
                                                return (
                                                    <div
                                                        className="active-scan-code"
                                                        key={index}
                                                    >
                                                        {item.value}
                                                        <span
                                                            className="scancode-cancel-chip"
                                                            onClick={() => {
                                                                deleteScancode(
                                                                    item.value
                                                                );
                                                            }}
                                                        >
                                                            &times;
                                                        </span>
                                                    </div>
                                                );
                                            }
                                        }
                                    )}
                                </div>
                            </IonCol>
                        </IonRow>
                    )}
                    <IonRow>
                        <IonCol size="12">
                            <h1 className="consmer-search-filter-lbl">
                                {ClientPortal.consumer_payment_lbl}
                            </h1>
                        </IonCol>
                    </IonRow>
                    {isExistingConsumer ? (
                        <IonRow>
                            {consumerBalances?.map(
                                (item: Record<string, any>, index: number) => {
                                    if (item.type === "DEFAULT") {
                                        return (
                                            <React.Fragment key={index}>
                                                <IonCol size="1" sizeMd="1" sizeXs="12">
                                                    <IonLabel className="consumer-detail-header">
                                                        {
                                                            CONSUMER_ACCOUNT_HEADER
                                                        }
                                                    </IonLabel>
                                                    <div className="consumer-account">
                                                        <p>
                                                            {getLocalizedNumericValues(
                                                                item.balance
                                                            )}
                                                        </p>
                                                    </div>
                                                </IonCol>
                                                <IonCol size="2" sizeMd="2" sizeXs="12" className="add-funds-button">
                                                    <IonButton
                                                        className="add-funds-text"
                                                        onClick={() => {
                                                            setConsumerAction(
                                                                "addFunds"
                                                            );
                                                        }}
                                                        size="large"
                                                    >
                                                        {CONSUMER_ADD_FUNDS}
                                                    </IonButton>
                                                </IonCol>
                                            </React.Fragment>
                                        );
                                    } else {
                                        return (
                                            <React.Fragment key={index}>
                                                <IonCol size="2" sizeMd="2" sizeXs="12">
                                                    <IonLabel className="consumer-detail-header">
                                                        {item.displayName}
                                                    </IonLabel>
                                                    <div className="consumer-account">
                                                        <p>
                                                            {getLocalizedNumericValues(
                                                                item.balance
                                                            )}
                                                        </p>
                                                    </div>
                                                </IonCol>
                                            </React.Fragment>
                                        );
                                    }
                                }
                            )}
                        </IonRow>
                    ) : (
                        <IonRow className="payment-fund">
                            <IonCol
                                size="12"
                                className="payment-fund-container"
                            >
                                <p className="payment-fund-text">
                                    {CONSUMER_ACCOUNT_MESSAGE}
                                </p>
                            </IonCol>
                        </IonRow>
                    )}
                    <IonRow>
                        <IonCol size="12">
                            <h1 className="consmer-search-filter-lbl">
                                {CONSUMER_ADDITIONAL_TEXT}
                            </h1>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3" sizeMd="3" sizeXs="12" className="ion-padding-end">
                            <IonLabel
                                className={
                                    checkPayrollGrps()
                                        ? "consumer-detail-disabled"
                                        : "consumer-detail-header"
                                }
                            >
                                {CONSUMER_PAYCYCLE_GROUP}
                            </IonLabel>
                            <IonSelect
                                interface="popover"
                                className={
                                    checkPayrollGrps()
                                        ? "consumer-form-disabled form-text-input"
                                        : "consumer-form-field form-text-input"
                                }
                                value={state.payrollDeductGroupId}
                                aria-label={CONSUMER_PAYCYCLE_GROUP}
                                onIonChange={(e) => {
                                    setIsChanged(true);
                                    setState((prevState) => ({
                                        ...prevState,
                                        ["payrollDeductGroupId"]:
                                            e.detail.value!,
                                    }));
                                }}
                                key={state.payrollDeductGroupId}
                                disabled={
                                    checkPayrollGrps()
                                }
                            >
                                {payrollGrps &&
                                    payrollGrps.length > 0 &&
                                    payrollGrps.map(
                                        (item: Record<string, any>) => (
                                            <IonSelectOption
                                                key={item.payrollDeductGroupId}
                                                value={
                                                    item.payrollDeductGroupId
                                                }
                                            >
                                                {item.groupName}
                                            </IonSelectOption>
                                        )
                                    )}
                            </IonSelect>
                            {checkPayrollGrps() && (
                                <span className="unsupported-location-text">
                                    Supported location not selected
                                </span>
                            )}
                        </IonCol>
                        <IonCol size="3" sizeMd="3" sizeXs="12" className="ion-padding-end">
                            <IonLabel
                                className={
                                    checkPayrollGrps()
                                        ? "consumer-detail-disabled"
                                        : "consumer-detail-header"
                                }
                            >
                                {CONSUMER_PAYROLL_ID}
                            </IonLabel>
                            <IonInput
                                className={
                                    checkPayrollGrps()
                                        ? "consumer-form-disabled form-text-input"
                                        : "consumer-form-field form-text-input"
                                }
                                name={"payroll"}
                                id={"payroll"}
                                value={state.payrollId}
                                onIonInput={(e) => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        ["payrollId"]: e.detail.value! as string,
                                    }));
                                }}
                                onKeyPress={(e) => {
                                    setIsChanged(true);
                                }}
                                onKeyDown={(e) => {
                                    setIsChanged(true);
                                }}
                                disabled={
                                    checkPayrollGrps()
                                }
                            ></IonInput>
                        </IonCol>
                        <IonCol size="3" sizeMd="3" sizeXs="12" className="ion-padding-end">
                            <IonLabel
                                className={
                                    checkSubSidy()
                                        ? "consumer-detail-disabled"
                                        : "consumer-detail-header"
                                }
                            >
                                {CONSUMER_SUBSIDY_GROUP}
                            </IonLabel>
                            <IonSelect
                                interface="popover"
                                className={
                                    checkSubSidy()
                                        ? "consumer-form-disabled form-text-input"
                                        : "consumer-form-field form-text-input"
                                }
                                value={state.pantryGroupId}
                                onIonChange={(e) => {
                                    setIsChanged(true);
                                    setState((prevState) => ({
                                        ...prevState,
                                        ["pantryGroupId"]: e.detail.value!,
                                    }));
                                }}
                                disabled={
                                    checkSubSidy()
                                }
                                key={state.pantryGroupId}// setting the attribute "key" on the component to current value, prevents the element from being updated after IonChange-event. Instead a new instance will be created and therefore the IonChange-event is not called in a loop. 
                            >
                                {subsidyGrps &&
                                    subsidyGrps.length > 0 &&
                                    subsidyGrps.map(
                                        (item: Record<string, any>) => (
                                            <IonSelectOption
                                                key={item.subsidyGroupId}
                                                value={item.subsidyGroupId}
                                            >
                                                {item.name}
                                            </IonSelectOption>
                                        )
                                    )}
                            </IonSelect>
                            {checkSubSidy() && (
                                <span className="unsupported-location-text">
                                    Supported location not selected
                                </span>
                            )}
                        </IonCol>
                    </IonRow>
                    <ConsumerListActionsPrompt
                        actionName={consumerAction}
                        setConsumerAction={setConsumerAction}
                        type={"detail"}
                        applyFunds={invokeFunds}
                        locationId={selectedConsumer?.locationId}
                    />
                    <ConsumerFooter
                        onCancel={() => {
                            history.push(`${Routes.dashboard.consumerListUrl}`);
                        }}
                        isDisabled={isSubmitted}
                        onClickSave={onSave}
                    ></ConsumerFooter>
                    <RouteLeavingGuard
                        type="consumer"
                        btnOk={FOOTER_SAVE_BUTTON}
                        btnCancel={DISCARD}
                        btnCancelStyles="leaving-gaurd-cancel-button"
                        message={DETECT_UNSAVE_HEADER}
                        promptContent={CONSUMER_DETECT_UNSAVE_MESSAGE}
                        when={isChanged}
                        navigate={(path: string) => {
                            resetForm(() => {
                                setNextPath(path);
                            });
                        }}
                        shouldBlockNavigation={() => isChanged}
                    />
                </IonCol>
            </IonRow>
            {consumerPending && (
                <IonSpinner name="circles" className="spinner-consumer" />
            )}
        </IonPage>
    );
};
export default ConsumerDetail;