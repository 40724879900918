
import React from "react";
import { IonPage } from "@ionic/react";

import AdminNavigation from "../../../components/NavigationProcess/AdminNavigation";
import { navigateWithProducts } from "../../../constants/navigation";

interface NavigateData {
  location: boolean;
  onBoard: boolean;
  addProducts: boolean;
}

const navigationFlow: NavigateData = navigateWithProducts;
const MarketPage: React.FC = () => {
  return (
    <IonPage>
      <AdminNavigation navigationFlow={navigationFlow} />
    </IonPage>
  );
};

export default MarketPage;
