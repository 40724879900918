//vendors
import React, { useEffect, useState } from "react";
import {
    IonButton,
    IonCol,
    IonContent,
    IonIcon,
    IonImg,
    IonInput,
    IonRow,
    isPlatform
} from "@ionic/react";
import {
    chevronBackCircleOutline,
    chevronForwardCircleOutline
} from "ionicons/icons";
//components
import PreviewButton from "../../../../components/common/Button/PreviewButton";
import Heading from "../../../../components/common/Heading/Heading"
//utils
import { ColorLogoProps } from '../../../../models/ColorLogoProps';
import { isValidHttpUrl } from '../../../../utils';
//constants
import {
    onboarding,
    Hotel365Portal,
    SETTING_LOGO_COLOR_SCHEMA,
    ONBOARDING_THEME_PRIMARY_COLOR,
    SETTING_COLOR_LOGO_RESET_BUTTON,
    GLOBAL_USE_BUTTON,
    SETTING_COLOR_LOGO_PREVIEW_BUTTON,
} from '../../../../constants/constant';

const ColorLogoForm: React.FC<ColorLogoProps> = ({ marketTheme, setOnboarding, sidenavExpanded, setSidenavExpanded, setFormErrors, formErrors }) => {
    const [siteLogo, setSiteLogo] = useState(marketTheme.logoUrl || '');
    const [siteColor, setsiteColor] = useState(marketTheme.primaryColorHex ? marketTheme.primaryColorHex : Hotel365Portal.defaultPrimaryColor);
    const { errorMessage } = formErrors;
    const [showLogo, setShowLogo] = useState(true);
    const isTabletView = isPlatform("tablet");
    const isMobileView = isPlatform("mobile");
    const isDesktopView = isPlatform("desktop");
    const toggleAccordion = () => {
        setSidenavExpanded(!sidenavExpanded);
    };
    const onImageChange = (e: any) => {
        const { errorMessage, isValid } = isValidHttpUrl(e.detail.value);
        setSiteLogo(e.detail.value);
        setFormErrors({ isValid, errorMessage });
        setOnboarding((prevState: any) => ({ ...prevState, marketTheme: { logoUrl: e.detail.value, primaryColorHex: marketTheme.primaryColorHex } }));
        setShowLogo(true);
    }
    const onClickUse = () => {
        if (!errorMessage || errorMessage.length === 0) {
            setShowLogo(true);
        }
    }
    const clearColor = () => {
        setsiteColor(Hotel365Portal.defaultPrimaryColor);
        setOnboarding((prevState: any) => ({ ...prevState, marketTheme: { ...prevState.marketTheme, primaryColorHex: Hotel365Portal.defaultPrimaryColor } }));
    }
    const colorPicker = (e: any) => {
        setsiteColor(e.target.value);
        setOnboarding((prevState: any) => ({ ...prevState, marketTheme: { primaryColorHex: e.target.value, logoUrl: marketTheme.logoUrl } }));
    }
    useEffect(() => {
        if (!siteLogo) {
            setShowLogo(false);
        }
    }, [showLogo])
    useEffect(() => {
        marketTheme.logoUrl ? setShowLogo(true) : setShowLogo(false);
        setSiteLogo(marketTheme.logoUrl || '');
        setsiteColor(
            marketTheme.primaryColorHex
                ? marketTheme.primaryColorHex
                : Hotel365Portal.defaultPrimaryColor
        );
    }, [marketTheme]);

    return (
        <IonContent className="scroll-content">
            <IonRow>
                <IonCol>
                    <IonRow className="ion-padding">
                        <IonCol onClick={toggleAccordion} style={{ display: 'flex' }}>
                            <IonIcon
                                className="settings-back-icon click-cursor"
                                size="large"
                                md={sidenavExpanded ? chevronBackCircleOutline : chevronForwardCircleOutline} />
                            <h2 className="header-text"><b>{SETTING_LOGO_COLOR_SCHEMA}</b></h2>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-padding-start logo-subheader'>
                        <IonCol sizeLg={sidenavExpanded ? '1.3' : '1'}>
                            <span>{onboarding.RIGHT_SIDE_LOGO_HEADING}</span>
                        </IonCol>
                        {(isDesktopView || isTabletView) && <IonCol sizeLg='4' sizeMd={sidenavExpanded ? '9.7' : '10.3'} className='ion-padding-start'>
                            <span className='url-header'>{onboarding.RIGHT_SIDE_LOGO_URL}</span>
                        </IonCol>}
                    </IonRow>
                    <IonRow className='logo-url-header ion-padding-start'>
                        <IonCol sizeLg={sidenavExpanded ? '1.6' : '1.3'} sizeMd={sidenavExpanded ? '2.9' : "2.1"} sizeXs='5'>
                            <IonImg
                                className={showLogo ? "brand-logo" : "site-logo-back brand-logo"}
                                id="image"
                                src={showLogo ? siteLogo : "/assets/logo.svg"}
                            />
                        </IonCol>
                        {isMobileView && !isTabletView &&
                            <IonCol sizeXs='10' className='ion-padding-top logo-subheader'>
                                <span className='mobile-url-header'>{onboarding.RIGHT_SIDE_LOGO_URL}</span>
                            </IonCol>}
                        <IonCol sizeLg={sidenavExpanded ? '4' : '3.3'} sizeMd={sidenavExpanded ? '8' : '5.5'} sizeXs='12' className={isDesktopView || isTabletView ? 'ion-padding-start' : 'ion-padding-top'}>
                            <IonInput
                                aria-label={onboarding.RIGHT_SIDE_LOGO_URL}
                                style={{ minHeight: "35px" }}
                                className="logo-input-url color-logo-input-size"
                                type="url"
                                value={siteLogo}
                                id='logo'
                                name="logoUrl"
                                onIonChange={onImageChange}
                                accept="image/*"
                            />
                            <IonRow className='short-logo-note-top'>
                                <IonCol>
                                    <p><small className={isDesktopView || isTabletView ? "short-logo-note" : "short-logo-note-mobile"}>{onboarding.RIGHT_SIDE_COLOR_LOGO_NOTE1}</small></p>
                                </IonCol>
                            </IonRow>
                            <IonRow className='short-logo-note-top'>
                                <IonCol>
                                    {errorMessage && <span className={'url-error-label'}> {errorMessage}</span>}
                                </IonCol>
                            </IonRow>
                            {(isTabletView && !isDesktopView) ? <IonButton
                                className="color-logo-url-btn"
                                onClick={onClickUse}
                            >
                                {GLOBAL_USE_BUTTON}
                            </IonButton> : ''}
                        </IonCol>
                        {(isDesktopView || isMobileView && !isTabletView) ? <IonCol className={isMobileView ? 'ion-margin-top' : ''}>
                            <IonButton
                                className="color-logo-url-btn"
                                onClick={onClickUse}
                            >
                                {GLOBAL_USE_BUTTON}
                            </IonButton>
                        </IonCol> : ''}
                    </IonRow>
                    <IonRow className='ion-padding-start ion-padding-top'>
                        <IonCol className="heading-logo" >
                            <Heading
                                heading={onboarding.RIGHT_SIDE_THEME_COLOR_BRAND}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-padding-start logo-subheader'>
                        <IonCol>
                            <span>{ONBOARDING_THEME_PRIMARY_COLOR}</span>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeLg={sidenavExpanded ? '1.65' : '1.5'} sizeMd={sidenavExpanded ? "3.3" : "2.4"} sizeXs='4.8' className='ion-padding-start'>
                            <IonButton className={"theme-selected-logo"} style={{ '--background': siteColor }} />
                        </IonCol>
                        <IonCol className='ion-padding-start' sizeLg={sidenavExpanded ? '1.2' : '1'} sizeMd={sidenavExpanded ? "2.2" : "1.6"}>
                            <IonButton
                                className="button-text-property"
                                color="dark"
                                fill="outline"
                                onClick={clearColor}
                            >
                                {SETTING_COLOR_LOGO_RESET_BUTTON}
                            </IonButton>
                            <IonRow>
                                <IonCol sizeLg={sidenavExpanded ? "2" : ""}>
                                    <span className={"color-logo-short-note"}>{onboarding.RIGHT_SIDE_COLOR_LOGO_NOTE2}</span>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol sizeLg='1'>
                            <IonButton className="color-selector">
                                <span className="color-use-button">{GLOBAL_USE_BUTTON}</span>
                                <input
                                    type="color"
                                    onChange={colorPicker}
                                    className="hidden"
                                />
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-padding-start ion-padding-top'>
                        <IonCol sizeLg={sidenavExpanded ? '1.6' : '1.4'} sizeMd={sidenavExpanded ? "3.3" : "2.5"}>
                            <PreviewButton
                                siteColorProps={siteColor}
                                paddingProps='9px 20px 9px 20px'
                                heightProps='50px'
                                borderRadiusProps='5px'
                                backgroundProps='#494952'
                                labelProps={SETTING_COLOR_LOGO_PREVIEW_BUTTON}
                            />
                        </IonCol>
                        <IonCol sizeLg={sidenavExpanded ? '1.7' : '1.2'} sizeMd={sidenavExpanded ? "6" : "8"}>
                            <PreviewButton
                                siteColorProps={siteColor}
                                paddingProps='9px 20px 9px 20px'
                                heightProps='50px'
                                borderRadiusProps='5px'
                                backgroundProps='#e7e7e8'
                                labelProps={SETTING_COLOR_LOGO_PREVIEW_BUTTON}
                            />
                        </IonCol>
                        <IonCol sizeLg={sidenavExpanded ? '1.5' : '1'} sizeMd={sidenavExpanded ? "3.4" : "2.4"}>
                            <PreviewButton
                                siteColorProps={siteColor}
                                labelProps={SETTING_COLOR_LOGO_PREVIEW_BUTTON}
                                marginProps='13px 20px'
                                heightProps='31px'
                            />
                        </IonCol>
                        <IonCol sizeLg={sidenavExpanded ? '1.5' : '0'}>
                            <PreviewButton
                                labelProps={SETTING_COLOR_LOGO_PREVIEW_BUTTON}
                                marginProps='13px 20px'
                                boxShadowProps='none'
                                siteColorProps='#fff'
                                colorProps={siteColor}
                            />
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>
        </IonContent >
    )
}
export default ColorLogoForm;









