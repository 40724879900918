//vendors
import { IonRouterOutlet } from "@ionic/react";
import { Route } from "react-router-dom";

// constants
import { Routes } from "../../../constants/Routes";

import Settings from "../../SettingsPage/Settings";
import OrdersPage from "../../HotelManager/OrdersPage/OrdersPage";
import Reports from "../../Reports";
import Profile from "../../profile";
import MarketPage from "../../HotelAdmin/MarketPage/MarketPage";
import ProductList from "../../HotelManager/ProductsPage/ProductList";
import UserManagement from "../../UserManagement/UserManagement";
import POS from "../../POS/Pos";
import ErrorComponent from "../../../components/ErrorComponent";

export function routeDescriptor(): JSX.Element {
    return (
        <IonRouterOutlet animated={false}>
            <Route exact path={Routes.dashboard.url} component={ProductList} />
            <Route exact path={Routes.dashboard.productsUrl} component={ProductList} />
            <Route exact path={Routes.dashboard.orderUrl} component={OrdersPage} />
            <Route exact path={Routes.dashboard.reportsUrl} component={Reports} />
            <Route exact path={Routes.dashboard.posUrl} component={POS} />
            <Route exact path={Routes.dashboard.settingsUrl} component={Settings} />
            <Route exact path={Routes.dashboard.marketUrl} component={MarketPage} />
            <Route exact path={Routes.profile.url} component={Profile} />
            <Route exact path={Routes.userManagement.url} component={UserManagement} />
            <Route render={() => <ErrorComponent isFallback={true} />} />
        </IonRouterOutlet>
    );
}
export default { routeDescriptor };
