
import envChange from './changeEnvironment'; 
import {fetchEnviorment} from './index';
const envConfig = envChange();
const getEnv = fetchEnviorment();

export const baseUrlWithProtocol: string = (envConfig as any)[getEnv]?.REACT_APP_API;
const TIMEOUT = 300000

const headers = (additionalHeaders = {}) => {
  const filterLocalStorageDomain = Object.keys(localStorage).filter(item => {if(item.includes(baseUrlWithProtocol)){return item}});
  const authorizationObject = filterLocalStorageDomain && filterLocalStorageDomain.length > 0 ? JSON.parse(localStorage.getItem(filterLocalStorageDomain[0]) as string) : {}
  const {body = {}} = authorizationObject
  const { access_token = '' } = body;
  return {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: 'application/json; charset=UTF-8',
    Authorization: `Bearer ${access_token}`,
    ...additionalHeaders,
  } as any
}

/**
 * @description function to handle the time out error
 * @param  {Promise} promise - promise
 * @param  {number} timeout - millseconds
 * @param  {string} error - errorcode
 * @return
 */
function timeoutPromise(promise: Promise<any>, timeout: number, error: number) {
  return new Promise((resolve, reject) => {
    const clearTimeOut = setTimeout(() => {
      const err = { status: error }
      reject(err)
    }, timeout)
    promise.then(
      (data) => {
        clearTimeout(clearTimeOut)
        resolve(data)
      },
      (data) => {
        clearTimeout(clearTimeOut)
        reject(data)
      },
    )
  })
}

export const statusCheck = (data: any) => {
  const { status } = data
  return data
    .json()
    .then((res: any) => {
      let resData
      if (Array.isArray(res)) {
        resData = { status, res }
      } else {
        resData = { status, ...res }
      }
      if (data.status >= 200 && data.status < 300) {
        return Promise.resolve(resData)
      }
      return Promise.reject(resData)
    })
    .catch((error: Error) => {
      let resData = { status }
      if ([204,200, 202, 201].indexOf(status)!==-1) return Promise.resolve(resData)
      else if (error) {
        resData = { status, ...error }
      }
      return Promise.reject(resData)
    })
}

/** @description calls a native fetch method and returns a promise Object
 * @param {string} url
 * @param {string} urlPrefix
 * @returns {Promise}
 */
export const fetchURL = (
  url: string,
  urlPrefix = baseUrlWithProtocol,
  additionalHeaders = {},
) => {
  return timeoutPromise(
    fetch(
      urlPrefix.concat(url),
      Object.assign(
        {},
        {
          headers: headers(additionalHeaders),
        },
      ),
    ),
    TIMEOUT,
    504,
  )
}

/** @description Sending a GET request to JSON API.
 * doGet method resolves or rejects the promise that is obtained
 * from the fetchURl method
 * @param {string} url
 * @param {string} urlPrefix
 * @returns {object}
 */
export const doGet = (
  url: string,
  urlPrefix = baseUrlWithProtocol,
  additionalHeaders = {},
  type?: any,
) => {
  return fetchURL(url, urlPrefix, additionalHeaders).then(statusCheck)
}

/** @description Sending a POST request.
 * @param {string} url
 * @param {object} body
 * @param {string} urlPrefix
 * @returns {Promise}
 */
export const doPost = (
  url: string,
  body: any,
  additionalHeaders = {},
  urlPrefix: string = baseUrlWithProtocol
) => {
  let reqBody;
  if(body){
    if (Array.isArray(body)) {
      reqBody = body
    } else {
      reqBody = {
        ...body,
      }
    }
  }
  
  return timeoutPromise(
    fetch(
      urlPrefix.concat(url),
      Object.assign(
        {},
        {
          method: 'post',
          headers: headers(additionalHeaders),
          body: JSON.stringify(reqBody),
        },
      ),
    ),
    TIMEOUT,
    504,
  ).then(statusCheck)
}

/** @description Sending a PUT request.
 * @param {string} url
 * @param {object} body
 * @param {string} urlPrefix
 * @returns {Promise}
 */
export const doPut = (
  url: string,
  body: any,
  urlPrefix = baseUrlWithProtocol,
  additionalHeaders = {},
) => {
  const reqBody = {
    ...body,
  }
  return timeoutPromise(
    fetch(
      urlPrefix.concat(url),
      Object.assign(
        {},
        {
          method: 'put',
          headers: headers(additionalHeaders),
          body: JSON.stringify(reqBody),
        },
      ),
    ),
    TIMEOUT,
    504,
  ).then(statusCheck)
}


/** @description Sending a DELETE request.
 * @param {string} url
 * @param {object} body
 * @param {string} urlPrefix
 * @returns {Promise}
 */
 export const doDelete = (url: string, body: any, urlPrefix = baseUrlWithProtocol) => {
  return timeoutPromise(
    fetch(
      urlPrefix.concat(url),
      Object.assign({}, {
          method: 'delete',
          headers: headers(),
          body: JSON.stringify(body),
      }),
  ), TIMEOUT, 504)
      .then(statusCheck);
};


/** @description Sending a PATCH request.
 * @param {string} url
 * @param {object} body
 * @param {string} urlPrefix
 * @returns {Promise}
 */
 export const doPatch = (
  url: string,
  body: any,
  urlPrefix = baseUrlWithProtocol,
  additionalHeaders = {},
) => {
  const reqBody = {
    ...body,
  }
  return timeoutPromise(
    fetch(
      urlPrefix.concat(url),
      Object.assign(
        {},
        {
          method: 'PATCH',
          headers: headers(additionalHeaders),
          body: JSON.stringify(reqBody),
        },
      ),
    ),
    TIMEOUT,
    504,
  ).then(statusCheck)
}
