import {
    PostAddCardRequest,
    PostAddCardSuccess,
    PostAddCardFailure,
    GetPublicKeyRequest,
    GetPublicKeySuccess,
    GetPublicKeyFailure,
    GetSysUserTokensRequest,
    GetSysUserTokensSuccess,
    GetSysUserTokensFailure,
    DetachCardRequest,
    DetachCardSuccess,
    DetachCardFailure,
    GetUserData,
} from "../../models/Payment";

const initialState = {
    error: null,
    pending: false,
    userData: { cardHolder: '', zip: '' },
    availableCards: { error: null, pending: false, cards: [] },
    publicKeyObject: { error: null, pending: false },
    deleteCard: { error: null, pending: false, status: '' }
}

export type paymentTypes =
    PostAddCardRequest |
    PostAddCardSuccess |
    PostAddCardFailure |
    GetSysUserTokensRequest |
    GetSysUserTokensSuccess |
    GetSysUserTokensFailure |
    DetachCardRequest |
    DetachCardSuccess |
    DetachCardFailure |
    GetUserData;

const strategies = {
    POST_ADDCARD_REQUEST: postAddCardRequest,
    POST_ADDCARD_SUCCESS: postAddCardSuccess,
    POST_ADDCARD_FAILURE: postAddCardailure,
    GET_PUBLIC_KEY_REQUEST: getPublicKeyRequest,
    GET_PUBLIC_KEY_SUCCESS: getPublicKeySuccess,
    GET_PUBLIC_KEY_FAILURE: getPublicKeyFailure,
    GET_SYSUSER_TOKENS_REQUEST: getSysUserTokensRequest,
    GET_SYSUSER_TOKENS_SUCCESS: getSysUserTokensSuccess,
    GET_SYSUSER_TOKENS_FAILURE: getSysUserTokensFailure,
    DETACH_CARD_REQUEST: detachCardRequest,
    DETACH_CARD_SUCCESS: detachCardSuccess,
    DETACH_CARD_FAILURE: detachCardailure,
    GET_USER_DATA: getUserData,
    default: (state: any) => state,
}

function getPublicKeyRequest(state: any) {
    return { ...state, publicKeyObject: { error: null, pending: true } }
}

function getPublicKeySuccess(state: any, action: GetPublicKeySuccess) {
    const { response } = action;
    return { ...state, publicKeyObject: { ...response, error: null, pending: false } }
}

function getPublicKeyFailure(state: any, action: GetPublicKeyFailure) {
    return { ...state, publicKeyObject: { error: action.error, pending: false } }
}

function getSysUserTokensRequest(state: any) {
    return { ...state, availableCards: { ...state.availableCards, error: null, pending: true } }
}

function getSysUserTokensSuccess(state: any, action: GetSysUserTokensSuccess) {
    const { response } = action;
    return { ...state, availableCards: { cards: [...response], error: null, pending: false } }
}

function getSysUserTokensFailure(state: any, action: GetSysUserTokensFailure) {
    return { ...state, availableCards: { ...state.availableCards, error: action.error, pending: false } }
}

function postAddCardRequest(state: any, action: PostAddCardRequest) {
    return { ...state, error: null, pending: true }
}

function postAddCardSuccess(state: any, action: PostAddCardSuccess) {
    const { response } = action;
    //need to revisit this state manipulation
    return { ...state, error: null, pending: false, data: { ...response }, userData: { cardHolder: '', zip: '' } }
}

function postAddCardailure(state: any, action: PostAddCardFailure) {
    return { ...state, pending: false, error: action.error }
}

function detachCardRequest(state: any, action: DetachCardRequest) {
    return { ...state, deleteCard: { error: null, pending: true } }
}

function detachCardSuccess(state: any, action: DetachCardSuccess) {
    const { response } = action;
    //need to revisit this state manipulation
    return { ...state, deleteCard: { error: null, pending: false, status: response } }
}

function detachCardailure(state: any, action: DetachCardFailure) {
    const { error } = action;
    return { ...state, deleteCard: { error, pending: false } }
}

function getUserData(state: any, action: GetUserData) {
    const { userData } = action;
    return { ...state, userData }
}

export default (state: any = initialState, action: paymentTypes): any => {
    return (strategies[action.type] || strategies.default)(state, action as never)
}