import { GenerateCart, PosSearchProducts } from "../../models/pos";
import * as POS from "../ActionTypes/posTypes";


export interface PostGenerateCart {
    type: POS.POST_GENERATE_CART_REQUEST;
    data: Record<string, any>;
}
export interface PostGenerateCartSuccess {
    type: POS.POST_GENERATE_CART_SUCCESS;
    response: GenerateCart;
}

export interface PostGenerateCartFailure {
    type: POS.POST_GENERATE_CART_FAILURE;
    error: Record<string, any> | null;
}
export type PostGenerateCartType =
    | PostGenerateCart
    | PostGenerateCartSuccess
    | PostGenerateCartFailure;

export function postGenerateCart(
    data: Record<string, any>
): PostGenerateCart {
    return {
        type: POS.POST_GENERATE_CART_REQUEST,
        data,
    };
}

export function postGenerateCartSuccess(
    response: GenerateCart
): PostGenerateCartSuccess {
    return {
        type: POS.POST_GENERATE_CART_SUCCESS,
        response,
    };
}
export function postGenerateCartFailure(
    error: Record<string, any>
): PostGenerateCartFailure {
    return {
        type: POS.POST_GENERATE_CART_FAILURE,
        error: error,
    };
}


// search product pos
export interface FetchSearchProduct {
    type: POS.FETCH_SEARCH_PRODUCT_REQUEST;
    data: Record<string, any>;
    locationId: string;
}
export interface FetchSearchProductSuccess {
    type: POS.FETCH_SEARCH_PRODUCT_SUCCESS;
    response: PosSearchProducts[];
}

export interface FetchSearchProductFailure {
    type: POS.FETCH_SEARCH_PRODUCT_FAILURE;
    error: Record<string, any> | null;
}
export type FetchSearchProductType =
    | FetchSearchProduct
    | FetchSearchProductSuccess
    | FetchSearchProductFailure;

export function fetchSearchProduct(
    data: Record<string, any>,
    locationId: string
): FetchSearchProduct {
    return {
        type: POS.FETCH_SEARCH_PRODUCT_REQUEST,
        data,
        locationId
    };
}

export function fetchSearchProductSuccess(
    response: PosSearchProducts[]
): FetchSearchProductSuccess {
    return {
        type: POS.FETCH_SEARCH_PRODUCT_SUCCESS,
        response,
    };
}
export function fetchSearchProductFailure(
    error: Record<string, any>
): FetchSearchProductFailure {
    return {
        type: POS.FETCH_SEARCH_PRODUCT_FAILURE,
        error: error,
    };
}


/**
 * Post Checkout Cart
 */

export interface PostCheckoutCart {
    type: POS.POST_CHECKOUT_CART_REQUEST;
    data: Record<string, any>;
}
export interface PostCheckoutCartSuccess {
    type: POS.POST_CHECKOUT_CART_SUCCESS;
    response: GenerateCart;
}

export interface PostCheckoutCartFailure {
    type: POS.POST_CHECKOUT_CART_FAILURE;
    error: Record<string, any> | null;
}
export type PostCheckoutCartType =
    | PostCheckoutCart
    | PostCheckoutCartSuccess
    | PostCheckoutCartFailure;

export function postCheckoutCart(
    data: Record<string, any>
): PostCheckoutCart {
    return {
        type: POS.POST_CHECKOUT_CART_REQUEST,
        data,
    };
}

export function postCheckoutCartSuccess(
    response: GenerateCart
): PostCheckoutCartSuccess {
    return {
        type: POS.POST_CHECKOUT_CART_SUCCESS,
        response,
    };
}
export function postCheckoutCartFailure(
    error: Record<string, any>
): PostCheckoutCartFailure {
    return {
        type: POS.POST_CHECKOUT_CART_FAILURE,
        error: error,
    };
}

export interface ResetPos {
    type: POS.RESET_POS;
    data?: Record<string, any>;
}

export type ResetPosType = ResetPos;

export function resetPOS(data?: Record<string, any>): ResetPosType {
    return {
        type: POS.RESET_POS,
        data,
    };
}