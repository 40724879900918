
//vendors
import { IonLoading } from "@ionic/react"
//constants
import { LOADER_MSG } from "../../../pages/Payment/constants";

const LoaderWithMessage = ({ message = LOADER_MSG, isOpen = false, cssClass = "my-custom-class" }) => {
    return (
        <IonLoading cssClass={cssClass} isOpen={isOpen} message={message} />
    )
}

export default LoaderWithMessage;