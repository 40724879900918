//vendors
import React, { useState } from "react";
import {
    IonCol,
    IonRow,
    IonInput,
    IonItem,
    IonNote,
    IonButton,
    isPlatform,
    IonImg,
    IonLabel,
    IonIcon,
} from "@ionic/react";
import { eye, eyeOff } from "ionicons/icons";
import { portalType } from "../../auth.config";
//constants
import { GLOBAL_SUBMIT_TEXT, Hotel365Portal, PortalType as SysPortalAppType } from "../../constants/constant";

const ResetPasswordForm: React.FC<any> = (props) => {
    const { onSubmitHandler, onChangeHandler, formErrors } = props;
    const isDesktop = isPlatform("desktop");
    const isMobile = isPlatform("mobile");
    const istablet = isPlatform("tablet");

    let [isShowNewPassword, isSetShowNewPassword] = useState(false)
    let [isShowCnfrmPassword, isSetShowCnfrmPassword] = useState(false)
    const togglePassword = (toggleProps: string) => {
        if (toggleProps === "newPwd") {
            isSetShowNewPassword(!isShowNewPassword)
        }
        if (toggleProps === "cnfrmPwd") {
            isSetShowCnfrmPassword(!isShowCnfrmPassword)
        }
    }

    return (
        <IonRow className="pwd-update-screen h-100 scroll-content">
            <IonCol>
                <IonRow className={isDesktop ? "content" : "content mobile"}>
                    <IonCol
                        sizeLg='4'
                        pushLg="2"
                        sizeXs='12'
                        className={"login-content-padding"}>
                        {portalType === SysPortalAppType.HP && <IonImg src="/assets/logo.svg" />}
                        {portalType === SysPortalAppType.CP && <IonImg src="/assets/Client365.svg" />}
                    </IonCol>
                    {!isMobile && <IonCol
                        className={"login-content-padding " + (isDesktop ? "v-line" : "h-line")}
                        sizeLg='2'
                    />}
                    <IonCol sizeLg='6'>
                        <IonRow className='ion-padding-start reset-main-div'>
                            <IonCol><h1 className="reset-main-header">{Hotel365Portal.RESET_PWD_HEADER_TEXT}</h1></IonCol>
                        </IonRow>
                        <IonRow className={`ion-padding-start ${istablet ? "ion-padding-end" : ""}`}>
                            <IonCol className='reset-main-div' sizeLg='6'>
                                <IonNote color="white">{Hotel365Portal.RESET_NEW_PWD}</IonNote>
                                <IonItem lines="none" className="reset-email-ion-item-input">
                                    <IonInput
                                        style={{ minHeight: "43px" }}
                                        aria-label={Hotel365Portal.RESET_NEW_PWD}
                                        name="newPassword"
                                        onIonChange={onChangeHandler}
                                        type={isShowNewPassword ? "text" : "password"}
                                    />
                                    <IonIcon className="ion-eye-icon" icon={isShowNewPassword ? eyeOff : eye} size="medium" onClick={() => togglePassword("newPwd")} />
                                </IonItem>
                                <IonRow>
                                    <IonLabel className="red-class">{formErrors.newPassword}</IonLabel>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                        <IonRow className={`ion-padding-start ${istablet ? "ion-padding-end" : ""}`}>
                            <IonCol className='reset-main-div' sizeLg='6'>
                                <IonNote color="white">{Hotel365Portal.RESET_CNFR_PWD}</IonNote>
                                <IonItem lines="none" className="reset-email-ion-item-input">
                                    <IonInput
                                        style={{ minHeight: "43px" }}
                                        aria-label={Hotel365Portal.RESET_NEW_PWD}
                                        className="pwd-input"
                                        name="confirmPassword"
                                        onIonChange={onChangeHandler}
                                        type={isShowCnfrmPassword ? "text" : "password"}
                                    />
                                    <IonIcon className="ion-eye-icon" icon={isShowCnfrmPassword ? eyeOff : eye} size="medium" onClick={() => togglePassword("cnfrmPwd")} />
                                </IonItem>
                                <IonLabel className="red-class">
                                    {formErrors.confirmPassword}
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow className={`ion-padding-start ${istablet ? "ion-padding-end" : ""}`}>
                            <IonCol size="12" sizeLg='6' className="reset-email-text-right">
                                <IonButton color="secondary" onClick={onSubmitHandler}>{GLOBAL_SUBMIT_TEXT}</IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ion-padding-start'>
                            <IonCol><IonNote color="white" className="reset-email-ion-note-font">{Hotel365Portal.GLOBAL_PASSWORD_MUST_TEXT}</IonNote></IonCol>
                        </IonRow>
                        <IonRow className="reset-email-ion-row ion-padding-start">
                            <IonLabel className="reset-email-label-font">{Hotel365Portal.GLOBAL_PASSWORD_MUST_HAVE_8CH_TEXT}</IonLabel>
                            <IonLabel className="reset-email-label-font">{Hotel365Portal.GLOBAL_PASSWORD_SPCL_CHR}</IonLabel>
                            <IonLabel className="reset-email-label-font">{Hotel365Portal.GLOBAL_PASSWORD_UPR_CASE}</IonLabel>
                            <IonLabel className="reset-email-label-font">{Hotel365Portal.GLOBAL_PASSWORD_LWR_CASE}</IonLabel>
                            <IonLabel className="reset-email-label-font">{Hotel365Portal.GLOBAL_PASSWORD_ONE_NUMBER}</IonLabel>
                        </IonRow>
                        <IonRow className='ion-padding-start ion-margin-top'>
                            <IonCol><IonNote color="white" className="reset-email-ion-note-font">{Hotel365Portal.GLOBAL_PASSWORD_MUST_NOT_TEXT}</IonNote></IonCol>
                        </IonRow>
                        <IonRow className="reset-email-ion-row-password-must-not ion-padding-start">
                            <IonLabel className="reset-email-label-font">{Hotel365Portal.GLOBAL_PASSWORD_WHITE_SPACE}</IonLabel>
                            <IonLabel className="reset-email-label-font">{Hotel365Portal.GLOBAL_PASSWORD_ONLY_ONE_CHR}</IonLabel>
                            <IonLabel className="reset-email-label-font">{Hotel365Portal.GLOBAL_PASSWORD_CONSECUTIVE_CHR}</IonLabel>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonCol>
        </IonRow>
    );
}
export default ResetPasswordForm;