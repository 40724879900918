import React, { memo } from 'react'

import { IonRow, IonCol, IonItem, IonCheckbox, IonLabel } from '@ionic/react';

interface CategoryItemsProps {
    isActiveHandler: (categoryName:string) => void;
    categoryList:Category[];
    checked:string[];
}

interface Category {
    categoryName: string
}

export const CategoryItems: React.FC<CategoryItemsProps> = memo(
  (props) => {
    return (
        <IonRow className="items-scroll">
        <IonCol className="item-list item-bg-color">
          {props.categoryList.map((category: Category, index: number) => (
            <IonItem key={index} lines="none">
              <IonCheckbox
                checked={props.checked.includes(category.categoryName)}
                onIonChange={() => props.isActiveHandler(category.categoryName)}
                key={category.categoryName}
              />
              <IonLabel className="product-item ion-text-wrap">
                {' '}
                {category.categoryName}
              </IonLabel>
            </IonItem>
          ))}
        </IonCol>
      </IonRow>
    )
  },
)
