import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    IonButton,
    IonLabel,
    IonRow,
    IonCol,
    IonInput,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonGrid,
    IonPage,
    IonSpinner,
    useIonRouter,
    isPlatform,
} from "@ionic/react";
import ConsumerSearchResult from "./ConsumerSearchResult";
import MultiSelectDropdown from "../../components/common/MultiSelectDropdown";
import { Routes } from "../../constants/Routes";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../_redux/reducers/rootReducer";

import ToastrHook from "../../components/common/toastr/Toastr";
import { fetchSysUserTokensRequest } from "../../_redux/actions/paymentActions";
import {
    fetchConsumerList,
    resetFetchConsumers,
    updateConsumerCheckboxType,
} from "../../_redux/actions/consumerActions";
import {
    Hotel365Portal,
    ClientPortal,
    dashboardLabels,
    ORDER_CONTENT_STATUS,
    categoryTypeLbl,
    GLOBAL_SEARCH_PLACEHOLDER,
    consumerListLocationCss,
} from "../../constants/constant";
import Option from "../../components/common/MultiSelectDropdown/Option";
import MultiValue from "../../components/common/MultiSelectDropdown/MultiValue";
import { useLocation } from "react-router";

export const ConsumerList: React.FC<any> = () => {
    const router = useIonRouter();
    const dispatch = useDispatch();
    const [renderToastr] = ToastrHook();
    const _location = useLocation();
    const [searchBy, setSearchBy] = useState(ClientPortal.name);
    const [status, setStatus] = useState(ClientPortal.active);
    let existingConsumerReq = localStorage.getItem('initListReq')
    const [location, setLocation] = useState([ClientPortal.viewAll] as Record<string, any>[]);
    const [locationOptions, setLocationOptions] = useState(
        [] as Record<string, any>[]
    );
    const [searchByText, setSearchByText] = useState("");
    const isDesktopView = isPlatform("desktop");
    const isTabletView = isPlatform("tablet");
    const consumerLst = useSelector((state: RootState) => {
        return state.consumer;
    }, shallowEqual);
    const userDetails = useSelector((state: RootState) => {
        return state.systemUsers;
    }, shallowEqual);
    const systemUserId = userDetails.systemUserId;
    const payment = useSelector((state: RootState) => {
        return state.payment;
    }, shallowEqual);
    const { availableCards } = payment;
    const {
        cards,
        pending: availableCardsPending,
        error: availableCardsErr,
    } = availableCards;
    const isTablet = isPlatform('tablet');
    const isDesktop = isPlatform('desktop');
    const isMobile = isPlatform('mobile');
    useEffect(() => {
        cards.length === 0 && dispatch(fetchSysUserTokensRequest(systemUserId));
    }, []);
    const {
        error: locationErr,
        pending: locationPending,
        setLocationForCP
    } = useSelector((state: RootState) => state.UserManagement, shallowEqual);
    const {
        error: consumerErr,
        consumerLists,
        pending: consumerPending,
        consumerPaginate,
        bulkActionResponse,
        consumerListReq
    } = consumerLst;
    useEffect(() => {
        if (existingConsumerReq && _location.pathname === Routes.dashboard.consumerListUrl) {
            const request = JSON.parse(existingConsumerReq);
            request.searchBy && setSearchBy(request.searchBy);
            request.q && setSearchByText(request.q[0]);
            setStatus(request.status);
            if (!request.locationIds) {
                setLocation([ClientPortal.viewAll]);
            } else {
                const _loc = setLocationForCP.map((i: Record<string, any>) => {
                    if (request.locationIds.includes(i.locationId)) {
                        return { value: i.locationId, label: i.name };
                    }
                })
                setLocation(_loc.filter(item => item) as any);
            }
            dispatch(fetchConsumerList(request));
        }

    }, [setLocationForCP, _location.pathname])

    const checkBulkActionStartResponse = useCallback(() => {
        return bulkActionResponse && Object.keys(bulkActionResponse).length > 0;
    }, [bulkActionResponse]);

    useEffect(() => {
        let errorMessage = "";
        let serviceErr = locationErr || consumerErr;
        if (serviceErr && Object.keys(serviceErr).length > 0) {
            if (serviceErr.status === 401) {
                errorMessage = Hotel365Portal.NOT_AUTHORIZED_ERR;
            } else if (serviceErr.status === 422) {
                errorMessage = serviceErr.message ? serviceErr.message : Hotel365Portal.notAvailableData;
            } else if (serviceErr.status === 400 && serviceErr.message) {
                errorMessage = serviceErr.message;
            } else {
                errorMessage = Hotel365Portal.SERVICE_UNAVAILABLE_ERR;
            }
            dispatch(resetFetchConsumers('resetErrorMessage'));
            renderToastr(errorMessage, "danger");
        }
        if (checkBulkActionStartResponse()) {
            renderToastr(ClientPortal.CONSUMER_BULK_ACTION_SUCCESS, "success");
        }
        setTimeout(() => {
            if (checkBulkActionStartResponse()) {
                dispatch(resetFetchConsumers());
                setTimeout(() => {
                    updatePaginate(0);
                }, 300);
            }
        }, 2000);
    }, [locationErr, consumerErr, consumerLists, dispatch, bulkActionResponse]);
    useEffect(() => {
        const updatedUserLocations = setLocationForCP.map(
            (location: any) => {
                return {
                    value: location.locationId,
                    label: location.name,
                };
            }
        );
        setLocationOptions([ClientPortal.viewAll, ...updatedUserLocations]);
    }, [setLocationForCP, setLocation]);

    const searchByHandle = (e: any) => {
        setSearchBy(e.target.value);
        setSearchByText("");
    };

    const statusHandle = (e: any) => {
        setStatus(e.target.value);
    };

    const locationHandle = (locations: any) => {
        const checkViewAllIndex = locations.findIndex((o: Record<string, any>) => o.value === 'viewall')
        if (locations.length > 1 && checkViewAllIndex === 0) {
            setLocation(locations.filter((o: Record<string, any>) => o.value !== 'viewall'));
            return;
        } else if (locations.length > 1 && checkViewAllIndex > 0) {
            setLocation(locations.filter((o: Record<string, any>) => o.value == 'viewall'));
            return;
        } else {
            setLocation(locations);
            return;
        }

    };

    const searchByTextHandle = (event: any) => {
        setSearchByText(event.detail.value);
    };

    const request = useMemo(() => {
        let reqObj = {
            isExactSearch: true,
            limit: 50,
            offset: 0,
            locationIds: null,
            status: status,
        } as any;
        if (searchByText) {
            reqObj["q"] = [searchByText];
            reqObj["searchBy"] = searchBy;
        }
        if (location.length > 0 && location[0].value !== "viewall") {
            reqObj["locationIds"] = location.map((item) => item.value) as any;
        }
        return reqObj;
    }, [searchByText, searchBy, status, location]);

    const invokeSearch = (type?: string) => {
        request.offset = type ? request.offset : 0;
        if (!request.locationIds) {
            setLocation([ClientPortal.viewAll]);
        }
        localStorage.removeItem('initListReq')
        localStorage.removeItem('detailConsumer')
        dispatch(fetchConsumerList(request));
    };

    const showConsumerResults = () => {
        return Object.keys(consumerPaginate).length > 0;
    };

    const updatePaginate = (page: number) => {
        dispatch(updateConsumerCheckboxType([]));
        request.offset = page !== 0 ? (page - 1) * 50 : 0;
        invokeSearch('paginate');
    };
    return (
        <IonPage className="consumer-list">
            <IonRow className={(isDesktop || isTablet) ? "ion-padding-start ion-padding-end consumer-scroll-content" : "ion-padding-start ion-padding-end consumer-scroll-content-mobile"}>
                <IonCol className="consumer-list-content">
                    {!locationPending && !locationErr && (
                        <>
                            <IonRow>
                                <IonCol sizeLg="10" sizeMd="6" sizeXs="12">
                                    <h1 className="header-primary">
                                        <b>{dashboardLabels.CONSUMER_MANAGEMENT_LABEL}</b>
                                    </h1>
                                </IonCol>
                                <IonCol
                                    sizeLg="2"
                                    sizeMd="6"
                                    sizeXs="12"
                                    className={
                                        isDesktopView || isTabletView
                                            ? "add-consmer-right"
                                            : ""
                                    }
                                >
                                    <IonButton
                                        className="add-consumer-text"
                                        color="primary"
                                        onClick={() => {
                                            localStorage.removeItem('initListReq')
                                            localStorage.removeItem('detailConsumer')
                                            consumerListReq && Object.keys(consumerListReq).length > 0 && consumerListReq.searchBy !== 'accountid' && localStorage.setItem('initListReq', JSON.stringify(consumerListReq))
                                            dispatch(resetFetchConsumers());
                                            router.push(
                                                Routes.dashboard
                                                    .consumerDetailUrl
                                            );
                                        }}
                                    >
                                        {/* <IonLabel className="add-consmer-btn-lbl">
                                            +
                                        </IonLabel> */}
                                        {ClientPortal.add_consumer}
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <h1 className="consmer-search-filter-lbl">
                                        {ClientPortal.consumer_search_filters}
                                    </h1>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol sizeLg="5" sizeMd="5" sizeXs="12" className={isDesktop ? "ion-padding-end" : ""}>
                                    <IonRow>
                                        <IonCol>
                                            <IonLabel className="consumer-searchBy-lbl">
                                                {ClientPortal.consumer_search_by}
                                            </IonLabel>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol
                                            sizeLg="12"
                                            sizeMd="12"
                                            sizeXs="11"
                                            className="consumer-select-input"
                                        >
                                            <IonSelect
                                                interface="popover"
                                                className="input-field-border consumer-select-dropdown"
                                                value={searchBy}
                                                onIonChange={searchByHandle}
                                            >
                                                {Object.values(
                                                    ClientPortal.consumerSearchByOptions
                                                ).map((item: Record<string, any>) => (
                                                    <IonSelectOption
                                                        key={item.value}
                                                        value={item.value}
                                                    >
                                                        {item.label}
                                                    </IonSelectOption>
                                                ))}
                                            </IonSelect>
                                            <IonInput
                                                className="consumer-input-item consumer-input-search"
                                                name={searchBy}
                                                id={searchBy}
                                                maxlength={30}
                                                placeholder={`${ClientPortal.consumer_enter
                                                    } ${searchBy &&
                                                    searchBy[0].toUpperCase() +
                                                    searchBy.slice(1)
                                                    }`}
                                                value={searchByText}
                                                onIonChange={searchByTextHandle}
                                            />
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                                <IonCol
                                    sizeLg="3"
                                    sizeMd="3"
                                    sizeXs="11"
                                    className={
                                        isDesktopView || isTabletView
                                            ? "consumer-status-col ion-padding-end"
                                            : ""
                                    }
                                >
                                    <IonLabel className="consumer-status-lbl consumer-searchBy-lbl">
                                        {ORDER_CONTENT_STATUS.slice(0, 6)}
                                    </IonLabel>
                                    <IonSelect
                                        interface="popover"
                                        className="input-field-border status-dropdown"
                                        value={status}
                                        onIonChange={statusHandle}
                                    >
                                        {Object.values(
                                            ClientPortal.consumerStatusOptions
                                        ).map((item: Record<string, any>) => (
                                            <IonSelectOption
                                                key={item.value}
                                                value={item.value}
                                            >
                                                {item.label}
                                            </IonSelectOption>
                                        ))}
                                    </IonSelect>
                                </IonCol>
                                <IonCol
                                    sizeLg="3"
                                    sizeMd="4"
                                    sizeXs="11"
                                    className={
                                        isDesktopView || isTabletView
                                            ? "consumer-location-col ion-padding-end"
                                            : " "
                                    }
                                >
                                    <IonLabel className="consumer-status-lbl consumer-searchBy-lbl">
                                        {categoryTypeLbl}
                                    </IonLabel>
                                    <MultiSelectDropdown
                                        colourStyles={consumerListLocationCss}
                                        setSelectedOptions={locationHandle}
                                        selectedOptions={location}
                                        options={locationOptions}
                                        isMulti={true}
                                        components={{ Option, MultiValue }}
                                    />
                                </IonCol>
                                <IonCol
                                    sizeLg="1"
                                    sizeMd="2"
                                    sizeXs="11"
                                    className={(isDesktopView || isTabletView) ? "consumer-searchbtn-col" : ""}
                                >
                                    <IonButton
                                        className="consumer-searchbtn-container"
                                        onClick={() => {
                                            dispatch(
                                                updateConsumerCheckboxType([])
                                            );
                                            dispatch(resetFetchConsumers());
                                            invokeSearch();
                                        }}
                                    >
                                        {GLOBAL_SEARCH_PLACEHOLDER}
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="8">
                                    <h1 className="consmer-search-filter-lbl">
                                        {showConsumerResults()
                                            ? ClientPortal.search_result
                                            : consumerPending ? '' : ClientPortal.perform_search_lbl}
                                    </h1>
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                    {locationPending ||
                        (consumerPending && (
                            <IonSpinner
                                name="circles"
                                className="spinner-consumer"
                            />
                        ))}
                    {showConsumerResults() && (
                        <ConsumerSearchResult
                            updatePaginate={updatePaginate}
                            location={location}
                            updatedUserLocations={setLocationForCP}
                        />
                    )}
                </IonCol>
            </IonRow>
        </IonPage>
    );
};
export default ConsumerList;
