//vendors
import { IonItem, IonLabel, IonCol, IonList, isPlatform } from '@ionic/react';
//constants
import { customRange, defaultToTime, timeValues } from '../../../constants/constant';

const DateRangesList: React.FC<any> = ({
    dateRange,
    setDateRange,
    showCustomRange,
    predefinedOptions,
    onPredefinedOptionSelect,
    setPopoverOpen,
    setOpenModal,
    setTimeRange,
    setFormErrors
}) => {
    const isMobileView = isPlatform("mobile");
    const isTabletView = isPlatform("tablet");
    return (
        <IonCol
            className={showCustomRange ? "ion-no-padding date-range-list" : "ion-no-padding"}
            size={(showCustomRange && !isMobileView) ? "3" : (showCustomRange && isTabletView) ? "3" : "12"}>
            <IonList lines="none" className="ion-no-padding">
                {Object.keys(predefinedOptions).map((item: string, index: number) => {
                    return (
                        <IonItem
                            button
                            className={
                                item === dateRange ?
                                    'ion-no-padding custom-range-items active-custom-range' :
                                    'custom-range-items ion-no-padding'
                            }
                            key={index}
                        >
                            <IonLabel
                                className="custom-range-label click-cursor"
                                onClick={() => {
                                    setTimeRange({ fromTime: timeValues[0]['value'], toTime: defaultToTime });
                                    setFormErrors({ isValid: true, errorMessage: "" })
                                    setDateRange(item);
                                    onPredefinedOptionSelect(item);
                                    item !== customRange && setPopoverOpen(false);
                                    if (isMobileView && !isTabletView) {
                                        item === customRange && setOpenModal(true);
                                    }
                                }}
                            >
                                {item}
                            </IonLabel>
                        </IonItem>
                    )
                })}
            </IonList>
        </IonCol >
    )
}

export default DateRangesList;