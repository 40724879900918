import React, { useState } from "react";
import {
    IonButton,
    IonChip,
    IonCol,
    IonFooter,
    IonHeader,
    IonLabel,
    IonModal,
    IonRow,
    IonTextarea,
    IonTitle,
    isPlatform
} from '@ionic/react';
//constants
import {
    FOOTER_CANCEL_BUTTON,
    FOOTER_SAVE_BUTTON,
    POS_ENTER_REASON_DESCP,
    POS_PURCHASE_AS_COMP
} from '../../constants/constant';

export const PurchaseAsComp: React.FC<any> = (props) => {
    const {
        openModal,
        closeModal,
        save
    } = props;
    const [compReason, setCompReason] = useState('');
    const isTabletView = isPlatform("tablet");
    const isDesktopView = isPlatform("desktop");
    
    return (
        <>
            <div>
                <IonModal
                    isOpen={openModal}
                    className={!isDesktopView && !isTabletView ? "pos-modal-mobile" : "order-modal-pop pos-purchase-comp-modal"}
                    onDidDismiss={() => {closeModal(); setCompReason('')}}
                >
                    <IonHeader className="header">
                        <IonRow className='primary-header'>
                            <IonTitle>{POS_PURCHASE_AS_COMP}</IonTitle>
                            <IonChip
                                color="primmary"
                                className="ion-button"
                                onClick={() => {setCompReason('');closeModal()}}
                            >
                                <p className="cancel">&times;</p>
                            </IonChip>
                        </IonRow>
                    </IonHeader>
                    <form className={!isDesktopView && !isTabletView ? "form-mobile-view" : "order-form-body"}>
                        <IonRow className="modal-body">
                            <IonCol offset="1"></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonLabel className="pos-comp-label">
                                {POS_ENTER_REASON_DESCP}
                            </IonLabel>
                            <IonTextarea className="pos-comp-reason-description" rows={6} cols={20}  value={compReason} maxlength={50}onIonChange={e => setCompReason(e.detail.value!)}></IonTextarea>
                        </IonRow>
                    </form>
                    <IonFooter className="footer">
                        <IonRow>
                            <IonCol offset="4" sizeMd="4">
                                <IonButton
                                    color="gray"
                                    expand="block"
                                    className="btn-text-cases-cancel"
                                    onClick={() => {setCompReason('');closeModal()}}
                                >
                                    {FOOTER_CANCEL_BUTTON}
                                </IonButton>
                            </IonCol>
                            <IonCol sizeMd="4">
                                <IonButton
                                    color="primary"
                                    expand="block"
                                    type="submit"
                                    className="btn-text-cases-save"
                                    onClick={() => save(compReason)}
                                >
                                    {FOOTER_SAVE_BUTTON}
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonFooter>
                </IonModal>
            </div>
        </>
    )
}

