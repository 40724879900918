// vendors
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
    isPlatform,
} from "@ionic/react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    IgrDataGrid,
    IgrDataGridCellEventArgs,
    IgrGridCellValueChangingEventArgs,
    IgrGridDataCommittingEventArgs,
    IgrNumericColumn,
    IgrTemplateCellInfo,
    IgrTemplateColumn,
    IgrTextColumn,
    IIgrCellTemplateProps,
} from "igniteui-react-grids";

import { RootState } from "../../_redux/reducers/rootReducer";
import { DataGridPager } from "../../pages/HotelManager/ProductsPage/ProductGrid/DataGridPager";
import AddProductOrderButton from "./AddProductOrderButton";

import {
    deleteMarketOrderProduct,
    patchMarketOrderProduct,
} from "../../_redux/actions/orderActions";
import { addZeroes, getLocalizedNumericValues } from "../../utils";
import { chevronBackCircleOutline, chevronForwardCircleOutline } from 'ionicons/icons';
import {
    Hotel365Portal,
    GLOBAL_FOOTER_TOTAL,
    GLOBAL_SUB_TOTAL,
    ORDER_ADD_NEW_PRODUCTS,
    ORDER_CONTENT_STATUS,
    ORDER_FOOTER_DELIVERY_FEE,
    ORDER_PRODUCT_COLUMN,
    ORDER_QTYORDERED_COLUMN,
    ORDER_QTY_COLUMN,
    ORDER_QTYDELIVERED_COLUMN,
    ORDER_PRICE_COLUMN,
    DELETE,
    fixPositionTwoDecimal
} from "../../constants/constant";
import getSymbolFromCurrencyCode from "../../i18n/currency-symbol-map/index";

export const OrderDetails: React.FC<any> = (props) => {
    const colNameMapping = {
        checkBox: "",
        productName: ORDER_PRODUCT_COLUMN,
        qty: ORDER_QTY_COLUMN,
        qtyOrdered: ORDER_QTYORDERED_COLUMN,
        qtyDelivered: ORDER_QTYDELIVERED_COLUMN,
        price: ORDER_PRICE_COLUMN,
        total: GLOBAL_SUB_TOTAL,
    };
    let _grid: IgrDataGrid;
    const dispatch = useDispatch();
    const originalColList = { ...colNameMapping } as any;
    const [selectedOrderProduct, setSelectedOrderProduct] = useState({});
    const [current, setCurrent] = useState("");
    const [checked, setChecked] = useState([] as string[]);
    const [subTotal, setSubTotal] = useState(0.0);
    const [deliveryFee, setDeliveryFee] = useState(0.0);
    const isDesktopView = isPlatform("desktop");
    const isTabletView = isPlatform("tablet");
    const isMobileView = isPlatform("mobile");

    const { toggleAccordion, sidenavExpanded, setSidenavExpanded } = props
    const {
        fetchMarketOrderProducts,
        fetchMarketOrderProductsPagination,
        error,
        fetchMarketOrder,
        removedMarketOrderProduct,
        patchMarketOrderProduct: updateMarketOrderProduct,
    } = useSelector((state: RootState) => state.order, shallowEqual);

    const profile = useSelector((state: RootState) => state.profile, shallowEqual);
    const { currentProfile } = profile;
    const { currency } = currentProfile;

    const checkRemovedMarketProductsOrder = useCallback(() => {
        return (
            removedMarketOrderProduct &&
            Object.keys(removedMarketOrderProduct).length > 0
        );
    }, [removedMarketOrderProduct]);

    useEffect(() => {
        !error && checkRemovedMarketProductsOrder() && setChecked([]);
    }, [error, removedMarketOrderProduct, dispatch]);

    useEffect(() => {
        if (fetchMarketOrder && Object.keys(fetchMarketOrder).length > 0) {
            setSubTotal(
                fetchMarketOrder.marketOrderStatus.subtotal ||
                0.0
            );
            setDeliveryFee(
                fetchMarketOrder.marketOrderStatus.deliveryFee ||
                0.0
            );
        }
    }, [fetchMarketOrder]);

    useEffect(() => {
        if (_grid?.dataSource && _grid?.dataSource.length > 0) {
            setTimeout(() => {
                updateFinalCalculation();
            }, 1000);
        }
    }, [fetchMarketOrderProducts]);

    const onGridRef = (grid: IgrDataGrid) => {
        if (!grid) {
            return;
        }

        _grid = grid;
    };
    const selectedColumnsTextColor = (i: string) => {
        return ["qty"].indexOf(i) > -1
            ? props.primaryColorHex || Hotel365Portal.defaultPrimaryColor
            : ["total"].indexOf(i) > -1
                ? "#000000"
                : Hotel365Portal.gridTextValueColorCode;
    };

    const checkEditableField = (i: string, status: string) => {
        return (["qty"].indexOf(i) > -1) && (["pending"].indexOf(
            status && status.toLowerCase()
        ) > -1) ? true : false;
    };

    const checkIsPendingCancelledStatusColumns = (i: string) => {
        return ["qtyOrdered", "qtyDelivered"].indexOf(i) > -1 ? true : false;
    };

    const checkColumnHidden = (i: string, status: string) => {
        return ["pending", "cancelled", "canceled"].indexOf(
            status && status.toLowerCase()
        ) > -1
            ? checkIsPendingCancelledStatusColumns(i)
            : ["qty"].indexOf(i) > -1
                ? true
                : false;
    };

    const checkCheckBoxColumnHidden = (status: string) => {
        return ["pending"].indexOf(status && status.toLowerCase()) > -1
            ? false
            : true;
    };

    const selectedColumnsTextStyle = (i: string) => {
        if (["productName", "total", "qty"].indexOf(i) > -1)
            return "Normal 700 14px Roboto";
    };

    const renderOtherColumns = (i: string) => {
        const { marketOrderStatus } = fetchMarketOrder || {};
        const { status = "" } = marketOrderStatus || {};
        return (
            <IgrTextColumn
                field={i}
                key={i}
                horizontalAlignment={i === "productName" ? "left" : "center"}
                headerText={originalColList[i] || i}
                isFilteringEnabled={false}
                isColumnOptionsSummariesEnabled={false}
                isEditable={checkEditableField(i, status)}
                isHidden={checkColumnHidden(i, status)}
                textColor={selectedColumnsTextColor(i)}
                textStyle={selectedColumnsTextStyle(i)}
                name={i}
            />
        );
    };
    const formatCostTotalColumn = () => {
        /**
         * to show the format with 2 decimal points
         */
        return new Intl.NumberFormat(navigator.language, {
            style: "currency",
            currency: currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    const renderNumericColumns = (i: string) => {
        const { marketOrderStatus } = fetchMarketOrder || {};
        const { status = "" } = marketOrderStatus || {};
        return (
            <IgrNumericColumn
                field={i}
                key={i}
                horizontalAlignment={"center"}
                headerText={originalColList[i] || i}
                isFilteringEnabled={false}
                isColumnOptionsSummariesEnabled={false}
                isEditable={checkEditableField(i, status)}
                isHidden={checkColumnHidden(i, status)}
                textColor={selectedColumnsTextColor(i)}
                textStyle={selectedColumnsTextStyle(i)}
                name={i}
                positivePrefix={getSymbolFromCurrencyCode(currency)}
                formatOverride={formatCostTotalColumn()}
            />
        );
    };
    const customCheckBox = (props: IIgrCellTemplateProps) => {
        return (
            <IonItem className="align-checkboxs">
                <IonCheckbox
                    checked={checked.includes(
                        props.dataContext.rowItem.marketOrderItemId
                    )}
                    onIonChange={(e) => {
                        checkedMarkedProduct(props.dataContext as any)
                    }}
                />
            </IonItem>
        );
    };

    const renderCheckBox = (i: string) => {
        const arr = [];
        const { marketOrderStatus } = fetchMarketOrder || {};
        const { status = "" } = marketOrderStatus || {};
        return (
            <IgrTemplateColumn
                field={i}
                key={i}
                horizontalAlignment="center"
                template={(props: IIgrCellTemplateProps) =>
                    customCheckBox(props)
                }
                width="100"
                headerText={""}
                isFilteringEnabled={false}
                isHidden={checkCheckBoxColumnHidden(status)}
                isColumnOptionsEnabled={false}
                isColumnOptionsSummariesEnabled={false}
                name={i}
            />
        );
    };

    const renderCols = () => {
        const arr = [];
        const { marketOrderStatus } = fetchMarketOrder || {};
        const { status = "" } = marketOrderStatus || {};
        for (let i in colNameMapping) {
            // @ts-ignore
            i !== "productId" &&
                arr.push(
                    ["checkBox"].indexOf(i) > -1
                        ? renderCheckBox(i)
                        : ["price", "total"].indexOf(i) > -1
                            ? renderNumericColumns(i)
                            : renderOtherColumns(i)
                );
        }
        return arr;
    };
    const checkedMarkedProduct = (event: IgrTemplateCellInfo) => {
        const rowItem = event.rowItem;
        const seletedValues = Object.assign([], checked); // to Clone an Array
        if (seletedValues.length > 0) {
            const isPresent = seletedValues.some(
                (item: string) => item === rowItem.marketOrderItemId
            );
            if (isPresent) {
                seletedValues.splice(
                    seletedValues.indexOf(rowItem.marketOrderItemId),
                    1
                );
            } else {
                seletedValues.push(rowItem.marketOrderItemId);
            }
        } else {
            seletedValues.push(rowItem.marketOrderItemId);
        }
        setChecked(seletedValues);
    };

    const selectedRow = (gridData: any, event: IgrDataGridCellEventArgs) => {
        setSelectedOrderProduct(event.cellInfo.rowItem);
    };

    const onPageChanged = (offset: number) => {
        props.updatePaginate(offset);
    };

    const updateFinalCalculation = () => {
        const total = addZeroes(
            _grid?.dataSource
                .map((item: Record<string, any>) => Number(item?.total))
                .reduce((prev: number, next: number) => prev + next)
        );

        setSubTotal(Number(total));
    };

    const onCellValueChanging = async (gridData: any, event: any) => {
        event.newValue =
            typeof event.newValue === "string" &&
                event.newValue.indexOf("%") > -1
                ? event.newValue.replace("%", "")
                : event.newValue;
        if (
            ["qty"].indexOf(event.column.name) > -1 &&
            (event.newValue < 0 || !/^\d+$/.test(Number(event.newValue) as any))
        ) {
            gridData?.rejectEdit(event.editID);
        } else {
            gridData?.acceptEdit(event.editID);
            gridData?.commitEdits();
            event._implementation._item[event.column.name] = event.newValue;
            event._implementation._item["total"] = Number(
                addZeroes(event.newValue * event._implementation._item["price"])
            );
            const reqObject = {
                marketOrderItemId:
                    event._implementation._item["marketOrderItemId"],
                marketOrderHeaderId: fetchMarketOrder?.marketOrderHeaderId,
                price: event._implementation._item["price"],
                qtyOrdered: event._implementation._item[event.column.name],
            };
            dispatch(patchMarketOrderProduct(reqObject));
            updateFinalCalculation();
        }
    };
    const onDataCommit = (
        s: IgrDataGrid,
        e: IgrGridDataCommittingEventArgs
    ) => {
        s.acceptCommit(e.commitID);
    };

    const invokeDeleteMarketOrder = () => {
        const request = checked.map((item) => {
            return {
                marketOrderHeaderId: fetchMarketOrder?.marketOrderHeaderId,
                marketOrderItemId: item,
            };
        });
        dispatch(deleteMarketOrderProduct(request));
    };

    const checkOrderTotal = () => {
        return Number(subTotal) + Number(deliveryFee);
    };

    useEffect(() => {
        props.setFinalTotal(checkOrderTotal());
    }, [checkOrderTotal]);
    // @ts-ignore
    return (
        <IonGrid
            className={isMobileView? "order-list-container-mobile order-products": "order-list-container order-products scroll-content"}
            key="market-product-list"
        >
            <IonRow className="order-details-section">
                <IonCol
                    onClick={toggleAccordion}
                    className="orderNav-back-icon"
                    size="1"
                    sizeMd="2"
                    sizeXs="12"
                    style={{height: isTabletView? "3%": "10%"}}
                >
                    <IonIcon
                        size="large click-cursor"
                        md={
                            sidenavExpanded
                                ? chevronBackCircleOutline
                                : chevronForwardCircleOutline
                        }
                    />
                </IonCol>
                <IonText className="header-text order-number">
                    {fetchMarketOrder?.orderNumber}
                </IonText>
                <IonCol className='status-text-view' sizeMd={!isDesktopView ? '12' : '5'} sizeXs='12'>
                    <IonText className={isMobileView? 'header-text': 'header-text order-status'}>
                        {fetchMarketOrder?.marketOrderStatus?.status && ORDER_CONTENT_STATUS +
                            fetchMarketOrder?.marketOrderStatus?.status[0].toUpperCase() +
                            fetchMarketOrder?.marketOrderStatus?.status
                                .slice(1)
                                .toLocaleLowerCase()}
                    </IonText>
                </IonCol>
            </IonRow>
            {isMobileView && <br />}
            {fetchMarketOrder?.marketOrderStatus?.status.toLowerCase() ===
                "pending" && (
                    <IonRow color="secondary toolbar-container">
                        <IonCol>
                            <AddProductOrderButton
                                onHeaderButtonClick={(e: any) =>
                                    setCurrent("ADD PRODUCT")
                                }
                                current={current}
                                match={props.match}
                                history={history}
                            />
                            <IonButton
                                size="small"
                                fill={current === "Delete" ? "solid" : undefined}
                                className="grid-btns"
                                onClick={() => {
                                    setCurrent("Delete");
                                    invokeDeleteMarketOrder();
                                }}
                                disabled={checked.length > 0 ? false : true}
                            >
                                {DELETE}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                )}

            <IonRow key="grid-container">
                <IgrDataGrid
                    ref={onGridRef}
                    height="267px"
                    width="100%"
                    defaultColumnMinWidth={100}
                    autoGenerateColumns={false}
                    dataSource={fetchMarketOrderProducts}
                    selectionMode="SingleCell"
                    activationMode="Cell"
                    editMode="CellBatch"
                    cellClicked={(s: IgrDataGrid, e: IgrDataGridCellEventArgs) =>
                        selectedRow(s, e)
                    }
                    cellValueChanging={(
                        s: IgrDataGrid,
                        e: IgrGridCellValueChangingEventArgs
                    ) => onCellValueChanging(s, e)}
                    dataCommitting={(
                        s: IgrDataGrid,
                        e: IgrGridDataCommittingEventArgs
                    ) => onDataCommit(s, e)}
                    isColumnOptionsEnabled="true"
                    headerBackground="#635D5D"
                    headerTextColor="#ffffff"
                    autoAcceptEdits={false}
                    headerTextStyle="Normal Normal 12px Roboto"
                    cellTextStyle="Normal Normal 14px Roboto"
                    columnMovingMode={"NONE"}
                >
                    {renderCols()}
                </IgrDataGrid>
            </IonRow>
            {fetchMarketOrderProducts.length === 0 && (
                <IonRow className={sidenavExpanded ? "no-order-products" : "no-orders-sidenav"}>
                    <IonCol size="12" sizeXs='12' className={!isDesktopView && !isTabletView ? "no-orders-mobile" : "no-orders-label"}>
                        <IonItem lines="none">
                            <IonLabel>
                                <b>{ORDER_ADD_NEW_PRODUCTS}</b>
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                </IonRow>
            )}
            {fetchMarketOrderProducts.length > 0 && (
                <IonRow className={isTabletView? "order-pagination-sections": ""}>
                    <DataGridPager
                        dataSource={fetchMarketOrderProducts}
                        pageSize={fetchMarketOrderProductsPagination.limit}
                        totalPageCount={
                            fetchMarketOrderProductsPagination.pageCount
                        }
                        currentPage={
                            fetchMarketOrderProductsPagination.currentPage
                        }
                        pagedChanged={onPageChanged}
                        // mobileViewPager={isMobileView}
                    />
                </IonRow>
            )}
            {fetchMarketOrderProducts.length > 0 && (
                <IonRow className="float-right float-right-table">
                    <IonCol>
                        <table>
                            <tr>
                                <td>{GLOBAL_SUB_TOTAL}</td>
                                <th>{getLocalizedNumericValues(subTotal, "currency", currency, fixPositionTwoDecimal)}</th>
                            </tr>
                            <tr>
                                <td>{ORDER_FOOTER_DELIVERY_FEE}</td>
                                <th>
                                    {(fetchMarketOrder?.marketOrderStatus?.status.toLowerCase() !==
                                        "cancelled") || (fetchMarketOrder?.marketOrderStatus?.status.toLowerCase() !==
                                            "canceled")
                                        ? `${getLocalizedNumericValues(deliveryFee, "currency", currency, fixPositionTwoDecimal)}`
                                        : "N/A"}
                                </th>
                            </tr>
                            <tr>
                                <td>{GLOBAL_FOOTER_TOTAL}</td>
                                <th>
                                    {(fetchMarketOrder?.marketOrderStatus?.status.toLowerCase() !==
                                        "cancelled") || (fetchMarketOrder?.marketOrderStatus?.status.toLowerCase() !== "canceled")
                                        ? `${getLocalizedNumericValues(checkOrderTotal(), "currency", currency, fixPositionTwoDecimal)}` : `${getLocalizedNumericValues(0, "currency", currency)}`}
                                </th>
                            </tr>
                        </table>
                    </IonCol>
                </IonRow>
            )}
        </IonGrid>
    );
};

export default OrderDetails;
