import { PostResetPassword, PostResetPasswordSuccess, PostResetPasswordFailure, PostResetPasswordType,

    PutResetPassword, PutResetPasswordSuccess, PutResetPasswordFailure, resetPasswordData, ResetPasswordData
} 
from './../actions/resetPasswordActions';
import { ResetPassword, UpdatePassword } from './../../models/ResetPassword';

 export type resetPasswordTypes = PostResetPasswordType;

 export interface IResetPassword {
    pending: boolean;
    error: Record<string, any> | null;
    postResetPassword: ResetPassword | null;
    errorMsg: string;
    putResetPassword: UpdatePassword | null;
    ResetPasswordErrors?: Record<string, any> | null
    updatePasswordStatus: string
    postResetPasswordStatus: Record<string, any> | null
}
const initialState = {
    pending: true,
    error: null,
    postResetPassword:{} as ResetPassword,
    errorMsg: '',
    putResetPassword:{} as UpdatePassword,
    ResetPasswordErrors:null,
    updatePasswordStatus:'',
    postResetPasswordStatus: null
};

const strategies = {
    POST_RESET_PASSWORD_REQUEST: postPutResetPassword,
    POST_RESET_PASSWORD_SUCCESS: postResetPasswordSuccess,
    POST_RESET_PASSWORD_FAILURE: postResetPasswordFailure,
    PUT_RESET_PASSWORD_REQUEST: postPutResetPassword,
    PUT_RESET_PASSWORD_SUCCESS: putResetPasswordSuccess,
    PUT_RESET_PASSWORD_FAILURE: putResetPasswordFailure,
    RESET_PASSWORD_DATA: resetPasswordDatas,
    default: (state: IResetPassword) => state,
};

function postPutResetPassword(state: IResetPassword, action: PostResetPassword | PutResetPassword) {
    return { ...state, error: null, pending: true , ResetPasswordErrors: null }
}

function postResetPasswordSuccess(state: IResetPassword, action: PostResetPasswordSuccess) {
    return { ...state, error: null, pending: false, postResetPassword:action.response, postResetPasswordStatus : action.response}
}

function postResetPasswordFailure(state: IResetPassword, action: PostResetPasswordFailure) {
    return { ...state, error: action.error, errorMsg: "This email is not registered", pending: false , postResetPassword: null }
} 

function putResetPasswordSuccess(state: IResetPassword, action: PutResetPasswordSuccess) {     
    return { ...state, error: null, pending: false, updatePasswordStatus : action.response, putResetPassword:action.response, ResetPasswordErrors: null }
}

function putResetPasswordFailure(state: IResetPassword, action: PutResetPasswordFailure) {
    return { ...state, error: action.error, pending: false ,putResetPassword: null , ResetPasswordErrors : action && action.error && action.error.status }
}
function resetPasswordDatas(
    state: IResetPassword,
    action: ResetPasswordData
) {
       return Object.assign({}, state, {
        ...state,
        postResetPasswordStatus :null,
        error:null
    });
}

export default (state: IResetPassword = initialState, action: resetPasswordTypes): IResetPassword => {
    return (strategies[action.type] || strategies.default)(state, action as never);
};