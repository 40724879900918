//constants
import * as PROFILE from '../ActionTypes/profileTypes';
//models
import {
  FetchProfileRequest,
  FetchProfileSuccess,
  FetchProfileFailure,
  PatchProfile,
  PatchProfileSuccess,
  PatchProfileFailure,
  PostProfile,
  PostProfileSuccess,
  PostProfileFailure,
  FetchProfilesRequestWithSysUserId,
  FetchProfilesSuccessWithSysUserId,
  FetchProfilesFailureWithSysUserId,
} from '../../models/MarketProfile'
export interface FetchProfileSuccessRespsonse {
  response: Record<string, any> | {};
}

/** 
* fetch PROFILE details
*/
export function fetchProfileRequest(locationId: string): FetchProfileRequest {
  return {
    type: PROFILE.FETCH_PROFILE_REQUEST,
    locationId
  };
}
export function fetchProfileSuccess(data: FetchProfileSuccessRespsonse): FetchProfileSuccess {
  return {
    type: PROFILE.FETCH_PROFILE_SUCCESS,
    data,
  };
}
export function fetchProfileFailure(error: Record<string, any>): FetchProfileFailure {
  return {
    type: PROFILE.FETCH_PROFILE_FAILURE,
    error: { error: 'Error in fetching profile, please try after some time' },
  };
}

/** 
* Patch PROFILE
*/
export type PatchProfileType = PatchProfile | PatchProfileSuccess | PatchProfileFailure;
export function patchProfile(locationId: string, data: Record<string, any> | {}): PatchProfile {
  return {
    type: PROFILE.PATCH_PROFILE,
    locationId,
    data,
  };
}
export function patchProfileSuccess(response: PatchProfileSuccess): PatchProfileSuccess {
  return {
    type: PROFILE.PATCH_PROFILE_SUCCESS,
    response,
  };
}
export function patchProfileFailure(error: Record<string, any>): PatchProfileFailure {
  return {
    type: PROFILE.PATCH_PROFILE_FAILURE,
    error: { error: 'Error in saving data, please try after some time' },
  };
}

/**
 * POST PROFILE
 */
export function postProfile(locationId: any, data: Record<string, any> | {}, initialStepOfPostCall: number): PostProfile {
  return {
    type: PROFILE.POST_PROFILE,
    locationId,
    data,
    initialStepOfPostCall
  };
}
export function postProfileSuccess(response: PostProfileSuccess): PostProfileSuccess {
  return {
    type: PROFILE.POST_PROFILE_SUCCESS,
    response,
  };
}
export function postProfileFailure(error: Record<string, any>): PostProfileFailure {
  return {
    type: PROFILE.POST_PROFILE_FAILURE,
    error: { error: 'Error in saving data, please try after some time' },
  };
}

/**FETCH PROFILES WITH SYSTEM USER ID */
export function fetchProfilesRequestWithSysUserId(systemUserId: string): FetchProfilesRequestWithSysUserId {
  return {
    type: PROFILE.FETCH_PROFILES_REQUEST_WITH_SYS_USER_ID,
    systemUserId
  };
}
export function fetchProfilesSuccessWithSysUserId(data: Record<string, any>): FetchProfilesSuccessWithSysUserId {
  const { items } = data;
  return {
    type: PROFILE.FETCH_PROFILES_SUCCESS_WITH_SYS_USER_ID,
    profiles: items
  };
}
export function fetchProfilesFailureWithSysUserId(error: Record<string, any>): FetchProfilesFailureWithSysUserId {
  return {
    type: PROFILE.FETCH_PROFILES_FAILURE_WITH_SYS_USER_ID,
    error: error
  };
}

/**Reset Profile Updation */
export interface ResetProfileUpdation {
    type: PROFILE.RESET_PROFILE_UPDATION;
}
export type ResetProfileUpdationType = ResetProfileUpdation;

export function resetProfileUpdation(): ResetProfileUpdationType {
    return {
        type: PROFILE.RESET_PROFILE_UPDATION,
    };
}