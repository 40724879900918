//vendors
import React from "react";
import {
    IonButton,
    IonChip,
    IonCol,
    IonFooter,
    IonHeader,
    IonLabel,
    IonModal,
    IonRow,
    IonTitle,
} from "@ionic/react";
//constants
import { paymentConstants } from './constants';

const DeleteModal: React.FC<any> = ({ cardDeleteModal, setCardDeleteModal, onConfirm }) => {
    return (
        <div>
            <IonModal
                isOpen={cardDeleteModal}
                className={"payment-delete-modal"}
                onDidDismiss={() => setCardDeleteModal(false)}
            >
                <IonHeader className="payment-delete-header">
                    <IonRow className="confirm-delete-head">
                        <IonTitle>{paymentConstants.CONFIRM_DELETE_HEADER}</IonTitle>
                        <IonChip
                            color="primmary"
                            className="ion-button-chip"
                            onClick={() => setCardDeleteModal(false)}
                        >
                            <p className="payment-cancel-chip">&times;</p>
                        </IonChip>
                    </IonRow>
                </IonHeader>
                <form className="payment-form-delete-body">
                    <IonRow>
                        <IonCol size="11">
                            <IonLabel className="delete-text-bold">
                                {paymentConstants.CONFIRM_DELETE_BODY_TEXT}
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                </form>
                <IonFooter className="footer">
                    <IonRow>
                        <IonCol size="4">
                            <IonButton
                                color="gray"
                                expand="block"
                                className="payment-cancel"
                                onClick={() => setCardDeleteModal(false)}
                            >
                                {paymentConstants.CONFIRM_CANCEL_BUTTON}
                            </IonButton>
                        </IonCol>
                        <IonCol size="4" className="payment-delete-btn">
                            <IonButton
                                expand="block"
                                className="delete-button"
                                onClick={onConfirm}
                            >
                                {paymentConstants.CONFIRM_DELETE_BUTTON}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonFooter>
            </IonModal>
        </div>
    );
};
export default DeleteModal;
