//get public key
export const GET_PUBLIC_KEY_REQUEST = 'GET_PUBLIC_KEY_REQUEST';
export const GET_PUBLIC_KEY_SUCCESS = 'GET_PUBLIC_KEY_SUCCESS';
export const GET_PUBLIC_KEY_FAILURE = 'GET_PUBLIC_KEY_FAILURE';

export type GET_PUBLIC_KEY_REQUEST = typeof GET_PUBLIC_KEY_REQUEST;
export type GET_PUBLIC_KEY_SUCCESS = typeof GET_PUBLIC_KEY_SUCCESS;
export type GET_PUBLIC_KEY_FAILURE = typeof GET_PUBLIC_KEY_FAILURE;

//attach new card
export const POST_ADDCARD_REQUEST = 'POST_ADDCARD_REQUEST';
export const POST_ADDCARD_SUCCESS = 'POST_ADDCARD_SUCCESS';
export const POST_ADDCARD_FAILURE = 'POST_ADDCARD_FAILURE';

export type POST_ADDCARD_REQUEST = typeof POST_ADDCARD_REQUEST;
export type POST_ADDCARD_SUCCESS = typeof POST_ADDCARD_SUCCESS;
export type POST_ADDCARD_FAILURE = typeof POST_ADDCARD_FAILURE;

//get cards with sys userid
export const GET_SYSUSER_TOKENS_REQUEST = 'GET_SYSUSER_TOKENS_REQUEST';
export const GET_SYSUSER_TOKENS_SUCCESS = 'GET_SYSUSER_TOKENS_SUCCESS';
export const GET_SYSUSER_TOKENS_FAILURE = 'GET_SYSUSER_TOKENS_FAILURE';

export type GET_SYSUSER_TOKENS_REQUEST = typeof GET_SYSUSER_TOKENS_REQUEST;
export type GET_SYSUSER_TOKENS_SUCCESS = typeof GET_SYSUSER_TOKENS_SUCCESS;
export type GET_SYSUSER_TOKENS_FAILURE = typeof GET_SYSUSER_TOKENS_FAILURE;

//detach cards
export const DETACH_CARD_REQUEST = 'DETACH_CARD_REQUEST';
export const DETACH_CARD_SUCCESS = 'DETACH_CARD_SUCCESS';
export const DETACH_CARD_FAILURE = 'DETACH_CARD_FAILURE';

export type DETACH_CARD_REQUEST = typeof DETACH_CARD_REQUEST;
export type DETACH_CARD_SUCCESS = typeof DETACH_CARD_SUCCESS;
export type DETACH_CARD_FAILURE = typeof DETACH_CARD_FAILURE;

//add card flow
export type ADD_CARD_FLOW = typeof ADD_CARD_FLOW;
export const ADD_CARD_FLOW = 'ADD_CARD_FLOW';

//delete card flow
export type DELETE_CARD_FLOW = typeof DELETE_CARD_FLOW;
export const DELETE_CARD_FLOW = 'DELETE_CARD_FLOW';
// get user data
export type GET_USER_DATA = typeof GET_USER_DATA;
export const GET_USER_DATA = 'GET_USER_DATA';