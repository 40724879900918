// vendors
import React from "react";
import { IonButton, IonCol, IonRow, isPlatform } from "@ionic/react";
import { paymentConstants } from "../Payment/constants";
import { FOOTER_SAVE_BUTTON } from "../../constants/constant";
import ConsumerMobileFooter from "./ConsumerMobileFooter";
//constants
export interface ConsumerFooterProps {
    onCancel?: any;
    onClickSave?: () => void;
    isDisabled?: boolean;
}

const ConsumerFooter: React.FC<ConsumerFooterProps> = ({
    onCancel,
    onClickSave,
    isDisabled = false
}) => {
    const ismobile = isPlatform('mobile');
    return (
        <IonRow className={ismobile ? `consumer-mobile-footer ion-no-padding` : `form-footer-consumer  ion-no-padding`}>
            {ismobile ?
                <ConsumerMobileFooter
                    onCancel={onCancel}
                    onClickSave={onClickSave}
                /> :
                <IonCol size="12" className="payment-footer-col">
                    <IonButton
                        onClick={onClickSave}
                        disabled={isDisabled}
                        className="consumer-save-button"
                    >{FOOTER_SAVE_BUTTON}</IonButton>
                    <IonButton className="consumer-cancel-button" onClick={onCancel}>
                        {paymentConstants.CONFIRM_CANCEL_BUTTON}
                    </IonButton>
                </IonCol>}
        </IonRow>
    );
}
export default ConsumerFooter;






