import React from "react";
import {
    IonCol,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption,
} from "@ionic/react";

// constants
import { ClientPortal } from "../../../constants/constant";
import { ConsumerActionsDescriberData } from "../../../models/ConsumerActions";

export function titleDescriptor(): string {
    return ClientPortal.bulkAssignSubsidyGrp;
}
export function contentDescriptor(
    props: ConsumerActionsDescriberData
): JSX.Element {
    const { subsidyGrp, updateSubsidy, result,subsidyGrps } = props;
    console.log(subsidyGrps)
    return (
        <React.Fragment>
            <IonRow>
                <IonCol>
                    {result && !result.isValid && (
                        <span className="validation-error-label">*</span>
                    )}
                    <IonLabel className="input-headers">Subsidy Group</IonLabel>
                    <IonSelect
                        interface="popover"
                        placeholder="-Choose-"
                        value={subsidyGrp}
                        onIonChange={(e) => updateSubsidy(e.target.value!)}
                        className="sort-dropdown self-products-select consumer-actions-select"
                        disabled={!subsidyGrps || !subsidyGrps.length}
                    >
                        
                        {subsidyGrps && subsidyGrps.length > 0 && subsidyGrps.map((item: Record<string,any>) => (
                            <IonSelectOption key={item.subsidyGroupId} value={item.subsidyGroupId}>
                                {item.name}
                            </IonSelectOption>
                        ))}
                    </IonSelect>
                    {result && !result.isValid && (
                        <span className="global-red">
                            {result && result.errorMessage}
                        </span>
                    )}
                </IonCol>
            </IonRow>
        </React.Fragment>
    );
}
export default {
    titleDescriptor,
    contentDescriptor,
};
