
import { all, fork } from "redux-saga/effects";

import productsSaga from "./Products";
import MarketProfileSaga from './Profile'
import ProductsSettingsPanelSaga from './ProductSettingsPanel'
import LocationsSaga from "./Locations";
import UserManagementSaga from "./UserManagement";
import SystemUsersSaga from "./SystemUsers";
import ResetPasswordSaga from './ResetPassword'
import EmailUpdateSaga from './EmailUpdate'
import OrderSaga from './Order';
import ReportsSaga from './Report';
import PosSaga from './POS';
import PaymentSaga from "./Payment";
import ConsumerSaga from "./Consumer";

export function* rootSaga() {
    yield all([
        fork(LocationsSaga),
        fork(productsSaga),
        fork(MarketProfileSaga),
        fork(ProductsSettingsPanelSaga),
        fork(UserManagementSaga),
        fork(SystemUsersSaga),
        fork(ResetPasswordSaga),
        fork(EmailUpdateSaga),
        fork(OrderSaga),
        fork(ReportsSaga),
        fork(PosSaga),
        fork(PaymentSaga),
        fork(ConsumerSaga)
    ])

}
