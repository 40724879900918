import React from 'react';

import {
  IonImg,
} from "@ionic/react";


import { LoginDescriberData } from '../../../models/UserProfile';

export function imageDescriptor(props:LoginDescriberData): JSX.Element {
  return <IonImg src="/assets/Client365.svg" onClick={props.checkLogoClick}/>;
}
export function routeDescriptor(props:LoginDescriberData): void {
  props.redirectToConsumer();
}
export default {
  imageDescriptor,
  routeDescriptor
};
