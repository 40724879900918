//actions
import {
    FetchLocationsRequest,
    FetchLocationsSuccess,
    FetchLocationsFailure,
    FetchLocationsBySysUserIdRequest,
    FetchLocationsBySysUserIdFailure,
    FetchLocationsBySysUserIdSuccess
} from '../actions/locationsActions';
export type locationsTypes = FetchLocationsBySysUserIdRequest | FetchLocationsBySysUserIdSuccess | FetchLocationsRequest;

export interface InterfaceLocations {
    [key: string]: any;
    pending: boolean;
    locations: Array<any>;
    errors?: Record<string, any> | null;
    
}
const initialState = {
    pending: true,
    errors: null,
    locations: [],
};

const strategies = {
    FETCH_LOCATIONS_REQUEST: fetchLocations,
    FETCH_LOCATIONS_SUCCESS: fetchLocationsSuccess,
    FETCH_LOCATIONS_FAILURE: fetchLocationsFailure,
    FETCH_LOCATIONS_BY_SYSUSERID_REQUEST: fetchLocationsBySystemUserId,
    FETCH_LOCATIONS_BY_SYSUSERID_SUCCESS: fetchLocationsBySystemUserIdSuccess,
    FETCH_LOCATIONS_BY_SYSUSERID_FAILURE: fetchLocationsBySystemUserIdFailure,
    default: (state: InterfaceLocations) => state,
};

function fetchLocations(state: InterfaceLocations) {
    return { ...state, errors: null };
}

function fetchLocationsSuccess(state: InterfaceLocations, action: FetchLocationsSuccess) {
    const { locations } = action;
    return { ...state, locations: [...locations], errors: null, pending: false };
}

function fetchLocationsFailure(state: InterfaceLocations, action: FetchLocationsFailure) {
    return { ...state, pending: false, errors: { ...action.error } };
}


function fetchLocationsBySystemUserId(state: InterfaceLocations) {
    return { ...state, errors: null };
}

function fetchLocationsBySystemUserIdSuccess(state: InterfaceLocations, action: FetchLocationsBySysUserIdSuccess) {
    const {systemUserId, locations} = action;
    return { ...state, [systemUserId]:[...locations], errors: null, pending: false };
}

function fetchLocationsBySystemUserIdFailure(state: InterfaceLocations, action: FetchLocationsBySysUserIdFailure) {
    return { ...state, pending: false, errors: { ...action.error } };
}

export default (state: InterfaceLocations = initialState, action: locationsTypes): InterfaceLocations => {
    return (strategies[action.type] || strategies.default)(state, action as never);
};