// vendors
import React, { useState } from "react";
import {
    IonCol,
    IonRow,
    IonTitle,
    IonSelect,
    IonSelectOption,
} from "@ionic/react";
import { useHistory } from "react-router";

import envChange from "../../utils/changeEnvironment";
import { Routes } from "../../constants/Routes";
interface Environment {
    id: number;
    name: string;
    value: string;
}

const environments: Environment[] = [
    {
        id: 1,
        name: "Local",
        value: "local",
    },
    {
        id: 2,
        name: "Test3",
        value: "test3",
    },
    {
        id: 3,
        name: "Test4",
        value: "test4",
    },
    {
        id: 4,
        name: "QA / Staging",
        value: "staging",
    },
    {
        id: 5,
        name: "Production",
        value: "production",
    },
];
export const ChangeEnvironment: React.FC<any> = () => {
    const [selectedEnvironment, setSelectedEnvironment] = useState(JSON.parse(localStorage.getItem('env') as string) || "test3");
    const history = useHistory();

    const changeEnvironment = (event: any) => {
        setSelectedEnvironment(event.target.value);
        envChange(event.target.value);
        localStorage.setItem('env', JSON.stringify(event.target.value));
        setTimeout(()=>{history.push(Routes.login.url); window.location.reload();},2000);
    };

    return (
        <IonRow>
            <IonCol size="4"></IonCol>
            <IonCol className="center-content">
                <IonTitle>Change Environment</IonTitle>
                <br />
                <IonCol>
                    <IonSelect
                        placeholder="Select Environment"
                        onIonChange={(ev) => changeEnvironment(ev)}
                        onIonDismiss={(ev) => changeEnvironment(ev)}
                        value={selectedEnvironment}
                    >
                        {environments.map((item: Environment) => (
                            <IonSelectOption key={item.id} value={item.value}>
                                {item.name}
                            </IonSelectOption>
                        ))}
                    </IonSelect>
                </IonCol>
            </IonCol>
            <IonCol size="4"></IonCol>
        </IonRow>
    );
};
export default ChangeEnvironment;
