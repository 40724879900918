//vendors
import React, { useState, useEffect } from "react";
import { IonCol, IonLabel, IonItem, IonInput, IonRow, isPlatform } from "@ionic/react"
//modals
import type { SignupProps } from "../../models/SignupProps";
// utils
import { isMarketNameValid } from "../../utils/index";
//constant
import { MARKET_NAME_REQ_ERR, MARKET_NAME_HEADER } from '../../constants/constant';

const LocationInfo: React.FC<SignupProps> = ({ marketName, setOnboarding }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const ismobile = isPlatform('mobile');
  const istablet = isPlatform('tablet');

  const onChangeHandler = (e: any) => {
    const marketName = e.detail.value;
    const { errorMessage } = isMarketNameValid(marketName);
    setErrorMessage(errorMessage);
    setOnboarding((prevState: any) => ({ ...prevState, marketName: e.detail.value }));
  }

  // to show error message on load 
  // if API data has invalid data
  useEffect(() => {
    const { errorMessage } = isMarketNameValid(marketName);
    setErrorMessage(errorMessage);
  }, []);

  return (
    <IonRow className={`verticle-center`} style={{ marginTop: (ismobile || istablet) ? '25%' : '' }}>
      <IonCol className="ion-padding-start">
        <IonLabel>{MARKET_NAME_HEADER}</IonLabel>
        <IonItem className={(ismobile || istablet) ? 'onboarding-market-name-mobile' : 'onboarding-market-name'}>
          <IonInput
            value={marketName}
            onIonChange={onChangeHandler}
            aria-label={MARKET_NAME_HEADER}
          />
        </IonItem>
        {errorMessage && <span className="validation-error-label">{MARKET_NAME_REQ_ERR}</span>}
      </IonCol>
    </IonRow>
  )
}
export default LocationInfo;