import {
    FetchProfileRequest,
    FetchProfileSuccess,
    FetchProfileFailure,
    FetchProfilesRequestWithSysUserId,
    FetchProfilesSuccessWithSysUserId,
    FetchProfilesFailureWithSysUserId,
    PatchProfile,
    PatchProfileSuccess,
    PatchProfileFailure,
    PostProfile,
    PostProfileSuccess,
    PostProfileFailure,
    MarketProfileAdditionalDetails,
    PaymentInfo,
    MarketTheme
} from '../../models/MarketProfile';
import { ResetProfileUpdation } from "./../actions/profileActions";
export type profileTypes =
    FetchProfileRequest |
    FetchProfileSuccess |
    FetchProfileFailure |
    FetchProfilesRequestWithSysUserId |
    FetchProfilesSuccessWithSysUserId |
    FetchProfilesFailureWithSysUserId |
    PatchProfile |
    PatchProfileSuccess |
    PatchProfileFailure |
    PostProfile |
    PostProfileSuccess |
    PostProfileFailure |
    ResetProfileUpdation;

export interface ICurrentProfile {
    pending?: boolean;
    marketProfileId: string;
    locationId: string;
    street: string;
    city: string;
    state: string;
    zip: any;
    marketName: string;
    isFullServiceRestaurant: boolean;
    isQuickServiceRestaurant: boolean;
    isRoomServiceAvailable: boolean;
    isSelfOperated:boolean;
    doesBarOrRestaurantOfferAlcohol: boolean;
    isCoffeeShopBar: boolean;
    hasPreferredBeverageCompany: boolean;
    preferredBeverageCompany: any;
    marketSizeInSqFt: number;
    additionalDetails: MarketProfileAdditionalDetails;
    paymentInfo: PaymentInfo;
    marketTheme: MarketTheme;
    dateCreated: any;
    lastUpdated: any;
    onboardingStep: number,
    errors?: Record<string, any> | null;
}
export interface IAllProfiles {
    pending?: boolean;
    errors?: Record<string, any> | null;
    profiles: Array<any>;
}
export interface InterfaceProfile {
    currentProfile: ICurrentProfile;
    allProfiles: IAllProfiles;
}
const initialProfileState = {
    pending: true,
    marketProfileId: '',
    locationId: '',
    street: '',
    city: '',
    state: '',
    zip: null,
    marketName: '',
    isFullServiceRestaurant: false,
    isQuickServiceRestaurant: false,
    isRoomServiceAvailable: false,
    doesBarOrRestaurantOfferAlcohol: false,
    isCoffeeShopBar: false,
    isSelfOperated:false,
    hasPreferredBeverageCompany: false,
    preferredBeverageCompany: null,
    marketSizeInSqFt: 0,
    additionalDetails: {} as MarketProfileAdditionalDetails,
    paymentInfo: {} as PaymentInfo,
    marketTheme: {} as MarketTheme,
    dateCreated: null,
    lastUpdated: null,
    onboardingStep: 0,
    errors: null,
}
export const initialState = {
    currentProfile: { ...initialProfileState },
    isProfileUpdationSuccess: false,
    allProfiles: {
        pending: true,
        errors: null,
        profiles: []
    },
};

const strategies = {
    FETCH_PROFILE_REQUEST: fetchProfile,
    FETCH_PROFILE_SUCCESS: fetchProfileSuccess,
    FETCH_PROFILE_FAILURE: fetchProfileFailure,
    FETCH_PROFILES_REQUEST_WITH_SYS_USER_ID: fetchProfilesWithSysUserId,
    FETCH_PROFILES_SUCCESS_WITH_SYS_USER_ID: fetchProfilesSuccessSysUserId,
    FETCH_PROFILES_FAILURE_WITH_SYS_USER_ID: fetchProfilesFailureSysUserId,
    PATCH_PROFILE: patchProfileRequest,
    PATCH_PROFILE_SUCCESS: patchProfileSuccess,
    PATCH_PROFILE_FAILURE: patchProfileFailure,
    POST_PROFILE: postProfileRequest,
    POST_PROFILE_SUCCESS: postProfileSuccess,
    POST_PROFILE_FAILURE: postProfileFailure,
    RESET_PROFILE_UPDATION: resetProfileUpdation,
    default: (state: InterfaceProfile) => state,
};

function fetchProfile(state: InterfaceProfile) {
    const currentProfile = { ...initialState.currentProfile };
    return { ...state, currentProfile };
}

function fetchProfileSuccess(state: InterfaceProfile, action: FetchProfileSuccess) {
    const currentProfile = { ...action.data, errors: null, pending: false };
    return { ...state, currentProfile };
}

function fetchProfileFailure(state: InterfaceProfile, action: FetchProfileFailure) {
    const currentProfile = { pending: false, errors: { ...action.error } };
    return { ...state, currentProfile };
}

function fetchProfilesWithSysUserId(state: InterfaceProfile) {
    const allProfiles = { ...state.allProfiles };
    return { ...state, allProfiles };
}

function fetchProfilesSuccessSysUserId(state: InterfaceProfile, action: FetchProfilesSuccessWithSysUserId) {
    const { profiles } = action;
    const allProfiles = { profiles: [...profiles], errors: null, pending: false };
    return { ...state, allProfiles };
}

function fetchProfilesFailureSysUserId(state: InterfaceProfile, action: FetchProfilesFailureWithSysUserId) {
    const allProfiles = { pending: false, errors: { ...action.error } };
    return { ...state, allProfiles };
}

function patchProfileRequest(state: InterfaceProfile) {
    const currentProfile = { ...state.currentProfile, errors: null, pending: true }
    return { ...state, currentProfile, isProfileUpdationSuccess: false };
}

function patchProfileSuccess(state: InterfaceProfile, action: PatchProfileSuccess) {
    const currentProfile = { ...state.currentProfile, ...action.response, errors: null, pending: false }
    return { ...state, currentProfile, isProfileUpdationSuccess: true }
}

function patchProfileFailure(state: InterfaceProfile, action: PatchProfileFailure) {
    const currentProfile = { ...state.currentProfile, errors: action.error, pending: false }
    return { ...state, currentProfile }
}

function postProfileRequest(state: InterfaceProfile) {
    const currentProfile = { ...state.currentProfile, errors: null, pending: true };
    return { ...state, currentProfile }
}

function postProfileSuccess(state: InterfaceProfile, action: PostProfileSuccess) {
    const currentProfile = { errors: null, pending: false, ...action.response };
    return { ...state, currentProfile }
}

function postProfileFailure(state: InterfaceProfile, action: PostProfileFailure) {
    const currentProfile = { ...state.currentProfile, errors: action.error, pending: false }
    return { ...state, currentProfile }
}

function resetProfileUpdation(state: InterfaceProfile, action: ResetProfileUpdation) {
    return { ...state, isProfileUpdationSuccess: false };
}

export default (state: InterfaceProfile = initialState, action: profileTypes): Record<string, any> => {
    return (strategies[action.type] || strategies.default)(state, action as never);
};