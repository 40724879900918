export type PaymentProps = { size: number; };
export type CreditCardImageProps = { issuer: string; size?: number };

export const paymentTypes = {
    "visa": "visa",
    "american-express": "american-express",
    "american express": "american express",
    "amex": "amex",
    "discover": "discover",
    "master-card": "master-card",
    "mastercard": "mastercard",
    "apple-pay": "apple-pay",
    "google-pay": "google-pay",
    "payroll": "payroll"
}
export default PaymentProps;