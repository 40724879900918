import { all, put, takeLatest, select, call } from "redux-saga/effects";
import { doGet, doPost, doPatch, doDelete } from "../../../utils/fetchWrapper";
import {
    FetchMarketProductsSuccess,
    FetchMarketProductsFailure,
    FetchGlobalProductsSuccess,
    FetchGlobalProductsFailure,
    saveMarketProductSuccess,
    saveMarketProductFailure,
    FetchProductDetailsSuccess,
    FetchProductDetailsFailure,
    PatchMarketProductSuccess,
    PatchMarketProductFailure,
    deleteMarketProductFailure,
    deleteMarketProductSuccess,
    SaveSwapProductSuccess,
    SaveSwapProductFailure,
    FetchGlobalProductsCategoriesSuccess,
    FetchGlobalProductsCategoriesFailure,
    postPublishProductSuccess,
    postPublishProductFailure,
    fetchMarketProductsForReportSuccess,
    fetchMarketProductsForReportFailure,
    saveSelfOperatedProductFailure,
    saveSelfOperatedProductSuccess
} from "../../actions/productActions";
import * as PRODUCT from "../../ActionTypes/productTypes";
import { Routes } from "../../../constants/Routes";
import {LocationExtendedProductRequest} from '../../../models/LocationsModal'

interface PostGlobalProduct {
    locationId: string;
    orgId: string;
    productId: string;
}

function* fetchGlobalProducts(action: any) {
    const { offset, name, category } = action;
    const { currentProfile } = yield select((state) => { return state.profile });
    const { orgId, locationId } = currentProfile;
    let url = `${Routes.dashboard.fetchGlobalProductsApiUrl}${orgId}`;
    if (offset) {
        url += `&offset=${offset}`;
    }
    if (name) {
        url += `&name=${name}`;
    }
    if (category && category.length > 0) {
        url += `&categories=${category}`;
    }
    try {
        // @ts-ignore
        const response = yield doGet(url);
        yield put(
            FetchGlobalProductsSuccess({
                products: response,
                searchName: name,
                category: category,
            })
        );
    } catch (e: any) {
        yield put(
            FetchGlobalProductsFailure(e)
        );
    }
}

function* fetchMarketProducts(action: any) {
    const { offset } = action;
    try {
        // @ts-ignore
        const { currentProfile } = yield select((state) => { return state.profile });
        const { locationId } = currentProfile;
        let url = `${Routes.dashboard.fetchMarketProductsAPiUrl}${locationId}`;
        if (offset) {
            url += `&offset=${offset}`;
        }
        // @ts-ignore
        const response = yield doGet(url);
        yield put(
            FetchMarketProductsSuccess({
                products: response,
            })
        );
    } catch (e: any) {
        yield put(
            FetchMarketProductsFailure(e)
        );
    }
}

function* submitAddProduct(request: PostGlobalProduct) {
    try {
        // @ts-ignore
        const response = yield doPost(
            Routes.dashboard.submitGlobalProductApiUrl,
            request
        );
        yield put(saveMarketProductSuccess(response));
    } catch (error: any) {
        yield put(saveMarketProductFailure(error));
    }
}

function* submitAddProducts(request: Record<string, any>) {
    yield all(
        request.info.map((file: PostGlobalProduct) => call(submitAddProduct, file))
    );
}

function* fetchProductDetail(action: any) {
    const { productLocationId } = action;
    // @ts-ignore
    let url = `${Routes.dashboard.fetchMarketProductDetailApiUrl}${productLocationId}`;
    try {
        // @ts-ignore
        const response = yield doGet(url);
        yield put(FetchProductDetailsSuccess(response));
    } catch (e: any) {
        yield put(
            FetchProductDetailsFailure(e)
        );
    }
}

function* patchMarketProduct(info: any) {
    const { productLocationId } = info;
    delete info.productLocationId;
    try {
        // @ts-ignore
        const response = yield doPatch(
            Routes.dashboard.patchMarketProductApiUrl + productLocationId,
            info
        );
        yield put(PatchMarketProductSuccess(response));
    } catch (error: any) {
        yield put(PatchMarketProductFailure(error));
    }
}

function* patchMarketProducts(request: any) {
    yield all(request.info.map((file: any) => call(patchMarketProduct, file)));
}

function* removeMarketProduct(action: any) {
    const { info } = action;
    // @ts-ignore
    let url = `${Routes.dashboard.deleteMarketProductApiUrl}${info}`;
    try {
        // @ts-ignore
        const response = yield doDelete(url);
        yield put(deleteMarketProductSuccess(response));
    } catch (e: any) {
        yield put(deleteMarketProductFailure(e));
    }
}

function* submitSwapProduct(request: any) {
    try {
        // @ts-ignore
        const response = yield doPost(
            Routes.dashboard.productActionsApiUrl + '/swap',
            request.info
        );
        yield put(SaveSwapProductSuccess(response));
    } catch (error: any) {
        yield put(SaveSwapProductFailure(error));
    }
}

function* fetchGlobalProductCategory(action: any) {
    const { offset } = action;
    try {
        const { currentProfile } = yield select((state) => state.profile);
        const { orgId } = currentProfile;
        let url = `${Routes.dashboard.globalProductCategoriesUrl}${orgId}`;
        if (offset) {
            url += `&offset=${offset}`;
        }
        // @ts-ignore
        const response = yield doGet(url);
        yield put(FetchGlobalProductsCategoriesSuccess(response));
    } catch (e: any) {
        yield put(
            FetchGlobalProductsCategoriesFailure(e)
        );
    }
}

function* postPublishProduct(request: any) {
    try {
        // @ts-ignore
        const response = yield doPost(
            Routes.systemUserActions.compilePriceActions + request.info.locationId + '/compile-prices', null
        );
        yield put(postPublishProductSuccess(response));
    } catch (error: any) {
        yield put(postPublishProductFailure(error));
    }
}
function* fetchMarketProductsReport(action: any) {
    try {
        // @ts-ignore
        const { currentProfile } = yield select((state) => { return state.profile });
        const { locationId } = currentProfile;
        const records: any = [];
        let offset = 0;
        do {
            let url = `${Routes.dashboard.fetchMarketProductsAPiUrl}${locationId}&offset=${offset}`;
            // @ts-ignore
            const response = yield doGet(url);
            const { metadata = { pagination: {} }, items } = response;
            records.push( ...items);
            const { pagination } = metadata;
            offset = pagination.nextOffset;
        } while (offset != null);

        yield put(fetchMarketProductsForReportSuccess(records));
    } catch (e: any) {
        yield put(
            fetchMarketProductsForReportFailure(e)
        );
    }
}

function* submitSelfOperatedProduct(request: LocationExtendedProductRequest) {
    try {
        // @ts-ignore
        const response = yield doPost(
            Routes.dashboard.submitExtendedProductApiUrl,
            request
        );
        yield put(saveSelfOperatedProductSuccess(response));
    } catch (error: any) {
        yield put(saveSelfOperatedProductFailure(error));
    }
}

function* submitSelfOperatedProducts(request: Record<string, any>) {
    yield all(
        request.info.map((file: LocationExtendedProductRequest) => call(submitSelfOperatedProduct, file))
    );
}

function* ProductsSaga() {
    yield all([
        takeLatest(PRODUCT.FETCH_MARKET_PRODUCTS_REQUEST, fetchMarketProducts),
        takeLatest(PRODUCT.FETCH_GLOBAL_PRODUCTS_REQUEST, fetchGlobalProducts),
        takeLatest(PRODUCT.SAVE_MARKET_PRODUCT_REQUEST, submitAddProducts),
        takeLatest(PRODUCT.FETCH_PRODUCT_DETAILS_REQUEST, fetchProductDetail),
        takeLatest(PRODUCT.PATCH_MARKET_PRODUCT_REQUEST, patchMarketProducts),
        takeLatest(PRODUCT.DELETE_MARKET_PRODUCT, removeMarketProduct),
        takeLatest(PRODUCT.SAVE_SWAP_PRODUCT_REQUEST, submitSwapProduct),
        takeLatest(
            PRODUCT.FETCH_GLOBAL_PRODUCTS_CATEGORIES_REQUEST,
            fetchGlobalProductCategory
        ),
        takeLatest(
            PRODUCT.POST_PUBLISH_PRODUCT_REQUEST,
            postPublishProduct
        ),
        takeLatest(PRODUCT.FETCH_MARKETPRODUCTS_REPORT_REQUEST, fetchMarketProductsReport),
        takeLatest(PRODUCT.POST_SELF_OPERATED_PRODUCT_REQUEST, submitSelfOperatedProducts),
    ]);
}

export default ProductsSaga;
