import HpDescriptor from './Header-hp';
import CpDescriptor from './Header-cp';
import { PortalType } from '../../../constants/constant';
import { ImageDescriberInterface, HeaderMarketLabels } from '../../../models/UserProfile';

export type GetDescriberType = {
    imageDescriptor: (props: ImageDescriberInterface) => React.ReactNode;
    headerMarketLabels:(props: HeaderMarketLabels) => React.ReactNode;
};

const typeDescriber = {
    [PortalType.HP]: HpDescriptor,
    [PortalType.CP]: CpDescriptor,
};

export function getDescriber(model: string): GetDescriberType {
    return typeDescriber[model as keyof typeof typeDescriber];
}
