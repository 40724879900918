//vendros
import React from "react";
import {
    IonCol,
    IonRow,
    IonIcon,
    IonText,
    IonButton,
    IonItem
} from "@ionic/react";
import { chevronBackCircleOutline, chevronForwardCircleOutline } from 'ionicons/icons';
//constants
import { BACK_TO_REPORT_LIST } from "../../constants/constant";

const ReportHeaderBarForMobile: React.FC<any> = ({ setShowSidePanel, showSidePanel, selectedReport, backToReportsList }) => {
    return (
        <>
            <IonRow className='mobile-text-allign-center'>
                <IonCol size="12" >
                    <IonItem lines="none" >
                        <IonIcon
                            className="report-side-nav-icon click-cursor"
                            onClick={() => setShowSidePanel((prevState: any) => !prevState)}
                            icon={showSidePanel ? chevronBackCircleOutline : chevronForwardCircleOutline}
                        />
                        <IonText className={"mobile-reports-header-text"}>{selectedReport.name || 'Reports'}</IonText>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className={"mobile-back-to-reports-btn"}>
                <IonCol size="12">
                    {selectedReport.name &&
                        <IonButton fill="clear" onClick={backToReportsList}>
                            {BACK_TO_REPORT_LIST}
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
        </>
    )
}
export default ReportHeaderBarForMobile