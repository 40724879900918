import React, { memo, useCallback, useState, useEffect, useMemo } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import {
    IonButton,
    IonContent,
    IonFooter,
    IonHeader,
    IonModal,
    IonText,
    IonRow,
    IonCol,
    IonImg,
    IonLabel,
    IonTitle,
    IonItem,
    IonInput,
    IonRadio,
    IonSelect,
    IonSelectOption,
    IonList,
    IonRadioGroup,
    IonIcon,
    isPlatform
} from "@ionic/react";
import CurrencyInput from "react-currency-input-field";

import {
    FetchGlobalProducts,
    FetchProductDetails,
    PatchMarketProduct,
    deleteMarketProduct,
    SaveSwapProduct,
    resetMarketProducts,
    updateProductCategoryFilters,
    setShowPublishProduct,
    resetGlobalProducts
} from "../../../_redux/actions/productActions";
import { resetProductsSettingsPanel } from "../../../_redux/actions/productSettingsPanelActions";
import { RootState } from "../../../_redux/reducers/rootReducer";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Header from "../ManageColumns/Header";
import SwapProduct from "../SwapProduct/Index";
import ToastrHook from "../../../components/common/toastr/Toastr";
import {
    Hotel365Portal,
    PRODUCT_PRICING,
    DISCONTINUED_SWAP,
    SWAP_NEW_PRODUCT,
    SWAPPED_OUT,
    DELETE,
    PRODUCT_DETAILS_SWAPPED_IN,
    SWAPPED_MSG,
    PRODUCT_GLOBAL_MARGINS,
    PRODUCT_CATEGORY_MARGINS,
    PRODUCT_SPECIFIC_MARGIN,
    PRODUCT_SWAP_SPECIFIC_PRICE,
    PRODUCT_SELLING_PRICE,
    PRODUCT_MARKET_QUANTITIES,
    PRODUCT_BELOW_MIN,
    PRODUCT_BELOW_MAX,
    FOOTER_CANCEL_BUTTON,
    FOOTER_SAVE_BUTTON,
    PRODUCT_REFILL_ACTION,
    RESET,
    ORDER_PRODUCT_REMOVED_SUCCESS,
    PRODUCT_PLACEHOLDER_PRICE,
    PRODUCT_PLACEHOLDER_MARGIN,
    GLOBAL_NO_CHANGES_TO_SAVE
} from "../../../constants/constant";
import { star } from "ionicons/icons";
import envChange from '../../../../src/utils/changeEnvironment';
import { fetchEnviorment, getLocalizedNumericValues } from "../../../utils";
import getSymbolFromCurrencyCode from "../../../i18n/currency-symbol-map/index";

const pricingSchema = yup.object().shape({
    specMargin: yup
        .string()
        .nullable(true)
        .test("match with regex", "not matches", (value) => {
            if (value) {
                const margin = value?.replace("%", "");
                return Hotel365Portal.marginRegEx.test(margin);
            }
            return true;
        }),
    specificPrice: yup
        .string()
        .nullable(true)
        .test("match with regex", "not matches", (value) => {
            if (value) {
                const margin = value?.replace("$", "");
                return Hotel365Portal.priceRegEX.test(margin);
            }
            return true;
        }),
});
const currencyOptionsObject = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

export const ProductDetails: React.FC<any> = memo((props) => {
    const envConfig = envChange();
    const getEnv = fetchEnviorment();
    const imageBaseUrlWithProtocol: string = (envConfig as any)[getEnv].ImageServerBaseUrl;
    const dispatch = useDispatch();
    const { selectedProduct, showDetailModal } = props;
    const [showModal, setShowModal] = useState(showDetailModal);
    const [refillAction, setRefillAction] = useState("BELOW_MAXIMUM");
    const [orderLeftMin, setOrderLeftMin] = useState(0);
    const [orderRightMax, setOrderRightMax] = useState(0);
    const [specMargin, setSpecMargin] = useState<string | number | undefined>(0);
    const [specificPrice, setSpecificPrice] = useState<string | number | undefined>(0);
    const [selected, setSelected] = useState("");
    const [buttonState, setButtonState] = useState(false);
    const [showCatgeoryMargin, setShowCatgeoryMargin] = useState({ isEnable: false, margin: "0%" });
    const [showGlobalMargin, setShowGlobalMargin] = useState({ isEnable: false, margin: "0%" });
    const [cost, setCost] = useState<string>("");
    const [price, setPrice] = useState<string | number>(0);
    const [name, setName] = useState<string>("");
    const [category, setCategory] = useState<string>("");
    const [scanCode, setScanCode] = useState<string>("");
    const [image, setImage] = useState<string>("");
    const [swappedproduct, setSwappedProduct] = useState<Record<string, unknown>>({});
    const [renderToastr] = ToastrHook();
    const isMobileView = isPlatform("mobile");
    const {
        marketProductDetail,
        patchedMarketProduct,
        removedMarketProduct,
        saveSwappedProduct,
        globalProducts,
    } = useSelector((state: RootState) => state.product, shallowEqual);
    const { categoryMargin, globalMargin } = useSelector((state: RootState) => state.productSettingsPanel, shallowEqual);
    const { smallImage } = marketProductDetail;

    const fetchImage = () => {
        if (smallImage && smallImage.length > 0) {
            return `${imageBaseUrlWithProtocol}/${smallImage}`;
        } else {
            return Hotel365Portal.defaultProductImage;
        }
    }
    const profile = useSelector((state: RootState) => state.profile, shallowEqual);
    const { currentProfile } = profile;
    const { currency } = currentProfile;
    const { register, setValue, formState: { errors }, } = useForm({ mode: "onBlur", resolver: yupResolver(pricingSchema) });

    useEffect(() => {
        dispatch(resetProductsSettingsPanel());
        dispatch(FetchProductDetails(selectedProduct?.productLocationId));
    }, [selectedProduct.productLocationId]);

    useEffect(() => {
        setShowModal(showDetailModal);
        if (
            showDetailModal &&
            marketProductDetail &&
            Object.keys(marketProductDetail).length > 0
        ) {
            const cat = marketProductDetail.category1 || marketProductDetail.category2 || marketProductDetail.category3
            setShowModal(showDetailModal);
            setOrderLeftMin(marketProductDetail.minStock);
            setOrderRightMax(marketProductDetail.maxStock);
            setName(marketProductDetail.name);
            setCategory(cat as string);
            setScanCode(marketProductDetail.scancode);
            setImage(marketProductDetail.largeImage as string);
            setSelected(marketProductDetail.pricingOption);
            setCost(marketProductDetail.price as string);
            setPrice(marketProductDetail.calculatedPrice);
            setSpecMargin(marketProductDetail.specificMargin as string);
            setSpecificPrice(marketProductDetail.calculatedPrice);
            setRefillAction(marketProductDetail.refillAction as string);
            const pickCategory = categoryMargin.filter(
                (item) =>
                    item.category.toLowerCase() === cat?.toLowerCase() &&
                    item.categoryMarginId
            );
            if (globalMargin.doesAllowCategoryLevelMargin && pickCategory.length > 0) {
                setShowCatgeoryMargin({
                    isEnable:
                        pickCategory[0].margin &&
                            pickCategory[0].margin !== "0%"
                            ? true
                            : false,
                    margin: pickCategory[0].margin as string,
                });
            } else {
                setShowCatgeoryMargin({
                    isEnable: false,
                    margin: "0%",
                });
            }
            if (globalMargin.margin !== 0) {
                setShowGlobalMargin({
                    isEnable:
                        globalMargin.margin &&
                            globalMargin.margin !== 0
                            ? true
                            : false,
                    margin: globalMargin.margin + '%',
                });
            } else {
                setShowGlobalMargin({
                    isEnable: false,
                    margin: "0%",
                });
            }
        }
        if (patchedMarketProduct && Object.keys(patchedMarketProduct).length > 0) {
            setShowModal(false);
            setButtonState(false);
        }
        if (removedMarketProduct && Object.keys(removedMarketProduct).length > 0) {
            renderToastr(ORDER_PRODUCT_REMOVED_SUCCESS, "success");
            setShowModal(false);
            setButtonState(false);
        }
    }, [
        setShowModal,
        showDetailModal,
        marketProductDetail,
        patchedMarketProduct,
        setSelected,
        removedMarketProduct,
        saveSwappedProduct,
    ]);

    const recalculateSellingPrice = () => {
        if (["SPECIFIC_PRICE"].indexOf(selected) > -1) {
            specificPrice ? setPrice(getLocalizedNumericValues(specificPrice, "currency", currency, currencyOptionsObject)) : setPrice(getLocalizedNumericValues("0", "currency", currency, currencyOptionsObject));
            setSelected("SPECIFIC_PRICE");
        } else if (globalMargin.doesAllowProductLevelMargin && ["SPECIFIC_PRICE"].indexOf(selected) === -1) {
            const _price = specMargin ? ((Number(cost) + (Number(cost) * Number(specMargin) / 100))) : null;
            _price ? setPrice(getLocalizedNumericValues(_price, "currency", currency, currencyOptionsObject)) : setPrice(getLocalizedNumericValues("0", "currency", currency, currencyOptionsObject));
            setSelected("SPECIFIC_MARGIN");
        } else if (
            (globalMargin.margin &&
                showCatgeoryMargin.isEnable &&
                !globalMargin.doesAllowProductLevelMargin &&
                ["SPECIFIC_PRICE"].indexOf(selected) === -1) ||
            (globalMargin.margin == 0 &&
                showCatgeoryMargin.isEnable &&
                !globalMargin.doesAllowProductLevelMargin &&
                ["SPECIFIC_PRICE"].indexOf(selected) === -1)
        ) {
            const _price = ((Number(cost) + (Number(cost) * Number(showCatgeoryMargin.margin) / 100)));
            _price ? setPrice(getLocalizedNumericValues(_price, "currency", currency, currencyOptionsObject)) : setPrice(getLocalizedNumericValues("0", "currency", currency, currencyOptionsObject));
            setSelected("CATEGORY_MARGIN");
        } else {
            const _price = ((Number(cost) + (Number(cost) * Number(globalMargin.margin) / 100)));
            _price ? setPrice(getLocalizedNumericValues(_price, "currency", currency, currencyOptionsObject)) : setPrice(getLocalizedNumericValues("0", "currency", currency, currencyOptionsObject));
            setSelected("GLOBAL_MARGIN");
        }
    };

    useEffect(() => {
        if (
            (marketProductDetail &&
                Object.keys(marketProductDetail).length > 0) ||
            selected
        ) {
            recalculateSellingPrice();
        }
    }, [
        showCatgeoryMargin,
        globalMargin,
        cost,
        selected,
        specMargin,
        specificPrice,
    ]);

    useEffect(() => {
        if (saveSwappedProduct && Object.keys(saveSwappedProduct).length > 0)
            onCloseModal();
    }, [saveSwappedProduct]);

    const onCloseModal = () => {
        setShowModal(false);
        setButtonState(false);
        setSwappedProduct({});
        props.closeModal();
    };

    const prepareReqObject = useMemo(() => {
        let request = {
            maxStock: orderRightMax,
            minStock: orderLeftMin,
            productLocationId: selectedProduct?.productLocationId,
            pricingOption: selected,
            refillAction: refillAction,
        } as any;

        if (selected === "SPECIFIC_MARGIN") {
            request = {
                ...request,
                specificMargin: Number(specMargin),
            };
        } else if (selected === "SPECIFIC_PRICE") {
            request = {
                ...request,
                specificPrice: Number(specificPrice),
            };
        }
        return request;
    }, [
        price,
        specMargin,
        selectedProduct,
        selected,
        orderRightMax,
        orderLeftMin,
        refillAction,
    ]);
    const prepareSwapReqObject = useMemo(() => {
        let request = {
            inProduct: {
                maxStock: orderRightMax,
                minStock: orderLeftMin,
                pricingOption: selected,
                refillAction: refillAction,
                locationId: marketProductDetail.locationId,
                orgId: marketProductDetail.orgId,
                productId: swappedproduct.productId,
            },
            outProductLocationId: marketProductDetail.productLocationId,
        } as any;

        if (selected === "SPECIFIC_MARGIN") {
            request["inProduct"]["specificMargin"] = Number(specMargin);
        } else if (selected === "SPECIFIC_PRICE") {
            request["inProduct"]["specificPrice"] = Number(specificPrice);
        }
        return request;
    }, [
        price,
        specMargin,
        selectedProduct,
        selected,
        orderRightMax,
        orderLeftMin,
        refillAction,
        swappedproduct,
    ]);
    const checkNoChanges = () => {
        if ((marketProductDetail.maxStock !== orderRightMax) || (marketProductDetail.minStock !== orderLeftMin) || (marketProductDetail.pricingOption !== selected) || (marketProductDetail.refillAction !== refillAction) || (marketProductDetail.specificMargin !== Number(specMargin)) || (marketProductDetail.calculatedPrice !== Number(specificPrice)) || (marketProductDetail.scancode !== scanCode)) {
            return false;
        }
        return true;
    }

    const checkPricingChanges = () => {
        if ((marketProductDetail.pricingOption !== selected) || (marketProductDetail.specificMargin !== Number(specMargin)) || (marketProductDetail.calculatedPrice !== Number(specificPrice))) {
            return false;
        }
        return true;
    }

    const onSave = () => {
        if (orderLeftMin > orderRightMax) {
            renderToastr(Hotel365Portal.MIN_SHOULD_NOT_BE_GREATER_MSG, "danger");
            return false;
        }
        if (orderLeftMin === 0 && orderRightMax > 0) {
            renderToastr(Hotel365Portal.MAX_SHOULD_NOT_BE_SET_MSG, "danger");
            return false;
        }
        const checkNoChangestoSave = checkNoChanges()
        if (!checkNoChangestoSave) {
            if (!checkPricingChanges()) dispatch(setShowPublishProduct(true))
            dispatch(resetProductsSettingsPanel());
            if (swappedproduct && Object.keys(swappedproduct).length > 0) {
                dispatch(SaveSwapProduct(prepareSwapReqObject));
            } else {
                dispatch(PatchMarketProduct([prepareReqObject]));
            }
        } else {
            renderToastr(GLOBAL_NO_CHANGES_TO_SAVE, "warning");
            return false;
        }

    };

    const onDelete = () => {
        dispatch(resetProductsSettingsPanel());
        dispatch(deleteMarketProduct(selectedProduct?.productLocationId));
    };

    const swapProduct = () => {
        dispatch(resetMarketProducts());
        dispatch(resetProductsSettingsPanel());
        dispatch(updateProductCategoryFilters([]));
        if (globalProducts && globalProducts.length === 0) {
            dispatch(FetchGlobalProducts(0));
        }
        setButtonState(true);
    };

    const refillActionHandle = (e: any) => {
        setRefillAction(e.target.value);
    };

    const orderMin = (type: string) => {
        if (type === "minus") {
            if (orderLeftMin > 0) setOrderLeftMin(Number(orderLeftMin - 1));
            else return false;
        } else {
            setOrderLeftMin(Number(orderLeftMin + 1));
        }
    };

    const orderMax = (type: string) => {
        if (type === "minus") {
            if (orderRightMax > 0) setOrderRightMax(Number(orderRightMax - 1));
            else return false;
        } else {
            setOrderRightMax(Number(orderRightMax + 1));
        }
    };

    const changePricing = (event: React.MouseEvent<HTMLIonRadioGroupElement, MouseEvent>) => {
        setSelected(event.currentTarget.value);
    };

    const onChangeOrderMin = (e: any) => {
        setOrderLeftMin(Number(e.target.value));
    };

    const onChangeOrderMax = (e: any) => {
        setOrderRightMax(Number(e.target.value));
    };

    const notifyCloseModal = useCallback(() => {
        //dispatch(resetGlobalProducts());
        setButtonState(false);
    }, [buttonState]);

    const invokeSwapProductCallback = useCallback(
        (_swapProduct) => {
            setSwappedProduct(_swapProduct);
        },
        [setSwappedProduct]
    );

    useEffect(() => {
        if (swappedproduct && Object.keys(swappedproduct).length > 0) {
            setCost(swappedproduct.price as string);
            setSpecificPrice(swappedproduct.price as string)
            const cat = swappedproduct.category1 || swappedproduct.category2 || swappedproduct.category3
            setName(swappedproduct.productName as string);
            setCategory(cat as string);
            setImage(swappedproduct.largeImage as string);
            setScanCode(
                Array.isArray(swappedproduct.scancode)
                    ? swappedproduct.scancode.join(",")
                    : (swappedproduct.scancode as string)
            );
            setOrderLeftMin(swappedproduct.minStock as number);
            setOrderRightMax(swappedproduct.maxStock as number);
            setRefillAction(swappedproduct.refillAction as string);
            recalculateSellingPrice();
        }
    }, [swappedproduct, showCatgeoryMargin, swappedproduct.price]);
    const renderStatusButton = () => {
        return (
            <React.Fragment>
                <IonRow className="modal-body">
                    <IonCol>
                        <IonImg
                            src={fetchImage()}
                            className="product-image"
                        />
                    </IonCol>
                    <IonCol>
                        {marketProductDetail.status === "DISCONTINUED" && (
                            <IonButton
                                color="danger"
                                onClick={swapProduct}
                                expand="block"
                                className="btn-text-cases"
                            >
                                {DISCONTINUED_SWAP}
                            </IonButton>
                        )}
                        {(marketProductDetail.status === "NORMAL" ||
                            marketProductDetail.status === "SWAPPED_IN") && (
                                <IonButton
                                    color="primary"
                                    onClick={swapProduct}
                                    expand="block"
                                    className="btn-text-cases"
                                >
                                    {SWAP_NEW_PRODUCT}
                                </IonButton>
                            )}
                        {marketProductDetail.status === "SWAPPED_OUT" && (
                            <IonButton
                                disabled={true}
                                color="light"
                                expand="block"
                                className="btn-text-cases"
                            >
                                {SWAPPED_OUT}
                            </IonButton>
                        )}
                        {marketProductDetail.status === "DELETED" && (
                            <IonButton
                                color="light"
                                disabled={true}
                                expand="block"
                                className="btn-text-cases"
                            >
                                {DELETE}
                            </IonButton>
                        )}
                    </IonCol>
                </IonRow>
                {swappedproduct && Object.keys(swappedproduct).length > 0 && (
                    <IonRow>
                        <IonCol className="swap-product-banner-content">
                            <IonText>{PRODUCT_DETAILS_SWAPPED_IN}</IonText>
                            <p>{SWAPPED_MSG}</p>
                        </IonCol>
                    </IonRow>
                )}
            </React.Fragment>
        );
    };

    const renderPricingSection = () => {
        return (
            <IonList>
                <IonRadioGroup
                    value={selected}
                    onClick={(e) => changePricing(e)}
                >
                    {showGlobalMargin.isEnable && (
                        <IonRow>
                            <IonCol>
                                <IonItem lines="full">
                                    <IonRadio slot="start" value="GLOBAL_MARGIN" />
                                    <IonText slot="start" className={`${selected === "GLOBAL_MARGIN" ? "selected-pricing" : null}`}>
                                        {PRODUCT_GLOBAL_MARGINS}
                                    </IonText>
                                    <IonLabel slot="end" className={`${selected === "GLOBAL_MARGIN" ? "selected-pricing" : null}`}>
                                        {showGlobalMargin.margin}
                                    </IonLabel>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    )}
                    {showCatgeoryMargin.isEnable && (
                        <IonRow>
                            <IonCol>
                                <IonItem lines="full">
                                    <IonRadio slot="start" value="CATEGORY_MARGIN" />
                                    <IonLabel slot="start" className={`${selected === "CATEGORY_MARGIN" ? "selected-pricing" : null}`}>
                                        {PRODUCT_CATEGORY_MARGINS}
                                    </IonLabel>
                                    <IonLabel slot="end" className={`${selected === "CATEGORY_MARGIN" ? "selected-pricing" : null}`}>
                                        {showCatgeoryMargin.margin}
                                    </IonLabel>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    )}
                    {globalMargin.doesAllowProductLevelMargin && (
                        <IonRow>
                            <IonCol>
                                <IonItem lines="full">
                                    <IonRadio slot="start" value="SPECIFIC_MARGIN" />
                                    <IonLabel slot="start" className={`${selected === "SPECIFIC_MARGIN" ? "selected-pricing" : null}`}>
                                        {errors?.specMargin?.message && (
                                            <IonIcon
                                                md={star}
                                                size="small"
                                                color="danger"
                                                className="error-asterik-size"
                                            />
                                        )}
                                        {PRODUCT_SPECIFIC_MARGIN}
                                    </IonLabel>
                                    <CurrencyInput
                                        id="specificMargin"
                                        name="specificMargin"
                                        placeholder={PRODUCT_PLACEHOLDER_MARGIN}
                                        value={specMargin}
                                        defaultValue={specMargin}
                                        className={`currency-text-box`}
                                        suffix="%"
                                        step={10}
                                        allowDecimals={false}
                                        onValueChange={(value) => { setSpecMargin(value); }}
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    )}
                    <IonRow>
                        <IonCol>
                            <IonItem lines="full">
                                <IonRadio slot="start" value="SPECIFIC_PRICE" />
                                <IonLabel slot="start" className={`${selected === "SPECIFIC_PRICE" ? "selected-pricing" : null}`}>
                                    {errors?.specificPrice?.message && (
                                        <IonIcon
                                            md={star}
                                            size="small"
                                            color="danger"
                                            className="error-asterik-size"
                                        />
                                    )}
                                    {PRODUCT_SWAP_SPECIFIC_PRICE}
                                </IonLabel>
                                <CurrencyInput
                                    id="specificPrice"
                                    name="specificPrice"
                                    placeholder={PRODUCT_PLACEHOLDER_PRICE}
                                    value={specificPrice}
                                    className={`currency-text-box`}
                                    prefix={getSymbolFromCurrencyCode(currency)}
                                    step={1}
                                    decimalsLimit={2}
                                    decimalScale={2}
                                    onValueChange={(value) => {
                                        setSpecificPrice(value);
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonRadioGroup>
            </IonList>
        );
    };

    const updateTwoDecimal = (num: number) => {
        const result = num.toFixed(2);
        return result.toString();
    };

    const restrictAlphabets = (
        e: any
    ) => {
        const code = e.nativeEvent.which || e.nativeEvent.keycode;
        if (code >= 48 && code <= 57) {
            const result =
                e.target.selectionStart === 0 &&
                    e.target.selectionEnd === e.target.value.length
                    ? e.key
                    : [
                        e.target.value.slice(0, e.target.selectionStart),
                        e.key,
                        e.target.value.slice(e.target.selectionStart),
                    ].join("");

        } else {
            e.preventDefault();
            return false;
        }
    };

    return (
        <>
            <IonModal
                isOpen={showModal}
                className={isMobileView ? "mobileview-modal-pop product-details" : "modal-pop product-details"}
                onDidDismiss={onCloseModal}
            >
                <IonContent>
                    <IonHeader className="header">
                        <Header
                            headerTitle={name}
                            onCloseModal={onCloseModal}
                            filterIcon={false}
                        />
                    </IonHeader>
                    <IonCol className="product-popup-content-margin">
                        {renderStatusButton()}
                        <IonRow>
                            <IonCol>
                                <IonLabel className="category-name">
                                    <b>{category}</b>
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonLabel className="category-price">
                                    {scanCode}
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="ion-no-padding">
                                <h3 className="product-heading">
                                    <IonTitle>{PRODUCT_PRICING}</IonTitle>
                                </h3>
                            </IonCol>
                        </IonRow>
                        {renderPricingSection()}
                        <IonRow>
                            <IonCol offset="1" className="cost-section">
                                <span className="pricing-text">{PRODUCT_PRICING}</span>
                                <br />
                                {cost ? <b>{getLocalizedNumericValues(cost, "currency", currency, currencyOptionsObject)}</b> : null}
                            </IonCol>
                            <IonCol size="0.3">
                                <span className="vertical-line-separator"></span>
                            </IonCol>
                            <IonCol offset="1">
                                <b>
                                    <span className="pricing-text">{PRODUCT_SELLING_PRICE}</span>
                                    <br />
                                    {price || null}
                                </b>
                            </IonCol>

                            <IonCol>
                                <IonButton
                                    onClick={() => {
                                        if (cost) {
                                            setPrice(cost);
                                            setSelected("");
                                        } else {
                                            setPrice("");
                                        }
                                    }}
                                >
                                    {RESET}
                                </IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="ion-no-padding">
                                <h3 className="product-heading">
                                    <IonTitle>{PRODUCT_MARKET_QUANTITIES}</IonTitle>
                                </h3>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="6">
                                <IonLabel className="cost-section f12">
                                    {PRODUCT_BELOW_MAX}
                                </IonLabel>
                                <IonRow className="add-quatity">
                                    <IonCol className="quantity-buttons-right">
                                        <IonButton
                                            className="btn no-padding"
                                            expand="full"
                                            onClick={() => orderMin("minus")}
                                        >
                                            -
                                        </IonButton>
                                    </IonCol>
                                    <IonCol className="quantity-value-field">
                                        <IonInput
                                            className="itemCount no-padding"
                                            name="quantity"
                                            value={orderLeftMin}
                                            onIonChange={onChangeOrderMin}
                                            onKeyPress={(e) => { restrictAlphabets(e); }}
                                        />
                                    </IonCol>
                                    <IonCol className="quantity-buttons-left">
                                        <IonButton
                                            className="btn no-padding"
                                            expand="full"
                                            onClick={() => orderMin("plus")}
                                        >
                                            +
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                            <IonCol size="6">
                                <IonLabel className="cost-section f12">
                                    {PRODUCT_BELOW_MIN}
                                </IonLabel>
                                <IonRow className="add-quatity">
                                    <IonCol className="quantity-buttons-right">
                                        <IonButton
                                            className="btn no-padding"
                                            expand="full"
                                            onClick={() => orderMax("minus")}
                                        >
                                            -
                                        </IonButton>
                                    </IonCol>
                                    <IonCol className="quantity-value-field">
                                        <IonInput
                                            className="itemCount no-padding"
                                            name="quantity"
                                            value={orderRightMax}
                                            onIonChange={onChangeOrderMax}
                                            onKeyPress={(e) => { restrictAlphabets(e); }}
                                        />
                                    </IonCol>
                                    <IonCol className="quantity-buttons-left">
                                        <IonButton
                                            className="btn no-padding"
                                            expand="full"
                                            onClick={() => orderMax("plus")}
                                        >
                                            +
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonLabel>{PRODUCT_REFILL_ACTION}</IonLabel>
                                <IonSelect
                                    interface="popover"
                                    value={refillAction}
                                    onIonChange={refillActionHandle}
                                    className="sort-dropdown"
                                >
                                    <IonSelectOption value="BELOW_MAXIMUM">
                                        {PRODUCT_BELOW_MAX}
                                    </IonSelectOption>
                                    <IonSelectOption value="AT_OR_BELOW_MINIMUM">
                                        {PRODUCT_BELOW_MIN}
                                    </IonSelectOption>
                                </IonSelect>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                    <IonFooter className="footer">
                        <IonRow>
                            <IonCol sizeMd="4">
                                <IonButton
                                    color="light"
                                    expand="block"
                                    className="btn-text-cases"
                                    onClick={onDelete}
                                    disabled={
                                        marketProductDetail.status ===
                                        "SWAPPED_OUT" ||
                                        marketProductDetail.status === "DELETED"
                                    }
                                >
                                    {DELETE}
                                </IonButton>
                            </IonCol>
                            <IonCol sizeMd="4">
                                <IonButton
                                    color="light"
                                    expand="block"
                                    className="btn-text-cases"
                                    onClick={() => onCloseModal()}
                                >
                                    {FOOTER_CANCEL_BUTTON}
                                </IonButton>
                            </IonCol>
                            <IonCol sizeMd="4">
                                <IonButton
                                    color="primary"
                                    expand="block"
                                    type="submit"
                                    className="btn-text-cases"
                                    onClick={onSave}
                                    disabled={
                                        errors?.specificPrice?.message as any ||
                                        errors?.specMargin?.message as any
                                    }
                                >
                                    {FOOTER_SAVE_BUTTON}
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonFooter>
                </IonContent>
            </IonModal >
            <SwapProduct
                currency={currency}
                notifySwapProductClose={notifyCloseModal}
                buttonState={buttonState}
                swapProdId={marketProductDetail.productLocationId}
                swappedProduct={invokeSwapProductCallback}
            />
        </>
    );
});