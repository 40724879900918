import { all, put, take, takeLatest, select } from "redux-saga/effects";
import { doGet, doPatch, baseUrlWithProtocol } from '../../../utils/fetchWrapper';
// actions
import {
    fetchSystemUsersSuccess,
    fetchSystemUsersFailure,
    fetchSystemUsersRequest,
    patchSystemUsers as patchSystemUsersAction,
    patchSystemUsersSuccess,
    patchSystemUsersFailure,
    postVerifyTokenSuccess,
    postVerifyTokenFailure,
    putCreatePasswordSuccess,
    putCreatePasswordFailure
} from "../../actions/systemUsersActions";
import {
    fetchProfileRequest,
    fetchProfilesRequestWithSysUserId,
    fetchProfilesFailureWithSysUserId,
} from "../../actions/profileActions";
import { fetchUserLocationRequest, setLocationForCP } from "../../actions/userManagementActions"
// constants
import * as SYSTEMUSERS from '../../ActionTypes/systemUsersTypes';
import * as PROFILE from '../../ActionTypes/profileTypes';
import { FETCH_USERDETAIL_LOCATION_SUCCESS } from "../../ActionTypes/userManagementTypes";
import { Routes } from '../../../constants/Routes';
import { userRoleIdsHP, userRoleIdsCP } from '../../../constants/constant'
import {
    INVALID_ROLES_ERROR,
    SYSTEMUSERS_FETCH_ERROR,
    NO_PROFILES_ASSOCIATED_WITH_USER_ERROR,
    ONBOARDING_IS_NOT_ALLOWED_FOR_ROLE_ERROR,
} from '../../../constants/constant';
//utils
import { isSecondArrHasSomeElementsFirstArr } from '../../../utils';
import { getRoleId, isOnboardingAccessPermitted, isUserValidityIntact } from '../../../utils/roleBasedAccess';

function* fetchSystemUsers(action: any) {
    try {
        // @ts-ignore
        const url = `${Routes.systemUsers.url}/${action.systemUserId}`;
        // @ts-ignore
        const response = yield doGet(url);
        yield put(fetchSystemUsersSuccess({ ...response }));
    } catch (e: any) {
        const updatedError = {
            type: SYSTEMUSERS_FETCH_ERROR,
            errorMessage: `Error while fetching data, please try after some time`
        }
        yield put(fetchSystemUsersFailure({ error: updatedError }));
    }
}

function* afterLoginFlowCP(action: any) {
    const { systemUserId } = action;
    yield put(fetchSystemUsersRequest(systemUserId));
    const { data } = yield take([SYSTEMUSERS.FETCH_SYSTEMUSERS_SUCCESS]);
    yield put(fetchUserLocationRequest(systemUserId));
    const { data: locations } = yield take([FETCH_USERDETAIL_LOCATION_SUCCESS]);
    yield put(setLocationForCP(locations))
    const { locationLastViewedId, roles } = data;
    const roleIds = getRoleId(roles);
    const areRolesValid = isSecondArrHasSomeElementsFirstArr(userRoleIdsCP, roleIds);
    const userValidity = isUserValidityIntact(false, roles);
    try {
        if (areRolesValid && userValidity) {
            try {
                if (locationLastViewedId) {
                    yield put(fetchProfileRequest(locationLastViewedId));
                } else if (locations.length) {
                    yield put(fetchProfileRequest(locations[0].locationId));
                } else {
                    throw (NO_PROFILES_ASSOCIATED_WITH_USER_ERROR);
                }
            } catch (e: any) {
                yield put(fetchProfilesFailureWithSysUserId({ error: e }));
            }
        } else {
            throw (INVALID_ROLES_ERROR);
        }
    } catch (e: any) {
        yield put(fetchSystemUsersFailure({ error: e }));
    }
}

function* afterLoginFlow(action: any) {
    const { systemUserId } = action;
    yield put(fetchSystemUsersRequest(systemUserId));
    const { data } = yield take([SYSTEMUSERS.FETCH_SYSTEMUSERS_SUCCESS]);
    const { locationLastViewedId, roles } = data;
    const roleIds = getRoleId(roles);
    const areRolesValid = isSecondArrHasSomeElementsFirstArr(userRoleIdsHP, roleIds);
    const userValidity = isUserValidityIntact(false, roles);
    try {
        if (areRolesValid && userValidity) {
            yield put(fetchProfilesRequestWithSysUserId(systemUserId));
            const { profiles } = yield take(PROFILE.FETCH_PROFILES_SUCCESS_WITH_SYS_USER_ID);
            try {
                if (profiles.length) {
                    // if Last Viewed location available, get profile details of Last Viewed location                    
                    const lastViewedprofileDetails = locationLastViewedId && profiles.find((profile: Record<string, any>) => {
                        return profile.locationId === locationLastViewedId && profile.onboardingStep === -1;
                    });
                    // if Last Viewed location is not available,
                    // take first available profile with onboarding step as -1
                    const firstAvailableOnboardedProfile = profiles.find((profile: Record<string, any>) => {
                        return profile.onboardingStep === -1;
                    });

                    if (lastViewedprofileDetails || firstAvailableOnboardedProfile) {
                        const { locationId } = lastViewedprofileDetails || firstAvailableOnboardedProfile;
                        yield put(fetchProfileRequest(locationId));
                        yield put(patchSystemUsersAction(systemUserId, { locationLastViewedId: locationId }));
                    } else {
                        if (isOnboardingAccessPermitted(false, roles)) {
                            const { locationId } = profiles[0];
                            yield put(fetchProfileRequest(locationId));
                            yield put(patchSystemUsersAction(systemUserId, { locationLastViewedId: locationId }));
                        } else {
                            throw (ONBOARDING_IS_NOT_ALLOWED_FOR_ROLE_ERROR);
                        }
                    }
                } else {
                    throw (NO_PROFILES_ASSOCIATED_WITH_USER_ERROR);
                }
            } catch (e: any) {
                yield put(fetchProfilesFailureWithSysUserId({ error: e }));
            }
        } else {
            throw (INVALID_ROLES_ERROR);
        }
    } catch (e: any) {
        yield put(fetchSystemUsersFailure({ error: e }));
    }
}

function* patchSystemUsers(action: any) {
    const { systemUserId, data } = action;
    const url = `${Routes.systemUsers.url}/${systemUserId}`;
    const request = { systemUserId, ...data };
    try {
        // @ts-ignore        
        const response = yield doPatch(url, request);
        // on success update redux store with complete request object
        yield put(patchSystemUsersSuccess({ ...request, ...response }));
    } catch (error: any) {
        yield put(patchSystemUsersFailure(error));
    }
}

function* afterLocationChange(action: any) {
    const { systemUserId, data, orgId } = action;
    yield put(patchSystemUsersAction(systemUserId, data));
    const { response } = yield take([SYSTEMUSERS.PATCH_SYSTEMUSERS_SUCCESS]);
    const { locationLastViewedId } = response;
    yield put(fetchProfileRequest(locationLastViewedId));
}

function* verifyToken(action: any) {
    const { token } = action;
    const url = `${baseUrlWithProtocol}${Routes.systemUserActions.tokenVerify}`;
    try {
        // @ts-ignore 
        const response = yield fetch(url, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Accept: 'application/json; charset=UTF-8',
            },
            method: 'POST',
            body: JSON.stringify({ token })
        });
        if ([200, 226].indexOf(response.status) === -1) {
            throw response
        }
        const { systemUserId, email } = yield response.json();
        yield put(postVerifyTokenSuccess({ systemUserId, email, token }));
    } catch (error: any) {
        yield put(postVerifyTokenFailure(error));
    }
}

function* createPassword(action: any) {
    const { password } = action;
    const { systemUserId, token } = yield select((state) => state.createPassword);
    const url = `${baseUrlWithProtocol}${Routes.systemUserActions.url}${systemUserId}/password`;
    const request = { token, ...password }
    try {
        // @ts-ignore 
        const response = yield fetch(url, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Accept: 'application/json; charset=UTF-8',
            },
            method: 'PUT',
            body: JSON.stringify(request)
        });
        if (![200, 204].includes(response.status)) {
            throw response
        }
        yield put(putCreatePasswordSuccess(response));
    } catch (error: any) {
        yield put(putCreatePasswordFailure(error));
    }
}

function* SystemUsersSaga() {
    yield all([
        takeLatest(SYSTEMUSERS.FETCH_SYSTEMUSERS_REQUEST, fetchSystemUsers),
        takeLatest(SYSTEMUSERS.PATCH_SYSTEMUSERS_REQUEST, patchSystemUsers),
        takeLatest(SYSTEMUSERS.AFTER_LOGIN_FLOW, afterLoginFlow),
        takeLatest(SYSTEMUSERS.AFTER_LOGIN_FLOW_CP, afterLoginFlowCP),
        takeLatest(SYSTEMUSERS.AFTER_LOCATION_CHANGE, afterLocationChange),
        takeLatest(SYSTEMUSERS.POST_VERIFYTOKEN_REQUEST, verifyToken),
        takeLatest(SYSTEMUSERS.PUT_CREATE_PASSWORD_REQUEST, createPassword)
    ])
}

export default SystemUsersSaga;