import * as PRODUCT from "../ActionTypes/productTypes";
import { SaveGlobalProduct } from "../../models/SaveGlobalProduct";
import {
    MarketProduct,
    PatchMarketProduct as IPatchMarketProduct,
} from "../../models/MarketProduct";
import { SwapProductRequest } from "../../models/SwapProduct";
import { GlobalProductCatgeories } from "../../models/GlobalProducts";
import {LocationExtendedProductRequest, LocationExtendedProductSuccess} from '../../models/LocationsModal'

export interface FetchGlobalProductsSuccessPayload {
    products: {} | any;
    searchName?: "";
    category?: [];
}

interface PostGlobalProduct {
    locationId: string;
    orgId: string;
    productId: string;
}

export interface UpdateProductFilters {
    type: PRODUCT.UPDATE_PRODUCT_FILTERS;
    info: string[];
}

export type UpdateProductFiltersType = UpdateProductFilters;

export interface UpdateManageColumns {
    type: PRODUCT.UPDATE_MANAGE_COLUMN;
    info: string[];
}

export type UpdateManageColumnsType = UpdateManageColumns;

export interface UpdateProductSettings {
    type: PRODUCT.UPDATE_PRODUCT_SETTINGS;
    info: Record<string, unknown>;
}

export type UpdateProductSettingsType = UpdateProductSettings;

export interface ResetGlobalProducts {
    type: PRODUCT.RESET_GLOBAL_PRODUCTS;
}

export type ResetGlobalProductsType = ResetGlobalProducts;


export interface ResetMarketProducts {
    type: PRODUCT.RESET_MARKET_PRODUCTS;
}

export type ResetMarketProductsType = ResetMarketProducts;

/**
 *
 */
export interface SetLocationID {
    type: PRODUCT.SET_LOCATION_ID;
    info: string;
}
export interface SetOrganizationID {
    type: PRODUCT.SET_ORGANIZATION_ID;
    info: string;
}

export type SetLocationOrganzationIDType = SetLocationID | SetOrganizationID;

export interface FetchMarketProducts {
    type: PRODUCT.FETCH_MARKET_PRODUCTS_REQUEST;
    offset: number | null;
}
export interface FetchMarketProductsSuccess {
    type: PRODUCT.FETCH_MARKET_PRODUCTS_SUCCESS;
    data: FetchGlobalProductsSuccessPayload;
}
export interface FetchMarketProductsFailure {
    type: PRODUCT.FETCH_MARKET_PRODUCTS_FAILURE;
    error: Record<string, any> | null;
}
export type FetchMarketProductsType =
    | FetchMarketProducts
    | FetchMarketProductsSuccess
    | FetchMarketProductsFailure;
export function FetchMarketProducts(
    offset: number | null
): FetchMarketProducts {
    return {
        type: PRODUCT.FETCH_MARKET_PRODUCTS_REQUEST,
        offset,
    };
}
export function FetchMarketProductsSuccess(
    data: FetchGlobalProductsSuccessPayload
): FetchMarketProductsSuccess {
    return {
        type: PRODUCT.FETCH_MARKET_PRODUCTS_SUCCESS,
        data,
    };
}
export function FetchMarketProductsFailure(
    error: Record<string, any>
): FetchMarketProductsFailure {
    return {
        type: PRODUCT.FETCH_MARKET_PRODUCTS_FAILURE,
        error,
    };
}

export function updateProductCategoryFilters(
    info: string[]
): UpdateProductFilters {
    return {
        type: PRODUCT.UPDATE_PRODUCT_FILTERS,
        info,
    };
}

export function updateManageColumns(info: string[]): UpdateManageColumns {
    return {
        type: PRODUCT.UPDATE_MANAGE_COLUMN,
        info,
    };
}

export function resetGlobalProducts(): ResetGlobalProductsType {
    return {
        type: PRODUCT.RESET_GLOBAL_PRODUCTS,
    };
}

export function resetMarketProducts(): ResetMarketProductsType {
    return {
        type: PRODUCT.RESET_MARKET_PRODUCTS,
    };
}

/**
 * SET location and organization ID
 * @param info
 * @returns
 */
export function setLocationID(info: string): SetLocationID {
    return {
        type: PRODUCT.SET_LOCATION_ID,
        info,
    };
}
export function setOrganizationID(info: string): SetOrganizationID {
    return {
        type: PRODUCT.SET_ORGANIZATION_ID,
        info,
    };
}
/**
 *  fetch global products
 * */
export interface FetchGlobalProducts {
    type: PRODUCT.FETCH_GLOBAL_PRODUCTS_REQUEST;
    offset: number | null;
    name: string | undefined;
    category: string[] | undefined;
}
export interface FetchGlobalProductsSuccess {
    type: PRODUCT.FETCH_GLOBAL_PRODUCTS_SUCCESS;
    data: FetchGlobalProductsSuccessPayload;
}
export interface FetchGlobalProductsFailure {
    type: PRODUCT.FETCH_GLOBAL_PRODUCTS_FAILURE;
    error: Record<string, any> | null;
}
export type FetchGlobalProductsType =
    | FetchGlobalProducts
    | FetchGlobalProductsSuccess
    | FetchGlobalProductsFailure;
export function FetchGlobalProducts(
    offset: number | null,
    name?: string | undefined,
    category?: string[] | undefined
): FetchGlobalProducts {
    return {
        type: PRODUCT.FETCH_GLOBAL_PRODUCTS_REQUEST,
        offset,
        name,
        category,
    };
}
export function FetchGlobalProductsSuccess(
    data: FetchGlobalProductsSuccessPayload
): FetchGlobalProductsSuccess {
    return {
        type: PRODUCT.FETCH_GLOBAL_PRODUCTS_SUCCESS,
        data,
    };
}
export function FetchGlobalProductsFailure(
    error: Record<string, any>
): FetchGlobalProductsFailure {
    return {
        type: PRODUCT.FETCH_GLOBAL_PRODUCTS_FAILURE,
        error,
    };
}
/**
 * Save market product
 */
export interface SaveMarketProduct {
    type: PRODUCT.SAVE_MARKET_PRODUCT_REQUEST;
    info: PostGlobalProduct[];
}
export interface SaveMarketProductSuccess {
    type: PRODUCT.SAVE_MARKET_PRODUCT_SUCCESS;
    data: SaveGlobalProduct;
}
export interface SaveMarketProductFailure {
    type: PRODUCT.SAVE_MARKET_PRODUCT_FAILURE;
    error: Record<string, any> | null;
}
export type SaveMarketProductType =
    | SaveMarketProduct
    | SaveMarketProductSuccess
    | SaveMarketProductFailure;

export function saveMarketProduct(info: PostGlobalProduct[]): SaveMarketProduct {
    return {
        type: PRODUCT.SAVE_MARKET_PRODUCT_REQUEST,
        info,
    };
}
export function saveMarketProductSuccess(
    data: SaveGlobalProduct
): SaveMarketProductSuccess {
    return {
        type: PRODUCT.SAVE_MARKET_PRODUCT_SUCCESS,
        data,
    };
}
export function saveMarketProductFailure(
    error: Record<string, any> | null
): SaveMarketProductFailure {
    return {
        type: PRODUCT.SAVE_MARKET_PRODUCT_FAILURE,
        error,
    };
}

export function updateProductSettings(
    info: Record<string, unknown>
): UpdateProductSettings {
    return {
        type: PRODUCT.UPDATE_PRODUCT_SETTINGS,
        info,
    };
}

/**
 * Fetch PRODUCT DETAIL
 */
export interface FetchProductDetails {
    type: PRODUCT.FETCH_PRODUCT_DETAILS_REQUEST;
    productLocationId: string;
}
export interface FetchProductDetailsSuccess {
    type: PRODUCT.FETCH_PRODUCT_DETAILS_SUCCESS;
    data: MarketProduct;
}
export interface FetchProductDetailsFailure {
    type: PRODUCT.FETCH_PRODUCT_DETAILS_FAILURE;
    error: Record<string, any> | null;
}
export type FetchProductDetailsType =
    | FetchProductDetails
    | FetchProductDetailsSuccess
    | FetchProductDetailsFailure;
export function FetchProductDetails(
    productLocationId: string
): FetchProductDetails {
    return {
        type: PRODUCT.FETCH_PRODUCT_DETAILS_REQUEST,
        productLocationId,
    };
}
export function FetchProductDetailsSuccess(
    data: MarketProduct
): FetchProductDetailsSuccess {
    return {
        type: PRODUCT.FETCH_PRODUCT_DETAILS_SUCCESS,
        data,
    };
}
export function FetchProductDetailsFailure(
    error: Record<string, any>
): FetchProductDetailsFailure {
    return {
        type: PRODUCT.FETCH_PRODUCT_DETAILS_FAILURE,
        error,
    };
}

/**
 * patch market product
 */
export interface PatchMarketProduct {
    type: PRODUCT.PATCH_MARKET_PRODUCT_REQUEST;
    info: IPatchMarketProduct;
}
export interface PatchMarketProductSuccess {
    type: PRODUCT.PATCH_MARKET_PRODUCT_SUCCESS;
    data: any;
}
export interface PatchMarketProductFailure {
    type: PRODUCT.PATCH_MARKET_PRODUCT_FAILURE;
    error: Record<string, any> | null;
}
export type PatchMarketProductType =
    | PatchMarketProduct
    | PatchMarketProductSuccess
    | PatchMarketProductFailure;

export function PatchMarketProduct(info: any): PatchMarketProduct {
    return {
        type: PRODUCT.PATCH_MARKET_PRODUCT_REQUEST,
        info,
    };
}
export function PatchMarketProductSuccess(
    data: any
): PatchMarketProductSuccess {
    return {
        type: PRODUCT.PATCH_MARKET_PRODUCT_SUCCESS,
        data,
    };
}
export function PatchMarketProductFailure(
    error: Record<string, any> | null
): PatchMarketProductFailure {
    return {
        type: PRODUCT.PATCH_MARKET_PRODUCT_FAILURE,
        error,
    };
}

export interface DeleteMarketProduct {
    type: PRODUCT.DELETE_MARKET_PRODUCT;
    info: string;
}
export interface DeleteMarketProductSuccess {
    type: PRODUCT.DELETE_MARKET_PRODUCT_SUCCESS;
    data: string;
}
export interface DeleteMarketProductFailure {
    type: PRODUCT.DELETE_MARKET_PRODUCT_FAILURE;
    error: Error;
}
export type DeleteMarketProductType =
    | DeleteMarketProduct
    | DeleteMarketProductSuccess
    | DeleteMarketProductFailure;

export function deleteMarketProduct(info: string): DeleteMarketProduct {
    return {
        type: PRODUCT.DELETE_MARKET_PRODUCT,
        info,
    };
}
export function deleteMarketProductSuccess(
    data: string
): DeleteMarketProductSuccess {
    return {
        type: PRODUCT.DELETE_MARKET_PRODUCT_SUCCESS,
        data,
    };
}
export function deleteMarketProductFailure(
    error: Error
): DeleteMarketProductFailure {
    return {
        type: PRODUCT.DELETE_MARKET_PRODUCT_FAILURE,
        error,
    };
}

/**
 * Save Swap product
 */
export interface SaveSwapProduct {
    type: PRODUCT.SAVE_SWAP_PRODUCT_REQUEST;
    info: SwapProductRequest;
}
export interface SaveSwapProductSuccess {
    type: PRODUCT.SAVE_SWAP_PRODUCT_SUCCESS;
    data: MarketProduct;
}
export interface SaveSwapProductFailure {
    type: PRODUCT.SAVE_SWAP_PRODUCT_FAILURE;
    error: Record<string, any> | null;
}
export type SaveSwapProductType =
    | SaveSwapProduct
    | SaveSwapProductSuccess
    | SaveSwapProductFailure;

export function SaveSwapProduct(info: SwapProductRequest): SaveSwapProduct {
    return {
        type: PRODUCT.SAVE_SWAP_PRODUCT_REQUEST,
        info,
    };
}
export function SaveSwapProductSuccess(
    data: MarketProduct
): SaveSwapProductSuccess {
    return {
        type: PRODUCT.SAVE_SWAP_PRODUCT_SUCCESS,
        data,
    };
}
export function SaveSwapProductFailure(
    error: Record<string, any> | null
): SaveSwapProductFailure {
    return {
        type: PRODUCT.SAVE_SWAP_PRODUCT_FAILURE,
        error,
    };
}

/**
 *  fetch global products categories
 * */
export interface FetchGlobalProductsCategories {
    type: PRODUCT.FETCH_GLOBAL_PRODUCTS_CATEGORIES_REQUEST;
    organizationId: string;
    offset: number | null;
}
export interface FetchGlobalProductsCategoriesSuccess {
    type: PRODUCT.FETCH_GLOBAL_PRODUCTS_CATEGORIES_SUCCESS;
    data: GlobalProductCatgeories;
}
export interface FetchGlobalProductsCategoriesFailure {
    type: PRODUCT.FETCH_GLOBAL_PRODUCTS_CATEGORIES_FAILURE;
    error: Record<string, any> | null;
}
export type FetchGlobalProductsCategoriesType =
    | FetchGlobalProductsCategories
    | FetchGlobalProductsCategoriesSuccess
    | FetchGlobalProductsCategoriesFailure;
export function FetchGlobalProductsCategories(
    organizationId: string,
    offset: number | null
): FetchGlobalProductsCategories {
    return {
        type: PRODUCT.FETCH_GLOBAL_PRODUCTS_CATEGORIES_REQUEST,
        organizationId,
        offset,
    };
}
export function FetchGlobalProductsCategoriesSuccess(
    data: GlobalProductCatgeories
): FetchGlobalProductsCategoriesSuccess {
    return {
        type: PRODUCT.FETCH_GLOBAL_PRODUCTS_CATEGORIES_SUCCESS,
        data,
    };
}
export function FetchGlobalProductsCategoriesFailure(
    error: Record<string, any>
): FetchGlobalProductsCategoriesFailure {
    return {
        type: PRODUCT.FETCH_GLOBAL_PRODUCTS_CATEGORIES_FAILURE,
        error,
    };
}


/**
 * Trigger publish product.
 */
export interface PostPublishProduct {
    type: PRODUCT.POST_PUBLISH_PRODUCT_REQUEST;
    info: Record<string, any>;
}
export interface PostPublishProductSuccess {
    type: PRODUCT.POST_PUBLISH_PRODUCT_SUCCESS;
    data: Record<string, any>;
}
export interface PostPublishProductFailure {
    type: PRODUCT.POST_PUBLISH_PRODUCT_FAILURE;
    error: Record<string, any> | null;
}
export type PostPublishProductType =
    | PostPublishProduct
    | PostPublishProductSuccess
    | PostPublishProductFailure;

export function postPublishProduct(info: Record<string, any>): PostPublishProduct {
    return {
        type: PRODUCT.POST_PUBLISH_PRODUCT_REQUEST,
        info,
    };
}
export function postPublishProductSuccess(
    data: Record<string, any>
): PostPublishProductSuccess {
    return {
        type: PRODUCT.POST_PUBLISH_PRODUCT_SUCCESS,
        data,
    };
}
export function postPublishProductFailure(
    error: Record<string, any> | null
): PostPublishProductFailure {
    return {
        type: PRODUCT.POST_PUBLISH_PRODUCT_FAILURE,
        error,
    };
}


export interface ShowPublishProduct {
    type: PRODUCT.SHOW_PUBLISH_PRODUCT;
    info: boolean;
}

export type ShowPublishProductType = ShowPublishProduct;

export function setShowPublishProduct(info: boolean): ShowPublishProduct {
    return {
        type: PRODUCT.SHOW_PUBLISH_PRODUCT,
        info,
    };
}

/*
fetch market products for report
*/
export interface FetchMarketProductsForReportRequest {
    type: PRODUCT.FETCH_MARKETPRODUCTS_REPORT_REQUEST;
}
export interface FetchMarketProductsForReportSuccess {
    type: PRODUCT.FETCH_MARKETPRODUCTS_REPORT_SUCCESS;
    items: [];
}
export interface FetchMarketProductsForReportFailure {
    type: PRODUCT.FETCH_MARKETPRODUCTS_REPORT_FAILURE;
    error: Record<string, any> | null;
}

export type FetchMarketProductsForReportType =
    | FetchMarketProductsForReportRequest
    | FetchMarketProductsForReportSuccess
    | FetchMarketProductsForReportFailure;

export function fetchMarketProductsForReport(): FetchMarketProductsForReportRequest {
    return {
        type: PRODUCT.FETCH_MARKETPRODUCTS_REPORT_REQUEST
    };
}
export function fetchMarketProductsForReportSuccess(items: []): FetchMarketProductsForReportSuccess {
    return {
        type: PRODUCT.FETCH_MARKETPRODUCTS_REPORT_SUCCESS,
        items,
    };
}
export function fetchMarketProductsForReportFailure(error: Record<string, any>): FetchMarketProductsForReportFailure {
    return {
        type: PRODUCT.FETCH_MARKETPRODUCTS_REPORT_FAILURE,
        error,
    };
}


/**
 * Save self operatd  product
 */
export interface SaveSelfOperatedProduct {
    type: PRODUCT.POST_SELF_OPERATED_PRODUCT_REQUEST;
    info: LocationExtendedProductRequest[];
}
export interface SaveSelfOperatedProductSuccess {
    type: PRODUCT.POST_SELF_OPERATED_PRODUCT_SUCCESS;
    data: LocationExtendedProductSuccess;
}
export interface SaveSelfOperatedProductFailure {
    type: PRODUCT.POST_SELF_OPERATED_PRODUCT_FAILURE;
    error: Record<string, any> | null;
}
export type SaveSelfOperatedProductType =
    | SaveSelfOperatedProduct
    | SaveSelfOperatedProductSuccess
    | SaveSelfOperatedProductFailure;

export function saveSelfOperatedProduct(info: LocationExtendedProductRequest[]): SaveSelfOperatedProduct {
    return {
        type: PRODUCT.POST_SELF_OPERATED_PRODUCT_REQUEST,
        info,
    };
}
export function saveSelfOperatedProductSuccess(
    data: LocationExtendedProductSuccess
): SaveSelfOperatedProductSuccess {
    return {
        type: PRODUCT.POST_SELF_OPERATED_PRODUCT_SUCCESS,
        data,
    };
}
export function saveSelfOperatedProductFailure(
    error: Record<string, any> | null
): SaveSelfOperatedProductFailure {
    return {
        type: PRODUCT.POST_SELF_OPERATED_PRODUCT_FAILURE,
        error,
    };
}