//vendors
import { IonCol, IonRow, IonLabel, IonIcon } from "@ionic/react";
import { clipboardOutline } from 'ionicons/icons';
//constants
import { FILTER_AND_RUN_YOUR_REPORT, SELECT_YOUR_REPORT_TO_BEGIN } from "../../constants/constant"

const BeforeReportRun: React.FC<any> = ({ selectedReport }) => {
    return (
        <>
            {selectedReport.name && <IonRow className="report-gray-bar" />}
            <IonRow className="report-right-icon-row">
                <IonCol>
                    <IonIcon icon={clipboardOutline} size="large" className="report-select-report-icon" />
                </IonCol>
            </IonRow>
            <IonRow className="report-align-center">
                <IonCol>
                    <IonLabel className="report-select-report-lbl">
                        {selectedReport.name ? FILTER_AND_RUN_YOUR_REPORT : SELECT_YOUR_REPORT_TO_BEGIN}
                    </IonLabel>
                </IonCol>
            </IonRow>
        </>
    )
}

export default BeforeReportRun