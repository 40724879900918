
import { IonButton } from "@ionic/react";
import React, { useState } from "react";
import { ORDER_ADD_PRODUCT_TEXT } from '../../constants/constant';
import { AddProductOrderPopup } from "./AddProductOrder/Index";

const AddProduct: React.FC<any> = ({ onHeaderButtonClick, current }) => {
    const [buttonState, setButtonState] = useState(false);
    return (<><IonButton
        size="small"
        color={buttonState ? 'primary' : undefined}
        fill={current === "ADD PRODUCT" ? "solid" : undefined}
        className="grid-btns"
        onClick={(e) => { onHeaderButtonClick(e); setButtonState(pv => !pv) }} >{ORDER_ADD_PRODUCT_TEXT}
    </IonButton>
        <AddProductOrderPopup showAddPrdtModal={buttonState} setButtonState={setButtonState} />
    </>
    )
}

export default AddProduct;
