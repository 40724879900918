//vendors
import { components } from "react-select";

const Option = (props: any) => {
    return (
        <components.Option {...props}>
            <input
                className="dropdown-checkbox"
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
            />
            <label className='labels'>{props.label}</label>
        </components.Option>
    );
};

export default Option;