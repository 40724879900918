export const Routes = {
    login: {
        url: "/login",
    },
    dashboard: {
        url: "/dashboard",
        settingsUrl: "/dashboard/settings",
        productsUrl: "/dashboard/products",
        locationListUrl: "/dashboard/location-list",
        marketUrl: "/dashboard/market",
        orderUrl: "/dashboard/orders",
        reportsUrl: "/dashboard/reports",
        posUrl:"/dashboard/pos",
        paymentUrl:"/dashboard/payment",
        paymentDetails:"/dashboard/payment/card-details",
        submitGlobalProductApiUrl: "/market/market-products",
        fetchMarketProductsAPiUrl: "/market/market-products?locationId=",
        fetchGlobalProductsApiUrl: "/market/global-products?orgId=",
        fetchGlobalMarginApiUrl: "/market/global-margin?locationId=",
        fetchMarketProductDetailApiUrl: "/market/market-products/",
        patchMarketProductApiUrl: "/market/market-products/",
        fetchCategoryMarginApiUrl: "/market/category-margins?locationId=",
        saveCategoryMarginApiUrl: "/market/category-margins",
        submitGlobalMarginApiUrl: "/market/global-margin",
        deleteMarketProductApiUrl: "/market/market-products/",
        productActionsApiUrl: "/market/actions/market-products",
        globalProductCategoriesUrl: "/market/global-product-categories?orgId=",
        submitExtendedProductApiUrl:"/market/actions/create-and-extend-product",
        consumerListUrl:"/dashboard/consumerList",
        consumerDetailUrl:"/dashboard/consumer"
    },
    profile: {
        url: "/dashboard/profile",
    },
    success: {
        url: "/success",
    },
    passwordUpdate: {
        url: "/password-update",
    },
    forgotPassword: {
        url: "/forgot-password",
        forgotPasswordApiUrl: '/platform/actions/system-users/reset-password',
    },
    onboarding: {
        url: "/onboarding",
    },
    userManagement: {
        url: "/dashboard/user-management",
        credUserManagmentApiUrl: "/platform/system-users",
        resendApiUrl: "/platform/actions/system-users/"
    },
    marketUsers: {
        url: '/market/market-users'
    },
    marketProfile: {
        url: '/market/market-profile/'
    },
    systemUsers: {
        url: '/platform/system-users'
    },
    locations: {
        url: '/platform/locations'
    },
    createPassword: {
        url: '/create-password/'
    },
    order: {
        marketOrderHeaderApiUrl: '/market/market-orders',
        marketOrderActionsAPiUrl: '/market/actions/market-orders'
    },
    systemUserActions: {
        url: '/platform/actions/system-user/',
        tokenVerify: '/platform/actions/system-user/verify-token',
        postResetPassword :'/platform/actions/system-users/reset-password',
        emailUpdate: '/platform/system-users', 
        compilePriceActions:'/platform/actions/locations/'  
    },
    verifyEmail: {
        url: '/verify'
    },
    changeEnvironment: {
        url: '/change-environment'
    },
    resetPassword: {
        url: '/reset-password'
    },
    inviteExpired: {
        url: '/invite-expired'
    },
    reports: {
        url: '/reports/api'
    },
    pos:{
        sales:'/app/actions/sales'
    },
    payment:{
        addCard: '/app/actions/system-user-tokens/setup-and-attach-card',
        getPublicKey: '/app/actions/payments/credentials/',
        getSysUserTokens: '/platform/system-users/',
        detachCard: '/app/actions/system-user-tokens/detach-card'
    },
    consumer:{
        consumerListApiUrl:'/gma/actions/account-search',
        payrollGrpApiUrl: '/platform/payroll-deduct/groups-by-location/',
        subsidyGrpApiUrl:'/platform/subsidy/groups-by-location/',
        bulkActionApiUrl:'/gma/actions/bulk-actions/start',
        consumerDetailApiUrl:'/gma/accounts',
        addFundsApiUrl:'/app/actions/system-user-tokens/fund',
        addSubsidyGrpUrl:'/app/actions/accounts'
    }
}
