//actions
import {
    PostVerifyTokenRequest,
    PostVerifyTokenSuccess,
    PostVerifyTokenFailure,
    PutCreatePasswordRequest,
    PutCreatePasswordSuccess,
    PutCreatePasswordFailure
} from "../../models/SystemUsers";
export type verifyTokenTypes =
    PostVerifyTokenRequest |
    PostVerifyTokenSuccess |
    PostVerifyTokenFailure |
    PutCreatePasswordRequest |
    PutCreatePasswordSuccess |
    PutCreatePasswordFailure;

export interface ICreatePassword {
    pending: boolean;
    errors: Record<string, any> | null;
    email: string;
    systemUserId: string;
    token: string;
    createPasswordPending?: boolean;
    CreatePasswordErrors?: Record<string, any> | null;
    createPasswordStatus?: string; 
}

const initialState = {
    pending: true,
    errors: null,
    systemUserId: '',
    email: '',
    token: '',
    createPasswordPending: true,
    CreatePasswordErrors: null,
    createPasswordStatus: '', 
};

const strategies = {
    POST_VERIFYTOKEN_REQUEST: postVerifyToken,
    POST_VERIFYTOKEN_SUCCESS: postVerifyTokenSuccess,
    POST_VERIFYTOKEN_FAILURE: postVerifyTokenFailure,
    PUT_CREATE_PASSWORD_REQUEST: putCreatetPassword,
    PUT_CREATE_PASSWORD_SUCCESS: putCreatePasswordSuccess,
    PUT_CREATE_PASSWORD_FAILURE: putCreatePasswordFailure,
    default: (state: ICreatePassword) => state,
};

function postVerifyToken(state: ICreatePassword) {
    return { ...state, errors: null };
}

function postVerifyTokenSuccess(state: ICreatePassword, action: PostVerifyTokenSuccess) {
    const { systemUserId, email, token } = action;
    return { ...state, systemUserId, email, token, errors: null, pending: false };
}

function postVerifyTokenFailure(state: ICreatePassword, action: PostVerifyTokenFailure) {  
    return { ...state, pending: false, errors: action && action.error && action.error.status};
}

function putCreatetPassword(state: ICreatePassword) {
    return { ...state, CreatePasswordErrors: null };
}

function putCreatePasswordSuccess(state: ICreatePassword, action: any) {
    const { response } = action;
    const { status } = response;
    return { ...state, createPasswordStatus: status, createPasswordPending: false, CreatePasswordErrors: null };
}

function putCreatePasswordFailure(state: ICreatePassword, action: PostVerifyTokenFailure) {
    return { ...state, pending: false, CreatePasswordErrors: action && action.error && action.error.status };
}

export default (state: ICreatePassword = initialState, action: verifyTokenTypes): ICreatePassword => {
    return (strategies[action.type] || strategies.default)(state, action as never);
};