import { memo, useCallback, useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
    IonModal,
    IonButton,
    IonContent,
    IonRow,
    IonCol,
    IonItem,
    IonHeader,
    IonFooter,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSpinner,
    IonGrid,
    isPlatform
} from "@ionic/react";

import { RootState } from "../../../_redux/reducers/rootReducer";
import { FetchMarketProducts, FetchGlobalProductsCategories } from "../../../_redux/actions/productActions";
import { saveMarketOrderProduct } from '../../../_redux/actions/orderActions';

import Header from "../../Product/ManageColumns/Header";
import ToastrHook from "../../common/toastr/Toastr";
import { ADD_PRODUCT_EMPTY_MESSAGE, ADD_PRODUCT_TO_ORDER, FOOTER_CANCEL_BUTTON, FOOTER_SAVE_BUTTON, GLOBAL_PLEASE_SELECT_PRODUCT, Hotel365Portal, LOADING_MORE_DATA, ORDER_ADD_GLOBAL_PRODUCT, PRODUCT_NAME_ATOZ, PRODUCT_LOW_TO_HIGH, PRODUCT_SORT_BY } from "../../../constants/constant";
import AddProductButton from "../../../pages/HotelManager/ProductsPage/AddProductComponent";
import { getLocalizedNumericValues } from "../../../utils/";

export const AddProductOrderPopup: React.FC<any> = memo(
    ({ showAddPrdtModal, setButtonState }) => {
        const isMobileView = isPlatform("mobile");
        const dispatch = useDispatch();
        const [sortSelect, setSortSelect] = useState(PRODUCT_NAME_ATOZ);
        const [searchBarShow, setSearchBarShow] = useState(true);
        const [showModalBox, setShowModalBox] = useState(showAddPrdtModal);
        const [showFilterModal, setShowFilterModal] = useState(false);
        const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
        const [current, setCurrent] = useState("");
        const [saveItems, setSaveItems] = useState<any>([]);
        const [showGlobalPop, setShowGlobalPop] = useState(false)
        const [renderToastr] = ToastrHook();

        const productFilter = useSelector((state: RootState) => state.product.categoryFilters, shallowEqual);
        const productOrder = useSelector((state: RootState) => state.order, shallowEqual);
        const { fetchMarketOrderHeaders } = productOrder;
        const marketOrderHeaderId = fetchMarketOrderHeaders && fetchMarketOrderHeaders[0] && fetchMarketOrderHeaders[0].marketOrderHeaderId;
        const {
            pending,
            products,
            paginate,
            createdMarketProduct,
            error,
        } = useSelector((state: RootState) => state.product, shallowEqual);
        const profile = useSelector((state: RootState) => { return state.profile; }, shallowEqual);
        const { currentProfile } = profile;
        const { currency } = currentProfile;
        const SortArray = (x: any, y: any) => {
            const productTempX = x.name.trim().toLocaleLowerCase();
            const productTempY = y.name.trim().toLocaleLowerCase();

            if (productTempX < productTempY) {
                return -1;
            }
            if (productTempX > productTempY) {
                return 1;
            }
            return 0;
        };

        const [productList, setProductList] = useState(() => [...products.sort(SortArray)]);
        const [productIds, setProductIds] = useState<string[]>([]);

        const handleScroll = (ev: any) => {
            setTimeout(() => {
                const offset: any = paginate.nextOffset;
                dispatch(
                    FetchMarketProducts(offset)
                );
                ev.target.complete();
                if (products.length === paginate.totalCount) {
                    setInfiniteDisabled(true);
                }
            }, 500);
        };


        const resetToDefault = () => {
            setSearchBarShow(true);
            setShowModalBox(false);
            setButtonState(false);
            setInfiniteDisabled(false);
            setProductIds([]);
            setSaveItems([]);
            setSortSelect(PRODUCT_NAME_ATOZ);
            dispatch(FetchGlobalProductsCategories("", 0));

        };
        const onCloseModal = () => {
            resetToDefault();
        };

        const onHeaderButtonClick = () => {
            setCurrent("ADD PRODUCT")
            setShowGlobalPop(true)
        }
        useEffect(() => {
            if (productList.length !== products.length) {
                setProductList(() => [...products.sort(SortArray)]);
            }
            if (
                products.length !== 0 &&
                products.length === paginate.totalCount
            ) {
                setInfiniteDisabled(true);
            }
            if (showAddPrdtModal) {
                setShowModalBox(showAddPrdtModal);
            }
            if (error && Object.keys(error).length > 0 && productIds?.length > 0) {
                setProductIds([]);
                setSaveItems([])
            }
        }, [
            createdMarketProduct,
            error,
            productList,
            products,
            showAddPrdtModal,
            productFilter,
            setProductList,
            onHeaderButtonClick,
        ]);

        const sortCostDscOrder = products?.sort(
            (a: any, b: any) => a.cost - b.cost
        );

        const sortByPrice = (args: any) => {
            if (args.detail.value == PRODUCT_NAME_ATOZ) {
                setProductList(productList.sort(SortArray));
                setSortSelect(args.detail.value);
            } else {
                sortCostDscOrder && setProductList([...sortCostDscOrder]);
                setSortSelect(args.detail.value);
            }
        };

        useEffect(() => {
            if (sortSelect === PRODUCT_LOW_TO_HIGH) {
                sortByPrice({ detail: { value: PRODUCT_LOW_TO_HIGH } })
            }
        }, [productList, sortSelect])

        const onSave = () => {
            if (productIds?.length === 0) {
                renderToastr(GLOBAL_PLEASE_SELECT_PRODUCT, "warning");
                return false;
            }
            dispatch(saveMarketOrderProduct(saveItems))
            resetToDefault()
        };

        const addProductIds = (productId: string) => {
            setProductIds((prevProductIds) => [
                ...prevProductIds,
                productId
            ]);
        }

        const addSaveItems = ((items: object) => {
            setSaveItems((prevItems: any) => [
                ...prevItems,
                items,
            ])
        })

        const onProductSelect = useCallback(
            (product) => {
                if (productIds.length === 0) {
                    addProductIds(product.productId)
                    addSaveItems(product)
                }
                else {
                    const isElementPresent = productIds.some(
                        (id: string) => id === product.productId
                    );
                    if (!isElementPresent) {
                        addProductIds(product.productId)
                        addSaveItems(product)
                    } else {
                        setProductIds(prevProductIds => (
                            prevProductIds.filter((value: string) => value !== product.productId)
                        ));
                        setSaveItems((prevProductItems: any) => (
                            prevProductItems.filter((value: any) => value.productId !== product.productId)
                        ))
                    }
                }
            },
            [setProductIds, productIds, setSaveItems, saveItems]
        );
        return (
            <>
                <IonModal
                    isOpen={showModalBox}
                    className={isMobileView ? "modal-pop-products-mobile" : "modal-pop-products"}
                    onDidDismiss={onCloseModal}
                >
                    <IonContent
                        style={{
                            visibility: !showFilterModal ? "visible" : "hidden",
                        }}
                    >
                        <IonHeader className="header">
                            <Header
                                headerTitle={ADD_PRODUCT_TO_ORDER}
                                onCloseModal={onCloseModal}
                                filterIcon={false}
                            />
                        </IonHeader>
                        {searchBarShow ? (
                            <>
                                <IonRow className="modal-body">
                                    <IonCol sizeXs="8" sizeSm="9">
                                        <IonLabel>{PRODUCT_SORT_BY}</IonLabel>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12">
                                        <IonSelect
                                            interface="popover"
                                            value={sortSelect}
                                            onIonChange={(e) => {
                                                sortByPrice(e);
                                            }}
                                            className="sort-dropdown"
                                        >
                                            <IonSelectOption value={PRODUCT_NAME_ATOZ}>
                                                {PRODUCT_NAME_ATOZ}
                                            </IonSelectOption>
                                            <IonSelectOption value={Hotel365Portal.HIGH_TO_LOW_VELOCITY}>
                                                {PRODUCT_LOW_TO_HIGH}
                                            </IonSelectOption>
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>
                            </>
                        ) : ''
                        }
                        <IonRow className="items-scroll">
                            <IonCol className="item-list click-cursor">
                                {pending && (
                                    <IonSpinner name="circles" className="spinner-circles"
                                    />
                                )}
                                {!pending && productList.length === 0 && (
                                    <p className="no-products">
                                        {ADD_PRODUCT_EMPTY_MESSAGE}
                                    </p>
                                )}
                                {!pending &&
                                    productList.length > 0 &&
                                    productList.map((res: any, i: number) => {
                                        const { productLocationId, name, cost, productId } = res;
                                        const productItem = {
                                            productId,
                                            productLocationId,
                                            productName: name,
                                            marketOrderHeaderId,
                                            price: cost,
                                        }
                                        return (<IonItem
                                            key={i}
                                            lines="full"
                                            color={
                                                productIds.indexOf(res.productId) > -1 ? "light"
                                                    : ""
                                            }
                                            onClick={() =>
                                                onProductSelect(productItem)
                                            }
                                            className="click-cursor"
                                        >
                                            <IonLabel className="product-item ion-text-wrap">
                                                {res.name}
                                            </IonLabel>
                                            <IonLabel slot="end" className="product-prise">
                                                {getLocalizedNumericValues(res.cost, "currency", currency)}
                                            </IonLabel>
                                        </IonItem>)
                                    })}

                                <IonInfiniteScroll
                                    onIonInfinite={handleScroll}
                                    threshold="100px"
                                    disabled={isInfiniteDisabled}
                                >
                                    <IonInfiniteScrollContent
                                        loadingSpinner="bubbles"
                                        loadingText={LOADING_MORE_DATA}
                                    ></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                        <IonFooter className="footer">
                            <IonRow>
                                <IonCol sizeMd="6">
                                    <AddProductButton
                                        onHeaderButtonClick={onHeaderButtonClick}
                                        className="btn-text-cases"
                                        current={current}
                                        showGlobalPop={showGlobalPop}
                                        setShowGlobalPop={setShowGlobalPop}
                                        buttonLabel={ORDER_ADD_GLOBAL_PRODUCT}
                                        buttonClassName='add-product-global-btns'
                                    />
                                </IonCol>
                                <IonCol sizeMd="3">
                                    <IonButton
                                        color="light"
                                        expand="block"
                                        className="btn-text-cases"
                                        onClick={onCloseModal}
                                    >
                                        {FOOTER_CANCEL_BUTTON}
                                    </IonButton>
                                </IonCol>
                                <IonCol sizeMd="3">
                                    <IonButton
                                        color="primary"
                                        expand="block"
                                        type="submit"
                                        className="btn-text-cases"
                                        onClick={onSave}
                                    >
                                        {FOOTER_SAVE_BUTTON}
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonFooter>
                    </IonContent>
                </IonModal>
            </>
        );
    }
);
