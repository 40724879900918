//vendors
import { all, put, takeLatest } from 'redux-saga/effects';
//types
import * as REPORTS from '../../ActionTypes/reportsTypes';
//custom wrapper
import { doPost } from '../../../utils/fetchWrapper';
//actions
import { postReportSuccess, postReportFailure } from '../../actions/reportsActions';
//constants
import { Routes } from '../../../constants/Routes';

function* postReport(action: any) {
    const { filterObject, reportType } = action;
    const dateRangeDates = {
        end: filterObject.end,
        start: filterObject.start
    };
    let url = `${Routes.reports.url}/${reportType}/buildReport`;
    try {
        // @ts-ignore
        const response = yield doPost(url, filterObject);
        yield put(postReportSuccess(response, dateRangeDates));
    } catch (err: any) {
        yield put(postReportFailure(err));
    }
}

function* ReportsSaga() {
    yield all([
        takeLatest(REPORTS.POST_REPORT_REQUEST, postReport)
    ])
}

export default ReportsSaga;