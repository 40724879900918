// vendors
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useStep } from "react-hooks-helper";
import {
    IonCol,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
    isPlatform,
    IonSpinner,
    IonAlert,
} from "@ionic/react";
// components
import LocationForm from "../../components/SettingsProcess/SettingsForms/LocationForm";
import AdditionalInfoForm from "../../components/SettingsProcess/SettingsForms/AdditionalInfoForm/AdditionalInfoForm";
import ColorLogoForm from "../../components/SettingsProcess/SettingsForms/ColorLogoForm/ColorLogoForm";
import FooterComponent from "../../components/FooterComponent";
import ToastrHook from "../../components/common/toastr/Toastr";
//constants
import {
    Hotel365Portal,
    GLOBAL_SETTING,
    SETTING_ADDITIONAL_INFO,
    SETTING_LOGO_COLOR_SCHEMA,
    SETTING_MARKET_INFO,
    DETECT_UNSAVE_HEADER,
    DETECT_UNSAVE_MESSAGE,
    FOOTER_CANCEL_BUTTON,
    GLOBAL_OK_BUTTON,
    SETTING_UPDATED_TOST_MSG,
    GLOBAL_NO_CHANGES_TO_SAVE,
} from '../../constants/constant';
//actions
import { patchProfile, resetProfileUpdation } from "../../_redux/actions/profileActions";
//reducers
import { RootState } from "../../_redux/reducers/rootReducer";

import RouteLeavingGuard from "../../components/RouteLeavingGuard";
import { useHistory } from "react-router-dom";
import { getRoleId } from "../../utils/roleBasedAccess";

interface useStepType {
    step: any;
    navigation: any;
    complete?: any;
}
export const Settings: React.FC = () => {
    const dispatch = useDispatch();
    const { push } = useHistory();
    const [renderToastr] = ToastrHook();
    const systemUser = useSelector((state: RootState) => {
        return state.systemUsers;
    }, shallowEqual);
    const { roles } = systemUser;
    const roleId = getRoleId(roles);
    const profile = useSelector((state: RootState) => {
        return state.profile;
    }, shallowEqual);
    const { currentProfile, isProfileUpdationSuccess } = profile;
    const [onboarding, setOnboarding] = useState({ ...currentProfile });
    const [formErrors, setFormErrors] = useState({
        isValid: true,
        errorMessage: "",
    });
    const [isFormSubmitted, setFormSubmitted] = useState(false);
    const [sidenavExpanded, setSidenavExpanded] = useState<boolean>(true);
    const [activeItem, setActiveItem] = useState<string>("market-info");
    const isdesktopview = isPlatform("desktop");
    const isTabletView = isPlatform("tablet");
    const isMobileView = isPlatform("mobile");
    const [isTabClicked, setTabClicked] = useState(false);
    const steps = [
        {
            id: "market-info",
            Component: LocationForm,
            name: "market Info",
        },
        {
            id: "additional-info",
            Component: AdditionalInfoForm,
            name: "Additional Details",
        },
        {
            id: "logo-color",
            Component: ColorLogoForm,
            name: "Logo and Color Schema",
        },
    ];
    const findActiveTab = (activeTab: string) => {
        return activeTab === "additional-info" ? 1 : activeTab === "logo-color" ? 2 : 0
    }
    const fetchInitialStep = JSON.parse(localStorage.getItem("activeComponent") as string)
    const { step, navigation }: useStepType = useStep({
        initialStep: findActiveTab(fetchInitialStep),
        steps,
    });
    const { go } = navigation;
    const { id, props, Component } = step;

    const detectUnsavedChanges = () => {
        return JSON.stringify(currentProfile) !== JSON.stringify(onboarding);
    };

    const submitHandler = () => {
        setFormSubmitted(true);
        const areValuesChanged = detectUnsavedChanges();
        const payload = { ...onboarding };
        delete payload["pending"];
        delete payload["errors"];
        delete payload["onboardingStep"];
        const { isValid } = formErrors;
        if (isValid && areValuesChanged) {
            dispatch(patchProfile(payload.locationId, payload));
        }
    };
    const goToTab = (route: React.SetStateAction<string>) => {
        setActiveItem(route);
        setTabClicked(true);
        if (!detectUnsavedChanges()) {
            setTabClicked(false);
            go(route);
        }
        !isdesktopview && !isTabletView && setSidenavExpanded((ps) => !ps);
    };

    useEffect(() => {
        let errorMessage = "";
        const { errors } = currentProfile;
        const { isValid } = formErrors;
        if (isFormSubmitted) {
            if (errors && Object.keys(errors).length > 0) {
                if (errors.status === 401) {
                    errorMessage = Hotel365Portal.NOT_AUTHORIZED_ERR;
                } else {
                    errorMessage = Hotel365Portal.SERVICE_UNAVAILABLE_ERR;
                }
                renderToastr(errorMessage, "danger");
            }
            else if (isValid && JSON.stringify(currentProfile) === JSON.stringify(onboarding)) {
                renderToastr(GLOBAL_NO_CHANGES_TO_SAVE, "warning");
            }
            setFormSubmitted(false);
        }
        if (isProfileUpdationSuccess) {
            renderToastr(SETTING_UPDATED_TOST_MSG, "success");
            goToTab(fetchInitialStep || "market-info")
            dispatch(resetProfileUpdation());
        }
    }, [isFormSubmitted, currentProfile, onboarding, isProfileUpdationSuccess]);

    useEffect(() => {
        setOnboarding({ ...currentProfile });
    }, [currentProfile]);
    return (
        <IonPage>
            <IonRow className="h-100">
                {sidenavExpanded &&
                    <IonCol
                        size="1"
                        size-md={sidenavExpanded ? '3.1' : '0'}
                        size-lg="2"
                        size-xs={sidenavExpanded ? "12" : '0'}
                        size-sm={sidenavExpanded ? "12" : '0'}
                        className="settings-sidenav ion-no-padding">
                        <IonItem lines="inset" color="none" className="ion-no-padding ion-margin-top">
                            <IonLabel className="header-text marginLeft10 ion-margin-bottom">{GLOBAL_SETTING}</IonLabel>
                        </IonItem>
                        <IonItem
                            className={`${step.id === "market-info" ? "settings-item-active" : "settings-item"} click-cursor`}
                            onClick={() => { localStorage.setItem("activeComponent", JSON.stringify("market-info")); goToTab("market-info") }}>
                            <IonLabel className="settings-label font14 ion-margin-bottom">{SETTING_MARKET_INFO}</IonLabel>
                        </IonItem>
                        {roleId &&
                            roleId.length > 0 &&
                            roleId.find((value: string) => value === "8") && (
                                <>
                                    <IonItem
                                        className={`${step.id === "additional-info" ? "settings-item-active" : "settings-item"} click-cursor`}
                                        onClick={() => { localStorage.setItem("activeComponent", JSON.stringify("additional-info")); goToTab("additional-info") }}>
                                        <IonLabel className="settings-label font14 ion-margin-bottom">{SETTING_ADDITIONAL_INFO}</IonLabel>
                                    </IonItem>
                                    <IonItem
                                        className={`${step.id === "logo-color" ? "settings-item-active" : "settings-item"} click-cursor`}
                                        onClick={() => { localStorage.setItem("activeComponent", JSON.stringify("logo-color")); goToTab("logo-color") }}>
                                        <IonLabel className="settings-label font14 ion-margin-bottom">{SETTING_LOGO_COLOR_SCHEMA}</IonLabel>
                                    </IonItem>
                                </>
                            )}
                    </IonCol>
                }
                <IonCol
                    size={!sidenavExpanded ? "12" : "11"}
                    size-lg={!sidenavExpanded ? "12" : "10"}
                    size-md={!sidenavExpanded ? "12" : "8.9"}
                    size-xs="12"
                    size-sm="12"
                    sizeXs={!sidenavExpanded ? "12" : '0'}>
                    {onboarding.pending ? (<IonSpinner name="circles" className="spinner-circles" />) :
                        (<Component
                            {...props}
                            roleId={roleId}
                            setFormErrors={setFormErrors}
                            setSidenavExpanded={setSidenavExpanded}
                            sidenavExpanded={sidenavExpanded}
                            setOnboarding={setOnboarding}
                            formErrors={formErrors}
                            onboarding={onboarding}
                            {...onboarding}
                        />
                        )}
                </IonCol>
                {(isMobileView && !sidenavExpanded || isTabletView || isdesktopview) && <FooterComponent submitCallBack={submitHandler} />}
                <RouteLeavingGuard
                    when={detectUnsavedChanges()}
                    navigate={(path: string) => {
                        push(path);
                        setOnboarding({ ...currentProfile });
                    }}
                    shouldBlockNavigation={() => {
                        if (detectUnsavedChanges()) {
                            return true;
                        }
                        return false;
                    }}
                />
                {/** block tab navigation for unsaved changes*/}
                <IonAlert
                    isOpen={isTabClicked && detectUnsavedChanges()}
                    cssClass="guard-restrict"
                    header={DETECT_UNSAVE_HEADER}
                    message={DETECT_UNSAVE_MESSAGE}
                    buttons={[
                        {
                            text: FOOTER_CANCEL_BUTTON,
                            role: "cancel",
                            cssClass: "btn-text-cases cancel-btn",
                            id: "cancel-button",
                            handler: () => setTabClicked(false),
                        },
                        {
                            text: GLOBAL_OK_BUTTON,
                            id: "confirm-button",
                            cssClass: "btn-text-cases confirm-btn",
                            handler: () => {
                                go(activeItem);
                                setTabClicked(false);
                                setFormErrors({ isValid: true, errorMessage: "" })
                                setOnboarding({ ...currentProfile });
                            },
                        },
                    ]}
                />
            </IonRow>
        </IonPage>
    );
};

export default Settings;