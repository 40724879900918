import PaymentProps from "../constants";

const Visa: React.FC<PaymentProps> = (props) => {
  return (
    <svg
      width={(32 / 21) * props.size}
      height={props.size}
      viewBox="0 0 32 21"
    >
      <path
        d="M26.58,21L2.42,21A2.4,2.4 0,0 1,0 18.62L0,4.38A2.4,2.4 0,0 1,2.42 2h24.16A2.4,2.4 0,0 1,29 4.38v14.25A2.4,2.4 0,0 1,26.58 21z"
        fill="#F6F9FC"
      />
      <path
        d="M0,18v0.63A2.4,2.4 0,0 0,2.42 21h24.16A2.4,2.4 0,0 0,29 18.62L29,18L0,18z"
        fill="#F99F1B"
      />
      <path
        d="M0,5v-0.63A2.4,2.4 0,0 1,2.42 2h24.16A2.4,2.4 0,0 1,29 4.38L29,5L0,5zM13.9,8.12l-1.48,6.77h-1.77l1.46,-6.77h1.78zM21.37,12.5l0.94,-2.55 0.54,2.55h-1.48zM23.36,14.9L25,14.9l-1.44,-6.78h-1.51a0.8,0.8 0,0 0,-0.76 0.5l-2.67,6.27h1.87l0.37,-1h2.28l0.22,1zM18.72,12.68c0,-1.78 -2.51,-1.88 -2.5,-2.68 0.01,-0.24 0.25,-0.5 0.76,-0.57a3.4,3.4 0,0 1,1.75 0.3l0.31,-1.43c-0.42,-0.15 -0.97,-0.3 -1.66,-0.3 -1.76,0 -3,0.92 -3,2.24 -0.01,0.97 0.88,1.52 1.55,1.84 0.7,0.33 0.93,0.55 0.93,0.84 -0.01,0.46 -0.56,0.66 -1.07,0.66 -0.9,0.02 -1.41,-0.23 -1.82,-0.42l-0.33,1.48c0.42,0.19 1.19,0.35 1.98,0.36 1.87,0 3.09,-0.9 3.1,-2.32zM11.35,8.12L8.47,14.9L6.6,14.9L5.18,9.5c-0.09,-0.33 -0.17,-0.45 -0.43,-0.6A7.53,7.53 0,0 0,3 8.33l0.04,-0.2h3.03c0.38,0 0.73,0.26 0.82,0.7l0.75,3.91 1.85,-4.6h1.86z"
        fill="#2D4990"
      />
    </svg>
  );
}

export default Visa;
