// vendors
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {
    IonSpinner,
    IonCol,
    IonContent,
    IonGrid,
    IonRow,
    isPlatform,
} from "@ionic/react";
import { useLocation } from "react-router";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// reducers
import { RootState } from "../../_redux/reducers/rootReducer";
// constants
import { Routes } from "../../constants/Routes";
import {
    SYSTEMUSERS_FETCH_ERROR,
    USER_PROFILES_FETCH_ERROR,
    NO_PROFILES_ASSOCIATED_WITH_USER,
    ONBOARDING_IS_NOT_ALLOWED_FOR_ROLE,
    INVALID_ROLES_OR_USER_VALIDITY_EXPIRED,
    SideNavItems,
    PortalType,
} from "../../constants/constant";
import { portalType } from "../../auth.config";
// components
import Header from "../Header";
import Sidenav from "../Sidenav";
import ToastrHook from "../../components/common/toastr/Toastr";
import ErrorComponent from "../../components/ErrorComponent";
import SpinningLoader from "../../components/common/loader/SpinningLoader";
//actions
import { resetPOS } from "../../_redux/actions/posActions";
//descriptors
import { getDescriber } from "./Descriptor/DescriptorType";
import { PortalContext } from "../..";
//utils
import { getDefaultItem } from "../../utils";
import LoaderWithMessage from "../../components/common/loader/LoaderWithMessage";

export const Dashboard: React.FC<any> = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { pathname } = location;
    const { user, logout } = useAuth0();
    const { currentProfilePending = true } = props;
    const [renderToastr] = ToastrHook();
    const systemUserId = user && user["https://365rm.us/systemUserId"];
    const errorReducer = useSelector((state: RootState) => { return state.error; }, shallowEqual);
    const { error: sysUserAndProfileError } = errorReducer;
    const [sysUserAndProfileErr, setSysUserAndProfileErr] = useState(false);
    const profile = useSelector((state: RootState) => { return state.profile; }, shallowEqual);
    const { currentProfile } = profile;
    const { isSelfOperated } = currentProfile;

    const [onboarding, setOnboarding] = useState(currentProfile);
    const { pending, onboardingStep } = onboarding;
    const [activeNavItem, setActiveNavItem] = useState<string>(pathname);
    const isdesktopview = isPlatform("desktop") || isPlatform("tablet");
    const [mQuery, setMQuery] = React.useState<any>({ matches: isdesktopview ? true : false });
    const { doLogout } = props;
    const _portalTypes = useContext(PortalContext) as any;
    const { routeDescriptor } = getDescriber(_portalTypes);

    const setNavItemActiveOnClick = useCallback((route) => {
        setActiveNavItem(route.toLowerCase());

        dispatch(resetPOS());
        history.push({ pathname: `/dashboard/${route}` });
    }, []);

    const selfOperatedSideNavItems = useMemo(() => {
        if (isSelfOperated && _portalTypes === PortalType.HP) {
            return SideNavItems.filter(item => item.name !== 'orders')
        }
        return SideNavItems;
    }, [isSelfOperated, SideNavItems]);

    const renderDashboard = () => {
        const { errorMessage } = sysUserAndProfileError || {};
        if (sysUserAndProfileErr) {
            return (
                <>
                    <Header
                        systemUserId={systemUserId}
                        buildLogoutUrl={logout}
                        doLogout={doLogout}
                    />
                    <Sidenav
                        mQuery={mQuery}
                        setNavItemActiveOnClick={setNavItemActiveOnClick}
                        activeNavItem={activeNavItem}
                        sideNavItems={selfOperatedSideNavItems}
                    />
                    <ErrorComponent
                        message={errorMessage}
                        tryAgain={true}
                        tryAgainCallBack={() => {
                            window.location.reload();
                        }}
                    />
                </>
            );
        } else {
            return (
                <>
                    {currentProfilePending ? (
                        <IonSpinner
                            name="circles"
                            className="spinner-circles"
                        />
                    ) : (
                        <>
                            <Header
                                systemUserId={systemUserId}
                                buildLogoutUrl={logout}
                                doLogout={doLogout}
                            />
                            <IonContent fullscreen>
                                <IonGrid className="ion-no-border dashboard-ig-main ion-no-padding">
                                    <IonRow className="dashboard-ig-height">
                                        <Sidenav
                                            mQuery={mQuery}
                                            setNavItemActiveOnClick={setNavItemActiveOnClick}
                                            activeNavItem={activeNavItem}
                                            sideNavItems={selfOperatedSideNavItems}
                                        />
                                        <IonCol className="ion-no-padding">
                                            {routeDescriptor()}
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonContent>
                        </>
                    )}
                </>
            );
        }
    };

    useEffect(() => {
        let mediaQuery = window.matchMedia("(min-width: 768px)");
        mediaQuery.addEventListener("change", setMQuery);
        return () => mediaQuery.removeEventListener("change", setMQuery);
    }, []);

    useEffect(() => {
        const type = sysUserAndProfileError && sysUserAndProfileError.type;
        const errorMessage = sysUserAndProfileError && sysUserAndProfileError.errorMessage;
        if (type === INVALID_ROLES_OR_USER_VALIDITY_EXPIRED ||
            type === NO_PROFILES_ASSOCIATED_WITH_USER) {
            renderToastr(errorMessage, "danger");
            setTimeout(() => {
                doLogout();
            }, 3000);
        } else if (type === SYSTEMUSERS_FETCH_ERROR ||
            type === USER_PROFILES_FETCH_ERROR ||
            type === ONBOARDING_IS_NOT_ALLOWED_FOR_ROLE) {
            setSysUserAndProfileErr(true);
        } else if (portalType === PortalType.HP && !pending && onboardingStep !== -1) {
            history.push({ pathname: `${Routes.onboarding.url}` });
        }
    }, [pending, onboardingStep, sysUserAndProfileError]);

    // set current market profile of logged in user
    useEffect(() => {
        portalType === PortalType.HP && setOnboarding(currentProfile);
    }, [currentProfile]);

    useEffect(() => {
        const tab = pathname.slice(pathname.lastIndexOf("/") + 1);
        setActiveNavItem(getDefaultItem(tab, _portalTypes) as string);
    }, [activeNavItem, pathname, location]);

    return renderDashboard();
};

export default withAuthenticationRequired(
    Dashboard, {}
);
