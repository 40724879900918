//vendors
import React, { useCallback } from "react";
import { IonCol, IonLabel, IonRow, isPlatform } from "@ionic/react"
//modals
import type { LocationToOnboardProps } from "../../models/SignupProps";
// components
import Dropdown from "../common/Dropdown";
//constant
import { MARKET_NAME_HEADER } from "../../constants/constant";

const SelectLocationToOnboard: React.FC<LocationToOnboardProps> = ({ toBeOnboardProfiles = [], selected = '', onChangeHandler }) => {
  const ismobile = isPlatform('mobile');
  const istablet = isPlatform('tablet');
  
  const onIonChange = useCallback((locationId: string) => {
    onChangeHandler(locationId);
  }, [])

  return (
    <IonRow className="ion-padding-start verticle-center" style={{ marginTop: (ismobile || istablet) ? '25%' : '' }}>
      <IonCol>
        <IonLabel>{MARKET_NAME_HEADER}</IonLabel>
        <Dropdown
          options={toBeOnboardProfiles}
          selected={selected}
          label='marketName'
          value={'locationId'}
          onChangeHandler={onIonChange}
        />
      </IonCol>
    </IonRow>
  )
}
export default SelectLocationToOnboard;