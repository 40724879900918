import PaymentProps from "../constants";

const Amex: React.FC<PaymentProps> = (props) => {
  return (
    <svg
      width={(32 / 21) * props.size}
      height={props.size}
      viewBox="0 0 32 21"
    >
      <path
        d="M26.58,21L2.42,21A2.4,2.4 0,0 1,0 18.62L0,4.38A2.4,2.4 0,0 1,2.42 2h24.16A2.4,2.4 0,0 1,29 4.38v14.25A2.4,2.4 0,0 1,26.58 21z"
        fill="#1D91CE"
      />
      <path
        d="M4.063,13.896l-0.472,1.104l-4.231,0l3.339,-7l7.975,0l0.805,1.546l0.752,-1.546l2.833,0l1.428,0l6.491,0l0.919,0.961l0.987,-0.961l4.993,0l-3.545,3.492l3.392,3.508l-4.832,0l-1.081,-1.031l-1.072,1.031l-6.252,0l-1.428,0l-9.568,0l-0.519,-1.104"
        fill="#FFFFFF"
      />
      <path
        d="M4.98,13.97h-0.92,0.92zM15.2,9h-2.1l-1.58,3.35L9.82,9h-2.1v4.85L5.55,9L3.58,9l-2.32,5h1.42l0.47,-1.14h2.7L6.39,14L9,14L9,9.93L10.85,14h1.22l1.84,-4v4h1.29L15.2,9zM23.87,10.62L22.37,9h-6.02v5L22.17,14l1.65,-1.64L25.48,14h1.55l-2.37,-2.53L27.1,9h-1.62l-1.61,1.62zM20.7,13h-3.06L17.64,11.9h3.06L20.7,10.9h-3.06L17.64,10h3.06v-0.85l2.27,2.27 -2.27,2.28L20.7,13zM4.53,9.82l0.88,2.03L3.58,11.85l0.95,-2.03z"
        fill="#1D91CE"
      />
    </svg>
  );
}

export default Amex;
