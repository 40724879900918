import { useAuth0 } from '@auth0/auth0-react'; 
import { IonButton, IonCol, IonRow, IonText } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { Hotel365Portal, PortalType as SysPortalAppType } from '../../constants/constant';
import { Routes } from '../../constants/Routes';
import { portalType } from '../../auth.config';

const LoginButton: React.FC = () => {
    const {push} = useHistory()
    const { loginWithRedirect } = useAuth0();

  return( 
      <>
          <IonRow style={{textAlign:"right"}}> 
              <IonCol size="12">
                  <IonButton onClick={() => push(Routes.forgotPassword.url)} fill="outline" color="light">{Hotel365Portal.FORGOT_PWD_QUESTION}</IonButton>
                  <IonButton onClick={() => loginWithRedirect()} color="secondary" className='login-spaces'>{Hotel365Portal.GLOBAL_SIGN_IN}</IonButton>
              </IonCol>
              <IonCol size="12" className={portalType === SysPortalAppType.HP ? "pv-terms-text" : "cp-pv-terms-text"}>
                  <IonText color="light" className={portalType === SysPortalAppType.HP ? "pv-text" : "cp-pv-text"}><a href="https://365retailmarkets.com/consumer-policy" target="_blank" >{Hotel365Portal.LOGIN_PRIVACY_TEXT} / {Hotel365Portal.LOGIN_TERM}</a></IonText>
              </IonCol>
          </IonRow>
      </>
  )
};

export default LoginButton;
