import React from 'react';
//vendors
import { memo, useCallback, useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import CurrencyInput from "react-currency-input-field";
import {
    IonModal,
    IonButton,
    IonContent,
    IonRow,
    IonCol,
    IonHeader,
    IonFooter,
    IonInput,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    isPlatform,
    IonNote,
    IonGrid,
    IonAlert,
} from "@ionic/react";

import {
    IgrDataGrid,
    IgrDataGridCellEventArgs,
    IgrTemplateColumn,
    IgrTextColumn,
    IIgrCellTemplateProps,
} from "igniteui-react-grids";
import { trashOutline, removeOutline, addOutline } from "ionicons/icons";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
//reducer
import { RootState } from "../../../_redux/reducers/rootReducer";
import { saveSelfOperatedProduct } from "../../../_redux/actions/productActions";
//components
import Header from "../../../components/Product/ManageColumns/Header";
import Footer from "../../../components/Product/ManageColumns//Footer";
import ToastrHook from "../../../components/common/toastr/Toastr";
//constants
import {
    ADD,
    mapper,
    Hotel365Portal,
    ADD_NEW_PRODUCT,
    DETECT_UNSAVE_HEADER,
    DETECT_UNSAVE_MESSAGE,
    FOOTER_CANCEL_BUTTON,
    FOOTER_SAVE_BUTTON,
    GLOBAL_OK_BUTTON,
    PRODUCT_BELOW_MAX,
    PRODUCT_BELOW_MIN,
    SELF_OPERATED_PRICE,
    SELF_OPERATED_REQUIRED_FIELD
} from "../../../constants/constant";
//utils
import { checkAmount, getLocalizedNumericValues, restrictSplChar } from '../../../utils'
import getSymbolFromCurrencyCode from "../../../i18n/currency-symbol-map";

const columnMappingList = {
    category: mapper.category1,
    upc: mapper.scancode,
    product: mapper.name,
    min: mapper.minStock,
    max: mapper.maxStock,
    refillActionOthr: mapper.refillAction,
    cost: mapper.cost,
    price: SELF_OPERATED_PRICE,
    delete: "",
};
export const AddSelfOperated: React.FC<any> = memo(
    ({ showAddPrdtModal, setButtonState, setShowGlobalPop, showGlobalPop }) => {
        const dispatch = useDispatch();
        const originalColList = { ...columnMappingList } as any;
        const [showModalBox, setShowModalBox] = useState(showAddPrdtModal);
        const [state, setState] = useState({
            category: null,
            upc: null,
            product: "",
            price: "",
            cost: "",
            min: 0,
            max: 0,
            refillAction: "BELOW_MAXIMUM",
            refillActionOthr: PRODUCT_BELOW_MAX
        });
        const [selfProducts, setSelfProducts] = useState([]);
        const ismobileview = isPlatform("mobile");
        const [renderToastr] = ToastrHook();
        const isDesktop = isPlatform("desktop");
        const istablet = isPlatform("tablet");
        const [isCloseClicked, setIsCloseClicked] = useState(false);
        const { globalProducts, error, selfOperatedProducts } =
            useSelector((state: RootState) => state.product, shallowEqual);
        const profile = useSelector((state: RootState) => {
            return state.profile;
        }, shallowEqual);
        const { currentProfile } = profile;
        const { currency } = currentProfile;

        const resetToDefault = () => {
            setShowModalBox(false);
            setButtonState(false);
            setSelfProducts([]);
            resetForm();
        };
        const onCloseModal = (event: CustomEvent<OverlayEventDetail>) => {
            selfProducts.length === 0 && resetToDefault();
            setIsCloseClicked(true)
        };
        const checkCreateAndExtendProduct = useCallback(() => {
            return (
                selfOperatedProducts && Object.keys(selfOperatedProducts).length > 0
            );
        }, [selfOperatedProducts]);

        useEffect(() => {
            if (showAddPrdtModal) {
                setShowModalBox(showAddPrdtModal);
            }

            if (showGlobalPop) {
                setShowGlobalPop(false);
            }
            if (
                checkCreateAndExtendProduct()
            ) {
                resetToDefault();
            }
        }, [
            selfOperatedProducts,
            error,
            globalProducts,
            showAddPrdtModal,
        ]);

        const onSave = () => {
            const reqObject = selfProducts.map((item: Record<string, any>) => {
                const obj = {
                    locationId: currentProfile.locationId,
                    orgId: currentProfile.orgId,
                    category1: item.category,
                    price: Number(item.cost.substr(1)),
                    scancode: item.upc,
                    maxStock: item.max,
                    minStock: item.min,
                    productName: item.product,
                    refillAction: item.refillAction,
                    hasModifier: false,
                    isDisabled: false,
                } as any;
                if (item.price) {
                    obj['specificPrice'] = Number(item.price.substr(1));
                    obj["pricingOption"] = "SPECIFIC_PRICE";
                }
                return obj;
            });
            dispatch(saveSelfOperatedProduct(reqObject));
        };

        const restrictAlphabets = (e: any) => {
            const code = e.nativeEvent.which || e.nativeEvent.keycode;
            if (code >= 48 && code <= 57) {
                const result =
                    e.target.selectionStart === 0 &&
                        e.target.selectionEnd === e.target.value.length
                        ? e.key
                        : [
                            e.target.value.slice(0, e.target.selectionStart),
                            e.key,
                            e.target.value.slice(e.target.selectionStart),
                        ].join("");
            } else {
                e.preventDefault();
                return false;
            }
        };

        const refillActionHandle = (e: any) => {
            setState((prevState) => ({
                ...prevState,
                ["refillAction"]: e.target.value,
            }));
        };
        const checkUPCExists = (obj: Record<string, any>) =>
            obj.upc === state.upc;

        const onSubmit = () => {
            if (
                !state.category ||
                !state.upc ||
                !state.product ||
                !state.cost
            ) {
                renderToastr(Hotel365Portal.SELF_OPERATED_FILED_REQ_TOST_MSG, "danger");
                return false;
            }
            if (selfProducts.some(checkUPCExists)) {
                renderToastr(Hotel365Portal.upcAlreadyExists, "danger");
                return false;
            }
            if (state.min > state.max) {
                renderToastr(Hotel365Portal.MIN_SHOULD_NOT_BE_GREATER_MSG, "danger");
                return false;
            }
            if (state.min === 0 && state.max > 0) {
                renderToastr(Hotel365Portal.MAX_SHOULD_NOT_BE_SET_MSG, "danger");
                return false;
            }
            if ((['0', '0.00'].indexOf(state.price) > -1) || (['0', '0.00'].indexOf(state.cost) > -1)) {
                renderToastr(Hotel365Portal.emptyPriceOrCost, "danger");
                return false;
            }
            if ((state.price && checkAmount(state.price, 2)) || checkAmount(state.cost, 2)) {
                renderToastr(Hotel365Portal.digitPriceOrCost, "danger");
                return false;
            }
            state.price = state.price ? getLocalizedNumericValues(state.price, "currency", currency) : '';
            state.cost = state.cost ? getLocalizedNumericValues(state.cost, "currency", currency) : state.cost;
            state.refillActionOthr = state.refillAction === 'BELOW_MAXIMUM' ? PRODUCT_BELOW_MAX : PRODUCT_BELOW_MIN;
            setSelfProducts((oldProducts) => [...oldProducts, state] as any);
            setIsCloseClicked(false)
            resetForm();
        };

        const resetForm = () => {
            setState({
                category: null,
                upc: null,
                product: "",
                price: "",
                cost: "",
                min: 0,
                max: 0,
                refillAction: "BELOW_MAXIMUM",
                refillActionOthr: PRODUCT_BELOW_MAX
            });
        };

        const orderMin = (type: string) => {
            if (type === "minus") {
                if (state.min > 0)
                    setState((prevState) => ({
                        ...prevState,
                        ["min"]: Number(prevState.min - 1) as any,
                    }));
                else return false;
            } else {
                setState((prevState) => ({
                    ...prevState,
                    ["min"]: Number(prevState.min + 1) as any,
                }));
            }
        };

        const orderMax = (type: string) => {
            if (type === "minus") {
                if (state.max > 0)
                    setState((prevState) => ({
                        ...prevState,
                        ["max"]: Number(prevState.max - 1) as any,
                    }));
                else return false;
            } else {
                setState((prevState) => ({
                    ...prevState,
                    ["max"]: Number(prevState.max + 1) as any,
                }));
            }
        };
        const selectedRow = (gridData: any, event: IgrDataGridCellEventArgs) => {
            if (gridData._implementation.dm?.c === "delete") {
                const filtered = selfProducts.filter(function (
                    el: Record<string, any>
                ) {
                    return el.upc != event.cellInfo.rowItem.upc;
                });
                setSelfProducts(filtered);
            }
        };
        const selectedColumnsTextColor = (i: string) => {
            return ["delete"].indexOf(i) > -1
                ? Hotel365Portal.defaultPrimaryColor
                : Hotel365Portal.gridTextValueColorCode;
        };

        const renderOtherColumns = (i: string) => {
            return (
                <IgrTextColumn
                    field={i}
                    key={i}
                    horizontalAlignment="center"
                    headerText={originalColList[i] || i}
                    isFilteringEnabled={false}
                    isColumnOptionsSummariesEnabled={false}
                    isEditable={false}
                    textColor={selectedColumnsTextColor(i)}
                    name={i}
                />
            );
        };

        const customDelete = (props: IIgrCellTemplateProps) => {
            return (
                <div className="user-delete">
                    <IonIcon icon={trashOutline} size="large"></IonIcon>
                </div>
            );
        };

        const renderDeleteIcon = (i: string) => {
            return (
                <IgrTemplateColumn
                    field={i}
                    key={i}
                    horizontalAlignment="center"
                    template={(props: IIgrCellTemplateProps) =>
                        customDelete(props)
                    }
                    width="80"
                    headerText={""}
                    isFilteringEnabled={false}
                    isColumnOptionsSummariesEnabled={false}
                    isColumnOptionsEnabled={false}
                    name={i}
                />
            );
        };

        const renderCols = () => {
            const arr = [];
            for (let i in columnMappingList) {
                arr.push(
                    ["delete"].indexOf(i) > -1
                        ? renderDeleteIcon(i)
                        : renderOtherColumns(i)
                );
            }
            return arr;
        };

        const detectUnsavedChanges = () => {
            return selfProducts.length > 0;
        };
        return (
            <>
                <IonModal
                    isOpen={showModalBox}
                    className={
                        ismobileview
                            ? "self-operated-mobileview-modal-pop"
                            : "self-operated-modal-pop"
                    }
                    onDidDismiss={onCloseModal}
                    backdropDismiss={false}
                >
                    <IonContent
                        style={{
                            visibility: "visible",
                        }}
                    >
                        <IonHeader className="header">
                            <Header
                                headerTitle={ADD_NEW_PRODUCT}
                                onCloseModal={onCloseModal}
                                filterIcon={false}
                            />
                        </IonHeader>
                        <form className="form-body-email">
                            <IonGrid>
                                <IonRow className={ismobileview && !istablet ? 'self-operated-required-section' : 'self-operated-required-section'} >
                                    <IonNote
                                        color="medium"
                                        className="location-tab"
                                    >
                                        <b className="ml-5">*{SELF_OPERATED_REQUIRED_FIELD}</b>
                                    </IonNote>
                                </IonRow>
                                <IonRow className="marketname-textbox items-scroll self-products">

                                    <IonCol size="2" sizeMd="2" sizeSm="6" sizeXs="12" className={ismobileview ? "mb-25" : ''}>
                                        <IonNote
                                            color="medium"
                                            className="location-tab"
                                        >
                                            <b>{mapper.category1}*</b>
                                        </IonNote>
                                        <IonInput
                                            className="setting-form-field self-operated-text-input-size"
                                            name={`category`}
                                            id={`category`}
                                            maxlength={30}
                                            value={state.category}
                                            onIonChange={(e) => {
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    ["category"]: e.target
                                                        .value as any,
                                                }));
                                            }}
                                        />
                                    </IonCol>
                                    <IonCol size="2" sizeMd="2" sizeSm="6" sizeXs="12" className={ismobileview ? "mb-25" : ''}>
                                        <IonNote
                                            color="medium"
                                            className="location-tab"
                                        >
                                            <b>{mapper.scancode}*</b>
                                        </IonNote>
                                        <IonInput
                                            className="setting-form-field self-operated-text-input-size"
                                            name={`upc`}
                                            id={`upc`}
                                            maxlength={25}
                                            value={state.upc}
                                            onIonChange={(e) => {
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    ["upc"]: e.target
                                                        .value as any,
                                                }));
                                            }}
                                            onKeyPress={(e) => {
                                                restrictSplChar(e);
                                            }}
                                        />
                                    </IonCol>
                                    <IonCol size="2" sizeMd="2" sizeSm="6" sizeXs="12" className={ismobileview ? "mb-25" : ''}>
                                        <IonNote
                                            color="medium"
                                            className="location-tab"
                                        >
                                            <b>{mapper.name}*</b>
                                        </IonNote>
                                        <IonInput
                                            className="setting-form-field self-operated-text-input-size"
                                            name={`product`}
                                            id={`product`}
                                            maxlength={30}
                                            value={state.product}
                                            onIonChange={(e) => {
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    ["product"]: e.target
                                                        .value as any,
                                                }));
                                            }}
                                        />
                                    </IonCol>
                                    <IonCol size="1" sizeMd="1" sizeSm="6" sizeXs="12" className={ismobileview ? "mb-25" : ''}>
                                        <IonNote
                                            color="medium"
                                            className="location-tab"
                                        >
                                            <b>{mapper.cost}*</b>
                                        </IonNote>
                                        <CurrencyInput
                                            name={`cost`}
                                            id={`cost`}
                                            value={state.cost}
                                            allowNegativeValue={false}
                                            className={`setting-form-field self-operated-text-input-size self-operated-currency-fields sc-ion-input-md-h sc-ion-input-md-s md self-operated-product-current`}
                                            prefix={getSymbolFromCurrencyCode(currency)}
                                            step={1}
                                            decimalsLimit={2}
                                            decimalScale={2}
                                            onValueChange={(value) => {
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    ["cost"]: value as any,
                                                }));
                                            }}
                                        />
                                    </IonCol>
                                    <IonCol size="1" sizeMd="1" sizeSm="6" sizeXs="12" className={ismobileview ? "mb-25" : ''}>
                                        <IonNote
                                            color="medium"
                                            className="location-tab"
                                        >
                                            <b>{SELF_OPERATED_PRICE}</b>
                                        </IonNote>
                                        <CurrencyInput
                                            name={`price`}
                                            id={`price`}
                                            value={state.price}
                                            allowNegativeValue={false}
                                            className={`setting-form-field self-operated-text-input-size self-operated-currency-fields sc-ion-input-md-h sc-ion-input-md-s md self-operated-product-current`}
                                            prefix={getSymbolFromCurrencyCode(currency)}
                                            step={1}
                                            decimalsLimit={2}
                                            decimalScale={2}
                                            onValueChange={(value) => {
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    ["price"]: value as any,
                                                }));
                                            }}
                                        />
                                    </IonCol>
                                    <IonCol size="1" sizeMd="1" sizeSm="6" sizeXs="3">
                                        <IonNote
                                            color="medium"
                                            className="location-tab"
                                        >
                                            <b>{mapper.minStock}</b>
                                        </IonNote>
                                        <IonRow className="setting-form-field pos-checkout-item-names-row self-operated-qty">
                                            <IonCol className="product-qty-buttons">
                                                <IonIcon
                                                    icon={removeOutline}
                                                    color="primary"
                                                    onClick={() =>
                                                        orderMin("minus")
                                                    }
                                                />
                                            </IonCol>
                                            <IonCol className="pos-checkout-qty-input self-operated-pdt-qty-input">
                                                <IonInput
                                                    name={`min`}
                                                    id={`min`}
                                                    value={state.min}
                                                    className="self-product-qty"
                                                    defaultValue={state.min}
                                                    onIonChange={(e) => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                ["min"]: Number(
                                                                    e.target
                                                                        .value as any
                                                                ),
                                                            })
                                                        );
                                                    }}
                                                    onKeyPress={(e) => {
                                                        restrictAlphabets(e);
                                                    }}
                                                    disabled
                                                >
                                                    {state.min}
                                                </IonInput>
                                            </IonCol>
                                            <IonCol className="product-qty-buttons">
                                                <IonIcon
                                                    icon={addOutline}
                                                    color="primary"
                                                    onClick={() =>
                                                        orderMin("plus")
                                                    }
                                                />
                                            </IonCol>
                                        </IonRow>
                                    </IonCol>
                                    <IonCol size="1" sizeMd="1" sizeSm="6" sizeXs="3">
                                        <IonNote
                                            color="medium"
                                            className="location-tab"
                                        >
                                            <b>{mapper.maxStock}</b>
                                        </IonNote>
                                        <IonRow className="setting-form-field pos-checkout-item-names-row self-operated-qty">
                                            <IonCol className="product-qty-buttons">
                                                <IonIcon
                                                    icon={removeOutline}
                                                    color="primary"
                                                    onClick={() =>
                                                        orderMax("minus")
                                                    }
                                                />
                                            </IonCol>
                                            <IonCol className="pos-checkout-qty-input self-operated-pdt-qty-input">
                                                <IonInput
                                                    name={`max`}
                                                    id={`max`}
                                                    value={state.max}
                                                    className="self-product-qty"
                                                    defaultValue={state.max}
                                                    onIonChange={(e) => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                ["max"]: Number(
                                                                    e.target
                                                                        .value
                                                                ) as any,
                                                            })
                                                        );
                                                    }}
                                                    onKeyPress={(e) => {
                                                        restrictAlphabets(e);
                                                    }}
                                                    disabled
                                                >
                                                    {state.max}
                                                </IonInput>
                                            </IonCol>
                                            <IonCol className="product-qty-buttons">
                                                <IonIcon
                                                    icon={addOutline}
                                                    color="primary"
                                                    onClick={() =>
                                                        orderMax("plus")
                                                    }
                                                />
                                            </IonCol>
                                        </IonRow>
                                    </IonCol>
                                    <IonCol size="2" sizeMd="2" sizeSm="6" sizeXs="12" className={ismobileview ? "mb-25" : ''}>
                                        <IonNote
                                            color="medium"
                                            className="location-tab"
                                        >
                                            <b>{mapper.refillAction}</b>
                                        </IonNote>
                                        <IonSelect
                                            interface="popover"
                                            value={state.refillAction}
                                            onIonChange={refillActionHandle}
                                            className="sort-dropdown self-products-select"
                                            aria-label={mapper.refillAction}
                                        >
                                            <IonSelectOption value="BELOW_MAXIMUM" aria-label={PRODUCT_BELOW_MAX}>
                                                {PRODUCT_BELOW_MAX}
                                            </IonSelectOption>
                                            <IonSelectOption value="AT_OR_BELOW_MINIMUM" aria-label={PRODUCT_BELOW_MIN}>
                                                {PRODUCT_BELOW_MIN}
                                            </IonSelectOption>
                                        </IonSelect>
                                    </IonCol>
                                    <IonCol size="2" sizeMd="2" sizeSm="6" sizeXs="12" className={ismobileview ? "btncenter mb-25" : 'btncenter'}>
                                        <IonButton
                                            color="primary"
                                            expand="block"
                                            type="button"
                                            className="btn-text-cases self-operated-btn-width"
                                            onClick={onSubmit}
                                        >
                                            {ADD}
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>

                            {selfProducts.length > 0 && (
                                <IonRow
                                    className={
                                        isDesktop || istablet
                                            ? "grid-container mt-60"
                                            : "mobile-grid-container mt-60"
                                    }
                                    key="grid-container"
                                >
                                    <IgrDataGrid
                                        height="180px"
                                        width="100%"
                                        defaultColumnMinWidth={100}
                                        autoGenerateColumns={false}
                                        dataSource={selfProducts}
                                        selectionMode="SingleCell"
                                        activationMode="Cell"
                                        editMode="CellBatch"
                                        cellClicked={(
                                            s: IgrDataGrid,
                                            e: IgrDataGridCellEventArgs
                                        ) => selectedRow(s, e)}
                                        isColumnOptionsEnabled="true"
                                        headerBackground="#635D5D"
                                        headerTextColor="#ffffff"
                                        autoAcceptEdits={false}
                                        headerTextStyle="Normal Normal 12px Roboto"
                                        cellTextStyle="Normal Normal 14px Roboto"
                                        columnMovingMode={"NONE"}
                                    >
                                        {renderCols()}
                                    </IgrDataGrid>
                                </IonRow>
                            )}
                        </form>
                        <IonFooter className="footer">
                            <Footer
                                onCloseModal={onCloseModal}
                                onSave={onSave}
                                isSaveDisabled={detectUnsavedChanges() ? false : true}
                                primaryBtnName={FOOTER_SAVE_BUTTON}
                                isSelfOperated={true}
                            />
                        </IonFooter>
                        <IonAlert
                            isOpen={isCloseClicked && detectUnsavedChanges()}
                            cssClass="guard-restrict"
                            header={DETECT_UNSAVE_HEADER}
                            message={DETECT_UNSAVE_MESSAGE}
                            buttons={[
                                {
                                    text: FOOTER_CANCEL_BUTTON,
                                    role: "cancel",
                                    cssClass: "btn-text-cases cancel-btn",
                                    id: "cancel-button",
                                    handler: () => setIsCloseClicked(false),
                                },
                                {
                                    text: GLOBAL_OK_BUTTON,
                                    id: "confirm-button",
                                    cssClass: "btn-text-cases confirm-btn",
                                    handler: () => {
                                        setIsCloseClicked(true);
                                        setTimeout(() => {
                                            resetToDefault();
                                        }, 1000);
                                    },
                                },
                            ]}
                        />
                    </IonContent>
                </IonModal>
            </>
        );
    }
);
