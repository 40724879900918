//vendors
import React, { useState } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { IonCol, IonRow, IonInput, IonItem, IonNote, IonButton, IonLabel, IonIcon, isPlatform } from "@ionic/react";
//components
import FooterComponent from "../FooterComponent";
import OnboardingLeftSections from "../OnboardingProcess/SignupLeftSection";
// constants
import { Hotel365Portal, onBoardingLeftSection, GLOBAL_SUBMIT_TEXT } from "../../constants/constant";
import { eye, eyeOff } from "ionicons/icons";


const CreatePasswordForm: React.FC<any> = (props) => {
    const { loginWithRedirect } = useAuth0();
    const { basicInfo, } = onBoardingLeftSection;
    const { email, onSubmitHandler, showRedirection, formErrors } = props;

    let [isShowNewPassword, isSetShowNewPassword] = useState(false);
    let [isShowCnfrmPassword, isSetShowCnfrmPassword] = useState(false);
    const isdesktop = isPlatform("desktop");
    const ismobile = isPlatform("mobile");
    const istablet = isPlatform("tablet");

    const togglePassword = (toggleProps: string) => {
        if (toggleProps === "newPwd") {
            isSetShowNewPassword(!isShowNewPassword)
        }
        if (toggleProps === "cnfrmPwd") {
            isSetShowCnfrmPassword(!isShowCnfrmPassword)
        }
    }

    return (
        <IonRow className={`h-100 ${ismobile ? 'scroll-content' : ''}`}>
            <OnboardingLeftSections
                colSize={ismobile || istablet && !isdesktop ? '12' : '6'}
                title={basicInfo.adminTitle} />
            <IonCol sizeLg='6' className={ismobile || istablet && !isdesktop ? 'create-password-tab' : ''}>
                {showRedirection ?
                    <IonRow className='ion-padding-start verticle-center'>
                        <IonCol>
                            {Hotel365Portal.CREATE_PWD_SET_MSG}
                            <IonButton className='ion-padding-start' onClick={() => loginWithRedirect()} color="primary">{Hotel365Portal.GLOBAL_SIGN_IN}</IonButton>
                        </IonCol>
                    </IonRow> :
                    <><IonRow className={`ion-padding-start ${isdesktop ? 'token-exp-tab' : ''}`}>
                        <IonCol>
                            <IonNote color="medium">{Hotel365Portal.GLOBAL_EMAIL}</IonNote>
                        </IonCol>
                    </IonRow>
                        <IonRow className='ion-padding-start'>
                            <IonItem lines="none">{email || ''}</IonItem>
                        </IonRow>
                        <IonRow className='ion-padding-start crete-password-mb'>
                            <IonCol>
                                <IonNote color="medium">{Hotel365Portal.RESET_NEW_PWD}</IonNote>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ion-padding-start'>
                            <IonCol>
                                <IonItem lines="none" className={isdesktop || istablet ? "create-password-item-input" : 'create-password-item-input-mobile'}>
                                    <IonInput
                                        aria-label={Hotel365Portal.RESET_NEW_PWD}
                                        name="newPassword"
                                        onIonChange={props.onChangeHandler}
                                        type={isShowNewPassword ? "text" : "password"} />
                                    <IonIcon className="ion-eye-icon" icon={isShowNewPassword ? eyeOff : eye} size="medium" onClick={() => togglePassword("newPwd")} />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ion-padding-start'>
                            <IonCol>
                                <IonLabel className="red-class">{formErrors.newPassword}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ion-padding-start crete-password-mb'>
                            <IonCol><IonNote color="medium">{Hotel365Portal.RESET_CNFR_PWD}</IonNote></IonCol>
                        </IonRow>
                        <IonRow className='ion-padding-start'>
                            <IonCol>
                                <IonItem lines="none" className={isdesktop || istablet ? "create-password-item-input" : 'create-password-item-input-mobile'}>
                                    <IonInput
                                        aria-label={Hotel365Portal.RESET_CNFR_PWD}
                                        name="confirmPassword"
                                        onIonChange={props.onChangeHandler}
                                        type={isShowCnfrmPassword ? "text" : "password"} />
                                    <IonIcon className="ion-eye-icon" icon={isShowCnfrmPassword ? eyeOff : eye} size="medium" onClick={() => togglePassword("cnfrmPwd")} />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ion-padding-start'>
                            <IonCol>
                                <IonLabel className="red-class">{formErrors.confirmPassword}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ion-padding-start'>
                            <IonCol><IonNote color="white" className="create-pass-validation-heading">{Hotel365Portal.GLOBAL_PASSWORD_MUST_TEXT}</IonNote></IonCol>
                        </IonRow>
                        <IonRow className='ion-padding-start create-pass-validation'>
                            <IonLabel>{Hotel365Portal.GLOBAL_PASSWORD_MUST_HAVE_8CH_TEXT}</IonLabel>
                            <IonLabel>{Hotel365Portal.GLOBAL_PASSWORD_SPCL_CHR}</IonLabel>
                            <IonLabel>{Hotel365Portal.GLOBAL_PASSWORD_UPR_CASE}</IonLabel>
                            <IonLabel>{Hotel365Portal.GLOBAL_PASSWORD_LWR_CASE}</IonLabel>
                            <IonLabel>{Hotel365Portal.GLOBAL_PASSWORD_ONE_NUMBER}</IonLabel>
                        </IonRow>
                        <IonRow className='ion-padding-start'>
                            <IonCol><IonNote color="white" className="create-pass-validation-heading"> {Hotel365Portal.GLOBAL_PASSWORD_MUST_NOT_TEXT}</IonNote></IonCol>
                        </IonRow>
                        <IonRow className='ion-padding-start create-pass-validation'>
                            <IonLabel>{Hotel365Portal.GLOBAL_PASSWORD_WHITE_SPACE}</IonLabel>
                            <IonLabel>{Hotel365Portal.GLOBAL_PASSWORD_ONLY_ONE_CHR}</IonLabel>
                            <IonLabel>{Hotel365Portal.GLOBAL_PASSWORD_CONSECUTIVE_CHR}</IonLabel>
                        </IonRow></>
                }
                {!showRedirection && <IonRow>
                    <IonCol className="ion-no-padding">
                        <FooterComponent
                            submitCallBack={onSubmitHandler}
                            footerRowClassName={isdesktop ? 'token-expired-footer' : ''}
                            footerRowMobileClassName={ismobile || istablet && !isdesktop ? 'token-expired-mobile' : ''}
                            footerColClassName='onboarding-footer-col'
                            buttonLabel={GLOBAL_SUBMIT_TEXT}
                        />
                    </IonCol>
                </IonRow>}
            </IonCol>
        </IonRow>
    )
}

export default CreatePasswordForm;