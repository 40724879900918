import { all, put, takeLatest, select, call } from "redux-saga/effects";
import { doGet, doPost, doPatch, doDelete } from "../../../utils/fetchWrapper";
import {
    fetchConsumerListSuccess,
    fetchConsumerListFailure,
    postConsumerBulkActionSuccess,
    postConsumerBulkActionFailure,
    fetchPayrollGrpSuccess,
    fetchPayrollGrpFailure,
    fetchSubsidyGrpSuccess,
    fetchSubsidyGrpFailure,
    postConsumerResendEmailFailure,
    postConsumerResendEmailSuccess,
    postConsumerAddFundsFailure,
    postConsumerAddFundsSuccess,
    fetchConsumerBalancesFailure,
    fetchConsumerBalancesSuccess,
    fetchConsumerIdentifiersFailure,
    fetchConsumerIdentifiersSuccess,
    postNewConsumerSuccess,
    postNewConsumerFailure,
    deleteConsumerScanCodeSuccess,
    deleteConsumerScanCodeFailure,
    postConsumerIdentifiersSuccess,
    postConsumerIdentifiersFailure,
    PatchConsumerSuccess,
    PatchConsumerFailure,
    postConsumerSubsidyGrpSuccess,
    postConsumerSubsidyGrpFailure
} from "../../actions/consumerActions";
import { Routes } from "../../../constants/Routes";
import * as CONSUMER from "../../ActionTypes/consumerTypes";

function* fetchConsumerLists(action: any) {
    const { data } = action;
    const request = { ...data };
    let url = `${Routes.consumer.consumerListApiUrl}`;
    try {
        // @ts-ignore
        const response = yield doPost(url, request);
        yield put(fetchConsumerListSuccess(response));
    } catch (e: any) {
        yield put(fetchConsumerListFailure(e));
    }
}

function* postConsumerBulkPin(action: any) {
    const { info } = action;
    const request = { ...info };
    let url = `${Routes.consumer.bulkActionApiUrl}`;
    try {
        // @ts-ignore
        const response = yield doPost(url, request);
        yield put(postConsumerBulkActionSuccess(response));
    } catch (error: any) {
        yield put(postConsumerBulkActionFailure(error));
    }
}

function* fetchPayrollGrps(action: any) {
    const { currentProfile } = yield select((state) => { return state.profile});
    let { locationId } = currentProfile;
    if(action.locationId){
        locationId  = action.locationId;
    }
    
    // @ts-ignore
    let url = `${Routes.consumer.payrollGrpApiUrl}${locationId}`;
    try {
        // @ts-ignore
        const response = yield doGet(url);
        yield put(fetchPayrollGrpSuccess(response.res));
    } catch (e: any) {
        yield put(
            fetchPayrollGrpFailure(e)
        );
    }
}

function* fetchSubsidyGrps(action: any) {
    const { currentProfile } = yield select((state) => { return state.profile});
    let { locationId } = currentProfile;
    if(action.locationId){
        locationId  = action.locationId;
    }
    // @ts-ignore
    let url = `${Routes.consumer.subsidyGrpApiUrl}${locationId}`;
    try {
        // @ts-ignore
        const response = yield doGet(url);
        yield put(fetchSubsidyGrpSuccess(response.res));
    } catch (e: any) {
        yield put(
            fetchSubsidyGrpFailure(e)
        );
    }
}

function* fetchConsumerBalances(action: any) {
    const { accountId } = action; // made changes here
    // @ts-ignore
    let url = `${Routes.consumer.consumerDetailApiUrl}/${accountId}/balances`;
    try {
        // @ts-ignore
        const response = yield doGet(url);
        yield put(fetchConsumerBalancesSuccess(response.res));
    } catch (error: any) {
        yield put(fetchConsumerBalancesFailure(error));
    }
}

function* fetchConsumerIdentifier(action: any) {
    const { accountId, identityType } = action; // made changes here
    // @ts-ignore
    let url = `${Routes.consumer.consumerDetailApiUrl}/${accountId}/identifiers?type=${identityType}`;
    try {
        // @ts-ignore
        const response = yield doGet(url);
        yield put(fetchConsumerIdentifiersSuccess(response.res, identityType));
    } catch (error: any) {
        yield put(fetchConsumerIdentifiersFailure(error));
    }
}

function* fetchConsumerIdentifiers(request: any) {
    yield all(
        request.identityTypes.map((file: any) => call(fetchConsumerIdentifier, {accountId:request.accountId,identityType:file}))
    );
}

function* postConsumerResendEmail(action: any) {
    const { accountId, info } = action;
    const request = { ...info };
    let url = `${Routes.consumer.consumerDetailApiUrl}/${accountId}/email-update`;
    try {
        // @ts-ignore
        const response = yield doPost(url, request);
        yield put(postConsumerResendEmailSuccess(response));
    } catch (error: any) {
        yield put(postConsumerResendEmailFailure(error));
    }
}

function* postConsumerAddFunds(action: any) {
    const { info } = action;
    const request = { ...info };
    let url = `${Routes.consumer.addFundsApiUrl}`;
    try {
        // @ts-ignore
        const response = yield doPost(url, request);
        yield put(postConsumerAddFundsSuccess(response));
    } catch (error: any) {
        yield put(postConsumerAddFundsFailure(error));
    }
}

function* postNewConsumer(action: any) {
    const { info } = action;
    const {pantryGroupId} = info;
    delete info.pantryGroupId;
    const request = { ...info };
    let url = `${Routes.consumer.consumerDetailApiUrl}`;
    try {
        // @ts-ignore
        const response = yield doPost(url, request);
        if(pantryGroupId){
            yield call(PostConsumerSubsidyGrp, {
                accountId: response?.accountId,
                pantryGroupId,
            });
        }
        yield put(postNewConsumerSuccess(response));
    } catch (error: any) {
        yield put(postNewConsumerFailure(error));
    }
}


function* removeConsumerScanCode(info: any) {
    const { accountId, accountIdentifierId } = info;
    // @ts-ignore
    let url = `${Routes.consumer.consumerDetailApiUrl}/${accountId}/identifiers/${accountIdentifierId}`;
    try {
        // @ts-ignore
        const response = yield doDelete(url);
        yield put(deleteConsumerScanCodeSuccess(response));
    } catch (e: any) {
        yield put(deleteConsumerScanCodeFailure(e));
    }
}

function* removeConsumerScanCodes(request: any) {
    yield all(
        request.info.map((file: any) => call(removeConsumerScanCode, file))
    );
}


function* PostConsumerIdentifier(action: any) {
    const { accountId } = action;
    delete action.accountId;
    const request = { ...action };
    let url = `${Routes.consumer.consumerDetailApiUrl}/${accountId}/identifiers`;
    try {
        // @ts-ignore
        const response = yield doPost(url, request);
        yield put(postConsumerIdentifiersSuccess(response));
    } catch (error: any) {
        yield put(postConsumerIdentifiersFailure(error));
    }
}

function* PostConsumerIdentifiers(request: any) {
    yield all(
        request.info.map((file: any) => call(PostConsumerIdentifier, file))
    );
}

function* patchConsumer(request: any) {
    const { info } = request;
    const {accountId, payrollId, removedScancodes, addedScancodes, emailUpdate, pantryGroupId} = info;
    delete info?.payrollId;
    delete info?.removedScancodes;
    delete info?.addedScancodes;
    delete info?.emailUpdate;
    delete info?.accountId;
    delete info?.pantryGroupId;
    try {
        // @ts-ignore
        const response = yield doPatch(
            `${Routes.consumer.consumerDetailApiUrl}/${accountId}`,
            info
        );
        if(response && payrollId && Object.keys(payrollId).length>0){
            yield call(PostConsumerIdentifiers, {
                info:[payrollId]
            });
        }
        if(response && removedScancodes && removedScancodes.length>0){
            yield call(removeConsumerScanCodes, {
                info:removedScancodes
            });
        }
        if(response && addedScancodes && addedScancodes.length>0){
            yield call(PostConsumerIdentifiers, {
                info:addedScancodes
            });
        }
        if(response && emailUpdate && Object.keys(emailUpdate).length>0){
            yield call(postConsumerResendEmail, {
                accountId,
                info:emailUpdate
            });
        }
        if(response && pantryGroupId){
            //used fpr change subsidygrp
            yield call(PostConsumerSubsidyGrp, {
                accountId,
                pantryGroupId,
            });
        }
        yield put(PatchConsumerSuccess(response));
    } catch (error: any) {
        yield put(PatchConsumerFailure(error));
    }
}

function* PostConsumerSubsidyGrp(info: any) {
    const {accountId} = info;
    delete info.accountId;
    try {
        // @ts-ignore
        const response = yield doPost(
            `${Routes.consumer.addSubsidyGrpUrl}/${accountId}/change-subsidy-group`,
            info
        );
        yield put(postConsumerSubsidyGrpSuccess(response));
    } catch (error: any) {
        yield put(postConsumerSubsidyGrpFailure(error));
    }
}

function* ConsumerSaga() {
    yield all([
        takeLatest(CONSUMER.FETCH_CONSUMER_LISTS_REQUEST, fetchConsumerLists),
        takeLatest(CONSUMER.POST_BULK_ACTION_REQUEST, postConsumerBulkPin),
        takeLatest(CONSUMER.FETCH_PAYROLLGRP_REQUEST, fetchPayrollGrps),
        takeLatest(CONSUMER.FETCH_SUBSIDYGRP_REQUEST, fetchSubsidyGrps),
        takeLatest(CONSUMER.FETCH_CONSUMER_BALANCES_REQUEST, fetchConsumerBalances),
        takeLatest(CONSUMER.FETCH_CONSUMER_IDENTIFIERS_REQUEST, fetchConsumerIdentifiers),
        takeLatest(CONSUMER.POST_RESEND_EMAIL_REQUEST, postConsumerResendEmail),
        takeLatest(CONSUMER.POST_ADD_FUNDS_REQUEST, postConsumerAddFunds),
        takeLatest(CONSUMER.POST_NEW_CONSUMER_REQUEST, postNewConsumer),
        takeLatest(
            CONSUMER.DELETE_CONSUMER_SCAN_CODE,
            removeConsumerScanCodes
        ),
        takeLatest(
            CONSUMER.POST_IDENTIFIERS_REQUEST,
            PostConsumerIdentifiers
        ),
        takeLatest(
            CONSUMER.PATCH_CONSUMER_REQUEST,
            patchConsumer
        ),
        takeLatest(
            CONSUMER.POST_SUBSIDYGRP_REQUEST,
            PostConsumerSubsidyGrp
        ),
    ]);
}

export default ConsumerSaga;
