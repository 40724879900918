import { DateTime } from "luxon";
import XLSX from 'sheetjs-style';
//constants
import {
    today,
    thisWeek,
    lastMonth,
    thisMonth,
    todayDate,
    yesterday,
    last7Days,
    endOfWeek,
    minus6Days,
    last30Days,
    last60Days,
    minus29Days,
    minus59Days,
    startOfWeek,
    passPattern,
    customRange,
    currentMonth,
    productsales,
    previousMonth,
    yesterdayDate,
    endOfThisMonth,
    endOfLastMonth,
    Hotel365Portal,
    startOfLastMonth,
    startOfThisMonth,
    onboardingStepIds,
    passWordValidationMsg,
    spaceDashAlphaCharRegx,
    currentandPreviousMonth,
    soldDetails,
    dailySalesSummary,
    badScanReport,
    inventoryAdjustmentDetail,
    itemStockout,
    productPricing,
    salesItemDetails,
    salesSummaryAndCost,
    salesTimeDetails,
    reportProductPricingColumns,
    dataSource,
    CUSTOM_TIME_RANGE_ERR,
    MARKET_NAME_REQ_ERR,
    MARKET_NAME_VALID_ERR,
    MARKET_NAME_ALLOWS_AS_HYPHENS_ERR,
    ONBOARDING_COLOR_THEME_LOGOURL_ERR,
    reportColumnsAppendWithDollar,
    reportColumnsAppendWithPercentage,
    ClientPortal,
    PortalType,
    HotelPortal,
    ClientPortalItems,
    RefillActionOptions,
    LAST_INVENTORY,
    LAST_INVENTORY_NA,
    creatbleUserRolesForHp,
    creatbleUserRolesForCp
} from "../../src/constants/constant";
import envChange from "./changeEnvironment";
import { SaveGlobalProduct } from './../models/SaveGlobalProduct';

export const getQuestionsForToggle = (
    questionariesValues: any,
    questionaries: any
) => {
    return (
        Object.keys(questionaries) as (keyof typeof questionaries)[]
    ).reduce((acc, key) => {
        return {
            ...acc,
            [key]: { ...questionaries[key], checked: questionariesValues[key] },
        };
    }, questionaries);
};

export const isZipValid = (value: any) => {
    if (value === "" || value === null) {
        return { isValid: false, errorMessage: Hotel365Portal.ZIPCODE_REQUIRED_ERR };
    } else if (isNaN(value)) {
        return { isValid: false, errorMessage: Hotel365Portal.ZIPCODE_DIGIT_ERR }
    } else if (value.length > 5 || value.length < 5) {
        return { isValid: false, errorMessage: Hotel365Portal.ZIPCODE_INVALID_ERR };
    } else {
        return { isValid: true, errorMessage: "" };
    }
};

export const isMarketNameValid = (value: any) => {
    if (value === '' || value === null) {
        return { isValid: false, errorMessage: MARKET_NAME_REQ_ERR };
    } else if (value.length > 50) {
        return { isValid: false, errorMessage: MARKET_NAME_VALID_ERR };
    } else if (!value.match(spaceDashAlphaCharRegx.pattern)) {
        return { isValid: false, errorMessage: MARKET_NAME_ALLOWS_AS_HYPHENS_ERR };
    } else {
        return { isValid: true, errorMessage: '' };
    }
}

export const onboardingValidations = (id: string, onboardingObject: Record<string, any>) => {
    switch (id) {
        case onboardingStepIds.zipCode:
            return isZipValid(onboardingObject.zip);
        case onboardingStepIds.locationProfile:
            return isMarketNameValid(onboardingObject.marketName);
        default:
            return { isValid: true, errorMessage: '' };
    }
};
export const sortArrayOfObjects = (arr: any, sortOn: string) => {
    return arr.sort((x: any, y: any) => {
        if (
            x[sortOn].trim().toLocaleLowerCase() <
            y[sortOn].trim().toLocaleLowerCase()
        ) {
            return -1;
        } else if (
            x[sortOn].trim().toLocaleLowerCase() >
            y[sortOn].trim().toLocaleLowerCase()
        ) {
            return 1;
        }
        return 0;
    });
};

/**
 *Password Validation function
*/

export const passWordValidation = (password: Record<string, any>) => {
    let valMsg = null;
    if (password.password <= 8) {
        valMsg = passWordValidationMsg.passwordLength;
    } else if (!passPattern.specialPattern.test(password.password)) {
        valMsg = passWordValidationMsg.specialPattern;
    } else if (!passPattern.upperCasePattern.test(password.password)) {
        valMsg = passWordValidationMsg.upperCasePattern;
    } else if (!passPattern.numberPattern.test(password.password)) {
        valMsg = passWordValidationMsg.numberPattern;
    }
    return valMsg;
}

/*
Order name validation
*/
export const isOrderNameValid = (value: any) => {
    var blockChars = new RegExp(Hotel365Portal.orderNameRegEX);
    if (value === '') {
        return { isValid: false, errorMessage: Hotel365Portal.ORDERNAME_REQUIRED_ERR };
    } else if (
        !blockChars.test(value)
    ) {
        return { isValid: false, errorMessage: Hotel365Portal.INVALID_ORDER_NAME };
    }
    else if (value.length > 50) {
        return { isValid: false, errorMessage: MARKET_NAME_VALID_ERR };
    }
    else {
        return { isValid: true, errorMessage: '' };
    }
}

/**
    * Group by is specfic to recent orders based on date
    */
export const groupBy = (objectArray: Record<string, any>[], property: string) => {
    return objectArray.reduce(function (acc, obj) {
        let key = obj['marketOrderStatus']['status'].toLowerCase() === 'pending' ? 'Pending' :
            obj[property].substr(0, 4) ===
                new Date().getFullYear().toString()
                ? "Recent"
                : obj[property].substr(0, 4);
        if (key === 'Pending' && acc[key] && acc[key].length === 1) {
            key = 'Recent';
        }
        if (key === 'Recent' && acc[key] && acc[key].length === 5) {
            key = obj[property].substr(0, 4);
        }

        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});
};

export const getOnboardedProfiles = (profiles: Array<Record<string, any>>) => profiles.filter((item: any) => item['onboardingStep'] === -1);
export const getNeedsOnboardingProfiles = (profiles: Array<Record<string, any>>) => profiles.filter((item: any) => item['onboardingStep'] !== -1);

export const updateTwoDecimal = (num: number) => {
    const result = num.toFixed(2);
    return result.toString();
};

export const addZeroes = (num: number) => {
    let numberAsString = num.toString();
    if (
        numberAsString.indexOf(".") === -1 ||
        numberAsString.split(".")[1].length < 3
    ) {
        numberAsString = updateTwoDecimal(num);
    }
    return numberAsString.slice(0, 6);
};

export const fetchEnviorment = () => {

    if (!localStorage.getItem("env")) {
        fetch("/assets/config/env.txt")
            .then((response) => response.text())
            .then((text) => {
                envChange(text);
                localStorage.setItem("env", JSON.stringify(text));
                setTimeout(()=>{window.location.reload();},500);
                return text;
            })
            .catch((error) => console.log(error));
    } else {
        return JSON.parse(localStorage.getItem("env") as string);
    }
}

export const isSecondArraySubsetOfFirstArr = (firstArray: any, secondArray: any) => {
    return secondArray.every((value: any) => firstArray.includes(value));
}

export const isSecondArrHasSomeElementsFirstArr = (firstArray: any, secondArray: any) => {
    return secondArray.some((value: any) => firstArray.includes(value));
}
export function isValidHttpUrl(url: string) {
    if ((url.indexOf("http://") == 0 || url.indexOf("https://") == 0) || url.length === 0) {
        return { isValid: true, errorMessage: '' };
    } else {
        return { isValid: false, errorMessage: ONBOARDING_COLOR_THEME_LOGOURL_ERR };
    }
}
export const getCommaSeparatedValues = (arr: Array<any>) => {
    return arr.reduce((acc, item, index) => {
        return index === 0 ? acc : acc + ',' + item;
    }, arr[0]);
}

export const getFromToDateLbl = (rangeArray: any) => {
    const fromDate = rangeArray[0];
    const toDate = rangeArray[1];
    //return `${fromDate.toFormat('MMMM dd, yyyy')} - ${toDate.toFormat('MMMM dd, yyyy')}`
    return `${fromDate.toLocaleString(DateTime.DATE_MED)} - ${toDate.toLocaleString(DateTime.DATE_MED)}`;
}

export const getFromToDateLblForCustomRange = (rangeArray: any) => {
    const fromDate = rangeArray[0];
    const toDate = rangeArray[1];
    //return `${DateTime.fromISO(fromDate).toFormat('dd-MM-yyyy')} - ${DateTime.fromISO(toDate).toFormat('dd-MM-yyyy')}`;
    //return `${DateTime.fromISO(fromDate).toFormat('MMMM dd, yyyy')} - ${DateTime.fromISO(toDate).toFormat('MMMM dd, yyyy')}`;
    return `${DateTime.fromISO(fromDate).toLocaleString(DateTime.DATE_MED)} - ${DateTime.fromISO(toDate).toLocaleString(DateTime.DATE_MED)}`;
}

export const getFrmAndToDateFrmRange = (object: any) => {
    return {
        fromDate: object[0],
        toDate: object[1]
    }
}

export const getReportPayload = (reportType: string, filterObject: { locationId: string, endDate: string, startDate: string, category: '', orgID: string }) => {
    const { locationId, endDate, startDate, category, orgID } = filterObject;
    switch (reportType) {
        case productsales:
        case soldDetails:
            return {
                "end": endDate,
                "start": startDate,
                "org": orgID,
                "reportConfig": {
                    "locale": window.navigator.languages[0].replace("-", "_"),
                    "category": category,
                    "isHotel": true,
                },
                "location": [locationId]
            }

        case dailySalesSummary:
        case badScanReport:
        case inventoryAdjustmentDetail:
        case itemStockout:
        case salesItemDetails:
        case salesSummaryAndCost:
            return {
                "end": endDate,
                "start": startDate,
                "org": orgID,
                "reportConfig": {
                    "locale": window.navigator.languages[0].replace("-", "_"),
                    "isHotel": true,
                },
                "location": [locationId]
            }
        case productPricing:
            return {
                "end": endDate,
                "start": startDate,
                "org": orgID,
                "reportConfig": {
                    "locale": window.navigator.languages[0].replace("-", "_"),
                    "isHotel": true,
                },
                "location": [locationId]
            }
        default:
            return {
                "end": endDate,
                "start": startDate,
                "reportConfig": {
                    "locale": window.navigator.languages[0].replace("-", "_"),
                },
                "location": [
                    locationId
                ]
            }
    }

}


export const getPredefinedLblsFromCustomDates = (object: any) => {
    const { fromDate, toDate } = object;
    const convertedFrmDate = DateTime.fromJSDate(new Date(fromDate)).startOf('day').toJSDate();
    const convertedToDate = DateTime.fromJSDate(new Date(toDate)).startOf('day');
    const convertedYesterDay = yesterdayDate.startOf('day').toJSDate();
    const convertedTodayDate = todayDate.startOf('day').toJSDate();
    const convertedMinus6Days = minus6Days.startOf('day').toJSDate();
    const convertedMinus29Days = minus29Days.startOf('day').toJSDate();
    const convertedMinus59Days = minus59Days.startOf('day').toJSDate();
    const convertedStartOfWeek = startOfWeek.startOf('day').toJSDate();
    const convertedEndOfWeek = endOfWeek.startOf('day').toJSDate();
    const convertedStartOfLastMonth = startOfLastMonth.startOf('day').toJSDate();
    const convertedEndOfLastMonth = endOfLastMonth.startOf('day').toJSDate();
    const convertedStartOfThisMonth = startOfThisMonth.startOf('day').toJSDate();
    const convertedEndOfThisMonth = endOfThisMonth.startOf('day').toJSDate();
    const convertedCurrentMonth = currentMonth.startOf('day').toJSDate();
    const convertedPreviousMonth = previousMonth.startOf('day').toJSDate();
    if (+convertedFrmDate === +convertedYesterDay && +convertedToDate === +convertedYesterDay) {
        return yesterday
    } else if (+convertedFrmDate === +convertedTodayDate && +convertedToDate === +convertedTodayDate) {
        return today;
    } else if (+convertedFrmDate === +convertedMinus6Days && +convertedToDate === +convertedTodayDate) {
        return last7Days;
    } else if (+convertedFrmDate === +convertedMinus29Days && +convertedToDate === +convertedTodayDate) {
        return last30Days;
    } else if (+convertedFrmDate === +convertedMinus59Days && +convertedToDate === +convertedTodayDate) {
        return last60Days;
    } else if (+convertedFrmDate === +convertedStartOfWeek && +convertedToDate === +convertedEndOfWeek) {
        return thisWeek;
    } else if (+convertedFrmDate === +convertedStartOfLastMonth && +convertedToDate === +convertedEndOfLastMonth) {
        return lastMonth;
    } else if (+convertedFrmDate === +convertedStartOfLastMonth && +convertedToDate === +convertedEndOfLastMonth) {
        return lastMonth;
    } else if (+convertedFrmDate === +convertedStartOfThisMonth && +convertedToDate === +convertedEndOfThisMonth) {
        return thisMonth;
    } else if (+convertedFrmDate === +convertedPreviousMonth && +convertedToDate === +convertedCurrentMonth) {
        return currentandPreviousMonth;
    } else {
        return customRange
    }
}

export const getCurrentUserTime = () => {
    const now = new Date();
    const currentTime = [
        now.getFullYear(),
        "-",
        now.getMonth() + 1,
        "-",
        now.getDate(),
        " ",
        now.getHours(),
        ":",
        now.getMinutes(),
        ":",
        now.getSeconds(),
    ].join("");

    return currentTime;
}

export const getReportHeaders = (reportType: string, reportHeaders: any) => {
    switch (reportType) {
        case productPricing:
            return reportProductPricingColumns;
        default:
            return reportHeaders;
    }
}

export const validateTimeRange = (dateRange: any, fromTime: string, toTime: string) => {
    const { fromDate, toDate } = dateRange;
    if (fromDate === toDate && fromTime > toTime) {
        return { isValid: false, errorMessage: CUSTOM_TIME_RANGE_ERR };
    } else {
        return { isValid: true, errorMessage: '' };
    }
}
//maximumFractionDigits: sossible values are from 0 to 20    ,
//minimumFractionDigits: possible values are from 1 to 21
export const getLocalizedNumericValues = (
    value: any,
    style = "currency",
    currency = "USD",
    optionsObject = { maximumFractionDigits: 20, minimumFractionDigits: 2 } || null,
    lang = navigator.language) => {
    switch (style) {
        case "currency":
            return new Intl.NumberFormat(lang, { style: "currency", currency, ...optionsObject }).format(value);
        case "percent":
            return new Intl.NumberFormat(lang, { style: "percent", ...optionsObject }).format(value);
        default:
            return new Intl.NumberFormat(lang).format(value)
    }

}
export const getReportData = (reportType: string, data: any, locationId: string, marketName = '', currency: string) => {
    //@ts-ignore
    const dataSourceKey = dataSource[reportType];
    const records = data[dataSourceKey];
    if (reportType === salesTimeDetails) {
        return records ? records[locationId] : [];
    } else if (reportType === productPricing) {
        return records ? records.map((record: any) => {
            return { ...record, location: marketName }
        }) : []
    } else {
        const updatedRecords = records && records.map((record: any) => {
            let updatedRecord = { ...record };
            for (const [key, value] of Object.entries(record)) {
                if (reportColumnsAppendWithDollar.includes(key)) {
                    updatedRecord = {
                        ...updatedRecord,
                        [key]: getLocalizedNumericValues(value, "currency", currency)
                    };
                } else if (reportColumnsAppendWithPercentage.includes(key)) {
                    updatedRecord = {
                        ...updatedRecord,
                        [key]: getLocalizedNumericValues(value, "percent", currency)
                    };
                }
            }
            return updatedRecord;
        });
        return updatedRecords;
    }
};

export const getReportRecordsForExcel = (reportHeaders = {}, records = [], reportType: string) => {
    let headerKeys = Object.keys(reportHeaders) || [];
    if (reportType == productPricing) {
        headerKeys = Object.keys(reportProductPricingColumns);
    }
    const updatedRecords = records ? records.map((record: any) => {
        const recordKeys = Object.keys(record) || [];
        const keysToDelete = recordKeys.reduce((acc: any, key) => !headerKeys.includes(key) ? [...acc, key] : acc, []);
        for (let i = 0; i < keysToDelete.length; i++) {
            delete record[keysToDelete[i]];
        }
        return record;
    }) : [];
    return updatedRecords;
}

export const getProductsDataForExcel = (records = []) => {
    return records.map((record: any) => {
        const { category1, scancode, name, inv, minStock, maxStock, refillAction, cost, price, deposit } = record; // tax, tax2, tax3, tax4,
        return { "Category": category1, "UPC": scancode, "Product": name, "Inv": inv, "Min": minStock, "Max": maxStock, "Refill Action": refillAction, "Cost": cost, "Price": price} //"Deposit": deposit , "Tax Rate 1": tax, "Tax Rate 2": tax2, "Tax Rate 3": tax3, "Tax Rate 4": tax4,
    })
}

export const toExcel = (records: any[] = [], reportHeaders: any[] = [], reportName = '', origin = '', skipHeader = true, sheetName = '') => {
    const updatesRecords = records.map((record: any) => {
        delete record['$hashCode'];
        return record;
    });
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, reportHeaders);
    ws['A1'].s = {
        font: {
            bold: true,
        }
    };
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.utils.sheet_add_json(ws, updatesRecords, { origin: origin, skipHeader });
    XLSX.writeFile(wb, `${reportName}.xlsx`);
}

export const getFinalProductList = (filtered = []) => {
    return filtered.map((item: SaveGlobalProduct) => {
        item.deposit = item.deposit || item.deposit === 0 ? + addZeroes(item.deposit as number) : item.deposit;
        item.cost = item.price || item.price === 0 ? addZeroes(item.price as number) : item.price;
        item.price = item.calculatedPrice || item.calculatedPrice === 0 ? addZeroes(item.calculatedPrice) : item.calculatedPrice;

        item.tax = item.tax || item.tax === 0 ? addZeroes(Number(((item.tax as number) * 100).toFixed(2))) : item.tax;
        item.tax2 = item.tax2 || item.tax2 === 0 ? addZeroes(Number(((item.tax2 as number) * 100).toFixed(2))) : item.tax2;
        item.tax3 = item.tax3 || item.tax3 === 0 ? addZeroes(Number(((item.tax3 as number) * 100).toFixed(2))) : item.tax3;
        item.tax4 = item.tax4 || item.tax4 === 0 ? addZeroes(Number(((item.tax4 as number) * 100).toFixed(2))) : item.tax4;
        item.refillAction = readableRefillAction(item as { refillAction: string; }) || RefillActionOptions.belowMaximumLabel;
        return item;
    });
}
export const splitFirstName = (firstName: string) => {
    return firstName && firstName.length > 20 ? `${firstName.slice(0, 20)}...` : firstName;
}
export const getlastInventoryDate = (lastInventoryDate: any) => {
    const date = new Date(lastInventoryDate);
    return lastInventoryDate ? `${LAST_INVENTORY} ${date.toLocaleDateString(navigator.language)}` : `${LAST_INVENTORY_NA}`;
}

const validatePINResetCycleSubsidyGrp = (value: string, message: string, type?: string) => {
    if (!value || (type === Hotel365Portal.resetPin && value.length < 4)) {
        return { isValid: false, errorMessage: message };
    } else {
        return { isValid: true, errorMessage: "" };
    }
}

export const checkAmount = (value: string, amtlength?: number) => {
    amtlength = amtlength || 3
    return value && value.split('.')[0].length <= amtlength ? false : true;
}

const checkPaymentMethod = (value: string) => {
    return value ? false : true;
}


const validateFunds = (AmountValue: string, paymentMethod: string, amountValueMessage: string, paymentMethodMessage: string) => {
    if (AmountValue && AmountValue.split('.')[0].length <= 3 && paymentMethod) {
        return { isValid: true, amtErrorMessage: "", methdErrMessage: '' };
    } else {
        let errObj = { isValid: false, amtErrorMessage: '', methdErrMessage: '' };
        errObj['amtErrorMessage'] = checkAmount(AmountValue) ? amountValueMessage : '';
        errObj['methdErrMessage'] = checkPaymentMethod(paymentMethod) ? paymentMethodMessage : '';
        return errObj;
    }
}


export const validateConsumerActions = (actionName: string, values: Record<string, any>) => {
    switch (actionName) {
        case 'payCycleGrp':
            return validatePINResetCycleSubsidyGrp(values.payCycleGrp, ClientPortal.payCycelRequired);
        case 'subsidyGrp':
            return validatePINResetCycleSubsidyGrp(values.subsidyGrp, ClientPortal.subSidyGrpRequired);
        case 'pinReset':
            return validatePINResetCycleSubsidyGrp(values.resetPIN, ClientPortal.pinResetRequired, 'pinReset');
        case 'addFunds':
            return validateFunds(values.fundAmount, values.fundsPaymentMethod, ClientPortal.invalidAmount, ClientPortal.paymentMethodReq);
        case 'topOff':
            return validateFunds(values.topOffBalance, values.topOffPaymentMethod, ClientPortal.invalidAmount, ClientPortal.paymentMethodReq);
        default:
            return { isValid: true, errorMessage: '' };
    }

}

export const restrictAlphaAndSplChar = (e: any) => {
    const charCode = e.nativeEvent.which || e.nativeEvent.keycode;
    if (
        (charCode >= 48 && charCode <= 57) ||
        charCode == 8
    ) {
        const result =
            e.target.selectionStart === 0 &&
                e.target.selectionEnd === e.target.value.length
                ? e.key
                : [
                    e.target.value.slice(0, e.target.selectionStart),
                    e.key,
                    e.target.value.slice(e.target.selectionStart),
                ].join("");
    } else {
        e.preventDefault();
        return false;
    }
};
export const getDefaultItem = (tab: string, portalType: string) => {
    if (portalType === PortalType.HP) {
        return tab === '' ? HotelPortal.Products : tab;
    } else if (portalType === PortalType.CP) {
        return tab === '' ? ClientPortalItems.Consumers : tab;
    }
};
export const getSideNavItemClassname = (portaltype: string, activeItem: string, item: { name: string }) => {
    const { pathname } = window.location;
    if (portaltype === PortalType.HP) {
        return item.name === activeItem ? "active-nav hpsidenav-items" : "hpsidenav-items";
    } else if (portaltype === PortalType.CP) {
        const modifiedActiveNav = ['consumerList', 'consumer'].indexOf(activeItem) > -1 ? 'consumerList' : activeItem;
        return (modifiedActiveNav === item.name || pathname.includes(item.name)) ? "active-nav-item hpsidenav-items" : "cpsidenav-items";
    }
}
export const filterNavItemsOnRoles = (items: Array<Record<string, any>>, userRoleIds: string) => {
    return items.filter((item) => {
        const { roles } = item;
        return isSecondArrHasSomeElementsFirstArr(roles, userRoleIds);
    });
}

export const readableRefillAction = (item: { refillAction: string }) => {
    return item.refillAction === RefillActionOptions.atOrBelowMinimum ? RefillActionOptions.atOrBelowMinimumLabel : RefillActionOptions.belowMaximumLabel;
}
export const restrictAlphabets = (e: any) => {
    var code = e.nativeEvent.which || e.nativeEvent.keycode;
    if ((code >= 48 && code <= 57) || code === 45) {
        e.target.selectionStart === 0 && e.target.selectionEnd === e.target.value.length ? e.key
            : [
                e.target.value.slice(0, e.target.selectionStart),
                e.key,
                e.target.value.slice(e.target.selectionStart),
            ].join("");
    } else {
        e.preventDefault();
        return false;
    }
};

export const restrictSplChar = (e: any) => {
    const charCode = e.nativeEvent.which || e.nativeEvent.keycode;
    if (
        (charCode >= 48 && charCode <= 57) ||
        (charCode >= 65 && charCode <= 90) ||
        (charCode > 96 && charCode < 123) ||
        charCode == 8
    ) {
        const result =
            e.target.selectionStart === 0 &&
                e.target.selectionEnd === e.target.value.length
                ? e.key
                : [
                    e.target.value.slice(0, e.target.selectionStart),
                    e.key,
                    e.target.value.slice(e.target.selectionStart),
                ].join("");
    } else {
        e.preventDefault();
        return false;
    }
};

export const getUserRolesBasedOnPortalType = (portalType: string) => {
    switch (portalType) {
        case PortalType.HP:
            return creatbleUserRolesForHp;
        case PortalType.CP:
            return creatbleUserRolesForCp;
        default:
            return [];
    }
}

export const getSidenavItemsByMarketType = (items = [], typeOfMarket: string) => {
    return items.filter((item) => {
        const { marketTypes = [] } = item;
        //@ts-ignore
        return marketTypes.includes(typeOfMarket)
    })
}

export const getDisplayName = (data: Record<string, any>) => {
    switch (data.type) {
        case "DEFAULT":
            return 'Account';
        case 'PAYROLL':
            return 'Payroll Deduct';
        case 'ROLL_OVER':
        case 'TOP_OFF':
            return 'Subsidy';
        case 'GIFT_CARD':
            return 'Gift Card';
        case 'STRIPE':
            return 'Stripe';
        case 'USCONNECT':
            return 'USCONNECT'
    }
}

export const removeDuplicateObjects = (array:Record<string, any>, property:string) => {
    const uniqueIds = [] as Record<string, any>;
    return array.filter((element:Record<string,any>) => {
        const isDuplicate = uniqueIds.includes(element[property]);
      
        if (!isDuplicate) {
          uniqueIds.push(element[property]);
      
          return true;
        }
      
        return false;
      });  
}

export const capitalizeFirstLetter = (string:string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}