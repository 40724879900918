import PopoverListCp from "./PopoverList-cp";
import PopoverListHp from "./PopoverList-hp";
//constants
import { PortalType } from '../../../constants/constant';
//interfaces
import { MenuItems } from '../../../models/UserProfile';

export type GetDescriberType = {
    menuItems: (props: MenuItems) => React.ReactNode;
};

const typeDescriber = {
    [PortalType.HP]: PopoverListHp,
    [PortalType.CP]: PopoverListCp,
};

export function getPopOverListDescriber(model: string): GetDescriberType {
    return typeDescriber[model as keyof typeof typeDescriber];
}
