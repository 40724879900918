import { IonSegment, IonSegmentButton, IonLabel } from "@ionic/react";
import { USER_MGMT_TABLE_EDIT_USR } from "../../constants/constant";

const UserRoleSelection: React.FC<any> = ({ onRoleChangeHandler, roleId, roles, formTitle }) => {
    return (
        <IonSegment className="segment" onIonChange={(e) => onRoleChangeHandler(e)} value={roleId} disabled={formTitle === USER_MGMT_TABLE_EDIT_USR}>
            {roles.map((role: Record<string, any>) => {
                const { label, roleId: id } = role;
                return (
                    <IonSegmentButton value={id} key={id}>
                        <IonLabel className="segment-input">{label}</IonLabel>
                    </IonSegmentButton>
                )
            })}
        </IonSegment>
    )
}

export default UserRoleSelection;