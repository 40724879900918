
import * as USERMANAGEMENT from "../../ActionTypes/userManagementTypes";
import { all, put, takeLatest, select, call, take } from "redux-saga/effects";
import { doGet, doPost, doPatch, doDelete, doPut } from "../../../utils/fetchWrapper";
import {
    PostUserSuccess,
    PostUserFailure,
    FetchUsersSuccess,
    FetchUsersFailure,
    PatchUserSuccess,
    PatchUserFailure,
    DeleteUserSuccess,
    DeleteUserFailure,
    resendInviteUserSuccess,
    resendInviteUserFailure,
    fetchUserFailure,
    fetchUserSuccess,
    putUserRoleSuccess,
    putUserRoleFailure,
    fetchUserLocationSuccess,
    fetchUserLocationFailure,
    updateUserLocationFailure,
    updateUserLocationSuccess,
    updateUserOrganizationFailure,
    updateUserOrganizationSuccess,
    postUserEmailFailure,
    postUserEmailSuccess
} from "../../actions/userManagementActions";
import { Routes } from "../../../constants/Routes";
import  { PortalType, HOTEL_ADMIN_ID, HOTEL_MANAGER_ID, CLIENT_ADMIN_ID } from "../../../constants/constant";
import { portalType } from "../../../auth.config";
import { SytemAppType } from "../../../utils/FormValidations"
function* postUserManagement(action: any) {
    const { data } = action;
    let url = `${Routes.userManagement.credUserManagmentApiUrl}`;
    const request = { ...data, systemAppType: SytemAppType(portalType)};
    try {
        // @ts-ignore
        const response = yield doPost(url, request);
        // on success update redux store with complete request object
        yield put(PostUserSuccess(request));
    } catch (error: any) {
        yield put(PostUserFailure(error));
    }
}

function* fetchUsers(action: any) {
    const { locationId, offset } = action;
    const baseURL = Routes.userManagement.credUserManagmentApiUrl;
    let url = portalType === PortalType.HP ? `${baseURL}?offset=${offset}&rolesToSearch=${HOTEL_ADMIN_ID}&rolesToSearch=${HOTEL_MANAGER_ID}`: `${baseURL}?offset=${offset}&rolesToSearch=${CLIENT_ADMIN_ID}`
    if (locationId && portalType === PortalType.HP) {
        url += `&locationId=${locationId}`;
    }
    try {
        // @ts-ignore
        const response = yield doGet(url);
        yield put(FetchUsersSuccess(response));
    } catch (error: any) {
        yield put(FetchUsersFailure(error));
    }
}
function* patchUser(action: any) {
    const { systemUserId, data } = action;
    // @ts-ignore
    const url = `${Routes.userManagement.credUserManagmentApiUrl}/${systemUserId}`;
    const request = {...data, systemAppType: SytemAppType(portalType)};
    try {
        // @ts-ignore
        const response = yield doPatch(url, request);
        yield put(PatchUserSuccess(response));
    } catch (error: any) {
        yield put(PatchUserFailure(error));
    }
}
function* removeUser(action: any) {
    const { systemUserId, data} = action;
    // @ts-ignore
    let url = `${Routes.userManagement.credUserManagmentApiUrl}/${systemUserId}`;
    const request = {...data, systemAppType: SytemAppType(portalType)};

    try {
        // @ts-ignore
        const response = yield doPatch(url, request);
        yield put(DeleteUserSuccess(response));
    } catch (e: any) {
        yield put(DeleteUserFailure(e));
    }
}
function* resendInviteUser(action: any) {
    const { info } = action;
    // @ts-ignore
    let url = `${Routes.userManagement.resendApiUrl}send-verification-email`;
    const request = {email:info, systemAppType: SytemAppType(portalType)};

    try {
        // @ts-ignore
        const response = yield doPost(url, request);
        yield put(resendInviteUserSuccess(response));
    } catch (e: any) {
        yield put(resendInviteUserFailure(e));
    }
}
function* fetchUserDetail(action: any) {
    const { systemUserId } = action; // made changes here
    // @ts-ignore
    let url = `${Routes.userManagement.credUserManagmentApiUrl}/${systemUserId}`;
    try {
        // @ts-ignore
        const response = yield doGet(url);
        yield put(fetchUserSuccess(response));
    } catch (error: any) {
        yield put(fetchUserFailure(error));
    }
}
function* putUserRole(action: any) {
    const { systemUserId, data, roleId } = action;
    // @ts-ignore
    const url = `${Routes.userManagement.credUserManagmentApiUrl}/${systemUserId}/roles/${roleId}`;
    try {
        // @ts-ignore
        const response = yield doPut(url, data);
        yield put(putUserRoleSuccess(response));
    } catch (error: any) {
        yield put(putUserRoleFailure(error));
    }
}

function* fetchUserDetailLocations(action: any) {
    const { systemUserId } = action;
    // @ts-ignore
    const url = `${Routes.userManagement.credUserManagmentApiUrl}/${systemUserId}/locations`;
    try {
        // @ts-ignore
        const response = yield doGet(url);
        yield put(fetchUserLocationSuccess(response.items));
    } catch (error: any) {
        yield put(fetchUserLocationFailure(error));
    }
}

function* putUserLocations(action: any) {
    const { systemUserId, data } = action;
    // @ts-ignore
    const url = `${Routes.userManagement.credUserManagmentApiUrl}/${systemUserId}/locations`;
    try {
        // @ts-ignore
        const response = yield doPut(url, data);
        yield put(updateUserLocationSuccess(response));
    } catch (error: any) {
        yield put(updateUserLocationFailure(error));
    }
}

function* putUserOrganization(action: any) {
    const { systemUserId, data } = action;
    // @ts-ignore
    const url = `${Routes.userManagement.credUserManagmentApiUrl}/${systemUserId}/orgs`;
    try {
        // @ts-ignore
        const response = yield doPut(url, data);
        yield put(updateUserOrganizationSuccess(response));
    } catch (error: any) {
        yield put(updateUserOrganizationFailure(error));
    }
}

function* postUserEmail(action: any) {
    const { systemUserId, data } = action;
    // @ts-ignore
    const url = `${Routes.userManagement.credUserManagmentApiUrl}/${systemUserId}/email-update`;
    try {
        // @ts-ignore
        const response = yield doPost(url, data);
        yield put(postUserEmailSuccess(response));
    } catch (error: any) {
        yield put(postUserEmailFailure(error));
    }
}

function* UserManagementSaga() {
    yield all([
        takeLatest(USERMANAGEMENT.FETCH_USERS_REQUEST, fetchUsers),
        takeLatest(USERMANAGEMENT.FETCH_USERDETAIL_REQUEST, fetchUserDetail),
        takeLatest(USERMANAGEMENT.PATCH_USER_REQUEST, patchUser),
        takeLatest(USERMANAGEMENT.DELETE_USER_REQUEST, removeUser),
        takeLatest(USERMANAGEMENT.RESEND_INVITE_LINK_REQUEST, resendInviteUser),
        takeLatest(USERMANAGEMENT.POST_USER_REQUEST, postUserManagement),
        takeLatest(USERMANAGEMENT.PUT_USER_ROLE_REQUEST, putUserRole),
        takeLatest(USERMANAGEMENT.FETCH_USERDETAIL_LOCATION_REQUEST, fetchUserDetailLocations),
        takeLatest(USERMANAGEMENT.PUT_USERDETAIL_LOCATION_REQUEST, putUserLocations),
        takeLatest(USERMANAGEMENT.PUT_USERDETAIL_ORGANIZATION_REQUEST, putUserOrganization),
        takeLatest(USERMANAGEMENT.POST_USERDETAIL_EMAIL_REQUEST, postUserEmail),
    ]);
}

export default UserManagementSaga;
