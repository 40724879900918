import { IonButton } from "@ionic/react"
interface PreviewButton {
    siteColorProps?: string | unknown;
    paddingProps?: string;
    heightProps?: string;
    borderRadiusProps?: string;
    backgroundProps?: string;
    marginProps?: string;
    boxShadowProps?: string;
    colorProps?: string | unknown;
    labelProps?: string
}
const PreviewButton = (props: PreviewButton) => {
    return (<>
        <IonButton
            style={{
                '--background': props.siteColorProps,
                padding: props.paddingProps,
                height: props.heightProps,
                borderRadius: props.borderRadiusProps,
                background: props.backgroundProps,
                margin: props.marginProps,
                '--box-shadow': props.boxShadowProps,
                '--color': props.colorProps,
            }}
        >
            <span className="button-text-property">{props.labelProps}</span>
        </IonButton>
    </>)
}

export default PreviewButton;