import { codeCurrenyMap } from "./mapping";

const getSymbolFromCurrencyCode = (currencyCode: string) => {
    const code = currencyCode.toUpperCase();
    if (!Object.prototype.hasOwnProperty.call(codeCurrenyMap, code)) {
        return undefined;
    }
    //@ts-ignore
    return codeCurrenyMap[code];
}

export default getSymbolFromCurrencyCode;