import { IonSelect } from "@ionic/react"
import SelectOption from "./SelectOptions";

const Dropdown: React.FC<any> = (props) => {
    const { options = [], selected, label, value, onChangeHandler, className = "loc-dropdown", interfaceOptions = {}, placeholder, labelPlacement = "floating", styles = {} } = props;
    return (
        <IonSelect
            style={{ ...styles }}
            value={selected}
            interface="popover"
            className={className}
            aria-label={placeholder}
            placeholder={placeholder}
            labelPlacement={labelPlacement}
            interfaceOptions={interfaceOptions}
            onIonChange={(e: any) => onChangeHandler(e.detail.value)}>
            {options.map((option: any, index: number) => {
                return (
                    <SelectOption
                        value={option[value]}
                        label={option[label]}
                        key={index} />
                )
            })}
        </IonSelect>
    )
}
export default Dropdown;