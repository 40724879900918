// vendors
import React, { useState, useEffect } from "react";
import { IonInput, IonItem, IonNote, IonIcon, IonRow, IonCol, isPlatform } from "@ionic/react";
import { star } from 'ionicons/icons';
// modals
import type { SignupProps } from "../../models/SignupProps";
// utils
import { isZipValid } from "../../utils/index";
//constant
import { onboarding, Hotel365Portal } from "../../constants/constant";

export const ZipCode: React.FC<SignupProps> = ({ zip, setOnboarding }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const isMobile = isPlatform("mobile");

  const onChangeHandler = (e: any) => {
    const zip = e.detail.value;
    const { errorMessage } = isZipValid(zip);
    setErrorMessage(errorMessage);
    setOnboarding((prevState: any) => ({ ...prevState, zip: e.detail.value }));
  }
  // to show error message on load
  // if API data has invalid data
  useEffect(() => {
    const { errorMessage } = isZipValid(zip);
    setErrorMessage(errorMessage);
  }, []);

  return (
    <IonRow className="verticle-center">
      <IonCol className={isMobile ? "ion-padding-start ion-padding-end" : "ion-padding-start"} style={isMobile ? { marginTop: '25%' } : {}}>
        <h2 className={isMobile ? "bold-h2-mobile" : "bold-h2"}>{onboarding.RIGHT_SIDE_ZIPCODE_HEADING}</h2>
        <IonNote color="medium">
          {!zip && <IonIcon md={star} size="small" color='danger' className='error-asterik' />}
          <span>{onboarding.RIGHT_SIDE_ZIPCODE}</span>
        </IonNote>
        <IonItem lines="none" className="all-border-ion-item-input" style={isMobile ? { width: '100%' } : { width: '50%' }}>
          <IonInput
            value={zip}
            maxlength={6}
            onIonChange={onChangeHandler}
            aria-label={onboarding.RIGHT_SIDE_ZIPCODE}
          />
        </IonItem>
        {errorMessage && <span className="validation-error-label">{Hotel365Portal.ZIPCODE_REQUIRED_ERR}</span>}
      </IonCol>
    </IonRow>
  )
};

export default ZipCode;