import React, { useEffect, useState } from "react";
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
    isPlatform,
} from "@ionic/react";

import FooterComponent from "../FooterComponent";
import {
    MY_PROFILE_HDR,
    PROFILE_ACC_INFO,
    GLOBAL_FIRST_NAME,
    GLOBAL_LAST_NAME,
    PROFILE_RESET_PASS_CODE,
    GLOBAL_EMAIL,
    PROFILE_CHANGE_EMAIL,
    PROFILE_NEW_EMAIL,
    PROFILE_CONFIRM_EMAIL
} from '../../constants/constant';

export const ProfileForm: React.FC<any> = (props) => {
    const {
        handleSubmit,
        onSubmit,
        userData,
        setUser,
        formErrors,
        resetPassword,
        enableEmailField,
        showEmailField,
    } = props;
    const isTablet = isPlatform("tablet");
    const isMobile = isPlatform("iphone" || "mobile");
    const isDesktop = isPlatform("desktop");
    const { firstName, lastName, email, newEmail, confirmEmail } = userData;
    const onChangeHandler = (e: any) => {
        setUser((prevState: any) => ({
            ...prevState,
            [e.target.name]: e.detail.value,
        }));
    };
    return (
        <IonContent>
            <form onSubmit={handleSubmit}>
                    <IonRow className={isMobile ? "ion-padding scroll-content" : ""}>
                        <IonCol>
                            <IonRow className={isDesktop || isTablet ? "ion-padding-start ion-margin-top" : 'ion-padding-start'}>
                                <IonCol sizeXs='12' sizeMd='12'><h6 className='profile-header-label'><b>{MY_PROFILE_HDR}</b></h6></IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-start ion-margin-top">
                                <IonCol sizeXs='12' sizeMd='12'><IonLabel className='profile-acc-info-label'><b>{PROFILE_ACC_INFO}</b></IonLabel></IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-start ion-margin-top ion-padding-end">
                                <IonCol sizeXs='12' sizeLg='4' sizeMd='3'>
                                    <span id='required-field' className={formErrors && formErrors.firstName ? "global-red" : ""}>*</span>
                                    <IonLabel className='profile-first-name-lbl'>{GLOBAL_FIRST_NAME}</IonLabel>
                                    <IonInput
                                        className='profile-input-first-name'
                                        aria-label={GLOBAL_FIRST_NAME}
                                        required
                                        type="text"
                                        maxlength={50}
                                        name="firstName"
                                        value={firstName}
                                        onIonChange={(e) => onChangeHandler(e)} />
                                    {formErrors.firstName && (<span className="global-red-first-name">{formErrors.firstName}</span>)}
                                </IonCol>
                                <IonCol sizeXs='12' sizeLg='4' sizeMd='3' className={isDesktop || isTablet ? "ion-padding-start" : 'ion-margin-top'}>
                                    <span id='required-field' className={formErrors && formErrors.firstName ? "global-red" : ""}>*</span>
                                    <IonLabel className='profile-first-name-lbl'>{GLOBAL_LAST_NAME}</IonLabel>
                                    <IonInput
                                        className='profile-input-first-name'
                                        aria-label={GLOBAL_LAST_NAME}
                                        required
                                        type="text"
                                        maxlength={50}
                                        name="lastName"
                                        value={lastName}
                                        onIonChange={(e) => onChangeHandler(e)} />
                                    {formErrors.lastName && (<span className="global-red-last-name">{formErrors.lastName}</span>)}
                                </IonCol>
                                <IonCol sizeXs='12' sizeLg='4' sizeMd='3' className={isDesktop || isTablet ? "ion-padding-start ion-margin-top" : 'ion-margin-top'}>
                                    <IonButton className={isDesktop ? 'reset-pwd-btn' : "reset-pwd-btn-mobile"} onClick={resetPassword} color="primary">{PROFILE_RESET_PASS_CODE}</IonButton>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-start ion-margin-top ion-padding-end">
                                <IonCol sizeXs='12' sizeLg='3.1' sizeMd='4' className='ion-no-padding' >
                                    <IonLabel className='profile-first-name-lbl'>{GLOBAL_EMAIL}</IonLabel>
                                    <IonInput
                                        className={isDesktop || isTablet ? 'profile-email-input' : 'profile-email-input-mobile'}
                                        aria-label={GLOBAL_EMAIL}
                                        required
                                        disabled
                                        type="email"
                                        name="Email"
                                        value={email} />
                                </IonCol>
                                <IonCol sizeLg='1.1' sizeMd='2' sizeXs='12' className="ion-no-padding ion-margin-top">
                                    <IonButton
                                        onClick={showEmailField}
                                        className={isDesktop || isTablet ? 'email-change-btn' : "reset-pwd-btn-mobile"}
                                        color="primary"
                                    >
                                        {PROFILE_CHANGE_EMAIL}
                                    </IonButton>
                                </IonCol>
                                <IonCol sizeLg='3.8' sizeXs='12' sizeMd='4' className={isDesktop ? "ion-no-padding new-email" : isTablet ? "ion-no-padding ion-padding-end" : "ion-margin-top"}>
                                    {enableEmailField && (<>
                                        <span id='profile-new-email'>*</span>
                                        <IonLabel className='profile-first-name-lbl'>{PROFILE_NEW_EMAIL}</IonLabel>
                                        <IonInput
                                            className='profile-input-first-name'
                                            aria-label={PROFILE_NEW_EMAIL}
                                            name="newEmail"
                                            value={newEmail}
                                            type="text"
                                            maxlength={50}
                                            onIonChange={(e) => {
                                                onChangeHandler(e);
                                            }} />
                                        {(formErrors.emailError ||
                                            formErrors.emailExixtsError) && (
                                                <span className="global-red-first-name">
                                                    {formErrors.emailError}{" "}
                                                    {
                                                        formErrors.emailExixtsError
                                                    }
                                                </span>
                                            )}</>
                                    )}
                                </IonCol>
                                <IonCol sizeMd='4' sizeLg='3.8' sizeXs='12' className={isDesktop ? "ion-no-padding ion-padding-start confirm-email" : "ion-margin-top"}>
                                    {enableEmailField && (<><span id='profile-new-email'>*</span>
                                        <IonLabel className='profile-first-name-lbl'>{PROFILE_CONFIRM_EMAIL}</IonLabel>
                                        <IonInput
                                            className='profile-input-first-name'
                                            aria-label={PROFILE_CONFIRM_EMAIL}
                                            name="confirmEmail"
                                            value={confirmEmail}
                                            type="text"
                                            maxlength={50}
                                            onIonChange={(e) => {
                                                onChangeHandler(e);
                                            }}
                                        />
                                        {(formErrors.confirmEmail ||
                                            formErrors.emailExixtsError) && (
                                                <span className="global-red-first-name">
                                                    {formErrors.confirmEmail}
                                                </span>
                                            )}
                                    </>
                                    )}
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                <FooterComponent
                    submitCallBack={onSubmit}
                />
            </form>
        </IonContent>
    );
};

export default ProfileForm;