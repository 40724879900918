import { isPlatform } from "@ionic/react";
import envChange from './utils/changeEnvironment';
import { fetchEnviorment } from './utils/index';
const envConfig = envChange();
const getEnv = fetchEnviorment();
export const portalType = envConfig.portalType;
export const domain = ((envConfig as any)[getEnv]?.REACT_APP_DOMAIN as string);
export const clientId = ((envConfig as any)[getEnv] ? (envConfig as any)[getEnv][portalType].REACT_APP_CLIENT_ID:'' as string);
const appId = ((envConfig as any)[getEnv]?(envConfig as any)[getEnv][portalType].REACT_APP_APP_ID:'' as string);
export const audience=((envConfig as any)[getEnv]?.REACT_APP_AUDIENCE);
export const scope ="openid profile email";

export const callbackUri = window.location.origin;

//Uncomment for mobile app
// `${appId}://${domain}/capacitor/${appId}/callback`