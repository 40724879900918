//components
import Amex from './Cards/Amex';
import ApplePay from './Cards/ApplePay';
import Discover from './Cards/Discover';
import GooglePay from './Cards/GooglePay';
import MasterCard from './Cards/Mastercard';
import PaperBill from './Cards/PaperBill';
import Visa from './Cards/Visa';
import CreditCard from './Cards/CreditCard';
//types and constants
import { CreditCardImageProps, paymentTypes } from "./constants";

const CreditCardImages: React.FC<CreditCardImageProps> = ({ issuer, size = 26 }) => {
  if (issuer) {
    switch (issuer.toLowerCase()) {
      case paymentTypes.visa:
        return <Visa size={26} />;
      case paymentTypes["american-express"]:
      case paymentTypes["american-express"]:
      case paymentTypes.amex:
        return <Amex size={26} />;
      case paymentTypes.discover:
        return <Discover size={26} />;
      case paymentTypes["master-card"]:
      case paymentTypes.mastercard:
        return <MasterCard size={26} />;
      case paymentTypes["apple-pay"]:
        return <ApplePay size={26} />;
      case paymentTypes["google-pay"]:
        return <GooglePay size={26} />;
      case paymentTypes.payroll:
        return <PaperBill size={26} />;
      default:
        return <CreditCard size={26} />;
    }
  } else {
    return <></>;
  }
}

export default CreditCardImages;