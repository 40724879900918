//vendors
import React, { useState } from "react";
import { IonRow, IonCol, IonItem, IonLabel } from "@ionic/react";
//components
//import Categories from './Categories';
import DateRangePicker from "../../components/common/DateRangePicker";
import TimeRange from "./TimeRange";
import RunReport from "./RunReport";
//constants
import {
    timeValues,
    last30Days,
    customRange,
    timeRangeLbl,
    dateRangeLbl,
    REPORT_FILTERS,
    dateRangeWarning,
    reportingDateRanges
} from '../../constants/constant';
//utils
import { getFrmAndToDateFrmRange } from '../../utils'

export const ReportFilters: React.FC<any> = ({ runReport }) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [dateRange, setDateRange] = useState(last30Days);
    const [categories, setCategories] = useState([]);
    const [timeRange, setTimeRange] = useState({
        fromTime: timeValues[0]['value'],
        toTime: timeValues[48]['value'],
    });
    const [dateRangeDates, setDateRangeDates] = useState({ fromDate: '', toDate: '' });
    const [showCustomRange, setShowCustomRange] = useState(false);
    const [customDateRangeErr, setCustomDateRangeErr] = useState('');
    const [formErrors, setFormErrors] = useState({
        isValid: true,
        errorMessage: "",
    });

    const applyCustomDateRange = (fromDate: any, toDate: any) => {
        if (customDateRangeErr === '') {
            setDateRangeDates((prevState) => ({ ...prevState, fromDate, toDate }));
            setPopoverOpen(false);
        }
    };

    const onPredefinedOptionSelect = (item: string) => {
        if (item !== customRange) {
            //@ts-ignore
            const dateObject = reportingDateRanges[item];
            const { fromDate, toDate } = getFrmAndToDateFrmRange(dateObject);
            setDateRangeDates((prevState) => ({ ...prevState, fromDate, toDate }));
            setShowCustomRange(false);
        } else {
            setShowCustomRange(true);
        }
    };

    const runReportCallBack = () => {
        const { fromDate: defaultFrm, toDate: defaultTo } = getFrmAndToDateFrmRange(reportingDateRanges[last30Days]);
        const { fromDate, toDate } = dateRangeDates;
        const dateRangeObj = { fromDate: fromDate || defaultFrm, toDate: toDate || defaultTo };
        runReport({ dateRangeDates: dateRangeObj, timeRange, categories });
    };
    return (
        <IonCol size="3" size-lg="3" size-md="5" size-xs="12" size-sm="12">
            <IonRow>
                <IonCol>
                    <IonItem lines="inset" color="none" className="ion-no-padding">
                        <IonLabel className="report-run-report">
                            <h3 className="header-text">{REPORT_FILTERS}</h3>
                        </IonLabel>
                    </IonItem>
                </IonCol>
            </IonRow>
            <DateRangePicker
                label={dateRangeLbl}
                dateRange={dateRange}
                popoverOpen={popoverOpen}
                setDateRange={setDateRange}
                warningLbl={dateRangeWarning}
                onApply={applyCustomDateRange}
                setPopoverOpen={setPopoverOpen}
                dateRangeDates={dateRangeDates}
                showCustomRange={showCustomRange}
                customDateRangeErr={customDateRangeErr}
                setShowCustomRange={setShowCustomRange}
                predefinedOptions={reportingDateRanges}
                setCustomDateRangeErr={setCustomDateRangeErr}
                onPredefinedOptionSelect={onPredefinedOptionSelect}
                setTimeRange={setTimeRange}
                setFormErrors={setFormErrors}
                defaultRangeDates={getFrmAndToDateFrmRange(reportingDateRanges[last30Days])}
            />
            <TimeRange
                label={timeRangeLbl}
                setTimeRange={setTimeRange}
                timeRange={timeRange}
                dateRange={dateRangeDates}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
            />
            {/* <Categories
                    categories={categories}
                    setCategories={setCategories}
                    categoryTypeLbl={categoriesTypeLbl}
                    placeholder={categoryPlaceholder}
                /> */}
            <RunReport runReportCallBack={runReportCallBack} formErrors={formErrors} />
        </IonCol>
    )
};
export default ReportFilters;
