//vendors
import React from 'react';
import { useEffect } from 'react';
import { DateTime } from 'luxon';
import {
    IonButton,
    IonChip,
    IonCol,
    IonHeader,
    IonInput,
    IonLabel,
    IonModal,
    IonRow,
    IonTitle
} from '@ionic/react';
//constants
import { CUSTOM_DATE_RANGE_ERR, customDateRangeHdr } from '../../../constants/constant';

const RangeCalendarForMobile: React.FC<any> = ({
    setDateObject,
    dateObject,
    cancelBtnLbl,
    applyBtnLbl,
    fromDateLbl,
    warningLbl,
    toDateLbl,
    openModal,
    closeModal,
    showCustomRange,
    resetOnDismiss,
    setCustomDateRangeErr,
    customDateRangeErr,
    onApply
}) => {
    useEffect(() => {
        const { fromDate, toDate } = dateObject;
        const luxonFromDate = DateTime.fromJSDate(new Date(fromDate));
        const luxonToDate = DateTime.fromJSDate(new Date(toDate));
        if (luxonFromDate.startOf('day') > luxonToDate.startOf('day')) {
            setCustomDateRangeErr(CUSTOM_DATE_RANGE_ERR);
        } else {
            setCustomDateRangeErr('');
        }
    }, [dateObject]);

    return (
        <IonModal
            isOpen={openModal}
            className="custom-date-popup"
            onDidDismiss={() => closeModal()}
        >{showCustomRange &&
            <><IonHeader className="header">
                <IonRow className='primary-header'>
                    <IonTitle>{customDateRangeHdr}</IonTitle>
                    <IonChip
                        color="primmary"
                        className="ion-button"
                        onClick={() => (resetOnDismiss())}
                    >
                        <p className="cancel">&times;</p>
                    </IonChip>
                </IonRow>
            </IonHeader>
                <IonRow className="from-date-row">
                    <IonCol>
                        <IonLabel>{fromDateLbl}</IonLabel>
                        <IonInput
                            type='date'
                            max="9999-12-31"
                            className='date-range-input'
                            onIonChange={(e) => {
                                setDateObject((prevState: any) => ({ ...prevState, fromDate: e.detail.value }));
                            }}
                        />
                    </IonCol>
                </IonRow>
                <IonRow className="to-date-row">
                    <IonCol>
                        <IonLabel>{toDateLbl}</IonLabel>
                        <IonInput
                            type='date'
                            max="9999-12-31"
                            className='date-range-input'
                            onIonChange={(e) => {
                                setDateObject((prevState: any) => ({ ...prevState, toDate: e.detail.value }));
                            }}
                        />
                    </IonCol>
                </IonRow>
                <IonRow><span className="custom-date-error">{customDateRangeErr}</span></IonRow>
            </>}
            <IonRow className='warning-label-mobile'>{warningLbl}</IonRow>
            {showCustomRange &&
                <>
                    <IonButton
                        color="primary"
                        onClick={() => {
                            const { fromDate, toDate } = dateObject;
                            onApply(fromDate, toDate);
                            !customDateRangeErr && closeModal();
                        }}
                        className="date-modal-save">
                        {applyBtnLbl}
                    </IonButton>
                    <IonButton color="gray" onClick={() => (resetOnDismiss())} className="date-modal-cancel">
                        {cancelBtnLbl}
                    </IonButton>
                </>
            }
        </IonModal >
    )
}

export default RangeCalendarForMobile;