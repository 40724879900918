import { IonLabel } from "@ionic/react"

interface HeadingProps {
    heading?: String
}

const Heading = (props: HeadingProps) => {
    return(<>
        <IonLabel>
            {props.heading}
        </IonLabel>
    </>)
}

export default Heading;