import { memo, useCallback, useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
    IonModal,
    IonButton,
    IonContent,
    IonRow,
    IonCol,
    IonItem,
    IonHeader,
    IonFooter,
    IonLabel,
    IonInput,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSpinner,
    isPlatform,
} from "@ionic/react";
import { search, close } from "ionicons/icons";
import { RootState } from "../../../_redux/reducers/rootReducer";
import {
    saveMarketProduct,
    FetchGlobalProducts,
    resetGlobalProducts,
    FetchGlobalProductsCategories,
} from "../../../_redux/actions/productActions";
import { resetProductsSettingsPanel } from '../../../_redux/actions/productSettingsPanelActions'

import Header from "../../../components/Product/ManageColumns/Header";
import { ProductFilters } from "../../../components/Product/ProductFilters/ProductFilters";
import Footer from "../../../components/Product/ManageColumns//Footer";
import ToastrHook from "../../../components/common/toastr/Toastr";
import {
    Hotel365Portal,
    ORDER_SORT_BY_HEADER,
    MODAL_FILTER_APPLIED,
    GLOBAL_SEARCH_PLACEHOLDER,
    ADD_PRODUCT_EMPTY_MESSAGE,
    LOADING_MORE_DATA,
    ADD,
    PRODUCT_NAME_ATOZ,
    PRODUCT_LOW_TO_HIGH,
    GLOBAL_PLEASE_SELECT_PRODUCT
} from "../../../constants/constant";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { addZeroes, getLocalizedNumericValues } from "../../../utils";

interface ProductList {
    productName: string;
    productPrice: number;
    product: Record<string, unknown>;
}

export const AddProductPopup: React.FC<any> = memo(
    ({ showAddPrdtModal, setButtonState, setShowGlobalPop, showGlobalPop }) => {
        const dispatch = useDispatch();
        const [sortSelect, setSortSelect] = useState(PRODUCT_NAME_ATOZ);
        const [searchBarShow, setSearchBarShow] = useState(true);
        const [showModalBox, setShowModalBox] = useState(showAddPrdtModal);
        const [showFilterModal, setShowFilterModal] = useState(false);
        const [searchText, setSearchText] = useState<string>();
        const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
        const ismobileview = isPlatform("mobile");
        const [renderToastr] = ToastrHook();
        const productFilter = useSelector(
            (state: RootState) => state.product.categoryFilters,
            shallowEqual
        );
        const [isFilterApplied, setIsFilterApplied] = useState(productFilter && productFilter.length > 0 ? true : false);

        const {
            pending,
            globalProducts,
            globalPaginate,
            createdMarketProduct,
            error,
            categoryFilters,
        } = useSelector((state: RootState) => state.product, shallowEqual);
        const profile = useSelector((state: RootState) => { return state.profile }, shallowEqual);
        const { currentProfile } = profile;
        const { currency } = currentProfile;

        const [productList, setProductList] = useState(globalProducts);
        const [productIds, setProductIds] = useState<string[]>([]);

        const handleScroll = (ev: any) => {
            setTimeout(() => {
                const offset: number = globalPaginate.nextOffset;
                dispatch(resetProductsSettingsPanel())
                dispatch(
                    FetchGlobalProducts(offset, searchText, categoryFilters)
                );
                ev.target.complete();
                if (globalProducts.length === globalPaginate.totalCount) {
                    setInfiniteDisabled(true);
                }
            }, 500);
        };

        const SortArray = (x: any, y: any) => {
            const productTempX = x.productName.trim().toLocaleLowerCase();
            const productTempY = y.productName.trim().toLocaleLowerCase();

            if (productTempX < productTempY) {
                return -1;
            }
            if (productTempX > productTempY) {
                return 1;
            }

            return 0;
        };
        const resetToDefault = () => {
            setSearchBarShow(true);
            setShowModalBox(false);
            setButtonState(false);
            setInfiniteDisabled(false)
            setSearchText("");
            setSortSelect(PRODUCT_NAME_ATOZ)
            setProductIds([])
        };
        const onCloseModal = (event: CustomEvent<OverlayEventDetail>) => {
            resetToDefault();
            dispatch(resetGlobalProducts());
            dispatch(resetProductsSettingsPanel())
            dispatch(FetchGlobalProducts(null, ""));
            dispatch(FetchGlobalProductsCategories("", 0));
        };

        const searchItems = (event: any) => {
            setSearchText(event.detail.value);
            setInfiniteDisabled(false)
            dispatch(resetGlobalProducts());
            dispatch(resetProductsSettingsPanel())
            dispatch(
                FetchGlobalProducts(null, event.detail.value, categoryFilters)
            );
        };

        useEffect(() => {
            setIsFilterApplied(productFilter && productFilter.length > 0 ? true : false)
            if (
                globalProducts.length !== 0 &&
                globalProducts.length === globalPaginate.totalCount
            ) {
                setInfiniteDisabled(true);
            }
            if (showAddPrdtModal) {
                setShowModalBox(showAddPrdtModal);
            }
            if (error && Object.keys(error).length > 0 && productIds?.length > 0) {
                setProductIds([]);
            }
            if (showGlobalPop) {
                setShowGlobalPop(false)
            } else if (
                createdMarketProduct &&
                Object.keys(createdMarketProduct).length > 0
            ) {
                resetToDefault();
            }
        }, [
            createdMarketProduct,
            error,
            productList,
            globalProducts,
            showAddPrdtModal,
            productFilter,
            setProductList,
            setIsFilterApplied
        ]);

        const sortByPrice = (args: any) => {
            const dbs = globalProducts?.sort(
                (a: any, b: any) => a.productPrice - b.productPrice
            );
            if (args.detail.value == PRODUCT_NAME_ATOZ) {
                setProductList(globalProducts.sort(SortArray));
                setSortSelect(args.detail.value);
            } else {
                dbs && setProductList([...dbs]);
                setSortSelect(args.detail.value);
            }
        };

        useEffect(() => {
            setProductList(globalProducts);
            if (sortSelect === PRODUCT_LOW_TO_HIGH) {
                sortByPrice({ detail: { value: PRODUCT_LOW_TO_HIGH } })
            }
        }, [globalProducts, sortSelect])

        const searchBar = (args: boolean) => {
            setSearchBarShow(args);
            if (args === true) {
                setSearchText("");
                setInfiniteDisabled(false)
                dispatch(resetGlobalProducts());
                dispatch(resetProductsSettingsPanel())
                dispatch(FetchGlobalProducts(null, "", categoryFilters));
                dispatch(FetchGlobalProductsCategories("", 0));
            }
            setSortSelect(PRODUCT_NAME_ATOZ)
        };
        const filterApply = () => {
            setShowFilterModal(true);
        };

        const onSave = () => {
            if (productIds?.length === 0) {
                renderToastr(GLOBAL_PLEASE_SELECT_PRODUCT, "warning");
                return false;
            }
            const reqObject = productIds.map((item: string) => {
                return {
                    locationId: currentProfile.locationId,
                    orgId: currentProfile.orgId,
                    productId: item,
                };
            })
            dispatch(resetProductsSettingsPanel())
            dispatch(saveMarketProduct(reqObject));
        };

        const onCloseFilterModal = async (_appliedCategories: string[]) => {
            setShowFilterModal(false);
            setInfiniteDisabled(false)
            setShowModalBox(true);
            setProductIds([]);
            dispatch(resetGlobalProducts());
            dispatch(resetProductsSettingsPanel())
            dispatch(FetchGlobalProducts(0, searchText, _appliedCategories));
            dispatch(FetchGlobalProductsCategories("", 0));
            if (_appliedCategories.length > 0) {
                setIsFilterApplied(true);
            } else {
                setIsFilterApplied(false);
            }
        };

        const addProductIds = (productId: string) => {
            setProductIds((prevProductIds) => [
                ...prevProductIds,
                productId
            ]);
        }

        const onProductSelect = useCallback(
            (product) => {
                if (productIds.length === 0) {
                    addProductIds(product.productId)
                } else {
                    const isElementPresent = productIds.some(
                        (id: string) => id === product.productId
                    );
                    if (!isElementPresent) {
                        addProductIds(product.productId)
                    } else {
                        setProductIds(prevProductIds => (
                            // Filter out the item with the matching value
                            prevProductIds.filter((value: string) => value !== product.productId)
                        ));
                    }
                }

            },
            [setProductIds, productIds]
        );
        return (
            <>
                <IonModal
                    isOpen={showModalBox}
                    className={
                        ismobileview
                            ? "mobileview-modal-pop"
                            : "modal-pop"
                    }
                    onDidDismiss={onCloseModal}
                >
                    <IonContent
                        style={{
                            visibility: !showFilterModal ? "visible" : "hidden",
                        }}
                    >
                        <IonHeader className="header">
                            <Header
                                headerTitle={Hotel365Portal.GLOBAL_CHOOSE_NEW_PRODUCT}
                                onCloseModal={onCloseModal}
                                filterIcon={true}
                                filterApply={filterApply}
                            />
                        </IonHeader>
                        {searchBarShow ? (
                            <IonRow>
                                <IonCol className="filter-section">
                                    <IonRow className="modal-body">
                                        <IonCol
                                            sizeXs="8"
                                            sizeSm="9"
                                            style={{ marginTop: "8px" }}
                                        >
                                            <IonLabel>{ORDER_SORT_BY_HEADER}</IonLabel>
                                        </IonCol>
                                        {isFilterApplied && (
                                            <IonCol sizeXs="4" sizeSm="3">
                                                <IonLabel className="filter-applied-text">
                                                    {MODAL_FILTER_APPLIED}
                                                </IonLabel>
                                            </IonCol>
                                        )}
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="10">
                                            <IonSelect
                                                interface="popover"
                                                value={sortSelect}
                                                onIonChange={(e) => {
                                                    sortByPrice(e);
                                                }}
                                                className="sort-dropdown"
                                            >
                                                <IonSelectOption value={PRODUCT_NAME_ATOZ}>
                                                    {PRODUCT_NAME_ATOZ}
                                                </IonSelectOption>
                                                <IonSelectOption value={PRODUCT_LOW_TO_HIGH}>
                                                    {PRODUCT_LOW_TO_HIGH}
                                                </IonSelectOption>
                                            </IonSelect>
                                        </IonCol>
                                        <IonCol size="2">
                                            <IonButton
                                                className="search-icon"
                                                onClick={() => searchBar(false)}
                                            >
                                                <IonIcon icon={search} />
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                        ) : (
                            <IonRow className="modal-body filter-section search-box">
                                <IonCol size="10">
                                    <IonLabel color="light">{GLOBAL_SEARCH_PLACEHOLDER}</IonLabel>
                                    <IonItem className="product-search-box">
                                        <IonInput
                                            placeholder={GLOBAL_SEARCH_PLACEHOLDER}
                                            value={searchText}
                                            onIonChange={searchItems}
                                        />
                                    </IonItem>
                                </IonCol>
                                <IonCol size="2">
                                    <IonIcon
                                        className="close-icon"
                                        onClick={() => searchBar(true)}
                                        icon={close}
                                    />
                                </IonCol>
                            </IonRow>
                        )}
                        <IonRow className="items-scroll">
                            <IonCol className="item-list click-cursor">
                                {pending && (
                                    <IonSpinner
                                        name="circles"
                                        className="spinner-circles"
                                    />
                                )}
                                {!pending && productList.length === 0 && (
                                    <p className="no-products">
                                        {ADD_PRODUCT_EMPTY_MESSAGE}
                                    </p>
                                )}
                                {!pending &&
                                    productList.length > 0 &&
                                    productList.map((res: any, i: number) => (
                                        <IonItem
                                            key={i}
                                            lines="full"
                                            color={
                                                productIds.indexOf(
                                                    res.product.productId
                                                ) > -1
                                                    ? "light"
                                                    : ""
                                            }
                                            onClick={() =>
                                                onProductSelect(res.product)
                                            }
                                        >
                                            <IonLabel className="product-item ion-text-wrap">
                                                {" "}
                                                {res.productName}
                                            </IonLabel>
                                            <IonLabel
                                                slot="end"
                                                className="product-prise"
                                            >
                                                {" "}
                                                {res.productPrice ? getLocalizedNumericValues(res.productPrice, "currency", currency) : 0}
                                            </IonLabel>
                                        </IonItem>
                                    ))}

                                <IonInfiniteScroll
                                    onIonInfinite={handleScroll}
                                    threshold="100px"
                                    disabled={isInfiniteDisabled}
                                >
                                    <IonInfiniteScrollContent
                                        loadingSpinner="bubbles"
                                        loadingText={LOADING_MORE_DATA}
                                    ></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                        <IonFooter className="footer">
                            <Footer
                                onCloseModal={onCloseModal}
                                onSave={onSave}
                                isSaveDisabled={pending}
                                primaryBtnName={ADD}
                            />
                        </IonFooter>
                    </IonContent>
                </IonModal>
                <ProductFilters
                    showFilterModal={showFilterModal}
                    notifyFilterClose={onCloseFilterModal}
                />
            </>
        );
    }
);
