
/**
 * consumer listing
 */
export type FETCH_CONSUMER_LISTS_REQUEST =
    typeof FETCH_CONSUMER_LISTS_REQUEST;
export type FETCH_CONSUMER_LISTS_SUCCESS =
    typeof FETCH_CONSUMER_LISTS_SUCCESS;
export type FETCH_CONSUMER_LISTS_FAILURE =
    typeof FETCH_CONSUMER_LISTS_FAILURE;

export const FETCH_CONSUMER_LISTS_REQUEST = "FETCH_CONSUMER_LISTS_REQUEST";
export const FETCH_CONSUMER_LISTS_SUCCESS = "FETCH_CONSUMER_LISTS_SUCCESS";
export const FETCH_CONSUMER_LISTS_FAILURE = "FETCH_CONSUMER_LISTS_FAILURE";


/**
 * Update consumer by actions
 */
export type POST_BULK_ACTION_REQUEST = typeof POST_BULK_ACTION_REQUEST;
export type POST_BULK_ACTION_SUCCESS = typeof POST_BULK_ACTION_SUCCESS;
export type POST_BULK_ACTION_FAILURE = typeof POST_BULK_ACTION_FAILURE;

export const POST_BULK_ACTION_REQUEST = "POST_BULK_ACTION_REQUEST";
export const POST_BULK_ACTION_SUCCESS = "POST_BULK_ACTION_SUCCESS";
export const POST_BULK_ACTION_FAILURE = "POST_BULK_ACTION_FAILURE";


/**
 * updateconsumercheckbox
 */
export type UPDATE_CONSUMER_SELECTED = typeof UPDATE_CONSUMER_SELECTED;
export const UPDATE_CONSUMER_SELECTED = "UPDATE_CONSUMER_SELECTED";


/**
 * ReSet fetch users
 */
export type RESET_FETCH_CONSUMERS = typeof RESET_FETCH_CONSUMERS;
export const RESET_FETCH_CONSUMERS = "RESET_FETCH_CONSUMERS";

/**
 * Fetch payroll deduct grps
 */
export type FETCH_PAYROLLGRP_REQUEST = typeof FETCH_PAYROLLGRP_REQUEST;
export type FETCH_PAYROLLGRP_SUCCESS = typeof FETCH_PAYROLLGRP_SUCCESS;
export type FETCH_PAYROLLGRP_FAILURE = typeof FETCH_PAYROLLGRP_FAILURE;

export const FETCH_PAYROLLGRP_REQUEST = 'FETCH_PAYROLLGRP_REQUEST';
export const FETCH_PAYROLLGRP_SUCCESS = 'FETCH_PAYROLLGRP_SUCCESS';
export const FETCH_PAYROLLGRP_FAILURE = 'FETCH_PAYROLLGRP_FAILURE';


/**
 * Fetch subsidy  grps
 */
export type FETCH_SUBSIDYGRP_REQUEST = typeof FETCH_SUBSIDYGRP_REQUEST;
export type FETCH_SUBSIDYGRP_SUCCESS = typeof FETCH_SUBSIDYGRP_SUCCESS;
export type FETCH_SUBSIDYGRP_FAILURE = typeof FETCH_SUBSIDYGRP_FAILURE;

export const FETCH_SUBSIDYGRP_REQUEST = 'FETCH_SUBSIDYGRP_REQUEST';
export const FETCH_SUBSIDYGRP_SUCCESS = 'FETCH_SUBSIDYGRP_SUCCESS';
export const FETCH_SUBSIDYGRP_FAILURE = 'FETCH_SUBSIDYGRP_FAILURE';


/**
 * consumer balances
 */
export type FETCH_CONSUMER_BALANCES_REQUEST =
    typeof FETCH_CONSUMER_BALANCES_REQUEST;
export type FETCH_CONSUMER_BALANCES_SUCCESS =
    typeof FETCH_CONSUMER_BALANCES_SUCCESS;
export type FETCH_CONSUMER_BALANCES_FAILURE =
    typeof FETCH_CONSUMER_BALANCES_FAILURE;

export const FETCH_CONSUMER_BALANCES_REQUEST = "FETCH_CONSUMER_BALANCES_REQUEST";
export const FETCH_CONSUMER_BALANCES_SUCCESS = "FETCH_CONSUMER_BALANCES_SUCCESS";
export const FETCH_CONSUMER_BALANCES_FAILURE = "FETCH_CONSUMER_BALANCES_FAILURE";

/**
 * consumer identifiers
 */
export type FETCH_CONSUMER_IDENTIFIERS_REQUEST =
    typeof FETCH_CONSUMER_IDENTIFIERS_REQUEST;
export type FETCH_CONSUMER_IDENTIFIERS_SUCCESS =
    typeof FETCH_CONSUMER_IDENTIFIERS_SUCCESS;
export type FETCH_CONSUMER_IDENTIFIERS_FAILURE =
    typeof FETCH_CONSUMER_IDENTIFIERS_FAILURE;

export const FETCH_CONSUMER_IDENTIFIERS_REQUEST = "FETCH_CONSUMER_IDENTIFIERS_REQUEST";
export const FETCH_CONSUMER_IDENTIFIERS_SUCCESS = "FETCH_CONSUMER_IDENTIFIERS_SUCCESS";
export const FETCH_CONSUMER_IDENTIFIERS_FAILURE = "FETCH_CONSUMER_IDENTIFIERS_FAILURE";

/**
 * Post Email update
 */
export type POST_RESEND_EMAIL_REQUEST = typeof POST_RESEND_EMAIL_REQUEST;
export type POST_RESEND_EMAIL_SUCCESS = typeof POST_RESEND_EMAIL_SUCCESS;
export type POST_RESEND_EMAIL_FAILURE = typeof POST_RESEND_EMAIL_FAILURE;

export const POST_RESEND_EMAIL_REQUEST = "POST_RESEND_EMAIL_REQUEST";
export const POST_RESEND_EMAIL_SUCCESS = "POST_RESEND_EMAIL_SUCCESS";
export const POST_RESEND_EMAIL_FAILURE = "POST_RESEND_EMAIL_FAILURE";


/**
 * Post Add funds
 */
export type POST_ADD_FUNDS_REQUEST = typeof POST_ADD_FUNDS_REQUEST;
export type POST_ADD_FUNDS_SUCCESS = typeof POST_ADD_FUNDS_SUCCESS;
export type POST_ADD_FUNDS_FAILURE = typeof POST_ADD_FUNDS_FAILURE;

export const POST_ADD_FUNDS_REQUEST = "POST_ADD_FUNDS_REQUEST";
export const POST_ADD_FUNDS_SUCCESS = "POST_ADD_FUNDS_SUCCESS";
export const POST_ADD_FUNDS_FAILURE = "POST_ADD_FUNDS_FAILURE";


/**
 * New consumer by post
 */
export type POST_NEW_CONSUMER_REQUEST = typeof POST_NEW_CONSUMER_REQUEST;
export type POST_NEW_CONSUMER_SUCCESS = typeof POST_NEW_CONSUMER_SUCCESS;
export type POST_NEW_CONSUMER_FAILURE = typeof POST_NEW_CONSUMER_FAILURE;

export const POST_NEW_CONSUMER_REQUEST = "POST_NEW_CONSUMER_REQUEST";
export const POST_NEW_CONSUMER_SUCCESS = "POST_NEW_CONSUMER_SUCCESS";
export const POST_NEW_CONSUMER_FAILURE = "POST_NEW_CONSUMER_FAILURE";

/**
 * Delete Consumer scan code
 */
export type DELETE_CONSUMER_SCAN_CODE = typeof DELETE_CONSUMER_SCAN_CODE;
export type DELETE_CONSUMER_SCAN_CODE_SUCCESS =
    typeof DELETE_CONSUMER_SCAN_CODE_SUCCESS;
export type DELETE_CONSUMER_SCAN_CODE_FAILURE =
    typeof DELETE_CONSUMER_SCAN_CODE_FAILURE;
export const DELETE_CONSUMER_SCAN_CODE = "DELETE_CONSUMER_SCAN_CODE";
export const DELETE_CONSUMER_SCAN_CODE_SUCCESS =
    "DELETE_CONSUMER_SCAN_CODE_SUCCESS";
export const DELETE_CONSUMER_SCAN_CODE_FAILURE =
    "DELETE_CONSUMER_SCAN_CODE_FAILURE";


    /**
 * Post Identifiers
 */
export type POST_IDENTIFIERS_REQUEST = typeof POST_IDENTIFIERS_REQUEST;
export type POST_IDENTIFIERS_SUCCESS = typeof POST_IDENTIFIERS_SUCCESS;
export type POST_IDENTIFIERS_FAILURE = typeof POST_IDENTIFIERS_FAILURE;

export const POST_IDENTIFIERS_REQUEST = "POST_IDENTIFIERS_REQUEST";
export const POST_IDENTIFIERS_SUCCESS = "POST_IDENTIFIERS_SUCCESS";
export const POST_IDENTIFIERS_FAILURE = "POST_IDENTIFIERS_FAILURE";


/**
 * Patch Consumer
 */
export type PATCH_CONSUMER_REQUEST = typeof PATCH_CONSUMER_REQUEST;
export type PATCH_CONSUMER_SUCCESS = typeof PATCH_CONSUMER_SUCCESS;
export type PATCH_CONSUMER_FAILURE = typeof PATCH_CONSUMER_FAILURE;

export const PATCH_CONSUMER_REQUEST = "PATCH_CONSUMER_REQUEST";
export const PATCH_CONSUMER_SUCCESS = "PATCH_CONSUMER_SUCCESS";
export const PATCH_CONSUMER_FAILURE = "PATCH_CONSUMER_FAILURE";


/**
 * Post New Consumer Subsidy grp
 */
export type POST_SUBSIDYGRP_REQUEST = typeof POST_SUBSIDYGRP_REQUEST;
export type POST_SUBSIDYGRP_SUCCESS = typeof POST_SUBSIDYGRP_SUCCESS;
export type POST_SUBSIDYGRP_FAILURE = typeof POST_SUBSIDYGRP_FAILURE;

export const POST_SUBSIDYGRP_REQUEST = "POST_SUBSIDYGRP_REQUEST";
export const POST_SUBSIDYGRP_SUCCESS = "POST_SUBSIDYGRP_SUCCESS";
export const POST_SUBSIDYGRP_FAILURE = "POST_SUBSIDYGRP_FAILURE";