import HpDescriptor from './login-hp';
import CpDescriptor from './login-cp';

import { LoginDescriberData } from '../../../models/UserProfile';
import { PortalType } from '../../../constants/constant';

export type GetDescriberType = {
  imageDescriptor: (props: LoginDescriberData) => React.ReactNode;
  routeDescriptor: (props: LoginDescriberData) => void;
};

const typeDescriber = {
  [PortalType.HP]: HpDescriptor,
  [PortalType.CP]: CpDescriptor,
};

export function getDescriber(model: string): GetDescriberType {
  return typeDescriber[model as keyof typeof typeDescriber];
}
