//vendors
import { useAuth0 } from "@auth0/auth0-react";
import {
    IonCol,
    IonGrid,  
    IonRow,
    isPlatform,
    useIonRouter,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { PortalContext } from "../..";
//components
import LoginButton from "../../components/Auth0/LoginButton";
import { VerifyEmailModalForm } from "../../components/VerifyEmailModal/verifyEmailModal";
import LoaderWithMessage from "../../components/common/loader/LoaderWithMessage";
//constants
import { Routes } from "../../constants/Routes";
import { LOGIN_LOADER_MSG } from "../Payment/constants";
//utils
import envChange from '../../utils/changeEnvironment';
import { getDescriber } from './Descriptor/DescriptorType';

const Login: React.FC<any> = (props) => {
    const { isAuthenticated, isLoading } = useAuth0(); 
    const [count, setCount] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const location = useLocation();
    const { push } = useHistory()
    const router = useIonRouter();
    const { verifiedEmail } = props;
    const isDesktop = isPlatform("desktop") || isPlatform("tablet");
    const _portalTypes = useContext(PortalContext) as any;
    const { imageDescriptor,routeDescriptor } = getDescriber(_portalTypes);
    const modalProps = {
        closeModal,
        openModal,
    };

    function closeModal() {
        router.push(Routes.login.url);
        setOpenModal(false);
    }
    const userModalData = {
        ...modalProps,
    };

    const navigateToChangeEnv = () => {
        if (count === 4) push(Routes.changeEnvironment.url)
    }

    const checkLogoClick = () =>{
        setCount(count + 1); 
        navigateToChangeEnv()
    }

    useEffect(() => {
        verifiedEmail && setOpenModal(true);
        if (isAuthenticated && location.pathname === Routes.login.url) {
            routeDescriptor(descriptorProps)
        }
        if (localStorage.getItem('env')) {
            const getEnv = JSON.parse(localStorage.getItem('env') as string);
            envChange(getEnv)
        }
    });  
    useEffect(() => {
        verifiedEmail && setOpenModal(true);
    }, [verifiedEmail]);

    const redirectToConsumer = ()=>{
        router.push(Routes.dashboard.consumerListUrl)
    }

    const redirectToProducts = ()=>{
        router.push(Routes.dashboard.productsUrl)
    }
    
    const descriptorProps={
        checkLogoClick,
        redirectToConsumer,
        redirectToProducts
    } 
    
    return (
        <>
            <VerifyEmailModalForm
                {...userModalData}
                verifiedEmail={verifiedEmail}
            />
           <LoaderWithMessage isOpen={isLoading && !isAuthenticated} message={LOGIN_LOADER_MSG} />  
            <IonGrid className="pwd-update-screen h-100">
                <IonRow className={isDesktop ? "content" : "content mobile"}>
                    <IonCol
                        className="login-content-padding"
                        size="12"
                        sizeXs="12"
                        sizeSm="6"
                        sizeMd="5"
                    >
                        {imageDescriptor(descriptorProps)}         
                    </IonCol>
                    <IonCol
                        className="login-content-padding"
                        size="12"
                        sizeXs="12"
                        sizeSm="6"
                        sizeMd="5"
                    >
                        {isAuthenticated ? "" : !isLoading && <LoginButton />}
                     </IonCol>
                </IonRow>
            </IonGrid>  
        </>
    );
};

export default Login;