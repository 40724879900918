import AddFunds from './add-funds';
import PayCycleGroup from './pay-cycle-group';
import PinReset from './pin-reset';
import SubsidyGroup from './subsidy-group';
import TopOff from './top-off';

import { ConsumerActionsDescriberData } from '../../../models/ConsumerActions';

export enum ActionsType {
  ADD_FUNDS = 'addFunds',
  PAY_CYCLE_GROUP = 'payCycleGrp',
  PIN_RESET = 'pinReset',
  SUBSIDY_GROUP = 'subsidyGrp',
  TOP_OFF = 'topOff',
}

export type GetDescriberType = {
  titleDescriptor: () => string;
  contentDescriptor:(props:ConsumerActionsDescriberData)=>void
};

const typeDescriber = {
  [ActionsType.ADD_FUNDS]: AddFunds,
  [ActionsType.PAY_CYCLE_GROUP]: PayCycleGroup,
  [ActionsType.PIN_RESET]: PinReset,
  [ActionsType.SUBSIDY_GROUP]: SubsidyGroup,
  [ActionsType.TOP_OFF]: TopOff
};

export function getDescriber(model: string): GetDescriberType {
  return typeDescriber[model as keyof typeof typeDescriber];
}
