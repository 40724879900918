
import { IonButton } from "@ionic/react";
import React, { useState } from "react";
import { AddProductPopup } from "./AddProductButton";
import {AddSelfOperated} from './AddSelfOperated';

import {
    updateProductCategoryFilters
} from "../../../_redux/actions/productActions";
import { useDispatch } from "react-redux";

const AddProduct: React.FC<any> = ({onHeaderButtonClick, current, buttonLabel, buttonClassName, setShowGlobalPop, showGlobalPop, isSelfOperated}) => {
    const dispatch = useDispatch();
    const [buttonState, setButtonState] = useState(false);

    const renderAddProductPopup = ()=>{
        return (<AddProductPopup
            showAddPrdtModal={buttonState}
            setButtonState={setButtonState}
            setShowGlobalPop={setShowGlobalPop}
            showGlobalPop={showGlobalPop}
        />);
    }

    const renderSelfOperatedPopup = () =>{
        return (<AddSelfOperated
            showAddPrdtModal={buttonState}
            setButtonState={setButtonState}
            setShowGlobalPop={setShowGlobalPop}
            showGlobalPop={showGlobalPop}
        />);
    }
    return (
        <>
            <IonButton
                size="small"
                color={buttonState ? "primary" : undefined}
                fill={
                    ["ADD PRODUCT", "ADD NEW PRODUCT"].indexOf(current) > -1
                        ? "solid"
                        : undefined
                }
                className={`${buttonClassName}`}
                onClick={(e) => {
                    onHeaderButtonClick(e);
                    !isSelfOperated && dispatch(updateProductCategoryFilters([]));
                    setButtonState((pv) => !pv);
                }}
            >
                {buttonLabel}
            </IonButton>
            {!isSelfOperated ? renderAddProductPopup(): renderSelfOperatedPopup()}
        </>
    );
}

export default AddProduct;
