import { DateTime } from "luxon";
import { Hotel365Portal,ClientPortal } from "./../../constants/constant";
import {
    FetchConsumerList,
    FetchConsumerListSuccess,
    FetchConsumerListFailure,
    FetchConsumerListType,
    PostConsumerBulkActionType,
    PostConsumerBulkAction,
    PostConsumerBulkActionFailure,
    PostConsumerBulkActionSuccess,
    UpdateConsumerCheckboxType,
    UpdateConsumerCheckbox,
    ResetFetchConsumersType,
    ResetFetchConsumers,
    FetchSubsidyGrp,
    FetchSubsidyGrpSuccess,
    FetchSubsidyGrpFailure,
    FetchSubsidyGrpType,
    FetchPayrollGrp,
    FetchPayrollGrpSuccess,
    FetchPayrollGrpFailure,
    FetchPayrollGrpType,
    FetchConsumerBalances,
    FetchConsumerBalancesSuccess,
    FetchConsumerBalancesFailure,
    FetchConsumerBalancesType,
    PostConsumerResendEmail,
    PostConsumerResendEmailSuccess,
    PostConsumerResendEmailFailure,
    PostConsumerResendEmailType,
    PostConsumerAddFunds,
    PostConsumerAddFundsSuccess,
    PostConsumerAddFundsFailure,
    PostConsumerAddFundsType,
    FetchConsumerIdentifiers,
    FetchConsumerIdentifiersSuccess,
    FetchConsumerIdentifiersFailure,
    FetchConsumerIdentifiersType,
    PostNewConsumer,
    PostNewConsumerSuccess,
    PostNewConsumerFailure,
    PostNewConsumerType,
    DeleteConsumerScanCode,
    DeleteConsumerScanCodeSuccess,
    DeleteConsumerScanCodeFailure,
    DeleteConsumerScanCodeType,
    PostConsumerIdentifiers,
    PostConsumerIdentifiersSuccess,
    PostConsumerIdentifiersFailure,
    PostConsumerIdentifiersType,
    PatchConsumer,
    PatchConsumerSuccess,
    PatchConsumerFailure,
    PatchConsumerType,
    PostConsumerSubsidyGrp,
    PostConsumerSubsidyGrpSuccess,
    PostConsumerSubsidyGrpFailure,
    PostConsumerSubsidyGrpType,
} from "../actions/consumerActions";

import {
    ConsumerPagination,
    PayrollGrp,
    SubsidyGrp,
    Identifiers,
    Balances,
} from "../../models/Consumer";
import { getDisplayName, removeDuplicateObjects } from "../../utils";

export type ConsumerTypes =
    | FetchConsumerListType
    | PostConsumerBulkActionType
    | UpdateConsumerCheckboxType
    | ResetFetchConsumersType
    | FetchPayrollGrpType
    | FetchSubsidyGrpType
    | PostConsumerResendEmailType
    | FetchConsumerBalancesType
    | PostConsumerAddFundsType
    | FetchConsumerIdentifiersType
    | PostNewConsumerType
    | DeleteConsumerScanCodeType
    | PostConsumerIdentifiersType
    | PostConsumerSubsidyGrpType
    | PatchConsumerType;

const initialState = {
    pending: false,
    error: null,
    consumerLists: [],
    cummulativeConsumers: [],
    consumerPaginate: {} as ConsumerPagination,
    bulkActionResponse: {} as Record<string, any>,
    consumerChecked: [],
    subsidyGrps: [],
    payrollGrps: [],
    consumerListReq: null,
    consumerBalances: [] as Balances[],
    consumerIdentifiers: [] as Identifiers[],
    consumerPayrollIdIdentifiers: [] as Identifiers[],
    ResenEmailResponse: {} as Record<string, any>,
    consumerAddFunds: {} as Record<string, any>,
    accountId: null,
    createdConsumer: {} as Record<string, any>,
    removedConsumerScanCode: {},
    updateConsumerIdentifier: {} as Record<string, any>,
    updatedConsumerResponse: {} as Record<string, any>,
    createdSubsidyGrp: {} as Record<string, any>,
};

export interface IConsumerState {
    pending: boolean;
    error: Record<string, any> | null;
    consumerLists: Record<string, any>[];
    cummulativeConsumers: Record<string, any>[];
    consumerPaginate: ConsumerPagination;
    bulkActionResponse: Record<string, any>;
    consumerChecked: string[];
    subsidyGrps: SubsidyGrp[];
    payrollGrps: PayrollGrp[];
    consumerListReq: Record<string, any> | null;
    consumerBalances: Balances[];
    consumerIdentifiers: Identifiers[];
    consumerPayrollIdIdentifiers: Identifiers[];
    ResenEmailResponse: Record<string, any>;
    consumerAddFunds: Record<string, any>;
    accountId: string | null;
    createdConsumer: Record<string, any>;
    removedConsumerScanCode: Record<string, unknown>;
    updateConsumerIdentifier: Record<string, any>;
    updatedConsumerResponse: Record<string, any>;
    createdSubsidyGrp: Record<string, any>;
}

const strategies = {
    UPDATE_CONSUMER_SELECTED: updateConsumerCheckboxSelected,
    FETCH_CONSUMER_LISTS_REQUEST: initConsumerCall,
    FETCH_CONSUMER_LISTS_SUCCESS: fetchConsumerListsSuccess,
    FETCH_CONSUMER_LISTS_FAILURE: consumerCallFailure,

    POST_BULK_ACTION_REQUEST: initConsumerCall,
    POST_BULK_ACTION_SUCCESS: postConsumerBulkSuccess,
    POST_BULK_ACTION_FAILURE: consumerCallFailure,
    RESET_FETCH_CONSUMERS: resetFetchConsumers,

    FETCH_PAYROLLGRP_REQUEST: initConsumerCall,
    FETCH_PAYROLLGRP_SUCCESS: fetchPayrollGrpsSuccess,
    FETCH_PAYROLLGRP_FAILURE: consumerCallFailure,

    FETCH_SUBSIDYGRP_REQUEST: initConsumerCall,
    FETCH_SUBSIDYGRP_SUCCESS: fetchSubsidyGrpsSuccess,
    FETCH_SUBSIDYGRP_FAILURE: consumerCallFailure,

    FETCH_CONSUMER_BALANCES_REQUEST: initConsumerCall,
    FETCH_CONSUMER_BALANCES_SUCCESS: getConsumerBalances,
    FETCH_CONSUMER_BALANCES_FAILURE: consumerCallFailure,

    FETCH_CONSUMER_IDENTIFIERS_REQUEST: initConsumerCall,
    FETCH_CONSUMER_IDENTIFIERS_SUCCESS: getConsumerIdentifiers,
    FETCH_CONSUMER_IDENTIFIERS_FAILURE: consumerCallFailure,

    POST_RESEND_EMAIL_REQUEST: initConsumerCall,
    POST_RESEND_EMAIL_SUCCESS: postConsumerResendEmailSuccess,
    POST_RESEND_EMAIL_FAILURE: consumerCallFailure,

    POST_ADD_FUNDS_REQUEST: initConsumerCall,
    POST_ADD_FUNDS_SUCCESS: postConsumerAddFundsSuccess,
    POST_ADD_FUNDS_FAILURE: consumerCallFailure,

    POST_NEW_CONSUMER_REQUEST: initConsumerCall,
    POST_NEW_CONSUMER_SUCCESS: postNewConsumerSuccess,
    POST_NEW_CONSUMER_FAILURE: consumerCallFailure,

    DELETE_CONSUMER_SCAN_CODE: initConsumerCall,
    DELETE_CONSUMER_SCAN_CODE_SUCCESS: deleteConsumerScanCode,
    DELETE_CONSUMER_SCAN_CODE_FAILURE: consumerCallFailure,

    POST_IDENTIFIERS_REQUEST: initConsumerCall,
    POST_IDENTIFIERS_SUCCESS: postConsumerIdentifierSuccess,
    POST_IDENTIFIERS_FAILURE: consumerCallFailure,

    PATCH_CONSUMER_REQUEST: initConsumerCall,
    PATCH_CONSUMER_SUCCESS: patchConsumerSuccessCallback,
    PATCH_CONSUMER_FAILURE: consumerCallFailure,

    POST_SUBSIDYGRP_REQUEST: initConsumerCall,
    POST_SUBSIDYGRP_SUCCESS: PostConsumerSubsidyGrpSuccessCallback,
    POST_SUBSIDYGRP_FAILURE: consumerCallFailure,

    default: (state: IConsumerState) => state,
};

function initConsumerCall(
    state: IConsumerState,
    _action:
        | FetchConsumerList
        | PostConsumerBulkAction
        | FetchSubsidyGrp
        | FetchPayrollGrp
        | FetchConsumerBalances
        | PostConsumerResendEmail
        | PostConsumerAddFunds
        | FetchConsumerIdentifiers
        | PostNewConsumer
        | DeleteConsumerScanCode
        | PostConsumerIdentifiers
        | PostConsumerSubsidyGrp
        | PatchConsumer
) {
    return {
        ...state,
        pending: true,
        error: _action.type !== "POST_SUBSIDYGRP_REQUEST" ? null : state.error,
        consumerListReq:
            _action.type === "FETCH_CONSUMER_LISTS_REQUEST"
                ? _action.data
                : state.consumerListReq,
        accountId:
            _action.type === "FETCH_CONSUMER_IDENTIFIERS_REQUEST"
                ? _action.accountId
                : state.accountId,
    };
}

function consumerCallFailure(
    state: IConsumerState,
    action:
        | PostConsumerBulkActionFailure
        | FetchConsumerListFailure
        | FetchSubsidyGrpFailure
        | FetchPayrollGrpFailure
        | PostConsumerResendEmailFailure
        | FetchConsumerBalancesFailure
        | PostConsumerAddFundsFailure
        | FetchConsumerIdentifiersFailure
        | PostNewConsumerFailure
        | DeleteConsumerScanCodeFailure
        | PostConsumerIdentifiersFailure
        | PatchConsumerFailure
        | PostConsumerSubsidyGrpFailure
) {
    let errorMessage: any = action.error;
    if(['POST_RESEND_EMAIL_FAILURE'].indexOf(action.type) !==  -1 && (action.error && Object.keys(action.error).length > 0 && action.error.status === 422)){
        errorMessage = {status: 422 , message : ClientPortal.consumer_email_already_exists} ;
    }
    if(['POST_NEW_CONSUMER_FAILURE',  "POST_IDENTIFIERS_FAILURE"].indexOf(action.type) !==  -1 && (action.error && Object.keys(action.error).length > 0 && action.error.status === 400))
    {   
        const getErrObj = action.type === "POST_IDENTIFIERS_FAILURE" ? errorMessage.violations[0]: errorMessage.res[0]
        errorMessage = getErrObj.message.includes('type:0') ? {status: 400 , message : ClientPortal.consumer_upc_exits} : getErrObj.message.includes('type:6') ? {status: 400 , message : ClientPortal.consumer_payroll_exists}:{status: 400 , message : getErrObj.field == 'Email'? ClientPortal.consumer_email_already_exists: getErrObj.message};
    }
    return {
        ...state,
        pending: false,
        error: errorMessage,
    };
}

function fetchConsumerListsSuccess(
    state: IConsumerState,
    action: FetchConsumerListSuccess
) {
    let consumers: any[] = [];
    /**to Avoid duplicates from an array */
    consumers = state.cummulativeConsumers
        .filter(
            (aa) =>
                !action.data.items.find(
                    (bb: Record<string, any>) => aa?.accountId === bb?.accountId
                )
        )
        .concat(action.data.items);
    const finalConsumersList = action.data.items.map(
        (item: Record<string, any>) => {
            item.hasFingerPrint = item.hasFingerPrint ? "Y" : "N";
            item.balance = item.balance || 0;
            item.hasApp = item.hasApp ? "Y" : "N";
            item.firstName = item.firstName || "null";
            item.lastName = item.lastName || "null"
            item.isChecked = false;
            item.lastUpdated =
                item.lastUpdated &&
                DateTime.fromJSDate(new Date(item.lastUpdated)).toFormat(
                    "MM/dd/yyyy"
                );
            return item;
        }
    );
    return {
        ...state,
        pending: false,
        consumerLists: removeDuplicateObjects(finalConsumersList, 'accountId'),
        consumerPaginate: action.data.metadata.pagination,
        cummulativeConsumers: removeDuplicateObjects(consumers, 'accountId'),
        bulkActionResponse: {},
        error: null,
    };
}

function postConsumerBulkSuccess(
    state: IConsumerState,
    action: PostConsumerBulkActionSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        bulkActionResponse: action.data,
        error: null,
    });
}

function updateConsumerCheckboxSelected(
    state: IConsumerState,
    action: UpdateConsumerCheckbox
) {
    let result = state.consumerLists.map(obj => {
        return {
          ...obj,
          isChecked: action.info.includes(obj.accountId) ? true : false
        }
    })
    return Object.assign({}, state, {
        ...state,
        consumerChecked: action.info,
        consumerLists:result
    });
}

function resetFetchConsumers(
    state: IConsumerState,
    action: ResetFetchConsumers
) {
    const { info } = action;
    return Object.assign({}, state, {
        ...state,
        consumerLists: !info ? [] : state.consumerLists,
        cummulativeConsumers: !info ? [] : state.cummulativeConsumers,
        consumerPaginate: !info
            ? ({} as ConsumerPagination)
            : state.consumerPaginate,
        consumerBalances: !info ? [] : state.consumerBalances,
        consumerIdentifiers: !info ? [] : state.consumerIdentifiers,
        consumerPayrollIdIdentifiers: !info
            ? []
            : state.consumerPayrollIdIdentifiers,
        ResenEmailResponse: {},
        consumerAddFunds: {},
        createdConsumer: {},
        accountId: !info ? "" : state.accountId,
        updatedConsumerResponse: {},
        updatedConsumerPinResponse: {},
        removedConsumerScanCode: {},
        updateConsumerIdentifier: {},
        createdSubsidyGrp: {},
        bulkActionResponse: {},
        subsidyGrps: info === 'resetErrorMessage'? state.subsidyGrps: [],
        payrollGrps: info === 'resetErrorMessage'? state.payrollGrps: [],
        error: null,
    });
}

function fetchPayrollGrpsSuccess(
    state: IConsumerState,
    action: FetchPayrollGrpSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        payrollGrps: action.data,
        error: null,
    });
}

function fetchSubsidyGrpsSuccess(
    state: IConsumerState,
    action: FetchSubsidyGrpSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        subsidyGrps: action.data,
        error: null,
    });
}

function getConsumerBalances(
    state: IConsumerState,
    action: FetchConsumerBalancesSuccess
) {
    const balances = Object.assign([], action.data); // to clone an array
    let finalList: Balances[] = [];
    let result = [] as any;
    if (balances.length > 0) {
        finalList = balances.map((item: Record<string, any>) => {
            //As per SOS_40606, we will be showing all the balances even if the amount is 0
                item.displayName = getDisplayName(item);
                return item;
        }) as Balances[];
        /**to remove undefined from the array */
        finalList = finalList.filter((item) => item);
        /**to sum of the balance based on display name */
        result = finalList.reduce(function (
            accumulator: Record<string, any>,
            cur: Record<string, any>
        ) {
            const name = cur.displayName,
                found = accumulator.find(function (elem: Record<string, any>) {
                    return elem.displayName == name;
                });
            if (found) found.balance += cur.balance;
            else accumulator.push(cur);
            return accumulator;
        },
        []);
    }
    return Object.assign({}, state, {
        ...state,
        pending: false,
        consumerBalances: result,
        error: null,
    });
}

function getConsumerIdentifiers(
    state: IConsumerState,
    action: FetchConsumerIdentifiersSuccess
) {
    if(action.identifier === 0){
        return Object.assign({}, state, {
            ...state,
            pending: false,
            consumerIdentifiers: action.data?.filter((item) => item.type === 0),
            error: null,
        });
    } else{
        return Object.assign({}, state, {
            ...state,
            pending: false,
            consumerPayrollIdIdentifiers: action.data?.filter(
                (item) => item.type === 6
            ),
            error: null,
        }); 
    }
}

function postConsumerResendEmailSuccess(
    state: IConsumerState,
    action: PostConsumerResendEmailSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        ResenEmailResponse: action.data,
        error: null,
    });
}

function postConsumerAddFundsSuccess(
    state: IConsumerState,
    action: PostConsumerAddFundsSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        consumerAddFunds: action.data,
        error: null,
    });
}

function postNewConsumerSuccess(
    state: IConsumerState,
    action: PostNewConsumerSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        createdConsumer: action.data,
    });
}

function deleteConsumerScanCode(
    state: IConsumerState,
    action: DeleteConsumerScanCodeSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        removedConsumerScanCode: action.data,
        error: null,
    });
}

function postConsumerIdentifierSuccess(
    state: IConsumerState,
    action: PostConsumerIdentifiersSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        updateConsumerIdentifier: action.data,
        error: null,
    });
}

function patchConsumerSuccessCallback(
    state: IConsumerState,
    action: PatchConsumerSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        updatedConsumerResponse: action.data,
    });
}

function PostConsumerSubsidyGrpSuccessCallback(
    state: IConsumerState,
    action: PostConsumerSubsidyGrpSuccess
) {
    return Object.assign({}, state, {
        ...state,
        pending: false,
        createdSubsidyGrp: action.data,
        error: null,
    });
}

export default (
    state: IConsumerState = initialState,
    action: ConsumerTypes
): IConsumerState => {
    return (strategies[action.type] || strategies.default)(
        state,
        action as never
    );
};
