//vendors
import { useState, useMemo } from "react";
import { IonRow, IonSpinner, IonButton, isPlatform, IonCol } from "@ionic/react";
import { IgrDataGrid, IgrTextColumn, IgrDataGridToolbar, IgrNumericColumn } from "igniteui-react-grids";
import { useSelector, shallowEqual } from "react-redux";
import { DateTime } from "luxon";
//reducer
import { RootState } from "../../_redux/reducers/rootReducer";
//components
import ToExcel from '../../components/ToExcel';
//constants
import { ReportGridSection, REPORT_NO_DATA, reportColumnsAppendWithDollar, reportColumnsAppendWithPercentage } from '../../constants/constant';
//utils
import { getReportHeaders, getReportData, getReportRecordsForExcel } from '../../utils';

const DataGrid: React.FC<any> = ({ selectedReport, runReport }) => {
    let _grid: IgrDataGrid;
    let _toolbar: IgrDataGridToolbar;
    const isDesktop = isPlatform("desktop");
    const istablet = isPlatform("tablet");
    const isMobile = isPlatform("mobile");
    const { id: reportType, name: reportName } = selectedReport;
    const reportsData = useSelector((state: RootState) => { return state.reports }, shallowEqual);
    const profile = useSelector((state: RootState) => { return state.profile; }, shallowEqual);
    const { data, pending } = reportsData;
    const { currentProfile } = profile;
    const { reportHeaders = {}, start, end, } = data;
    const [records, setRecords] = useState([]);
    const [recordsForExcel, setRecordsForExcel] = useState<any>([]);
    const fromDate = start && DateTime.fromJSDate(new Date(start)).toFormat('MM/dd/yyyy');
    const toDate = end && DateTime.fromJSDate(new Date(end)).toFormat('MM/dd/yyyy');
    const fromTime = start && DateTime.fromJSDate(new Date(start)).toLocaleString(DateTime.TIME_SIMPLE);
    const toTime = end && DateTime.fromJSDate(new Date(end)).toLocaleString(DateTime.TIME_SIMPLE);

    const onGridRef = (grid: IgrDataGrid) => {
        if (!grid) {
            return;
        }
        _grid = grid;
        if (_toolbar !== null) {
            _toolbar.targetGrid = _grid;
        }
    };

    const onToolbarRef = (toolbar: IgrDataGridToolbar) => {
        _toolbar = toolbar;
        if (toolbar !== null) {
            _toolbar.targetGrid = _grid;
        }
    }

    const renderCols = () => {
        const headerObject = getReportHeaders(reportType, reportHeaders);
        return (Object.keys(getReportHeaders(reportType, reportHeaders)).map((key) => {
            // if (reportColumnsAppendWithDollar.includes(key)) {
            //     return (
            //         <IgrNumericColumn
            //             key={key}
            //             name={key}
            //             field={key}
            //             minFractionDigits="2"
            //             isFilteringEnabled={false}
            //             horizontalAlignment="center"
            //             isColumnOptionsSummariesEnabled={false}
            //             headerText={headerObject[key]}
            //         />
            //     )
            // } else if (reportColumnsAppendWithPercentage.includes(key)) {
            //     return (
            //         <IgrNumericColumn
            //             key={key}
            //             name={key}
            //             field={key}
            //             positiveSuffix="%"
            //             isFilteringEnabled={false}
            //             horizontalAlignment="center"
            //             isColumnOptionsSummariesEnabled={false}
            //             headerText={headerObject[key]}
            //         />
            //     )
            // } else {
            return (
                <IgrTextColumn
                    key={key}
                    name={key}
                    field={key}
                    isFilteringEnabled={false}
                    horizontalAlignment="center"
                    isColumnOptionsSummariesEnabled={false}
                    headerText={headerObject[key]}
                />
            )
            // }
        }) || []);
    };

    useMemo(() => {
        const { locationId, marketName } = currentProfile;
        const records = getReportData(reportType, { ...data }, locationId, marketName, currentProfile.currency);
        //@ts-ignore
        const updatedRecords = getReportRecordsForExcel(reportHeaders, records ? [...records] : [], reportType);
        setRecords(records);
        setRecordsForExcel(updatedRecords);
    }, [data]);

    const renderDataGrid = () => {
        return (
            <>
                <IonRow className={(isDesktop || istablet) ? "report-bar" : ''}>
                    {runReport &&
                        <IonCol className="report-bar-buttons">
                            <IonButton
                                fill="solid"
                                disabled={records?.length === 0}
                                className={(isDesktop || istablet) ? "report-to-excel" : isMobile ? 'report-to-excel-mobile' : ''}>
                                <ToExcel
                                    origin={'A3'}
                                    skipHeader={true}
                                    sheetName={reportName}
                                    records={recordsForExcel}
                                    reportName={reportName.replaceAll(' ', '')}
                                    reportHeaders={(() => {
                                        const headers = Object.values(getReportHeaders(reportType, reportHeaders));
                                        return [[`${reportName} - ${fromDate} ${fromTime} to ${toDate} ${toTime}`], headers];
                                    })()}
                                />
                            </IonButton>
                        </IonCol>
                    }
                </IonRow>
                {records && records?.length > 0 ?
                    <IonRow className="grid-toolbar-tbl-container" id="reporting-grid">
                        <IgrDataGridToolbar
                            ref={onToolbarRef}
                            columnChooser="true"
                            toolbarTitleStyle={isMobile || istablet && !isDesktop ?
                                ReportGridSection.gridToolbarTitleStyle :
                                ReportGridSection.gridDesktopToolbarTitleStyle
                            }
                            toolbarTitle={runReport && `${fromDate} ${fromTime} to ${toDate} ${toTime}`}
                        />
                        <IgrDataGrid
                            ref={onGridRef}
                            columnMovingMode="None"
                            dataSource={records}
                            autoAcceptEdits={false}
                            autoGenerateColumns={false}
                            isColumnOptionsEnabled="true"
                            headerSortIndicatorColor="#FFF"
                            height={records.length > 8 ? ReportGridSection.gridHeight : ReportGridSection.gridCalHeight}
                            width={ReportGridSection.gridWidth}
                            cellTextStyle={ReportGridSection.cellTextStyle}
                            scrollbarStyle={ReportGridSection.scrollbarStyle}
                            selectionMode={ReportGridSection.gridSelectionMode}
                            headerTextColor={ReportGridSection.gridHeaderTextColor}
                            headerTextStyle={ReportGridSection.gridHeaderTextStyle}
                            defaultColumnMinWidth={ReportGridSection.columnMinWidth}
                            headerBackground={ReportGridSection.gridHeaderBackground}
                        >
                            {renderCols()}
                        </IgrDataGrid>
                    </IonRow> :
                    <IonRow className={isDesktop || !istablet && !isMobile ? 'no-data-placeholder' : 'tablet-no-data-placeholder'}>
                        {REPORT_NO_DATA}
                    </IonRow>}
            </>
        )
    }
    return (<>{pending ? <IonSpinner name="circles" className={istablet ? "spinner-circles-tablet" : 'spinner-circles'} /> : renderDataGrid()}</>)
}
export default DataGrid;
