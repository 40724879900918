import { IonCol, IonIcon, IonItemDivider, IonLabel, IonRow } from "@ionic/react";
import { chevronForward } from "ionicons/icons";
//components
import CreditCardImages from "../../components/CreditCardImages";
//constants
import { paymentConstants } from "./constants";

const CardsList: React.FC<any> = ({ cards = [],redirectToDetails }) => {
    return (
        cards.map((card: Record<string, any>, index: number) => {
            const { name, cardIssuer, systemUserTokenId } = card;
            return (
                <IonRow key={index}>
                    <IonItemDivider
                        className="ion-no-padding"
                        onClick={(e) => {
                            e.preventDefault();
                            redirectToDetails(systemUserTokenId)
                        }}>
                        <IonCol className="ion-no-padding">
                            <div className="card-img-wrapper">
                                <CreditCardImages issuer={cardIssuer} />
                                <div className="card-details">
                                    <IonLabel>{paymentConstants.PAYMENT_CREDIET_CARD}</IonLabel>
                                    <IonLabel>{name}</IonLabel>
                                </div>
                                <IonIcon
                                    size="large"
                                    md={chevronForward}
                                    className="payment-arrow"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirectToDetails(systemUserTokenId);
                                    }} />
                            </div>
                        </IonCol>
                    </IonItemDivider>
                </IonRow>
            )
        })
    )
}

export default CardsList;