import { Order, Orders, OrderProducts, OrderProduct } from "../../models/Order";
import * as ORDERS from "../ActionTypes/orderTypes";

/**
 * fetch market order header
 */
export interface FetchMarketOrderHeader {
    type: ORDERS.FETCH_MARKET_ORDER_HEADER_REQUEST;
    locationId: string;
    orgId: string;
    offset: number | null;
}
export interface FetchMarketOrderHeaderSuccess {
    type: ORDERS.FETCH_MARKET_ORDER_HEADER_SUCCESS;
    data: Orders;
    locationId: string;
}
export interface FetchMarketOrderHeaderFailure {
    type: ORDERS.FETCH_MARKET_ORDER_HEADER_FAILURE;
    error: Record<string, any> | null;
}
export type FetchMarketOrderHeaderType =
    | FetchMarketOrderHeader
    | FetchMarketOrderHeaderSuccess
    | FetchMarketOrderHeaderFailure;

export function fetchMarketOrderHeader(
    locationId: string,
    orgId: string,
    offset: number | null
): FetchMarketOrderHeader {
    return {
        type: ORDERS.FETCH_MARKET_ORDER_HEADER_REQUEST,
        locationId,
        orgId,
        offset,
    };
}

export function fetchMarketOrderHeaderSuccess(
    data: Orders,
    locationId: string
): FetchMarketOrderHeaderSuccess {
    return {
        type: ORDERS.FETCH_MARKET_ORDER_HEADER_SUCCESS,
        data,
        locationId,
    };
}

export function fetchMarketOrderHeaderFailure(
    error: Record<string, any>
): FetchMarketOrderHeaderFailure {
    return {
        type: ORDERS.FETCH_MARKET_ORDER_HEADER_FAILURE,
        error,
    };
}

/**
 * post market order header
 */
export interface PostMarketOrderHeaderRequest {
    type: ORDERS.POST_MARKET_ORDER_HEADER_REQUEST;
    data: Record<string, any>;
}
export interface PostMarketOrderHeaderSuccess {
    type: ORDERS.POST_MARKET_ORDER_HEADER_SUCCESS;
    data: Order;
}
export interface PostMarketOrderHeaderFailure {
    type: ORDERS.POST_MARKET_ORDER_HEADER_FAILURE;
    error: Record<string, any> | null;
}
export type PostMarketOrderHeaderType =
    | PostMarketOrderHeaderRequest
    | PostMarketOrderHeaderSuccess
    | PostMarketOrderHeaderFailure;

export function postMarketOrderHeaderRequest(
    data: Record<string, any>
): PostMarketOrderHeaderRequest {
    return {
        type: ORDERS.POST_MARKET_ORDER_HEADER_REQUEST,
        data,
    };
}

export function postMarketOrderHeaderSuccess(
    data: Order
): PostMarketOrderHeaderSuccess {
    return {
        type: ORDERS.POST_MARKET_ORDER_HEADER_SUCCESS,
        data,
    };
}
export function postMarketOrderHeaderFailure(
    error: Record<string, any>
): PostMarketOrderHeaderFailure {
    return {
        type: ORDERS.POST_MARKET_ORDER_HEADER_FAILURE,
        error,
    };
}

export interface ResetMarketOrder {
    type: ORDERS.RESET_MARKET_ORDER;
    data?: Order[];
}

export type ResetMarketOrderType = ResetMarketOrder;

export function resetMarketOrder(data?: Order[]): ResetMarketOrderType {
    return {
        type: ORDERS.RESET_MARKET_ORDER,
        data,
    };
}

/**
 * fetch market order products
 */
export interface FetchMarketOrderProducts {
    type: ORDERS.FETCH_MARKET_ORDER_PRODUCT_REQUEST;
    marketOrderHeaderId: string;
    offset: number | null;
}
export interface FetchMarketOrderProductsSuccess {
    type: ORDERS.FETCH_MARKET_ORDER_PRODUCT_SUCCESS;
    data: OrderProducts;
}
export interface FetchMarketOrderProductsFailure {
    type: ORDERS.FETCH_MARKET_ORDER_PRODUCT_FAILURE;
    error: Record<string, any> | null;
}
export type FetchMarketOrderProductsType =
    | FetchMarketOrderProducts
    | FetchMarketOrderProductsSuccess
    | FetchMarketOrderProductsFailure;

export function fetchMarketOrderProducts(
    marketOrderHeaderId: string,
    offset: number | null
): FetchMarketOrderProducts {
    return {
        type: ORDERS.FETCH_MARKET_ORDER_PRODUCT_REQUEST,
        marketOrderHeaderId,
        offset,
    };
}

export function fetchMarketOrderProductsSuccess(
    data: OrderProducts
): FetchMarketOrderProductsSuccess {
    return {
        type: ORDERS.FETCH_MARKET_ORDER_PRODUCT_SUCCESS,
        data,
    };
}

export function fetchMarketOrderProductsFailure(
    error: Record<string, any>
): FetchMarketOrderProductsFailure {
    return {
        type: ORDERS.FETCH_MARKET_ORDER_PRODUCT_FAILURE,
        error,
    };
}

/**
 * fetch market order.
 */
export interface FetchMarketOrder {
    type: ORDERS.FETCH_MARKET_ORDER_REQUEST;
    marketOrderHeaderId: string;
}
export interface FetchMarketOrderSuccess {
    type: ORDERS.FETCH_MARKET_ORDER_SUCCESS;
    data: Order;
}
export interface FetchMarketOrderFailure {
    type: ORDERS.FETCH_MARKET_ORDER_FAILURE;
    error: Record<string, any> | null;
}
export type FetchMarketOrderType =
    | FetchMarketOrder
    | FetchMarketOrderSuccess
    | FetchMarketOrderFailure;

export function fetchMarketOrder(
    marketOrderHeaderId: string
): FetchMarketOrder {
    return {
        type: ORDERS.FETCH_MARKET_ORDER_REQUEST,
        marketOrderHeaderId,
    };
}

export function fetchMarketOrderSuccess(data: Order): FetchMarketOrderSuccess {
    return {
        type: ORDERS.FETCH_MARKET_ORDER_SUCCESS,
        data,
    };
}

export function fetchMarketOrderFailure(
    error: Record<string, any>
): FetchMarketOrderFailure {
    return {
        type: ORDERS.FETCH_MARKET_ORDER_FAILURE,
        error,
    };
}
/**
 * delete market order product
 */

export interface DeleteMarketOrderProduct {
    type: ORDERS.DELETE_MARKET_ORDER_PRODUCT;
    // info should contain an array of object . Each object shpuld contain marketOrderHeaderId, marketOrderItemId:string;
    info: Record<string, any>[];
}
export interface DeleteMarketOrderProductSuccess {
    type: ORDERS.DELETE_MARKET_ORDER_PRODUCT_SUCCESS;
    data: string;
}
export interface DeleteMarketOrderProductFailure {
    type: ORDERS.DELETE_MARKET_ORDER_PRODUCT_FAILURE;
    error: Error;
}
export type DeleteMarketOrderProductType =
    | DeleteMarketOrderProduct
    | DeleteMarketOrderProductSuccess
    | DeleteMarketOrderProductFailure;

export function deleteMarketOrderProduct(
    info: Record<string, any>[]
): DeleteMarketOrderProduct {
    return {
        type: ORDERS.DELETE_MARKET_ORDER_PRODUCT,
        info,
    };
}
export function deleteMarketOrderProductSuccess(
    data: string
): DeleteMarketOrderProductSuccess {
    return {
        type: ORDERS.DELETE_MARKET_ORDER_PRODUCT_SUCCESS,
        data,
    };
}
export function deleteMarketOrderProductFailure(
    error: Error
): DeleteMarketOrderProductFailure {
    return {
        type: ORDERS.DELETE_MARKET_ORDER_PRODUCT_FAILURE,
        error,
    };
}

export interface SaveMarketOrderProduct {
    type: ORDERS.SAVE_MARKET_ORDER_PRODUCT_REQUEST;
    // info should contain an array of object . Each object shpuld contain marketOrderHeaderId, marketOrderItemId:string;
    info: Record<string, any>[];
}
export interface SaveMarketOrderProductSuccess {
    type: ORDERS.SAVE_MARKET_ORDER_PRODUCT_SUCCESS;
    data: string;
}
export interface SaveMarketOrderProductFailure {
    type: ORDERS.SAVE_MARKET_ORDER_PRODUCT_FAILURE;
    error: Error;
}
export type SaveMarketOrderProductType =
    | SaveMarketOrderProduct
    | SaveMarketOrderProductSuccess
    | SaveMarketOrderProductFailure;

export function saveMarketOrderProduct(
    info: Record<string, any>[]
): SaveMarketOrderProduct {
    return {
        type: ORDERS.SAVE_MARKET_ORDER_PRODUCT_REQUEST,
        info,
    };
}
export function saveMarketOrderProductSuccess(
    data: string
): SaveMarketOrderProductSuccess {
    return {
        type: ORDERS.SAVE_MARKET_ORDER_PRODUCT_SUCCESS,
        data,
    };
}
export function saveMarketOrderProductFailure(
    error: Error
): SaveMarketOrderProductFailure {
    return {
        type: ORDERS.SAVE_MARKET_ORDER_PRODUCT_FAILURE,
        error,
    };
}

export interface PatchMarketOrderProduct {
    type: ORDERS.PATCH_MARKET_ORDER_PRODUCT;
    // info should contain an object . Each object should contain marketOrderHeaderId, marketOrderItemId:string, price and qty;
    info: Record<string, any>;
}
export interface PatchMarketOrderProductSuccess {
    type: ORDERS.PATCH_MARKET_ORDER_PRODUCT_SUCCESS;
    data: string;
}
export interface PatchMarketOrderProductFailure {
    type: ORDERS.PATCH_MARKET_ORDER_PRODUCT_FAILURE;
    error: Error;
}
export type PatchMarketOrderProductType =
    | PatchMarketOrderProduct
    | PatchMarketOrderProductSuccess
    | PatchMarketOrderProductFailure;

export function patchMarketOrderProduct(
    info: Record<string, any>
): PatchMarketOrderProduct {
    return {
        type: ORDERS.PATCH_MARKET_ORDER_PRODUCT,
        info,
    };
}
export function patchMarketOrderProductSuccess(
    data: string
): PatchMarketOrderProductSuccess {
    return {
        type: ORDERS.PATCH_MARKET_ORDER_PRODUCT_SUCCESS,
        data,
    };
}
export function patchMarketOrderProductFailure(
    error: Error
): PatchMarketOrderProductFailure {
    return {
        type: ORDERS.PATCH_MARKET_ORDER_PRODUCT_FAILURE,
        error,
    };
}

/**
 * post market order
 */
export interface PostMarketOrderRequest {
    type: ORDERS.POST_MARKET_ORDER_REQUEST;
    marketOrderHeaderId: string;
    data: Record<string, any>;
}
export interface PostMarketOrderSuccess {
    type: ORDERS.POST_MARKET_ORDER_SUCCESS;
    data: Record<string, unknown>;
}
export interface PostMarketOrderFailure {
    type: ORDERS.POST_MARKET_ORDER_FAILURE;
    error: Record<string, any> | null;
}
export type PostMarketOrderType =
    | PostMarketOrderRequest
    | PostMarketOrderSuccess
    | PostMarketOrderFailure;

export function postMarketOrderRequest(
    marketOrderHeaderId: string,
    data: Record<string, any>
): PostMarketOrderRequest {
    return {
        type: ORDERS.POST_MARKET_ORDER_REQUEST,
        marketOrderHeaderId,
        data,
    };
}

export function postMarketOrderSuccess(
    data: Record<string, unknown>
): PostMarketOrderSuccess {
    return {
        type: ORDERS.POST_MARKET_ORDER_SUCCESS,
        data,
    };
}
export function postMarketOrderFailure(
    error: Record<string, any>
): PostMarketOrderFailure {
    return {
        type: ORDERS.POST_MARKET_ORDER_FAILURE,
        error,
    };
}



/**
 * post Auto order header
 */
 export interface PostAutoOrderHeaderRequest {
    type: ORDERS.POST_AUTO_ORDER_HEADER_REQUEST;
    data: Record<string, any>;
}
export interface PostAutoOrderHeaderSuccess {
    type: ORDERS.POST_AUTO_ORDER_HEADER_SUCCESS;
    data: Order;
}
export interface PostAutoOrderHeaderFailure {
    type: ORDERS.POST_AUTO_ORDER_HEADER_FAILURE;
    error: Record<string, any> | null;
}
export type PostAutoOrderHeaderType =
    | PostAutoOrderHeaderRequest
    | PostAutoOrderHeaderSuccess
    | PostAutoOrderHeaderFailure;

export function postAutoOrderHeaderRequest(
    data: Record<string, any>
): PostAutoOrderHeaderRequest {
    return {
        type: ORDERS.POST_AUTO_ORDER_HEADER_REQUEST,
        data,
    };
}

export function postAutoOrderHeaderSuccess(
    data: Order
): PostAutoOrderHeaderSuccess {
    return {
        type: ORDERS.POST_AUTO_ORDER_HEADER_SUCCESS,
        data,
    };
}
export function postAutoOrderHeaderFailure(
    error: Record<string, any>
): PostAutoOrderHeaderFailure {
    return {
        type: ORDERS.POST_AUTO_ORDER_HEADER_FAILURE,
        error,
    };
}