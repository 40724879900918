//vendors
import React, { useMemo, useState } from "react";
import { IonItem, IonLabel, IonIcon, IonCol } from "@ionic/react";
import { caretDown, caretUp } from 'ionicons/icons';
//constants
import { reportsList, REPORTS_LISTS } from '../../constants/constant';

export const ReportsList: React.FC<any> = ({ setSelectedReport, setShowReportList }) => {
    const [ascending, setAscending] = useState(false);

    const sortList = useMemo(() => {
        return reportsList.sort(function (a, b) {
            if (ascending) {
                if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) { return -1; }
                if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) { return 1; }
                return 0;
            } else {
                if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) { return -1; }
                if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) { return 1; }
                return 0;
            }
        })
    }, [ascending]);

    return (
        <IonCol size="3" size-lg="3" size-md="5" size-xs="12" size-sm="12">
            <IonItem lines="inset" color="none" className="ion-no-padding">
                <IonLabel className="header-text marginLeft10">{REPORTS_LISTS}</IonLabel>
                <IonIcon icon={ascending ? caretDown : caretUp} slot="end" onClick={() => setAscending(!ascending)}></IonIcon>
            </IonItem>
            {sortList.map((item) => {
                const { id, name } = item;
                return (
                    <IonItem
                        button
                        key={id}
                        className="ion-no-padding"
                        onClick={() => {
                            setSelectedReport({ name, id });
                            setShowReportList(false);
                        }}
                    >
                        <IonLabel className="click-cursor report-list-items paddingLeft10">{name}</IonLabel>
                    </IonItem>
                )
            })}
        </IonCol>
    )
};
export default ReportsList;
